var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-4"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 flex-column"
  }, [_c('strong', [_vm._v("Status")]), _c('div', {
    staticClass: "d-flex align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-2"
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "new_sub_project",
      "switch": "",
      "inline": ""
    },
    on: {
      "change": _vm.onHandleCheck
    },
    model: {
      value: _vm.params.all,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "all", $$v);
      },
      expression: "params.all"
    }
  }, [_vm._v(" All ")]), _c('v-multiselect', {
    staticClass: "w-auto",
    attrs: {
      "size": "sm",
      "tag-placeholder": "Status",
      "placeholder": "Select status...",
      "label": "name",
      "track-by": "code",
      "options": _vm.statusList,
      "multiple": true,
      "taggable": true
    },
    model: {
      value: _vm.params.selectedStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "selectedStatus", $$v);
      },
      expression: "params.selectedStatus"
    }
  })], 1), _c('button', {
    staticClass: "btn",
    style: {
      backgroundColor: '#2BDCAA',
      color: 'white'
    },
    attrs: {
      "type": "button",
      "block": ""
    },
    on: {
      "click": _vm.onFilter
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])])]), _c('div', {
    staticClass: "d-flex w-100 border my-2"
  })]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100 overflow-auto",
    staticStyle: {
      "max-height": "600px"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', {
    staticStyle: {
      "background": "#334697",
      "color": "#fff"
    }
  }, [_c('th', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Job Name")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Equipment Name")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Job Class")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Trigger")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Frequency")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Last Maintenance")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Due Date / Hours")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Attachment")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Action")])]), _c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("1")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Lubricating Oil Cooler - BG. SML 104")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Main Engine 1 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "danger"
    }
  }, [_c('span', [_vm._v("Overdue / 8436 RH")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Corrective Maintenance")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Counter")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("300 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Jul 2024 | 280 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("580 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex align-items-center p-2",
    staticStyle: {
      "cursor": "pointer",
      "background-color": "#ECEEED"
    }
  }, [_c('i', {
    staticClass: "fa fa-download mr-2 text-primary"
  }), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Attachment.pdf")])])]), _c('td', {
    staticClass: "text-center sticky-column"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "variant": "dark",
      "toggle-class": "text-decoration-none",
      "text": "Actions",
      "dropright": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" Actions "), _c('i', {
          staticClass: "fa fa-chevron-down ml-1"
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-tasks"
  }), _vm._v(" Maintenance")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-thumbs-up"
  }), _vm._v(" Approve")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "warning"
    }
  }, [_c('i', {
    staticClass: "fa fa-clock"
  }), _vm._v(" Postpone")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Review")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _vm._v(" Edit")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "danger"
    }
  }, [_c('i', {
    staticClass: "fa fa-tools"
  }), _vm._v(" Convert Repair")])], 1)], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("2")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Lubricating Oil Cooler - BG. SML 104")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Main Engine 1 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("Active")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Corrective Maintenance")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Counter")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("300 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Jul 2024 | 280 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("580 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex align-items-center p-2",
    staticStyle: {
      "cursor": "pointer",
      "background-color": "#ECEEED"
    }
  }, [_c('i', {
    staticClass: "fa fa-download mr-2 text-primary"
  }), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Attachment.pdf")])])]), _c('td', {
    staticClass: "text-center sticky-column"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "variant": "dark",
      "toggle-class": "text-decoration-none",
      "text": "Actions",
      "dropright": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" Actions "), _c('i', {
          staticClass: "fa fa-chevron-down ml-1"
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-tasks"
  }), _vm._v(" Maintenance")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-thumbs-up"
  }), _vm._v(" Approve")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "warning"
    }
  }, [_c('i', {
    staticClass: "fa fa-clock"
  }), _vm._v(" Postpone")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Review")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _vm._v(" Edit")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "danger"
    }
  }, [_c('i', {
    staticClass: "fa fa-tools"
  }), _vm._v(" Convert Repair")])], 1)], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("3")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Lubricating Oil Cooler - BG. SML 104")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Main Engine 1 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "info"
    }
  }, [_c('span', [_vm._v("Towards")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Corrective Maintenance")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Counter")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("300 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Jul 2024 | 280 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("580 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex align-items-center p-2",
    staticStyle: {
      "cursor": "pointer",
      "background-color": "#ECEEED"
    }
  }, [_c('i', {
    staticClass: "fa fa-download mr-2 text-primary"
  }), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Attachment.pdf")])])]), _c('td', {
    staticClass: "text-center sticky-column"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "variant": "dark",
      "toggle-class": "text-decoration-none",
      "text": "Actions",
      "dropright": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" Actions "), _c('i', {
          staticClass: "fa fa-chevron-down ml-1"
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-tasks"
  }), _vm._v(" Maintenance")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-thumbs-up"
  }), _vm._v(" Approve")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "warning"
    }
  }, [_c('i', {
    staticClass: "fa fa-clock"
  }), _vm._v(" Postpone")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Review")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _vm._v(" Edit")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "danger"
    }
  }, [_c('i', {
    staticClass: "fa fa-tools"
  }), _vm._v(" Convert Repair")])], 1)], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("4")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Lubricating Oil Cooler - BG. SML 104")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Main Engine 1 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("Review")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Corrective Maintenance")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Counter")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("300 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Jul 2024 | 280 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("580 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex align-items-center p-2",
    staticStyle: {
      "cursor": "pointer",
      "background-color": "#ECEEED"
    }
  }, [_c('i', {
    staticClass: "fa fa-download mr-2 text-primary"
  }), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Attachment.pdf")])])]), _c('td', {
    staticClass: "text-center sticky-column"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "variant": "dark",
      "toggle-class": "text-decoration-none",
      "text": "Actions",
      "dropright": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" Actions "), _c('i', {
          staticClass: "fa fa-chevron-down ml-1"
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-tasks"
  }), _vm._v(" Maintenance")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-thumbs-up"
  }), _vm._v(" Approve")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "warning"
    }
  }, [_c('i', {
    staticClass: "fa fa-clock"
  }), _vm._v(" Postpone")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Review")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _vm._v(" Edit")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "danger"
    }
  }, [_c('i', {
    staticClass: "fa fa-tools"
  }), _vm._v(" Convert Repair")])], 1)], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("5")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Lubricating Oil Cooler - BG. SML 104")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Main Engine 1 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "primary"
    }
  }, [_c('span', [_vm._v("Approved")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Corrective Maintenance")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Counter")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("300 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Jul 2024 | 280 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("580 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex align-items-center p-2",
    staticStyle: {
      "cursor": "pointer",
      "background-color": "#ECEEED"
    }
  }, [_c('i', {
    staticClass: "fa fa-download mr-2 text-primary"
  }), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Attachment.pdf")])])]), _c('td', {
    staticClass: "text-center sticky-column"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "variant": "dark",
      "toggle-class": "text-decoration-none",
      "text": "Actions",
      "dropright": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" Actions "), _c('i', {
          staticClass: "fa fa-chevron-down ml-1"
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-tasks"
  }), _vm._v(" Maintenance")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-thumbs-up"
  }), _vm._v(" Approve")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "warning"
    }
  }, [_c('i', {
    staticClass: "fa fa-clock"
  }), _vm._v(" Postpone")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Review")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _vm._v(" Edit")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "danger"
    }
  }, [_c('i', {
    staticClass: "fa fa-tools"
  }), _vm._v(" Convert Repair")])], 1)], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("6")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Lubricating Oil Cooler - BG. SML 104")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Main Engine 1 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "dark"
    }
  }, [_c('span', [_vm._v("Finish")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Corrective Maintenance")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Counter")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("300 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Jul 2024 | 280 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("580 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex align-items-center p-2",
    staticStyle: {
      "cursor": "pointer",
      "background-color": "#ECEEED"
    }
  }, [_c('i', {
    staticClass: "fa fa-download mr-2 text-primary"
  }), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Attachment.pdf")])])]), _c('td', {
    staticClass: "text-center sticky-column"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "variant": "dark",
      "toggle-class": "text-decoration-none",
      "text": "Actions",
      "dropright": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" Actions "), _c('i', {
          staticClass: "fa fa-chevron-down ml-1"
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-tasks"
  }), _vm._v(" Maintenance")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-thumbs-up"
  }), _vm._v(" Approve")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "warning"
    }
  }, [_c('i', {
    staticClass: "fa fa-clock"
  }), _vm._v(" Postpone")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Review")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _vm._v(" Edit")]), _c('b-dropdown-item', {
    attrs: {
      "size": "sm",
      "href": "#",
      "variant": "danger"
    }
  }, [_c('i', {
    staticClass: "fa fa-tools"
  }), _vm._v(" Convert Repair")])], 1)], 1)])])])]), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_vm._v(" Total: 1   |   Per page: 20 ")]), _c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": 1,
      "per-page": 20,
      "align": "right"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
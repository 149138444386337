var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col-md-12 d-flex justify-content-end mb-2"
  }, [_vm.detailFillSurvey ? [!_vm.isEditMode === true ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditMode = !_vm.isEditMode;
      }
    }
  }, [_vm._v("Update Claim Analysis")]) : _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditMode = !_vm.isEditMode;
      }
    }
  }, [_vm._v("Preview Claim Analysis")])] : _vm._e(), _vm.isEditMode ? _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "type": "submit",
      "variant": "outline-primary"
    }
  }, [_vm._v("Submit")]) : _vm._e()], 2)]), _c('div', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "px-3"
  }, [_c('b-row', {
    staticClass: "pt-3",
    staticStyle: {
      "background": "#f2f1f1"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Policy Number *",
      "label-for": "police_number",
      "label-class": "mb-0 font-500"
    }
  }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('b-badge', {
    staticClass: "form-control-static m-0",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.police_number) + " ")]) : _c('b-form-input', {
    attrs: {
      "required": "",
      "type": "text"
    },
    model: {
      value: _vm.form.police_number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "police_number", $$v);
      },
      expression: "form.police_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet *",
      "label-for": "fleets",
      "label-class": "mb-0 font-500"
    }
  }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
    staticClass: "form-control-static mb-0 mt-1"
  }, [_vm._v(_vm._s(_vm.detailMi.vehicle.name))]) : _c('b-form-input', {
    attrs: {
      "readonly": "",
      "value": _vm.detailMi.vehicle.name || '-',
      "type": "text"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cause of Loss *",
      "label-for": "col",
      "label-class": "mb-0 font-500"
    }
  }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
    staticClass: "form-control-static mb-0 mt-1"
  }, [_vm._v(_vm._s(_vm.detailMi.type_loss.name))]) : _c('b-form-input', {
    attrs: {
      "readonly": "",
      "value": _vm.detailMi.type_loss.name || '-',
      "type": "text"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "pt-3",
    staticStyle: {
      "background": "#f9fafb"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Place of Loss (POL) *",
      "label-for": "pol",
      "label-class": "mb-0 font-500"
    }
  }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
    staticClass: "form-control-static mb-0 mt-1"
  }, [_vm._v(_vm._s(_vm.detailMi.place_of_loss))]) : _c('b-form-input', {
    attrs: {
      "readonly": "",
      "value": _vm.detailMi.place_of_loss || '-',
      "type": "text"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Loss (DOL) *",
      "label-for": "dol",
      "label-class": "mb-0 font-500"
    }
  }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
    staticClass: "form-control-static mb-0 mt-1"
  }, [_vm._v(_vm._s(_vm.detailMi.date_of_loss))]) : _c('b-form-input', {
    attrs: {
      "readonly": "",
      "value": _vm.detailMi.date_of_loss || '-',
      "type": "text"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Periode of Coverage*",
      "label-for": "periodeOfCoverage",
      "label-class": "mb-0 font-500"
    }
  }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
    staticClass: "form-control-static mb-0 mt-1"
  }, [_vm._v(_vm._s(_vm.form.coverage_period || '-'))]) : _c('date-picker', {
    attrs: {
      "type": "year",
      "range": "",
      "value-type": "YYYY",
      "format": "YYYY",
      "placeholder": "Select Year"
    },
    model: {
      value: _vm.form.coverage_period,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "coverage_period", $$v);
      },
      expression: "form.coverage_period"
    }
  })], 1)], 1)], 1)], 1), _vm.detailSurvey && !_vm.loading.survey ? _c('div', {
    staticClass: "mt-4"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "60px"
    }
  }, [_vm._v("NO")]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
    return _c('th', {
      key: headerIndex,
      staticClass: "text-truncate",
      attrs: {
        "width": headerIndex === 3 ? '450px' : ''
      }
    }, [_vm._v(_vm._s(header.label_name))]);
  })], 2), _vm.detailSurvey.headers[0] ? _c('tbody', [_vm._l(_vm.detailSurvey.headers[0].contents.length, function (number, numberIndex) {
    return _c('tr', {
      key: numberIndex
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(number))]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndexes) {
      return _c('td', {
        key: headerIndexes,
        class: {
          'text-center': headerIndexes == 2
        }
      }, [header.contents[number - 1] ? [header.contents[number - 1].text ? [_vm._v(" " + _vm._s(header.contents[number - 1].text) + " ")] : header.contents[number - 1].input ? [_vm.detailFillSurvey && !_vm.isEditMode ? [headerIndexes != 2 ? _c('p', {
        staticClass: "form-control-static mb-0 mt-1"
      }, [_vm._v(_vm._s(header.contents[number - 1].input.value) + " ")]) : _c('b-badge', {
        staticClass: "p-3",
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "variant": _vm.indicatorColor(header.contents[number - 1].input.value)
        }
      }, [_vm._v(_vm._s(header.contents[number - 1].input.value))]), header.contents[number - 1].input.alias === 'max100' ? _c('input', {
        staticClass: "survey-input-max100",
        attrs: {
          "type": "hidden"
        },
        domProps: {
          "value": header.contents[number - 1].input.value
        }
      }) : _vm._e()] : [header.contents[number - 1].input.alias === 'max100' ? _c('b-form-input', {
        staticClass: "survey-input-max100",
        attrs: {
          "type": "number",
          "maxlength": 3,
          "min": 1,
          "max": 100
        },
        on: {
          "blur": function blur($event) {
            return _vm.onGenerateTotal();
          }
        },
        model: {
          value: header.contents[number - 1].input.value,
          callback: function callback($$v) {
            _vm.$set(header.contents[number - 1].input, "value", $$v);
          },
          expression: "header.contents[number - 1].input.value"
        }
      }) : header.contents[number - 1].input.alias === 'field' ? _c('b-form-textarea', {
        attrs: {
          "rows": "6"
        },
        model: {
          value: header.contents[number - 1].input.value,
          callback: function callback($$v) {
            _vm.$set(header.contents[number - 1].input, "value", $$v);
          },
          expression: "header.contents[number - 1].input.value"
        }
      }) : _vm._e()]] : _vm._e()] : _vm._e()], 2);
    })], 2);
  }), _c('tr', {
    staticStyle: {
      "border-bottom": "1px solid"
    }
  }, [_c('b-td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('h5', [_c('b', [_vm._v("Total")])])]), _c('b-td', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    staticClass: "p-3",
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.totalAll))])], 1), _c('b-td', [_vm._v(" ")])], 1)], 2) : _vm._e()])]) : _c('div', {
    staticClass: "py-5 my-5 d-flex justify-content-center"
  }, [_c('b-spinner')], 1), _c('b-row', {
    staticClass: "px-3 pb-3"
  }, [_c('b-col', {
    staticClass: "border",
    attrs: {
      "md": "4"
    }
  }, [_c('h5', {
    staticClass: "my-2"
  }, [_vm._v("Value Indicator")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', _vm._l(_vm.indicators, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('b-row', [_c('b-col', {
      staticClass: "pl-4",
      attrs: {
        "cols": "4"
      }
    }, [_c('b-badge', {
      staticClass: "py-2",
      staticStyle: {
        "min-width": "120px"
      },
      attrs: {
        "variant": item.color
      }
    }, [_vm._v(_vm._s(item.value))])], 1), _c('b-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('h6', [_vm._v(_vm._s(item.label))])])], 1)], 1)]);
  }), 0)])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', {
    staticClass: "my-2 p-2 px-3",
    staticStyle: {
      "background": "#f9fafb"
    }
  }, [_vm._v("Conclusion")]), _c('b-form-group', [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
    staticClass: "form-control-static mb-0 mt-1"
  }, [_vm._v(_vm._s(_vm.form.conclusion))]) : _c('b-form-textarea', {
    attrs: {
      "id": "exampleFormControlTextarea1",
      "required": "",
      "rows": "5"
    },
    model: {
      value: _vm.form.conclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "conclusion", $$v);
      },
      expression: "form.conclusion"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', {
    staticClass: "my-2 p-2 px-3",
    staticStyle: {
      "background": "#f9fafb"
    }
  }, [_vm._v("Comments or Suggestions")]), _c('b-form-group', [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
    staticClass: "form-control-static mb-0 mt-1"
  }, [_vm._v(_vm._s(_vm.form.comments) + " ")]) : _c('b-form-textarea', {
    attrs: {
      "id": "exampleFormControlTextarea1",
      "required": "",
      "rows": "5"
    },
    model: {
      value: _vm.form.comments,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comments", $$v);
      },
      expression: "form.comments"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    staticClass: "pull-right d-flex justify-content-end",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
    staticClass: "form-control-static mb-0 mt-1"
  }) : _c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1)]), _c('b-row', {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm.detailMi ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
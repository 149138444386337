var render = function render(){
  var _vm$projectData, _vm$projectData$proje, _vm$projectData2, _vm$projectData3, _vm$projectData3$proj, _vm$projectData3$proj2, _vm$tripData, _vm$tripData$trip_att, _vm$tripData2, _vm$projectPlan, _vm$projectPlan2, _vm$projectPlan$fleet, _vm$projectPlan3, _vm$projectPlan3$carg, _vm$projectPlan4, _vm$projectPlan5, _vm$projectPlan6, _vm$projectPlan6$term, _vm$projectPlan7, _vm$projectPlan8, _vm$projectPlan9, _vm$projectPlan10, _vm$projectPlan11;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveTripDocument.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Create Trip Document  ")]) : _vm._e(), _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideFormTripDocument
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Close  ")])], 1), _c('b-col', {
    staticClass: "p-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Document Name"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "type": "text"
    },
    model: {
      value: _vm.form.name_document,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name_document", $$v);
      },
      expression: "form.name_document"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.form.name_document))]) : _vm._e()], 1)], 1), ((_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : (_vm$projectData$proje = _vm$projectData.project_contract_plan) === null || _vm$projectData$proje === void 0 ? void 0 : _vm$projectData$proje.length) > 0 ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "For Trip"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('v-select', {
    attrs: {
      "label": "shipment_plan_detail_number",
      "track-by": "id",
      "name": "plan_id",
      "options": (_vm$projectData2 = _vm.projectData) === null || _vm$projectData2 === void 0 ? void 0 : _vm$projectData2.project_contract_plan,
      "reduce": function reduce(plan) {
        return plan.id;
      },
      "placeholder": "Select...",
      "required": ""
    },
    on: {
      "input": _vm.getProjectPlanById
    },
    model: {
      value: _vm.form.plan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "plan_id", $$v);
      },
      expression: "form.plan_id"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s((_vm$projectData3 = _vm.projectData) === null || _vm$projectData3 === void 0 ? void 0 : (_vm$projectData3$proj = _vm$projectData3.project_contract_plan) === null || _vm$projectData3$proj === void 0 ? void 0 : (_vm$projectData3$proj2 = _vm$projectData3$proj.find(function (obj) {
    return obj.id === _vm.form.plan_id;
  })) === null || _vm$projectData3$proj2 === void 0 ? void 0 : _vm$projectData3$proj2.shipment_plan_detail_number))]) : _vm._e()], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Documents"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-file', {
    staticClass: "border p-2",
    attrs: {
      "accept": "application/*",
      "multiple": "",
      "name": "document",
      "id": "document"
    },
    model: {
      value: _vm.form.document,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "document", $$v);
      },
      expression: "form.document"
    }
  }) : _vm._e(), ((_vm$tripData = _vm.tripData) === null || _vm$tripData === void 0 ? void 0 : (_vm$tripData$trip_att = _vm$tripData.trip_attachment) === null || _vm$tripData$trip_att === void 0 ? void 0 : _vm$tripData$trip_att.length) > 0 && ['EDIT', 'PREVIEW'].includes(_vm.action) ? _c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l((_vm$tripData2 = _vm.tripData) === null || _vm$tripData2 === void 0 ? void 0 : _vm$tripData2.trip_attachment, function (file, fileIndex) {
    return _c('div', {
      key: fileIndex,
      staticClass: "d-flex align-items-center mb-1",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file === null || file === void 0 ? void 0 : file.file_location), file.file_name);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-file text-primary mr-2"
    }), _c('span', {
      staticClass: "text-primary text-truncate"
    }, [_vm._v(_vm._s(file.file_name))])]);
  }), 0) : _vm._e()], 1)], 1), _vm.projectPlan && _vm.form.plan_id ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project Plan")]), _c('div', {
    staticClass: "d-flex w-100 overflow-auto"
  }, [_c('table', {
    staticClass: "table table-bordered table-sm table-responsive"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center text-truncate align-middle",
    staticStyle: {
      "width": "5px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*SHIPMENT PLAN DETAIL NUMBER")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*DESCRIPTION")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "colspan": !_vm.projectData.third_party ? '3' : '2'
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("DRAUGHT TYPE")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("LOCATION")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*SHIPMENT MONTH")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PLAN DATE")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*PLAN QUANTITY")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*REMARKS")])]), _c('tr', [_c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*OWNERSHIP")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*TYPE")]), !_vm.projectData.third_party ? _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*FLEETS")]) : _vm._e(), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*TYPE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*ACC. DATE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*TERMS")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*POL")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*POD")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*ETA POL")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*ETA POD")])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center bg-white text-black"
  }), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan = _vm.projectPlan) === null || _vm$projectPlan === void 0 ? void 0 : _vm$projectPlan.shipment_plan_detail_number))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan2 = _vm.projectPlan) === null || _vm$projectPlan2 === void 0 ? void 0 : _vm$projectPlan2.description))]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s(_vm.projectPlan.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED'))])], 1), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s((_vm$projectPlan$fleet = _vm.projectPlan.fleet_type) === null || _vm$projectPlan$fleet === void 0 ? void 0 : _vm$projectPlan$fleet.name))])], 1), !_vm.projectData.third_party ? _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._l(_vm.projectPlan.fleets, function (objDetail, detailIndex) {
    return [objDetail.vehicle_id ? _c('b-badge', {
      key: detailIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(_vm.showVehicleName(objDetail === null || objDetail === void 0 ? void 0 : objDetail.vehicle_name)))]) : _vm._e()];
  })], 2)]) : _vm._e(), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan3 = _vm.projectPlan) === null || _vm$projectPlan3 === void 0 ? void 0 : (_vm$projectPlan3$carg = _vm$projectPlan3.cargo_type) === null || _vm$projectPlan3$carg === void 0 ? void 0 : _vm$projectPlan3$carg.name))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan4 = _vm.projectPlan) !== null && _vm$projectPlan4 !== void 0 && _vm$projectPlan4.acceptance_date ? _vm.moment((_vm$projectPlan5 = _vm.projectPlan) === null || _vm$projectPlan5 === void 0 ? void 0 : _vm$projectPlan5.acceptance_date).format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v(_vm._s((_vm$projectPlan6 = _vm.projectPlan) === null || _vm$projectPlan6 === void 0 ? void 0 : (_vm$projectPlan6$term = _vm$projectPlan6.terms) === null || _vm$projectPlan6$term === void 0 ? void 0 : _vm$projectPlan6$term.name))]), _c('span', [_vm._v("Remarks: " + _vm._s((_vm$projectPlan7 = _vm.projectPlan) === null || _vm$projectPlan7 === void 0 ? void 0 : _vm$projectPlan7.terms_remarks))])])]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.capitalizeString((_vm$projectPlan8 = _vm.projectPlan) === null || _vm$projectPlan8 === void 0 ? void 0 : _vm$projectPlan8.draught_type)))]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex w-100"
  }, _vm._l(_vm.projectPlan.pol, function (obj, polIndex) {
    var _obj$value;
    return _c('b-badge', {
      key: polIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(" " + _vm._s((obj === null || obj === void 0 ? void 0 : obj.value) === "Others" ? obj.valueOther : (_obj$value = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value !== void 0 ? _obj$value : '-') + " ")]);
  }), 1)]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex w-100"
  }, _vm._l(_vm.projectPlan.pod, function (obj, podIndex) {
    var _obj$value2;
    return _c('b-badge', {
      key: podIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(" " + _vm._s((obj === null || obj === void 0 ? void 0 : obj.value) === "Others" ? obj.valueOther : (_obj$value2 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value2 !== void 0 ? _obj$value2 : '-') + " ")]);
  }), 1)]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan9 = _vm.projectPlan) === null || _vm$projectPlan9 === void 0 ? void 0 : _vm$projectPlan9.shipment))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.projectPlan.eta_pol ? _vm.moment(_vm.projectPlan.eta_pol).format('DD MMM YYYY') : '-'))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.projectPlan.eta_pod ? _vm.moment(_vm.projectPlan.eta_pod).format('DD MMM YYYY') : '-'))]), _c('td', {
    staticClass: "text-truncate text-right"
  }, [_vm._v(_vm._s((_vm$projectPlan10 = _vm.projectPlan) === null || _vm$projectPlan10 === void 0 ? void 0 : _vm$projectPlan10.plan_quantity.toLocaleString()))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan11 = _vm.projectPlan) === null || _vm$projectPlan11 === void 0 ? void 0 : _vm$projectPlan11.remarks))])])])])])])]) : _vm._e()], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
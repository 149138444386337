<template>
  <b-container fluid>
    <form  @submit="onSave"  action="#">
      <b-row>
        <b-col md="12">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
                :disabled="saving"
                variant="primary"
                type="submit"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
          </div>
        </b-col>
        <b-col lg="12" class="mb-4">
          <b-row>
            <b-col md="6">
              <b-form-group label="POL" label-for="pol"> 
                <v-select 
                  v-model="form.pol_id" 
                  label="text" 
                  track-by="value"
                  :options="listPorts" 
                  :reduce="listPorts => listPorts.value" 
                  placeholder="Select..." 
                  required
                />
              </b-form-group> 
            </b-col>
            <b-col md="6">
              <b-form-group label="POD" label-for="pod"> 
                <v-select 
                  v-model="form.pod_id" 
                  label="text" 
                  track-by="value"
                  :options="listPorts" 
                  :reduce="listPorts => listPorts.value" 
                  placeholder="Select..." 
                  required
                />
              </b-form-group> 
            </b-col>
            <b-col md="6">
              <b-form-group label="Route ID" label-for="route_code">
                <b-form-input
                  id="route_code"
                  type="text"
                  placeholder="Type text"
                  v-model="form.route_code"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Route Name" label-for="route_name">
                <b-form-input
                  id="route_name"
                  type="text"
                  placeholder="Type text"
                  v-model="form.route_name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Type" label-for="type">
                <b-form-select
                  id="type"
                  plain
                  size="md"
                  v-model="form.type"
                  required
                >
                  <template v-slot:first>
                    <b-form-select-option :value="''">Select...</b-form-select-option>
                    <b-form-select-option :value="'POL'">POL</b-form-select-option>
                    <b-form-select-option :value="'POD'">POD</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Streaming Time" label-for="streaming_time">
                <b-form-input
                  id="streaming_time"
                  type="number"
                  placeholder="Type text"
                  v-model="form.streaming_time"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Distance" label-for="distance">
                <b-form-input
                  id="distance"
                  type="number"
                  placeholder="Type number"
                  v-model="form.distance"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Speed" label-for="speed">
                <b-form-input
                  id="speed"
                  type="number"
                  placeholder="Type number"
                  v-model="form.speed"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Ltrs" label-for="ltrs">
                <b-form-input
                  id="ltrs"
                  type="number"
                  placeholder="Type number"
                  v-model="form.ltrs"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Amount" label-for="amount">
                <b-form-input
                  id="amount"
                  type="number"
                  placeholder="Type number"
                  v-model="form.amount"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { busdevActions } from '@src/Utils/helper'
  import moment from 'moment'

  export default {
    name: 'form',
    props:{
      refreshData: {
        type: Function,
        default: true
      },
      isEdit: {
        type: Boolean,
        default: false
      },
      editId: {
        type: Number,
        default: null
      }, 
      previewData: {
        type: Boolean,
        default: false
      },
      listPorts: {
        type: Array,
        default: []
      }, 
    },
    data() {
      return {
        loading:false,
        saving:false,

        form:{
          route_code:'',
          pol_id:null,
          pod_id:null,
          type:'', // 'pol', 'pod'
          route_name:'', 
          streaming_time: null, 
          distance: null, 
          speed: null, 
          ltrs: null, 
          amount: null, 
        },
      }
    },
    components:{},
    watch:{
      'editId': {
        handler(value) {
          if(value){
            this.getBunkerRouteById()
          } else {
            this.resetform()
          }
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {},
    async mounted() {
     
    },
    methods: {
      ...busdevActions,
      resetform(){
        this.form = {
          route_code:'',
          pol_id:null,
          pod_id:null,
          type:'', // 'pol', 'pod'
          route_name:'', 
          streaming_time: null, 
          distance: null, 
          speed: null, 
          ltrs: null, 
          amount: null, 
        }
      },
      async getBunkerRouteById (){
        if(this.isEdit && this.editId){
          const res = await this.fetchBunkerRouteById({id: this.editId})

          if(res.status === 'success'){
            const data = res.data.data


            this.form = {
              ...res.data.data
            }
          }
        }
      },
      async onSave(e) {
        e.preventDefault()
        this.saving = true

        let payload = {
          ...this.form,
        }

        let res = null
        if(this.isEdit && this.editId){
          res = await this.updateBunkerRoute({data: payload, id: Number(this.editId)})
        } else {
          res = await this.createBunkerRoute({data: payload})
        }

        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
          return
        }

        if(res?.data?.status === true){
          this.saving = false
          this.refreshData()
          this.resetform()
          this.$swal(`Success!`, `${this.isEdit ? 'Update' : 'Create'} Bunker Route Success.`, 'success')
        } else {
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }

        this.saving = false
      }
    }
  }
</script>
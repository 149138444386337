<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card class="iq-card-block iq-card-stretch iq-card-height text-center">
          <template>
            <div class="btn-group w-100">
              <button
                type="button"
                @click="viewMenu = 'cargo-sales'"
                @mouseover="tabMenuOptions.cargoSales.isHover = true"
                @mouseleave="tabMenuOptions.cargoSales.isHover = false"
                class="btn btn-outline-primary p-3 btn-menu"
                :class="{ 'btn-menu-active': viewMenu === 'cargo-sales' }"
              > CARGO & SALES ANALYSIS
              </button>
              <button
                type="button"
                @click="viewMenu = 'cargo'"
                @mouseover="tabMenuOptions.cargo.isHover = true"
                @mouseleave="tabMenuOptions.cargo.isHover = false"
                class="btn btn-outline-primary p-3 btn-menu"
                :class="{ 'btn-menu-active': viewMenu === 'cargo' }"
              >
                BUDGET VS ACTUAL CARGO
              </button>
              <button
                type="button"
                @click="viewMenu = 'sales'"
                @mouseover="tabMenuOptions.sales.isHover = true"
                @mouseleave="tabMenuOptions.sales.isHover = false"
                class="btn btn-outline-primary p-3 btn-menu"
                :class="{ 'btn-menu-active': viewMenu === 'sales' }"
              >
                BUDGET VS ACTUAL SALES
              </button>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col md="12">
        <template v-if="viewMenu === 'cargo-sales'">
          <CargoSalesAnalysis />
        </template>
        <template v-if="viewMenu === 'cargo'">
          <BudgetActualCargo />
        </template>
        <template v-if="viewMenu === 'sales'">
          <BudgetActualSales />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BudgetActualCargo from './BudgetActualCargo.vue'
import BudgetActualSales from './BudgetActualSales.vue'
import CargoSalesAnalysis from './CargoSalesAnalysis.vue'

export default {
  name:'Budget',
  components:{
    CargoSalesAnalysis,
    BudgetActualSales,
    BudgetActualCargo,
  },
  data() {
    return {
      viewMenu: 'cargo-sales',
      tabMenuOptions: {
        cargoSales: {
          isHover: false,
          isActive: false,
        },
        cargo: {
          isHover: false,
          isActive: false,
        },
        sales: {
          isHover: false,
          isActive: false,
        },
      },
    }
  },
  mounted() {
    
  },
  methods: {

    
  },
}
</script>

<style scoped>
.icon-state-default {
  color: gray;
}
.icon-state-danger {
  color: red;
}
.icon-state-warning {
  color: yellow;
}
.icon-state-success {
  color: green;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.btn-menu:hover {
  background: #334697 !important;
  border: #334697;
}

.btn-menu-active {
  background: #334697 !important;
  color: white !important;
}
</style>
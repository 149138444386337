<template>
  <div class="p-0 py-2">
    <ul class="doctors-lists m-0 p-0" v-if="crewData?.length > 0">
      <li
        class="d-flex mb-4 align-items-center"
        v-for="crew in crewData"
        :key="'crew-' + crew.id"
      >
        <div class="user-img img-fluid"
          ><img
            :src="crew?.photo"
            alt="story-img"
            class="rounded-circle avatar-40"
        /></div>
        <div class="media-support-info ml-3">
          <h6>{{ crew?.personal_data?.full_name ?? '-' }}</h6>
          <p class="mb-0 font-size-12">{{ crew?.position?.name ?? '-' }}</p>
          <small class="mb-0 font-size-12">
            Join On Board:
            <strong>{{
              crew?.join_on_board
                ? moment(crew?.join_on_board).format('DD MMMM YYYY')
                : '-'
            }}</strong>
          </small>
          <br />
          <small class="mb-0 font-size-12">
            Sign Off:
            <strong>{{
              crew?.sign_off
                ? moment(crew?.sign_off).format('DD MMMM YYYY')
                : '-'
            }}</strong>
          </small>
        </div>
        <div class="iq-card-header-toolbar d-flex align-items-center">
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="p-0 text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <span
                class="dropdown-toggle p-0"
                id="dropdownMenuButton6"
                data-toggle="dropdown"
              >
                <i class="ri-more-2-line"></i>
              </span>
            </template>

            <b-dropdown-item
              href="#"
              @click="
                $router.push({
                  path: `/crewing/profile`,
                  query: {
                    user: 'crew',
                    id: crew.id,
                  },
                })
              "
              ><i class="ri-eye-line mr-2"></i>View Detail</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </li>
    </ul>
    <div class="d-flex align-items-center justify-content-center" v-else>
      <h6 class="text-muted">
        <strong>This Fleet doesnt have Crew</strong>
      </h6>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    crewData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

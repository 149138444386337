var render = function render(){
  var _vm$incident$name, _vm$incident$position, _vm$incident$details_, _vm$incident$estimate, _vm$incident$conclusi, _vm$formatCurrency;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Claim Type"
    }
  }, [_vm._l(_vm.typeFormInsurance, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      attrs: {
        "inline": "",
        "color": item.color,
        "name": "color",
        "value": item.value,
        "disabled": _vm.readonly
      },
      model: {
        value: _vm.incident.type_form,
        callback: function callback($$v) {
          _vm.$set(_vm.incident, "type_form", $$v);
        },
        expression: "incident.type_form"
      }
    }, [_c('h6', [_vm._v(_vm._s(item.label))])])];
  })], 2)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h6', {
    staticClass: "card-title text-primary pb-2 border-bottom"
  }, [_c('b', [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company",
      "label-for": "company"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.companies.find(function (el) {
    return el.value == _vm.incident.company_id;
  }).label))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet Name",
      "label-for": "vehicles"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.vehicles.find(function (el) {
    return el.value == _vm.incident.vehicle_id;
  }).label))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Loss (DOL)",
      "label-for": "dol"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.moment(_vm.incident.date_of_loss).format('DD MMM YYYY')))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Place of Loss (POL)",
      "label-for": "pol"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.incident.place_of_loss))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Coverage",
      "label-for": "coverage"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.incident.type_coverage_id ? _vm.coverages.find(function (el) {
    return el.value == _vm.incident.type_coverage_id;
  }).label : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Loss",
      "label-for": "loss"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.incident.type_coverage_id ? _vm.losses.find(function (el) {
    return el.value == _vm.incident.type_loss_id;
  }).label : '-'))])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h6', {
    staticClass: "card-title text-primary pb-2 border-bottom"
  }, [_c('b', [_vm._v("CLAIMANT")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company",
      "label-for": "company"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.incident.company_claimant_id ? _vm.companies.find(function (el) {
    return el.value == _vm.incident.company_claimant_id;
  }).label : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$incident$name = _vm.incident.name) !== null && _vm$incident$name !== void 0 ? _vm$incident$name : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Position",
      "label-for": "position"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$incident$position = _vm.incident.position) !== null && _vm$incident$position !== void 0 ? _vm$incident$position : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Department of FLeetasdasd",
      "label-for": "departments"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.incident.department_id ? _vm.departments.find(function (el) {
    return el.value == _vm.incident.department_id;
  }).label : '-'))])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Detail Chronology"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$incident$details_ = _vm.incident.details_of_chronology) !== null && _vm$incident$details_ !== void 0 ? _vm$incident$details_ : '-'))])])], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimate Damage or Loss"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$incident$estimate = _vm.incident.estimate_damage) !== null && _vm$incident$estimate !== void 0 ? _vm$incident$estimate : '-'))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Decribe of Conclusion"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$incident$conclusi = _vm.incident.conclusion) !== null && _vm$incident$conclusi !== void 0 ? _vm$incident$conclusi : '-'))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimate Cost"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$formatCurrency = _vm.formatCurrency(_vm.incident.cost)) !== null && _vm$formatCurrency !== void 0 ? _vm$formatCurrency : '-'))])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="my-4">
    <div class="mb-3 d-flex justify-content-end">
      <b-button variant="primary" @click="onCreateRCA(detailMi.id)"> Create RCA </b-button>
    </div>
    <table class="table table-striped border">
      <thead>
        <th>Nama</th>
        <th>Company</th>
        <th>Gender</th>
        <th>Posisi</th>
        <th class="text-center">Action</th>
      </thead>
      <tbody >
        <template v-if="detailMi?.rcas?.length > 0">
          <tr v-for="rca in detailMi?.rcas" :key="rca.id">
            <td>{{ rca.personal_fullname }}</td>
            <td>{{ findCompany(rca.personal_company_id) }}</td>
            <td>{{
              rca.personal_gender === 'M' ? 'Laki - Laki' : 'Perempuan'
            }}</td>
            <td>{{ rca.personal_position }}</td>
            <td class="text-center">
              <b-button
                variant="success"
                size="sm"
                class="mr-2"
                v-if="canEditRCA"
                @click="onEditRCA(rca.id, detailMi.id)"
                ><i class="fa fa-edit"></i
              ></b-button>
              <b-button
                variant="danger"
                size="sm"
                class="mr-2"
                @click="onDeleteRCA(rca)"
                v-if="canDeleteRCA"
              >
                <i v-if="!loading_delete_rca" class="fa fa-trash"></i>
                <template v-else>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Deleting...
                </template>
              </b-button>
              <b-button
                variant="warning"
                size="sm"
                @click="onPreviewRCA(rca.id, detailMi.id)"
              >
                <i class="fa fa-eye"></i
              ></b-button>
            </td>
          </tr>
        </template>
        <template v-else>
          <div class="p-2">No Data RCA</div>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import { xray } from '@/src/config/pluginInit'
import { miActions } from '@/src/Utils/helper'

export default {
  name: 'RootCauseAnalysis',
  props: {
    detailMi: null,
    companies: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  mounted() {
    if (
      this.$route.query.preview === 'true' ||
      this.$route.query.preview === true
    ) {
      this.isPreview = true
    }
    xray.index()
  },
  data() {
    return {
      isPreview: false,
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      loading_delete_rca: false,
    }
  },
  computed: {
    RCA() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Marine Insurance')
        ?.child.find((child) => child.menu === 'RCA')
    },
    activeRCA() {
      return this.RCA ? this.RCA?.active : false
    },
    canAddRCA() {
      return this.RCA ? this.RCA?.add : false
    },
    canEditRCA() {
      return this.RCA ? this.RCA?.update : false
    },
    canApproveRCA() {
      return this.RCA ? this.RCA?.approval : false
    },
    canReviewRCA() {
      return this.RCA ? this.RCA?.review : false
    },
    canDeleteRCA() {
      return this.RCA ? this.RCA?.delete : false
    },
    canExportRCA() {
      return this.RCA ? this.RCA?.export : false
    },
  },
  methods: {
    ...miActions,
    findCompany(id) {
      return this.companies.find((company) => company.value === id)?.text
    },
    onCreateRCA(formId) {
      this.$router.push({
        name: 'mi.root-cause-analysis-form',
        query: {
          formId,
        },
      })
    },
    onEditRCA(id, formId) {
      this.$router.push({
        name: 'mi.root-cause-analysis-form',
        params: {
          id,
        },
        query: {
          formId,
        },
      })
    },
    onDeleteRCA(rca) {
      this.$swal({
        title: 'Delete RCA?',
        text: `RCA for ${rca.personal_fullname} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_rca = true
          let res = await this.deleteRCA(rca.id)
          if (res.status !== 'success') {
            this.loading_delete_rca = false
            if (res.data.message !== null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get RCA data.',
                'error'
              )
            }
          } else {
            this.loading_delete_rca = false
            this.handleFetchIncidents()
            this.$swal(
              `RCA deleted!`,
              `RCA for ${rca.personal_fullname} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    onPreviewRCA(id, formId) {
      this.$router.push({
        name: 'mi.root-cause-analysis-form',
        params: {
          id,
        },
        query: {
          formId,
          preview: true,
        },
      })
    },
  },
}
</script>

<template>
  <b-row class="m-4">
    <b-col md="12">
      <div class="d-flex w-100 flex-column">
        <strong>Status</strong>
        <div class="d-flex align-items-center w-100">
          <div class="d-flex align-items-center mr-2">
            <b-form-checkbox
              name="new_sub_project"
              switch
              inline
              v-model="params.all"
              @change="onHandleCheck"
            >
              All
            </b-form-checkbox>
            <v-multiselect size="sm" v-model="params.selectedStatus" class="w-auto" tag-placeholder="Status" placeholder="Select status..." label="name" track-by="code" :options="statusList" :multiple="true" :taggable="true" >
            </v-multiselect>
          </div>
          <button type="button" class="btn" :style="{ backgroundColor: '#2BDCAA', color: 'white' }" block @click="onFilter"><i class="fa fa-search" /></button>
        </div>
      </div>
      <div class="d-flex w-100 border my-2" />
    </b-col>
    <b-col md="12">
      <div class="d-flex flex-column w-100 overflow-auto" style="max-height: 600px">
        <table class="table table-striped">
          <thead style="background: #334697; color: #fff;">
            <th style="width: 5px;">No</th>
            <th class="text-truncate">Job Name</th>
            <th class="text-truncate">Equipment Name</th>
            <th class="text-truncate">Status</th>
            <th class="text-truncate">Job Class</th>
            <th class="text-truncate">Trigger</th>
            <th class="text-truncate">Frequency</th>
            <th class="text-truncate">Last Maintenance</th>
            <th class="text-truncate">Due Date / Hours</th>
            <th class="text-truncate">Attachment</th>
            <th class="text-truncate">Action</th>
          </thead>  
          <tbody>
            <tr>
              <td style="width: 5px;">1</td>
              <td class="text-truncate">Lubricating Oil Cooler - BG. SML 104</td>
              <td class="text-truncate">Main Engine 1 - Lubricating Oil Cooler</td>
              <td class="text-truncate">
                <b-badge variant="danger" class="p-2">
                  <span>Overdue / 8436 RH</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">Corrective Maintenance</td>
              <td class="text-truncate text-center">Counter</td>
              <td class="text-truncate">300 Hours</td>
              <td class="text-truncate">01 Jul 2024 | 280 Hours</td>
              <td class="text-truncate">580 Hours</td>
              <td class="text-truncate">
                <div class="d-flex align-items-center p-2" style="cursor: pointer; background-color: #ECEEED">
                  <i class="fa fa-download mr-2 text-primary" />
                  <span class="text-black">Attachment.pdf</span>
                </div>
              </td>
              <td class="text-center sticky-column">
                <b-dropdown
                  size="sm"
                  variant="dark"
                  toggle-class="text-decoration-none"
                  text="Actions"
                  dropright
                  no-caret
                >
                  <template v-slot:button-content>
                    Actions
                    <i class="fa fa-chevron-down ml-1"></i>
                  </template>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-tasks"></i> Maintenance</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-thumbs-up"></i> Approve</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="warning"><i class="fa fa-clock"></i> Postpone</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-search"></i> Review</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-pen"></i> Edit</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="danger"><i class="fa fa-tools"></i> Convert Repair</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">2</td>
              <td class="text-truncate">Lubricating Oil Cooler - BG. SML 104</td>
              <td class="text-truncate">Main Engine 1 - Lubricating Oil Cooler</td>
              <td class="text-truncate">
                <b-badge variant="success" class="p-2">
                  <span>Active</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">Corrective Maintenance</td>
              <td class="text-truncate text-center">Counter</td>
              <td class="text-truncate">300 Hours</td>
              <td class="text-truncate">01 Jul 2024 | 280 Hours</td>
              <td class="text-truncate">580 Hours</td>
              <td class="text-truncate">
                <div class="d-flex align-items-center p-2" style="cursor: pointer; background-color: #ECEEED">
                  <i class="fa fa-download mr-2 text-primary" />
                  <span class="text-black">Attachment.pdf</span>
                </div>
              </td>
              <td class="text-center sticky-column">
                <b-dropdown
                  size="sm"
                  variant="dark"
                  toggle-class="text-decoration-none"
                  text="Actions"
                  dropright
                  no-caret
                >
                  <template v-slot:button-content>
                    Actions
                    <i class="fa fa-chevron-down ml-1"></i>
                  </template>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-tasks"></i> Maintenance</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-thumbs-up"></i> Approve</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="warning"><i class="fa fa-clock"></i> Postpone</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-search"></i> Review</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-pen"></i> Edit</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="danger"><i class="fa fa-tools"></i> Convert Repair</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">3</td>
              <td class="text-truncate">Lubricating Oil Cooler - BG. SML 104</td>
              <td class="text-truncate">Main Engine 1 - Lubricating Oil Cooler</td>
              <td class="text-truncate">
                <b-badge variant="info" class="p-2">
                  <span>Towards</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">Corrective Maintenance</td>
              <td class="text-truncate text-center">Counter</td>
              <td class="text-truncate">300 Hours</td>
              <td class="text-truncate">01 Jul 2024 | 280 Hours</td>
              <td class="text-truncate">580 Hours</td>
              <td class="text-truncate">
                <div class="d-flex align-items-center p-2" style="cursor: pointer; background-color: #ECEEED">
                  <i class="fa fa-download mr-2 text-primary" />
                  <span class="text-black">Attachment.pdf</span>
                </div>
              </td>
              <td class="text-center sticky-column">
                <b-dropdown
                  size="sm"
                  variant="dark"
                  toggle-class="text-decoration-none"
                  text="Actions"
                  dropright
                  no-caret
                >
                  <template v-slot:button-content>
                    Actions
                    <i class="fa fa-chevron-down ml-1"></i>
                  </template>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-tasks"></i> Maintenance</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-thumbs-up"></i> Approve</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="warning"><i class="fa fa-clock"></i> Postpone</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-search"></i> Review</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-pen"></i> Edit</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="danger"><i class="fa fa-tools"></i> Convert Repair</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">4</td>
              <td class="text-truncate">Lubricating Oil Cooler - BG. SML 104</td>
              <td class="text-truncate">Main Engine 1 - Lubricating Oil Cooler</td>
              <td class="text-truncate">
                <b-badge variant="warning" class="p-2">
                  <span>Review</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">Corrective Maintenance</td>
              <td class="text-truncate text-center">Counter</td>
              <td class="text-truncate">300 Hours</td>
              <td class="text-truncate">01 Jul 2024 | 280 Hours</td>
              <td class="text-truncate">580 Hours</td>
              <td class="text-truncate">
                <div class="d-flex align-items-center p-2" style="cursor: pointer; background-color: #ECEEED">
                  <i class="fa fa-download mr-2 text-primary" />
                  <span class="text-black">Attachment.pdf</span>
                </div>
              </td>
              <td class="text-center sticky-column">
                <b-dropdown
                  size="sm"
                  variant="dark"
                  toggle-class="text-decoration-none"
                  text="Actions"
                  dropright
                  no-caret
                >
                  <template v-slot:button-content>
                    Actions
                    <i class="fa fa-chevron-down ml-1"></i>
                  </template>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-tasks"></i> Maintenance</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-thumbs-up"></i> Approve</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="warning"><i class="fa fa-clock"></i> Postpone</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-search"></i> Review</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-pen"></i> Edit</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="danger"><i class="fa fa-tools"></i> Convert Repair</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">5</td>
              <td class="text-truncate">Lubricating Oil Cooler - BG. SML 104</td>
              <td class="text-truncate">Main Engine 1 - Lubricating Oil Cooler</td>
              <td class="text-truncate">
                <b-badge variant="primary" class="p-2">
                  <span>Approved</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">Corrective Maintenance</td>
              <td class="text-truncate text-center">Counter</td>
              <td class="text-truncate">300 Hours</td>
              <td class="text-truncate">01 Jul 2024 | 280 Hours</td>
              <td class="text-truncate">580 Hours</td>
              <td class="text-truncate">
                <div class="d-flex align-items-center p-2" style="cursor: pointer; background-color: #ECEEED">
                  <i class="fa fa-download mr-2 text-primary" />
                  <span class="text-black">Attachment.pdf</span>
                </div>
              </td>
              <td class="text-center sticky-column">
                <b-dropdown
                  size="sm"
                  variant="dark"
                  toggle-class="text-decoration-none"
                  text="Actions"
                  dropright
                  no-caret
                >
                  <template v-slot:button-content>
                    Actions
                    <i class="fa fa-chevron-down ml-1"></i>
                  </template>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-tasks"></i> Maintenance</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-thumbs-up"></i> Approve</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="warning"><i class="fa fa-clock"></i> Postpone</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-search"></i> Review</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-pen"></i> Edit</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="danger"><i class="fa fa-tools"></i> Convert Repair</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">6</td>
              <td class="text-truncate">Lubricating Oil Cooler - BG. SML 104</td>
              <td class="text-truncate">Main Engine 1 - Lubricating Oil Cooler</td>
              <td class="text-truncate">
                <b-badge variant="dark" class="p-2">
                  <span>Finish</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">Corrective Maintenance</td>
              <td class="text-truncate text-center">Counter</td>
              <td class="text-truncate">300 Hours</td>
              <td class="text-truncate">01 Jul 2024 | 280 Hours</td>
              <td class="text-truncate">580 Hours</td>
              <td class="text-truncate">
                <div class="d-flex align-items-center p-2" style="cursor: pointer; background-color: #ECEEED">
                  <i class="fa fa-download mr-2 text-primary" />
                  <span class="text-black">Attachment.pdf</span>
                </div>
              </td>
              <td class="text-center sticky-column">
                <b-dropdown
                  size="sm"
                  variant="dark"
                  toggle-class="text-decoration-none"
                  text="Actions"
                  dropright
                  no-caret
                >
                  <template v-slot:button-content>
                    Actions
                    <i class="fa fa-chevron-down ml-1"></i>
                  </template>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-tasks"></i> Maintenance</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-thumbs-up"></i> Approve</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="warning"><i class="fa fa-clock"></i> Postpone</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="info"><i class="fa fa-search"></i> Review</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="primary"><i class="fa fa-pen"></i> Edit</b-dropdown-item>
                  <b-dropdown-item size="sm" href="#" variant="danger"><i class="fa fa-tools"></i> Convert Repair</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-row class="mt-4">
        <b-col col-6>
          Total: 1 &nbsp;
          | &nbsp; Per page:
          20
        </b-col>
        <b-col col-6>
          <b-pagination
            :total-rows="1"
            :per-page="20"
            align="right"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-col>
  </b-row> 
</template>
<script>
export default {
  name: 'HistoryRecordDetail',
  props: {
    title: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      collapse: true,

      statusList: [{
        name:'Active',
        code: 'active'
      },{
        name:'Towards',
        code: 'toward'
      },{
        name:'Overdue',
        code: 'overdue'
      },{
        name:'Next 3 Month',
        code: 'next-3-month'
      },{
        name:'Postponed',
        code: 'postponed'
      },{
        name:'Finish',
        code: 'finish'
      }],

      params:{
        all:false,
        selectedStatus:[]
      }
    }
  },
  methods:{
    onHandleCheck(){
      if(this.params.all){
        this.params.selectedStatus = [{
          name:'Active',
          code: 'active'
        },{
          name:'Towards',
          code: 'toward'
        },{
          name:'Overdue',
          code: 'overdue'
        },{
          name:'Next 3 Month',
          code: 'next-3-month'
        },{
          name:'Postponed',
          code: 'postponed'
        },{
          name:'Finish',
          code: 'finish'
        }]
      } else {
        this.params.selectedStatus = []
      }
    },
    onFilter(){
      console.log(this.params.selectedStatus, "this.selectedStatus")
    }
  }
}
</script>

<style scoped>
.sticky-column {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 9999999;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveRealInvoice.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Save Real Invoice  ")]), _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideRealInvoice
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Cancel  ")])], 1), _c('b-col', {
    staticClass: "p-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Real Invoice Number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "type": "text"
    },
    model: {
      value: _vm.form.real_invoice_number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "real_invoice_number", $$v);
      },
      expression: "form.real_invoice_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.invoice_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "invoice_date", $$v);
      },
      expression: "form.invoice_date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Due Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.due_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "due_date", $$v);
      },
      expression: "form.due_date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice File"
    }
  }, [_c('b-form-file', {
    staticClass: "border p-2",
    attrs: {
      "accept": "application/*",
      "plain": "",
      "multiple": "",
      "name": "file",
      "id": "attactment_file"
    },
    model: {
      value: _vm.form.attachment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "attachment", $$v);
      },
      expression: "form.attachment"
    }
  }), _vm.real_invoice_attachment.length > 0 ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v("Existed File: ")]), _vm._l(_vm.real_invoice_attachment, function (file, fileIndex) {
    return _c('div', {
      key: fileIndex,
      staticClass: "d-flex align-items-center mb-2",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_c('i', {
      staticClass: "fa fa-file text-primary mr-2"
    }), _c('span', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s(file.file_name))])]);
  })], 2) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Invoice"
    }
  }, [_c('b-form-select', {
    staticClass: "form-control",
    attrs: {
      "plain": "",
      "name": "status"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("select...")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": "request"
    }
  }, [_vm._v("Request")]), _c('b-form-select-option', {
    attrs: {
      "value": "paid"
    }
  }, [_vm._v("Paid")]), _c('b-form-select-option', {
    attrs: {
      "value": "canceled"
    }
  }, [_vm._v("Canceled")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-4",
    staticStyle: {
      "background": "rgb(241 245 249)",
      "min-height": "calc(100vh - 60px)"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', {}, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          style: {
            width: '100%'
          },
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          },
          model: {
            value: _vm.params.status,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "status", $$v);
            },
            expression: "params.status"
          }
        }, [_c('b-form-select-option', {
          attrs: {
            "value": "all"
          }
        }, [_vm._v("All")]), _c('b-form-select-option', {
          attrs: {
            "value": "progress"
          }
        }, [_vm._v("Progress")]), _c('b-form-select-option', {
          attrs: {
            "value": "completed"
          }
        }, [_vm._v("Completed")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter Option",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          },
          model: {
            value: _vm.params.filterOption,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "filterOption", $$v);
            },
            expression: "params.filterOption"
          }
        }, [_c('b-form-select-option', {
          attrs: {
            "value": "all"
          }
        }, [_vm._v("All")]), _c('b-form-select-option', {
          attrs: {
            "value": "by-project"
          }
        }, [_vm._v("By Project")]), _c('b-form-select-option', {
          attrs: {
            "value": "by-fleet"
          }
        }, [_vm._v("By Fleet")])], 1)], 1)], 1), _vm.params.filterOption == 'by-fleet' ? _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Name",
            "label-for": "fleet-categtory"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "id": "fleet-category",
            "multiple": true,
            "placeholder": "Select Categories",
            "label": "name",
            "options": _vm.fleetNameOpt,
            "track-by": "code"
          },
          model: {
            value: _vm.params.vehicleIds,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "vehicleIds", $$v);
            },
            expression: "params.vehicleIds"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1) : _vm._e(), _vm.params.filterOption == 'by-project' ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Project Name",
            "label-for": "fleet-categtory"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "id": "fleet-category",
            "multiple": true,
            "placeholder": "Select Categories",
            "label": "name",
            "options": _vm.projectNameOpt,
            "track-by": "code"
          },
          model: {
            value: _vm.params.projectIds,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "projectIds", $$v);
            },
            expression: "params.projectIds"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1) : _vm._e(), _c('b-col', {
          staticClass: "d-flex align-items-end",
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          staticClass: "w-100"
        }, [_c('b-button', {
          staticClass: "w-100",
          attrs: {
            "variant": "primary",
            "type": "button"
          },
          on: {
            "click": _vm.fetchVoyageByTrips
          }
        }, [_c('i', {
          staticClass: "fas fa-search"
        }), _vm._v(" Show Data ")])], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex justify-content-between align-items-center mb-3"
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_c('strong', [_vm._v("Operational Data Running Shipment")])]), _c('b-button', {
          staticClass: "mx-2 d-flex",
          attrs: {
            "variant": "outline-primary",
            "size": "md"
          }
        }, [_c('i', {
          staticClass: "fa fa-download"
        }), _vm._v(" Export ")])], 1), _c('div', {
          staticClass: "overflow-auto",
          staticStyle: {
            "max-height": "700px"
          }
        }, [_vm.list.length > 0 ? _vm._l(_vm.list, function (state, index) {
          var _state$projectSub, _state$projectSub$pro, _state$projectSub2, _state$projectSub3;
          return _c('div', {
            key: index,
            staticStyle: {
              "max-height": "600px"
            }
          }, [_c('div', {
            staticClass: "d-flex w-100 p-3",
            staticStyle: {
              "background-color": "#EDEDED",
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleRow("list-".concat(index));
              }
            }
          }, [_c('h5', {
            staticClass: "text-truncate"
          }, [_c('strong', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$projectSub = state.projectSub) === null || _state$projectSub === void 0 ? void 0 : (_state$projectSub$pro = _state$projectSub.project) === null || _state$projectSub$pro === void 0 ? void 0 : _state$projectSub$pro.projectName) + " - " + _vm._s(state === null || state === void 0 ? void 0 : (_state$projectSub2 = state.projectSub) === null || _state$projectSub2 === void 0 ? void 0 : _state$projectSub2.name))])])]), _vm.isRowVisible("list-".concat(index)) ? _c('div', {
            staticClass: "overflow-auto",
            class: {
              'collapsed-row': !_vm.isRowVisible("list-".concat(index)),
              'expanded-row': _vm.isRowVisible("list-".concat(index))
            }
          }, _vm._l(state === null || state === void 0 ? void 0 : (_state$projectSub3 = state.projectSub) === null || _state$projectSub3 === void 0 ? void 0 : _state$projectSub3.trip, function (trip, tripIndex) {
            var _trip$tripRecord, _trip$tripRecord2, _trip$tripRecord3, _trip$tripRecord4, _trip$tripRecord5, _trip$tripRecord6, _trip$tripRecord7, _trip$tripRecord8, _trip$tripRecord9, _trip$tripRecord10, _trip$tripRecord11, _trip$tripRecord12, _trip$tripRecord13, _trip$tripRecord14, _trip$tripRecord15, _trip$tripRecord16, _trip$tripRecord17, _trip$tripRecord18, _trip$tripRecord19, _trip$tripRecord20, _trip$tripRecord21;
            return _c('table', {
              key: tripIndex,
              staticClass: "table table-borderless table-hover"
            }, [_c('thead', [_c('tr', _vm._l(_vm.columns, function (column, index) {
              return _c('th', {
                key: index,
                staticClass: "text-truncate column-primary"
              }, [_vm._v(_vm._s(column))]);
            }), 0)]), _c('tbody', [_c('tr', [_c('td', {
              staticClass: "text-truncate"
            }, _vm._l(trip.vehicle, function (vehicle, vehicleIndex) {
              return _c('b-badge', {
                staticClass: "mr-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v(_vm._s(vehicle === null || vehicle === void 0 ? void 0 : vehicle.name))]);
            }), 1), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s((trip === null || trip === void 0 ? void 0 : (_trip$tripRecord = trip.tripRecord) === null || _trip$tripRecord === void 0 ? void 0 : _trip$tripRecord.portLoading) || (trip === null || trip === void 0 ? void 0 : (_trip$tripRecord2 = trip.tripRecord) === null || _trip$tripRecord2 === void 0 ? void 0 : _trip$tripRecord2.portLoadingOther)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s((trip === null || trip === void 0 ? void 0 : (_trip$tripRecord3 = trip.tripRecord) === null || _trip$tripRecord3 === void 0 ? void 0 : _trip$tripRecord3.portDischarge) || (trip === null || trip === void 0 ? void 0 : (_trip$tripRecord4 = trip.tripRecord) === null || _trip$tripRecord4 === void 0 ? void 0 : _trip$tripRecord4.portDischargeOther)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s((trip === null || trip === void 0 ? void 0 : (_trip$tripRecord5 = trip.tripRecord) === null || _trip$tripRecord5 === void 0 ? void 0 : _trip$tripRecord5.polGrabType) || (trip === null || trip === void 0 ? void 0 : (_trip$tripRecord6 = trip.tripRecord) === null || _trip$tripRecord6 === void 0 ? void 0 : _trip$tripRecord6.podGrabType)))]), _c('td', {
              staticClass: "text-truncate text-right text-primary"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord7 = trip.tripRecord) === null || _trip$tripRecord7 === void 0 ? void 0 : _trip$tripRecord7.cargoUnloading))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord8 = trip.tripRecord) === null || _trip$tripRecord8 === void 0 ? void 0 : _trip$tripRecord8.status))]), _c('td', {
              staticClass: "text-truncate"
            }, [_c('b-button', {
              attrs: {
                "variant": "primary"
              },
              on: {
                "click": function click($event) {
                  var _state$projectSub4, _state$projectSub4$pr, _state$projectSub5;
                  return _vm.handleOpenFreight(trip, "".concat(state === null || state === void 0 ? void 0 : (_state$projectSub4 = state.projectSub) === null || _state$projectSub4 === void 0 ? void 0 : (_state$projectSub4$pr = _state$projectSub4.project) === null || _state$projectSub4$pr === void 0 ? void 0 : _state$projectSub4$pr.projectName, " - ").concat(state === null || state === void 0 ? void 0 : (_state$projectSub5 = state.projectSub) === null || _state$projectSub5 === void 0 ? void 0 : _state$projectSub5.name));
                }
              }
            }, [_c('i', {
              staticClass: "fa fa-plus"
            }), _vm._v(" Input Freight ")])], 1), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord9 = trip.tripRecord) === null || _trip$tripRecord9 === void 0 ? void 0 : _trip$tripRecord9.polArrivalTime))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Waiting for Berthing POL', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Waiting for Loading', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord10 = trip.tripRecord) === null || _trip$tripRecord10 === void 0 ? void 0 : _trip$tripRecord10.polCommenced))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord11 = trip.tripRecord) === null || _trip$tripRecord11 === void 0 ? void 0 : _trip$tripRecord11.polCompleted))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Loading', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord12 = trip.tripRecord) === null || _trip$tripRecord12 === void 0 ? void 0 : _trip$tripRecord12.polCastOut))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord13 = trip.tripRecord) === null || _trip$tripRecord13 === void 0 ? void 0 : _trip$tripRecord13.polLhv))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Waiting LHV', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord14 = trip.tripRecord) === null || _trip$tripRecord14 === void 0 ? void 0 : _trip$tripRecord14.polDocOnBoard))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Waiting Document POL', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord15 = trip.tripRecord) === null || _trip$tripRecord15 === void 0 ? void 0 : _trip$tripRecord15.polDeparture))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Waiting Departure', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord16 = trip.tripRecord) === null || _trip$tripRecord16 === void 0 ? void 0 : _trip$tripRecord16.podArrivalTime))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('POL Steaming Time', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord17 = trip.tripRecord) === null || _trip$tripRecord17 === void 0 ? void 0 : _trip$tripRecord17.podAnchorUp))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord18 = trip.tripRecord) === null || _trip$tripRecord18 === void 0 ? void 0 : _trip$tripRecord18.podBerthing))]), _c('td', {
              staticClass: "text-truncate"
            }), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Waiting for Berthing', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Discharge', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord19 = trip.tripRecord) === null || _trip$tripRecord19 === void 0 ? void 0 : _trip$tripRecord19.podCastOut))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Waiting Full Away', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord20 = trip.tripRecord) === null || _trip$tripRecord20 === void 0 ? void 0 : _trip$tripRecord20.podDeparture))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : (_trip$tripRecord21 = trip.tripRecord) === null || _trip$tripRecord21 === void 0 ? void 0 : _trip$tripRecord21.podArrivalTime))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('POD Steaming Time', index, tripIndex)))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.matrixData('Total Steaming', index, tripIndex)))])])])]);
          }), 0) : _vm._e()]);
        }) : _c('div', {
          staticClass: "d-flex align-items-center justify-content-center w-100 p-3"
        }, [_c('h6', {
          staticClass: "text-muted"
        }, [_vm._v("No Data Found. try change filter period"), _c('strong')])])], 2)];
      },
      proxy: true
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "input-freight-rate",
      "title": "Input Freight Rate",
      "centered": "",
      "size": "xl",
      "title-class": "title-modal",
      "hide-footer": ""
    },
    model: {
      value: _vm.modal.freightRate,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "freightRate", $$v);
      },
      expression: "modal.freightRate"
    }
  }, [_c('FormFreightRate', {
    attrs: {
      "refreshData": _vm.refreshData,
      "fleets": _vm.fleetNameOpt,
      "tripData": _vm.selectedTrip,
      "projectName": _vm.selectedProject
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h6 class="card-title"><strong>BLACK LIST CREW</strong></h6>
      </template>
      <template v-slot:body>
        <b-collapse visible id="collapse-filter" class="mb-2">
          <b-row>
            <b-col md="2">
              <b-form-group label="Search" label-for="search">
                <b-form-input
                  v-model="blackList.params.full_name"
                  type="text"
                  placeholder="Ketikan Nama"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Crew Status" label-for="jabatan">
                <b-form-select plain :options="[]" size="lg">
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >Pilih Jabatan</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Perusahaan" label-for="perusahaan">
                <b-form-select id="perusahaan" plain :options="[]" size="lg">
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >Pilih Jabatan</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Jabatan" label-for="jabatan">
                <b-form-select
                  id="jabatan"
                  plain
                  v-model="blackList.params.position_id"
                  :options="optionsPosition"
                  size="lg"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >Pilih Jabatan</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Filter" label-for="button_search">
                <button
                  @click="getBlackList(null)"
                  style="background: #2bdcaa"
                  class="btn mb-3 text-white"
                  ><i class="fa fa-search"></i
                ></button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>
        <div class="d-flex  w-100 justify-content-end my-3">
          <!-- <span class="mr-1">Total Blacklist: </span>
          <strong>{{ blackList.meta.total }}</strong> -->
          <b-button
            variant="primary"
            class="mr-2"
            @click="exportBlacklist()"
            :disabled="blackList.loading"
          >
            <span
              v-if="blackList.loading"
              class="spinner-border spinner-border-sm"
            />
            <div v-else class="d-flex gap-2 align-items-center">
              <span>Export Blacklist</span>
              <i class="fa fa-upload ml-2"></i>
            </div>
          </b-button>
        </div>
        <div class="d-flex overflow-auto">
          <table class="table table-striped">
            <thead>
              <tr>
                <!-- <th class="text-left" style="max-width: 100px">PHOTO</th> -->
                <th class="text-center text-truncate bg-tosca">No</th>
                <th class="text-truncate bg-tosca">Crew Name</th>
                <th class="text-truncate bg-tosca">Seafer Code</th>
                <th class="text-truncate bg-tosca">NIP</th>
                <th class="text-truncate bg-tosca">Position</th>
                <th class="text-truncate bg-tosca">Perusahan</th>
                <th class="text-truncate bg-tosca">Remarks</th>
                <th class="text-truncate bg-tosca">Port</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in blackList.data" :key="index">
                <td class="text-truncate text-center">{{ index + 1 }}</td>
                <td class="text-truncate text-left">
                  <router-link
                    :to="{
                      path: `profile`,
                      query: {
                        user: 'candidate',
                        id: data.id,
                      },
                    }"
                  >
                    <div class="d-flex align-items-center">
                      <b-avatar
                        size="34"
                        variant="warning"
                        :src="
                          data.photo ||
                          require('../../assets/images/page-img/10.jpg')
                        "
                      ></b-avatar>
                      <h6 class="ml-2">{{ data?.personal_data?.full_name }}</h6>
                    </div>
                  </router-link>
                </td>
                <td class="text-left text-truncate">{{
                  data?.personal_data?.seaferer_code ?? '-'
                }}</td>
                <td class="text-left text-truncate">{{ data?.nip ?? '-' }} </td>
                <td class="text-left text-truncate"
                  >{{ data?.position?.name ?? '-' }}
                </td>
                <td class="text-left text-truncate">{{
                  data?.company?.company ?? '-'
                }}</td>
                <td>{{ data?.remarks ?? 'Tanpa keterangan' }}</td>
                <td>{{ data?.port ?? '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3" v-if="blackList.data.length > 0">
          <b-pagination
            v-model="blackList.meta.currentPage"
            :total-rows="blackList.meta.total"
            :per-page="blackList.meta.perPage"
            first-number
            @change="getBlackList"
            align="center"
          />
        </div>
        <p v-else class="text-left my-4">List blacklist belum ada.</p>
      </template>
    </iq-card>
  </div>
</template>

<script>
import { crewingActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'ContentBlacklistCrew',
  props: {
    optionsPosition: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      blackList: {
        params: {
          full_name: '',
          status: null,
          from: null,
          to: null,
          position_id: null,
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
    }
  },
  created() {
    this.getBlackList = _.debounce(this.getBlackList, 500)
  },
  async mounted() {
    this.getBlackList()
  },

  methods: {
    ...crewingActions,
    async getBlackList(page = 1) {
      const params = {
        page: page || 1,
      }

      for (const key in this.blackList.params) {
        if (this.blackList.params[key]) {
          params[key] = this.blackList.params[key]
        }
      }

      const { status, data } = await this.fetchBlackListCrew(params)

      if (status === 'success') {
        this.blackList.meta.perPage = data.per_page
        this.blackList.meta.currentPage = data.current_page
        this.blackList.meta.total = data.total
        this.blackList.meta.totalPage = Math.round(data.total / data.per_page)
        if (data.data) {
          this.blackList.data = data.data
        }
      }
    },

    async exportBlacklist() {
      this.blackList.loading = true
      let params = {
        ...this.blackList.params,
        crew: 1,
      }

      if (!params.position_id) delete params.position_id
      if (!params.full_name) delete params.full_name

      const res = await this.exportListBlacklist({ params })

      if (
        res.status === 404 ||
        res.status === 500 ||
        res.status === 400 ||
        res.status === 401
      ) {
        this.$swal(`Oops!`, `Something went wrong.`, 'error')
        this.blackList.loading = false
        return
      }

      saveAs(res.data, 'List Blacklist.xlsx')
      this.blackList.loading = false
    },
  },
}
</script>
<style scoped>
.bg-tosca {
  background: #334697 !important;
  color: white !important;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ratings.length ? _c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tr', [_c('th', {
    attrs: {
      "bgcolor": "#faebd7",
      "width": "70%"
    }
  }, [_vm._v(" " + _vm._s(_vm.headerTitle) + " ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "30%"
    }
  }, [_vm._v(" RATING ")])]), _c('tbody', [_vm._l(_vm.ratings, function (item, i) {
    return [_c('tr', {
      key: "detail-rating-" + i
    }, [_c('td', {
      attrs: {
        "rowspan": item.rating.length
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('td', {
      staticClass: "text-center text-white",
      attrs: {
        "bgcolor": item.rating[0].color
      }
    }, [_vm._v(_vm._s(item.rating[0].value))])]), _vm._l(item.rating, function (rate, ratingIdx) {
      return [ratingIdx > 0 ? _c('tr', {
        key: "rating-".concat(ratingIdx, "-").concat(i)
      }, [_c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": rate.color
        }
      }, [_vm._v(" " + _vm._s(rate.value) + " ")])]) : _vm._e()];
    })];
  })], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from './auth'

const VOYAGE_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/voyage`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getVoyageHistories({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/histories`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getVoyageUnfinishedTrip({ commit }, params) {
    const url = `${VOYAGE_BASE_URL}/trips/progress`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getVoyageTrips({ commit }, params = {}) {
    const url = `${VOYAGE_BASE_URL}/trips`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  }, 
  async getVoyageTripsByPeriod({ commit }, params = {}) {
    const url = `${VOYAGE_BASE_URL}/trip-records`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getVoyageMatrixBySystem({ commit }, params = {}) {
    const url = `${VOYAGE_BASE_URL}/${params.vehicleId}/matrix-by-system`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: params.params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getVoyageTripDetail({ commit }, params) {
    const url = `${VOYAGE_BASE_URL}/${params.vehicleId}/trips/${params.tripId}`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  /*async getVoyageHistoriesExport({ commit }, payload) {
    const paramsUrls = new URLSearchParams(payload.params).toString();
    const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/export?${paramsUrls}`;

    return {
      status: 'success',
      data: url,
    };

  },*/
  async getVoyageHistoriesExport({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/export`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      responseType: 'arraybuffer',
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async getVoyageLastActivity({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/last`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getVoyageMatrix({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/matrix`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getSCL({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/scl`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getSCLById({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/scl/${payload.id}`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async checkVehicleVoyage({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/check`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload.data,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async createVoyageActivity({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/2/${payload.id}`;

    const method = "post";
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };
    const config = {
        url,
        method,
        data: payload.data,
        headers
    };

    try {
    const response = await axios(config);
    let data = response.data.data;
    return {
        status: 'success',
        data,
    };
    } catch (e) {
    return {
        status: 'error',
        data: e.response.data
    };
    }
},
async createSCL({ commit }, payload) {
  const url = `${VOYAGE_BASE_URL}/${payload.id}/scl`;

  const method = "post";
  const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
  };
  const config = {
      url,
      method,
      data: payload.data,
      headers
  };

  try {
  const response = await axios(config);
  let data = response.data.data;
  return {
      status: 'success',
      data,
  };
  } catch (e) {
  return {
      status: 'error',
      data: e.response.data
  };
  }
},
async deleteActivityById({ commit }, payload) {
  const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/${payload.id}`;

  const method = "delete";
  const headers = {};
  const config = {
      url,
      method,
      headers
  };

  try {
      await axios(config);
      return { status: 'success' };
  } catch (e) {
      return {
          status: 'error',
          data: e.response.data
      };
  }
},
async deleteSCLById({ commit }, payload) {
  const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/scl/${payload.id}`;

  const method = "delete";
  const headers = {};
  const config = {
      url,
      method,
      headers
  };

  try {
      await axios(config);
      return { status: 'success' };
  } catch (e) {
      return {
          status: 'error',
          data: e.response.data
      };
  }
},
async getActivityById({ commit }, payload) {
  const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/${payload.id}`;

  const method = "get";
  const headers = {};
  const config = {
      url,
      method,
      headers
  };

  try {
    const response = await axios(config);
    let data = response.data.data;
    return {
      status: 'success',
      data,
    };
  } catch (e) {
    return {
      status: 'error',
      data: e.response.data
    };
  }
},
async updateActivity({ commit }, payload) {
  const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/${payload.id}`;

  const method = "put";
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    url,
    method,
    data: payload.data,
    headers
  };

  try {
    const response = await axios(config);
    let data = response.data.data;
    return {
      status: 'success',
      data,
    };
  } catch (e) {
    return {
      status: 'error',
      data: e.response.data
    };
  }
},

async updateTripRecordById({ commit }, payload) {
  const url = `${VOYAGE_BASE_URL}/trips/${payload.id}`;

  const method = "put";
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    url,
    method,
    data: payload.data,
    headers
  };

  try {
    const response = await axios(config);
    let data = response.data.data;
    return {
      status: 'success',
      data,
    };
  } catch (e) {
    return {
      status: 'error',
      data: e.response.data
    };
  }
},
async deleteTripRecordById({ commit }, payload = {}) {
  const url = `${VOYAGE_BASE_URL}/trips/${payload.id}`;

  const method = "delete";
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    url,
    method,
    headers
  };

  try {
    const response = await axios(config);
    let data = response.data.data;
    return {
      status: 'success',
      data,
    };
  } catch (e) {
    return {
      status: 'error',
      data: e.response.data
    };
  }
},

async updateSCL({ commit }, payload) {
  const url = `${VOYAGE_BASE_URL}/${payload.vehicleId}/scl/${payload.id}`;

  const method = "put";
  const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
  };
  const config = {
      url,
      method,
      data: payload.data,
      headers
  };

  try {
  const response = await axios(config);
  let data = response.data.data;
  return {
      status: 'success',
      data,
  };
  } catch (e) {
  return {
      status: 'error',
      data: e.response.data
  };
  }
},
  // async getFleetById({ commit }, id) {
  //   const url = `${VOYAGE_BASE_URL}/${id}`;

  //   const method = "get";
  //   const headers = {};
  //   const config = {
  //     url,
  //     method,
  //     headers
  //   };

  //   try {
  //     const response = await axios(config);
  //     let data = response.data.data;
  //     return {
  //       status: 'success',
  //       data,
  //     };
  //   } catch (e) {
  //     return {
  //       status: 'error',
  //       data: e.response.data
  //     };
  //   }
  // },
  // async getFleetTypes({ commit }, params = {}) {
  //   const url = FLEET_TYPE_BASE_URL;

  //   const method = "get";
  //   const headers = {};
  //   const config = {
  //     url,
  //     method,
  //     headers,
  //     params
  //   };

  //   try {
  //     const response = await axios(config);
  //     let data = response.data.data;
  //     return {
  //       status: 'success',
  //       data,
  //     };
  //   } catch (e) {
  //     return {
  //       status: 'error',
  //       data: e.response.data
  //     };
  //   }
  // },
  // async updateFleetType({ commit }, payload) {
  //   const url = `${FLEET_TYPE_BASE_URL}/${payload.id}`;
  //   let urlencoded = new URLSearchParams()
  //   urlencoded.append('name', payload.data.name)
  //   urlencoded.append('icon', payload.data.icon)
  //   urlencoded.append('active', payload.data.active)

  //   const method = "put";
  //   const headers = {
  //     "Content-Type": "application/x-www-form-urlencoded"
  //   };
  //   const config = {
  //     url,
  //     method,
  //     data: urlencoded,
  //     headers
  //   };

  //   try {
  //     const response = await axios(config);
  //     let data = response.data.data;
  //     return {
  //       status: 'success',
  //       data,
  //     };
  //   } catch (e) {
  //     return {
  //       status: 'error',
  //       data: e.response.data
  //     };
  //   }
  // },
  // async deleteFleetType({ commit }, payload) {
  //   const url = `${FLEET_TYPE_BASE_URL}/${payload}`;

  //   const method = "delete";
  //   const headers = {};
  //   const config = {
  //     url,
  //     method,
  //     headers
  //   };

  //   try {
  //     await axios(config);
  //     return { status: 'success' };
  //   } catch (e) {
  //     return {
  //       status: 'error',
  //       data: e.response.data
  //     };
  //   }
  // },
  async getFcPeriod ({ commit }, params) {
    const url = `${VOYAGE_BASE_URL}/fc/${params.vehicleId}`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getFcDashboardPeriod ({ commit }, params) {
    const url = `${VOYAGE_BASE_URL}/fc/${params.vehicleId}/dashboard`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async saveFC ({ commit }, { method, data }) {
    console.log('data', data)

    const url = `${VOYAGE_BASE_URL}/fc/${data.vehicleId}`
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    }

    const config = {
      url,
      method,
      data,
      headers
    }

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getIdle({ commit }, payload) {
    const url = `${VOYAGE_BASE_URL}/fc/idle`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

}

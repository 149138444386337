<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#operational_tab"
                  :title="`${fleet.name} - OPERATIONAL HISTORY`"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#scl_tab"
                  :title="`${fleet.name} - SCL (SOUNDING CHECK LIST)`"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <!-- Operational -->
      <tab-content-item :active="true" id="operational_tab">
        <!-- Top Section !-->
        <b-row>
          <!-- <b-col md="12">
            <div class="iq-edit-list">
              <tab-nav
                :pills="true"
                class="nav-fill mb-3 d-flex w-100 justify-content-end align-items-center rounded-0"
              >
                <tab-nav-items
                  class="col-md-2 p-0 bg-white text-truncate"
                  :active="true"
                  @click.native="tab = 'monthly'"
                  href="#monthly"
                  title="Monthly Voyage"
                />
                <tab-nav-items
                  class="col-md-2 p-0 bg-white text-truncate"
                  :active="false"
                  @click.native="tab = 'by-trip'"
                  href="#by-trip"
                  title="Voyage by Trip"
                />
              </tab-nav>
            </div>
          </b-col> -->

          <b-col md="12">
            <iq-card>
              <template #body>
                <CustomNav :items="voyageTabOpt" @item-click="(val)=> voyageTab = val" />
                <div class="d-flex justify-content-end mt-3">
                  <router-link
                    :to="{
                      name: 'dashboard.operational-input',
                      query: { vehicleId: $route.query.vehicleId },
                    }"
                  >
                    <button
                      class="btn btn-primary mb-3"
                      v-if="
                        lsProfile.menu[1].child[0].child[1].add &&
                        lsProfile.menu[1].child[0].child[1].add ===
                          true &&
                        !isChildComponent
                      "
                      ><i class="fa fa-plus"></i> Add Operational
                      Data</button
                    >
                  </router-link>
                </div>
              </template>
            </iq-card>
          </b-col>

          <b-col md="12">
            <div v-if="voyageTab == 'monthly'">
              <b-row>
                <b-col lg="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title text-primary"
                        ><strong
                          >OPERATIONAL HISTORY - {{ fleet.name }}</strong
                        ></h4
                      >
                    </template>
                    <template v-slot:body>
                      <b-row>
                        <b-col md="6">
                          <b-row>
                            <b-col md="8">
                              <date-picker
                                v-model="historiesParamsDate"
                                type="datetime"
                                range
                                placeholder="Select date range"
                                format="DD-MM-YYYY HH:mm"
                              ></date-picker>
                            </b-col>
                            <b-col md="4">
                              <b-button
                                variant="primary"
                                class="mb-3 mr-1"
                                @click="onFilterMonthly()"
                                ><i class="fa fa-filter"></i
                              ></b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="6" class="text-right">
                          <button
                            class="btn btn-dark mb-3 mr-1"
                            @click="exportVoyageHistories()"
                            v-if="
                              lsProfile.menu[1].child[0].child[1].export &&
                              lsProfile.menu[1].child[0].child[1].export ===
                                true
                            "
                            ><i class="fa fa-file-excel"></i> Export</button
                          >
                        </b-col>
                        <b-col md="12">
                          <hr />
                          <b-alert
                            show
                            variant="warning"
                            v-if="voyageMatrix.missingDuration"
                            >{{ voyageMatrix.missingDuration }}, Not yet
                            input</b-alert
                          >
                          <div
                            v-if="loading_voyage_history"
                            class="text-center my-5"
                          >
                            <b-spinner
                              type="grow"
                              label="Spinning"
                            ></b-spinner>
                          </div>
                          <template v-else>
                            <template v-if="voyageHistories.length > 0">
                              <div
                                class="overflow-auto tableFixHead"
                                style="max-height: 450px"
                              >
                                <table
                                  class="table mb-0 table-striped table-hover"
                                >
                                  <thead
                                    class="thead-dark text-center"
                                    style="z-index: 50; position: relative"
                                  >
                                    <tr>
                                      <th class="text-center">No</th>
                                      <th class="text-truncate" style="width: 200px;">Type Voyage</th>
                                      <th class="text-center text-truncate"
                                        >Project</th
                                      >
                                      <th class="text-center text-truncate"
                                        >Start Date</th
                                      >
                                      <th class="text-center text-truncate"
                                        >End Date</th
                                      >
                                      <th class="text-center text-truncate"
                                        >Reason</th
                                      >
                                      <th class="text-center text-truncate"
                                        >Hours</th
                                      >
                                      <th class="text-center text-truncate"
                                        >Cargo Loading</th
                                      >
                                      <th class="text-center text-truncate"
                                        >Cargo Unloading</th
                                      >
                                      <!-- <th class="text-center" v-if="showPortAgentPol">POL</th> -->
                                      <!-- <th class="text-center" v-if="showPortAgentPod">POD</th> -->
                                      <th class="text-center">POL</th>
                                      <th class="text-center">POD</th>
                                      <th class="text-center text-truncate"
                                        >Current Position</th
                                      >
                                      <th class="text-center">Remarks</th>
                                      <th
                                        class="text-center"
                                        v-if="showLatitude && showLongtitude"
                                        >GPS</th
                                      >
                                      <th class="text-center" v-if="showSpeed"
                                        >Speed</th
                                      >
                                      <th
                                        v-if="
                                          (lsProfile.menu[1].child[0].child[1]
                                            .delete === true ||
                                            lsProfile.menu[1].child[0]
                                              .child[1].update === true) &&
                                          !isChildComponent
                                        "
                                        >#</th
                                      >
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in voyageHistories"
                                      :key="`h-${index}`"
                                      style="font-size: 10pt"
                                    >
                                      <td class="text-center">{{
                                        (index += 1)
                                      }}</td>
                                      <td class="text-truncate">
                                        <div class="d-flex flex-column">
                                          <span v-for="(v,v_index) in item.vehicle" :key="`h-v-${v_index}`">
                                            <template v-if="v.id != vehicleId">
                                              {{ v.name }}
                                            </template>
                                          </span>
                                        </div>
                                        <br v-if="isBreakdown(item)" />
                                        <strong
                                          class="text-danger"
                                          v-if="isBreakdown(item)"
                                          >Breakdown</strong
                                        >
                                      </td>
                                      <td class="text-center">
                                        <p class="mb-0 text-truncate"
                                          >{{
                                            item.subProject.project
                                              .projectName
                                          }}
                                          - {{ item.subProject.name }}</p
                                        >
                                      </td>
                                      <td class="text-center text-truncate">
                                        {{
                                          item.startDate
                                            ? moment(
                                                item.startDate,
                                                'DD-MM-YYYY HH:mm'
                                              ).format('DD MMM YYYY HH:mm')
                                            : '-'
                                        }}
                                      </td>
                                      <td class="text-center text-truncate">
                                        {{
                                          item.endDate
                                            ? moment(
                                                item.endDate,
                                                'DD-MM-YYYY HH:mm'
                                              ).format('DD MMM YYYY HH:mm')
                                            : '-'
                                        }}
                                      </td>
                                      <td class="text-center text-truncate">{{
                                        item.reason.name
                                      }}</td>
                                      <td class="text-center text-truncate">
                                        {{ item.activityDurationFormat }}
                                      </td>
                                      <td class="text-center text-truncate">{{
                                        item.cargoLoading
                                          ? numberFormat(item.cargoLoading)
                                          : '-'
                                      }}</td>
                                      <td class="text-center text-truncate">{{
                                        item.cargoUnloading
                                          ? numberFormat(item.cargoUnloading)
                                          : '-'
                                      }}</td>
                                      <td
                                        v-if="showPortAgentPol"
                                        class="text-center text-truncate"
                                      >
                                        <p class="mb-0">{{
                                          item.portLoading.portName
                                        }}</p>
                                      </td>
                                      <td
                                        v-if="showPortAgentPod"
                                        class="text-center text-truncate"
                                      >
                                        <p class="mb-0">{{
                                          item.portDischarge.portName
                                        }}</p>
                                      </td>
                                      <td class="text-center text-truncate">{{
                                        item.currentPosition
                                      }}</td>
                                      <td class="text-center">{{
                                        item.remark
                                      }}</td>
                                      <td
                                        v-if="showLatitude && showLongtitude"
                                        class="text-truncate"
                                        >{{ item.dmsLatitude || '-' }},
                                        {{ item.dmsLongitude || '-' }}</td
                                      >
                                      <td
                                        v-if="showSpeed"
                                        class="text-center"
                                        >{{ item.speed }}</td
                                      >
                                      <td
                                        style="
                                          position: sticky;
                                          right: 0;
                                          z-index: 1020;
                                          background: white;
                                        "
                                        v-if="
                                          (lsProfile.menu[1].child[0].child[1]
                                            .delete === true ||
                                            lsProfile.menu[1].child[0]
                                              .child[1].update === true) &&
                                          !isChildComponent
                                        "
                                      >
                                        <div class="d-flex flex-column">
                                          <b-button
                                            variant="success"
                                            size="sm"
                                            class="mb-2"
                                            @click="toEditHistory(item.id)"
                                            v-if="
                                              lsProfile.menu[1].child[0]
                                                .child[1].update === true
                                            "
                                            ><i class="fa fa-edit"></i
                                          ></b-button>
                                          <b-button
                                            variant="danger"
                                            size="sm"
                                            @click="onDeleteActivity(item)"
                                            v-if="
                                              lsProfile.menu[1].child[0]
                                                .child[1].delete === true
                                            "
                                          >
                                            <i class="fa fa-trash"></i>
                                          </b-button>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </template>
                            <p v-else class="text-center my-4">
                              No voyage histories data found.
                            </p>
                          </template>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>
                <b-col
                  lg="12"
                  v-if="
                    voyageMatrix &&
                    voyageMatrix.activities &&
                    voyageMatrix.activities.length > 0
                  "
                >
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title text-primary"
                        ><strong
                          >OPERATIONAL MATRIX - {{ fleet.name }}</strong
                        ></h4
                      >
                    </template>
                    <template v-slot:body>
                      <b-row>
                        <b-col md="12">
                          <div class="table-responsive">
                            <table
                              class="table mb-0 table-striped table-hover"
                              style="width: 2200px"
                            >
                              <thead class="text-center">
                                <tr>
                                  <th>No</th>
                                  <th>Date</th>
                                  <th
                                    v-for="(
                                      reason, index
                                    ) in voyageMatrix.activities"
                                    :key="index"
                                    class="text-truncate"
                                    >{{ reason.name }}</th
                                  >
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-if="voyageHistories.length > 0">
                                  <td class="text-center">1</td>
                                  <td class="text-center text-truncate"
                                    >{{
                                      voyageMatrix.startDate
                                        ? formatDate(voyageMatrix.startDate)
                                        : ''
                                    }}
                                    -
                                    {{
                                      voyageMatrix.endDate
                                        ? formatDate(voyageMatrix.endDate)
                                        : ''
                                    }}</td
                                  >
                                  <td
                                    class="text-center text-truncate"
                                    v-for="(
                                      value, indexValue
                                    ) in voyageMatrix.activities"
                                    :key="indexValue"
                                    >{{ value.durationFormat }}</td
                                  >
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>

                <b-col lg="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title text-primary"
                        ><strong
                          >OPERATIONAL MATRIX BY SYSTEM -
                          {{ fleet?.name }}</strong
                        ></h4
                      >
                    </template>
                    <template v-slot:body>
                      <b-row>
                        <b-col
                          md="12"
                          v-if="
                            listMatrixBySystem &&
                            listMatrixBySystem.activities.length > 0
                          "
                        >
                          <div class="d-flex overflow-auto">
                            <table
                              class="table mb-0 table-striped table-hover"
                            >
                              <thead class="text-center">
                                <tr>
                                  <th>No</th>
                                  <th class="text-center">Date</th>
                                  <th
                                    v-for="(
                                      reason, index
                                    ) in listMatrixBySystem.activities"
                                    :key="index"
                                    class="text-center text-truncate"
                                    >{{ reason.name }}</th
                                  >
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>1</th>
                                  <td class="text-center text-truncate"
                                    >{{
                                      listMatrixBySystem.startDate
                                        ? formatDate(
                                            listMatrixBySystem.startDate
                                          )
                                        : ''
                                    }}
                                    -
                                    {{
                                      listMatrixBySystem.endDate
                                        ? formatDate(voyageMatrix.endDate)
                                        : ''
                                    }}</td
                                  >
                                  <td
                                    class="text-center text-truncate"
                                    v-for="(
                                      value, indexValue
                                    ) in listMatrixBySystem.activities"
                                    :key="indexValue"
                                    >{{ value.durationFormat }}</td
                                  >
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-col>
                        <b-col
                          md="12"
                          class="align-center text-center"
                          v-else
                        >
                          <strong class="text-center text-muted"
                            >No Data.</strong
                          >
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>

                <b-col
                  md="12"
                  v-if="
                    voyageMatrix.activities &&
                    voyageMatrix.activities.length > 0
                  "
                >
                  <iq-card
                    class-name="iq-card-block iq-card-stretch iq-card-height pb-4"
                    body-class=""
                  >
                    <template v-slot:headerTitle>
                      <h4 class="card-title text-primary"
                        ><strong>OPERATIONAL STATISTIC</strong></h4
                      >
                    </template>
                    <template v-slot:body>
                      <b-row v-if="!isLoadChart">
                        <b-col>
                          <div class="text-center">
                            <b-spinner label="Spinning"></b-spinner>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-if="isLoadChart && voyageHistories.length > 0">
                        <b-col
                          md="12"
                          v-for="(item, index) in opsChart"
                          :key="index"
                        >
                          <div class="mb-2" v-if="item.type === 'pie'">
                            <b-form-group :label="'Filter Chart '">
                              <v-multiselect
                                v-model="item.bodyData.data"
                                tag-placeholder="Category"
                                placeholder="Search or statistic"
                                label="name"
                                track-by="name"
                                :options="filterFields"
                                :multiple="true"
                                :taggable="true"
                              >
                              </v-multiselect>
                            </b-form-group>
                            <AmChart
                              :element="`pie-chart-${index}-`"
                              :type="item.bodyData.type"
                              :option="item.bodyData"
                            />
                          </div>
                          <div class="mb-2" v-if="item.type === 'bar'">
                            <b-form-group :label="'Filter Chart '">
                              <v-multiselect
                                @input="onChangeChart(index)"
                                v-model="item.bodyData.xaxis.categories"
                                tag-placeholder="Category"
                                placeholder="Search or statistic"
                                :options="
                                  filterFields.map((field) => field.name)
                                "
                                :multiple="true"
                                :taggable="true"
                              >
                              </v-multiselect>
                            </b-form-group>
                            <ApexChart
                              v-if="!item.loadingChart"
                              :element="`bar-chart-${index}-`"
                              :chartOption="item.bodyData"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>
                <b-col
                  md="6"
                  v-if="
                    voyageMatrix.projects && voyageMatrix.projects.length > 0
                  "
                >
                  <iq-card
                    class-name="iq-card-block iq-card-stretch"
                    body-class=""
                  >
                    <template v-slot:headerTitle>
                      <h4 class="card-title text-primary"
                        ><strong>PROJECT & CARGO</strong></h4
                      >
                    </template>
                    <template v-slot:body>
                      <div class="table-responsive">
                        <table class="table mb-0 table-striped table-hover">
                          <thead>
                            <tr>
                              <th class="text-center">No</th>
                              <th class="text-center">Project</th>
                              <th class="text-center">Trip</th>
                              <th class="text-center">Cargo</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in voyageMatrix.projects"
                              :key="index"
                            >
                              <td class="text-center">{{ index + 1 }}</td>
                              <td class="text-center">{{ item.name }}</td>
                              <td class="text-center">{{
                                numberFormat(item.trip)
                              }}</td>
                              <td class="text-center">{{
                                numberFormat(item.cargo)
                              }}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td
                                colspan="2"
                                class="font-weight-bold text-right"
                                >Total</td
                              >
                              <td class="font-weight-bold text-center">{{
                                totalTripMatrixProject
                              }}</td>
                              <td class="font-weight-bold text-center">{{
                                totalCargoMatrixProject
                              }}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </div>

            <div v-else>
              <TabVoyageByTrip :fleet="fleet" />
            </div>
          </b-col>
        </b-row>
        <!-- End Top Section !-->
      </tab-content-item>
      <!-- End Operational -->
      <!-- SCL -->
      <tab-content-item :active="false" id="scl_tab">
        <!-- Top Section !-->
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                  ><strong
                    >STEAMING TIME, SOUNDING CHECK LIST, & SUMMARY</strong
                  ></h4
                >
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="8">
                    <b-form class="row" @submit.prevent="fetchSCL()">
                      <b-col md="4">
                        <b-form-group label="From" label-for="from">
                          <b-form-input
                            id="exampleInputmonth"
                            type="date"
                            v-model="sclDateFilter.startDate"
                          ></b-form-input>
                          <small style="float: right"
                            >click <i class="fa fa-calendar"></i> to show</small
                          >
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="To" label-for="to">
                          <b-form-input
                            id="exampleInputmonth"
                            type="date"
                            v-model="sclDateFilter.endDate"
                          ></b-form-input>
                          <small style="float: right"
                            >click <i class="fa fa-calendar"></i> to show</small
                          >
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Filter" label-for="filter">
                          <b-button
                            type="submit"
                            variant="primary"
                            class="mb-3 mr-1"
                            ><i class="fa fa-filter"></i
                          ></b-button>
                        </b-form-group>
                      </b-col>
                    </b-form>
                  </b-col>
                  <b-col md="4" class="text-right">
                    <button class="btn btn-dark mb-3 mr-1"
                      ><i class="fa fa-file-excel" v-if="sclCanExport"></i>
                      Export</button
                    >
                    <router-link
                      :to="{
                        path: '/scl/scl-add',
                        query: { vehicleId: $route.query.vehicleId },
                      }"
                      v-if="sclCanAdd"
                    >
                      <button class="btn btn-primary mb-3"
                        ><i class="fa fa-plus"></i> Add SCL</button
                      >
                    </router-link>
                  </b-col>
                  <b-col md="12">
                    <hr />
                    <div class="table-responsive">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="thead-dark">
                          <tr>
                            <th>No</th>
                            <th>Fleet</th>
                            <th>Port of Loading</th>
                            <th>Port of Discharge</th>
                            <th>Cargo Type</th>
                            <th>Quantity</th>
                            <th>Total Steaming Time</th>
                            <th v-if="sclCanDelete || sclCanEdit"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="sclData.length > 0">
                            <tr v-for="(each, i) in sclData" :key="i">
                              <td>{{ (i += 1) }}</td>
                              <td
                                ><router-link to="">{{
                                  each.tugBargeName
                                }}</router-link></td
                              >
                              <td>{{ each.pol }}</td>
                              <td>{{ each.pod }}</td>
                              <td>{{ each.cargoType }}</td>
                              <td>{{ each.quantity }} MT</td>
                              <td
                                ><router-link to="">{{
                                  each.steamingTime
                                }}</router-link></td
                              >
                              <td v-if="sclCanDelete || sclCanEdit">
                                <router-link
                                  :to="{
                                    path: '/scl/scl-add',
                                    query: {
                                      vehicleId: $route.query.vehicleId,
                                      scl_id: each.id,
                                    },
                                  }"
                                  v-if="sclCanEdit"
                                >
                                  <b-button
                                    variant="success"
                                    size="sm"
                                    class="mr-2"
                                    ><i class="fa fa-edit"></i
                                  ></b-button>
                                </router-link>
                                <b-button
                                  variant="danger"
                                  size="sm"
                                  @click="onDeleteSCL(each)"
                                  v-if="sclCanDelete"
                                >
                                  <i class="fa fa-trash"></i>
                                </b-button>
                              </td>
                            </tr>
                          </template>
                          <tr v-if="sclData.length === 0"
                            ><td colspan="8" class="text-center"
                              >No Data</td
                            ></tr
                          >
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <!-- End SCL -->
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { saveAs } from 'file-saver'
import {
  voyagesActions,
  fleetsActions,
  reasonsActions,
} from '@src/Utils/helper'
import moment from 'moment'
import { numberFormat } from '@src/plugins/helpers'
import TabVoyageByTrip from './TabVoyageByTrip.vue'
import CustomNav from '@/src/components/global/CustomNav.vue'

var date = new Date()
export default {
  name: 'OperationalInput',
  props: {
    isChildComponent: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      isLoadChart: false,
      reasons: [],
      // historiesParamsDate: [new Date(2020, 1, 1), new Date()],
      opsChart: [
        {
          title: 'Operational Statistic',
          type: 'pie',
          bodyData: {
            type: 'pie',
            colors: [
              '#0084ff',
              '#00ca00',
              '#e64141',
              '#ffd400',
              '#00d0ff',
              '#374948',
            ],
            value: ['duration'],
            category: ['name'],
            data: [],
          },
          loadingChart: false,
        },
        {
          title: 'Operational Statistic',
          type: 'bar',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar',
            },
            colors: ['#334697'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded',
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 2.5,
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2'],
              },
            },
            series: [
              {
                data: [],
              },
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0',
                    },
                    text: 'Bananas are good',
                  },
                },
              ],
            },
            xaxis: {
              labels: {
                rotate: -45,
              },
              categories: [],
              tickPlacement: 'on',
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100],
              },
            },
          },
          loadingChart: false,
        },
      ],
      projectCargo: [
        { no: 1, project: 'KPI Project', trip: 2, cargo: '22.561' },
        { no: 2, project: 'AI Project', trip: 1, cargo: '8.921' },
      ],
      config: {
        dateFormat: 'Y-m-d',
        inline: true,
      },
      opsMatrix: [
        {
          no: '1',
          date: '1 Mei 2021 - 24 Mei 2021',
          run: '4,92',
          tech: '1,86',
          dock: '0,00',
          weather: '5,83',
          ops: '0,40',
          purch: '0,00',
          loading: '0,00',
          unloading: '0,00',
          docPOL: '0,00',
          docPOD: '1,30',
          crewing: '0,00',
          finance: '0,00',
        },
        {
          no: '2',
          date: '1 Mei 2021 - 24 Mei 2021',
          run: '4,92',
          tech: '1,86',
          dock: '0,00',
          weather: '5,83',
          ops: '0,40',
          purch: '0,00',
          loading: '0,00',
          unloading: '0,00',
          docPOL: '0,00',
          docPOD: '1,30',
          crewing: '0,00',
          finance: '0,00',
        },
        {
          no: '3',
          date: '1 Mei 2021 - 24 Mei 2021',
          run: '4,92',
          tech: '1,86',
          dock: '0,00',
          weather: '5,83',
          ops: '0,40',
          purch: '0,00',
          loading: '0,00',
          unloading: '0,00',
          docPOL: '0,00',
          docPOD: '1,30',
          crewing: '0,00',
          finance: '0,00',
        },
        {
          no: '4',
          date: '1 Mei 2021 - 24 Mei 2021',
          run: '4,92',
          tech: '1,86',
          dock: '0,00',
          weather: '5,83',
          ops: '0,40',
          purch: '0,00',
          loading: '0,00',
          unloading: '0,00',
          docPOL: '0,00',
          docPOD: '1,30',
          crewing: '0,00',
          finance: '0,00',
        },
      ],
      loading_voyage_history: false,
      loading_voyage_matrix: false,
      // historiesParamsDate: [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1,0)],
      historiesParamsDate: [],
      voyageHistories: [],
      voyageMatrix: [],

      filterFields: [],
      fleet: {},
      sclDateFilter: {
        startDate: null,
        endDate: null,
      },
      sclData: [],
      listMatrixBySystem: [],
      voyageTab:'monthly',
      voyageTabOpt:[
        {
          value:'monthly',
          label:'Monthly Voyage'
        },
        {
          value:'by-trip',
          label:'Voyage By Trip'
        },
      ]
    }
  },
  components: {
    TabVoyageByTrip,
    CustomNav
  },
  computed: {
    vehicleId() {
      const { vehicleId } = this.$route.query

      if (!vehicleId) {
        return null
      }

      return vehicleId
    },

    totalCargoMatrixProject() {
      return this.voyageMatrix?.projects
        .map((item) => item.cargo)
        .reduce((prev, curr) => prev + curr, 0)
    },

    totalTripMatrixProject() {
      return this.voyageMatrix?.projects
        .map((item) => item.trip)
        .reduce((prev, curr) => prev + curr, 0)
    },

    // field visibilities
    showVoyageWidth() {
      return ![3, 6].includes(this.fleet?.vehicleType?.id)
    },
    showDepartureDateTime() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showArrivalDateTime() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showPortOfLoading() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showPortOfDischarge() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showPortAgentPol() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showPortAgentPod() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showLatitude() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showLongtitude() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showSpeed() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showDocumentOnBoard() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
    showPIC() {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },

    opsSclPermissions() {
      return this.lsProfile.menu[1]?.child[0]?.child[1]
    },
    sclCanAdd() {
      return this.opsSclPermissions ? this.opsSclPermissions?.add : false
    },
    sclCanEdit() {
      return this.opsSclPermissions ? this.opsSclPermissions?.update : false
    },
    sclCanApprove() {
      return this.opsSclPermissions ? this.opsSclPermissions?.approval : false
    },
    sclCanReview() {
      return this.opsSclPermissions ? this.opsSclPermissions?.review : false
    },
    sclCanDelete() {
      return this.opsSclPermissions ? this.opsSclPermissions?.delete : false
    },
    sclCanExport() {
      return this.opsSclPermissions ? this.opsSclPermissions?.export : false
    },
  },
  async mounted() {
    xray.index()

    await this.fetchVoyageMatrixBySystem()
    await this.fetchReasons()
    this.initFilterDateRange()
    await this.fetchFleet()
    this.fetchVoyageHistories()
    // this.fetchVoyageMatrix()
    this.fetchSCL()
    this.initFilterDate()
  },
  methods: {
    ...voyagesActions,
    ...fleetsActions,
    ...reasonsActions,
    isBreakdown(item) {
      let data = item.vehicle.find((obj) => obj.id == this.fleet?.id)

      return data.breakdown
    },
    numberFormat(number) {
      return numberFormat(number)
    },
    async onFilterMonthly() {
      this.fetchVoyageHistories()
      this.fetchVoyageMatrixBySystem()
    },
    async fetchFleet() {
      const res = await this.getFleetById(this.vehicleId)

      if (res.status == 'success') {
        this.fleet = res.data
      } else {
        this.fleet = {}
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async fetchVoyageHistories() {
      this.loading_voyage_history = true

      const params = {
        page: 1,
        showAll: true,
        startDate: moment(this.historiesParamsDate[0]).format(
          'DD-MM-YYYY HH:mm'
        ),
        endDate: moment(this.historiesParamsDate[1]).format('DD-MM-YYYY HH:mm'),
      }
      const payload = {
        vehicleId: this.vehicleId,
        params,
      }
      const res = await this.getVoyageHistories(payload)

      if (res.status == 'success') {
        const { data } = res
        this.voyageHistories = data
        this.fetchVoyageMatrix()
      } else {
        this.voyageHistories = []
        this.$swal(`Oops!`, res.data.message, 'error')
      }

      this.loading_voyage_history = false
    },
    async exportVoyageHistories() {
      this.loading_voyage_history = true

      const params = {
        /* page: 1,
        showAll: true, */
        startDate: moment(this.historiesParamsDate[0]).format(
          'DD-MM-YYYY HH:mm'
        ),
        endDate: moment(this.historiesParamsDate[1]).format('DD-MM-YYYY HH:mm'),
      }
      const payload = {
        vehicleId: this.vehicleId,
        params,
      }
      const res = await this.getVoyageHistoriesExport(payload)
      if (res.data) {
        // window.open(res.data)
        let blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }

      /*if (res.status == 'success') {
        this.loading_voyage_history = false
        saveAs(res.data, 'Voyage History Data.xlsx')
      } else {
        this.loading_voyage_history = false
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }*/

      this.loading_voyage_history = false
    },
    async fetchReasons() {
      let params = {
        active: true,
        perPage: 50,
        page: 1,
      }

      let res = await this.getReasons(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.reasons = res.data.data
        }
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async fetchVoyageMatrix() {
      this.loading_voyage_matrix = true
      let self = this

      const params = {
        page: 1,
        showAll: true,
        startDate: moment(this.historiesParamsDate[0]).format(
          'DD-MM-YYYY HH:mm'
        ),
        endDate: moment(this.historiesParamsDate[1]).format('DD-MM-YYYY HH:mm'),
      }
      const payload = {
        vehicleId: this.vehicleId,
        params,
      }
      const res = await this.getVoyageMatrix(payload)

      if (res.status == 'success') {
        const { data } = res

        if (data) {
          data.projects = data.projects.filter((project) => project.trip > 0)
        }

        let tempArr = []

        data.activities.map((activity) => {
          this.reasons.map((reason) => {
            if (activity.name === reason.name && reason.active === true) {
              tempArr.push(activity)
            }

            if (activity.name === 'Breakdown') {
              tempArr.push(activity)
            }
          })
        })

        const uniqueData = tempArr.reduce((acc, curr) => {
          // Check if the current object's id already exists in the accumulator
          if (acc[curr.name]) {
            // If the current object has a non-zero value, replace the existing object in the accumulator
            if (curr.duration !== 0) {
              acc[curr.name] = curr
            }
          } else {
            // Add the current object to the accumulator
            acc[curr.name] = curr
          }
          return acc
        }, {})
        // Convert the object back to an array of objects
        const result = Object.values(uniqueData)

        const fixedData = {
          ...data,
          activities: result,
        }

        this.voyageMatrix = fixedData

        self.opsChart[0].bodyData.data = fixedData.activities

        let dataValBarChart = []
        let dataHeaderBarChart = []
        this.filterFields = []

        fixedData.activities.forEach((el3) => {
          dataValBarChart.push(el3.duration)
          dataHeaderBarChart.push(el3.name)
          this.filterFields.push(el3)
        })

        self.opsChart[1].bodyData = {
          series: [
            {
              name: 'Reason Day',
              data: dataValBarChart,
            },
          ],
          annotations: {
            points: [
              {
                x: 'Bananas',
                seriesIndex: 0,
                label: {
                  borderColor: '#775DD0',
                  offsetY: 0,
                  style: {
                    color: '#fff',
                    background: '#775DD0',
                  },
                  text: 'Bananas are good',
                },
              },
            ],
          },
          chart: {
            height: 300,
            type: 'bar',
          },
          colors: ['#334697'],
          dataLabels: {
            enabled: false,
          },
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
            categories: dataHeaderBarChart,
            tickPlacement: 'on',
          },
        }
        self.opsChart[1].loadingChart = true

        setTimeout(() => {
          self.opsChart[1].loadingChart = false
          self.isLoadChart = true
        }, 300)
      } else {
        this.voyageMatrix = []
        this.$swal(`Oops!`, res.data.message, 'error')
      }
      this.loading_voyage_matrix = false
    },
    async fetchVoyageMatrixBySystem() {
      let params = {
        vehicleId: Number(this.$route.query.vehicleId),
        params: {
          startDate: moment(this.historiesParamsDate[0]).format(
            'DD-MM-YYYY HH:mm'
          ),
          endDate: moment(this.historiesParamsDate[1]).format(
            'DD-MM-YYYY HH:mm'
          ),
        },
      }

      const res = await this.getVoyageMatrixBySystem(params)

      if (res.status == 'success') {
        this.listMatrixBySystem = res.data
      } else {
        this.listMatrixBySystem = []
      }
    },
    async fetchSCL() {
      let yearNow = moment().format('YYYY')
      let monthNow = moment().format('MM')
      let startDateNow = '01-' + monthNow + '-' + yearNow + ' 00:00'
      let endDateNow = moment().endOf('month').format('DD-MM-YYYY') + ' 23:59'
      let params = {
        page: 1,
        showAll: true,
        startDate:
          this.sclDateFilter.startDate !== '' &&
          this.sclDateFilter.startDate !== null
            ? moment(this.sclDateFilter.startDate).format('DD-MM-YYYY') +
              ' 00:00'
            : startDateNow,
        endDate:
          this.sclDateFilter.endDate !== '' &&
          this.sclDateFilter.endDate !== null
            ? moment(this.sclDateFilter.endDate).format('DD-MM-YYYY') + ' 23:59'
            : endDateNow,
      }
      const payload = {
        vehicleId: this.vehicleId,
        params,
      }
      const res = await this.getSCL(payload)

      if (res.status == 'success') {
        const { data } = res
        this.sclData = data
      } else {
        this.sclData = []
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    initFilterDate() {
      var date = new Date()
      var y = date.getFullYear()
      var m = date.getMonth()
      var firstDay = new Date(y, m, 1)
      var lastDay = new Date(y, m + 1, 0)

      this.sclDateFilter.startDate = moment(firstDay).format('YYYY-MM-DD')
      this.sclDateFilter.endDate = moment(lastDay).format('YYYY-MM-DD')
    },
    toEditHistory(activityId) {
      this.$router.push({
        name: 'dashboard.operational-input',
        query: {
          vehicleId: this.$route.query.vehicleId,
          activityEditId: activityId,
        },
      })
    },
    async onDeleteActivity(activityData) {
      let activity = activityData

      this.$swal({
        title: 'Delete Activity?',
        text: `Activity ${activity.activityName} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const vehicleId = this.$route.query.vehicleId
          let res = await this.deleteActivityById({
            vehicleId,
            id: activity.id,
          })
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.fetchVoyageHistories()
            this.$swal(
              `Activity deleted!`,
              `Activity ${activity.activityName} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    async onDeleteSCL(activityData) {
      let activity = activityData

      this.$swal({
        title: 'Delete SCL?',
        text: `SCL ${activity.tugBargeName} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const vehicleId = this.$route.query.vehicleId
          let res = await this.deleteSCLById({ vehicleId, id: activity.id })
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.fetchSCL()
            this.$swal(
              `SCL deleted!`,
              `SCL ${activity.tugBargeName} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    initFilterDateRange() {
      var date = new Date()
      var y = date.getFullYear()
      var m = date.getMonth()
      var firstDay = new Date(y, m, 1)
      var lastDay = new Date(y, m + 1, 0)

      this.historiesParamsDate = [firstDay, lastDay]
    },
    formatDate(val) {
      if (val) {
        let dateVal = `${val.split('-')[2]}-${val.split('-')[1]}-${
          val.split('-')[0]
        }`
        return moment(dateVal).format('DD MMMM YYYY')
      }
    },
    onChangeChart(index) {
      this.opsChart[index].loadingChart = true

      setTimeout(() => {
        this.opsChart[index].loadingChart = false
      }, 100)
    },
  },
}
</script>

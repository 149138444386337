var render = function render(){
  var _vm$detailMi$company$, _vm$detailMi$vehicle$, _vm$detailMi$vehicle, _vm$detailMi$date_of_, _vm$detailMi$place_of, _vm$detailMi$type_cov, _vm$detailMi$type_los, _vm$detailMi$company$2, _vm$detailMi$name, _vm$detailMi, _vm$detailMi$position, _vm$detailMi$departme, _vm$detailMi$cause_of;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave();
      }
    }
  }, [_vm.detailMi ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('openModalEmail', 'Evaluation', _vm.detailMi.id);
      }
    }
  }, [_vm._v(" Send Email Notification "), _c('i', {
    staticClass: "fa fa-send ml-1"
  })]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditMode = !_vm.isEditMode;
      }
    }
  }, [_vm._v(_vm._s(_vm.isEditMode ? 'Preview' : 'Update') + " Evaluation")])], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h6', {
    staticClass: "text-primary border-bottom pb-1 mb-3"
  }, [_c('b', [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company *",
      "label-for": "company"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$company$ = _vm.detailMi.company.company) !== null && _vm$detailMi$company$ !== void 0 ? _vm$detailMi$company$ : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet Name *",
      "label-for": "fleets"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$vehicle$ = (_vm$detailMi$vehicle = _vm.detailMi.vehicle) === null || _vm$detailMi$vehicle === void 0 ? void 0 : _vm$detailMi$vehicle.name) !== null && _vm$detailMi$vehicle$ !== void 0 ? _vm$detailMi$vehicle$ : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Loss (DOL) *",
      "label-for": "dol"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$date_of_ = _vm.detailMi.date_of_loss) !== null && _vm$detailMi$date_of_ !== void 0 ? _vm$detailMi$date_of_ : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Place of Loss (POL) *",
      "label-for": "pol"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$place_of = _vm.detailMi.place_of_loss) !== null && _vm$detailMi$place_of !== void 0 ? _vm$detailMi$place_of : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Coverage *",
      "label-for": "coverage"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$type_cov = _vm.detailMi.type_coverage.name) !== null && _vm$detailMi$type_cov !== void 0 ? _vm$detailMi$type_cov : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Loss *",
      "label-for": "loss"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$type_los = _vm.detailMi.type_loss.name) !== null && _vm$detailMi$type_los !== void 0 ? _vm$detailMi$type_los : '-'))])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h6', {
    staticClass: "text-primary border-bottom pb-1 mb-3"
  }, [_c('b', [_vm._v("CLAIMANT")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company *",
      "label-for": "company"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$company$2 = _vm.detailMi.company.company) !== null && _vm$detailMi$company$2 !== void 0 ? _vm$detailMi$company$2 : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name *",
      "label-for": "name"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$name = (_vm$detailMi = _vm.detailMi) === null || _vm$detailMi === void 0 ? void 0 : _vm$detailMi.name) !== null && _vm$detailMi$name !== void 0 ? _vm$detailMi$name : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Position *",
      "label-for": "position"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$position = _vm.detailMi.position) !== null && _vm$detailMi$position !== void 0 ? _vm$detailMi$position : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Department of FLeet *",
      "label-for": "departments"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.department ? (_vm$detailMi$departme = _vm.detailMi.department) === null || _vm$detailMi$departme === void 0 ? void 0 : _vm$detailMi$departme.name : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cause of Loss",
      "label-for": "cause_of_loss"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.cause_of_loss ? (_vm$detailMi$cause_of = _vm.detailMi.cause_of_loss) === null || _vm$detailMi$cause_of === void 0 ? void 0 : _vm$detailMi$cause_of.name : '-'))])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr')]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-2 text-primary",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Recommendation")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table mb-0 table-borderless table-box-shadow"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Permasalahan")]), _c('th', [_vm._v("Vessel Case")]), _c('th', [_vm._v("Root Cause")]), _c('th', [_vm._v("Rekomendasi")]), _c('th', [_vm._v("Cause of Loss")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Closed Date")])])]), _c('tbody', [_vm.recommendations.length ? _vm._l(_vm.recommendations, function (recommendation, idx) {
    return _c('tr', {
      key: recommendation.id
    }, [_c('td', [_vm._v(_vm._s(idx + 1) + ". ")]), _c('td', [_vm._v(_vm._s(recommendation.problem))]), _c('td', [_vm._v(_vm._s(recommendation.vessel_case))]), _c('td', [_vm._v(_vm._s(recommendation.root_cause))]), _c('td', [_vm._v(_vm._s(recommendation.recommendation))]), _c('td', [_vm._v(_vm._s(recommendation.cause_of_loss_id))]), _c('td', [_vm._v(_vm._s(recommendation.status))]), _c('td', [_vm._v(_vm._s(recommendation.closed_at))])]);
  }) : _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "8"
    }
  }, [_vm._v("No Data Available!")])])], 2)])])]), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-2 text-primary",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Notif Mail to Users")]), _c('b-form-group', {
    staticClass: "mt-2"
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Notif user",
      "label": "name",
      "track-by": "id",
      "options": _vm.optionsUser,
      "multiple": true
    },
    model: {
      value: _vm.notifMailUser,
      callback: function callback($$v) {
        _vm.notifMailUser = $$v;
      },
      expression: "notifMailUser"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Data not found.")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-2 text-primary",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Input Evaluation")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("No.")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Tahapan Kegiatan")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Sasaran Tahapan (Milestone)")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Uraian Tugas")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Indikator Keberhasilan")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Metode Evaluasi")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Frekuensi")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Pelaksana")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Kebutuhan Tindak lanjut")]), _vm.isEditMode ? _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.forms, function (form, idx) {
    var _form$activity_stage, _form$milestone, _form$job_description, _form$success_indicat, _form$evaluation_resu, _form$frequency, _form$frequency2;
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(idx + 1))]), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "id": "tahapan-kegiatan",
        "rows": "3"
      },
      model: {
        value: form.activity_stage,
        callback: function callback($$v) {
          _vm.$set(form, "activity_stage", $$v);
        },
        expression: "form.activity_stage"
      }
    }) : _c('h6', [_vm._v(_vm._s((_form$activity_stage = form.activity_stage) !== null && _form$activity_stage !== void 0 ? _form$activity_stage : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "id": "sasaran-tahapan",
        "rows": "3"
      },
      model: {
        value: form.milestone,
        callback: function callback($$v) {
          _vm.$set(form, "milestone", $$v);
        },
        expression: "form.milestone"
      }
    }) : _c('h6', [_vm._v(_vm._s((_form$milestone = form.milestone) !== null && _form$milestone !== void 0 ? _form$milestone : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "id": "uraian-tugas",
        "rows": "3"
      },
      model: {
        value: form.job_description,
        callback: function callback($$v) {
          _vm.$set(form, "job_description", $$v);
        },
        expression: "form.job_description"
      }
    }) : _c('h6', [_vm._v(_vm._s((_form$job_description = form.job_description) !== null && _form$job_description !== void 0 ? _form$job_description : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "id": "indikator-keberhasilan",
        "rows": "3"
      },
      model: {
        value: form.success_indicator,
        callback: function callback($$v) {
          _vm.$set(form, "success_indicator", $$v);
        },
        expression: "form.success_indicator"
      }
    }) : _c('h6', [_vm._v(_vm._s((_form$success_indicat = form.success_indicator) !== null && _form$success_indicat !== void 0 ? _form$success_indicat : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "id": "hasil-evaluasi",
        "rows": "3"
      },
      model: {
        value: form.evaluation_result,
        callback: function callback($$v) {
          _vm.$set(form, "evaluation_result", $$v);
        },
        expression: "form.evaluation_result"
      }
    }) : _c('h6', [_vm._v(_vm._s((_form$evaluation_resu = form.evaluation_result) !== null && _form$evaluation_resu !== void 0 ? _form$evaluation_resu : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "id": "frekuensi",
        "rows": "3"
      },
      model: {
        value: form.frequency,
        callback: function callback($$v) {
          _vm.$set(form, "frequency", $$v);
        },
        expression: "form.frequency"
      }
    }) : _c('h6', [_vm._v(_vm._s((_form$frequency = form.frequency) !== null && _form$frequency !== void 0 ? _form$frequency : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "id": "pelaksana",
        "rows": "3"
      },
      model: {
        value: form.executor,
        callback: function callback($$v) {
          _vm.$set(form, "executor", $$v);
        },
        expression: "form.executor"
      }
    }) : _c('h6', [_vm._v(_vm._s((_form$frequency2 = form.frequency) !== null && _form$frequency2 !== void 0 ? _form$frequency2 : '-'))])], 1), _c('td', [_vm.isEditMode ? _vm._l(_vm.typeFormInsurance, function (item, index) {
      return _c('b-form-radio', {
        key: index,
        staticClass: "custom-radio-color-checked",
        attrs: {
          "inline": "",
          "color": item.color,
          "name": "follow_up_needs-".concat(idx + 1),
          "value": item.value,
          "id": "follow_up_needs-".concat(item.value, "-").concat(idx + 1)
        },
        model: {
          value: form.follow_up_needs,
          callback: function callback($$v) {
            _vm.$set(form, "follow_up_needs", $$v);
          },
          expression: "form.follow_up_needs"
        }
      }, [_c('strong', [_c('h4', [_vm._v(_vm._s(item.label))])])]);
    }) : _c('h6', {
      staticClass: "text-capitalize text-center"
    }, [_vm._v(_vm._s(form.follow_up_needs))])], 2), _vm.isEditMode ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteForm(idx);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1) : _vm._e()]);
  }), 0)]), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addForms
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)])], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    staticClass: "text-right mb-4",
    attrs: {
      "md": "12"
    }
  }, [_vm.isEditMode ? _c('b-button', {
    staticClass: "px-5",
    attrs: {
      "type": "submit",
      "disabled": _vm.saving,
      "variant": "primary"
    }
  }, [_vm._v("Submit")]) : _vm._e()], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
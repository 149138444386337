<template>
  <div class="p-0 py-2">
    <ul class="doctors-lists m-0 p-0" v-if="picData?.length > 0">
      <li
        class="my-2 align-items-center"
        v-for="pic in picData"
        :key="'pic-' + pic?.id"
      >
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div class="d-flex flex-column">
            <span
              ><strong>{{ pic?.name ?? '-' }}</strong></span
            >
            <span
              >Email: <strong>{{ pic?.email ?? '-' }}</strong></span
            >
          </div>
          <i class="fa fa-user text-primary mr-2" style="font-size: 20px" />
        </div>
        <div class="w-100 d-flex my-2 border" />
      </li>
    </ul>
    <div class="d-flex align-items-center justify-content-center" v-else>
      <h6 class="text-muted">
        <strong>This Fleet doesnt have PIC</strong>
      </h6>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    picData: () => [],
    type: Array,
  },
}
</script>

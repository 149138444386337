var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary text-capitalize d-flex align-items-center"
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-3",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(-1);
            }
          }
        }), _vm._v(" "), _c('strong', [_vm._v("create budget " + _vm._s(_vm.$route.query.type))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "period"
          }
        }, [_c('date-picker', {
          staticClass: "w-100",
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Type",
            "label-for": "fleet_type"
          }
        }, [_c('v-multiselect', {
          staticClass: "w-auto",
          attrs: {
            "size": "sm",
            "tag-placeholder": "Fleet type",
            "placeholder": "Select status...",
            "label": "label",
            "track-by": "value",
            "options": _vm.optionsFleetTypes,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.form.fleetType,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "fleetType", $$v);
            },
            expression: "form.fleetType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_vm._l(_vm.statusOptions, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "disabled": item.disabled
            },
            model: {
              value: _vm.form.status,
              callback: function callback($$v) {
                _vm.$set(_vm.form, "status", $$v);
              },
              expression: "form.status"
            }
          }, [_c('span', [_vm._v(_vm._s(item.label))])])];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('wysiwyg', {
          style: {
            height: '200px'
          },
          model: {
            value: _vm.form.notes,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "notes", $$v);
            },
            expression: "form.notes"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "border mx-1"
        }, [_c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "py-2",
          staticStyle: {
            "background-color": "#e7f3ff"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Project Name",
            "label-for": "status"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "background-color": "#ffffff"
          },
          attrs: {
            "placeholder": "Arutmin Project"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Add Sub Project",
            "label-for": "status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "add_sub_project",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.add_sub_project,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "add_sub_project", $$v);
            },
            expression: "form.add_sub_project"
          }
        }, [_vm._v(" No / Yes ")])], 1)], 1)], 1)], 1), _vm.form.add_sub_project ? [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "overflow-auto",
          staticStyle: {
            "max-height": "500px"
          }
        }, [_vm._l(_vm.form.sub_project, function (val, index) {
          return [_c('table', {
            key: index,
            staticClass: "table table-sm table-borderless table-hover"
          }, [_c('tr', [_c('td', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "row-".concat(index),
              expression: "`row-${index}`"
            }],
            staticClass: "text-center",
            staticStyle: {
              "width": "5px",
              "margin-left": "1rem",
              "cursor": "pointer"
            }
          }, [_c('ChevronArrow', {
            attrs: {
              "color": "black"
            }
          })], 1), _c('td', {
            staticClass: "p-2"
          }, [_c('b-form-input', {
            staticClass: "mx-2",
            attrs: {
              "placeholder": "Labuan"
            }
          })], 1), _c('td', {
            staticClass: "p-2"
          }, [_c('div', {
            staticClass: "input-group"
          }, [_c('input', {
            staticClass: "form-control text-right",
            attrs: {
              "type": "number"
            }
          }), _vm.$route.query.type === 'cargo' ? _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text bg-primary",
            attrs: {
              "id": "rp"
            }
          }, [_vm._v("Tons")])]) : _vm._e()])]), _c('td', {
            staticClass: "text-center",
            staticStyle: {
              "width": "5px"
            }
          }, [_c('div', {
            staticClass: "bg-danger p-1 px-2",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.form.sub_project.splice(index, 1);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])])])]), _c('b-collapse', {
            staticClass: "collapse-row",
            attrs: {
              "id": "row-".concat(index)
            }
          }, [_c('div', {
            staticClass: "mx-5 mb-4 border"
          }, [_c('table', {
            staticClass: "table table-borderless table-hover px-4 table-striped"
          }, [_c('tr', {
            staticStyle: {
              "background-color": "#e7f3ff"
            }
          }, [_c('th', [_vm._v("Fleet Capacity")]), _c('th', [_vm._v("Fleet Ownership")]), _c('th', [_vm._v("Loading")]), _c('th', {
            staticStyle: {
              "width": "5px"
            }
          })]), _vm._l(val.sub_project_child, function (obj, objIndex) {
            return _c('tr', {
              key: objIndex
            }, [_c('td', [_c('b-form-input', {
              staticClass: "mr-2",
              attrs: {
                "placeholder": "Labuan"
              }
            })], 1), _c('td', [_c('b-form-select', {
              staticClass: "mr-2",
              attrs: {
                "plain": "",
                "size": "sm",
                "placeholder": "Select..."
              }
            }, [_c('option', {
              attrs: {
                "value": "1"
              }
            }, [_vm._v("Owned")]), _c('option', {
              attrs: {
                "value": "2"
              }
            }, [_vm._v("Third Party")])])], 1), _c('td', [_c('div', {
              staticClass: "input-group"
            }, [_c('input', {
              staticClass: "form-control text-right",
              attrs: {
                "type": "number"
              }
            }), _vm.$route.query.type === 'cargo' ? _c('div', {
              staticClass: "input-group-prepend"
            }, [_c('span', {
              staticClass: "input-group-text bg-primary",
              attrs: {
                "id": "rp"
              }
            }, [_vm._v("Tons")])]) : _vm._e()])]), _c('td', {
              staticClass: "text-danger text-center px-2",
              staticStyle: {
                "width": "5px",
                "cursor": "pointer"
              },
              on: {
                "click": function click($event) {
                  return val.sub_project_child.splice(objIndex, 1);
                }
              }
            }, [_c('i', {
              staticClass: "fa fa-trash"
            })])]);
          })], 2), _c('button', {
            staticClass: "btn ml-3 mb-3 mt-0",
            staticStyle: {
              "background-color": "#e7f3ff"
            },
            attrs: {
              "type": "button"
            },
            on: {
              "click": function click($event) {
                return val.sub_project_child.push({
                  fleet_capacity: '',
                  fleet_ownership: '',
                  loading: null
                });
              }
            }
          }, [_c('strong', [_vm._v("Add Budget Cargo")])])])])];
        })], 2)])] : _vm._e(), _vm.form.add_sub_project ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "w-100 d-flex my-2"
        }), _c('button', {
          staticClass: "btn mb-3",
          staticStyle: {
            "background-color": "#3c5de7"
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.form.sub_project.push({
                project_name: '',
                project_value: null,
                sub_project_child: [{
                  fleet_capacity: '',
                  fleet_ownership: '',
                  loading: null
                }]
              });
            }
          }
        }, [_c('strong', {
          staticClass: "text-white"
        }, [_vm._v("Add Budget Cargo")])])]) : _vm._e()], 2)], 1), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-end"
        }, [_c('button', {
          staticClass: "btn btn-outline-primary mr-2",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" Cancel ")]), _c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" Submit ")])])])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-4"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3 d-flex justify-content-end",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditMode = !_vm.isEditMode;
      }
    }
  }, [_vm._v(_vm._s(_vm.isEditMode ? 'Preview' : 'Update') + " Lost Ratio")])], 1), _vm.detailMi ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave();
      }
    }
  }, [_c('table', {
    staticClass: "table table-striped",
    staticStyle: {
      "border-bottom": "1px solid slategray"
    }
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Lost Ratio Type")]), _c('th', [_vm._v("Premium")]), _c('th', [_vm._v("Loss")]), _c('th', [_vm._v("Ratio")])])]), _c('tbody', _vm._l(_vm.form.sources, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('h6', [_vm._v(_vm._s(row.type))])]), _c('td', [_vm.isEditMode ? _c('InputCurrency', {
      on: {
        "input": function input($event) {
          return _vm.handleCalculateLossRatio(index);
        }
      },
      model: {
        value: row.premium,
        callback: function callback($$v) {
          _vm.$set(row, "premium", $$v);
        },
        expression: "row.premium"
      }
    }) : _c('h6', {}, [_vm._v(_vm._s(_vm.formatCurrency(row.premium)))])], 1), _c('td', [_vm.isEditMode ? _c('InputCurrency', {
      on: {
        "input": function input($event) {
          return _vm.handleCalculateLossRatio(index);
        }
      },
      model: {
        value: row.loss,
        callback: function callback($$v) {
          _vm.$set(row, "loss", $$v);
        },
        expression: "row.loss"
      }
    }) : _c('h6', {}, [_vm._v(_vm._s(_vm.formatCurrency(row.loss)))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "bg-white",
      attrs: {
        "type": "number",
        "step": ".01",
        "required": ""
      },
      model: {
        value: row.ratio,
        callback: function callback($$v) {
          _vm.$set(row, "ratio", $$v);
        },
        expression: "row.ratio"
      }
    }) : _c('h6', {}, [_vm._v(_vm._s(_vm.formatCurrency(row.loss)))])], 1)]);
  }), 0)]), _c('div', {
    staticClass: "d-flex justify-content-end mb-5"
  }, [_vm.isEditMode ? _c('b-button', {
    attrs: {
      "type": "submit",
      "disabled": _vm.form._saving,
      "variant": "primary"
    }
  }, [_vm._v("Submit")]) : _vm._e()], 1)])]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$crewData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-0 py-2"
  }, [((_vm$crewData = _vm.crewData) === null || _vm$crewData === void 0 ? void 0 : _vm$crewData.length) > 0 ? _c('ul', {
    staticClass: "doctors-lists m-0 p-0"
  }, _vm._l(_vm.crewData, function (crew) {
    var _crew$personal_data$f, _crew$personal_data, _crew$position$name, _crew$position;
    return _c('li', {
      key: 'crew-' + crew.id,
      staticClass: "d-flex mb-4 align-items-center"
    }, [_c('div', {
      staticClass: "user-img img-fluid"
    }, [_c('img', {
      staticClass: "rounded-circle avatar-40",
      attrs: {
        "src": crew === null || crew === void 0 ? void 0 : crew.photo,
        "alt": "story-img"
      }
    })]), _c('div', {
      staticClass: "media-support-info ml-3"
    }, [_c('h6', [_vm._v(_vm._s((_crew$personal_data$f = crew === null || crew === void 0 ? void 0 : (_crew$personal_data = crew.personal_data) === null || _crew$personal_data === void 0 ? void 0 : _crew$personal_data.full_name) !== null && _crew$personal_data$f !== void 0 ? _crew$personal_data$f : '-'))]), _c('p', {
      staticClass: "mb-0 font-size-12"
    }, [_vm._v(_vm._s((_crew$position$name = crew === null || crew === void 0 ? void 0 : (_crew$position = crew.position) === null || _crew$position === void 0 ? void 0 : _crew$position.name) !== null && _crew$position$name !== void 0 ? _crew$position$name : '-'))]), _c('small', {
      staticClass: "mb-0 font-size-12"
    }, [_vm._v(" Join On Board: "), _c('strong', [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.join_on_board ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.join_on_board).format('DD MMMM YYYY') : '-'))])]), _c('br'), _c('small', {
      staticClass: "mb-0 font-size-12"
    }, [_vm._v(" Sign Off: "), _c('strong', [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.sign_off ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_off).format('DD MMMM YYYY') : '-'))])])]), _c('div', {
      staticClass: "iq-card-header-toolbar d-flex align-items-center"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link",
        "toggle-class": "p-0 text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton6",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "ri-more-2-line"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            path: "/crewing/profile",
            query: {
              user: 'crew',
              id: crew.id
            }
          });
        }
      }
    }, [_c('i', {
      staticClass: "ri-eye-line mr-2"
    }), _vm._v("View Detail")])], 1)], 1)]);
  }), 0) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h6', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("This Fleet doesnt have Crew")])]);

}]

export { render, staticRenderFns }
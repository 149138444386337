<template>
  <b-container fluid>
    <!-- Floating -->
    <div class="fab-container">
      <div
        class="fab shadow"
        v-if="
          [
            'SUPER ADMIN',
            'PORT CAPTAIN',
            'MANAGEMENT',
            'RADIO CONTROL ROOM',
            'MARINE INSURANCE',
            'MARINE INSURANCE ADMIN',
          ].includes(role)
        "
        @click="dashboard = 'operational'"
      >
        <div class="fab-content bg-blue">
          <span>
            <i class="ri ri-safari-fill mr-2"></i>
          </span>
          <span style="font-family: Arial, Helvetica, sans-serif"
            >Operational</span
          >
        </div>
      </div>
      <div
        class="fab shadow"
        v-if="
          [
            'SUPER ADMIN',
            'MANAGEMENT',
            'MARINE INSURANCE',
            'MARINE INSURANCE ADMIN',
          ].includes(role)
        "
        @click="dashboard = 'mi'"
      >
        <div class="fab-content bg-warning">
          <span>
            <i class="ri ri-ship-2-line mr-2"></i>
          </span>
          <span style="font-family: Arial, Helvetica, sans-serif"
            >Marine Insurance</span
          >
        </div>
      </div>
      <div
        class="fab shadow"
        v-if="
          [
            'SUPER ADMIN',
            'MANAGEMENT',
            'MARINE INSURANCE',
            'MARINE INSURANCE ADMIN',
          ].includes(role)
        "
        @click="dashboard = 'mi_list_unsettled'"
      >
        <div class="fab-content bg-dark">
          <span>
            <i class="ri ri-ship-fill mr-2"></i>
          </span>
          <span style="font-family: Arial, Helvetica, sans-serif"
            >Vessel Case</span
          >
        </div>
      </div>
      <div
        class="fab shadow"
        v-if="
          [
            'SUPER ADMIN',
            'MANAGEMENT',
            'MARINE INSURANCE',
            'MARINE INSURANCE ADMIN',
            'CREWING',
          ].includes(role)
        "
        @click="dashboard = 'crewing'"
      >
        <div class="fab-content bg-dark">
          <span>
            <i class="las la-users mr-2"></i>
          </span>
          <span style="font-family: Arial, Helvetica, sans-serif">Crewing</span>
        </div>
      </div>
    </div>
    <!-- End Floating -->
    <!-- Maps -->
    <b-row v-if="layout === 'maps'">
      <ContentMaps
        :fleetActivities="fleetActivities"
        :fetchFleetDetail="fetchFleetDetail"
        :onDeleteFleet="onDeleteFleet"
      />
    </b-row>
    <!-- FILTERS -->
    <b-row class="mb-3" style="z-index: 999 !important">
      <!-- DISPLAY -->
      <b-col md="9">
        <div v-if="layout === 'maps'" class="justify-content-end text-left">
          <FilterMaps
            :vehicleTypes="vehicleTypes"
            :categoryOwnership="categoryOwnership"
            :fetchFleetActivities="fetchFleetActivities"
            v-model="params"
            :maintenance="maintenance"
            :fleetDetail="fleetDetail"
          />
        </div>
      </b-col>
      <b-col md="3">
        <div class="justify-content-end text-right">
          <strong :class="{ 'text-white': layout === 'maps' }"
            >Display : &nbsp;</strong
          >
          <div class="btn-group">
            <button
              type="button"
              @click="layout = 'list'"
              class="btn btn-outline-primary"
              :class="{
                active: layout === 'list',
                'btn-outline-light': layout === 'maps',
              }"
            >
              <span class="fa fa-list"></span> Dashboard List
            </button>
            <button
              type="button"
              @click="layout = 'maps'"
              class="btn btn-outline-primary"
              :class="{ active: layout === 'maps' }"
            >
              <span class="fa fa-map"></span> Maps
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- End Maps -->
    <!-- List Dashboard -->
    <b-row v-if="layout === 'list'">
      <!-- OPS -->
      <b-col md="12">
        <iq-card
          class="iq-card-block iq-card-stretch iq-card-height text-center"
        >
          <template v-slot:body>
            <div class="btn-group w-100">
              <button
                v-if="isOperationalDashboardHasAccess"
                type="button"
                @click="dashboard = 'operational'"
                class="btn btn-outline-primary p-3 btn-menu"
                @mouseover="tabMenuOptions.operational.isHover = true"
                @mouseleave="tabMenuOptions.operational.isHover = false"
                :class="{ 'btn-menu-active': dashboard === 'operational' }"
              >
                Operational
                <OperationalIcon
                  :color="
                    tabMenuOptions.operational.isHover ||
                    dashboard === 'operational'
                      ? 'white'
                      : '#334697'
                  "
                  class="ml-2"
                />
              </button>
              <button
                v-if="isMIDashboardHasAccess"
                type="button"
                @click="dashboard = 'mi'"
                @mouseover="tabMenuOptions.mi.isHover = true"
                @mouseleave="tabMenuOptions.mi.isHover = false"
                class="btn btn-outline-primary p-3 btn-menu"
                :class="{ 'btn-menu-active': dashboard === 'mi' }"
              >
                Marine Insurance
                <MarineInsurance
                  :color="
                    tabMenuOptions.mi.isHover || dashboard === 'mi'
                      ? 'white'
                      : '#334697'
                  "
                  class="ml-2"
                />
              </button>
              <button
                v-if="isMIDashboardHasAccess"
                type="button"
                @click="dashboard = 'crewing'"
                @mouseover="tabMenuOptions.crewing.isHover = true"
                @mouseleave="tabMenuOptions.crewing.isHover = false"
                class="btn btn-outline-primary p-3 btn-menu"
                :class="{ 'btn-menu-active': dashboard === 'crewing' }"
              >
                Crewing
                <CrewingIconCircle
                  :color="
                    tabMenuOptions.crewing.isHover || dashboard === 'crewing'
                      ? 'white'
                      : '#334697'
                  "
                  class="ml-2"
                />
              </button>
              <button
                v-if="isMIDashboardHasAccess"
                type="button"
                @click="dashboard = 'technical_maintenance'"
                @mouseover="tabMenuOptions.technical.isHover = true"
                @mouseleave="tabMenuOptions.technical.isHover = false"
                class="btn btn-outline-primary p-3 btn-menu"
                :class="{
                  'btn-menu-active': dashboard === 'technical_maintenance',
                }"
              >
                Technical
                <TechnicalIcon
                  :color="
                    tabMenuOptions.technical.isHover ||
                    dashboard === 'technical_maintenance'
                      ? 'white'
                      : '#334697'
                  "
                  class="ml-2"
                />
              </button>
              <button
                v-if="isMIDashboardHasAccess"
                type="button"
                @click="dashboard = 'mi_list_unsettled'"
                @mouseover="tabMenuOptions.businesDev.isHover = true"
                @mouseleave="tabMenuOptions.businesDev.isHover = false"
                class="btn btn-outline-primary p-3 btn-menu"
                :class="{
                  'btn-menu-active': dashboard === 'mi_list_unsettled',
                }"
              >
                Business Development
                <BusinessIcon
                  :color="
                    tabMenuOptions.businesDev.isHover ||
                    dashboard === 'mi_list_unsettled'
                      ? 'white'
                      : '#334697'
                  "
                  class="ml-2"
                />
              </button>
              <!-- Apps - MI - Report Summary Menejemen -> active -->
              <!-- <button v-if="isMIDashboardHasAccess" type="button" @click="dashboard = 'mi_list_unsettled'" class="btn btn-outline-dark" :class="{ active: dashboard === 'mi_list_unsettled' }">
              On Going Vessel Case </button> -->
              <!-- Apps - MI - Report Summary Menejemen -> active -->
              <!-- <button v-if="isCrewingDashboardHasAccess" type="button" @click="dashboard = 'crewing'" class="btn btn-outline-danger" :class="{ active: dashboard === 'crewing' }">
             Crewing Dashboard </button> -->
              <!-- Apps - Crewing - Crew Dashboard -> export -->
            </div>
          </template>
        </iq-card>
      </b-col>

      <template v-if="dashboard === 'operational'">
        <OperationalDashboard
          :vehicleTypes="vehicleTypes"
          :vehicle="vehicle"
          :projectOptions="projectOptions"
          :optionsUsers="optionsUsers"
        />
      </template>
      <template v-else-if="dashboard === 'mi'">
        <SummaryManagement />
      </template>
      <template v-else-if="dashboard === 'technical_maintenance'">
        <TechnicalDashboardMain />
      </template>
      <template v-else-if="dashboard === 'crewing'">
        <CrewingDashboard />
      </template>
      <template v-else-if="dashboard === 'mi_list_unsettled'">
        <ListInitialTable
          :show-status-action="false"
          :show-filter="false"
          non-settled
          type-form="claim"
        />
      </template>
    </b-row>
    <!-- End List Dashboard -->
  </b-container>
</template>

<script>
import {
  fleetsActions,
  dashboardActions,
  projectsActions,
  maintenanceActions,
  reasonsActions,
  usersActions,
} from '@src/Utils/helper'
import moment from 'moment'
import SummaryManagement from '@src/views/MI/SummaryManagement'
import ListInitialTable from '@src/views/MI/ListInitialTable'
import CrewingDashboard from '@src/views/Crewing/Dashboard'
import OperationalIcon from './IconsTabMenus/OperationalIcon.vue'
import MarineInsurance from './IconsTabMenus/MarineInsurance.vue'
import CrewingIcon from './IconsTabMenus/CrewingIcon.vue'
import TechnicalIcon from './IconsTabMenus/TechnicalIcon.vue'
import BusinessIcon from './IconsTabMenus/BusinessIcon.vue'
import TechnicalDashboardMain from './TechnicalDashboardMain.vue'

import FilterMaps from './Components/FilterMaps.vue'
import ContentMaps from './Components/ContentMaps.vue'
import OperationalDashboard from './Components/OperationalDashboard.vue'
import CrewingIconCircle from './IconsTabMenus/CrewingIconCircle.vue'
/* const body = document.getElementsByTagName('body') */
export default {
  name: 'FleetDashboard',
  components: {
    ListInitialTable,
    SummaryManagement,
    CrewingDashboard,
    OperationalIcon,
    MarineInsurance,
    CrewingIcon,
    TechnicalIcon,
    BusinessIcon,
    TechnicalDashboardMain,
    FilterMaps,
    ContentMaps,
    OperationalDashboard,
    CrewingIconCircle,
  },
  data() {
    return {
      optionsUsers: [],
      layout: 'list',
      dashboard: 'operational',
      lang: {
        formatLocale: {
          // MMMM
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          // dddd
          weekdays: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ],
          // ddd
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          // dd
          weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          // first day of week
          firstDayOfWeek: 0,
          // first week contains January 1st.
          firstWeekContainsDate: 1,
        },
        monthBeforeYear: false,
      },
      vehicle: [],
      categoryOwnership: [
        { value: '', text: 'All Category Ownership' },
        { value: 'OWNED', text: 'Owned By Company' },
        { value: 'CHARTERED', text: 'Chartered' },
      ],
      projectOptions: [],
      params: {
        ownership: '',
        search: '',
        page: 1,
        active: true,
        showAll: true,
      },
      maintenance: {
        dateRange: [
          new Date(moment().subtract(1, 'months').startOf('month')),
          new Date(moment().endOf('month')),
        ],
        isLoading: false,
        params: {
          action: '',
          search: '',
          status: 'OVERDUE',
          startDate: null,
          endDate: null,
          perPage: 250,
          page: 1,
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      fleetActivities: [],
      vehicleTypes: [],
      filterDailyOps: {
        date: moment().format('YYYY-MM-DD'),
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [],
        pic: [],
      },
      filterMonthlyOps: {
        dateRange: [],
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [],
        pic: [],
      },
      tabMenuOptions: {
        operational: {
          isHover: false,
          isActive: false,
        },
        mi: {
          isHover: false,
          isActive: false,
        },
        crewing: {
          isHover: false,
          isActive: false,
        },
        technical: {
          isHover: false,
          isActive: false,
        },
        businesDev: {
          isHover: false,
          isActive: false,
        },
      },
      dailyOpsData: [],
      isFetchChart: false,
      fleetDetail: null,
      reasons: [],
      lsProfile: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    role() {
      return this.lsProfile && this.lsProfile.role
        ? this.lsProfile.role.roles.toUpperCase()
        : ''
    },
    isOperationalDashboardHasAccess() {
      // Dashboard -> active
      let hasAccess = this.lsProfile?.menu?.find(
        (menu) => menu?.menu === 'Dashboard'
      )?.active

      return hasAccess
    },
    isMIDashboardHasAccess() {
      // Apps - MI - Report Summary Menejemen -> active
      let hasAccess = this.lsProfile?.menu
        ?.find((menu) => menu.menu === 'Apps')
        ?.child?.find((child) => child?.menu === 'Marine Insurance')
        ?.child?.find(
          (childMi) => childMi?.menu === 'Report - Summary Management'
        )?.active

      return hasAccess
    },
    isCrewingDashboardHasAccess() {
      // Apps - Crewing - Crew Dashboard -> export
      let hasAccess = this.lsProfile?.menu
        ?.find((menu) => menu?.menu === 'Apps')
        ?.child?.find((child) => child?.menu === 'Crewing')
        ?.child?.find((childMi) => childMi?.menu === 'Crew Dashboard')?.active

      return hasAccess
    },
  },
  async mounted() {
    await this.fetchUsers()
    await this.fetchReasons()
    await this.fetchFleetTypes()
    await this.getProjectList()
    await this.fetchFleetActivities()
    await this.getMaintenanceList()
  },
  destroyed() {
    /* body[0].classList.remove('sidebar-main-menu') */
  },
  methods: {
    ...fleetsActions,
    ...dashboardActions,
    ...projectsActions,
    ...maintenanceActions,
    ...reasonsActions,
    ...usersActions,
    async fetchUsers() {
      this.optionsUsers = []
      let params = {}
      const res = await this.getUsersPic(params)

      if (res.status === 'success') {
        this.optionsUsers = res.data.data.map((val) => ({
          value: val.id,
          text: val.name,
        }))
      }
    },
    async getMaintenanceList() {
      this.maintenance.isLoading = true
      this.maintenance.data = []

      const paramsQ = {
        search: this.maintenance.params.search,
        perPage: this.maintenance.params.perPage,
        page: this.maintenance.params.page,
        status: this.maintenance.params.status,
      }

      if (paramsQ.search === '') {
        delete paramsQ.search
      }

      const res = await this.getMaintenance(paramsQ)

      if (res.status === 'success') {
        if (res.data.data && res.data.data.length > 0) {
          const groupData = [
            ...new Set(res.data.data.map((item) => item.vehicleName)),
          ].map((group) => {
            return {
              group: group,
              jobs: res.data.data.filter(
                (maintenance) => maintenance.vehicleName === group
              ),
            }
          })

          this.maintenance.data = groupData
          this.maintenance.meta.perPage = res.data.perPage
          this.maintenance.meta.currentPage = res.data.currentPage
          this.maintenance.meta.total = res.data.total
          this.maintenance.meta.totalPage = res.data.totalPage
        }
        this.maintenance.isLoading = false
      } else {
        this.maintenance.data = []
        this.maintenance.isLoading = false
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async fetchFleetActivities() {
      let params = { ...this.params }

      if (this.vehicleTypes.length > 0) {
        params.vehicleTypeId = this.vehicleTypes
          .filter((type) => type.checked)
          .map((type) => type.id)
          .join(',')
      }

      params = this.$options.filters.cleanObject(params)

      let res = await this.getFleetActivities(params)

      this.fleetActivities = res.data
    },
    async fetchFleetTypes() {
      try {
        let params = { active: true }
        let res = await this.getFleetTypes(params)
        let data = []
        res?.data?.forEach((f) => {
          data.push({
            ...f,
            checked: true,
            disabled: false,
          })
          this.vehicle.push({
            value: f.id,
            text: f.name,
            disabled: false,
          })
        })

        this.vehicleTypes = data
      } catch (error) {}
    },
    async fetchFleetDetail(id) {
      const res = await this.getFleetById(id)

      if (res.status === 'success') {
        this.fleetDetail = res.data
        this.$bvModal.show('edit-fleet')
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async onDeleteFleet(dataVal) {
      let data = dataVal

      this.$swal({
        title: 'Delete Fleet?',
        text: `Fleet ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteFleet(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.fetchFleetActivities()
            this.$swal(
              `Fleet deleted!`,
              `Fleet ${data.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    async fetchReasons() {
      let params = {
        active: true,
        perPage: 50,
        page: 1,
      }

      let res = await this.getReasons(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.reasons = res.data.data
        }
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async getProjectList() {
      let params = {
        active: true,
        search: '',
        page: 1,
        perPage: 250,
      }
      const res = await this.getProjects(params)
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res?.data?.data?.forEach((val) => {
            this.projectOptions.push({
              project: val.projectName,
              sub: val.subProject,
            })
          })
        }
      } else {
        this.$swal(`Oops!`, res.data.data, 'error')
      }
    },
  },
}
</script>

<style scoped>
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.btn-menu:hover {
  background: #334697 !important;
  border: #334697;
}

.btn-menu-active {
  background: #334697 !important;
  color: white !important;
}
</style>

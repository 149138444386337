var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-modal', {
    attrs: {
      "centered": "",
      "size": "lg",
      "title": "Filter Data",
      "ok-title": "Filter"
    },
    on: {
      "ok": _vm.fetchFleetActivities
    },
    model: {
      value: _vm.modalFilter,
      callback: function callback($$v) {
        _vm.modalFilter = $$v;
      },
      expression: "modalFilter"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Category",
      "label-for": "cat",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "id": "cat",
      "options": _vm.vehicleTypes,
      "multiple": true,
      "track-by": "id",
      "placeholder": "Select Categories",
      "label": "name",
      "value": "id"
    },
    model: {
      value: _vm.vehicleSelected,
      callback: function callback($$v) {
        _vm.vehicleSelected = $$v;
      },
      expression: "vehicleSelected"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Company",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.CompanyName,
      "id": "gender"
    },
    model: {
      value: _vm.params.companyId,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "companyId", $$v);
      },
      expression: "params.companyId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Category Ownership",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.categoryOwnership,
      "id": "gender"
    },
    model: {
      value: _vm.params.ownership,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "ownership", $$v);
      },
      expression: "params.ownership"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Fleets")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$CompanyName$find$, _vm$CompanyName$find, _vm$categoryOwnership, _vm$categoryOwnership2, _vm$fleetSelected, _vm$fleetSelected$pic, _vm$fleetSelected2, _vm$fleetSelected3, _vm$fleetSelected4;
        return [_vm.loading ? _c('div', {
          staticClass: "text-center my-5 w-100"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_c('b-row', [_c('b-col', {
          staticClass: "d-flex justify-content-between mb-3",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          attrs: {
            "size": "lg",
            "placeholder": "search name..."
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        }), _c('b-button', {
          staticClass: "mx-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.fetchFleetActivities
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })]), _c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.toggleFilter
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data ")])])], 1), _c('div', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('h5', {
          staticClass: "mr-2"
        }, [_vm._v("View : ")]), _c('b-button-group', [_c('b-button', {
          staticClass: "p-2 px-3 button-view",
          attrs: {
            "variant": _vm.view == 'large' ? 'primary' : 'outline-primary'
          },
          on: {
            "click": function click($event) {
              _vm.view = 'large';
            }
          }
        }, [_c('i', {
          staticClass: "fa m-0 fa-th-large"
        })]), _c('b-button', {
          staticClass: "p-2 px-3 button-view",
          attrs: {
            "variant": _vm.view == 'list' ? 'primary' : 'outline-primary'
          },
          on: {
            "click": function click($event) {
              _vm.view = 'list';
            }
          }
        }, [_c('i', {
          staticClass: "fa m-0 fa-bars"
        })])], 1)], 1)])], 1), _c('div', {
          staticStyle: {
            "padding": "12px 14px"
          }
        }, [_c('b-row', {
          staticClass: "border rounded-md pt-2"
        }, [_vm.vehicleSelected.length ? _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category :"
          }
        }, [_vm.vehicleSelected.length ? _vm._l(_vm.vehicleSelected, function (vehicle, index) {
          return _c('b-badge', {
            key: index,
            staticClass: "mr-1",
            attrs: {
              "variant": "success"
            }
          }, [_vm._v(_vm._s(vehicle.name))]);
        }) : _c('div', [_vm._v("-")])], 2)], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company :"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s((_vm$CompanyName$find$ = (_vm$CompanyName$find = _vm.CompanyName.find(function (el, i) {
          return el.value == _vm.params.companyId;
        })) === null || _vm$CompanyName$find === void 0 ? void 0 : _vm$CompanyName$find.text) !== null && _vm$CompanyName$find$ !== void 0 ? _vm$CompanyName$find$ : 'All Company'))])])], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category Ownership :"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s((_vm$categoryOwnership = (_vm$categoryOwnership2 = _vm.categoryOwnership.find(function (el, i) {
          return el.value == _vm.params.ownership;
        })) === null || _vm$categoryOwnership2 === void 0 ? void 0 : _vm$categoryOwnership2.text) !== null && _vm$categoryOwnership !== void 0 ? _vm$categoryOwnership : 'All Category Ownership'))])])], 1)], 1)], 1), _vm.view == 'large' ? _c('b-row', _vm._l(_vm.fleetActivities, function (f, i) {
          var _f$vehicleType$name, _f$vehicleType, _f$company$company, _f$company;
          return _c('b-col', {
            key: "f-".concat(i),
            attrs: {
              "md": "6",
              "lg": "4"
            }
          }, [_c('b-card', {
            staticClass: "iq-mb-3 shadow-sm border rounded"
          }, [_c('b-card-text', {
            staticClass: "mb-0"
          }, [_c('div', {
            staticClass: "fleet-card-wrap mb-3"
          }, [_c('div', {
            staticClass: "overflow-hidden img-card-shadow",
            staticStyle: {
              "position": "relative"
            }
          }, [_c('div', {
            staticClass: "p-2",
            staticStyle: {
              "position": "absolute"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.handleOpenMI(f);
              }
            }
          }, [_c('b-badge', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.top",
              value: 'Total Claim : ' + f.miData.totalClaim,
              expression: "'Total Claim : ' +f.miData.totalClaim",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-1 cursor-pointer",
            attrs: {
              "variant": "success"
            }
          }, [_vm._v("Claim : " + _vm._s(f.miData.totalClaim))]), _c('b-badge', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.top",
              value: 'Total Non Claim : ' + f.miData.totalNonClaim,
              expression: "'Total Non Claim : ' +f.miData.totalNonClaim",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor-pointer",
            attrs: {
              "variant": "warning"
            }
          }, [_vm._v("Non Claim : " + _vm._s(f.miData.totalNonClaim))])], 1), _c('b-badge', {
            staticClass: "px-4 py-1 rounded-none",
            staticStyle: {
              "min-width": "120px",
              "position": "absolute",
              "right": "-28px",
              "top": "24px",
              "rotate": "45deg"
            },
            attrs: {
              "variant": _vm.getStatusColor(f.status)
            }
          }, [_vm._v(_vm._s(f.status ? f.status.replace(/_/g, " ") : '-'))]), _c('img', {
            staticClass: "w-100",
            staticStyle: {
              "position": "relative",
              "z-index": "-1"
            },
            attrs: {
              "src": require('../../assets/images/example-fleets.png'),
              "alt": "f",
              "height": "240"
            }
          })], 1), _c('div', {
            staticClass: "my-2 mt-3 content-text d-flex justify-content-between w-100 align-items-center",
            staticStyle: {
              "color": "#FFF000"
            }
          }, [_c('div', [_c('div', {
            staticClass: "d-flex"
          }, [_c('img', {
            staticClass: "mr-2",
            attrs: {
              "src": "https://flagsapi.com/ID/shiny/24.png",
              "alt": "flags"
            }
          }), _c('router-link', {
            attrs: {
              "to": {
                path: 'fleet-information',
                query: {
                  vehicleId: f.id,
                  menuObject: _vm.hashObject(f.modules[2])
                }
              }
            }
          }, [_c('h5', {
            staticStyle: {
              "color": "#FFF000"
            }
          }, [_c('strong', [_vm._v(_vm._s(f.name))])])])], 1), _c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('p', {
            staticClass: "text-sm m-0 text-white"
          }, [_vm._v(_vm._s((_f$vehicleType$name = (_f$vehicleType = f.vehicleType) === null || _f$vehicleType === void 0 ? void 0 : _f$vehicleType.name) !== null && _f$vehicleType$name !== void 0 ? _f$vehicleType$name : '-'))]), _c('div', {
            staticClass: "mx-2",
            staticStyle: {
              "height": "6px",
              "width": "6px",
              "border-radius": "50%",
              "background-color": "white"
            }
          }), _c('p', {
            staticClass: "text-sm m-0 text-white"
          }, [_vm._v(_vm._s((_f$company$company = (_f$company = f.company) === null || _f$company === void 0 ? void 0 : _f$company.company) !== null && _f$company$company !== void 0 ? _f$company$company : '-'))])])]), _c('b-dropdown', {
            attrs: {
              "size": "sm",
              "text": "Small",
              "variant": "none",
              "no-caret": "",
              "right": ""
            }
          }, [_c('template', {
            slot: "button-content"
          }, [_c('div', [_c('i', {
            staticClass: "fa fa-ellipsis-h cursor-pointer text-white",
            staticStyle: {
              "rotate": "90deg"
            }
          })])]), _c('div', {
            staticClass: "p-2",
            on: {
              "click": function click($event) {
                _vm.fleetSelected = f;
              }
            }
          }, [_vm.permissionDeleteFleet ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-primary",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.fetchFleetDetail(f.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          }), _vm._v(" Update ")]) : _vm._e(), _vm.permissionDeleteFleet ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteFleet(f);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          }), _vm._v(" Delete ")]) : _vm._e(), _vm.permissionDeleteFleet ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-warning",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.modalCrewList = true;
              }
            }
          }, [_c('i', {
            staticClass: "ri-group-fill"
          }), _vm._v(" Crew List ")]) : _vm._e(), _vm.permissionDeleteFleet ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-info",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.modalPicList = true;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-user"
          }), _vm._v(" PIC List ")]) : _vm._e(), _vm.permissionMaintenanceSetup ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.showModal.setupComponent = true;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-file"
          }), _vm._v(" Setup Component ")]) : _vm._e(), _vm.permissionMaintenanceSetup ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.showModal.setupComponent = true;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-file"
          }), _vm._v(" Maintenance Initiation ")]) : _vm._e(), _vm.permissionSpecialSurvey ? [f !== null && f !== void 0 && f.unscheduledDocking.includes('INTERMEDIATE') ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.$router.push("/docking/dashboard?vehicleId=".concat(f === null || f === void 0 ? void 0 : f.id, "&typeSchedule=", 'intermerdiate', "&menuObject=").concat(_vm.hashObject(f === null || f === void 0 ? void 0 : f.modules[6])));
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-ship"
          }), _vm._v(" Set intermediate Survey ")]) : _vm._e(), f !== null && f !== void 0 && f.unscheduledDocking.includes('SPECIAL') ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-info",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.$router.push("/docking/dashboard?vehicleId=".concat(f === null || f === void 0 ? void 0 : f.id, "&typeSchedule=", 'special_value', "&menuObject=").concat(_vm.hashObject(f === null || f === void 0 ? void 0 : f.modules[6])));
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-ship"
          }), _vm._v(" Set Special Survey ")]) : _vm._e(), _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-warning",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenSlideFleetStatus(f === null || f === void 0 ? void 0 : f.id, f === null || f === void 0 ? void 0 : f.status);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-ship"
          }), _vm._v(" Change Fleet Status ")])] : _vm._e()], 2)], 2)], 1)]), _c('b-row', {
            staticClass: "text-center"
          }, [_vm._l(f.modules, function (m, im) {
            return [m.hasAccess && (_vm.validateTechnicalPermission(m.url) || _vm.validateOperationalPermission(m.url) || _vm.validateReportPermission(m.url)) ? _c('b-col', {
              key: "m-".concat(im),
              staticClass: "mb-3",
              attrs: {
                "md": "3"
              }
            }, [_c('router-link', {
              attrs: {
                "to": {
                  path: m.url,
                  query: {
                    vehicleId: f.id,
                    menuObject: _vm.hashObject(m)
                  }
                }
              }
            }, [_c('div', {
              staticClass: "d-flex flex-column"
            }, [m.icon && m.icon.url ? _c('img', {
              directives: [{
                name: "b-tooltip",
                rawName: "v-b-tooltip.top",
                value: m.name,
                expression: "m.name",
                modifiers: {
                  "top": true
                }
              }],
              attrs: {
                "src": m.icon.url,
                "alt": "Responsive image",
                "width": ""
              }
            }) : _c('img', {
              directives: [{
                name: "b-tooltip",
                rawName: "v-b-tooltip.top",
                value: 'Operational',
                expression: "'Operational'",
                modifiers: {
                  "top": true
                }
              }],
              attrs: {
                "src": require("../../assets/images/flaticon/operational.png"),
                "height": "",
                "alt": "Responsive image"
              }
            }), _c('p', {
              staticClass: "mb-0 text-nowrap mt-1",
              staticStyle: {
                "font-size": "10px !important"
              }
            }, [_vm._v(_vm._s(m.name))])])])], 1) : _vm._e(), m.hasAccess && false ? _c('b-col', {
              key: "m-b-".concat(im),
              attrs: {
                "md": "12"
              }
            }, [_c('router-link', {
              attrs: {
                "to": "/ops/docking"
              }
            }, [_c('b-button', {
              staticClass: "mb-3",
              attrs: {
                "block": "",
                "variant": "success"
              }
            }, [_c('i', {
              staticClass: "fa fa-ship"
            }), _vm._v("Docking Intermediate Survey")])], 1)], 1) : _vm._e()];
          })], 2), _c('router-link', {
            attrs: {
              "to": {
                path: 'fleet-information',
                query: {
                  vehicleId: f.id,
                  menuObject: _vm.hashObject(f.modules[2])
                }
              }
            }
          }, [_c('b-button', {
            staticClass: "w-100 d-flex justify-content-between align-items-center px-2",
            staticStyle: {
              "padding": "10px 0"
            },
            attrs: {
              "variant": "warning"
            }
          }, [_c('div', {
            staticClass: "d-flex"
          }, [_c('OperationalIcon', {
            staticClass: "mr-1",
            staticStyle: {
              "height": "20px",
              "width": "20px"
            }
          }), _c('p', {
            staticClass: "mb-0"
          }, [_vm._v("Detail Fleet")])], 1), _c('i', {
            staticClass: "fas fa-chevron-right text-lg"
          })])], 1)], 1), _c('b-card-text', [_vm.permissionMaintenanceSetup ? _c('b-row', [[_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-card', {
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            staticClass: "p-0 bg-transparent",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('b-modal', {
            attrs: {
              "id": "clone_component",
              "size": "large",
              "title": "Duplikasi Komponen Kapal",
              "header-bg-variant": "light",
              "header-text-variant": "dark",
              "footer-bg-variant": "light",
              "footer-text-variant": "dark"
            },
            model: {
              value: _vm.showModal.setupComponent,
              callback: function callback($$v) {
                _vm.$set(_vm.showModal, "setupComponent", $$v);
              },
              expression: "showModal.setupComponent"
            }
          }, [_c('b-alert', {
            attrs: {
              "show": true,
              "variant": "primary"
            }
          }, [_c('div', {
            staticClass: "iq-alert-icon"
          }, [_c('i', {
            staticClass: "ri-alert-line"
          })]), _c('div', {
            staticClass: "iq-alert-text"
          }, [_c('b', [_vm._v("Perhatikan")]), _vm._v(" Pastikan kapal yang akan diduplikasi komponen nya sudah sesuai datanya")])]), _c('b-row', [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Dari Komponen Kapal*",
              "label-for": "fleet"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "size": "lg"
            }
          }, [_c('b-form-select-option', [_vm._v("Select Fleet")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 1")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 2")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 1")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 2")])], 1)], 1)], 1), _c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Ke Komponen Kapal*",
              "label-for": "fleet"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "size": "lg"
            }
          }, [_c('b-form-select-option', [_vm._v("Select Fleet")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 1")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 2")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 1")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 2")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]], 2) : _vm._e()], 1)], 1)], 1);
        }), 1) : _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table border mb-0 table-striped table-hover"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Vessel Name")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Vessel Type")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Crew")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Claim")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Non Claim")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("PIC Name")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.fleetActivities, function (f, i) {
          var _f$pic$, _f$pic$2, _f$unscheduledDocking, _f$unscheduledDocking2;
          return _c('tr', {
            key: "f-".concat(i)
          }, [_c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(i + 1) + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('router-link', {
            attrs: {
              "to": {
                path: 'fleet-information',
                query: {
                  vehicleId: f.id,
                  menuObject: _vm.hashObject(f.modules[2])
                }
              }
            }
          }, [_c('strong', [_vm._v(_vm._s(f.name))])])], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(f.vehicleType.name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('b-badge', {
            staticClass: "cursor-pointer",
            attrs: {
              "variant": "info"
            },
            on: {
              "click": function click($event) {
                _vm.fleetSelected = f, _vm.modalCrewList = true;
              }
            }
          }, [_vm._v("crew : " + _vm._s(f.total_crew))])], 1), _c('td', {
            staticClass: "text-truncate text-left"
          }, [_c('b-badge', {
            staticClass: "cursor-pointer",
            staticStyle: {
              "min-width": "50px"
            },
            attrs: {
              "variant": "success"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOpenMI(f);
              }
            }
          }, [_vm._v(_vm._s(f.miData.totalClaim))])], 1), _c('td', {
            staticClass: "text-truncate text-left"
          }, [_c('b-badge', {
            staticClass: "cursor-pointer",
            staticStyle: {
              "min-width": "50px"
            },
            attrs: {
              "variant": "warning"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOpenMI(f);
              }
            }
          }, [_vm._v(_vm._s(f.miData.totalNonClaim))])], 1), _c('td', {}, [f.pic.length ? _c('div', {
            staticClass: "text-truncate d-flex align-items-center"
          }, [_c('b-avatar', {
            staticClass: "mr-1",
            attrs: {
              "variant": "warning",
              "size": "34"
            }
          }), _c('div', {
            staticClass: "ml-1",
            staticStyle: {
              "font-size": "small"
            }
          }, [_vm._v(" " + _vm._s((_f$pic$ = f.pic[0]) === null || _f$pic$ === void 0 ? void 0 : _f$pic$.name)), _c('br'), _c('small', {
            staticClass: "p-0"
          }, [_vm._v(_vm._s((_f$pic$2 = f.pic[0]) === null || _f$pic$2 === void 0 ? void 0 : _f$pic$2.email))])])], 1) : _c('div', [_vm._v("-")])]), _c('td', [_c('b-badge', {
            staticStyle: {
              "min-width": "60px"
            },
            attrs: {
              "variant": _vm.getStatusColor(f.status)
            }
          }, [_vm._v(_vm._s(f.status.replace(/_/g, " ")))])], 1), _c('td', {}, [_c('b-dropdown', {
            staticClass: "p-0",
            attrs: {
              "id": "dropdown-1",
              "text": "Actions",
              "size": "sm",
              "small": "",
              "variant": "dark"
            }
          }, [_c('div', {
            staticClass: "p-2",
            on: {
              "click": function click($event) {
                _vm.fleetSelected = f;
              }
            }
          }, [_vm.permissionDeleteFleet ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-primary",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.fetchFleetDetail(f.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          }), _vm._v(" Update ")]) : _vm._e(), _vm.permissionDeleteFleet ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteFleet(f);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          }), _vm._v(" Delete ")]) : _vm._e(), _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-warning",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.modalCrewList = true;
              }
            }
          }, [_c('i', {
            staticClass: "ri-group-fill"
          }), _vm._v(" Crew List ")]), _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-info",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.modalPicList = true;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-user"
          }), _vm._v(" PIC List ")]), _vm.permissionMaintenanceSetup ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.showModal.setupComponent = true;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-file"
          }), _vm._v(" Setup Component ")]) : _vm._e(), _vm.permissionMaintenanceSetup ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.showModal.setupComponent = true;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-file"
          }), _vm._v(" Maintenance Initiation ")]) : _vm._e(), _vm.permissionSpecialSurvey ? [f !== null && f !== void 0 && (_f$unscheduledDocking = f.unscheduledDocking) !== null && _f$unscheduledDocking !== void 0 && _f$unscheduledDocking.includes('INTERMEDIATE') ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.$router.push("/docking/dashboard?vehicleId=".concat(f === null || f === void 0 ? void 0 : f.id, "&typeSchedule=", 'intermerdiate', "&menuObject=").concat(_vm.hashObject(f === null || f === void 0 ? void 0 : f.modules[6])));
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-ship"
          }), _vm._v(" Set intermediate Survey ")]) : _vm._e(), f !== null && f !== void 0 && (_f$unscheduledDocking2 = f.unscheduledDocking) !== null && _f$unscheduledDocking2 !== void 0 && _f$unscheduledDocking2.includes('SPECIAL') ? _c('b-button', {
            staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
            attrs: {
              "variant": "outline-info",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.$router.push("/docking/dashboard?vehicleId=".concat(f === null || f === void 0 ? void 0 : f.id, "&typeSchedule=", 'special_value', "&menuObject=").concat(_vm.hashObject(f === null || f === void 0 ? void 0 : f.modules[6])));
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-ship"
          }), _vm._v(" Set Special Survey ")]) : _vm._e()] : _vm._e()], 2)])], 1)]);
        }), 0)])]), _c('b-row', [!_vm.isHideLoadMore ? _c('b-col', {
          attrs: {
            "md": "auto mx-auto my-3"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "dark px-4 py-3"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchFleetActivitiesLoadMore();
            }
          }
        }, [_vm.isFetchingLoadMore ? _c('b-spinner', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "label": "Loading..."
          }
        }) : _vm._e(), _vm._v(" load more")], 1)], 1) : _vm._e()], 1), _c('b-modal', {
          attrs: {
            "title": "List PIC (".concat((_vm$fleetSelected = _vm.fleetSelected) === null || _vm$fleetSelected === void 0 ? void 0 : (_vm$fleetSelected$pic = _vm$fleetSelected.pic) === null || _vm$fleetSelected$pic === void 0 ? void 0 : _vm$fleetSelected$pic.length, ")"),
            "ok-only": ""
          },
          model: {
            value: _vm.modalPicList,
            callback: function callback($$v) {
              _vm.modalPicList = $$v;
            },
            expression: "modalPicList"
          }
        }, [_c('ListPic', {
          attrs: {
            "picData": (_vm$fleetSelected2 = _vm.fleetSelected) === null || _vm$fleetSelected2 === void 0 ? void 0 : _vm$fleetSelected2.pic
          }
        })], 1), _c('b-modal', {
          attrs: {
            "title": "List Crew (".concat((_vm$fleetSelected3 = _vm.fleetSelected) === null || _vm$fleetSelected3 === void 0 ? void 0 : _vm$fleetSelected3.total_crew, ")"),
            "ok-only": ""
          },
          model: {
            value: _vm.modalCrewList,
            callback: function callback($$v) {
              _vm.modalCrewList = $$v;
            },
            expression: "modalCrewList"
          }
        }, [_c('ListCrew', {
          attrs: {
            "crewData": (_vm$fleetSelected4 = _vm.fleetSelected) === null || _vm$fleetSelected4 === void 0 ? void 0 : _vm$fleetSelected4.crew_data
          }
        })], 1), _c('b-modal', {
          attrs: {
            "id": "edit-fleet",
            "size": "xl",
            "no-close-on-backdrop": true,
            "header-bg-variant": _vm.modal.headerBgVariant,
            "header-text-variant": _vm.modal.headerTextVariant,
            "body-bg-variant": _vm.modal.bodyBgVariant,
            "body-text-variant": _vm.modal.bodyTextVariant,
            "hide-footer": ""
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn(_ref) {
              var close = _ref.close;
              return [_c('h5', [_vm._v("Edit Your Fleet")]), _c('b-button', {
                attrs: {
                  "size": "md",
                  "variant": "outline-danger"
                },
                on: {
                  "click": close
                }
              }, [_c('i', {
                staticClass: "fa fa-times"
              }), _vm._v(" Close ")])];
            }
          }])
        }, [_c('AddFleet', {
          attrs: {
            "formData": _vm.fleetDetail
          },
          on: {
            "save": function save($event) {
              return _vm.fetchFleetActivities();
            },
            "childAction": function childAction($event) {
              return _vm.$bvModal.hide('edit-fleet');
            }
          }
        })], 1), _vm.fleetDetailMiDataModal ? _c('b-sidebar', {
          attrs: {
            "id": "sidebar-mi",
            "title": "".concat(_vm.fleetDetailMiDataModal.name, " - TOTAL CASE DETAIL"),
            "width": "1350px",
            "backdrop-variant": "dark",
            "right": "",
            "backdrop": "",
            "shadow": ""
          }
        }, [_c('b-row', {
          staticClass: "pt-2 pl-3 pr-3 pb-2"
        }, [_c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "md": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('iq-card', {
          attrs: {
            "body-class": "p-0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "iq-edit-list"
              }, [_c('tab-nav', {
                staticClass: "iq-edit-profile d-flex",
                attrs: {
                  "pills": true
                }
              }, [_c('tab-nav-items', {
                staticClass: "col-md-3 p-0",
                attrs: {
                  "active": true,
                  "href": "#accident",
                  "title": "Accident / Incident"
                }
              }), _c('tab-nav-items', {
                staticClass: "col-md-3 p-0",
                attrs: {
                  "active": false,
                  "href": "#recommendation",
                  "title": "Recommendation"
                }
              }), _c('tab-nav-items', {
                staticClass: "col-md-3 p-0",
                attrs: {
                  "active": false,
                  "href": "#evaluation",
                  "title": "Evaluation"
                }
              }), _c('tab-nav-items', {
                staticClass: "col-md-3 p-0",
                attrs: {
                  "active": false,
                  "href": "#erm",
                  "title": "Enterprise Risk Management"
                }
              })], 1)], 1)];
            },
            proxy: true
          }], null, false, 3080177949)
        })], 1), _c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "pills-tabContent-3"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "accident"
          }
        }, [_c('SummaryManagementDetailAccidentList', {
          attrs: {
            "data": _vm.fleetDetailMiDataModal.forms
          }
        })], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "recommendation"
          }
        }, [_c('SummaryManagementRecommendations', {
          attrs: {
            "lists": _vm.fleetDetailMiDataModal.recommendations
          }
        })], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "evaluation"
          }
        }, [_c('SummaryManagementEvaluations', {
          attrs: {
            "lists": _vm.fleetDetailMiDataModal.evaluations
          }
        })], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "erm"
          }
        }, [_c('SummaryManagementERM', {
          attrs: {
            "lists": _vm.fleetDetailMiDataModal.erm
          }
        })], 1)], 1)])], 1)], 1) : _vm._e(), _c('b-sidebar', {
          attrs: {
            "id": "change-fleet-status",
            "title": "Fleet Status Update",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          model: {
            value: _vm.slideChangeFleetStatus,
            callback: function callback($$v) {
              _vm.slideChangeFleetStatus = $$v;
            },
            expression: "slideChangeFleetStatus"
          }
        }, [_c('SlideFleetStatusUpdate', {
          attrs: {
            "onOpenSlideFleetStatus": _vm.onOpenSlideFleetStatus,
            "fleetId": _vm.fleetId,
            "statusFleet": _vm.statusFleet
          }
        })], 1)]];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
 <template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" v-if="incident">
        <StepWizard
          current-step="RECOMMENDATION"
          :claim-type="incident.type_form === 'non_claim' ? 'NON_CLAIM' : 'CLAIM'"
        />
      <div class="col-md-12 mb-2">
        <b-button  variant="danger" @click="$router.push('/mi/list-initial')">Back</b-button>
      </div>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template #headerTitle>
            <h4>
              <strong class="text-primary">Temporary Recommendation</strong>
            </h4>
          </template>
          <template #body>
            <b-row>
              <template v-if="forms?.length">
                <b-col md="12" v-for="(item, i) in forms" :key="i">
                  <div @click="selectTemp(i)" class=" p-2 px-3 d-flex align-items-center justify-content-between item-temp cursor-pointer"  :style="{fontWeight: '600', background: tempIdx === i ? '#C7EBFF':''}">
                    <p class="m-0 text-black" >{{ linkToCaseOptions.find((el)=>el.value == item.form_id )?.text ?? '-' }}</p>
                    <i class="fas fa-close text-danger cursor-pointer" @click="deleteTemporary(i)"></i>
                  </div>
                </b-col>
              </template>
              <template v-else>
                <b-col md="12">
                  <h6>
                    No Recomendation Found
                  </h6>
                </b-col>
              </template>
              <b-col md="12" class="mt-3">
                <b-button variant="success" class="w-100" :disabled="!forms.length" @click="onSave()">
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="8">
        <iq-card>
          <template #headerTitle>
            <h4>
              <strong class="text-primary">Form Recommendation</strong>
            </h4>
          </template>
          <template v-slot:body>
            <!-- <form @submit.prevent="onSave()">
              <b-row>
                <b-col cols="6">
                  <img :src="image" alt="logo" class="img-fluid w-20">
                </b-col>
                <b-col cols="6" align-self="center">
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
                <b-col cols="12">
                  <div class="table-responsive">
                    <b-row v-if="$route.query.formId">
                      <b-col cols="4">
                        <b-form-group label="Link to Case" class="mt-2">
                          <v-select v-model="linkToCase" placeholder="Link To Case"
                            :options="linkToCaseOptions" disabled
                            label="text"
                            :reduce="(type) => type.value"
                            :clearable="false">
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group label="Notif to Department" class="mt-2">
                          <v-multiselect   v-model="department_ids" placeholder="Notif" label="name"
                            track-by="id" :options="notifOptions" :multiple="true">
                            <span slot="noResult">Data not found.</span>
                          </v-multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group label="Notif mail to user" class="mt-2">
                          <v-multiselect   v-model="notifMailUser" placeholder="Notif user" label="name"
                            track-by="id" :options="optionsUser" :multiple="true">
                            <span slot="noResult">Data not found.</span>
                          </v-multiselect>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Permasalahan</th>
                          <th>Vessel Case</th>
                          <th>Root Cause</th>
                          <th>Rekomendasi</th>
                          <th>Cause of Loss</th>
                          <th>Status</th>
                          <th>Closed Date</th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody v-for="(form, idx) in forms" :key="idx">
                        <tr v-if="!$route.query.formId">
                          <td colspan="4">
                            <b-form-group label="Link to Case" class="mt-5">
                              <v-select v-model="form.form_id" placeholder="Link To Case"
                                :options="linkToCaseOptions"
                                label="text"
                                :reduce="(type) => type.value"
                                :clearable="false">
                              </v-select>
                            </b-form-group>
                          </td>
                          <td colspan="5">
                            <b-form-group label="Notif to Department" class="mt-5">
                              <v-multiselect   v-model="form.department_ids" placeholder="Notif" label="name"
                                track-by="id" :options="notifOptions" :multiple="true">
                                <span slot="noResult">Data not found.</span>
                              </v-multiselect>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>{{ idx + 1 }}.</td>
                          <td>
                            <b-form-textarea   v-model="form.problem" id="exampleFormControlTextarea1" rows="3">
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.vessel_case" id="exampleFormControlTextarea1" rows="3">
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.root_cause" id="exampleFormControlTextarea1" rows="3">
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.recommendation" id="exampleFormControlTextarea1" rows="3">
                            </b-form-textarea>
                          </td>
                          <td>
                            <v-multiselect   v-model="form.cause_of_loss_id" placeholder="Cause of Loss" label="name"
                              track-by="id" :options="causeOfLossOptions">
                              <span slot="noResult">Data not found.</span>
                            </v-multiselect>
                          </td>
                          <td>
                            <v-multiselect   v-model="form.status" placeholder="Status" label="name" track-by="id"
                              :options="statusOptions">
                              <span slot="noResult">Data not found.</span>
                            </v-multiselect>
                          </td>
                          <td>
                            <date-picker v-model="form.closed_at" name="closedDate" type="date" value-type="YYYY-MM-DD"
                              format="DD MMM YYYY" placeholder="Select date"></date-picker>
                          </td>
                          <td>
                            <b-button v-if="!$route.params.id " @click="deleteForm(idx)" variant="danger" size="sm"><i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-button v-if="!$route.params.id " @click="addForm" variant="warning" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add
                      More Data</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="text-right mt-4">
                  <b-button  type="submit" :disabled="saving" variant="primary"> Submit</b-button>
                  <b-button  @click="$router.go(-1)" :disabled="saving" variant="none" class="iq-bg-danger ml-3">Cancel
                  </b-button>
                </b-col>
              </b-row>
            </form> -->
            <form @submit.prevent="onSaveTemp">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Link to Case" label-for="link-case" >
                    <v-select 
                      id="link-case"
                      v-model="formInitialInput.form_id" 
                      placeholder="Select Link To Case"
                      :options="linkToCaseOptions"
                      label="text"
                      :reduce="(type) => type.value"
                      :clearable="false">
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Cause of Loss" label-for="couse-of-lose">
                    <v-select 
                      id="couse-of-lose"
                      v-model="formInitialInput.cause_of_loss_id" 
                      placeholder="Select Cause of Loss"
                      :options="causeOfLossOptions"
                      label="name"
                      :clearable="false">
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Status" label-for="status"><v-select 
                      id="status"
                      v-model="formInitialInput.status" 
                      placeholder="Select Status"
                      :options="statusOptions"
                      label="name"
                      :clearable="false">
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Closing Date" label-for="closing-date">
                    <date-picker 
                      id="closing-date"
                      v-model="formInitialInput.closed_at" 
                      name="closedDate" 
                      type="date" 
                      value-type="YYYY-MM-DD"
                      format="DD MMM YYYY"
                      class="w-100"
                      placeholder="Select date"></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Notif To Department" label-for="notif">
                    <v-multiselect
                    id="notif"  
                    v-model="formInitialInput.department_ids" 
                    placeholder="Notif" 
                    label="name"
                    track-by="id" 
                    :options="notifOptions" 
                    :multiple="true">
                      <span slot="noResult">Data not found.</span>
                    </v-multiselect>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Problem / Issue" label-for="problem">
                    <wysiwyg :style="{ minHeight: '250px' }" class="text-black" v-model="formInitialInput.problem" id="problem" />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Vessel Case" label-for="vessel-case">
                    <wysiwyg :style="{ minHeight: '250px' }" class="text-black" v-model="formInitialInput.vessel_case" id="vessel-case" />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Root Cause" label-for="root-case">
                    <wysiwyg :style="{ minHeight: '250px' }" class="text-black" v-model="formInitialInput.root_cause" id="root-case" />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Recommendation" label-for="rekomendasi">
                    <wysiwyg :style="{ minHeight: '250px' }" class="text-black" v-model="formInitialInput.recommendation" id="rekomendasi" />
                  </b-form-group>
                </b-col>
                <b-col md="12" class="d-flex">
                  <b-button variant="outline-primary mr-2" class="w-100" type="button" @click="onClearForm">Clear Form</b-button>
                  <b-button variant="primary" class="w-100" type="submit">{{ isEditTemp  ? 'Update' :'Save to' }} Temporary Recomendation</b-button>
                </b-col>
              </b-row>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { miActions, crewingActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  name: 'BlankPage',
  components: { StepNavigation, StepWizard },
  async mounted () {
    xray.index()
    await this.getDepartmentList()
    await this.getCauseOfLossList()
    await this.fetchDataUserExpert()
    if (this.$route.query.formId) {
      await this.getIncidents()
      await this.getRecommendationList()
    } else {
      await this.getAllMi()
    }
    if (this.$route.params.id) {
      if (!this.$route.query.formId) {
        this.$router.push({
          name: 'mi.listInitial'
        })
      } else {
        await this.getRecommendationList(this.$route.params.id)
        await this.getIncidents()
        if (this.forms[0].form_id.toString() !== this.$route.query.formId.toString()) {
          this.$router.push({
            name: 'mi.listInitial'
          })
        }
      }
    }
    if (!this.$route.params.id) {
        const tempFormChace = localStorage.getItem('tempFormRecomendation')
        this.forms = tempFormChace ? JSON.parse(tempFormChace) :[]
      }
  },
  computed: {
    userPermission(){
      if(['SUPER ADMIN', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'MARINE INSURANCE MANAGER'].includes(this.permission_user)){
        return true
      } else{
        return false
      } 
    }
  },
  data () {
    return {
      permission_user:JSON.parse(localStorage.getItem('profile')).role.roles,
      image: require('../../assets/images/logo.png'),
      incident: null,
      saving: false,
      forms: [
        // {
        //   form_id: null,
        //   department_ids: [],
        //   problem: '',
        //   vessel_case: '',
        //   root_cause: '',
        //   recommendation: '',
        //   cause_of_loss_id: null,
        //   status: 'OPEN',
        //   closed_at: null
        // }
      ],
      formInitialInput:{
        form_id: null,
        department_ids: [],
        problem: '',
        vessel_case: '',
        root_cause: '',
        recommendation: '',
        cause_of_loss_id: null,
        status: 'OPEN',
        closed_at: null
      },
      statusOptions: [
        {
          id: 'OPEN',
          name: 'Open'
        },
        {
          id: 'PROGRESS',
          name: 'On Progress'
        },
        {
          id: 'CLOSED',
          name: 'Closed'
        }
      ],
      linkToCaseOptions: [
        {
          value: null,
          text: null
        }
      ],
      notifOptions: [],
      optionsUser: [],

      notifMailUser: [],

      causeOfLossOptions: [],
      department_ids: null,
      linkToCase: null,
      tempIdx:'',
      isEditTemp:false
    }
  },
  methods: {
    ...miActions,
    ...crewingActions,
    async getAllMi () {
      const { data } = await this.getMi({
        id: this.$route.query.formId,
        with: 'type_loss,vehicle',
        pagination: 0
      })

      if (data.length) {
        this.linkToCaseOptions = data.map(val => ({
          value: val.id,
          text: `${val.vehicle.name} - ${val.type_form.split('_').join(' ')} - ${val.type_loss.name}`
        }))
      }
    },
    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 10000,
      }
      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          this.optionsUser = data.map(val => {
            return {
              ...val,
              name: val.userDetail?.name,
            }
          })
        }
      }
    },
    async getDepartmentList () {
      const { data } = await this.getDepartments()
      if (data.length) {
        this.notifOptions = data.map(val => ({ id: val.id, name: val.name }))
      }
    },
    async getIncidents () {
      const { data, status } = await this.getMi({
        id: this.$route.query.formId,
        with: 'type_loss,vehicle',
        pagination: 0
      })
      if (status === 'success') {
        const incident = data[0]
        this.incident = data[0]
        this.linkToCaseOptions = [{
          value: incident.id,
          text: `${incident.vehicle.name} - ${incident.type_form.split('_').join(' ')} - ${incident.type_loss.name}`
        }]
      } else {
        this.$router.push({
          name: 'mi.listInitial'
        })
      }
    },
    async getCauseOfLossList () {
      const { data } = await this.getMasterCauseOfLoss()
      if (data.length) {
        this.causeOfLossOptions = data.map(val => ({ id: val.id, name: val.name }))
      }
    },
    async getRecommendationList (id) {
      if (id) {
        const params = {
          id: this.$route.params.id
        }
        const { data, status } = await this.getRecommendation(params)

        if (status === 'success' && data) {
          if (data) {
            this.forms = [{
              ...data,
              cause_of_loss_id: this.causeOfLossOptions.find(val => val.id === data.cause_of_loss_id),
              department_ids: data.department_ids.map(id => this.notifOptions.find(val => val.id === id)),
              status: this.statusOptions.find(val => val.id === data.status)
            }]
          } else {
            this.$swal(
              `Oops!`,
              'Recommendation not found',
              'error'
            )
            setTimeout(() => {
              this.$router.push({ name: 'mi.listInitial' })
            }, 1500)
          }
        } else {
          this.$swal(
            `Oops!`,
            data,
            'error'
          )
          setTimeout(() => {
            this.$router.push({ name: 'mi.listInitial' })
          }, 1500)
        }
      } else {
        const params = {
          form_id: this.$route.query.formId,
          pagination: 0
        }
        const res = await this.getRecommendation(params)

        if (res.status === 'success' && res.data) {
          if (res.data.data.length) {
            this.forms = res.data.data.map(data => ({
              ...data,
              cause_of_loss_id: this.causeOfLossOptions.find(val => val.id === data.cause_of_loss_id),
              status: this.statusOptions.find(val => val.id === data.status)
            }))
            this.department_ids = this.forms[0].department_ids.map(id => this.notifOptions.find(val => val.id === id))
          }
          this.linkToCase = parseInt(this.$route.query.formId, 10)
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
      }
    },
    deleteForm (index) {
      this.forms.splice(index, 1)
    },
    async onSave () {
      let payload = {
        data: this.forms.map(form => ({
          ...form,
          department_ids: form.department_ids.map(val => val.id),
          cause_of_loss_id: form.cause_of_loss_id.id,
          status: form.status.id
        }))
      }

      if (this.$route.query.formId) {
        payload = {
          data: this.forms.map(form => ({
            ...form,
            form_id: this.linkToCase,
            cause_of_loss_id: form.cause_of_loss_id.id,
            department_ids: this.department_ids.map(val => val.id),
            status: form.status.id
          }))
        }
        payload.form_id = this.$route.query.formId
      }

      if (this.$route.params.id) {
        payload = {
          data: this.forms.map(form => ({
            ...form,
            form_id: this.linkToCase,
            cause_of_loss_id: form.cause_of_loss_id.id,
            department_ids: this.department_ids.map(val => val.id),
            status: form.status.id
          }))
        }
        payload.id = this.$route.params.id
      }

      this.saving = true

      let res
      if (this.$route.params.id) {
        res = (await this.updateRecommendation(payload))
      } else {
        res = (await this.saveRecommendation(payload))
      }

      if (res.status === 'error') {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.saving = false
        return
      }

      let emailPayload = {
        user_id:[]
      }

      if(this.notifMailUser.length > 0){
        this.notifMailUser.map(obj => {
          emailPayload.user_id.push(obj.id)
        })
  
        await this.sendEmailRecommendation({data:emailPayload, id: res.data[0].form_id})
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.saving = false

      if (this.$route.params.id) {
        setTimeout(() => {
          this.$router.push({ name: 'mi.listInitial' })
        }, 1500)
      }
    },
    onSaveTemp(){
      this.isEditTemp ? this.updateTemporary() : this.addTemporary()
      if (!this.$route.params.id) {
        localStorage.setItem('tempFormRecomendation', JSON.stringify(this.forms))
      }
    },
    addForm () {
      this.forms.push({
        department_ids: [],
        problem: '',
        vessel_case: '',
        root_cause: '',
        recommendation: '',
        cause_of_loss_id: null,
        status: 'OPEN',
        closed_at: null
      })
    },
    addTemporary(){
      this.forms.push({...this.formInitialInput})
      this.resetForm()
    },
    updateTemporary(){
      this.forms[this.tempIdx] = {...this.formInitialInput}
      this.resetForm()
    },
    deleteTemporary(idx){
      this.forms = this.forms.filter((el, i)=> i != idx)
      if (!this.$route.params.id) {
        localStorage.setItem('tempFormRecomendation', JSON.stringify(this.forms))
      }
    },
    resetForm(){
      this.formInitialInput = {
        form_id: null,
        department_ids: [],
        problem: '',
        vessel_case: '',
        root_cause: '',
        recommendation: '',
        cause_of_loss_id: null,
        status: 'OPEN',
        closed_at: null
      }
      this.tempIdx = ''
      this.isEditTemp = false
    },
    selectTemp(idx){
      this.tempIdx = idx
      this.formInitialInput = {...this.forms[idx]}
      this.isEditTemp = true
    },
    onClearForm(){
      this.resetForm()
    }
  }
}
</script>
<style scoped>

.item-temp:hover{
    background: #C7EBFF;
}

</style>
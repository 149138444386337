<template>
  <div>
    <b-row class="mt-4 mb-3">
      <b-col md="6">
        <!-- <b-badge
          v-if="
            detailMi?.recommendations && detailMi?.recommendations?.length > 0
          "
          variant="success"
          >Already Input</b-badge
        >
        <b-badge v-else variant="warning">Not Input Yet</b-badge> -->
        <b-badge
          variant="primary"
          class="ml-2"
          style="cursor: pointer"
          @click="handleOpenModalEmail('Recommendation', detailMi.id)"
          >Send Email Notification</b-badge
        >
      </b-col>
      <b-col md="6" class="text-right">
        <b-button
          @click="onCreateRecommendation(detailMi.id)"
          variant="success"
          v-if="!userPermission"
        >
          View Recommendation
        </b-button>
        <b-button
          @click="onCreateRecommendation(detailMi.id)"
          variant="success"
          v-else-if="canAddRecommendation || canEditRecommendation"
        >
          {{ !detailMi?.recommendations?.length ? 'Input' : 'Edit' }}
          Recommendation
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="detailMi?.recommendations?.length" md="12" class="mt-4">
      <b-table-simple>
        <b-thead>
          <b-th>Problem</b-th>
          <b-th>Vessel Case</b-th>
          <b-th>Root Cause</b-th>
          <b-th>Recommendation</b-th>
          <b-th>Status</b-th>
        </b-thead>
        <b-tbody
          v-for="recommendation in detailMi?.recommendations"
          :key="recommendation.id"
        >
          <b-tr>
            <b-td>{{ recommendation.problem }}</b-td>
            <b-td>{{ recommendation.vessel_case }}</b-td>
            <b-td>{{ recommendation.root_cause }}</b-td>
            <b-td>{{ recommendation.recommendation }}</b-td>
            <b-td>{{ recommendation.status }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-row>
  </div>
</template>
<script>


export default {
  name: 'RecomendationStep',
  props: {
    detailMi: null,
  },
  data() {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')),
    }
  },
  methods:{
    handleOpenModalEmail(title, formId) {
      this.$emit('openModalEmail', title, formId)
    },
    onCreateRecommendation (formId) {
      this.$router.push({
        name: 'mi.add-form-recommendation',
        query: {
          formId
        }
      })
    },
  },
  computed: {
    permissionUser() {
      return this.lsProfile?.role.roles
    },
    userPermission() {
      if (
        [
          'SUPER ADMIN',
          'MARINE INSURANCE',
          'MARINE INSURANCE ADMIN',
          'MARINE INSURANCE MANAGER',
        ].includes(this.permissionUser)
      ) {
        return true
      } else {
        return false
      }
    },
    recommendation() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Marine Insurance')
        ?.child.find((child) => child.menu === 'Recommendation')
    },
    activeRecommendation() {
      return this.recommendation ? this.recommendation?.active : false
    },
    canAddRecommendation() {
      return this.recommendation ? this.recommendation?.add : false
    },
    canEditRecommendation() {
      return this.recommendation ? this.recommendation?.update : false
    },
    canApproveRecommendation() {
      return this.recommendation ? this.recommendation?.approval : false
    },
    canReviewRecommendation() {
      return this.recommendation ? this.recommendation?.review : false
    },
    canDeleteRecommendation() {
      return this.recommendation ? this.recommendation?.delete : false
    },
    canExportRecommendation() {
      return this.recommendation ? this.recommendation?.export : false
    },
  },
}
</script>

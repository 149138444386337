var render = function render(){
  var _vm$detailIncident, _vm$detailIncident$do, _vm$detailIncident2, _vm$detailIncident3, _vm$detailIncident3$h, _vm$detailIncident4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showFilter ? _c('div', [_c('b-modal', {
    attrs: {
      "centered": "",
      "size": "lg",
      "ok-title": "Filter",
      "title": "Filter Data"
    },
    on: {
      "ok": function ok($event) {
        return _vm.handleFetchIncidents(1, true);
      }
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Date Range",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "date",
      "value-type": "YYYY-MM-DD",
      "range": "",
      "placeholder": "Select date range"
    },
    on: {
      "change": _vm.handleChangeDate
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Company",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.companies,
      "reduce": function reduce(companyOptions) {
        return companyOptions.value;
      }
    },
    model: {
      value: _vm.incidents.params.company_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incidents.params, "company_id", $$v);
      },
      expression: "incidents.params.company_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Fleet/Vessel",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_vm.vehicleId ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.fleets.find(function (fleet) {
    return fleet.code === _vm.vehicleId;
  }) ? _vm.fleets.find(function (fleet) {
    return fleet.code === _vm.vehicleId;
  }).name : '-') + " ")]) : _c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.fleets,
      "reduce": function reduce(fleetList) {
        return fleetList.code;
      }
    },
    model: {
      value: _vm.incidents.params.vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incidents.params, "vehicle_id", $$v);
      },
      expression: "incidents.params.vehicle_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Type",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.typeOfForm,
      "reduce": function reduce(typeOfForm) {
        return typeOfForm.value;
      }
    },
    model: {
      value: _vm.incidents.params.type_form,
      callback: function callback($$v) {
        _vm.$set(_vm.incidents.params, "type_form", $$v);
      },
      expression: "incidents.params.type_form"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Type of Coverage",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.typeCoverages,
      "reduce": function reduce(typeOfCoverage) {
        return typeOfCoverage.code;
      }
    },
    model: {
      value: _vm.incidents.params.id_type_coverage,
      callback: function callback($$v) {
        _vm.$set(_vm.incidents.params, "id_type_coverage", $$v);
      },
      expression: "incidents.params.id_type_coverage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Type of Loss",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.typeLoss,
      "reduce": function reduce(typeOfLoss) {
        return typeOfLoss.code;
      }
    },
    model: {
      value: _vm.incidents.params.id_type_loss,
      callback: function callback($$v) {
        _vm.$set(_vm.incidents.params, "id_type_loss", $$v);
      },
      expression: "incidents.params.id_type_loss"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Status",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.statusOptions,
      "reduce": function reduce(statusOptions) {
        return statusOptions.value;
      }
    },
    model: {
      value: _vm.incidents.params.status_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incidents.params, "status_id", $$v);
      },
      expression: "incidents.params.status_id"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List of Accident AND Incident")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$incidents, _vm$incidents$data, _vm$incidents2;
        return [_c('div', {
          staticClass: "d-flex justify-content-between mb-3"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.toggleModalFilter
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data")])])], 1), _c('div', [_vm.canExport ? _c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.exportIncidentsFile
          }
        }, [!_vm.loading_export ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2) : _vm._e(), _vm.canAdd ? _c('router-link', {
          attrs: {
            "to": "/mi/portal"
          }
        }, [_c('button', {
          staticClass: "btn btn-primary"
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Form Submission")])]) : _vm._e()], 1)]), _c('div', {
          staticClass: "px-3 mb-3"
        }, [_c('b-row', {
          staticClass: "border py-2"
        }, [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Range"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.moment(_vm.dateRange[0]).format('DD MMM YYYY')) + " - " + _vm._s(_vm.moment(_vm.dateRange[1]).format('DD MMM YYYY')) + " ")])])], 1), _vm.incidents.params.company_id ? _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Company"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.companies.find(function (el) {
          return el.value == _vm.incidents.params.company_id;
        }).text) + " ")])])], 1) : _vm._e(), _vm.incidents.params.vehicle_id ? _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Fleet/Vessel"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.fleets.find(function (el) {
          return el.code == _vm.incidents.params.vehicle_id;
        }).name) + " ")])])], 1) : _vm._e(), _vm.incidents.params.type_form ? _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Type"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.typeOfForm.find(function (el) {
          return el.value == _vm.incidents.params.type_form;
        }).text) + " ")])])], 1) : _vm._e(), _vm.incidents.params.id_type_coverage ? _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Type of Coverage"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.typeCoverages.find(function (el) {
          return el.code == _vm.incidents.params.id_type_coverage;
        }).name) + " ")])])], 1) : _vm._e(), _vm.incidents.params.id_type_loss ? _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Type of Loss"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.typeLoss.find(function (el) {
          return el.code == _vm.incidents.params.id_type_loss;
        }).name) + " ")])])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_vm.incidents.params.status_id ? _c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Status"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.statusOptions.find(function (el) {
          return el.value == _vm.incidents.params.status_id;
        }).text) + " ")])]) : _vm._e()], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 overflow-auto"
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "column-1"
        }, [_vm._v("No.")]), _c('th', {
          staticClass: "text-truncate column-2"
        }, [_vm._v("Name of Vessel")]), _c('th', {
          staticClass: "text-truncate column-3"
        }, [_vm._v("Type")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Type of Coverage")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Type of Loss")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Cost")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Remarks")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Date of Loss / Created")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [((_vm$incidents = _vm.incidents) === null || _vm$incidents === void 0 ? void 0 : (_vm$incidents$data = _vm$incidents.data) === null || _vm$incidents$data === void 0 ? void 0 : _vm$incidents$data.length) === 0 || _vm.incidents.isLoading ? _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "10"
          }
        }, [_vm._v(_vm._s(_vm.incidents.isLoading ? 'Loading...' : "No Data"))])]) : _vm._e(), _vm._l((_vm$incidents2 = _vm.incidents) === null || _vm$incidents2 === void 0 ? void 0 : _vm$incidents2.data, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-truncate column-1 bg-white"
          }, [_vm._v(_vm._s(_vm.incidents.meta.from + index) + ".")]), _c('td', {
            staticClass: "text-truncate column-2 bg-white"
          }, [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.handleOpenDetailIncident(index);
              }
            }
          }, [_vm._v(_vm._s(data.vehicle.name))])]), _c('td', {
            staticClass: "text-truncate column-3 bg-white"
          }, [data.type_form === 'claim' ? _c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("Claim")]) : _vm._e(), data.type_form === 'non_claim' ? _c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "variant": "warning"
            }
          }, [_vm._v("Non Claim ")]) : _vm._e()], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(data.type_coverage.name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(data.type_loss.name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("Rp. " + _vm._s(_vm.numberFormat(data.cost)) + ",-")]), _c('td', {
            staticStyle: {
              "min-width": "350px",
              "max-width": "400px"
            }
          }, [_vm._v(_vm._s(data.remarks || '-'))]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "id": _vm.canApprove ? "popover-status-".concat(index) : '',
              "variant": "primary"
            }
          }, [_vm._v(_vm._s(data.status ? data.status.name : '-'))])], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(data.date_of_loss) + " "), _c('br'), _c('strong', [_vm._v("PIC: " + _vm._s(data.creator ? data.creator.username : '-'))])]), _c('td', [_c('b-dropdown', {
            attrs: {
              "variant": "primary"
            }
          }, [_c('template', {
            slot: "button-content"
          }, [_vm._v(" Action "), _c('i', {
            staticClass: "uil uil-angle-down"
          })]), [_c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.handleNextProcess(index, data);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-arrow-right"
          }), _vm._v(" Next Step Process")])], _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.handleOpenDetailIncident(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-list"
          }), _vm._v(" Detail")]), _c('b-dropdown-item', {
            attrs: {
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOpenModalEmail('Accident', data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-send"
          }), _vm._v(" Send Email Accident")]), _vm.role === 'MANAGEMENT' || _vm.canApprove ? [_c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.$router.push("/mi/management-approval?formId=".concat(data.id));
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-check"
          }), _vm._v(" Management Approval")]), _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.$router.push("/mi/management-summary?formId=".concat(data.id));
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-check"
          }), _vm._v(" Management Summary")])] : _vm._e(), _vm.canDelete ? _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.handleDeleteMi(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          }), _vm._v(" Delete")]) : _vm._e()], 2)], 1), _vm.showStatusAction ? _c('b-popover', {
            attrs: {
              "target": "popover-status-".concat(index),
              "placement": "bottom",
              "triggers": "hover focus"
            },
            scopedSlots: _vm._u([{
              key: "title",
              fn: function fn() {
                return [_vm._v("Status Initial Claim!")];
              },
              proxy: true
            }], null, true)
          }, [_vm._l(_vm.statuses, function (status, idx) {
            return [data.status.id !== status.code && _vm.handleShowListStatuses(data.status.id).includes(status.code) ? _c('div', {
              key: idx,
              on: {
                "click": function click($event) {
                  return _vm.handleChangeStatus(data.id, status, index);
                }
              }
            }, [status.code === 11 ? _c('b-button', {
              ref: "button",
              refInFor: true,
              staticClass: "mb-2",
              attrs: {
                "block": "",
                "variant": "warning"
              }
            }, [_vm._v(" Completed")]) : status.code === 10 ? _c('b-button', {
              ref: "button",
              refInFor: true,
              staticClass: "mb-2",
              attrs: {
                "block": "",
                "variant": "danger"
              }
            }, [_vm._v("Waiting R&D")]) : status.code === 9 ? _c('b-button', {
              ref: "button",
              refInFor: true,
              staticClass: "mb-2",
              attrs: {
                "block": "",
                "variant": "success"
              }
            }, [_vm._v("Waiting Final Adjustment")]) : _c('b-button', {
              ref: "button",
              refInFor: true,
              staticClass: "mb-2",
              attrs: {
                "block": "",
                "variant": "info"
              }
            }, [_vm._v(_vm._s(status.name) + " ")])], 1) : _vm._e()];
          })], 2) : _vm._e()], 1);
        })], 2)])]), _c('div', {
          staticClass: "mt-3 mb-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.incidents.meta.total,
            "per-page": _vm.incidents.meta.perPage,
            "align": "center"
          },
          on: {
            "input": _vm.handleFetchIncidents
          },
          model: {
            value: _vm.incidents.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.incidents.params, "page", $$v);
            },
            expression: "incidents.params.page"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-sidebar', {
    attrs: {
      "id": "sidebar-view",
      "title": "DETAIL INFORMATION OF ACCIDENT/INCIDENT",
      "width": "1200px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_vm.detailIncident ? _c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_vm.detailIncident.type_form === 'claim' ? _c('b-button', {
    ref: "button",
    attrs: {
      "id": "popover-type",
      "variant": "info"
    }
  }, [_vm._v("Claim")]) : _vm._e(), _vm.detailIncident.type_form === 'non_claim' ? _c('b-button', {
    ref: "button",
    attrs: {
      "id": "popover-type",
      "variant": "warning"
    }
  }, [_vm._v("Non Claim")]) : _vm._e()], 1), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Status")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('b-button', {
    ref: "button",
    attrs: {
      "id": "popover-view-status",
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.detailIncident.status ? _vm.detailIncident.status.name : ''))]), _c('b-popover', {
    attrs: {
      "target": "popover-view-status",
      "placement": "bottom",
      "triggers": "hover focus"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Status Initial Claim!")];
      },
      proxy: true
    }], null, false, 3736808988)
  }, [_vm._l(_vm.statuses, function (status, idx) {
    return [_vm.detailIncident.status.id !== status.code && _vm.handleShowListStatuses(_vm.detailIncident.status.id).includes(status.code) ? _c('div', {
      key: idx,
      on: {
        "click": function click($event) {
          return _vm.handleChangeStatus(_vm.detailIncident.id, status);
        }
      }
    }, [status.code === 11 ? _c('b-button', {
      ref: "button",
      refInFor: true,
      staticClass: "mb-2",
      attrs: {
        "block": "",
        "variant": "warning"
      }
    }, [_vm._v(" Completed")]) : status.code === 10 ? _c('b-button', {
      ref: "button",
      refInFor: true,
      staticClass: "mb-2",
      attrs: {
        "block": "",
        "variant": "danger"
      }
    }, [_vm._v("Waiting R&D")]) : status.code === 9 ? _c('b-button', {
      ref: "button",
      refInFor: true,
      staticClass: "mb-2",
      attrs: {
        "block": "",
        "variant": "success"
      }
    }, [_vm._v("Waiting Final Adjustment")]) : _c('b-button', {
      ref: "button",
      refInFor: true,
      staticClass: "mb-2",
      attrs: {
        "block": "",
        "variant": "info"
      }
    }, [_vm._v(_vm._s(status.name) + " ")])], 1) : _vm._e()];
  })], 2)], 1)]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.company.company))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Name of Vessel")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(_vm._s(_vm.detailIncident.vehicle.name))])], 1)]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type of Coverage")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.type_coverage.name || '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Place of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.place_of_loss || '-'))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Cause of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.cause_of_loss ? _vm.detailIncident.cause_of_loss.name : '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.type_loss.name || '-'))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Date of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.date_of_loss))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Created Form")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_vm._v(_vm._s(_vm.detailIncident.created_at))])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.remarks || '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Cost")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v("Rp. " + _vm._s(_vm.detailIncident.cost) + ",-")])])])])])]), _c('b-col', {
    staticClass: "text-right pt-2",
    attrs: {
      "md": "1"
    }
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "text": "Small",
      "right": ""
    }
  }, [_c('template', {
    slot: "button-content"
  }, [_c('i', {
    staticClass: "fa fa-ellipsis-h"
  })]), _c('b-dropdown-item', [_c('router-link', {
    attrs: {
      "to": "/mi/portal?formId=".concat(_vm.detailIncident.id)
    }
  }, [_c('i', {
    staticClass: "fa fa-edit"
  }), _vm._v(" Edit")])], 1), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.handleDeleteMi(_vm.detailIncident.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash"
  }), _vm._v(" Delete ")])], 2)], 1), _c('b-popover', {
    attrs: {
      "target": "popover-view-type",
      "placement": "bottom",
      "triggers": "hover focus"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Type Form!")];
      },
      proxy: true
    }], null, false, 3348586610)
  }, [_c('b-button', {
    ref: "button",
    staticClass: "mb-2",
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_vm._v("Non Claim")])], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("CLAIMANT / NOTIFICATION DETAILS")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.company_claimant ? _vm.detailIncident.company_claimant.company : '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Department/Vessel")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.department.name))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Status")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.status ? _vm.detailIncident.status.name : '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', [_c('strong')])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Details of Chronology")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped table-warning"
  }, [_c('tbody', [_c('tr', [_c('td', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailIncident.details_of_chronology || '-')
    }
  })])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Estimate Damage or Loss")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped table-primary"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailIncident.estimate_damage || '-')
    }
  })])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Conclusion")])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped table-info"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailIncident.conclusion || '-')
    }
  })])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Support Document")])]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-0 table-borderless table-box-shadow"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No.")]), _c('th', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Name of File / Document")]), _c('th', {
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("File / Document")])])]), _c('tbody', [((_vm$detailIncident = _vm.detailIncident) === null || _vm$detailIncident === void 0 ? void 0 : (_vm$detailIncident$do = _vm$detailIncident.documents) === null || _vm$detailIncident$do === void 0 ? void 0 : _vm$detailIncident$do.length) === 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("No Document")])]) : _vm._e(), _vm._l((_vm$detailIncident2 = _vm.detailIncident) === null || _vm$detailIncident2 === void 0 ? void 0 : _vm$detailIncident2.documents, function (file, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1) + ".")]), _c('td', [_vm._v(_vm._s(file.name_of_file))]), _c('td', [_c('b-button-group', [_c('b-button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "variant": "info",
        "href": file.document_url,
        "download": "",
        "title": "Preview File"
      }
    }, [_c('i', {
      staticClass: "fa fa-eye"
    })]), _c('b-button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "variant": "dark",
        "href": file.document_url,
        "download": "",
        "title": "Download File"
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    })])], 1)], 1)]);
  })], 2)])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-btn', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion1",
      modifiers: {
        "accordion1": true
      }
    }],
    attrs: {
      "block": "",
      "href": "#",
      "variant": "secondary"
    }
  }, [_vm._v(" History Form "), _c('span', {
    staticClass: "when-opened"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  })]), _c('span', {
    staticClass: "when-closed"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  })])]), _c('b-collapse', {
    attrs: {
      "id": "accordion1",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-timeline"
  }, [((_vm$detailIncident3 = _vm.detailIncident) === null || _vm$detailIncident3 === void 0 ? void 0 : (_vm$detailIncident3$h = _vm$detailIncident3.histories) === null || _vm$detailIncident3$h === void 0 ? void 0 : _vm$detailIncident3$h.length) == 0 ? _c('li', [_vm._v(" No Data ")]) : _vm._e(), _vm._l((_vm$detailIncident4 = _vm.detailIncident) === null || _vm$detailIncident4 === void 0 ? void 0 : _vm$detailIncident4.histories, function (history, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "timeline-dots border-secondary"
    }), _c('h6', {}, [_vm._v(_vm._s(history.history_text))]), _c('small', {
      staticClass: "mt-1"
    }, [_vm._v(_vm._s(history.date_history))])]);
  })], 2)])], 1), _vm.showApprovalForm || _vm.detailIncident.status_id === 3 ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-3 mt-2 text-center"
  }, [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s(_vm.detailIncident.status_id !== 3 ? 'Approval' : 'Get') + " Marine Insurance Form")])], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Actual Close Date *",
      "label-for": "acd"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "date",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.approvalForm.actual_close_date,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "actual_close_date", $$v);
      },
      expression: "approvalForm.actual_close_date"
    }
  })], 1), _c('table', {
    staticClass: "table mb-3 table-borderless table-box-shadow table-success"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("Type")]), _c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Quantification")]), _c('th', {
    attrs: {
      "width": "35%"
    }
  })])]), _c('tbody', [_c('tr', [_c('td', [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.typeOfForm,
      "reduce": function reduce(typeOfForm) {
        return typeOfForm.value;
      }
    },
    model: {
      value: _vm.approvalForm.type_form,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "type_form", $$v);
      },
      expression: "approvalForm.type_form"
    }
  })], 1), _c('td', [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.quantificationOptions,
      "reduce": function reduce(quantificationOptions) {
        return quantificationOptions.value;
      }
    },
    model: {
      value: _vm.approvalForm.quantification,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "quantification", $$v);
      },
      expression: "approvalForm.quantification"
    }
  })], 1), _c('td')]), _c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "Coverage Period",
      "label-for": "fleet-equipment"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "year",
      "value-type": "YYYY",
      "range": "",
      "placeholder": "Select year"
    },
    on: {
      "change": _vm.handleCoverageDateRange
    },
    model: {
      value: _vm.coverageDateRange,
      callback: function callback($$v) {
        _vm.coverageDateRange = $$v;
      },
      expression: "coverageDateRange"
    }
  })], 1)], 1), _c('td', [_c('b-form-group', {
    attrs: {
      "label": "Status Coverage",
      "label-for": "fleet-equipment"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.statusCoverageOptions,
      "reduce": function reduce(statusCoverageOptions) {
        return statusCoverageOptions.value;
      }
    },
    model: {
      value: _vm.approvalForm.status_coverage,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "status_coverage", $$v);
      },
      expression: "approvalForm.status_coverage"
    }
  })], 1)], 1), _c('td')]), _c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "Proximity Level",
      "label-for": "fleet-equipment"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "md",
      "append": "PL"
    }
  }, [_c('b-form-spinbutton', {
    staticClass: "bg-secondary text-white mr-2",
    attrs: {
      "min": "1",
      "max": "5",
      "inline": ""
    },
    model: {
      value: _vm.approvalForm.proximity_level,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "proximity_level", $$v);
      },
      expression: "approvalForm.proximity_level"
    }
  })], 1)], 1)], 1), _c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Remarks"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.approvalForm.remarks,
      expression: "approvalForm.remarks"
    }],
    staticClass: "form-control border-dark",
    attrs: {
      "rows": "1"
    },
    domProps: {
      "value": _vm.approvalForm.remarks
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.approvalForm, "remarks", $event.target.value);
      }
    }
  })])], 1)]), _c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "Follow Up Response",
      "label-for": "fleet-equipment"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.responseFuOptions,
      "reduce": function reduce(responseFuOptions) {
        return responseFuOptions.value;
      }
    },
    model: {
      value: _vm.approvalForm.response_fu_user,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "response_fu_user", $$v);
      },
      expression: "approvalForm.response_fu_user"
    }
  })], 1)], 1), _c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reporting Status"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.statusOptions,
      "reduce": function reduce(statusOptions) {
        return statusOptions.text;
      }
    },
    model: {
      value: _vm.approvalForm.reporting_status,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "reporting_status", $$v);
      },
      expression: "approvalForm.reporting_status"
    }
  })], 1)], 1)]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Insurer")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Share")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Currency")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Amount")]), _c('th', {
    attrs: {
      "width": "13%"
    }
  }, [_vm._v("Paid at")]), _c('th', {
    attrs: {
      "width": "1%"
    }
  })])]), _c('tbody', [_vm._l(_vm.approvalForm.insurers, function (insurer, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('b-form-input', {
      staticClass: "border-dark",
      attrs: {
        "type": "text",
        "required": ""
      },
      model: {
        value: insurer.insurer_id,
        callback: function callback($$v) {
          _vm.$set(insurer, "insurer_id", $$v);
        },
        expression: "insurer.insurer_id"
      }
    })], 1), _c('td', [_c('b-input-group', {
      attrs: {
        "size": "sm",
        "append": "%"
      }
    }, [_c('b-form-input', {
      staticClass: "border-dark",
      attrs: {
        "type": "number",
        "required": ""
      },
      model: {
        value: insurer.share,
        callback: function callback($$v) {
          _vm.$set(insurer, "share", $$v);
        },
        expression: "insurer.share"
      }
    })], 1)], 1), _c('td', [_c('v-select', {
      attrs: {
        "label": "alias",
        "options": _vm.currencies,
        "reduce": function reduce(type) {
          return type.id;
        }
      },
      model: {
        value: insurer.currency_id,
        callback: function callback($$v) {
          _vm.$set(insurer, "currency_id", $$v);
        },
        expression: "insurer.currency_id"
      }
    })], 1), _c('td', [_c('b-input-group', {
      attrs: {
        "size": "sm",
        "prepend": "Rp"
      }
    }, [_c('b-form-input', {
      staticClass: "border-dark",
      attrs: {
        "required": "",
        "type": "number"
      },
      model: {
        value: insurer.amount,
        callback: function callback($$v) {
          _vm.$set(insurer, "amount", $$v);
        },
        expression: "insurer.amount"
      }
    })], 1)], 1), _c('td', [_c('date-picker', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "required": "",
        "type": "date",
        "placeholder": "Select Date",
        "value-type": "YYYY-MM-DD",
        "format": "DD MMM YYYY"
      },
      model: {
        value: insurer.paid_at,
        callback: function callback($$v) {
          _vm.$set(insurer, "paid_at", $$v);
        },
        expression: "insurer.paid_at"
      }
    })], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.removeApprovalInsurer(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1)]);
  }), _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('b-button', {
    on: {
      "click": function click($event) {
        return _vm.addApprovalInsurer();
      }
    }
  }, [_vm._v("Add Insurer")])], 1)])], 2)])])]), _c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "Cause of Loss"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.causesOfLoss,
      "reduce": function reduce(causesOfLoss) {
        return causesOfLoss.code;
      }
    },
    model: {
      value: _vm.approvalForm.cause_of_loss_id,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "cause_of_loss_id", $$v);
      },
      expression: "approvalForm.cause_of_loss_id"
    }
  })], 1)], 1), _c('td', [_c('b-form-group', {
    attrs: {
      "label": "Classification of Loss"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.classificationsOfLoss,
      "reduce": function reduce(classificationsOfLoss) {
        return classificationsOfLoss.code;
      }
    },
    model: {
      value: _vm.approvalForm.classification_of_loss_id,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "classification_of_loss_id", $$v);
      },
      expression: "approvalForm.classification_of_loss_id"
    }
  })], 1)], 1), _c('td')])])]), _c('b-button', {
    staticClass: "mt-5 m-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onApprove();
      }
    }
  }, [_vm._v(_vm._s(_vm.detailIncident.status_id !== 3 ? 'Setujui' : 'Submit'))])], 1) : _vm.showRejectForm ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-3 mt-2 text-center text-white"
  }, [_c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Reject / Pending Form")])], 1), _c('table', {
    staticClass: "table mb-3 table-borderless table-box-shadow table-warning"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Remarks")])])]), _c('tbody', [_c('tr', [_c('td', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rejectForm.remarks,
      expression: "rejectForm.remarks"
    }],
    staticClass: "form-control border-dark",
    attrs: {
      "rows": "3"
    },
    domProps: {
      "value": _vm.rejectForm.remarks
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.rejectForm, "remarks", $event.target.value);
      }
    }
  })])])])]), _c('b-button', {
    staticClass: "mt-5 m-auto",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onReject();
      }
    }
  }, [_vm._v("Tolak")])], 1) : _vm._e(), _c('b-col', {
    staticClass: "mb-3 text-center",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-button-group', {
    staticClass: "mr-2"
  }, [![11, 12].includes(_vm.detailIncident.status.id) ? _c('b-button', {
    attrs: {
      "title": "Approval Marine Insurance",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangeStatus(_vm.detailIncident.id, {
          code: 3
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-check"
  }, [_vm._v(" Approval")])]) : _vm._e(), ![11, 12].includes(_vm.detailIncident.status.id) ? _c('b-button', {
    attrs: {
      "title": "Reject/Pending this Submit Form",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangeStatus(_vm.detailIncident.id, {
          code: 12
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  }, [_vm._v(" Reject / Pending")])]) : _vm._e(), _c('router-link', {
    attrs: {
      "to": "/mi/portal?formId=".concat(_vm.detailIncident.id)
    }
  }, [_c('b-button', {
    attrs: {
      "title": "Edit Data",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-edit"
  }, [_vm._v(" Edit")])])], 1), _c('b-button', {
    attrs: {
      "title": "Delete Data",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.handleDeleteMi(_vm.detailIncident.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash"
  }, [_vm._v(" Delete")])])], 1)], 1)], 1) : _vm._e()], 1), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-view-next",
      "title": "NEXT PROCESS",
      "width": "100vw",
      "header-class": "bg-white mb-3",
      "right": "",
      "backdrop": "",
      "body-class": "px-3 overflow-x-hidden"
    },
    model: {
      value: _vm.modal.nextStep,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "nextStep", $$v);
      },
      expression: "modal.nextStep"
    }
  }, [_vm.modal.nextStep ? _c('NextStepProcess', {
    attrs: {
      "companies": _vm.companies
    }
  }) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "send-email",
      "size": "lg",
      "title": "Send Email ".concat(_vm.emailTitle),
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 border my-2"
  })]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mt-2",
    attrs: {
      "label": "Notif mail to user",
      "label-for": "notifMailUser"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Notif user",
      "label": "name",
      "track-by": "id",
      "options": _vm.filteredExpertUsers,
      "multiple": true
    },
    model: {
      value: _vm.notifMailUser,
      callback: function callback($$v) {
        _vm.notifMailUser = $$v;
      },
      expression: "notifMailUser"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Data not found.")])])], 1)], 1), _vm.emailTitle === 'Accident' ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mt-2",
    attrs: {
      "label": "Approval mail to user",
      "label-for": "approvalMailUser"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Notif user",
      "label": "name",
      "track-by": "id",
      "options": _vm.filteredExpertUsers,
      "multiple": true
    },
    model: {
      value: _vm.approvalMailUser,
      callback: function callback($$v) {
        _vm.approvalMailUser = $$v;
      },
      expression: "approvalMailUser"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Data not found.")])])], 1)], 1) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 border my-2"
  })]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendEmailToUser.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-send mr-2"
  }), _vm._v(" Send Mail")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
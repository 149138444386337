var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('input', {
    staticClass: "input-class w-100 text-right",
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.formattedValue
    },
    on: {
      "input": _vm.onInput,
      "keypress": function keypress($event) {
        return _vm.isNumber($event);
      },
      "blur": _vm.onBlur,
      "focus": _vm.onFocus
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
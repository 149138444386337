var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column justify-content-between overflow-auto",
    staticStyle: {
      "max-height": "100vh"
    }
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('div', {
      key: index,
      staticClass: "w-100"
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-100 cursor-pointer tab-animation",
      on: {
        "click": function click($event) {
          return _vm.handleClick(tab.id);
        }
      }
    }, [_c('div', {
      staticClass: "tab-number border",
      class: {
        'bg-primary': _vm.activeTab >= index + 1
      }
    }, [_vm._v(" " + _vm._s(tab.id) + " ")]), _c('h6', {
      staticClass: "ml-2",
      class: {
        'text-primary font-semibold': _vm.activeTab >= index + 1
      }
    }, [_vm._v(_vm._s(tab.label))])]), _c('div', {
      staticClass: "line-tab",
      class: {
        'border-active': _vm.activeTab >= index + 1,
        'border-inactive': index >= _vm.activeTab
      }
    })]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-container fluid>
    <!-- Top Section !-->
    <b-modal
      v-model="modalFilter"
      centered
      size="lg"
      title="Filter Data"
      ok-title="Filter"
      @ok="fetchFleetActivities"
    >
      <b-row>
        <b-col md="12">
          <b-form-group label="Category" label-for="cat" label-cols-sm="4" label-cols-lg="3">
            <v-multiselect
              id="cat"
              :options="vehicleTypes"
              v-model="vehicleSelected"
              :multiple="true"
              track-by="id"
              placeholder="Select Categories"
              label="name"
              value="id"
            >
              <span slot="noResult"
                >Oops! No elements found. Consider changing the search
                query.</span
              >
            </v-multiselect>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label-for="dateIssued" label="Company" label-cols-sm="4" label-cols-lg="3">
            <b-form-select
              plain
              :options="CompanyName"
              id="gender"
              v-model="params.companyId"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label-for="dateIssued" label="Category Ownership" label-cols-sm="4" label-cols-lg="3">
            <b-form-select
              plain
              :options="categoryOwnership"
              id="gender"
              v-model="params.ownership"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <!-- <b-col md="12">
          <b-form-group label-for="fleet-status" label="Fleet Status" label-cols-sm="4" label-cols-lg="3">
            <b-form-select plain :options="[]" id="fleet-status">
            </b-form-select>
          </b-form-group>
        </b-col> -->
        <!-- <b-col md="3" class="h-auto d-flex align-items-end">
          <b-button variant="primary" class="mb-3 mr-1 w-100"
            ><i class="fa fa-plus border rounded-circle p-1"></i>Add New
            Fleet</b-button
          >
        </b-col> -->
      </b-row>
    </b-modal>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Fleets</strong></h4>
          </template>
          <template v-slot:body>
            <div v-if="loading" class="text-center my-5 w-100">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-between mb-3">
                  <div class="d-flex">
                    <b-form-input
                      size="lg"
                      placeholder="search name..."
                      v-model="params.search"
                    ></b-form-input>
                    <b-button variant="primary" class="mx-2" @click="fetchFleetActivities">
                      <i class="fa fa-search"></i>
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      class="d-flex align-items-center"
                      @click="toggleFilter"
                    >
                      <i class="fas fa-sliders"></i>
                      <p class="text-truncate m-0"> Filter Data </p>
                    </b-button>
                  </div>
                  <div class="d-flex justify-content-end align-items-center">
                    <h5 class="mr-2">View : </h5>
                    <b-button-group>
                      <b-button
                        @click="view = 'large'"
                        class="p-2 px-3 button-view"
                        :variant="
                          view == 'large' ? 'primary' : 'outline-primary'
                        "
                        ><i class="fa m-0 fa-th-large"></i
                      ></b-button>
                      <b-button
                        @click="view = 'list'"
                        class="p-2 px-3 button-view"
                        :variant="
                          view == 'list' ? 'primary' : 'outline-primary'
                        "
                        ><i class="fa m-0 fa-bars"></i
                      ></b-button>
                    </b-button-group>
                  </div>
                </b-col>
              </b-row>
              <!-- SHOW FILTER VALUE -->

              <div style="padding: 12px 14px;">
                <b-row class="border rounded-md pt-2">
                  <b-col md="4" v-if="vehicleSelected.length">
                    <b-form-group label='Fleet Category :'>
                      <template v-if="vehicleSelected.length">
                        <b-badge variant="success" v-for="(vehicle, index) in vehicleSelected" :key="index" class="mr-1">{{ vehicle.name }}</b-badge>
                      </template>
                      <div v-else>-</div>
                    </b-form-group>
                  </b-col> 
                  <b-col md="3">
                    <b-form-group label="Company :" >
                      <p class="mb-0 text-black" style="font-weight: 500;">{{CompanyName.find((el, i)=>el.value == params.companyId)?.text ?? 'All Company'}}</p>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Category Ownership :" >
                      <p class="mb-0 text-black" style="font-weight: 500;">{{categoryOwnership.find((el, i)=>el.value == params.ownership)?.text ?? 'All Category Ownership'}}</p>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              
              <!-- END SHOW FILTER VALUE -->

              <b-row v-if="view == 'large'">
                <b-col md="6" lg="4" v-for="(f, i) in fleetActivities" :key="`f-${i}`">
                  <b-card class="iq-mb-3 shadow-sm border rounded">
                    <b-card-text class="mb-0">
                      <!-- <div class="top d-flex justify-content-between">
                        <div v-if="f.miData" class="mb-5 p-2" style="z-index: 9">
                          <a href="#" @click.prevent="handleOpenMI(f)">
                            <h5 class="mt-1 ml-1">TOTAL CASE:</h5>
                            <p>
                              <b-button variant="outline-danger" class="m-1">
                                Claim
                                <b-badge variant="danger" class="ml-2">{{
                                  f.miData.totalClaim
                                }}</b-badge>
                              </b-button>
                              <b-button variant="outline-primary" class="m-1">
                                Non Claim
                                <b-badge variant="primary" class="ml-2">{{
                                  f.miData.totalNonClaim
                                }}</b-badge>
                              </b-button>
                            </p>
                          </a>
                        </div>
                        <div class="mb-5" style="z-index: 9">
                          <b-dropdown
                            size="sm"
                            text="Small"
                            variant="light"
                            right
                          >
                            <template slot="button-content">
                              <i class="fa fa-ellipsis-h"></i>
                            </template>
                            <b-dropdown-item
                              v-if="permissionEditFleet"
                              variant="primary"
                              @click="fetchFleetDetail(f.id)"
                              ><i class="fa fa-edit"></i> Edit</b-dropdown-item
                            >
                            <b-dropdown-item
                              v-if="permissionDeleteFleet"
                              variant="danger"
                              @click="onDeleteFleet(f)"
                              ><i class="fa fa-trash"></i>
                              Delete</b-dropdown-item
                            >
                          </b-dropdown>
                        </div>
                        <div
                          class="boat"
                          :class="{
                            'boat-no-animation': [7, 6].includes(
                              f.vehicleType.id
                            ),
                          }"
                        >
                          <img
                            v-if="
                              f.vehicleType &&
                              f.vehicleType.icon &&
                              f.vehicleType.icon.url
                            "
                            :src="f.vehicleType.icon.url"
                            alt=""
                          />
                          <img
                            v-else
                            :src="require('../../assets/images/Barge.png')"
                            alt=""
                          />
                        </div>
                        <div
                          class="waves"
                          v-if="![7, 6].includes(f.vehicleType.id)"
                        ></div>
                      </div>
                      <div
                        class="bottom"
                        :style="{
                          background: [7, 6].includes(f.vehicleType.id)
                            ? '#d4e8ff !important'
                            : '#33ccff',
                        }"
                      >
                        <div
                          class="float"
                          v-if="![7, 6].includes(f.vehicleType.id)"
                        ></div>
                      </div> -->
                      <!-- <img :src="f.img.url" alt="" height="160" class="w-100"/> -->
                       <div class="fleet-card-wrap mb-3">
                            <div class="overflow-hidden img-card-shadow" style="position: relative;">
                              <div class="p-2" style="position: absolute;" @click.prevent="handleOpenMI(f)">
                                <b-badge variant="success" class="mr-1  cursor-pointer"  v-b-tooltip.hover.top="'Total Claim : ' +f.miData.totalClaim">Claim : {{ f.miData.totalClaim }}</b-badge>
                                <b-badge variant="warning" class="cursor-pointer"  v-b-tooltip.hover.top="'Total Non Claim : ' +f.miData.totalNonClaim">Non Claim : {{ f.miData.totalNonClaim }}</b-badge>
                              </div>
                              <b-badge style="min-width: 120px; position: absolute; right: -28px; top: 24px; rotate: 45deg;" :variant="getStatusColor(f.status)" class="px-4 py-1 rounded-none">{{ f.status ? f.status.replace(/_/g, " " ) : '-'}}</b-badge>
                              <img style="position: relative;z-index: -1;" :src="require('../../assets/images/example-fleets.png')" alt="f" height="240" class="w-100"/>
                          </div>
                         <div class="my-2 mt-3 content-text d-flex justify-content-between w-100 align-items-center" style="color: #FFF000;">
                            <div>
                              <div class="d-flex">
                                <img src="https://flagsapi.com/ID/shiny/24.png" alt="flags" class="mr-2">
                                <router-link
                                :to="{
                                  path: 'fleet-information',
                                  query: {
                                    vehicleId: f.id,
                                    menuObject: hashObject(f.modules[2]),
                                  },
                                }"
                                >
                                <h5 style="color: #FFF000;">
                                  <strong>{{ f.name }}</strong>
                                </h5>
                                </router-link>
                              </div>
                              <div class="d-flex align-items-center">
                                <p class="text-sm m-0 text-white">{{ f.vehicleType?.name ?? '-' }}</p>
                                <div style="height: 6px; width: 6px; border-radius: 50%; background-color: white;" class="mx-2"></div>
                                <p class="text-sm m-0 text-white">{{ f.company?.company ?? '-' }}</p>
                              </div>
                            </div>
                            <b-dropdown
                              size="sm"
                              text="Small"
                              variant="none"
                              no-caret
                              right
                            >
                              <template slot="button-content">
                                <div>
                                  <i class="fa fa-ellipsis-h cursor-pointer text-white" style="rotate: 90deg;"></i>
                                </div>
                              </template>
                              <!-- <FleetActions
                                :fleet="f"
                                :permissionEditFleet="permissionEditFleet"
                                :permissionDeleteFleet="permissionDeleteFleet" 
                                :permissionMaintenanceSetup="permissionMaintenanceSetup" 
                                :permissionMaintenanceInitiation="permissionMaintenanceInitiation"
                                :permissionSpecialSurvey="permissionSpecialSurvey"
                                @onUpdate="fetchFleetDetail(f.id)"
                                @onDelete="onDeleteFleet(f)"
                                @onSetup="showModal.setupComponent = true"
                                @onInit="showModal.setupComponent = true"
                              /> -->
                              <div class="p-2" @click="fleetSelected = f">
                                <b-button
                                  v-if="permissionDeleteFleet"
                                  variant="outline-primary"
                                  size="sm"
                                  class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                  @click="fetchFleetDetail(f.id)"
                                >
                                  <i class="fa fa-edit"></i>
                                  Update
                                </b-button>
                                <b-button
                                  v-if="permissionDeleteFleet"
                                  variant="outline-danger"
                                  size="sm"
                                  class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                  @click="onDeleteFleet(f)"
                                >
                                  <i class="fa fa-trash"></i>
                                  Delete
                                </b-button>
                                <b-button
                                  v-if="permissionDeleteFleet"
                                  variant="outline-warning"
                                  size="sm"
                                  class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                  @click="modalCrewList = true"
                                >
                                  <i class="ri-group-fill"></i>
                                  Crew List
                                </b-button>
                                <b-button
                                  v-if="permissionDeleteFleet"
                                  variant="outline-info"
                                  size="sm"
                                  class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                  @click="modalPicList = true"
                                >
                                  <i class="fa fa-user"></i>
                                  PIC List
                                </b-button>
                                <b-button
                                  v-if="permissionMaintenanceSetup"
                                  variant="outline-success"
                                  size="sm"
                                  class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                  @click="showModal.setupComponent = true"
                                >
                                  <i class="fa fa-file"></i>
                                  Setup Component
                                </b-button>
                                <b-button
                                  v-if="permissionMaintenanceSetup"
                                  variant="outline-success"
                                  size="sm"
                                  class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                  @click="showModal.setupComponent = true"
                                >
                                  <i class="fa fa-file"></i>
                                  Maintenance Initiation
                                </b-button>
                                <template v-if="permissionSpecialSurvey">
                                  <b-button
                                    v-if="
                                      f?.unscheduledDocking.includes(
                                        'INTERMEDIATE'
                                      )
                                    "
                                    @click="
                                      $router.push(
                                        `/docking/dashboard?vehicleId=${
                                          f?.id
                                        }&typeSchedule=${'intermerdiate'}&menuObject=${hashObject(
                                          f?.modules[6]
                                        )}`
                                      )
                                    "
                                    variant="outline-danger"
                                    size="sm"
                                    class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                    ><i class="fa fa-ship"></i>
                                    Set intermediate Survey
                                  </b-button>
                                  <b-button
                                    v-if="
                                      f?.unscheduledDocking.includes('SPECIAL')
                                    "
                                    @click="
                                      $router.push(
                                        `/docking/dashboard?vehicleId=${
                                          f?.id
                                        }&typeSchedule=${'special_value'}&menuObject=${hashObject(
                                          f?.modules[6]
                                        )}`
                                      )
                                    "
                                    variant="outline-info"
                                    size="sm"
                                    class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                    ><i class="fa fa-ship"></i>
                                    Set Special Survey
                                  </b-button>
                                  <b-button
                                    variant="outline-warning"
                                    size="sm"
                                    class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                    @click="onOpenSlideFleetStatus(f?.id, f?.status)"
                                    ><i class="fa fa-ship"></i>
                                    Change Fleet Status
                                  </b-button>
                                </template>
                              </div>
                            </b-dropdown>
                          </div>
                       </div>
                       <b-row class="text-center">
                           <template v-for="(m, im) in f.modules">
                              <b-col
                                md="3"
                                class="mb-3"
                                :key="`m-${im}`"
                                v-if="
                                  m.hasAccess &&
                                  (validateTechnicalPermission(m.url) ||
                                    validateOperationalPermission(m.url) ||
                                    validateReportPermission(m.url))
                                "
                              >
                                <router-link
                                  :to="{
                                    path: m.url,
                                    query: {
                                      vehicleId: f.id,
                                      menuObject: hashObject(m),
                                    },
                                  }"
                                >
                                <div class="d-flex flex-column">
                                  <img
                                    v-if="m.icon && m.icon.url"
                                    :src="m.icon.url"
                                    alt="Responsive image"
                                    width=""
                                    v-b-tooltip.top="m.name"
                                  />
                                  <img
                                    v-else
                                    src="../../assets/images/flaticon/operational.png"
                                    height=""
                                    alt="Responsive image"
                                    v-b-tooltip.top="'Operational'"
                                  />
                                  <p class="mb-0 text-nowrap mt-1" style="font-size: 10px !important">{{m.name}}</p>
                                </div>
                                </router-link>
                              </b-col>
                              <b-col
                                md="12"
                                :key="`m-b-${im}`"
                                v-if="m.hasAccess && false"
                              >
                                <router-link to="/ops/docking">
                                  <b-button block variant="success" class="mb-3"
                                    ><i class="fa fa-ship"></i>Docking Intermediate
                                    Survey</b-button
                                  >
                                </router-link>
                              </b-col>
                          </template>
                       </b-row>
                       <router-link
                        :to="{
                          path: 'fleet-information',
                          query: {
                            vehicleId: f.id,
                            menuObject: hashObject(f.modules[2]),
                          },
                        }"
                        >
                        <b-button variant="warning" class="w-100 d-flex justify-content-between align-items-center px-2" style="padding: 10px 0;">
                          <div class="d-flex">
                            <OperationalIcon style="height: 20px; width: 20px;" class="mr-1" />
                            <p class="mb-0">Detail Fleet</p>
                          </div>
                          <i class="fas fa-chevron-right text-lg"></i>
                        </b-button>
                      </router-link>
                      <!-- <table class="table table-sm table-striped">
                        <tbody>
                          <tr>
                            <td class="px-2">Main Engine Starboard</td>
                            <td class="text-right"><strong>1.380 Hours</strong></td>
                          </tr>
                          <tr>
                            <td class="px-2">Main Engine Portside</td>
                            <td class="text-right px-2"><strong>1.380 Hours</strong></td>
                          </tr>
                          <tr>
                            <td class="px-2">Auxiliary Engine Starboard</td>
                            <td class="text-right px-2"><strong>1.380 Hours</strong></td>
                          </tr>
                          <tr>
                            <td class="px-2">Auxiliary Engine Portside</td>
                            <td class="text-right px-2"><strong>1.380 Hours</strong></td>
                          </tr>
                        </tbody>
                      </table> -->
                    </b-card-text>
                    <!-- <b-card-text class="mb-3">
                      <router-link
                        :to="{
                          path: 'fleet-information',
                          query: {
                            vehicleId: f.id,
                            menuObject: hashObject(f.modules[2]),
                          },
                        }"
                        ><h4 class="text-center text-danger"
                          ><b>{{ f.name }}</b></h4
                        >
                      </router-link>
                    </b-card-text> -->
                    <b-card-text>
                      <b-row v-if="permissionMaintenanceSetup">
                        <template>
                          <b-col md="12">
                            <b-card no-body class="mb-1">
                              <b-card-header
                                header-tag="header"
                                class="p-0 bg-transparent"
                                role="tab"
                              >
                                <!-- <b-button
                                  v-b-modal.clone_component
                                  variant="success"
                                  block
                                  class="justify-content-between d-flex align-items-center border-0"
                                >
                                  <span>Maintenance Setup Component</span>
                                  <i class="fa fa-clone"></i>
                                </b-button> -->
                                <b-modal
                                  id="clone_component"
                                  v-model="showModal.setupComponent"
                                  size="large"
                                  title="Duplikasi Komponen Kapal"
                                  header-bg-variant="light"
                                  header-text-variant="dark"
                                  footer-bg-variant="light"
                                  footer-text-variant="dark"
                                >
                                  <b-alert :show="true" variant="primary">
                                    <div class="iq-alert-icon">
                                      <i class="ri-alert-line"></i>
                                    </div>
                                    <div class="iq-alert-text"
                                      ><b>Perhatikan</b> Pastikan kapal yang
                                      akan diduplikasi komponen nya sudah sesuai
                                      datanya</div
                                    >
                                  </b-alert>
                                  <b-row>
                                    <b-col md="12">
                                      <b-form-group
                                        label="Dari Komponen Kapal*"
                                        label-for="fleet"
                                      >
                                        <b-form-select plain size="lg">
                                          <b-form-select-option
                                            >Select Fleet</b-form-select-option
                                          >
                                          <b-form-select-option
                                            >TB. TAVIA 1</b-form-select-option
                                          >
                                          <b-form-select-option
                                            >TB. TAVIA 2</b-form-select-option
                                          >
                                          <b-form-select-option
                                            >BG. LANGIT 1</b-form-select-option
                                          >
                                          <b-form-select-option
                                            >BG. LANGIT 2</b-form-select-option
                                          >
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="12">
                                      <b-form-group
                                        label="Ke Komponen Kapal*"
                                        label-for="fleet"
                                      >
                                        <b-form-select plain size="lg">
                                          <b-form-select-option
                                            >Select Fleet</b-form-select-option
                                          >
                                          <b-form-select-option
                                            >TB. TAVIA 1</b-form-select-option
                                          >
                                          <b-form-select-option
                                            >TB. TAVIA 2</b-form-select-option
                                          >
                                          <b-form-select-option
                                            >BG. LANGIT 1</b-form-select-option
                                          >
                                          <b-form-select-option
                                            >BG. LANGIT 2</b-form-select-option
                                          >
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-modal>
                              </b-card-header>
                            </b-card>
                          </b-col>
                        </template>
                      </b-row>

                      <!-- <b-row v-if="permissionMaintenanceInitiation">
                        <template>
                          <b-col md="12">
                            <b-card no-body class="mb-1">
                              <b-card-header
                                header-tag="header"
                                class="p-0 bg-transparent"
                                role="tab"
                              >
                                <b-button
                                  v-b-modal.clone_component
                                  variant="warning"
                                  block
                                  class="justify-content-between d-flex align-items-center border-0"
                                >
                                  <span>Maintenance Initiation</span>
                                  <i class="fa fa-clone"></i>
                                </b-button>
                              </b-card-header>
                            </b-card>
                          </b-col>
                        </template>
                      </b-row> -->

                      <!-- <b-row v-if="permissionSpecialSurvey">
                        <template>
                          <b-col md="12">
                            <b-card no-body class="mb-1">
                              <b-card-header
                                header-tag="header"
                                class="p-0 bg-transparent"
                                role="tab"
                              >
                                <b-button
                                  v-if="
                                    f?.unscheduledDocking.includes(
                                      'INTERMEDIATE'
                                    )
                                  "
                                  @click="
                                    $router.push(
                                      `/docking/dashboard?vehicleId=${
                                        f?.id
                                      }&typeSchedule=${'intermerdiate'}&menuObject=${hashObject(
                                        f?.modules[6]
                                      )}`
                                    )
                                  "
                                  block
                                  :variant="'danger'"
                                  :class="`${`justify-content-between d-flex align-items-center border-0`}`"
                                  ><span>Set intermediate Survey</span>
                                  <i class="fa fa-ship"></i>
                                </b-button>
                                <b-button
                                  v-if="
                                    f?.unscheduledDocking.includes('SPECIAL')
                                  "
                                  @click="
                                    $router.push(
                                      `/docking/dashboard?vehicleId=${
                                        f?.id
                                      }&typeSchedule=${'special_value'}&menuObject=${hashObject(
                                        f?.modules[6]
                                      )}`
                                    )
                                  "
                                  block
                                  :variant="'info'"
                                  :class="`${`justify-content-between d-flex align-items-center border-0`}`"
                                  ><span>Set Special Survey</span>
                                  <i class="fa fa-ship"></i>
                                </b-button>
                              </b-card-header>
                            </b-card>
                          </b-col>
                        </template>
                      </b-row> -->
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
              <div v-else class="table-responsive">
                <table class="table border mb-0 table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="text-truncate">No</th>
                      <th class="text-truncate">Vessel Name</th>
                      <th class="text-truncate">Vessel Type</th>
                      <th class="text-truncate">Crew</th>
                      <th class="text-truncate">Claim</th>
                      <th class="text-truncate">Non Claim</th>
                      <th class="text-truncate">PIC Name</th>
                      <th class="text-truncate">Status</th>
                      <th class="text-truncate">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(f, i) in fleetActivities" :key="`f-${i}`">
                      <td class="text-truncate text-center">{{ i + 1 }} </td>
                      <td class="text-truncate">
                        <router-link
                        :to="{
                          path: 'fleet-information',
                          query: {
                            vehicleId: f.id,
                            menuObject: hashObject(f.modules[2]),
                          },
                        }"
                        >
                          <strong>{{ f.name }}</strong>
                        </router-link>
                      </td>
                      <td class="text-truncate">{{ f.vehicleType.name }}</td>
                      <td class="text-truncate">
                        <b-badge variant="info" class="cursor-pointer" @click="fleetSelected = f, modalCrewList = true">crew : {{ f.total_crew }}</b-badge>
                      </td>
                      <td class="text-truncate text-left"><b-badge class="cursor-pointer" variant="success" style="min-width: 50px;" @click="handleOpenMI(f)">{{ f.miData.totalClaim }}</b-badge></td>
                      <td class="text-truncate text-left"><b-badge class="cursor-pointer" variant="warning" style="min-width: 50px;" @click="handleOpenMI(f)">{{ f.miData.totalNonClaim }}</b-badge></td>
                      <td class="">
                        <div
                          class="text-truncate d-flex align-items-center"
                          v-if="f.pic.length"
                        >
                          <b-avatar variant="warning" class="mr-1" size="34"></b-avatar>
                          <div class="ml-1" style="font-size: small">
                            {{ f.pic[0]?.name }}<br />
                            <small class="p-0">{{ f.pic[0]?.email }}</small>
                          </div>
                        </div>
                        <div v-else>-</div>
                      </td>
                      <td>
                        <b-badge :variant="getStatusColor(f.status)" style="min-width: 60px;">{{ f.status.replace(/_/g, " ") }}</b-badge>
                      </td>
                      <td class="">
                        <b-dropdown
                          id="dropdown-1"
                          text="Actions"
                          class="p-0"
                          size="sm"
                          small
                          variant="dark"
                        >
                          <div class="p-2" @click="fleetSelected = f">
                            <b-button
                              v-if="permissionDeleteFleet"
                              variant="outline-primary"
                              size="sm"
                              class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                              @click="fetchFleetDetail(f.id)"
                            >
                              <i class="fa fa-edit"></i>
                              Update
                            </b-button>
                            <b-button
                              v-if="permissionDeleteFleet"
                              variant="outline-danger"
                              size="sm"
                              class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                              @click="onDeleteFleet(f)"
                            >
                              <i class="fa fa-trash"></i>
                              Delete
                            </b-button>
                            <b-button
                              variant="outline-warning"
                              size="sm"
                              class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                              @click="modalCrewList = true"
                            >
                              <i class="ri-group-fill"></i>
                              Crew List
                            </b-button>
                            <b-button
                              variant="outline-info"
                              size="sm"
                              class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                              @click="modalPicList = true"
                            >
                              <i class="fa fa-user"></i>
                              PIC List
                            </b-button>
                            <b-button
                              v-if="permissionMaintenanceSetup"
                              variant="outline-success"
                              size="sm"
                              class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                              @click="showModal.setupComponent = true"
                            >
                              <i class="fa fa-file"></i>
                              Setup Component
                            </b-button>
                            <b-button
                              v-if="permissionMaintenanceSetup"
                              variant="outline-success"
                              size="sm"
                              class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                              @click="showModal.setupComponent = true"
                            >
                              <i class="fa fa-file"></i>
                              Maintenance Initiation
                            </b-button>
                            <template v-if="permissionSpecialSurvey">
                              <b-button
                                v-if="
                                  f?.unscheduledDocking?.includes(
                                    'INTERMEDIATE'
                                  )
                                "
                                @click="
                                  $router.push(
                                    `/docking/dashboard?vehicleId=${
                                      f?.id
                                    }&typeSchedule=${'intermerdiate'}&menuObject=${hashObject(
                                      f?.modules[6]
                                    )}`
                                  )
                                "
                                variant="outline-danger"
                                size="sm"
                                class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                ><i class="fa fa-ship"></i>
                                Set intermediate Survey
                              </b-button>
                              <b-button
                                v-if="
                                  f?.unscheduledDocking?.includes('SPECIAL')
                                "
                                @click="
                                  $router.push(
                                    `/docking/dashboard?vehicleId=${
                                      f?.id
                                    }&typeSchedule=${'special_value'}&menuObject=${hashObject(
                                      f?.modules[6]
                                    )}`
                                  )
                                "
                                variant="outline-info"
                                size="sm"
                                class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
                                ><i class="fa fa-ship"></i>
                                Set Special Survey
                              </b-button>
                            </template>
                          </div>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-row>
                <b-col md="auto mx-auto my-3" v-if="!isHideLoadMore">
                  <b-button
                    variant="dark px-4 py-3"
                    @click="fetchFleetActivitiesLoadMore()"
                    ><b-spinner
                      v-if="isFetchingLoadMore"
                      class="mr-2"
                      small
                      label="Loading..."
                    ></b-spinner>
                    load more</b-button
                  >
                </b-col>
              </b-row>

              <!-- List PIC On Each Fleet -->
              <b-modal v-model="modalPicList" :title="`List PIC (${fleetSelected?.pic?.length})`" ok-only>
                <!-- <b-button
                  block
                  variant="primary"
                  v-b-toggle="`pic-accordion-` + i"
                  :class="`${`justify-content-between d-flex align-items-center border-0`}`"
                  ><span>List PIC ({{ f?.pic.length }})</span>
                  <i class="ri-group-fill"></i>
                </b-button> -->
                <ListPic :picData="fleetSelected?.pic"/>
              </b-modal>

              <!-- List Crew On Each Fleet -->
              <b-modal v-model="modalCrewList" :title="`List Crew (${fleetSelected?.total_crew})`" ok-only>
                <ListCrew :crewData="fleetSelected?.crew_data"/>
              </b-modal>

              <!-- Edit Fleet Form -->
              <b-modal
                id="edit-fleet"
                size="xl"
                :no-close-on-backdrop="true"
                :header-bg-variant="modal.headerBgVariant"
                :header-text-variant="modal.headerTextVariant"
                :body-bg-variant="modal.bodyBgVariant"
                :body-text-variant="modal.bodyTextVariant"
                hide-footer
              >
                <template v-slot:modal-header="{ close }">
                  <h5>Edit Your Fleet</h5>
                  <!-- Emulate built in modal header close button action -->
                  <b-button size="md" variant="outline-danger" @click="close">
                    <i class="fa fa-times"></i> Close
                  </b-button>
                </template>
                <AddFleet
                  v-bind:formData="fleetDetail"
                  @save="fetchFleetActivities()"
                  @childAction="$bvModal.hide('edit-fleet')"
                />
              </b-modal>
              <!-- End Edit Fleet Form -->

              <!-- SIDEBAR MI -->
              <b-sidebar
                v-if="fleetDetailMiDataModal"
                id="sidebar-mi"
                :title="`${fleetDetailMiDataModal.name} - TOTAL CASE DETAIL`"
                width="1350px"
                backdrop-variant="dark"
                right
                backdrop
                shadow
              >
                <b-row class="pt-2 pl-3 pr-3 pb-2">
                  <b-col md="12" class="mb-3">
                    <hr />
                  </b-col>
                  <!-- TAB -->
                  <b-col lg="12">
                    <iq-card body-class="p-0">
                      <template v-slot:body>
                        <div class="iq-edit-list">
                          <tab-nav :pills="true" class="iq-edit-profile d-flex">
                            <tab-nav-items
                              class="col-md-3 p-0"
                              :active="true"
                              href="#accident"
                              title="Accident / Incident"
                            />
                            <tab-nav-items
                              class="col-md-3 p-0"
                              :active="false"
                              href="#recommendation"
                              title="Recommendation"
                            />
                            <tab-nav-items
                              class="col-md-3 p-0"
                              :active="false"
                              href="#evaluation"
                              title="Evaluation"
                            />
                            <tab-nav-items
                              class="col-md-3 p-0"
                              :active="false"
                              href="#erm"
                              title="Enterprise Risk Management"
                            />
                          </tab-nav>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                  <!-- END TAB -->
                  <b-col lg="12">
                    <div id="pills-tabContent-3" class="tab-content">
                      <!-- Operational -->
                      <tab-content-item :active="true" id="accident">
                        <SummaryManagementDetailAccidentList
                          :data="fleetDetailMiDataModal.forms"
                        />
                      </tab-content-item>
                      <tab-content-item :active="false" id="recommendation">
                        <SummaryManagementRecommendations
                          :lists="fleetDetailMiDataModal.recommendations"
                        />
                      </tab-content-item>
                      <tab-content-item :active="false" id="evaluation">
                        <SummaryManagementEvaluations
                          :lists="fleetDetailMiDataModal.evaluations"
                        />
                      </tab-content-item>
                      <tab-content-item :active="false" id="erm">
                        <SummaryManagementERM
                          :lists="fleetDetailMiDataModal.erm"
                        />
                      </tab-content-item>
                    </div>
                  </b-col>
                </b-row>
              </b-sidebar>
              <!-- END SIDEBAR MI -->

              <!-- Sidebar View -->
              <b-sidebar
                v-model="slideChangeFleetStatus"
                id="change-fleet-status"
                title="Fleet Status Update"
                width="100%"
                backdrop-variant="dark"
                bg-variant="white"
                right
                backdrop
                shadow
              >
                <SlideFleetStatusUpdate :onOpenSlideFleetStatus="onOpenSlideFleetStatus" :fleetId="fleetId" :statusFleet="statusFleet" />
              </b-sidebar>
              <!-- End Sidebar View -->
            </template>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->

    <!-- Content Section !-->

    <!-- End Content Section !-->
  </b-container>
</template>
<script>
import AddFleet from '../MasterPages/AddFleet.vue'
import { fleetsActions, companiesActions, miActions } from '@src/Utils/helper'
import SummaryManagementDetailAccidentList from '@src/views/MI/SummaryManagementDetailAccidentList'
import SummaryManagementRecommendations from '@src/views/MI/SummaryManagementRecommendations'
import SummaryManagementEvaluations from '@src/views/MI/SummaryManagementEvaluations'
import SummaryManagementERM from '@src/views/MI/SummaryManagementERM'
import IqCard from '@/src/components/xray/cards/iq-card.vue'
import OperationalIcon from '../Dashboards/IconsTabMenus/OperationalIcon.vue'
import FleetActions from './FleetActions.vue'
import ListCrew from './ListCrew.vue'
import ListPic from './ListPic.vue'
import SlideFleetStatusUpdate from './components/SlideFleetStatusUpdate.vue'

export default {
  name: 'PMS',
  components: {
    SummaryManagementERM,
    SummaryManagementEvaluations,
    SummaryManagementRecommendations,
    SummaryManagementDetailAccidentList,
    AddFleet,
    IqCard,
    OperationalIcon,
    FleetActions,
    ListCrew,
    ListPic,
    SlideFleetStatusUpdate
  },
  data() {
    return {
      fleetId: null,
      statusFleet: '',
      fleetDetail: null,
      fleetDetailMiDataModal: null,
      defaultPhoto: require('../../assets/images/user/11.png'),
      categoryOwnership: [
        { value: null, text: 'All Category Ownership' },
        { value: 'OWNED', text: 'Owned By Company' },
        { value: 'CHARTERED', text: 'Chartered' },
      ],
      view: 'large',
      modalFilter: false,
      // Breadcrumb
      breadcrumIcon: [
        {
          html: '<i class="ri-home-4-line mr-1 float-left"></i>PMS',
          href: '#',
          active: true,
        },
      ],
      loading: true,
      params: {
        page: 1,
        perPage: 12,
        active: true,
        ownership: 'all',
        search: '',
        companyId: null,
        vehicleTypeId: '',
      },
      isFetchingLoadMore: false,
      isHideLoadMore: false,
      fleetActivities: [],
      crewInFleet: [],
      // Edit Fleet
      modal: {
        show: false,
        variants: [
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'info',
          'light',
          'dark',
        ],
        headerBgVariant: 'light',
        headerTextVariant: 'dark',
        bodyBgVariant: 'default',
        bodyTextVariant: 'dark',
        footerBgVariant: '#000000',
        footerTextVariant: 'dark',
      },
      fleetCategory: [
        {
          label: 'Tug Boat',
          color: 'success',
          value: 'tugboat',
        },
        {
          label: 'Barge',
          color: 'warning',
          value: 'barge',
        },
        {
          label: 'Mother Vessel',
          color: 'primary',
          value: 'mother_vessel',
        },
        {
          label: 'Heavy Equipment',
          color: 'primary',
          value: 'heavy_equipment',
        },
        {
          label: 'Pusher Tug',
          color: 'primary',
          value: 'pusher_tug',
        },
        {
          label: 'Pusher Barge',
          color: 'primary',
          value: 'pusher_barge',
        },
      ],
      technicalActive: {
        technical_module: 'active',
      },
      formPower: {
        _saving: false,
        typeOfPower: [
          {
            type: '',
            series: '',
            actual: '',
          },
        ],
      },
      powerOptions: [
        { value: '1', text: 'Main Engine 1' },
        { value: '2', text: 'Main Engine 1' },
        { value: '3', text: 'Auxiliary Engine 1' },
        { value: '4', text: 'Auxiliary Engine 2' },
        { value: '5', text: 'Diesel Generator 1' },
        { value: '6', text: 'Diesel Generator 2' },
        { value: '7', text: 'Diesel Generator 3' },
        { value: '8', text: 'Engine Crane 1' },
        { value: '9', text: 'Emergency Generator' },
      ],
      ROBOptions: [
        { value: '1', text: 'Fuel Oil' },
        { value: '2', text: 'Fresh Water' },
        { value: '3', text: 'Drill Water' },
        { value: '4', text: 'Barite' },
        { value: '5', text: 'Bentonite' },
        { value: '6', text: 'Cement' },
        { value: '7', text: 'Brine' },
      ],
      seriesROBOptions: [
        { value: '1', text: 'TELS 46' },
        { value: '2', text: 'TELS 48' },
        { value: '3', text: 'OMEL 100' },
        { value: '4', text: 'SHELL' },
      ],
      formROBType: {
        _saving: false,
        typeOfROB: [
          {
            type: '',
            series: '',
            actual: '',
          },
        ],
      },
      main_engine: [
        { value: 'Yanmar 12LAK-STE2', text: 'Yanmar 12LAK-STE2' },
        {
          value: 'Weichai TD226B - 3CDI/4CD',
          text: 'Weichai TD226B - 3CDI/4CD',
        },
        { value: 'Cummin s6BT5.9DM', text: 'Cummin s6BT5.9DM' },
        { value: 'MITSUBISHI S6R2-T2MTK3L', text: 'MITSUBISHI S6R2-T2MTK3L' },
        { value: 'Caterpillar C-32', text: 'Caterpillar C-32' },
      ],
      CompanyName: [{ value: null, text: 'All Company' }],
      selectedCertificateName: null,
      certificateName: [
        { value: '1', text: 'SNPP/IOPP/Marpol' },
        { value: '2', text: 'Class Mesin' },
        { value: '3', text: 'Class Garis Muat' },
        { value: '4', text: 'Class Lambung' },
        { value: '5', text: 'Sertifikat Keselamatan Radio Kapal Barang' },
        { value: '6', text: 'Surat Laut' },
        { value: '7', text: 'Sertifikat Safe Manning' },
      ],
      selectedCertificateType: null,
      certificateType: [
        { value: '1', text: 'Permanent-Endorsement' },
        { value: '2', text: 'Unpermanent-Endrsement' },
      ],
      Contract: [
        { value: '1', text: 'Owned by Company' },
        { value: '2', text: 'Chartered' },
      ],
      job_check: [
        {
          checked: false,
          disabled: false,
        },
      ],
      formFileSupportList: {
        _saving: false,
        fileSupport: [
          {
            name: '',
            file: '',
          },
        ],
      },
      technical: [
        {
          name: 'technical_module',
          label: 'Yes',
          value: 'active',
          disabled: false,
        },
        {
          name: 'technical_module',
          label: 'No',
          value: 'inactive',
          disabled: false,
        },
      ],
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      vehicleSelected: [],
      showModal:{
        setupComponent:false
      },
      fleetSelected:null,
      modalCrewList:false,
      modalPicList:false,
      slideChangeFleetStatus:false
    }
  },
  computed: {
    permissionMaintenanceSetup() {
      let active = JSON.parse(localStorage.getItem('profile'))
        .menu.find((obj) => obj.menu === 'Apps')
        ?.child.find((obj) => obj.menu === 'Technical')
        ?.child.find((obj) => obj.menu === 'Setup Component')?.active

      let add = JSON.parse(localStorage.getItem('profile'))
        .menu.find((obj) => obj.menu === 'Apps')
        ?.child.find((obj) => obj.menu === 'Technical')
        ?.child.find((obj) => obj.menu === 'Setup Component')?.add

      let hasAccess = false
      if (active && add) {
        hasAccess = true
      } else {
        hasAccess = false
      }
      return hasAccess
    },
    permissionMaintenanceInitiation() {
      let active = JSON.parse(localStorage.getItem('profile'))
        .menu.find((obj) => obj.menu === 'Apps')
        ?.child.find((obj) => obj.menu === 'Technical')
        ?.child.find((obj) => obj.menu === 'Master Maintenance')?.active

      let add = JSON.parse(localStorage.getItem('profile'))
        .menu.find((obj) => obj.menu === 'Apps')
        ?.child.find((obj) => obj.menu === 'Technical')
        ?.child.find((obj) => obj.menu === 'Master Maintenance')?.add

      let hasAccess = false
      if (active && add) {
        hasAccess = true
      } else {
        hasAccess = false
      }
      return hasAccess
    },
    permissionSpecialSurvey() {
      let active = JSON.parse(localStorage.getItem('profile'))
        .menu.find((obj) => obj.menu === 'Apps')
        ?.child.find((obj) => obj.menu === 'Technical')
        ?.child.find((obj) => obj.menu === 'Docking')?.active

      let add = JSON.parse(localStorage.getItem('profile'))
        .menu.find((obj) => obj.menu === 'Apps')
        ?.child.find((obj) => obj.menu === 'Technical')
        ?.child.find((obj) => obj.menu === 'Docking')?.add

      let hasAccess = false
      if (active && add) {
        hasAccess = true
      } else {
        hasAccess = false
      }
      return hasAccess
    },
    permissionEditFleet() {
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu.find(
        (obj) => obj.menu === 'Fleets'
      ).update
      return hasAccess
    },
    permissionDeleteFleet() {
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu.find(
        (obj) => obj.menu === 'Fleets'
      ).delete
      return hasAccess
    },
    userVehicles() {
      return this.$store.state.auth.profile.vehicle || []
    },
    vehicleTypes() {
      let vehicleTypes = []

      this.userVehicles.forEach((vehicle) => {
        const vehicleTypeExist = vehicleTypes.find(
          (v) => v.id === vehicle.vehicleType.id
        )
        if (!vehicleTypeExist) {
          vehicle.vehicleType.checked = true
          vehicleTypes.push(vehicle.vehicleType)
        }
      })

      return vehicleTypes
    },
    technicalPermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Technical')
    },
    operationalPermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Operations')
    },
    reportPermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Report')
    },
  },
  mounted() {
    this.getCompanyListData()
    this.fetchFleetActivities()
  },
  methods: {
    ...fleetsActions,
    ...miActions,
    ...companiesActions,
    onOpenSlideFleetStatus(id, statusFleet = ''){
      this.slideChangeFleetStatus = !this.slideChangeFleetStatus

      if(id) {
        this.fleetId = id
        this.statusFleet = statusFleet
      } else {
        this.statusFleet = ""
        this.fleetId = null
      }
    },
    toggleFilter() {
      this.modalFilter = !this.modalFilter
    },
    validateTechnicalPermission(url) {
      let getPermission = this.technicalPermission
        ? this.technicalPermission.child.find((menu) => menu.url === url)
        : null
      return getPermission ? getPermission.active : false
    },
    validateOperationalPermission(url) {
      let getPermission = this.operationalPermission
        ? this.operationalPermission.child.find((menu) =>
            url.includes(menu.url)
          )
        : null
      return getPermission ? getPermission.active : false
    },
    validateReportPermission(url) {
      let getPermission = this.reportPermission
        ? this.reportPermission.child.find((menu) => menu.url === url)
        : null
      return getPermission ? getPermission.active : false
    },
    async fetchFleetActivities() {
      this.loading = true

      let params = { ...this.params }

      if (params.ownership === 'all') {
        params.ownership = ''
      }

      // const checkedFleetTypes = this.checkedFleetTypes()
      const checkedFleetTypes = this.vehicleSelected.map((el) => el.id)

      params.vehicleTypeId =
        checkedFleetTypes.length > 0 ? checkedFleetTypes.join(',') : ''

      params = this.$options.filters.cleanObject(params)
      let res = await this.getFleetActivities(params)

      this.fleetActivities = []

      const promises = res.data.map(async (vehicle) => {
        // const vehicleExist = this.userVehicles.find(v => v.id === vehicle.id)
        // if (vehicleExist) this.fleetActivities.push(vehicle)
        let newCrewData = []

        const res_crew = await this.getCrewInFleet({ vehicle_id: vehicle.id })

        const total_crew = res_crew.data.reduce((count, value) => {
          if (value.status_emp_crewing.alias === 'active') {
            return count + 1
          }
          return count
        }, 0)

        res_crew.data.map((obj) => {
          if (obj.status_emp_crewing.alias === 'active') {
            newCrewData.push({
              ...obj,
              photo: obj?.photo
                ? obj?.photo_url
                : obj?.personal_data?.gender === 'Male'
                ? require('../../assets/images/user/09.jpg')
                : require('../../assets/images/user/11.png'),
            })
          }
        })

        this.fleetActivities.push({
          ...vehicle,
          total_crew,
          crew_data: newCrewData,
        })
      })

      await Promise.all(promises)

      if (res && res.currentPage && res.totalPage) {
        if (res.currentPage === res.totalPage) {
          this.isHideLoadMore = true
        } else {
          this.isHideLoadMore = false
        }
      } else {
        this.isHideLoadMore = true
      }

      this.loading = false
    },
    async fetchFleetActivitiesLoadMore() {
      this.isFetchingLoadMore = true
      this.params.page += 1
      let params = { ...this.params }

      if (params.ownership === 'all') {
        params.ownership = ''
      }

      // const checkedFleetTypes = this.checkedFleetTypes()
      const checkedFleetTypes = this.vehicleSelected.map((el) => el.id)

      params.vehicleTypeId =
        checkedFleetTypes.length > 0 ? checkedFleetTypes.join(',') : ''

      params = this.$options.filters.cleanObject(params)

      let res = await this.getFleetActivities(params)

      const promises = res.data.map(async (vehicle) => {
        // const vehicleExist = this.userVehicles.find(v => v.id === vehicle.id)
        // if (vehicleExist) this.fleetActivities.push(vehicle)
        let newCrewData = []

        const res_crew = await this.getCrewInFleet({ vehicle_id: vehicle.id })

        const total_crew = res_crew.data.reduce((count, value) => {
          if (value.status_emp_crewing.alias === 'active') {
            return count + 1
          }
          return count
        }, 0)

        res_crew.data.map((obj) => {
          if (obj.status_emp_crewing.alias === 'active') {
            newCrewData.push({
              ...obj,
              photo: obj?.photo
                ? obj?.photo_url
                : obj?.personal_data?.gender === 'Male'
                ? require('../../assets/images/user/09.jpg')
                : require('../../assets/images/user/11.png'),
            })
          }
        })

        this.fleetActivities.push({
          ...vehicle,
          total_crew,
          crew_data: newCrewData,
        })
      })

      await Promise.all(promises)

      if (res && res.currentPage && res.totalPage) {
        if (res.currentPage === res.totalPage) {
          this.isHideLoadMore = true
        } else {
          this.isHideLoadMore = false
        }
      } else {
        this.isHideLoadMore = true
      }

      this.isFetchingLoadMore = false
    },
    async getCompanyListData() {
      const res = await this.getCompanies()

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          this.CompanyName.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id,
          })
        })
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    checkedFleetTypes(array) {
      let checked = []

      this.vehicleTypes.forEach((fleet) => {
        if (fleet.checked) {
          checked.push(fleet.id)
        }
      })

      return checked
    },
    async fetchFleetDetail(id) {
      const res = await this.getFleetById(id)

      if (res.status == 'success') {
        this.fleetDetail = res.data
        this.$bvModal.show('edit-fleet')
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
      this.loadingForm = false
    },
    hashObject(obj) {
      return window.btoa(JSON.stringify(obj))
    },
    async onDeleteFleet(dataVal) {
      let data = dataVal

      this.$swal({
        title: 'Delete Fleet?',
        text: `Fleet ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteFleet(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.fetchFleetActivities()
            this.$swal(
              `Fleet deleted!`,
              `Fleet ${data.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    async handleOpenMI(data) {
      const forms = await this.getFleetForms({
        vehicleId: data.id,
      })

      if (forms.data) {
        const recommendations = forms.data
          .map((form) => {
            return {
              data: form.recommendations,
              vehicle: data,
            }
          })
          .flat()
        const erm = forms.data
          .map((form) => {
            return {
              data: form.erm,
              vehicle: data,
            }
          })
          .flat()
        const evaluations = forms.data
          .map((form) => {
            return {
              data: form.evaluations,
              vehicle: data,
            }
          })
          .flat()

        this.fleetDetailMiDataModal = {
          ...data,
          forms: forms.data,
          recommendations,
          erm,
          evaluations,
        }

        this.fleetDetailMiDataModal.erm.map((val, index) => {
          this.fleetDetailMiDataModal.forms.map((obj, objIndex) => {
            if (index === objIndex) {
              val.type_data = {
                type: obj.type_form,
                date_of_loss: obj.date_of_loss,
              }
            }
          })

          return val
        })

        setTimeout(() => {
          this.$root.$emit('bv::toggle::collapse', 'sidebar-mi')
        }, 500)
      }
    },
    getStatusColor(status){
      let color = 'danger'
      switch (status.toLowerCase()) {
        case 'active':
          color = "success";
          break;
        case 'sell':
          color = "primary";
          break;
        case 'on_loan':
          color = "warning";
          break;
      }
      return color
    }
  },
}
</script>
<style scoped>
table th {
  background: #334697 !important;
  color: white;
}
.button-view:hover {
  background: #334697 !important;
  border: #334697 !important;
}
.fleet-card-wrap{
  position: relative;
  border-radius: 12px;
}
.fleet-card-wrap .content-text{
  position: absolute;
  bottom: 0;
  padding: 0 12px;
}
.img-card-shadow {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.42903098739495793) 0%,rgba(255, 255, 255, 0) 55%);
  border-radius: 12px;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("BUNKER ROUTES")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center gap-4 float-right"
        }, [_c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.onExportBunkerRoute
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _c('span', [_vm._v("Export Data")])]), _vm.addPermission ? _c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.onAdd
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _c('span', [_vm._v("Create Data")])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list, _vm$list$data, _vm$list2, _vm$list2$data, _vm$list3, _vm$list3$meta, _vm$list4, _vm$list4$meta;
        return [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search ID/Name...",
            "name": "search"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "button",
            "variant": "primary"
          },
          on: {
            "click": _vm.getListBunkerRoute
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.loading ? _c('div', {
          staticClass: "alert alert-info"
        }, [_vm._v("Loading...")]) : _vm._e(), _c('iq-card', [((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : (_vm$list$data = _vm$list.data) === null || _vm$list$data === void 0 ? void 0 : _vm$list$data.length) > 0 ? _c('div', {
          staticClass: "overflow-auto d-flex"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Route ID")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("POL")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("POD")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Type")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Route Name")]), _c('th', {
          staticClass: "text-right align-middle text-truncate"
        }, [_vm._v("Steaming Time")]), _c('th', {
          staticClass: "text-right align-middle text-truncate"
        }, [_vm._v("Distance")]), _c('th', {
          staticClass: "text-right align-middle text-truncate"
        }, [_vm._v("Speed")]), _c('th', {
          staticClass: "text-right align-middle text-truncate"
        }, [_vm._v("Ltrs")]), _c('th', {
          staticClass: "text-right align-middle text-truncate"
        }, [_vm._v("Amount")]), _c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.list.data, function (val, index) {
          var _val$pol, _val$pod, _val$streaming_time, _val$distance, _val$speed, _val$ltrs, _val$amount;
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.route_code))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$pol = val.pol) === null || _val$pol === void 0 ? void 0 : _val$pol.port_name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$pod = val.pod) === null || _val$pod === void 0 ? void 0 : _val$pod.port_name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.type))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.route_name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$streaming_time = val.streaming_time) === null || _val$streaming_time === void 0 ? void 0 : _val$streaming_time.toLocaleString()))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$distance = val.distance) === null || _val$distance === void 0 ? void 0 : _val$distance.toLocaleString()))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$speed = val.speed) === null || _val$speed === void 0 ? void 0 : _val$speed.toLocaleString()))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$ltrs = val.ltrs) === null || _val$ltrs === void 0 ? void 0 : _val$ltrs.toLocaleString()))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$amount = val.amount) === null || _val$amount === void 0 ? void 0 : _val$amount.toLocaleString()))]), _vm.updatePermission || _vm.deletePermission ? _c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('i', {
            staticClass: "fa fa-pen text-primary mr-3",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onEdit(val === null || val === void 0 ? void 0 : val.id);
              }
            }
          }), _c('i', {
            staticClass: "fa fa-trash text-danger",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onDelete(val);
              }
            }
          })])]) : _vm._e()]);
        }), 0)])]) : _vm._e(), ((_vm$list2 = _vm.list) === null || _vm$list2 === void 0 ? void 0 : (_vm$list2$data = _vm$list2.data) === null || _vm$list2$data === void 0 ? void 0 : _vm$list2$data.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": (_vm$list3 = _vm.list) === null || _vm$list3 === void 0 ? void 0 : (_vm$list3$meta = _vm$list3.meta) === null || _vm$list3$meta === void 0 ? void 0 : _vm$list3$meta.totalItem,
            "per-page": (_vm$list4 = _vm.list) === null || _vm$list4 === void 0 ? void 0 : (_vm$list4$meta = _vm$list4.meta) === null || _vm$list4$meta === void 0 ? void 0 : _vm$list4$meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListBunkerRoute
          },
          model: {
            value: _vm.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "page", $$v);
            },
            expression: "params.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No BunkerRoute data found.")])])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "add-bunker",
      "title": "".concat(_vm.isEdit ? 'EDIT' : 'ADD', " Bunker Route"),
      "width": "50%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Create Bunker Route")]), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('FormBunkerRoute', {
          attrs: {
            "show-add-bunker": _vm.showAddBunkerRoute,
            "is-edit": _vm.isEdit,
            "edit-id": _vm.editId,
            "refresh-data": _vm.refreshData,
            "listPorts": _vm.listPorts
          }
        })], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.showAddBunkerRoute,
      callback: function callback($$v) {
        _vm.showAddBunkerRoute = $$v;
      },
      expression: "showAddBunkerRoute"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
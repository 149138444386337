var render = function render(){
  var _vm$list, _vm$list$params, _vm$list2, _vm$list$data;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PIC MOVEMENT / TRANSFER")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }, [_c('b-modal', {
    attrs: {
      "size": "md",
      "title": "Filter Data",
      "ok-title": "Filter",
      "centered": ""
    },
    on: {
      "ok": function ok($event) {
        return _vm.fetchListPICMovement(1);
      }
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', {
    staticClass: "px-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3 d-flex",
    attrs: {
      "label": "Date",
      "label-for": "filter-date",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "id": "filter-date",
      "type": "date",
      "range": "",
      "placeholder": "Select date range"
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Transfer Type",
      "label-for": "movementType",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "plain": "",
      "placeholder": "Select Options...",
      "name": "movementType"
    },
    model: {
      value: _vm.list.params.movementType,
      callback: function callback($$v) {
        _vm.$set(_vm.list.params, "movementType", $$v);
      },
      expression: "list.params.movementType"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select options")]), _c('b-form-select-option', {
    attrs: {
      "value": "new"
    }
  }, [_vm._v("New")]), _c('b-form-select-option', {
    attrs: {
      "value": "rotate"
    }
  }, [_vm._v("Rotate")]), _c('b-form-select-option', {
    attrs: {
      "value": "reduce"
    }
  }, [_vm._v("Reduce")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Status",
      "label-for": "status",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "plain": ""
    },
    model: {
      value: _vm.list.params.state,
      callback: function callback($$v) {
        _vm.$set(_vm.list.params, "state", $$v);
      },
      expression: "list.params.state"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All")]), _c('b-form-select-option', {
    attrs: {
      "value": "active"
    }
  }, [_vm._v("Active")]), _c('b-form-select-option', {
    attrs: {
      "value": "request"
    }
  }, [_vm._v("Request")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-between px-4 mt-3 mb-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-input', {
    attrs: {
      "size": "md",
      "placeholder": "search..."
    },
    model: {
      value: _vm.list.params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.list.params, "search", $$v);
      },
      expression: "list.params.search"
    }
  }), _c('b-button', {
    staticClass: "mx-2",
    attrs: {
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })]), _c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.toggleModalFilter
    }
  }, [_c('i', {
    staticClass: "fas fa-sliders"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Filter Data")])])], 1), _c('div', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.openSlideForm('ADD');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v("   CREATE MOVEMENT")])], 1)]), _c('div', {
    staticClass: "px-4 mb-3"
  }, [_c('b-row', {
    staticClass: "m-0 border pt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm.dateRange.length ? _c('b-form-group', {
    attrs: {
      "label": "Date",
      "label-for": "filter-date"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.moment(_vm.dateRange[0]).format('DD MMM YYYY')) + "-" + _vm._s(_vm.moment(_vm.dateRange[1]).format('DD MMM YYYY')))])]) : _vm._e()], 1), (_vm$list = _vm.list) !== null && _vm$list !== void 0 && (_vm$list$params = _vm$list.params) !== null && _vm$list$params !== void 0 && _vm$list$params.movementType ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Transfer Type",
      "label-for": "movementType"
    }
  }, [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.list.params.movementType))])])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.list.params.state || 'all'))])])], 1)], 1)], 1), [_c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "width": "150px"
    }
  }, [_vm._v("NAME")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("TRANSFER TYPE")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("CREATED DATE")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("EFFECTIVE DATE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "width": "300px"
    }
  }, [_vm._v("FLEETS")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("STATUS")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("ATTACHMENT")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("ACTION")])])]), _c('tbody', [_vm._l((_vm$list2 = _vm.list) === null || _vm$list2 === void 0 ? void 0 : _vm$list2.data, function (state, index) {
    var _state$sourcePic$name, _state$sourcePic;
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$sourcePic$name = state === null || state === void 0 ? void 0 : (_state$sourcePic = state.sourcePic) === null || _state$sourcePic === void 0 ? void 0 : _state$sourcePic.name) !== null && _state$sourcePic$name !== void 0 ? _state$sourcePic$name : 'N/A'))]), _c('td', {
      staticClass: "text-center"
    }, [['new'].includes(state === null || state === void 0 ? void 0 : state.movementType) ? _c('b-badge', {
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v("New")]) : _vm._e(), ['rotate'].includes(state === null || state === void 0 ? void 0 : state.movementType) ? _c('b-badge', {
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v("Rotate")]) : _vm._e(), ['reduce'].includes(state === null || state === void 0 ? void 0 : state.movementType) ? _c('b-badge', {
      attrs: {
        "variant": "danger"
      }
    }, [_vm._v("Reduce")]) : _vm._e()], 1), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.createdAt ? _vm.moment(state.createdAt, "DD-MM-YYYY HH:mm").format("DD MMM YYYY HH:mm") : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.effectiveDate ? _vm.moment(state.effectiveDate, "DD-MM-YYYY").format("DD MMM YYYY") : '-'))]), ['new'].includes(state === null || state === void 0 ? void 0 : state.movementType) ? _c('td', {
      staticClass: "text-center text-truncate"
    }, [_c('div', {
      staticClass: "d-flex flex-wrap"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.vehicles, function (fleet, fleetIndex) {
      return _c('b-badge', {
        key: fleetIndex,
        staticClass: "border border-primary text-primary text-uppercase mr-1 mb-1",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(_vm._s(fleet === null || fleet === void 0 ? void 0 : fleet.name))]);
    }), 1)]) : _vm._e(), ['rotate'].includes(state === null || state === void 0 ? void 0 : state.movementType) ? _c('td', {
      staticClass: "text-center text-truncate"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-center"
    }, [_c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 001")]), _c('span', {
      staticClass: "text-primary mx-2"
    }, [_c('i', {
      staticClass: "fa fa-chevron-right"
    }), _c('i', {
      staticClass: "fa fa-chevron-right"
    })]), _c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 002")])], 1), _c('div', {
      staticClass: "d-flex align-items-center justify-content-center"
    }, [_c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 002")]), _c('span', {
      staticClass: "text-primary mx-2"
    }, [_c('i', {
      staticClass: "fa fa-chevron-right"
    }), _c('i', {
      staticClass: "fa fa-chevron-right"
    })]), _c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 003")])], 1), _c('div', {
      staticClass: "d-flex align-items-center justify-content-center"
    }, [_c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 003")]), _c('span', {
      staticClass: "text-primary mx-2"
    }, [_c('i', {
      staticClass: "fa fa-chevron-right"
    }), _c('i', {
      staticClass: "fa fa-chevron-right"
    })]), _c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 004")])], 1)]) : _vm._e(), ['reduce'].includes(state === null || state === void 0 ? void 0 : state.movementType) ? _c('td', {
      staticClass: "text-center text-truncate"
    }, [_c('div', {
      staticClass: "d-flex flex-wrap"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.vehicles, function (fleet, fleetIndex) {
      return _c('b-badge', {
        key: fleetIndex,
        staticClass: "border border-primary text-primary text-uppercase mr-1 mb-1",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(_vm._s(fleet === null || fleet === void 0 ? void 0 : fleet.name))]);
    }), 1)]) : _vm._e(), _c('td', {
      staticClass: "text-center text-capitalize"
    }, [['active'].includes(state === null || state === void 0 ? void 0 : state.state) ? _c('b-badge', {
      attrs: {
        "variant": "success"
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.state))]) : _vm._e(), ['decline'].includes(state === null || state === void 0 ? void 0 : state.state) ? _c('b-badge', {
      attrs: {
        "variant": "danger"
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.state))]) : _vm._e(), ['approved'].includes(state === null || state === void 0 ? void 0 : state.state) ? _c('b-badge', {
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.state))]) : _vm._e()], 1), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.attachment ? 'File' : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideForm('DETAIL', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Detail ")]), !['active'].includes(state === null || state === void 0 ? void 0 : state.state) ? _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.openModalStatusApproval(state === null || state === void 0 ? void 0 : state.id, 'approve');
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-check mr-2"
    }), _vm._v("Status Approve ")]) : _vm._e(), !['active'].includes(state === null || state === void 0 ? void 0 : state.state) ? _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.openModalStatusApproval(state === null || state === void 0 ? void 0 : state.id, 'reject');
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v("Cancel Transfer ")]) : _vm._e()], 1)], 1)]);
  }), ((_vm$list$data = _vm.list.data) === null || _vm$list$data === void 0 ? void 0 : _vm$list$data.length) <= 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "9"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No data exists.")])])]) : _vm._e()], 2)])]), _vm.list.data.length > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.list.meta.total,
      "per-page": _vm.list.meta.perPage,
      "first-number": "",
      "align": "center"
    },
    on: {
      "change": _vm.fetchListPICMovement
    },
    model: {
      value: _vm.list.meta.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.list.meta, "currentPage", $$v);
      },
      expression: "list.meta.currentPage"
    }
  })], 1) : _vm._e()])], 1)], _c('b-sidebar', {
    attrs: {
      "id": "slideAddPicMovement",
      "title": "".concat(_vm.action == 'ADD' ? 'ADD' : _vm.action == 'EDIT' ? 'EDIT' : 'DETAIL', " PIC MOVEMENT"),
      "width": "70%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    model: {
      value: _vm.slideAddPicMovement,
      callback: function callback($$v) {
        _vm.slideAddPicMovement = $$v;
      },
      expression: "slideAddPicMovement"
    }
  }, [[_c('SlideFormPicMovement', {
    attrs: {
      "action": _vm.action,
      "refreshData": _vm.refreshData,
      "editId": _vm.editId
    },
    on: {
      "updateAction": _vm.updateAction
    }
  })]], 2), _c('b-modal', {
    attrs: {
      "id": "status-approval",
      "size": "md",
      "title": "".concat(_vm.action === 'reject' ? 'Cancel Transfer' : 'Status Approve'),
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Remarks",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "4",
      "placeholder": "Text Type",
      "name": "description"
    },
    model: {
      value: _vm.formApproval.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formApproval, "description", $$v);
      },
      expression: "formApproval.description"
    }
  })], 1), _c('div', {
    staticClass: "d-flex w-100 border my-2"
  }), _c('div', {
    staticClass: "d-flex w-100 align-items-center justify-content-end"
  }, [_vm.action === 'reject' ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "danger",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onStatusApproval('reject');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-ban mr-2"
  }), _vm._v(" Reject ")]) : _vm._e(), _vm.action === 'approve' ? _c('b-button', {
    attrs: {
      "variant": "success",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onStatusApproval('approve');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-check mr-2"
  }), _vm._v(" Approve ")]) : _vm._e()], 1)], 1)], 1)], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
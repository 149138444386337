<template>
  <div class="d-flex flex-column justify-content-between overflow-auto" style="max-height:100vh;">
    <div class="w-100" v-for="(tab, index) in tabs" :key="index">
      <div class="d-flex align-items-center w-100  cursor-pointer tab-animation" @click="handleClick(tab.id)">
        <div
          class="tab-number border"
          :class="{ 'bg-primary': activeTab >= index + 1 }"
        >
          {{ tab.id }}
        </div>
        <h6 :class="{ 'text-primary font-semibold': activeTab >= index + 1 }" class="ml-2">{{ tab.label }}</h6>
      </div>
      <div
        class="line-tab"
        :class="{
          'border-active': activeTab >= index + 1,
          'border-inactive': index >= activeTab,
          
        }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeTab: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tabs: [
        {
          label: 'Sasaran Smart',
          labelBtn: 'A',
          id: 1,
        },
        {
          label: 'Penamaan Risiko',
          labelBtn: 'B',
          id: 2,
        },
        {
          label: 'Prinsip Manajemen Risiko',
          labelBtn: 'C',
          id: 3,
        },
        {
          label: 'Kepemimpinan Dan Komitmen',
          labelBtn: 'D',
          id: 4,
        },
        {
          label: 'Analisa Pemangku Kepentingan',
          labelBtn: 'H',
          id: 5,
        },
        {
          label: 'Integrasi - Sasaran Smart',
          labelBtn: 'I',
          id: 6,
        },
        {
          label: 'Evaluasi',
          labelBtn: 'J',
          id: 7,
        },
        {
          label: 'Perbaikan Sinambung',
          labelBtn: 'K',
          id: 8,
        },
        {
          label: 'Komunikasi Dan Konsultasi',
          labelBtn: 'L',
          id: 9,
        },
        {
          label: 'Raci Matrix',
          labelBtn: 'M',
          id: 10,
        },
        {
          label: 'Kriteria Konsekuensi',
          labelBtn: 'N',
          id: 11,
        },
        {
          label: 'Kriteria Kemungkinan',
          labelBtn: 'O',
          id: 12,
        },
        {
          label: 'Efektifitas Kendali',
          labelBtn: 'P',
          id: 13,
        },
        {
          label: 'Kriteria Evaluasi',
          labelBtn: 'R',
          id: 14,
        },
        {
          label: 'Identifikasi Risiko',
          labelBtn: 'S',
          id: 15,
        },
        {
          label: 'Analisis Dan Evaluasi Risiko',
          labelBtn: 'T',
          id: 16,
        },
        {
          label: 'Peta Risiko - Before',
          labelBtn: 'U',
          id: 17,
        },
        {
          label: 'Perlakuan Risiko',
          labelBtn: 'V',
          id: 18,
        },
        {
          label: 'Peta Risiko - After',
          labelBtn: 'X',
          id: 19,
        },
      ],
    }
  },
  methods: {
    handleClick(id) {
      this.$emit('clickTab', id)
    },
  },
}
</script>
<style scoped>
.tab-number {
  border: 1px solid black;
  width: 44px !important;
  height: 44px !important;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  aspect-ratio: 1/1;
}

.line-tab {
  border-left: 1.5px solid;
  height: 40px;
  width: max-content;
  margin-left: 23px;
}
.border-active {
  border-color: #0a9cab;
}
.border-inactive {
  border-color: #dee2e6;
}
.font-semibold{
    font-weight: 500;
}
</style>

var render = function render(){
  var _vm$picData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-0 py-2"
  }, [((_vm$picData = _vm.picData) === null || _vm$picData === void 0 ? void 0 : _vm$picData.length) > 0 ? _c('ul', {
    staticClass: "doctors-lists m-0 p-0"
  }, _vm._l(_vm.picData, function (pic) {
    var _pic$name, _pic$email;
    return _c('li', {
      key: 'pic-' + (pic === null || pic === void 0 ? void 0 : pic.id),
      staticClass: "my-2 align-items-center"
    }, [_c('div', {
      staticClass: "d-flex w-100 align-items-center justify-content-between"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', [_c('strong', [_vm._v(_vm._s((_pic$name = pic === null || pic === void 0 ? void 0 : pic.name) !== null && _pic$name !== void 0 ? _pic$name : '-'))])]), _c('span', [_vm._v("Email: "), _c('strong', [_vm._v(_vm._s((_pic$email = pic === null || pic === void 0 ? void 0 : pic.email) !== null && _pic$email !== void 0 ? _pic$email : '-'))])])]), _c('i', {
      staticClass: "fa fa-user text-primary mr-2",
      staticStyle: {
        "font-size": "20px"
      }
    })]), _c('div', {
      staticClass: "w-100 d-flex my-2 border"
    })]);
  }), 0) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h6', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("This Fleet doesnt have PIC")])]);

}]

export { render, staticRenderFns }
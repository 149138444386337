<template>
  <div>
    <b-form-checkbox
      v-if="canPreview"
      name="check-button"
      v-model="isPreview"
      switch
      inline
    >
    </b-form-checkbox>
    <RcaFinalPreviewStep :detailMi="detailMi"/>
    <!-- <FinalReportRootCauseAnalysisPreview v-if="isPreview" /> -->
    <!-- <FinalReportRootCauseAnalysisForm v-else /> -->

  </div>
</template>
<script>
import { xray } from '@/src/config/pluginInit'
import FinalReportRootCauseAnalysisForm from '@src/views/MI/FinalReportRootCauseAnalysisForm'
import FinalReportRootCauseAnalysisPreview from '@src/views/MI/FinalReportRootCauseAnalysisPreview'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'
import RcaFinalPreviewStep from './RcaFinalPreviewStep.vue'

export default {
  components: {
    StepNavigation,
    StepWizard,
    FinalReportRootCauseAnalysisPreview,
    FinalReportRootCauseAnalysisForm,
    RcaFinalPreviewStep
  },
  props:{
    detailMi:null
  },
  mounted() {
    if (
      this.$route.query.preview === 'true' ||
      this.$route.query.preview === true
    ) {
      this.isPreview = true
    }
    if (this.$route.query.id && this.$route.query.formId) {
      this.canPreview = true
    }
    xray.index()
  },
  props:{detailMi:null},
  data() {
    return {
      isPreview: false,
      canPreview: false,
    }
  },
}
</script>

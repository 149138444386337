var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ApexChart', {
    attrs: {
      "element": "chart-sales-".concat(_vm.element),
      "chartOption": _vm.chartSalesAnalysis
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
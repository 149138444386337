<template>
  <div>
    <iq-card>
      <template #body>
        <div class="" v-for="(item, index) in steps" :key="index">
          <div
            class="border p-3 mb-2 d-flex align-items-center justify-content-between cursor-pointer"
            style="border-radius: 8px"
            @click="item.collapse = !item.collapse"
          >
            <div class="d-flex align-items-center">
              <i
                class="fa fa-caret-right mr-2 caret-animation"
                :class="{
                  'rotate-0': !item.collapse,
                  'rotate-90': item.collapse,
                }"
              ></i>
              <p class="m-0" style="font-size: 16px; font-weight: 500">{{
                item.title
              }}</p>
              <p
                class="m-0 ml-2"
                :class="{
                  'text-success': item.isDataExist,
                  'text-danger': !item.isDataExist,
                }"
                style="font-size: 14px; font-weight: 500"
                >{{
                  item.isDataExist
                    ? '(Data Has Been Inputted)'
                    : '(No Data Yet)'
                }}</p
              >
            </div>
            <i class="fa fa-check text-success" v-if="item.isDataExist"></i>
            <i class="fa fa-ban text-danger" v-else></i>
          </div>
          <b-collapse
            class="mt-4"
            :id="`step-${index}`"
            v-model="item.collapse"
          >
            <template v-if="item.component">
              <component
                v-if="item.collapse"
                :is="item.component"
                :detailMi="detailMi"
                :companies="companies"
                @openModalEmail="handleOpenModalEmail"
              ></component>
            </template>
          </b-collapse>
        </div>
      </template>
    </iq-card>
    <!-- SEND EMAIL -->
    <b-modal
      id="send-email-modal"
      size="lg"
      :title="`Send Email ${emailTitle}`"
      cancel-title="Close"
      hide-footer
    >
      <b-row>
        <b-col lg="12">
          <b-row>
            <b-col lg="12">
              <div class="d-flex w-100 border my-2" />
            </b-col>
            <b-col lg="12">
              <b-form-group
                label="Notif mail to user"
                class="mt-2"
                label-for="notifMailUser"
              >
                <v-multiselect
                  v-model="notifMailUser"
                  placeholder="Notif user"
                  label="name"
                  track-by="id"
                  :options="filteredExpertUsers"
                  :multiple="true"
                >
                  <span slot="noResult">Data not found.</span>
                </v-multiselect>
              </b-form-group>
            </b-col>
            <b-col lg="12" v-if="emailTitle === 'Accident'">
              <b-form-group
                label="Approval mail to user"
                class="mt-2"
                label-for="approvalMailUser"
              >
                <v-multiselect
                  v-model="approvalMailUser"
                  placeholder="Notif user"
                  label="name"
                  track-by="id"
                  :options="filteredExpertUsers"
                  :multiple="true"
                >
                  <span slot="noResult">Data not found.</span>
                </v-multiselect>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12">
          <div class="d-flex w-100 border my-2" />
        </b-col>
        <b-col lg="12" class="text-right">
          <b-button
            variant="primary"
            type="button"
            @click.prevent="sendEmailToUser"
            ><i class="fa fa-send mr-2" /> Send Mail</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <!-- SEND EMAIL END -->
  </div>
</template>
<script>
import IqCard from '@/src/components/xray/cards/iq-card.vue'
import { crewingActions, miActions } from '@/src/Utils/helper'
import ClaimAnalysisStep from './ClaimAnalysisStep.vue'
import CostBreakdownStep from './CostBreakdownStep.vue'
import LossRatioStep from './LossRatioStep.vue'
import ManagementApprovalStep from './ManagementApprovalStep.vue'
import RootCauseAnalysisStep from './RootCauseAnalysisStep.vue'
import RcaFinalStep from './RcaFinalStep.vue'
import MonitoringStep from './MonitoringStep.vue'
import FormEvaluationStep from './FormEvaluationStep.vue'
import EnterpriseRiskManagementStep from './EnterpriseRiskManagementStep.vue'
import ManagementSummaryStep from './ManagementSummaryStep.vue'
import RecomendationStep from './RecomendationStep.vue'

export default {
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    IqCard,
    ClaimAnalysisStep,
    CostBreakdownStep,
    LossRatioStep,
    ManagementApprovalStep,
    RootCauseAnalysisStep,
    RcaFinalStep,
    MonitoringStep,
    FormEvaluationStep,
    EnterpriseRiskManagementStep,
    ManagementSummaryStep,
    RecomendationStep,
  },
  data() {
    return {
      detailMi: null,
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      emailTitle: '',
      notifMailUser: [],
      approvalMailUser: [],
      filteredExpertUsers: [],
      formId: null,
      optionsExpertUsers: [],
      steps: [
        {
          key: 'CLAIM_ANALYSIS',
          title: 'Claim Analysis',
          url: '/mi/claim-analysis?',
          menu: 'Initial Claim Analysis',
          claimType: ['CLAIM'],
          collapse: false,
          component: ClaimAnalysisStep,
        },
        {
          key: 'COST_BREAKDOWN',
          title: 'Cost Breakdown',
          url: '/mi/cost-breakdown?',
          menu: 'Cost Breakdown',
          claimType: ['CLAIM'],
          collapse: false,
          component: CostBreakdownStep,
        },
        {
          key: 'LOST_RATIO',
          title: 'Loss Ratio',
          url: '/mi/loss-ratio-input?',
          menu: 'Loss Ratio',
          claimType: ['CLAIM'],
          collapse: false,
          component: LossRatioStep,
        },
        {
          key: 'MANAGEMENT_APPROVAL',
          title: 'Management Approval',
          url: '/mi/management-approval?',
          menu: 'Management Approval',
          claimType: ['CLAIM'],
          collapse: false,
          component: ManagementApprovalStep,
        },
        {
          key: 'LIST_RCA',
          title: 'List RCA',
          url: '/mi/root-cause-analysis?',
          menu: 'RCA',
          claimType: ['CLAIM'],
          collapse: false,
          component: RootCauseAnalysisStep,
        },
        {
          key: 'RCA_FINAL',
          title: 'RCA Final',
          url: '/mi/final-report-root-cause-analysis?',
          menu: 'Final RCA',
          claimType: ['CLAIM'],
          collapse: false,
          component: RcaFinalStep,
        },
        {
          key: 'MONITORING',
          title: 'Monitoring',
          url: '/mi/monitoring-form?',
          menu: 'Monitoring',
          claimType: ['CLAIM'],
          collapse: false,
          component: MonitoringStep,
        },
        {
          key: 'RECOMMENDATION',
          title: 'Recommendation',
          url: '/mi/add-form-recommendation?',
          menu: 'Recommendation',
          claimType: ['NON_CLAIM', 'CLAIM'],
          collapse: false,
          component: RecomendationStep,
        },
        {
          key: 'EVALUATION',
          title: 'Evaluation',
          url: '/mi/add-form-evaluation?',
          menu: 'Evaluation',
          claimType: ['NON_CLAIM', 'CLAIM'],
          collapse: false,
          component: FormEvaluationStep,
        },
        {
          key: 'ERM',
          title: 'Enterprise Risk Management',
          url: '/mi/enterprise-risk-management?',
          menu: 'Enterprise Risk Management',
          claimType: ['NON_CLAIM', 'CLAIM'],
          collapse: false,
          component: EnterpriseRiskManagementStep,
        },
        {
          key: 'MANAGEMENT_SUMMARY',
          title: 'Management Summary',
          url: '/mi/management-summary?',
          menu: 'Management Summary',
          claimType: ['CLAIM'],
          collapse: false,
          component: ManagementSummaryStep,
        },
      ],
      incidents: {
        isLoading: false,
        data: [],
        meta: {
          from: 0,
          to: 0,
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
        params: {
          page: 1,
          from: null,
          to: null,
          company_id: null,
          vehicle_id: null,
          type_form: null,
          status_id: null,
          id_type_coverage: null,
          id_type_loss: null,
          sort: 'date_of_loss:asc',
          with: 'vehicle,type_coverage,type_loss,cause_of_loss,company,documents,company_claimant,status,creator,histories,rcas,claim_analysis,cost_breakdown,loss_ratio,rca_final,department',
        },
        detailParams: {
          withDetail:
            'vehicle,type_coverage,type_loss,cause_of_loss,company,documents,company_claimant,status,creator,histories,rcas,claim_analysis,cost_breakdown,loss_ratio,rca_final,insurers,classification_of_loss,monitorings,recommendations,evaluations,erm,summary,department',
        },
      },
    }
  },
  methods: {
    ...miActions,
    ...crewingActions,
    async conditionalMI() {
      if (this.$route.query.formId) {
        const { data } = await this.fetchDetailMi({
          id: this.$route.query.formId,
          with: this.incidents.detailParams.withDetail,
        })

        // this.type_form = data.type_form

        this.detailMi = data

        this.steps = this.steps.map((val) => {
          if (val.key === 'CLAIM_ANALYSIS')
            val.isDataExist = data.claim_analysis ? true : false
          if (val.key === 'COST_BREAKDOWN')
            val.isDataExist = data.cost_breakdown ? true : false
          if (val.key === 'LOST_RATIO')
            val.isDataExist = data.loss_ratio.length > 0 ? true : false
          if (val.key === 'MANAGEMENT_APPROVAL')
            val.isDataExist =
              data.creator_fullname &&
              data.creator_date &&
              data.approver_fullname &&
              data.approver_date
                ? true
                : false
          if (val.key === 'LIST_RCA')
            val.isDataExist = data.rcas.length > 0 ? true : false
          if (val.key === 'RCA_FINAL')
            val.isDataExist = data.rca_final ? true : false
          if (val.key === 'MONITORING')
            val.isDataExist = data.monitorings.length > 0 ? true : false
          if (val.key === 'RECOMMENDATION')
            val.isDataExist = data.recommendations.length > 0 ? true : false
          if (val.key === 'EVALUATION')
            val.isDataExist = data.evaluations.length > 0 ? true : false
          if (val.key === 'ERM') val.isDataExist = data.erm ? true : false
          if (val.key === 'MANAGEMENT_SUMMARY')
            val.isDataExist = data.summary ? true : false

          return val
        })

        if (data.type_form !== 'claim') {
          let keyToKeep = ['RECOMMENDATION', 'EVALUATION', 'ERM']

          const filteredStep = this.steps.filter((item) =>
            keyToKeep.includes(item.key)
          )

          this.steps = filteredStep
        }
      }
    },
    handleOpenModalEmail(title, formId) {
      this.emailTitle = title
      this.formId = formId
      this.$nextTick(() => {
        this.$bvModal.show('send-email-modal')
      })
    },
    async sendEmailToUser() {
      let userId = []
      let approvalId = []
      this.notifMailUser.map((obj) => {
        userId.push(obj.id)
      })
      this.approvalMailUser.map((obj) => {
        approvalId.push(obj.id)
      })

      if (userId.length <= 0)
        return this.$swal(`Oops!`, 'Select user first.', 'warning')

      let payload = {
        user_id: userId,
        approval_id: this.emailTitle == 'Accident' ? approvalId : [],
      }

      this.$swal({
        title: `Apakah anda yakin ingin mengirimkan email ${this.emailTitle} ?`,
        text: 'Ya, Saya yakin.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = null
          if (this.emailTitle == 'Evaluation') {
            res = await this.sendEmailEvaluation({
              data: payload,
              id: Number(this.formId),
            })
          } else if (this.emailTitle == 'Accident') {
            res = await this.sendEmailAccident({
              data: payload,
              id: Number(this.formId),
            })
          } else if (this.emailTitle == 'Recommendation') {
            res = await this.sendEmailRecommendation({
              data: payload,
              id: Number(this.formId),
            })
          }

          if (res.status === 'success') {
            this.$nextTick(() => {
              this.$bvModal.hide('send-email-modal')
            })
            this.notifMailUser = []
            this.approvalMailUser = []
            this.$swal(
              `Oops!`,
              `Email ${this.emailTitle} sended to user.`,
              'success'
            )
          } else {
            this.$swal(`Oops!`, res?.data, 'error')
          }
        }
      })
    },
    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 10000,
      }

      try {
        const { status, data } = await this.fetchUserExpert(params)
        data.map((state) => {
          this.optionsExpertUsers.push({
            id: state.id,
            name: state.userDetail?.name,
            selected: false,
          })
          this.filteredExpertUsers = this.optionsExpertUsers
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  async mounted() {
    await this.conditionalMI()
    await this.fetchDataUserExpert()
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        this.conditionalMI()
      },
      immediate: true,
    },
  },
}
</script>
<style scoped>
.caret-animation {
  transition: all 250ms;
}

.rotate-90 {
  rotate: 90deg;
}

.rotate-0 {
  rotate: 0deg;
}
</style>

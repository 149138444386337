<!-- CurrencyInput.vue -->
<template>
  <div>
    <input
      class="input-class w-100 text-right"
      type="text"
      :placeholder="placeholder"
      :value="formattedValue"
      :disabled="disabled"
      @input="onInput"
      @keypress="isNumber($event)"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    placeholder:'',
    disabled:Boolean
  },
  data() {
    return {
      internalValue: String(this.value),
    }
  },
  computed: {
    formattedValue() {
      // Format angka dengan pemisah ribuan
      return this.formatNumber(this.internalValue)
    },
  },
  methods: {
    formatNumber(value) {
      if (!value) return ''
      // Menghapus non-digit dan menambahkan pemisah ribuan
      return parseInt(value.replace(/\D/g, '')).toLocaleString()
    },
    onInput(event) {
      const rawValue = event.target.value.replace(/\D/g, '') // Hanya angka
      this.internalValue = rawValue
      // Emit nilai asli (tanpa pemisah ribuan) ke parent
      this.$emit('input', Number(rawValue))
    },
    isNumber(event) {
      // Memastikan hanya angka yang dapat diinput
      const char = String.fromCharCode(event.keyCode)
      if (!/[0-9]/.test(char)) {
        event.preventDefault()
      }
    },
    onBlur() {
      // Format saat kehilangan fokus
      this.internalValue = this.formatNumber(this.internalValue)
    },
    onFocus() {
      // Hapus format saat fokus agar pengguna bisa langsung mengetik
      this.internalValue = this.internalValue.replace(/\D/g, '')
    },
  },
  watch: {
    value(newValue) {
      // Update nilai internal jika prop `value` berubah dari parent
      this.internalValue = String(newValue)
    },
  },
}
</script>
<style scoped>
.input-class {
  border: 1px solid#d7dbda;
  border-radius: 10px;
  padding: 4px 8px;
}

.input-class:focus {
  border: 1px solid #334697;
}
</style>

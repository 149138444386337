<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row v-if="!isChildComponent">
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#fleet_certificate"
                  title="FLEET CERTIFICATE"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#master_certificate"
                  title="MASTER CERTIFICATE"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <!-- Fleet Certificate -->
      <tab-content-item :active="true" id="fleet_certificate">
        <!-- Modal Filter !-->
          <b-modal v-model="modal.filter" centered size="lg" title="Filter Data" ok-title="Filter" @ok="fetchCertificates">
            <b-row>
              <b-col md="12" v-if="!fleetParamsAvailable">
                <b-form-group
                  label="Fleet/Equipment"
                  label-for="kind-fleet"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  class="mb-3"
                >
                  <v-select
                    v-model="certificate_params.vehicle"
                    label="name"
                    :options="fleets"
                    :reduce="(fleet) => fleet.id"
                    :disabled="vehicleIdParam !== ''"
                  ></v-select>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Periode Certificate:"
                  label-for="typeCertificate"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  class="mb-3"
                >
                  <b-form-select
                    v-model="certificate_params.type"
                    :options="remarks_filter"
                    value-field="value"
                    text-field="label"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Category"
                  label-for="kind-fleet"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  class="mb-3"
                >
                  <v-multiselect
                    v-model="certificate_params.certificateCategoryIds"
                    :options="certificate_categories"
                    :multiple="true"
                    group-values="subCategory"
                    group-label="name"
                    :group-select="true"
                    placeholder=""
                    track-by="id"
                    label="name"
                  >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                  </v-multiselect>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Status Certificate:"
                  label-for="statusCertificate"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  class="mb-3"
                >
                  <b-form-select
                    @change="onStatusCertificateChange"
                    v-model="certificate_params.status"
                    plain
                    :options="certificate_statuses"
                    value-field="value"
                    text-field="text"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>
        <!-- End Top Section !-->
        <!-- Content Section !-->
        <b-row>
          <b-col md="12">
            <iq-card>
              <template #headerTitle>
                <h4 class="text-primary">
                  <strong>
                    Fleet Certificate
                  </strong>
                </h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_certificates" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <!-- <b-form-input size="lg" placeholder="search..." v-model="master_certificate_params.search"></b-form-input>
                        <b-button variant="primary" class="mx-2" @click="fetchMasterCertificates">
                          <i class="fa fa-search"></i>
                        </b-button> -->
                        <b-button
                          variant="outline-primary"
                          class="d-flex align-items-center"
                          @click="toggleModalFilter"
                        >
                          <i class="fas fa-sliders"></i>
                          <p class="text-truncate m-0"> Filter Data</p>
                        </b-button>
                      </div>
                      <div class="d-flex">
                        <div class="d-flex  align-items-center">
                          <strong>Display : &nbsp;</strong>
                          <div class="btn-group">
                            <button
                              type="button"
                              @click="view_style = 'list'"
                              class="btn btn-outline-primary"
                              :class="{ active: view_style === 'list' }"
                            ><span class="fa fa-list"></span> List
                            </button
                            >
                            <button
                              type="button"
                              @click="view_style = 'grid'"
                              class="btn btn-outline-primary"
                              :class="{ active: view_style === 'grid' }"
                            ><span class="fa fa-th-large"></span> Grid
                            </button
                            >
                            <button
                              type="button"
                              @click="view_style = 'calendar'"
                              class="btn btn-outline-primary"
                              :class="{ active: view_style === 'calendar' }"
                            ><span class="fa fa-calendar"></span>
                              Calendar
                            </button
                            >
                          </div>
                        </div>
                        <div style="border-left: 1.5px solid" class="mx-3"></div>
                        <div class="d-flex align-items-center">
                          <b-button
                            v-if="menuPermission('export') || lsProfile.menu[1].child[0].child[0].export == true"
                            variant="primary"
                            class="mr-1"
                            @click="doExportCertificateVehicles"
                          >
                            <template v-if="!loading_export_certificates">
                              <i class="fa fa-file-excel"></i> &nbsp; Export
                            </template>
                            <template v-else>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Exporting...
                            </template>
                          </b-button>
                          <b-button
                            v-if="((!vehicleIdParam || fleetParamsAvailable) && (menuPermission('add') || lsProfile.menu[1].child[0].child[0].add == true)) && !isChildComponent"
                            variant="success"
                            class="mr-1"
                            @click="onAddCertificateVehicle"
                          ><i class="fa fa-plus"></i>&nbsp;Add
                            Certificate
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <div class="px-3 mt-3">
                      <b-row class="pt-3 border">
                        <b-col md="3" v-if="certificate_params.vehicle">
                          <b-form-group label="Fleet/Equipment" >
                            <p class="m-0 text-black">{{ fleets.find((el)=>el.id ==certificate_params.vehicle ).name }}</p>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label="Periode Certificate:">
                            <p class="m-0 text-black">{{ remarks_filter.find((el)=>el.value ==certificate_params.type ).label }}</p>
                          </b-form-group>
                        </b-col>
                        <b-col md="3" v-if="certificate_params.certificateCategoryIds.length">
                          <b-form-group label="Category">
                          <b-badge variant="success" v-for="(item, index) in certificate_params.certificateCategoryIds" :key="index" class="mr-1" >{{ item.name }}</b-badge>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Status Certificate:">
                          <p class="m-0 text-black">{{ certificate_statuses.find((el)=>el.value == certificate_params.status ).text }}</p>
                        </b-form-group>
                      </b-col>
                      </b-row>
                    </div>
                  <template v-if="certificates.length > 0">
                    <!-- Grid View -->
                    <b-row v-if="view_style == 'grid'">
                      <b-col
                        sm="12"
                        md="3"
                        v-for="cert in certificates"
                        :key="cert.code + cert.id"
                      >
                        <b-card class="iq-mb-3">
                          <b-card-body>
                            <div class="doc-profile text-center">
                              <img
                                class="img-fluid avatar-80 button-pointer"
                                v-b-tooltip.hover
                                title="View Details"
                                @click="toggleModalDetail(true, cert)"
                                :src="
                              require('@assets/images/flaticon/certificate.png')
                            "
                                alt="profile"
                              />
                            </div>
                            <div class="iq-doc-info mt-3 text-center">
                              <h4
                                class="text-default button-pointer"
                                v-b-tooltip.hover
                                title="View Details"
                                @click="toggleModalDetail(true, cert)"
                              >
                                {{ cert.certificate.name }}</h4
                              >
                              <template
                                v-if="
                              cert.certificate.certificateType == 'FULL_TERM' &&
                              ($options.filters.getDateDifferenceInMonthWithFormat(cert.expired, cert.dateIssued) <= 12)
                            "
                              >
                                <br/>
                                <b-badge pill variant="secondary" class="mr-1"
                                >INTERM
                                </b-badge
                                >
                              </template>
                              <h6
                                class="text-primary button-pointer"
                                v-b-tooltip.hover
                                title="View Details"
                                @click="toggleModalDetail(true, cert)"
                              >
                                {{ cert.vehicle.name }}
                              </h6>
                              <p class="mb-0"
                              ><small>Place Issued:</small>
                                {{ cert.placeIssued }}
                              </p>
                              <p class="mb-0"
                              ><small>Date Issued:</small>
                                {{ cert.dateIssued }}
                              </p>
                              <p class="mb-0" v-if="cert.expired"
                              ><small>Expired: </small>{{ cert.expired }}</p
                              >
                              <p v-if="cert.endorsement.length > 0"
                              ><small>Next Endorsement: </small
                              >{{
                                  findEndorsement(
                                    cert.endorsement,
                                    cert.lastEndorsementNumber
                                  )
                                }}</p
                              >
                            </div>

                            <div class="iq-doc-description mt-2 text-center">
                              <p class="mb-1">
                                <template
                                  v-if="
                                cert.certificate.certificateType === 'PERMANENT'
                              "
                                >
                                  <b-badge variant="dark" class="mr-1"
                                  >Permanent
                                  </b-badge
                                  >
                                </template>
                                <template
                                  v-else-if="
                                cert.certificate.certificateType ===
                                'SHORT_TERM'
                              "
                                >
                                  <b-badge variant="secondary" class="mr-1"
                                  >Short Term
                                  </b-badge
                                  >
                                </template>
                                <template
                                  v-else-if="
                                cert.certificate.certificateType === 'FULL_TERM'
                              "
                                >
                                  <b-badge variant="light" class="mr-1"
                                  >Full Term
                                  </b-badge
                                  >
                                </template>
                                <template v-else>
                                  <b-badge variant="primary" class="mr-1"
                                  >Provisional
                                  </b-badge
                                  >
                                </template>
                              </p>
                              <div class="mb-1">
                            <span
                              :style="`background-color: ${cert.status.color}; color: white`"
                              class="badge badge-pill text-capitalize">{{ cert.status.text }}</span>
                              </div>
                            </div>
                            <b-row class="mt-3 text-center">
                              <b-col cols="12">
                                <b-button-group class="mr-2">
                                  <b-button
                                    v-if="(menuPermission('update') || lsProfile.menu[1].child[0].child[0].update == true)  && !isChildComponent"
                                    v-b-tooltip.hover
                                    title="Edit Certificate"
                                    @click="onEditCertificate(cert)"
                                    variant="primary"
                                  ><i class="fa fa-edit"></i
                                  ></b-button>

                                  <b-button
                                    variant="dark"
                                    v-if="cert.file && cert.file.url && (menuPermission('export') || lsProfile.menu[1].child[0].child[0].export == true)"
                                    v-b-tooltip.hover
                                    title="Download Certificate"
                                    @click="downloadCertificate(cert.file.url)"
                                  ><i class="fa fa-download"></i
                                  ></b-button>

                                  <b-button
                                    v-if="(menuPermission('delete') || lsProfile.menu[1].child[0].child[0].delete == true)  && !isChildComponent"
                                    v-b-tooltip.hover
                                    title="Delete Certificate"
                                    variant="danger"
                                    @click.prevent="onDeleteCertificate(cert)"
                                  ><i class="fa fa-trash"></i
                                  ></b-button>

                                  <template
                                    v-if="
                                  !['PROVISIONAL'].includes(
                                    cert.certificate.certificateType
                                  )
                                "
                                  >
                                    <b-dropdown variant="primary"
                                                v-if="
                                    cert.certificate.certificateType === 'PERMANENT'
                                    && cert.status.showEndorsement
                                    && ((menuPermission('update') && menuPermission('add'))  || (lsProfile.menu[1].child[0].child[0].add == true && lsProfile.menu[1].child[0].child[0].update == true))
                                  ">
                                      <template slot="button-content">
                                        Action
                                        <i class="uil uil-angle-down"></i>
                                      </template>
                                      <template>
                                        <b-dropdown-item
                                          @click.prevent="toggleModalEndorsement(true, cert)"
                                          href="#"
                                        >Proses Endorsement
                                        </b-dropdown-item
                                        >
                                      </template>
                                    </b-dropdown>
                                    <b-dropdown variant="primary" v-else>
                                      <template slot="button-content">
                                        Action
                                        <i class="uil uil-angle-down"></i>
                                      </template>
                                      <b-dropdown-item
                                        v-if="cert.status.showEndorsement
                                      && ((menuPermission('update') && menuPermission('add'))  || (lsProfile.menu[1].child[0].child[0].add == true && lsProfile.menu[1].child[0].child[0].update == true))"
                                        @click.prevent="toggleModalEndorsement(true, cert)"
                                        href="#"
                                      >Proses Endorsement
                                      </b-dropdown-item
                                      >
                                      <b-dropdown-item
                                        v-if="cert.status.showRenewal
                                      && ((menuPermission('update') && menuPermission('add'))  || (lsProfile.menu[1].child[0].child[0].add == true && lsProfile.menu[1].child[0].child[0].update == true))"
                                        @click="toggleModalRenewal(true, cert)"
                                        href="#"
                                      >Proses Perpanjang
                                      </b-dropdown-item
                                      >

                                      <b-dropdown-item
                                        v-if="cert.status.showLog"
                                        @click.prevent="toggleModalLog(true, cert)"
                                        href="#"
                                      >Log History
                                      </b-dropdown-item
                                      >
                                    </b-dropdown>
                                  </template>
                                </b-button-group>
                              </b-col>
                            </b-row>
                          </b-card-body>
                        </b-card>
                      </b-col>
                    </b-row>

                    <!-- List View -->
                    <table
                      class="table table-striped mt-4"
                      v-else-if="view_style == 'list'"
                    >
                      <thead>
                      <tr>
                        <th style="width: 30px">No</th>
                        <th>Fleet</th>
                        <th>Certificate</th>
                        <th v-if="fleetParamsAvailable" style="width: 120px">Issued</th>
                        <th style="width: 120px">Expired</th>
                        <th>Next Endorse</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="(cert, index) in certificates"
                        :key="cert.code + cert.id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ cert.vehicle.name }}
                          <template v-if="cert.vehicleOnDocking">
                            <b-badge variant="warning" class="mr-1"
                            >Docking
                            </b-badge
                            >
                          </template>
                        </td>
                        <td
                        ><span
                          class="text-uppercase button-pointer text-primary"
                          v-b-tooltip.hover
                          title="View Details"
                          @click="toggleModalDetail(true, cert)"
                        >{{ cert.certificate.name }}</span
                        >
                          <br/>
                          <b-badge v-if="cert.certificate.certificateType === 'PROVISIONAL'" variant="secondary" class="mr-1">
                            PERMANENT (NO ENDORS)
                          </b-badge>
                          <b-badge v-else variant="secondary" class="mr-1">
                            {{ cert.certificate.certificateType.replace('_', ' ') }}
                          </b-badge>
                          <template
                            v-if="
                            cert.certificate.certificateType == 'FULL_TERM' &&
                            ($options.filters.getDateDifferenceInMonthWithFormat(cert.expired, cert.dateIssued) <= 12)
                          "
                          >
                            <b-badge pill variant="secondary" class="mr-1"
                            >INTERM
                            </b-badge
                            >
                          </template>
                        </td>
                        <td v-if="fleetParamsAvailable">{{ cert.dateIssued ? cert.dateIssued : '-' }}</td>
                        <td>{{ cert.expired ? cert.expired : '-' }}</td>
                        <td
                        >{{
                            cert.endorsement.length > 0
                              ? findEndorsement(
                                cert.endorsement,
                                cert.lastEndorsementNumber
                              )
                              : '-'
                          }}
                        </td>
                        <td>
                        <span
                          :style="`background-color: ${cert.status.color}; color: white`"
                          class="badge badge-pill text-capitalize">{{ cert.status.text }}</span>
                        </td>
                        <td>
                          <b-button-group class="mr-2">
                            <b-button
                              v-if="(menuPermission('update') || lsProfile.menu[1].child[0].child[0].update == true)  && !isChildComponent"
                              v-b-tooltip.hover
                              title="Edit Certificate"
                              @click="onEditCertificate(cert)"
                              variant="primary"
                            ><i class="fa fa-edit"></i
                            ></b-button>

                            <b-button
                              variant="dark"
                              v-if="cert.file && cert.file.url && (menuPermission('export') || lsProfile.menu[1].child[0].child[0].export == true)"
                              v-b-tooltip.hover
                              title="Download Certificate"
                              @click="downloadCertificate(cert.file.url)"
                            ><i class="fa fa-download"></i
                            ></b-button>

                            <b-button
                              v-if="(menuPermission('delete') || lsProfile.menu[1].child[0].child[0].delete == true) && !isChildComponent"
                              v-b-tooltip.hover
                              title="Delete Certificate"
                              variant="danger"
                              @click.prevent="onDeleteCertificate(cert)"
                            ><i class="fa fa-trash"></i
                            ></b-button>

                            <template
                              v-if="
                              !['PROVISIONAL'].includes(
                                cert.certificate.certificateType
                              ) && !isChildComponent
                            "
                            >
                              <b-dropdown variant="primary"
                                          v-if="
                                cert.certificate.certificateType === 'PERMANENT'
                                && cert.status.showEndorsement
                                && ((menuPermission('update') && menuPermission('add')) || (lsProfile.menu[1].child[0].child[0].update == true && lsProfile.menu[1].child[0].child[0].add == true))
                              ">
                                <template slot="button-content">
                                  Action
                                  <i class="uil uil-angle-down"></i>
                                </template>
                                <template>
                                  <b-dropdown-item
                                    @click.prevent="toggleModalEndorsement(true, cert)"
                                    href="#"
                                  >Proses Endorsement
                                  </b-dropdown-item
                                  >
                                </template>
                              </b-dropdown>
                              <b-dropdown variant="primary" v-else>
                                <template slot="button-content">
                                  Action
                                  <i class="uil uil-angle-down"></i>
                                </template>
                                <b-dropdown-item
                                  v-if="cert.status.showEndorsement
                                  && ((menuPermission('update') && menuPermission('add')) || (lsProfile.menu[1].child[0].child[0].update == true && lsProfile.menu[1].child[0].child[0].add == true))"
                                  @click.prevent="toggleModalEndorsement(true, cert)"
                                  href="#"
                                >Proses Endorsement
                                </b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="cert.status.showRenewal
                                && ((menuPermission('update') && menuPermission('add')) || (lsProfile.menu[1].child[0].child[0].update == true && lsProfile.menu[1].child[0].child[0].add == true))"
                                  @click="toggleModalRenewal(true, cert)"
                                  href="#"
                                >Proses Perpanjang
                                </b-dropdown-item
                                >

                                <b-dropdown-item
                                  v-if="cert.status.showLog"
                                  @click.prevent="toggleModalLog(true, cert)"
                                  href="#"
                                >Log History
                                </b-dropdown-item
                                >
                              </b-dropdown>
                            </template>
                          </b-button-group>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <!-- Calendar view -->
                    <v-calendar
                      class="custom-calendar max-w-full mb-2 mt-4"
                      :masks="masks"
                      :attributes="attributes"
                      disable-page-swipe
                      is-expanded
                      v-else
                    >
                      <template v-slot:day-content="{ day, attributes }">
                        <div
                          class="
                            flex flex-col
                            h-full
                            z-10
                            overflow-hidden
                            mb-4
                            mt-2
                            text-right
                          "
                        >
                          <span class="day-label text-sm text-gray-900">{{
                              day.day
                            }}</span>
                          <div
                            class="flex-grow overflow-y-auto overflow-x-auto"
                          >
                            <p
                              v-for="attr in attributes"
                              :key="attr.key"
                              class="
                                text-xs
                                leading-tight
                                rounded-sm
                                p-1
                                mt-0
                                mb-1
                                text-justify
                              "
                              :class="attr.customData.class"
                            >
                              <strong>{{ attr.customData.vehicleName }}</strong>
                              <br/>
                              <span v-if="attr.customData.title.length > 25"
                                    v-b-tooltip.hover
                                    :title="attr.customData.title">
                                {{ attr.customData.title.substring(0, 25).concat('...') }}
                              </span>
                              <span v-else>
                                {{ attr.customData.title }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </template>
                    </v-calendar>

                    <!-- pagination -->
                    <template>
                      <hr />
                      <b-row>
                        <b-col col-6>
                          Total: {{ pagination.total }} &nbsp; | &nbsp; Per
                          page:
                          {{ pagination.perPage }}
                        </b-col>
                        <b-col col-6>
                          <b-pagination
                            v-model="certificate_params.page"
                            :total-rows="pagination.total"
                            :per-page="pagination.perPage"
                            align="right"
                            @input="fetchCertificates"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                      
                    </template>
                  </template>

                  <p v-else class="text-center my-4"
                  >No certificates data found.
                    <a
                      v-if="(!vehicleIdParam || fleetParamsAvailable) && (menuPermission('add') || lsProfile.menu[1].child[0].child[0].add == true)"
                      href="#" @click="onAddCertificateVehicle">Add New</a>
                  </p>
                </template>

                <!-- MODAL ADD CERTIFICATE -->
                <b-modal
                  v-model="show_add_modal"
                  size="xl"
                  :title="`Add Certificate ${fleetParamsAvailable && fleetFromParam ? `for ${fleetFromParam.name}` : ''}`"
                  no-close-on-backdrop
                  no-close-on-esc
                  :hide-footer="true"
                >
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Details:</legend>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="Name of Certificate*" label-for="category">
                          <v-select
                            label="name"
                            :options="masterAllCertificates"
                            v-model="form.certificate.certificate"
                            :clearable="false"
                          >
                            <template v-slot:option="option">
                              <strong>{{option.certificateType === 'PROVISIONAL' ? 'PERMANENT (NO ENDORS)' : option.certificateType.replace('_', ' ')}}</strong>
                              - {{ option.name }}
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col :md="fleetParamsAvailable ? 3 : 6">
                        <b-form-group label="Type" label-for="type">
                          <b-form-input
                            id="type"
                            type="text"
                            disabled
                            :value="
                          form.certificate.certificate.certificateType
                            ? ( form.certificate.certificate.certificateType === 'PROVISIONAL' ? 'PERMANENT (NO ENDORS)'  :
                              form.certificate.certificate.certificateType.replace(
                                '_',
                                ' '
                              )
                            )
                            : '-'
                        "
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col :md="fleetParamsAvailable ? 3 : 6">
                        <b-form-group label="Category" label-for="category">
                          <b-form-input
                            id="type"
                            type="text"
                            disabled
                            :value="
                          form.certificate.certificate.certificateCategory
                            ? form.certificate.certificate.certificateCategory
                                .name
                            : '-'
                        "
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" v-if="!fleetParamsAvailable">
                        <b-form-group label="Fleet*" label-for="fleet">
                          <v-select
                            label="name"
                            :options="fleets"
                            :reduce="(fleet) => fleet.id"
                            v-model="form.certificate.vehicleId"
                            @input="fetchFleetUsers"
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="certificateFile"
                          label="File Certificate*"
                        >
                          <b-form-file
                            id="customFile"
                            placeholder="Choose a file"
                            accept="application/pdf"
                            :disabled="loading_upload_file"
                            @change="uploadFile"
                          ></b-form-file>
                          <p class="mt-2 text-danger" v-if="loading_upload_file"
                          >Please wait, uploading file is in process...</p
                          >
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label-for="placeIssued" label="Place Issued*">
                          <b-form-input
                            id="place_issued"
                            type="text"
                            placeholder="Place Issued"
                            v-model="form.certificate.placeIssued"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label-for="issued_by" label="Issued By*">
                          <b-form-select
                            v-if="form.certificate.certificate.certificateCategory"
                            :disabled="
                          !form.certificate.certificate.certificateCategory ||
                          !form.certificate.certificate.certificateCategory
                            .subCategory.length > 0
                        "
                            v-model="form.certificate.issuedBy"
                            :options="
                          form.certificate.certificate.certificateCategory
                            .subCategory
                        "
                            value-field="id"
                            text-field="name"
                          />
                          <span v-else>-</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Validity:</legend>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label-for="dateIssued" label="Date Issued*">
                          <b-form-input
                            type="date"
                            v-model="form.certificate.dateIssued"
                          ></b-form-input>
                          <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show</small
                          >
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        v-if="
                      form.certificate.certificate &&
                      form.certificate.certificate.certificateType !=='PERMANENT' &&
                      form.certificate.certificate.certificateType !=='PROVISIONAL'
                    "
                      >
                        <b-form-group label-for="expired" label="Valid Until*">
                          <b-form-input
                            type="date"
                            v-model="form.certificate.expiredDate"
                            @change="checkFulltermInterm"
                          ></b-form-input>
                          <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show</small
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset
                    class="form-group border p-3"
                    v-if="form?.certificate?.certificate?.certificateType === 'PERMANENT'"
                  >
                    <legend class="w-auto px-2">Endorsement:</legend>

                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-for="notification"
                          label="Next Endorsement*"
                        >
                          <b-input-group size="md" append="Month">
                            <b-form-input
                              type="number"
                              min="1"
                              v-model="form.certificate.nextEndorsement"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="notification"
                          label="Notification Endorsement Before*"
                        >
                          <b-input-group size="md" append="Month">
                            <b-form-input
                              type="number"
                              min="1"
                              v-model="form.certificate.notificationEndorsement"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>

                  <fieldset
                    class="form-group border p-3"
                    v-if="
                    form.certificate.certificate &&
                    form.certificate.certificate.certificateType === 'FULL_TERM'
                    && isFulltermInterm"
                  >
                    <legend class="w-auto px-2">Endorsement:</legend>

                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-for="notification"
                          label="Next Endorsement*"
                        >
                          <b-input-group size="md" append="Month">
                            <b-form-input
                              type="number"
                              min="1"
                              v-model="form.certificate.nextEndorsement"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="notification"
                          label="Notification Endorsement Before*"
                        >
                          <b-input-group size="md" append="Month">
                            <b-form-input
                              type="number"
                              min="1"
                              v-model="form.certificate.notificationEndorsement"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col
                        md="6"
                        v-if="isFulltermInterm"
                      >
                        <b-form-group
                          label="Type of Endorsement*"
                          label-for="Type of Endorsement"
                        >
                          <v-select
                            label="text"
                            :options="endorsement_types"
                            :reduce="(type) => type.value"
                            v-model="form.certificate.endorsementType"
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        v-if="
                      form.certificate.certificate &&
                      form.certificate.certificate.certificateType ===
                        'FULL_TERM' &&
                      !isCertificateInterm()
                      && form.certificate.endorsementType === 'VERIFIED'
                    "
                      >
                        <b-form-group
                          label-for="DateVerified"
                          label="Date Verified"
                        >
                          <b-form-input
                            type="date"
                            v-model="form.certificate.dateVerified"
                          ></b-form-input>
                          <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show</small
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div
                      v-if="form.certificate.endorsementType === 'MANUAL'"
                      class="mt-4"
                    >
                      <b-row
                        v-for="(end, end_i) in form.certificate
                      .endorsementDocuments"
                        :key="end_i"
                        class="mt-2"
                      >
                        <b-col md="6"> Endorsement #{{ end.endorseNumber }}</b-col>
                        <b-col md="6">
                          <date-picker
                            v-model="end.rangeEndorsement"
                            :lang="lang"
                            type="date"
                            range
                            :placeholder="`Select range endorsement ${end.endorseNumber}`"
                          ></date-picker>
                          <b-button
                            variant="outline-danger"
                            class="ml-2"
                            v-if="end_i !== 0"
                            v-b-tooltip.hover
                            :title="`Delete endorsement ${end.endorseNumber}`"
                            @click="deleteEndorsementDocument(end_i)"
                          >
                            <i class="fa fa-times"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-col md="12" class="mt-4">
                        <b-button
                          block
                          variant="warning"
                          @click="addEndorsementDocument"
                        >Add Endorsement Based on Document
                        </b-button
                        >
                      </b-col>
                    </div>
                  </fieldset>

                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Remarks:</legend>
                    <b-row>
                      <b-col md="3">
                        <label>Office Checked</label>
                      </b-col>
                      <b-col md="9">
                        <b-form-checkbox
                          v-model="form.certificate.officeChecked"
                          name="check-button"
                          switch
                          inline
                        >
                          {{ form.certificate.officeChecked ? 'Yes' : 'No' }}
                        </b-form-checkbox>
                        <b-form-textarea
                          v-if="form.certificate.officeChecked"
                          v-model="form.certificate.officeRemark"
                          id="description"
                          rows="3"
                          placeholder="Type Remark"
                        ></b-form-textarea>
                      </b-col>
                      <b-col md="3" class="mt-3">
                        <label>Ship Checked</label>
                      </b-col>
                      <b-col md="9" class="mt-3">
                        <b-form-checkbox
                          v-model="form.certificate.shipChecked"
                          name="check-button"
                          switch
                          inline
                        >
                          {{ form.certificate.shipChecked ? 'Yes' : 'No' }}
                        </b-form-checkbox>
                        <b-form-textarea
                          v-if="form.certificate.shipChecked"
                          v-model="form.certificate.shipRemark"
                          id="description"
                          rows="3"
                          placeholder="Type Remark"
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset
                    class="form-group border p-3"
                    v-if="
                  form.certificate.certificate &&
                  !['PROVISIONAL'].includes(
                    form.certificate.certificate.certificateType
                  )
                "
                  >
                    <legend class="w-auto px-2">Notification Receiver:</legend>
                    <b-form-group
                      label="Receiver* "
                      label-for="category_for"
                      v-if="users && users.length > 0"
                    >
                      <v-multiselect
                        v-model="form.certificate.user"
                        placeholder="Select receivers"
                        label="name"
                        track-by="id"
                        :options="users"
                        :multiple="true"
                      >
                        <span slot="noResult">Category not found.</span>
                      </v-multiselect>
                    </b-form-group>
                    <p v-else class="text-center my-3 text-muted">{{
                        form.certificate.vehicleId
                          ? 'Users not found on selected fleet.'
                          : 'Please select fleet first.'
                      }}</p>
                  </fieldset>
                  <b-form-group class="text-right">
                    <b-button
                      variant="outline-danger"
                      class="mr-2"
                      @click="show_add_modal = !show_add_modal"
                    >Cancel
                    </b-button
                    >
                    <b-button
                      variant="primary"
                      :disabled="loading_upload_file"
                      @click="doAddCertificate"
                    >
                      <span v-if="!loading_add_certificates">Save</span>
                      <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                        Saving...
                      </template>
                    </b-button>
                  </b-form-group>
                </b-modal>

                <!-- MODAL EDIT CERTIFICARE -->
                <b-modal
                  v-model="show_edit_modal"
                  size="xl"
                  no-close-on-backdrop
                  no-close-on-esc
                  :title="`Edit Certificate ${form.certificate.vehicleId && fleets.find(fleet => fleet.id === form.certificate.vehicleId)? fleets.find(fleet => fleet.id === form.certificate.vehicleId).name : ''}`"
                  :hide-footer="true"
                >
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Details:</legend>
                    <b-row>
<!--                      <b-col md="6">-->
<!--                        <b-form-group-->
<!--                          label="Nomor Register:"-->
<!--                          label-for="serial"-->
<!--                        >-->
<!--                          <b-form-input-->
<!--                            id="serial"-->
<!--                            type="text"-->
<!--                            placeholder="Nomor Register"-->
<!--                            v-model="form.certificate.serialNumber"-->
<!--                          ></b-form-input>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
                      <b-col md="6">
                        <b-form-group
                          label="Name of Certificate:"
                          label-for="category"
                        >
                          <v-select
                            label="name"
                            :options="masterAllCertificates"
                            v-model="form.certificate.certificate"
                            disabled
                          >
                            <template v-slot:option="option">
                              <strong>{{
                                  option.certificateType.replace('_', ' ')
                                }}</strong>
                              - {{ option.name }}
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Type" label-for="type">
                          <b-form-input
                            id="type"
                            type="text"
                            disabled
                            :value="
                          form.certificate.certificate.certificateType
                            ? ( form.certificate.certificate.certificateType === 'PROVISIONAL' ? 'PERMANENT (NO ENDORS)'  :
                              form.certificate.certificate.certificateType.replace(
                                '_',
                                ' '
                              )
                            )
                            : '-'
                        "
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Category" label-for="category">
                          <b-form-input
                            id="type"
                            type="text"
                            disabled
                            :value="
                              form.certificate.certificate.certificateCategory
                                ? form.certificate.certificate.certificateCategory
                                    .name
                                : '-'
                            "
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
<!--                      <b-col md="6">-->
<!--                        <b-form-group label="Fleet:" label-for="fleet">-->
<!--                          <v-select-->
<!--                            label="name"-->
<!--                            :options="fleets"-->
<!--                            :reduce="(fleet) => fleet.id"-->
<!--                            v-model="form.certificate.vehicleId"-->
<!--                            disabled-->
<!--                          ></v-select>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
                      <b-col md="6">
                        <b-form-group
                          label-for="certificateFile"
                          label="File Certificate *"
                        >
                          <b-form-file
                            id="customFile"
                            placeholder="Choose a file"
                            accept="application/pdf"
                            :disabled="loading_upload_file"
                            @change="uploadFile"
                          ></b-form-file>
                          <p class="mt-2 text-danger" v-if="loading_upload_file"
                          >Please wait, uploading file is in process...</p
                          >
                          <p class="mt-2" v-if="form.certificate.file">
                            <a :href="form.certificate.file.url" target="_blank">{{
                                form.certificate.file.fileName
                              }}</a>
                          </p>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label-for="placeIssued" label="Place Issued">
                          <b-form-input
                            id="place_issued"
                            type="text"
                            placeholder="Place Issued"
                            v-model="form.certificate.placeIssued"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label-for="issued_by" label="Issued By">
                          <b-form-select
                            v-if="form.certificate.certificate.certificateCategory"
                            v-model="form.certificate.issuedBy"
                            :options="
                              form.certificate.certificate.certificateCategory
                                .subCategory
                            "
                            value-field="id"
                            text-field="name"
                          />
                          <span v-else>-</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Validity:</legend>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label-for="dateIssued" label="Date Issued">
                          <b-form-input
                            type="date"
                            v-model="form.certificate.dateIssued"
                          ></b-form-input>
                          <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show</small
                          >
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        v-if="
                          form.certificate.certificate &&
                            form.certificate.certificate.certificateType !=='PERMANENT' &&
                            form.certificate.certificate.certificateType !=='PROVISIONAL'
                        "
                      >
                        <b-form-group label-for="expired" label="Valid Until">
                          <b-form-input
                            type="date"
                            v-model="form.certificate.expiredDate"
                          ></b-form-input>
                          <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show</small
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>

                  <fieldset
                    class="form-group border p-3"
                    v-if="form?.certificate?.certificate?.certificateType === 'PERMANENT'"
                  >
                    <legend class="w-auto px-2">Endorsement:</legend>

                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-for="notification"
                          label="Next Endorsement*"
                        >
                          <b-input-group size="md" append="Month">
                            <b-form-input
                              type="number"
                              min="1"
                              v-model="form.certificate.nextEndorsement"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="notification"
                          label="Notification Endorsement Before*"
                        >
                          <b-input-group size="md" append="Month">
                            <b-form-input
                              type="number"
                              min="1"
                              v-model="form.certificate.notificationEndorsement"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>

                  <fieldset
                    class="form-group border p-3"
                    v-if="
                    form.certificate.certificate &&
                    form.certificate.certificate.certificateType === 'FULL_TERM'
                    && isFulltermInterm"
                  >
                    <legend class="w-auto px-2">Endorsement:</legend>

                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-for="notification"
                          label="Next Endorsement*"
                        >
                          <b-input-group size="md" append="Month">
                            <b-form-input
                              type="number"
                              min="1"
                              v-model="form.certificate.nextEndorsement"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="notification"
                          label="Notification Endorsement Before*"
                        >
                          <b-input-group size="md" append="Month">
                            <b-form-input
                              type="number"
                              min="1"
                              v-model="form.certificate.notificationEndorsement"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col
                        md="6"
                        v-if="isFulltermInterm"
                      >
                        <b-form-group
                          label="Type of Endorsement*"
                          label-for="Type of Endorsement"
                        >
                          <v-select
                            label="text"
                            :options="endorsement_types"
                            :reduce="(type) => type.value"
                            v-model="form.certificate.endorsementType"
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        v-if="
                      form.certificate.certificate &&
                      form.certificate.certificate.certificateType ===
                        'FULL_TERM' &&
                      !isCertificateInterm()
                      && form.certificate.endorsementType === 'VERIFIED'
                    "
                      >
                        <b-form-group
                          label-for="DateVerified"
                          label="Date Verified"
                        >
                          <b-form-input
                            type="date"
                            v-model="form.certificate.dateVerified"
                          ></b-form-input>
                          <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show</small
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div
                      v-if="form.certificate.endorsementType === 'MANUAL'"
                      class="mt-4"
                    >
                      <b-row
                        v-for="(end, end_i) in form.certificate
                      .endorsementDocuments"
                        :key="end_i"
                        class="mt-2"
                      >
                        <b-col md="6"> Endorsement #{{ end.endorseNumber }}</b-col>
                        <b-col md="6">
                          <date-picker
                            v-model="end.rangeEndorsement"
                            :lang="lang"
                            type="date"
                            range
                            :placeholder="`Select range endorsement ${end.endorseNumber}`"
                          ></date-picker>
                          <b-button
                            variant="outline-danger"
                            class="ml-2"
                            v-if="end_i !== 0"
                            v-b-tooltip.hover
                            :title="`Delete endorsement ${end.endorseNumber}`"
                            @click="deleteEndorsementDocument(end_i)"
                          >
                            <i class="fa fa-times"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-col md="12" class="mt-4">
                        <b-button
                          block
                          variant="warning"
                          @click="addEndorsementDocument"
                        >Add Endorsement Based on Document
                        </b-button
                        >
                      </b-col>
                    </div>
                  </fieldset>

                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Remarks:</legend>
                    <b-row>
                      <b-col md="3">
                        <label>Office Checked</label>
                      </b-col>
                      <b-col md="9">
                        <b-form-checkbox
                          v-model="form.certificate.officeChecked"
                          name="check-button"
                          switch
                          inline
                        >
                          {{ form.certificate.officeChecked ? 'Yes' : 'No' }}
                        </b-form-checkbox>
                        <b-form-textarea
                          v-if="form.certificate.officeChecked"
                          v-model="form.certificate.officeRemark"
                          id="description"
                          rows="3"
                          placeholder="Type Remark"
                        ></b-form-textarea>
                      </b-col>
                      <b-col md="3" class="mt-3">
                        <label>Ship Checked</label>
                      </b-col>
                      <b-col md="9" class="mt-3">
                        <b-form-checkbox
                          v-model="form.certificate.shipChecked"
                          name="check-button"
                          switch
                          inline
                        >
                          {{ form.certificate.shipChecked ? 'Yes' : 'No' }}
                        </b-form-checkbox>
                        <b-form-textarea
                          v-if="form.certificate.shipChecked"
                          v-model="form.certificate.shipRemark"
                          id="description"
                          rows="3"
                          placeholder="Type Remark"
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset
                    class="form-group border p-3"
                    v-if="
                      form.certificate.certificate &&
                      !['PROVISIONAL'].includes(
                        form.certificate.certificate.certificateType
                      )
                    "
                  >
                    <legend class="w-auto px-2">Notification Receiver:</legend>
                    <b-form-group
                      label="Receiver* "
                      label-for="category_for"
                      v-if="users && users.length > 0"
                    >
                      <v-multiselect
                        v-model="form.certificate.user"
                        placeholder="Select receivers"
                        label="name"
                        track-by="id"
                        :options="users"
                        :multiple="true"
                      >
                        <span slot="noResult">Category not found.</span>
                      </v-multiselect>
                    </b-form-group>
                    <p v-else class="text-center my-3 text-muted">{{
                        form.certificate.vehicleId
                          ? 'Users not found on selected fleet.'
                          : 'Please select fleet first.'
                      }}</p>
                  </fieldset>
                  <b-form-group class="text-right">
                    <b-button
                      variant="outline-danger"
                      class="mr-2"
                      @click="show_edit_modal = !show_edit_modal"
                    >Cancel
                    </b-button
                    >
                    <b-button
                      variant="primary"
                      :disabled="loading_upload_file"
                      @click="doUpdateCertificate">
                      <span v-if="!loading_edit_certificates">Save</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                  </b-form-group>
                </b-modal>

                <!-- MODAL DETAIL CERTIFICARE -->
                <ModalDetailCertificate
                  :reminders="reminders"
                  @close="toggleModalDetail(false)"/>

                <!-- MODAL LOG HISTORY -->
                <ModalLogHistory
                  @close="toggleModalLog(false)"/>

                <!-- MODAL ENDORSEMENT -->
                <ModalEndorsement
                  @refresh="fetchCertificates"
                  @close="toggleModalEndorsement(false)"/>

                <!-- MODAL RENEWAL -->
                <ModalRenewal
                  @refresh="fetchCertificates"
                  @close="toggleModalRenewal(false)"/>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <!-- End Content Section !-->
      </tab-content-item>
      <!-- End Fleet Certificate -->
      <!-- Master Certificate -->
      <tab-content-item :active="false" id="master_certificate">
        <!-- Content Section !-->
        <b-row>

          <!-- Card Fleet -->
          <b-col :md="isChildComponent ? (!vehicleIdParam ? (!menuPermission('add') && !menuPermission('update') ? '12' : '8') : '12') : '8'">
            <iq-card>
              <template #headerTitle>
                <h4 class="text-primary">
                  <strong>
                    List Master Certificate
                  </strong>
                </h4>
              </template>
              <template v-slot:body>
                <template >
                  <b-row class="mb-3">
                    <b-col cols="12" md="8">
                      <b-row>
                        <b-col class="mr-0 pr-0">
                          <b-form-input
                            id="fleet_name"
                            class="w-100"
                            type="text"
                            placeholder="Find master certificates..."
                            v-model="master_certificate_params.search"
                          ></b-form-input>
                        </b-col>

                        <b-col cols="6" class="ml-0 pl-0">
                          <b-button
                            variant="primary"
                            class="ml-2"
                            @click="fetchMasterCertificates"
                          ><i class="fa fa-search"></i
                          ></b-button>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col cols="12" md="4" class="text-right"
                          v-if="menuPermission('export') || lsProfile.menu[1].child[0].child[0].export">
                      <b-button
                        variant="primary"
                        @click="doExportMasterCertificates"
                      >
                        <template v-if="!loading_export_master_certificates">
                          <i class="fa fa-file-excel"></i> &nbsp; Export
                        </template>
                        <template v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Exporting...
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
                <div
                  v-if="loading_master_certificates"
                  class="text-center my-5"
                >
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div
                    class="table-responsive"
                    v-if="master_certificates.length > 0"
                  >
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th width="10%">No</th>
                        <th width="25%">Certificate Name</th>
                        <th width="35%">Description</th>
                        <th width="15%">Type/Remark</th>
                        <th width="15%" v-if="!isChildComponent">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="(c, i) in master_certificates"
                        :key="`ms-c-${i}`"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>
                          {{ c.name }} <br/>
                          <strong>{{ c.certificateCategory.name }}</strong>
                        </td>
                        <td>{{ c.description }}</td>
                        <td>{{ c.certificateType ? (c.certificateType === 'PROVISIONAL' ? 'PERMANENT (NO ENDORS)' : c.certificateType.replace("_", " ")) : '-'}}</td>
                        <td v-if="!isChildComponent">
                          <b-button
                            v-if="menuPermission('update') || lsProfile.menu[1].child[0].child[0].update"
                            variant="success"
                            size="sm"
                            class="mr-2"
                            @click="onEditMasterCertificate(c)"
                          ><i class="fa fa-edit"></i
                          ></b-button>
                          <b-button
                            v-if="menuPermission('delete') || lsProfile.menu[1].child[0].child[0].delete"
                            variant="danger"
                            size="sm"
                            @click="onDeleteMasterCertificate(c)"
                          >
                            <i
                              v-if="!loading_delete_master_certificates"
                              class="fa fa-trash"
                            ></i>
                            <template v-else>
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              Deleting...
                            </template>
                          </b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                    <b-row class="mt-4">
                      <b-col col-6>
                        Total: {{ pagination_master_certificate.total }} &nbsp;
                        | &nbsp; Per page:
                        {{ pagination_master_certificate.perPage }}
                      </b-col>
                      <b-col col-6>
                        <b-pagination
                          v-model="master_certificate_params.page"
                          :total-rows="pagination_master_certificate.total"
                          :per-page="pagination_master_certificate.perPage"
                          align="right"
                          @input="fetchMasterCertificates"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                  <p v-else class="text-center my-4"
                  >No master certificates found.</p
                  >
                </template>
              </template>
            </iq-card>
          </b-col>

          <b-col sm="4"
                 v-if="(menuPermission('add') || menuPermission('update') || lsProfile.menu[1].child[0].child[0].add || lsProfile.menu[1].child[0].child[0].update) && !isChildComponent">
            <iq-card
              :class-name="form_master_certificate_edit_mode ? `border border-danger shadow-lg` : ''"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>{{
                    form_master_certificate_edit_mode ? 'Edit' : 'Add'
                  }}
                  Master Certificate</strong></h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="submitFormMasterCertificate">
                  <b-form-group label="Category" label-for="sub_project_name">
                    <b-row>
                      <b-col>
                        <v-multiselect
                          v-model="form.master_certificate.certificateCategory"
                          placeholder="Select category"
                          label="name"
                          track-by="id"
                          :options="certificate_categories"
                        >
                          <span slot="noResult">Category not found.</span>
                        </v-multiselect>
                      </b-col>

                      <b-button
                        v-if="form.master_certificate.certificateCategory"
                        variant="outline-primary"
                        class="mr-2"
                        v-b-modal.edit-certificate-subcategory
                        v-b-tooltip.hover
                        title="Edit Sub Category"
                        @click.prevent="onEditSubCategory"
                      ><i class="fa fa-edit"></i
                      ></b-button>

                      <b-button
                        variant="outline-primary"
                        class="mr-2"
                        v-b-modal.add-certificate-category
                        v-b-tooltip.hover
                        title="Add New Category"
                      ><i class="fa fa-plus"></i
                      ></b-button>

                      <!-- MODAL EDIT CATEGORY / MODAL EDIT SUB CATEGORY -->
                      <b-modal
                        id="edit-certificate-subcategory"
                        size="md"
                        :title="`Edit Category ${form.edit_certificate_category.name}`"
                        ok-title="Save"
                        cancel-title="Cancel"
                        centered
                        @ok="doEditCertificateCategory"
                      >
                        <b-form-group
                          label="Category name"
                          label-for="category_name"
                        >
                          <b-form-input
                            id="category_name"
                            type="text"
                            placeholder="Category Name"
                            v-model="form.edit_certificate_category.name"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          label="Description"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="description"
                            rows="5"
                            placeholder="Category Description"
                            v-model="form.edit_certificate_category.description"
                          ></b-form-textarea>
                        </b-form-group>

                        <b-form-group label="Sub Category">
                          <template
                            v-for="(sc, i) in form.edit_certificate_category
                              .subCategory"
                          >
                            <b-input-group
                              :key="`scf-${i}`"
                              :prepend="(i + 1).toString()"
                              class="mb-3"
                            >
                              <b-form-input
                                v-model="sc.name"
                                :placeholder="`Sub Category ${i + 1} Name`"
                                type="text"
                                maxlength="30"
                              ></b-form-input>

                              <b-input-group-append>
                                <b-button
                                  variant="outline-danger"
                                  @click="deleteSubCategory(i, true)"
                                >
                                  <i class="fa fa-times"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </template>
                          <div class="mt-3">
                            <b-button @click.prevent="addSubCategory(true)" pill variant="primary" class="mb-3 mr-1"><i class="fa fa-plus"></i>Add New Sub Category</b-button>
                          </div>
                        </b-form-group>
                      </b-modal>

                      <!-- MODAL ADD CATEGORY -->
                      <b-modal
                        id="add-certificate-category"
                        size="md"
                        :title="`Add New Category`"
                        ok-title="Add"
                        cancel-title="Cancel"
                        centered
                        @ok="doAddCertificateCategory"
                      >
                        <b-form-group
                          label="Category name"
                          label-for="category_name"
                        >
                          <b-form-input
                            id="category_name"
                            type="text"
                            placeholder="Category Name"
                            v-model="form.certificate_category.name"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          label="Description"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="description"
                            rows="3"
                            placeholder="Category Description"
                            v-model="form.certificate_category.description"
                          ></b-form-textarea>
                        </b-form-group>

                        <b-form-group label="Sub Category">
                          <template
                            v-for="(sc, i) in form.certificate_category
                              .subCategory"
                          >
                            <b-input-group
                              :key="`scf-${i}`"
                              :prepend="(i + 1).toString()"
                              class="mb-3"
                            >
                              <b-form-input
                                v-model="sc.name"
                                :placeholder="`Sub Category ${i + 1}`"
                                type="text"
                                maxlength="30"
                              ></b-form-input>

                              <b-input-group-append>
                                <b-button
                                  variant="outline-danger"
                                  @click="deleteSubCategory(i, false)"
                                >
                                  <i class="fa fa-times"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </template>
                          <div class="mt-3">
                            <b-button @click.prevent="addSubCategory(false)" pill variant="primary" class="mb-3 mr-1 btn-sm"><i class="fa fa-plus"></i>Add New Sub Category</b-button>
                          </div>
                        </b-form-group>
                      </b-modal>
                    </b-row>
                  </b-form-group>

                  <b-form-group
                    label="Certificate Name"
                    label-for="name_certificate"
                  >
                    <b-form-input
                      id="name_certificate"
                      type="text"
                      placeholder="Name of Certificate"
                      v-model="form.master_certificate.name"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Type" for="remarks">
                    <b-form-radio-group
                      id="remarks"
                      v-model="form.master_certificate.certificateType"
                    >
                      <template v-for="(item, index) in remarks">
                        <b-form-radio
                          :key="`rm-${index}`"
                          :value="item.value"
                        >{{ item.label }}
                        </b-form-radio
                        >
                      </template>
                    </b-form-radio-group>
                  </b-form-group>

                  <b-form-group label="Description" label-for="description">
                    <b-form-textarea
                      id="description"
                      rows="3"
                      placeholder="Description"
                      v-model="form.master_certificate.description"
                    ></b-form-textarea>
                  </b-form-group>

                  <b-form-group
                    label="Setting Reminder"
                    label-for="reminder"
                    v-if="
                      form.master_certificate.certificateType !== 'PROVISIONAL' && form.master_certificate.certificateType !== 'PERMANENT'
                    "
                  >
                    <b-form-checkbox-group
                      v-model="form.master_certificate.reminder"
                      :options="reminders"
                      switches
                      stacked
                    ></b-form-checkbox-group>
                  </b-form-group>
                  <hr/>
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_master_certificates">{{
                          form_master_certificate_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                        }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          Filter Log History Users
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormMasterCertificate"
                    >Cancel
                    </b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
          <!-- End Card Fleet -->
        </b-row>
        <!-- End Content Section !-->
      </tab-content-item>
      <!-- End Master Certificate -->
    </div>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { saveAs } from 'file-saver'
import {
  authComputed,
  certificatesActions,
  fleetsActions,
  filesActions,
  usersActions
} from '@src/Utils/helper'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Certificate',
  components: {
    ModalDetailCertificate: () =>
      import('@components/certificate/modal/ModalDetailCertificate'),
    ModalLogHistory: () =>
      import('@components/certificate/modal/ModalLogHistory'),
    ModalEndorsement: () =>
      import('@components/certificate/modal/ModalEndorsement'),
    ModalRenewal: () => import('@components/certificate/modal/ModalRenewal')
  },
  data () {
    return {
      view_style: 'list', // list | grid | calendar
      // Calendar
      masks: {
        weekdays: 'WWW'
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      attributes: [],
      // End Calendar
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Activities',
          href: '/apps/activities'
        },
        {
          text: 'Certificate',
          active: true
        }
      ],
      certificate_statuses: [
        { value: '', text: 'All' },
        { value: 'active', text: 'Active' },
        { value: 'expired', text: 'Expired' },
        { value: 'nextMonth', text: 'One Month Expiring' },
        { value: 'nextThreeMonth', text: 'Three Month Expiring' },
        { value: 'progress', text: 'Progress' }
      ],
      remarks: [
        {
          label: 'Full Term',
          color: 'success',
          value: 'FULL_TERM'
        },
        {
          label: 'Short Term',
          color: 'warning',
          value: 'SHORT_TERM'
        },
        {
          label: 'Permanent',
          color: 'primary',
          value: 'PERMANENT'
        },
        {
          label: 'Permanent (Not Endorsement)',
          color: 'primary',
          value: 'PROVISIONAL'
        }
      ],
      reminders: [
        {
          text: '3 Bulan Sebelumnya',
          value: 90
        },
        {
          text: '2 Bulan Sebelumnya',
          value: 60
        },
        {
          text: '1 Bulan Sebelumnya',
          value: 30
        },
        {
          text: '3 Minggu Sebelumnya',
          value: 21
        },
        {
          text: '2 Minggu Sebelumnya',
          value: 14
        },
        {
          text: '1 Minggu Sebelumnya',
          value: 7
        },
        {
          text: '6 Hari Sebelumnya',
          value: 6
        },
        {
          text: '5 Hari Sebelumnya',
          value: 5
        },
        {
          text: '4 Hari Sebelumnya',
          value: 4
        },
        {
          text: '3 Hari Sebelumnya',
          value: 3
        },
        {
          text: '2 Hari Sebelumnya',
          value: 2
        },
        {
          text: '1 Hari Sebelumnya',
          value: 1
        }
      ],
      endorsement_types: [
        { value: 'ISSUED', text: 'Trigger Endorsement Based on Date Issued' },
        {
          value: 'VERIFIED',
          text: 'Trigger Endorsement Based on Date Verified'
        },
        { value: 'MANUAL', text: 'Trigger Endorsement Based on Document' }
      ],
      loading_certificates: true,
      loading_users: false,
      loading_master_certificates: true,
      loading_form_master_certificates: false,
      loading_export_master_certificates: false,
      loading_export_certificates: false,
      loading_delete_master_certificates: false,
      loading_delete_certificates: false,
      loading_add_certificates: false,
      loading_edit_certificates: false,
      loading_upload_file: false,
      form_master_certificate_edit_mode: false,
      show_add_modal: false,
      show_edit_modal: false,
      certificates: [],
      certificates_logs: [],
      users: [],
      master_certificates: [],
      masterAllCertificates: [],
      dateRangeFilter: '',
      certificate_categories: [],
      selected_master_certificate: {},
      master_certificate_params: {
        page: 1,
        perPage: 25,
        active: true,
        search: ''
      },
      certificate_params: {
        page: 1,
        active: true,
        perPage: 25,
        status: '',
        search: '',
        vehicle: '',
        type: '',
        certificateCategoryIds: ''
      },
      users_params: {
        page: 1,
        active: true,
        search: '',
        perPage: 250
      },
      endorsementType: '',
      form: {
        master_certificate: {
          certificateCategory: '',
          certificateSubCategory: '',
          name: '',
          haveSubName: false,
          subName: '',
          certificateType: '',
          reminder: [],
          description: ''
        },
        certificate_category: {
          name: '',
          description: '',
          subCategory: [{ name: '' }]
        },
        edit_certificate_category: {
          name: '',
          description: '',
          subCategory: []
        },
        certificate: {
          serialNumber: '-',
          code: '',
          certificate: {},
          certificateCategory: {},
          vehicleId: '',
          file: '',
          placeIssued: '',
          issuedBy: '',
          notOnBoard: false,
          user: [],
          dateIssued: '',
          expiredDate: '',
          nextEndorsement: '',
          notificationEndorsement: '',
          endorsementDocuments: [
            {
              endorseNumber: 1,
              rangeEndorsement: ''
            }
          ],
          endorsement: [
            {
              endorseNumber: 1,
              startEndorseDate: '',
              endEndorseDate: ''
            }
          ],
          endorsementType: '',
          dateVerified: '',
          officeChecked: true,
          officeRemark: '',
          shipChecked: true,
          shipRemark: ''
        }
      },
      modal:{
        filter:false
      },
      pagination: {},
      pagination_master_certificate: {},
      readOnlyIssuedBy: true,
      isFulltermInterm: false,
      lsProfile: JSON.parse(localStorage.getItem('profile'))
    }
  },
  props: {
    isChildComponent: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  created () {
    this.fetchFleetUsers = _.debounce(this.fetchFleetUsers, 1000)
    this.fetchMasterCertificates = _.debounce(
      this.fetchMasterCertificates,
      1000
    )
  },
  mounted () {
    if (this.$route.query.status) {
      this.certificate_params.status = this.$route.query.status.toString().toLowerCase()
    }
    const profileData = JSON.parse(localStorage.getItem('profile'))
    // const  menuObject  = profileData.menu[1].child[0].child[0]
    this.fetchMasterCertificates()
    this.fetMasterAllCertificates()
    this.fetchCertificateCategories()
    this.fetchCertificates()
  },
  computed: {
    ...authComputed,
    ...mapState('certificates', {
      modal_detail: (state) => state.modal_detail,
      modal_log: (state) => state.modal_log,
      modal_endorsement: (state) => state.modal_endorsement,
      modal_renewak: (state) => state.modal_renewak
    }),
    remarks_filter () {
      let copied_remarks = [...this.remarks]
      copied_remarks.unshift({
        label: 'All',
        color: 'success',
        value: ''
      })

      return copied_remarks
    },
    fleets () {
      if (!this.profile || !this.profile.vehicle) return []

      return this.profile.vehicle
    },
    vehicleIdParam () {
      return this.$route.query.vehicleId
        ? this.$route.query.vehicleId
        : ''
    },
    fleetParamsAvailable () {
      if (!this.vehicleIdParam) return false

      let found = this.fleets.find((fleet) => fleet.id == this.vehicleIdParam)

      if (found) return true

      return false
    },
    fleetFromParam () {
      if (!this.vehicleIdParam) return {}

      return this.fleets.find((fleet) => fleet.id == this.vehicleIdParam)
    }
  },
  methods: {
    ...certificatesActions,
    ...fleetsActions,
    ...filesActions,
    ...usersActions,
    ...mapMutations('certificates', {
      setModalDetail: 'SET_MODAL_DETAIL',
      setModalLog: 'SET_MODAL_LOG',
      setModalEndorsement: 'SET_MODAL_ENDORSEMENT',
      setModalRenewal: 'SET_MODAL_RENEWAL'
    }),
    
    toggleModalFilter(){
      this.modal.filter = !this.modal.filter
    },

    async fetchCertificates () {
      if (this.vehicleIdParam) {
        this.certificate_params.vehicle = parseInt(this.vehicleIdParam)
      }

      if (this.$route.query.status_certificate) {
        this.certificate_params.status = this.$route.query.status_certificate
      }

      let junk_params = { ...this.certificate_params }
      if (
        Array.isArray(junk_params.certificateCategoryIds) &&
        junk_params.certificateCategoryIds.length > 0
      ) {
        let certificateCategoryIds = []
        junk_params.certificateCategoryIds.forEach((certificateCategoryId) => {
          certificateCategoryIds.push(certificateCategoryId.id)
        })

        junk_params.certificateCategoryIds = certificateCategoryIds.join(',')
      }
      this.loading_certificates = true

      let params = this.$options.filters.cleanObject(junk_params)
      let res = await this.getCertificateVehicles(params)

      if (res.status == 'success') {
        const { data, pagination } = res
        this.certificates = data
        this.pagination = pagination
        this.setCalendarView()
      } else {
        this.certificates = []
        this.pagination = {}
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }

      this.loading_certificates = false
    },
    async fetchFleetUsers () {
      this.loading_users = true
      let res = await this.getUsers({
        page: 1,
        showAll: true,
        vehicleId: this.form.certificate.vehicleId,
        perPage: 250
      })

      this.users = res.data.data ? res.data.data.map(user => {
        return {
          id: user.id,
          name: user.userDetail?.name
        }
      }) : []
      this.loading_users = false
    },
    async fetchMasterCertificates () {
      this.loading_master_certificates = true
      let params = this.$options.filters.cleanObject(
        this.master_certificate_params
      )
      let res = await this.getCertificates(params)

      const { data, pagination } = res
      this.master_certificates = data
      this.pagination_master_certificate = pagination
      this.loading_master_certificates = false
    },
    async fetMasterAllCertificates () {
      let res = await this.getCertificates({
        page: 1,
        showAll: true,
        vehicleId: this.form.certificate.vehicleId,
        perPage: 250
      })

      const { data } = res
      this.masterAllCertificates = data
    },
    async fetchCertificateCategories () {
      let res = await this.getCertificateCategories()
      this.certificate_categories = res.data
    },
    async doAddCertificateCategory () {
      let payload = this.form.certificate_category

      let form_has_empty_value = false
      for (var key in payload.data) {
        if (payload.data[key] === '') {
          form_has_empty_value = true
        } else if (Array.isArray(payload.data[key]) && !payload.data[key].length) {
          form_has_empty_value = true
        }
      }

      if (form_has_empty_value) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {
        let res = await this.addCertificateCategory(payload)
        if (res.status == 'success') {
          this.form.certificate_category = {
            name: '',
            description: '',
            subCategory: []
          }
          this.fetchCertificateCategories()
          this.$swal(
            'Category added!',
            'Category added successfully.',
            'success'
          )
        } else {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
      }
    },
    async doEditCertificateCategory () {
      const payload = this.form.edit_certificate_category
      const id = this.form.master_certificate.certificateCategory.id

      let form_has_empty_value = false
      for (var key in payload.data) {
        if (payload.data[key] === '') {
          form_has_empty_value = true
        } else if (Array.isArray(payload.data[key]) && !payload.data[key].length) {
          form_has_empty_value = true
        }
      }

      if (form_has_empty_value) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {
        let res = await this.updateCertificateCategory({ id, data: payload })
        if (res.status == 'success') {
          this.form.edit_certificate_category = {
            name: '',
            description: '',
            subCategory: []
          }
          this.fetchCertificateCategories()
          this.$swal(
            'Category edited!',
            'Category edited successfully.',
            'success'
          )
        } else {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
      }
    },
    async submitFormMasterCertificate () {
      this.loading_form_master_certificates = true

      let payload = {
        certificateCategoryId:
        this.form.master_certificate.certificateCategory.id,
        name: this.form.master_certificate.name,
        certificateType: this.form.master_certificate.certificateType,
        reminder: JSON.stringify(this.form.master_certificate.reminder),
        description: this.form.master_certificate.description
      }

      if (this.form.master_certificate.certificateType == 'PROVISIONAL') {
        payload = {
          certificateCategoryId:
          this.form.master_certificate.certificateCategory.id,
          name: this.form.master_certificate.name,
          certificateType: this.form.master_certificate.certificateType,
          description: this.form.master_certificate.description
        }
      }

      let form_has_empty_value = false
      for (var key in payload) {
        if (payload[key] === '') {
          form_has_empty_value = true
        } else if (Array.isArray(payload[key]) && !payload[key].length) {
          form_has_empty_value = true
        }
      }

      if (form_has_empty_value) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {
        payload.haveSubName = false
        payload.subName = ''

        if (!this.form_master_certificate_edit_mode) {
          let res = await this.addCertificate(payload)
          if (res.status == 'success') {
            this.resetFormMasterCertificate()
            this.fetchMasterCertificates()
            this.$swal(
              'Certificate added!',
              'Certificate added successfully.',
              'success'
            )
          } else {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        } else {
          let res = await this.updateCertificate({
            id: this.selected_master_certificate.id,
            data: payload
          })
          if (res.status == 'success') {
            this.resetFormMasterCertificate()
            this.fetchMasterCertificates()
            this.$swal(
              'Certificate updated!',
              'Certificate updated successfully.',
              'success'
            )
          } else {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        }
      }
      this.loading_form_master_certificates = false
    },
    async onDeleteMasterCertificate (certificate) {
      this.$swal({
        title: 'Delete certificate?',
        text: `Certificate ${certificate.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_master_certificates = true
          let res = await this.deleteCertificate(certificate.id)
          if (res.status != 'success') {
            this.loading_delete_master_certificates = false
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.loading_delete_master_certificates = false
            this.fetchMasterCertificates()
            this.$swal(
              `Certificate deleted!`,
              `Certificate ${certificate.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    onStatusCertificateChange () {
      if (this.$route.query.status_certificate) {
        this.$router.push({ query: { status_certificate: '' } })
      }
    },
    async doExportMasterCertificates () {
      this.loading_export_master_certificates = true
      let res = await this.exportCertificates()
      if (res.status == 'success') {
        this.loading_export_master_certificates = false
        saveAs(res.data, 'Certificates List.xlsx')
      } else {
        this.loading_export_master_certificates = false
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async doExportCertificateVehicles () {
      this.loading_export_certificates = true
      if (this.vehicleIdParam) {
        this.certificate_params.vehicle = parseInt(this.vehicleIdParam)
      }

      if (this.$route.query.status_certificate) {
        this.certificate_params.status = this.$route.query.status_certificate
      }

      let junk_params = { ...this.certificate_params }
      if (
        Array.isArray(junk_params.certificateCategoryIds) &&
        junk_params.certificateCategoryIds.length > 0
      ) {
        let certificateCategoryIds = []
        junk_params.certificateCategoryIds.forEach((certificateCategoryId) => {
          certificateCategoryIds.push(certificateCategoryId.id)
        })

        junk_params.certificateCategoryIds = certificateCategoryIds.join(',')
      }

      let params = this.$options.filters.cleanObject(junk_params)

      let res = await this.exportCertificateVehicles(params)
      if (res.status == 'success') {
        this.loading_export_certificates = false
        saveAs(res.data, 'Certificates Vehicle List.xlsx')
      } else {
        this.loading_export_certificates = false
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async uploadFile (event) {
      this.loading_upload_file = true

      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        this.loading_upload_file = false
        this.form.certificate.file = res.data.fileName
      } else {
        this.loading_upload_file = false
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async onDeleteCertificate (certificate_vehicle) {
      let certificate = certificate_vehicle.certificate

      this.$swal({
        title: 'Delete certificate?',
        text: `Certificate ${certificate.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_certificates = true
          let res = await this.deleteCertificateVehicle(certificate_vehicle.id)
          if (res.status != 'success') {
            this.loading_delete_certificates = false
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.loading_delete_certificates = false
            this.fetchCertificates()
            this.$swal(
              `Certificate deleted!`,
              `Certificate ${certificate.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    async doUpdateCertificate () {
      this.loading_edit_certificates = true
      const { pass, payload } = await this.checkFormUpdateCertificate()
      console.log('payload', payload)

      if (pass) {
        let res = await this.updateCertificateVehicle({
          id: this.form.certificate.id,
          data: payload
        })
        if (res.status == 'success') {
          this.resetFormCertificate()
          this.fetchCertificates()
          this.$swal(
            `Certificate edited`,
            `Certificate edited successfully.`,
            'success'
          )
        } else {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
        this.loading_edit_certificates = false
        this.show_edit_modal = false
      }

      this.loading_edit_certificates = false
    },
    async doAddCertificate () {
      this.loading_add_certificates = true

      const { pass, payload } = await this.checkFormCertificate()

      if (pass) {
        let res = await this.addCertificateVehicle(payload)
        if (res.status == 'success') {
          this.fetchCertificates()
          this.$swal(
            `Certificate added!`,
            `Certificate added successfully.`,
            'success'
          )
          this.resetFormCertificate()

          this.loading_add_certificates = false
          this.show_add_modal = false
        } else {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }

          this.loading_add_certificates = false
          this.show_add_modal = false
        }
      } else {
        this.loading_add_certificates = false
      }
    },
    async checkFormCertificate () {
      let payload = {}
      let users = []

      if (!this.form.certificate.user && Array.isArray(this.form.certificate.user)) {
        return { pass: false, payload }
      }

      this.form.certificate.user.forEach((user) => users.push({ id: user.id }))

      this.form.certificate.code = this.form.certificate.serialNumber

      if (this.form.certificate.certificate.certificateType == 'PERMANENT') {
        let dateIssued = this.$options.filters.parseDate(
          this.form.certificate.dateIssued,
          'DD-MM-YYYY'
        )

        const res_end = await this.getEndorsementDates({
          type: 'PERMANENT',
          params: {
            dateIssued,
            nextEndorsement: this.form.certificate.nextEndorsement
          }
        })

        if (!res_end) {
          this.$swal(
            'Error while getting endorsement!',
            'Please try again later or contact the admin.',
            'error'
          )

          return
        }

        payload = {
          serialNumber: this.form.certificate.serialNumber,
          code: this.form.certificate.code,
          certificateId: this.form.certificate.certificate.id,
          vehicleId: this.form.certificate.vehicleId,
          file: this.form.certificate.file,
          placeIssued: this.form.certificate.placeIssued,
          issuedBy: this.form.certificate.issuedBy,
          notOnBoard: this.form.certificate.notOnBoard,
          user: users,
          dateIssued,
          nextEndorsement: this.form.certificate.nextEndorsement,
          notificationEndorsement:
          this.form.certificate.notificationEndorsement,
          endorsement: res_end.data,
          officeChecked: this.form.certificate.officeChecked,
          officeRemark: this.form.certificate.officeRemark,
          shipChecked: this.form.certificate.shipChecked,
          shipRemark: this.form.certificate.shipRemark
        }
      } else if (
        this.form.certificate.certificate.certificateType == 'FULL_TERM' &&
        this.isCertificateInterm()
      ) {
        // FULL TERM - INTERM
        let dateIssued = this.$options.filters.parseDate(
          this.form.certificate.dateIssued,
          'DD-MM-YYYY'
        )
        let expiredDate = this.$options.filters.parseDate(
          this.form.certificate.expiredDate,
          'DD-MM-YYYY'
        )

        payload = {
          serialNumber: this.form.certificate.serialNumber,
          code: this.form.certificate.code,
          certificateId: this.form.certificate.certificate.id,
          vehicleId: this.form.certificate.vehicleId,
          file: this.form.certificate.file,
          placeIssued: this.form.certificate.placeIssued,
          issuedBy: this.form.certificate.issuedBy,
          notOnBoard: this.form.certificate.notOnBoard,
          user: users,
          dateIssued,
          expiredDate,
          officeChecked: this.form.certificate.officeChecked,
          officeRemark: this.form.certificate.officeRemark,
          shipChecked: this.form.certificate.shipChecked,
          shipRemark: this.form.certificate.shipRemark
        }
      } else if (
        this.form.certificate.certificate.certificateType == 'FULL_TERM' &&
        !this.isCertificateInterm()
      ) {
        let endorsements = []
        let dateIssued = this.$options.filters.parseDate(
          this.form.certificate.dateIssued,
          'DD-MM-YYYY'
        )
        let dateVerified = this.$options.filters.parseDate(
          this.form.certificate.dateVerified,
          'DD-MM-YYYY'
        )
        let expiredDate = this.$options.filters.parseDate(
          this.form.certificate.expiredDate,
          'DD-MM-YYYY'
        )

        if (this.form.certificate.endorsementType === 'ISSUED') {
          // ENDORSEMENT BASED ON DATE ISSUED
          const res_end = await this.getEndorsementDates({
            type: 'FULL_TERM',
            params: {
              dateParam: dateIssued,
              expiredDate,
              nextEndorsement: this.form.certificate.nextEndorsement
            }
          })

          if (!res_end) {
            this.$swal(
              'Error while getting endorsement!',
              'Please try again later or contact the admin.',
              'error'
            )

            return
          }
          endorsements = res_end.data
        } else if (this.form.certificate.endorsementType === 'VERIFIED') {
          const res_end = await this.getEndorsementDates({
            type: 'FULL_TERM',
            params: {
              dateParam: dateVerified,
              expiredDate,
              nextEndorsement: this.form.certificate.nextEndorsement
            }
          })

          if (!res_end) {
            this.$swal(
              'Error while getting endorsement!',
              'Please try again later or contact the admin.',
              'error'
            )

            return
          }

          endorsements = res_end.data
        } else if (this.form.certificate.endorsementType === 'MANUAL') {
          // ENDORSEMENT BASED ON DOCUMENT
          endorsements = this.getEndorsementDocument()
        }

        payload = {
          serialNumber: this.form.certificate.serialNumber,
          code: this.form.certificate.code,
          certificateId: this.form.certificate.certificate.id,
          vehicleId: this.form.certificate.vehicleId,
          file: this.form.certificate.file,
          placeIssued: this.form.certificate.placeIssued,
          issuedBy: this.form.certificate.issuedBy,
          notOnBoard: this.form.certificate.notOnBoard,
          user: users,
          dateIssued,
          expiredDate,
          verificationDate: dateVerified,
          nextEndorsement:
          this.form.certificate.nextEndorsement,
          notificationEndorsement:
          this.form.certificate.notificationEndorsement,
          endorsement: endorsements,
          endorsementType: this.form.certificate.endorsementType,
          officeChecked: this.form.certificate.officeChecked,
          officeRemark: this.form.certificate.officeRemark,
          shipChecked: this.form.certificate.shipChecked,
          shipRemark: this.form.certificate.shipRemark
        }
      } else if (this.form.certificate.certificate.certificateType == 'SHORT_TERM') {
        // TYPE SHORT_TERM
        payload = {
          serialNumber: this.form.certificate.serialNumber,
          code: this.form.certificate.code,
          certificateId: this.form.certificate.certificate.id,
          vehicleId: this.form.certificate.vehicleId,
          file: this.form.certificate.file,
          placeIssued: this.form.certificate.placeIssued,
          issuedBy: this.form.certificate.issuedBy,
          notOnBoard: this.form.certificate.notOnBoard,
          dateIssued: this.$options.filters.parseDate(
            this.form.certificate.dateIssued,
            'DD-MM-YYYY'
          ),
          expiredDate: this.$options.filters.parseDate(
            this.form.certificate.expiredDate,
            'DD-MM-YYYY'
          ),
          user: users,
          officeChecked: this.form.certificate.officeChecked,
          officeRemark: this.form.certificate.officeRemark,
          shipChecked: this.form.certificate.shipChecked,
          shipRemark: this.form.certificate.shipRemark
        }
      } else {
        // TYPE PROVISIONAL
        payload = {
          serialNumber: this.form.certificate.serialNumber,
          code: this.form.certificate.code,
          certificateId: this.form.certificate.certificate.id,
          vehicleId: this.form.certificate.vehicleId,
          file: this.form.certificate.file,
          placeIssued: this.form.certificate.placeIssued,
          issuedBy: this.form.certificate.issuedBy,
          notOnBoard: this.form.certificate.notOnBoard,
          dateIssued: this.$options.filters.parseDate(
            this.form.certificate.dateIssued,
            'DD-MM-YYYY'
          ),
          officeChecked: this.form.certificate.officeChecked,
          officeRemark: this.form.certificate.officeRemark,
          shipChecked: this.form.certificate.shipChecked,
          shipRemark: this.form.certificate.shipRemark
        }
      }

      let form_has_empty_value = false
      for (var key in payload) {
        if (['officeRemark', 'shipRemark', 'verificationDate'].includes(key)) {
          continue
        } else {
          if (payload[key] === '') {
            form_has_empty_value = true
          } else if (Array.isArray(payload[key]) && !payload[key].length) {
            form_has_empty_value = true
          }
        }
      }

      if (form_has_empty_value || Object.keys(payload).length == 0) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )

        return { pass: false, payload }
      }
      return { pass: true, payload }
    },
    async checkFormUpdateCertificate () {
      let payload = {}
      let users = []

      if (!this.form.certificate.user && Array.isArray(this.form.certificate.user)) {
        return { pass: false, payload }
      }

      this.form.certificate.user.forEach((user) => users.push({ id: user.id }))

      payload = {
        certificateId: this.form.certificate.certificate.id,
        vehicleId: this.form.certificate.vehicleId,
        issuedBy: this.form.certificate.issuedBy,
        serialNumber: this.form.certificate.serialNumber,
        code: this.form.certificate.serialNumber,
        file: this.form.certificate.file,
        placeIssued: this.form.certificate.placeIssued,
        location: this.form.certificate.placeIssued,
        notOnBoard: false,
        officeChecked: this.form.certificate.officeChecked,
        officeRemark: this.form.certificate.officeRemark,
        shipChecked: this.form.certificate.shipChecked,
        shipRemark: this.form.certificate.shipRemark,
        dateIssued: this.$options.filters.parseDate(
          this.form.certificate.dateIssued,
          'DD-MM-YYYY'
        ),
        expiredDate: this.$options.filters.parseDate(
          this.form.certificate.expiredDate,
          'DD-MM-YYYY'
        ),
        verificationDate: this.form.certificate.verificationDate,
        user: users
      }

      if (this.form.certificate.certificate.certificateType === 'PROVISIONAL') {
        delete payload.expiredDate
        delete payload.user
      }

      let form_has_empty_value = false
      for (var key in payload) {
        if (['officeRemark', 'shipRemark', 'verificationDate'].includes(key)) {
          continue
        } else {
          if (payload[key] === '') {
            form_has_empty_value = true
          } else if (Array.isArray(payload[key]) && !payload[key].length) {
            form_has_empty_value = true
          }
        }
      }

      if (form_has_empty_value || Object.keys(payload).length == 0) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )

        return { pass: false, payload }
      }
      return { pass: true, payload }
    },
    onEditMasterCertificate (certificate) {
      this.form_master_certificate_edit_mode = true
      this.selected_master_certificate = certificate
      this.form.master_certificate = {
        certificateCategory: certificate.certificateCategory,
        name: certificate.name,
        haveSubName: false,
        subName: '',
        certificateType: certificate.certificateType,
        reminder: certificate.reminder ? JSON.parse(certificate.reminder) : [],
        description: certificate.description
      }
    },
    onEditCertificate (cert) {
      this.resetFormCertificate()
      const certificate = JSON.parse(JSON.stringify(cert))
      this.fetchFleetUsers()
      this.show_edit_modal = !this.show_edit_modal
      this.form.certificate = certificate
      this.form.certificate.vehicleId = certificate.vehicle.id
      this.form.certificate.issuedBy = certificate.issuedBy.id
      this.form.certificate.file = certificate.file.fileName

      console.log( this.form.certificate.certificate?.certificateType, " this.form.certificate ")

      this.form.certificate.dateIssued = this.$options.filters.parseDate(
        certificate.dateIssued,
        'YYYY-MM-DD'
      )
      this.form.certificate.expiredDate = this.$options.filters.parseDate(
        certificate.expired,
        'YYYY-MM-DD'
      )

      if (certificate.endorsementType && certificate.endorsementType === 'VERIFIED') {
        this.form.certificate.dateVerified = this.$options.filters.parseDate(
          certificate.dateVerified,
          'YYYY-MM-DD'
        )
      }
    },
    resetFormMasterCertificate () {
      this.form_master_certificate_edit_mode = false
      this.selected_master_certificate = {}
      this.form.master_certificate = {
        certificateCategory: '',
        name: '',
        haveSubName: false,
        subName: '',
        certificateType: '',
        reminder: [],
        description: ''
      }
    },
    resetFormCertificate () {
      this.loading_upload_file = false
      this.form.certificate = {
        serialNumber: '-',
        code: '',
        certificate: {},
        vehicleId: '',
        file: '',
        placeIssued: '',
        issuedBy: '',
        notOnBoard: true,
        user: [],
        dateIssued: '',
        expiredDate: '',
        nextEndorsement: '',
        notificationEndorsement: '',
        endorsement: [
          {
            endorseNumber: 1,
            endorseDate: ''
          }
        ],
        endorsementDocuments: [
          {
            endorseNumber: 1,
            rangeEndorsement: ''
          }
        ],
        officeChecked: true,
        officeRemark: '',
        shipChecked: true,
        shipRemark: ''
      }
    },
    downloadCertificate (file_url) {
      window.open(file_url)
    },
    findEndorsement (endorsements, endorseNumber) {
      if (!endorsements && endorsements.length < 1) {
        return '-'
      }

      if (endorseNumber) {
        let last_endorse = endorsements.find(
          (endorse) => endorse.endorseNumber == endorseNumber
        )

        if (!last_endorse) {
          return '-'
        }

        return last_endorse.endEndorseDate
          ? last_endorse.endEndorseDate
          : last_endorse.startEndorseDate
      }

      return '-'
    },
    findEndorsementByMustProcess (endorsements) {
      if (!endorsements && endorsements.length < 1) {
        return '-'
      }

      let must_process = endorsements.find(
        (endorse) => endorse.mustProcess == true
      )

      if (!must_process) {
        return '-'
      }

      return must_process.endEndorseDate
        ? must_process.endEndorseDate
        : must_process.startEndorseDate
    },
    findReminder (reminder_value) {
      let reminder = this.reminders.find((r) => r.value == reminder_value)
      if (reminder) return reminder.text
      return '-'
    },
    setCalendarView () {
      this.attributes = []
      this.certificates.forEach((cert, i) => {
        const number = (i += 1)

        if (cert && cert.endorsement) {
          const next_endorse_date = this.findEndorsementByMustProcess(
            cert.endorsement
          )
          if (next_endorse_date && next_endorse_date != '-') {
            this.attributes.push({
              key: `event_end-${number}`,
              customData: {
                title: cert.certificate.name,
                vehicleName: cert.vehicle.name,
                class: 'bg-warning text-white'
              },
              dates: new Date(
                this.$options.filters.parseDate(next_endorse_date, 'YYYY-MM-DD')
              )
            })
          }
        }

        this.attributes.push({
          key: `event_exp-${number}`,
          customData: {
            title: cert.certificate.name,
            vehicleName: cert.vehicle.name,
            class: 'bg-danger text-white'
          },
          dates: new Date(
            this.$options.filters.parseDate(cert.expired, 'YYYY-MM-DD')
          )
        })
      })
    },
    addSubCategory (edit) {
      if (!edit) {
        this.form.certificate_category.subCategory.push({ name: '' })
      } else {
        this.form.edit_certificate_category.subCategory.push({ name: '' })
      }
    },
    deleteSubCategory (index, edit) {
      if (!edit) {
        this.form.certificate_category.subCategory.splice(index, 1)
      } else {
        this.form.edit_certificate_category.subCategory.splice(index, 1)
      }
    },
    onEditSubCategory () {
      this.form.edit_certificate_category =
        this.form.master_certificate.certificateCategory
    },
    checkExpiredOrEndorsement (certificate) {
      if (
        certificate.endorsementRemainingDay > 0 &&
        certificate.endorsementRemainingDay < certificate.expiredRemainingDay
      ) {
        return certificate.endorsementRemainingDay
      }
      return certificate.expiredRemainingDay
    },
    async onSearchCertificate (search, loading) {
      if (search.length) {
        loading(true)
        this.master_certificate_params.search = search
        await this.fetchMasterCertificates()
        loading(false)
      } else {
        loading(true)
        this.master_certificate_params.search = ''
        await this.fetchMasterCertificates()
        loading(false)
      }
    },
    certificateRangeInMonth () {
      return this.$options.filters.getDateDifferenceInMonth(
        this.form.certificate.expiredDate,
        this.form.certificate.dateIssued
      )
    },
    isCertificateInterm () {
      const months = this.certificateRangeInMonth()

      if (months && months <= 12) return true
      return false
    },
    checkFulltermInterm () {
      this.isFulltermInterm = this.form.certificate &&
        this.form.certificate.certificate.certificateType === 'FULL_TERM' &&
        this.certificateRangeInMonth() > 0 &&
        !this.isCertificateInterm()
    },
    addEndorsementDocument () {
      const endorsementDocuments = this.form.certificate.endorsementDocuments

      endorsementDocuments.push({
        endorseNumber: endorsementDocuments.length + 1,
        rangeEndorsement: ''
      })
    },
    deleteEndorsementDocument (index) {
      this.form.certificate.endorsementDocuments.splice(index, 1)
    },
    getEndorsementDocument () {
      let endorsementDocuments = []
      this.form.certificate.endorsementDocuments.forEach((endorsement) => {
        endorsementDocuments.push({
          endorseNumber: endorsement.endorseNumber,
          startEndorseDate: this.$options.filters.parseDateEndorsement(
            new Date(endorsement.rangeEndorsement[0])
          ),
          endEndorseDate: this.$options.filters.parseDateEndorsement(
            new Date(endorsement.rangeEndorsement[1])
          )
        })
      })

      return endorsementDocuments
    },
    toggleModalDetail (show, certificate = {}) {
      if (show) {
        this.setModalDetail({ show, certificate })
      } else {
        this.setModalDetail({ show, certificate: {} })
      }
    },
    toggleModalLog (show, certificate = {}) {
      if (show) {
        this.setModalLog({ show, certificate })
      } else {
        this.setModalLog({ show, certificate: {} })
      }
    },
    toggleModalEndorsement (show, certificate = {}) {
      if (show) {
        this.setModalEndorsement({ show, certificate })
      } else {
        this.setModalEndorsement({ show, certificate: {} })
      }
    },
    toggleModalRenewal (show, certificate = {}) {
      if (show) {
        this.setModalRenewal({ show, certificate })
      } else {
        this.setModalRenewal({ show, certificate: {} })
      }
    },
    onAddCertificateVehicle () {
      this.resetFormCertificate()

      if (this.vehicleIdParam) {
        this.form.certificate.vehicleId = parseInt(this.vehicleIdParam)
        this.fetchFleetUsers()
      }
      this.show_add_modal = true
    },
    menuPermission (output) {
      // const { menuObject } = this.$route.query
      // console.log('permis', JSON.parse(window.atob(menuObject))[output])
      // return JSON.parse(window.atob(menuObject))[output]
      if (this.lsProfile) {
        const menuObject = this.lsProfile.menu[1].child[0].child[0]
        return menuObject[output]
      }
    }
  }
}
</script>
<style scoped>
table th{
  background: #334697 !important;
  color: white;
}
</style>
<template>
  <div>
    <b-row v-if="incident && !isLoading">
        <b-col md="12" class="d-flex justify-content-end">
        <b-button variant="primary" @click="isEditMode = !isEditMode"
            >{{ !isEditMode ? 'Update' : 'Preview' }} Management Approval</b-button
        >
        </b-col>
        <b-col md="12" class="px-4">
        <b-row>
            <ComponentClaimStep
            :incident="incident"
            readonly
            :isEditMode="isEditMode"
            :companies="companies"
            :vehicles="[
                {
                value: incident.vehicle.id,
                label: incident.vehicle.name,
                },
            ]"
            :departments="[
                {
                value: incident.department.id,
                label: incident.department.name,
                },
            ]"
            :coverages="[
                {
                value: incident.type_coverage.id,
                label: incident.type_coverage.name,
                },
            ]"
            :losses="[
                {
                value: incident.type_loss.id,
                label: incident.type_loss.name,
                },
            ]"
            />
            <b-col md="12" class="px-4">
            <b-row style="background: #f2f1f1" class="pt-3">
                <b-col md="6">
                <b-form-group label="Approve By">
                    <b-form-input
                    v-if="isEditMode"
                    type="text"
                    v-model="incident.creator_fullname"
                    :readonly="$route.query.state === 'view'"
                    placeholder="Input Name"
                    class="bg-white"
                    ></b-form-input>
                    <h6 v-else style="font-weight: 500">{{
                    incident.creator_fullname
                    }}</h6>
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group label="Date">
                    <date-picker
                    v-if="isEditMode"
                    class="w-100"
                    type="date"
                    v-model="incident.creator_date"
                    :readonly="$route.query.state === 'view'"
                    value-type="YYYY-MM-DD"
                    format="DD MMM YYYY"
                    placeholder="Select date"
                    ></date-picker>
                    <h6 v-else style="font-weight: 500">{{
                    moment(incident.creator_date).format('DD MMM YYYY')
                    }}</h6>
                </b-form-group>
                </b-col>
            </b-row>
            </b-col>
            <template v-if="['MANAGEMENT', 'management'].includes(role)">
            <b-col md="12" class="mt-4">
                <h5 class="card-title text-success"
                ><b>MANAGEMENT APPROVAL AND COMMENT</b></h5
                >
                <b-form-group label="Decribe of Approval">
                <b-form-textarea
                    id="exampleFormControlTextarea1"
                    v-model="incident.approver_description"
                    :readonly="$route.query.state === 'view'"
                    rows="3"
                ></b-form-textarea>
                </b-form-group>
            </b-col>
            <b-col md="6" class="mt-4">
                <hr />
                <b-form-input
                type="text"
                v-model="incident.approver_fullname"
                :readonly="$route.query.state === 'view'"
                placeholder="Input Name"
                ></b-form-input>
            </b-col>
            <b-col md="6" class="mt-4">
                <hr />
                <span class="mr-5"><strong>Date</strong></span
                ><span class="ml-5"
                ><date-picker
                    type="date"
                    v-model="incident.approver_date"
                    :readonly="$route.query.state === 'view'"
                    value-type="YYYY-MM-DD"
                    format="DD MMM YYYY"
                    placeholder="Select date"
                ></date-picker
                ></span>
            </b-col>
            </template>
            <template v-if="isEditMode">
            <b-col
                cols="12"
                md="12"
                class="pull-right text-center mt-5"
                v-if="$route.query.state !== 'view'"
            >
                <b-form-checkbox class="mb-3" v-model="statementTruth">
                I already read all and I approve this case
                </b-form-checkbox>
            </b-col>
            <b-col
                cols="12"
                md="12"
                class="pull-right text-right mt-2"
                v-if="$route.query.state !== 'view'"
            >
                <b-button
                type="submit"
                @click="
                    onSave(
                    incident.creator_fullname,
                    incident.creator_date,
                    incident.approver_description,
                    incident.approver_fullname,
                    incident.approver_date
                    )
                "
                :disabled="!statementTruth"
                variant="primary"
                >Submit</b-button
                >
                <!-- <b-button
                    @click="$router.push({ name: 'mi.listInitial' })"
                    variant="none"
                    class="iq-bg-danger ml-3"
                    >Cancel</b-button
                > -->
            </b-col>
            </template>
            <b-col cols="12" md="12" class="mb-5"></b-col>
        </b-row>
        </b-col>
    </b-row>
    <div v-else class="my-5 py-5 d-flex justify-content-center">
        <b-spinner></b-spinner>
    </div>
  </div>
</template>
<script>
import { miActions } from '@/src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'
import { xray } from '@/src/config/pluginInit'
import ComponentClaimStep from './ComponentClaimStep.vue'
import moment from '@/node_modules/moment-timezone/index'

export default {
  name: 'BlankPage',
  components: { StepNavigation, StepWizard, ComponentClaimStep },
  async mounted() {
    xray.index()
    if (!this.$route.query.formId) {
      this.$router.push({
        name: 'mi.listInitial',
      })
    }
    await this.fetchIncident()
  },
  computed: {
    role() {
      return this.lsProfile ? this.lsProfile.role.roles : ''
    },
    companies() {
      const companies = []
      if (this.incident) {
        if (this.incident.company) {
          companies.push({
            value: this.incident.company.id,
            label: this.incident.company.company,
          })
        }
        if (this.incident.company_claimant) {
          companies.push({
            value: this.incident.company_claimant.id,
            label: this.incident.company_claimant.company,
          })
        }
      }

      return companies
    },
  },
  data() {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      statementTruth: false,
      incident: null,
      isEditMode: false,
      isLoading: false,
    }
  },
  methods: {
    ...miActions,
    async fetchIncident() {
      try {
        this.isLoading = true
        const { data, status } = await this.fetchDetailMi({
          id: this.$route.query.formId,
          with: 'type_loss,vehicle,type_coverage,department,company,claim_analysis,company_claimant',
        })
        if (status === 'success') {
          this.incident = data
          this.incident.department_id = parseInt(data.department_id, 10)
          this.incident.conclusion = data.claim_analysis
            ? data.claim_analysis.conclusion
            : data.conclusion
        } else {
          this.$router.push({
            name: 'mi.listInitial',
          })
        }
      } catch (error) {
        console.log(error);
      }finally{
        this.isLoading = false
        
      }
    },
    // eslint-disable-next-line camelcase
    async onSave(
      creator_fullname,
      creator_date,
      approver_description,
      approver_fullname,
      approver_date
    ) {
      const payload = {
        id: this.$route.query.formId,
        creator_fullname,
        creator_date,
        approver_description,
        approver_fullname,
        approver_date,
      }

      const { status } = await this.updateMi(payload)

      if (status !== 'success') {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.form._saving = false
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')
      setTimeout(() => {
        this.$router.push({ name: 'mi.listInitial' })
      }, 1500)
    },
  },
}
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.saving,
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "POL",
      "label-for": "pol"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "track-by": "value",
      "options": _vm.listPorts,
      "reduce": function reduce(listPorts) {
        return listPorts.value;
      },
      "placeholder": "Select...",
      "required": ""
    },
    model: {
      value: _vm.form.pol_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pol_id", $$v);
      },
      expression: "form.pol_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "POD",
      "label-for": "pod"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "track-by": "value",
      "options": _vm.listPorts,
      "reduce": function reduce(listPorts) {
        return listPorts.value;
      },
      "placeholder": "Select...",
      "required": ""
    },
    model: {
      value: _vm.form.pod_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pod_id", $$v);
      },
      expression: "form.pod_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Route ID",
      "label-for": "route_code"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "route_code",
      "type": "text",
      "placeholder": "Type text",
      "required": ""
    },
    model: {
      value: _vm.form.route_code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "route_code", $$v);
      },
      expression: "form.route_code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Route Name",
      "label-for": "route_name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "route_name",
      "type": "text",
      "placeholder": "Type text",
      "required": ""
    },
    model: {
      value: _vm.form.route_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "route_name", $$v);
      },
      expression: "form.route_name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type",
      "label-for": "type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "type",
      "plain": "",
      "size": "md",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select...")]), _c('b-form-select-option', {
          attrs: {
            "value": 'POL'
          }
        }, [_vm._v("POL")]), _c('b-form-select-option', {
          attrs: {
            "value": 'POD'
          }
        }, [_vm._v("POD")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Streaming Time",
      "label-for": "streaming_time"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "streaming_time",
      "type": "number",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.form.streaming_time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "streaming_time", $$v);
      },
      expression: "form.streaming_time"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Distance",
      "label-for": "distance"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "distance",
      "type": "number",
      "placeholder": "Type number"
    },
    model: {
      value: _vm.form.distance,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distance", $$v);
      },
      expression: "form.distance"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Speed",
      "label-for": "speed"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "speed",
      "type": "number",
      "placeholder": "Type number"
    },
    model: {
      value: _vm.form.speed,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "speed", $$v);
      },
      expression: "form.speed"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ltrs",
      "label-for": "ltrs"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "ltrs",
      "type": "number",
      "placeholder": "Type number"
    },
    model: {
      value: _vm.form.ltrs,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ltrs", $$v);
      },
      expression: "form.ltrs"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Amount",
      "label-for": "amount"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "amount",
      "type": "number",
      "placeholder": "Type number"
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$detailMi, _vm$detailMi$recommen, _vm$detailMi2, _vm$detailMi2$recomme, _vm$detailMi3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-badge', {
    staticClass: "ml-2",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleOpenModalEmail('Recommendation', _vm.detailMi.id);
      }
    }
  }, [_vm._v("Send Email Notification")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateRecommendation(_vm.detailMi.id);
      }
    }
  }, [_vm._v(" View Recommendation ")]) : _vm.canAddRecommendation || _vm.canEditRecommendation ? _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateRecommendation(_vm.detailMi.id);
      }
    }
  }, [_vm._v(" " + _vm._s(!((_vm$detailMi = _vm.detailMi) !== null && _vm$detailMi !== void 0 && (_vm$detailMi$recommen = _vm$detailMi.recommendations) !== null && _vm$detailMi$recommen !== void 0 && _vm$detailMi$recommen.length) ? 'Input' : 'Edit') + " Recommendation ")]) : _vm._e()], 1)], 1), (_vm$detailMi2 = _vm.detailMi) !== null && _vm$detailMi2 !== void 0 && (_vm$detailMi2$recomme = _vm$detailMi2.recommendations) !== null && _vm$detailMi2$recomme !== void 0 && _vm$detailMi2$recomme.length ? _c('b-row', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Problem")]), _c('b-th', [_vm._v("Vessel Case")]), _c('b-th', [_vm._v("Root Cause")]), _c('b-th', [_vm._v("Recommendation")]), _c('b-th', [_vm._v("Status")])], 1), _vm._l((_vm$detailMi3 = _vm.detailMi) === null || _vm$detailMi3 === void 0 ? void 0 : _vm$detailMi3.recommendations, function (recommendation) {
    return _c('b-tbody', {
      key: recommendation.id
    }, [_c('b-tr', [_c('b-td', [_vm._v(_vm._s(recommendation.problem))]), _c('b-td', [_vm._v(_vm._s(recommendation.vessel_case))]), _c('b-td', [_vm._v(_vm._s(recommendation.root_cause))]), _c('b-td', [_vm._v(_vm._s(recommendation.recommendation))]), _c('b-td', [_vm._v(_vm._s(recommendation.status))])], 1)], 1);
  })], 2)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-row class="mt-4">
    <b-col md="7">
      <div class="d-flex align-items-center rounded shadow-sm p-4">
        <div class="w-50 border rounded mr-4">
          <img
            :src="require('../../../assets/images/example-fleets.png')"
            alt="images"
            style="width:100%; height: 265px;"
          />
        </div>
        <div class="w-50">
          <div class="d-flex">
            <!-- icon -->
            <div class="mr-2 mb-2">
              <IconFleet />
            </div>
            <div class="d-flex flex-column align-items-start">
              <strong class="text-primary">TB. TCP 401 🇮🇩</strong>
              <span>PT. Energy Transporter Indonesia</span>
            </div>
          </div>
          <div class="d-flex">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>Fleet Type</td>
                  <th>Tugboat</th>
                </tr>
                <tr>
                  <td>Call Sign</td>
                  <th>ETI</th>
                </tr>
                <tr>
                  <td>Flag</td>
                  <th>Indonesia</th>
                </tr>
                <tr>
                  <td>Year & Building</td>
                  <th>2015 / Singapore</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-col>
    <b-col md="5">
      <div class="rounded shadow-sm p-4">
        <div class="d-flex">
          <!-- icon -->
          <div class="mr-2 mb-2">
            <ClockIcon />
          </div>
          <div class="d-flex w-100 align-items-center justify-content-between">
            <div class="d-flex flex-column align-items-start mr-3">
              <strong class="text-black">Total Running</strong>
              <span class="text-black">Last Updated :<strong> 15 January 2024</strong></span>
            </div>
            <div class="d-flex">
              <b-button block variant="warning" class="d-flex align-items-center m-0">
                <i class="fa fa-clock mr-1" />
                Detail RH
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>Main Engine Portside</td>
                <th>1.550 Hours</th>
              </tr>
              <tr>
                <td>Main Engine Starboard</td>
                <th>1.550 Hours</th>
              </tr>
              <tr>
                <td>Auxiliary Engine Portside</td>
                <th>1.550 Hours</th>
              </tr>
              <tr>
                <td>Auxiliary Engine Starboard</td>
                <th>1.550 Hours</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-col>

    <b-col md="12" class="mt-4">
      <div class="p-3 rounded" style="background-color: #4044AC">
        <b-row>
          <b-col md="6">
            <div class="d-flex rounded p-3" style="background-color: #ffffff">
              <div class="p-2 mr-2 rounded-lg" style="background-color: #334697">
                <FleetIcon fill="white" stroke="white"  />
              </div>
              <div class="d-flex flex-column">
                <strong class="text-black">140</strong>
                <span class="text-black">Total Job Maintenance Plan / Initiation</span>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div class="d-flex rounded p-3" style="background-color: #ffffff">
              <div class="p-2 mr-2 rounded-lg" style="background-color: #3C5DE7">
                <PaperIcon fill="white" stroke="white"  />
              </div>
              <div class="d-flex flex-column">
                <strong class="text-black">120</strong>
                <span class="text-black">Equipment Maintenance Plan / Initiation</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <b-col md="12" class="mt-4">
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Period"
            label-for="period"
          >
            <date-picker type="date" class="w-100" range placeholder="Select date range"></date-picker>
          </b-form-group>
         
        </b-col>
        <b-col md="2">
          <b-form-group label="Trigger" label-for="trigger">
            <b-form-select  
              plain
              size="sm"
              placeholder="Select..."
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <option value="">All</option>
              <option value="owned">Owned by Company</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Filter Type"
            label-for="filter_type"
          >
            <template v-for="(item, index) in typeOptions">
              <b-form-radio
                class="custom-radio-color-checked"
                inline
                :color="item.color"
                name="color"
                :key="index"
                :value="item.value"
                :disabled="item.disabled"
                required
              >
                <span>{{ item.label }}</span>
              </b-form-radio>
            </template>
          </b-form-group> 
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Job Type"
            label-for="job-type"
          >
            <v-select
              label="text"
              :options="[]"
              placeholder="Select..."
            />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <button type="button" class="btn" :style="{ backgroundColor: '#2BDCAA', color: 'white', marginTop:'30px' }" block><i class="fa fa-search" /></button>
        </b-col>
      </b-row>

      <div class="d-flex w-100 border my-4" />

      <fieldset class="form-group border p-3 mb-2">
        <legend class="w-auto px-2 text-primary text-sm">Summary Board</legend>
        <b-row>
          <b-col md="4">
            <div class="d-flex rounded p-3  border shadow" style="background-color: #ffffff">
              <div class="p-2 mr-2 rounded-lg" style="background-color: #334697">
                <PaperIcon fill="white" stroke="white"  />
              </div>
              <div class="d-flex flex-column">
                <strong class="text-black">20</strong>
                <span class="text-black">Active</span>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <div class="d-flex rounded p-3  border shadow" style="background-color: #ffffff">
              <div class="p-2 mr-2 rounded-lg" style="background-color: #ffd3A0">
                <FleetIcon fill="black" stroke="black"  />
              </div>
              <div class="d-flex flex-column">
                <strong class="text-black">5</strong>
                <span class="text-black">Postponed Maintenance</span>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <div class="d-flex rounded p-3  border shadow" style="background-color: #ffffff">
              <div class="p-2 mr-2 rounded-lg" style="background-color: #2bdcaa">
                <PaperIcon fill="white" stroke="white"  />
              </div>
              <div class="d-flex flex-column">
                <strong class="text-black">20</strong>
                <span class="text-black">Finished Maintenance</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </fieldset>


      <fieldset class="form-group border p-3 mb-2">
        <legend class="w-auto px-2 text-primary  text-sm">Active Maintenance</legend>
        <b-row>
          <b-col md="4">
            <div class="d-flex rounded p-3  border shadow" style="background-color: #ffffff">
              <div class="p-2 mr-2 rounded-lg" style="background-color: #71ffd7">
                <FleetIcon fill="black" stroke="black"  />
              </div>
              <div class="d-flex flex-column">
                <strong class="text-black">54</strong>
                <span class="text-black">Toward Maintenance</span>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <div class="d-flex rounded p-3  border shadow" style="background-color: #ffffff">
              <div class="p-2 mr-2 rounded-lg" style="background-color: #ffd1d1">
                <FleetIcon fill="black" stroke="black"  />
              </div>
              <div class="d-flex flex-column">
                <strong class="text-black">15</strong>
                <span class="text-black">Overdue Maintenance</span>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <div class="d-flex rounded p-3  border shadow" style="background-color: #ffffff">
              <div class="p-2 mr-2 rounded-lg" style="background-color: #b9d1ff">
                <FleetIcon fill="black" stroke="black"  />
              </div>
              <div class="d-flex flex-column">
                <strong class="text-black">32</strong>
                <span class="text-black">Next 3 Month</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </fieldset>

      <div class="d-flex w-100 border my-4" />

      <div class="d-flex w-100 align-items-center justify-content-between mb-4">
        <b-button
          variant="outline-primary"
          class="d-flex align-items-center"
        >
          <i class="fa fa-file-excel"></i>
          <p class="text-truncate m-0"> Export </p>
        </b-button>

        <div class="d-flex align-items-center">
          <span class="text-black mr-2">View :</span>
          <b-button
            :variant="selectedView === 'list' ? 'primary' : `outline-primary`"
            class="d-flex align-items-center mr-2"
            @click="selectedView = 'list'"
          >
            <i class="fa fa-bars"></i>
            <p class="text-truncate m-0"> Job List Data </p>
          </b-button>
          <b-button
            :variant="selectedView === 'group' ? 'primary' : `outline-primary`"
            class="d-flex align-items-center"
            @click="selectedView = 'group'"
          >
            <i class="fa fa-layer-group"></i>
            <p class="text-truncate m-0"> Group By Component </p>
          </b-button>
        </div>
      </div>

      <div v-if="selectedView === 'list'" class="d-flex flex-column w-100 overflow-auto" style="max-height: 400px">
        <table class="table table-striped">
          <thead style="background: #334697; color: #fff;">
            <th style="width: 5px;">No</th>
            <th class="text-truncate">Job Name</th>
            <th class="text-truncate">Equipment Name</th>
            <th class="text-truncate">Status</th>
            <th class="text-truncate">Finish</th>
            <th class="text-truncate">Postponed</th>
            <th class="text-truncate">Last Maintenance</th>
            <th class="text-truncate">Frequency</th>
            <th class="text-truncate">Due Date / Hours</th>
            <th class="text-truncate">Job Type</th>
            <th class="text-truncate">History Data</th>
          </thead>  
          <tbody>
            <tr>
              <td style="width: 5px;">1</td>
              <td class="text-truncate text-primary">Lubricating Oil Cooler - TB. TCP 401</td>
              <td class="text-truncate text-primary">Main Engine 1 - Lubricating Oil Cooler</td>
              <td class="text-truncate">
                <b-badge variant="danger" class="p-2">
                  <span>Overdue / 8436 RH</span>
                </b-badge>
              </td>
              <th class="text-truncate text-center">45</th>
              <th class="text-truncate text-center">3</th>
              <td class="text-truncate">01 Jul 2024 | 280 Hours</td>
              <td class="text-truncate">300 Hours</td>
              <td class="text-truncate">580 Hours</td>
              <td class="text-truncate">Overhaul</td>
              <td class="text-truncate">
                <b-button
                  variant="outline-success"
                  class="d-flex align-items-center"
                  @click="viewHistoryRecordDetail = true"
                >
                  <i class="fa fa-pen"></i>
                  <p class="text-truncate m-0"> Detail Data </p>
                </b-button>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">2</td>
              <td class="text-truncate text-primary">Lubricating Oil Cooler - TB. TCP 401</td>
              <td class="text-truncate text-primary">Main Engine 2 - Lubricating Oil Cooler</td>
              <td class="text-truncate">
                <b-badge variant="success" class="p-2">
                  <span>Active</span>
                </b-badge>
              </td>
              <th class="text-truncate text-center">45</th>
              <th class="text-truncate text-center">0</th>
              <td class="text-truncate">01 Jul 2024</td>
              <td class="text-truncate">3 Month</td>
              <td class="text-truncate">01 Oktober 2024</td>
              <td class="text-truncate">Replace</td>
              <td class="text-truncate">
                <b-button
                  variant="outline-success"
                  class="d-flex align-items-center"
                  @click="viewHistoryRecordDetail = true"
                >
                  <i class="fa fa-pen"></i>
                  <p class="text-truncate m-0"> Detail Data </p>
                </b-button>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">3</td>
              <td class="text-truncate text-primary">Emergency/Extra Battery - TB. TCP 401</td>
              <td class="text-truncate text-primary">Emergency/Extra Battery</td>
              <td class="text-truncate">
                <b-badge variant="info" class="p-2">
                  <span>Initiation</span>
                </b-badge>
              </td>
              <th class="text-truncate text-center">0</th>
              <th class="text-truncate text-center">0</th>
              <td class="text-truncate">-</td>
              <td class="text-truncate">1 Month</td>
              <td class="text-truncate">01 Oktober 2024</td>
              <td class="text-truncate">Greasing</td>
              <td class="text-truncate">
                <b-button
                  variant="outline-success"
                  class="d-flex align-items-center"
                  @click="viewHistoryRecordDetail = true"
                >
                  <i class="fa fa-pen"></i>
                  <p class="text-truncate m-0"> Detail Data </p>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="selectedView === 'group'" class="d-flex flex-column w-100 overflow-auto" style="max-height: 400px">
        <table class="table table-striped">
          <thead style="background: #334697; color: #fff;">
            <th style="width: 5px;">No</th>
            <th class="text-truncate">Equipment Name</th>
            <th class="text-truncate text-center">Job Data</th>
            <th class="text-truncate text-center">Postponed</th>
            <th class="text-truncate text-center">Repair Handling</th>
            <th class="text-truncate text-center">Finish</th>
            <th class="text-truncate">History Data</th>
          </thead>  
          <tbody>
            <tr>
              <td style="width: 5px;">1</td>
              <td class="text-truncate text-primary">Main Engine 1 - Lubricating Oil Cooler</td>
              <td class="text-truncate text-center">
                <b-badge variant="primary" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="warning" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="dark" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="success" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate">
                <b-button
                  variant="outline-success"
                  class="d-flex align-items-center"
                  @click="viewHistoryRecordDetail = true"
                >
                  <i class="fa fa-pen"></i>
                  <p class="text-truncate m-0"> Detail Data </p>
                </b-button>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">2</td>
              <td class="text-truncate text-primary">Main Engine 2 - Lubricating Oil Cooler</td>
              <td class="text-truncate text-center">
                <b-badge variant="primary" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="warning" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="dark" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="success" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate">
                <b-button
                  variant="outline-success"
                  class="d-flex align-items-center"
                  @click="viewHistoryRecordDetail = true"
                >
                  <i class="fa fa-pen"></i>
                  <p class="text-truncate m-0"> Detail Data </p>
                </b-button>
              </td>
            </tr>
            <tr>
              <td style="width: 5px;">3</td>
              <td class="text-truncate text-primary">Emergency/Extra Battery</td>
              <td class="text-truncate text-center">
                <b-badge variant="primary" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="warning" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="dark" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate text-center">
                <b-badge variant="success" class="p-2">
                  <span>10</span>
                </b-badge>
              </td>
              <td class="text-truncate">
                <b-button
                  variant="outline-success"
                  class="d-flex align-items-center"
                  @click="viewHistoryRecordDetail = true"
                >
                  <i class="fa fa-pen"></i>
                  <p class="text-truncate m-0"> Detail Data </p>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-row class="mt-4">
        <b-col col-6>
          Total: 1 &nbsp;
          | &nbsp; Per page:
          20
        </b-col>
        <b-col col-6>
          <b-pagination
            :total-rows="1"
            :per-page="20"
            align="right"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-col>

    <!-- FILTER MODAL -->
    <b-col md="12">
      <b-sidebar v-model="viewHistoryRecordDetail" right width="100%" bg-variant="white" style="background-color:#F3F6F7" title="Maintenance History Record - TB. TCP 401">
        <HistoryRecordDetail />
      </b-sidebar>
    </b-col>
  </b-row>
</template>

<script>
import {
  fleetsActions,
  maintenanceActions,
  filesActions,
} from '@src/Utils/helper'
import _ from 'lodash'
import IconFleet from './Icons/IconFleet'
import ClockIcon from './Icons/ClockIcon'
import FleetIcon from './Icons/FleetIcon'
import PaperIcon from './Icons/PaperIcon'
import HistoryRecordDetail from './HistoryRecordDetail'

export default {
  name: 'MaintenanceHistoryRecordDetail',
  components: {
    HistoryRecordDetail,
    IconFleet,
    ClockIcon,
    FleetIcon,
    PaperIcon
  },
  data() {
    return {
      typeOptions: [{
        label: 'None',
        color: 'dark',
        value: 'none',
      },
      {
        label: 'Job Class',
        color: 'warning',
        value: '1',
      },
      {
        label: 'Job Type',
        color: 'danger',
        value: '2',
      },{
        label: 'Maintenance Name',
        color: 'primary',
        value: '3',
      }],

      viewHistoryRecordDetail: false,

      selectedView: 'list', // list, group
    }
  },
  computed: {

  },
  watch: {
   
  },
  methods: {

  },
  created() {
  },
  async mounted() {
  },
}
</script>

<style>
.editr--content ul {
  list-style: circle;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-modal', {
    attrs: {
      "centered": "",
      "ok-title": "Filter",
      "title": "Filter Data"
    },
    on: {
      "ok": function ok($event) {
        return _vm.fetchLogs(1);
      }
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Date Range",
      "label-for": "dateRange",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "date",
      "range": "",
      "placeholder": "Select date range",
      "format": "DD-MM-YYYY"
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Role",
      "label-for": "role",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "roles",
      "options": _vm.roles,
      "reduce": function reduce(role) {
        return role.id;
      },
      "placeholder": "Select Role"
    },
    model: {
      value: _vm.params.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "roleId", $$v);
      },
      expression: "params.roleId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "User",
      "label-for": "user",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "multiple": "",
      "getOptionLabel": function getOptionLabel(user) {
        return user.userDetail.name;
      },
      "options": _vm.users,
      "placeholder": "Select User"
    },
    on: {
      "input": _vm.onChangeUsers
    },
    model: {
      value: _vm.userFilters,
      callback: function callback($$v) {
        _vm.userFilters = $$v;
      },
      expression: "userFilters"
    }
  })], 1)], 1)], 1)], 1), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Log History Users")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between mb-3"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.toggleModalFilter
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data")])])], 1), _c('div', [_vm.findMenuPermission(1, 'export', _vm.$route.query.menuId) ? _c('div', [_c('button', {
          staticClass: "btn btn-success",
          attrs: {
            "disabled": _vm.export_loading
          },
          on: {
            "click": _vm.onExportLogs
          }
        }, [_vm.export_loading ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('div', {
          staticClass: "d-flex gap-2 align-items-center"
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _c('span', [_vm._v("Export")])])])]) : _vm._e()])]), _c('div', {
          staticClass: "px-3 mb-3"
        }, [_c('b-row', {
          staticClass: "border py-3"
        }, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-0",
          attrs: {
            "label": "Date Range"
          }
        }, [_c('p', {
          staticClass: "m-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(_vm.moment(_vm.dateRange[0]).format('DD MMM YYYY')) + "-" + _vm._s(_vm.moment(_vm.dateRange[1]).format('DD MMM YYYY')))])])], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-0",
          attrs: {
            "label": "Role"
          }
        }, [_c('p', {
          staticClass: "m-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(_vm.params.roleId ? _vm.roles.find(function (el) {
          return el.id == _vm.params.roleId;
        }).roles : 'All'))])])], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_vm.userFilters.length ? _c('b-form-group', {
          staticClass: "mb-0",
          attrs: {
            "label": "User"
          }
        }, _vm._l(_vm.userFilters, function (item, index) {
          return _c('b-badge', {
            key: index,
            staticClass: "mr-1",
            attrs: {
              "variant": "primary"
            }
          }, [_vm._v(_vm._s(item.userDetail.name))]);
        }), 1) : _vm._e()], 1)], 1)], 1), _vm.loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.logs.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("User")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Role")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Aktivitas")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("IP/Browser")])])]), _c('tbody', _vm._l(_vm.logs, function (item, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(item.date ? _vm.moment(item.date, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(item.name))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(item.role))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(item.activity))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(item.ip))])]);
        }), 0)])]) : _c('p', {
          staticClass: "text-center my-5"
        }, [_vm._v("No logs data found.")]), _c('b-pagination', {
          attrs: {
            "total-rows": _vm.meta.total,
            "per-page": _vm.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.fetchLogs
          },
          model: {
            value: _vm.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.meta, "currentPage", $$v);
            },
            expression: "meta.currentPage"
          }
        })]];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL HISTORY - " + _vm._s(_vm.fleet.name))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type",
            "label-for": "type"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "placeholder": "Select type..."
          },
          model: {
            value: _vm.params.type,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "type", $$v);
            },
            expression: "params.type"
          }
        }, [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("All")]), _c('option', {
          attrs: {
            "value": "progress"
          }
        }, [_vm._v("Progress")]), _c('option', {
          attrs: {
            "value": "finish"
          }
        }, [_vm._v("finish")])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Date",
            "label-for": "date"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range",
            "format": "DD-MM-YYYY"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "filter"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "type": "button"
          },
          on: {
            "click": _vm.fetchVoyageByTrips
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column mb-2"
        }, [_vm.list.length > 0 ? _c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center text-truncate align-middle",
          staticStyle: {
            "width": "5px"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("CARGO TYPE")]), _c('th', {
          staticClass: "text-center text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("CARGO LOADING")]), _c('th', {
          staticClass: "text-center text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("CARGO UNLOADING")]), _c('th', {
          staticClass: "text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("START DATE")]), _c('th', {
          staticClass: "text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("END DATE")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "18"
          }
        }, [_vm._v("Port of Loading")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "19"
          }
        }, [_vm._v("Port of Discharge")]), _c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "5px",
            "position": "sticky",
            "right": "0",
            "z-index": "1"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Action")])]), _c('tr', [_c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Agen POL")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("POL Grab Type")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("POL FC Name")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("POL MV Name")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Arival Time POL")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("BL Date")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("NOR")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("NOA")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Anchor Up")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("In Position / Berthing")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Commenced")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Completed")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Draught Survey")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Cast In")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Cast Out")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("LHV")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Document on Board")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Departure to POD")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Agen POD")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("POD Grab Type")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("POD FC Name")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("POD MV Name")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Arival Time POD")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("BL Date")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("NOR")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("NOA")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Anchor Up")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("In Position / Berthing")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Commenced")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Completed")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Draught Survey")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Cast In")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Cast Out")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("LHV")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Document on Board")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Departure to POL")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.list, function (state, index) {
          var _state$vehicle, _state$tripRecord, _state$tripRecord2, _state$tripRecord$sta, _state$tripRecord3, _state$tripRecord$car, _state$tripRecord4, _state$tripRecord$car2, _state$tripRecord5, _state$tripRecord$car3, _state$tripRecord6, _state$tripRecord7, _state$tripRecord8, _state$tripRecord9, _state$tripRecord10, _state$tripRecord11, _state$tripRecord12, _state$tripRecord13, _state$tripRecord14, _state$tripRecord15, _state$tripRecord16, _state$tripRecord17, _state$tripRecord18, _state$tripRecord19, _state$tripRecord20, _state$tripRecord21, _state$tripRecord22, _state$tripRecord23, _state$tripRecord$pol, _state$tripRecord24, _state$tripRecord25, _state$tripRecord26, _state$tripRecord27, _state$tripRecord28, _state$tripRecord29, _state$tripRecord30, _state$tripRecord31, _state$tripRecord32, _state$tripRecord33, _state$tripRecord34, _state$tripRecord35, _state$tripRecord36, _state$tripRecord37, _state$tripRecord38, _state$tripRecord39, _state$tripRecord40, _state$tripRecord41, _state$tripRecord42, _state$tripRecord43, _state$tripRecord44, _state$tripRecord45, _state$tripRecord46, _state$tripRecord$pod, _state$tripRecord47, _state$tripRecord48, _state$tripRecord49, _state$tripRecord50, _state$tripRecord51, _state$tripRecord52, _state$tripRecord53, _state$tripRecord54, _state$tripRecord55;
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('div', {
            staticClass: "d-flex flex-column align-items-center",
            on: {
              "click": function click($event) {
                return _vm.openSlideDetailRealisasi('PREVIEW', state);
              }
            }
          }, [(state === null || state === void 0 ? void 0 : (_state$vehicle = state.vehicle) === null || _state$vehicle === void 0 ? void 0 : _state$vehicle.length) > 0 ? _vm._l(state === null || state === void 0 ? void 0 : state.vehicle, function (val, index) {
            var _val$name;
            return _c('strong', {
              key: index,
              staticClass: "cursor-pointer text-primary"
            }, [_vm._v(_vm._s((_val$name = val === null || val === void 0 ? void 0 : val.name) !== null && _val$name !== void 0 ? _val$name : '-'))]);
          }) : _vm._e(), _c('b-badge', {
            class: "border text-xl ".concat((state === null || state === void 0 ? void 0 : (_state$tripRecord = state.tripRecord) === null || _state$tripRecord === void 0 ? void 0 : _state$tripRecord.status) === 'Progress' ? 'border-dark text-warning' : (state === null || state === void 0 ? void 0 : (_state$tripRecord2 = state.tripRecord) === null || _state$tripRecord2 === void 0 ? void 0 : _state$tripRecord2.status) === 'Finish' ? 'border-dark text-success' : 'border-dark text-primary', " text-uppercase mr-2"),
            attrs: {
              "variant": "none"
            }
          }, [_vm._v(_vm._s((_state$tripRecord$sta = state === null || state === void 0 ? void 0 : (_state$tripRecord3 = state.tripRecord) === null || _state$tripRecord3 === void 0 ? void 0 : _state$tripRecord3.status) !== null && _state$tripRecord$sta !== void 0 ? _state$tripRecord$sta : '-'))])], 2)]), _c('td', [_vm._v(_vm._s((_state$tripRecord$car = state === null || state === void 0 ? void 0 : (_state$tripRecord4 = state.tripRecord) === null || _state$tripRecord4 === void 0 ? void 0 : _state$tripRecord4.cargoType) !== null && _state$tripRecord$car !== void 0 ? _state$tripRecord$car : '-'))]), _c('td', [_vm._v(_vm._s((_state$tripRecord$car2 = state === null || state === void 0 ? void 0 : (_state$tripRecord5 = state.tripRecord) === null || _state$tripRecord5 === void 0 ? void 0 : _state$tripRecord5.cargoLoading) !== null && _state$tripRecord$car2 !== void 0 ? _state$tripRecord$car2 : '0'))]), _c('td', [_vm._v(_vm._s((_state$tripRecord$car3 = state === null || state === void 0 ? void 0 : (_state$tripRecord6 = state.tripRecord) === null || _state$tripRecord6 === void 0 ? void 0 : _state$tripRecord6.cargoUnloading) !== null && _state$tripRecord$car3 !== void 0 ? _state$tripRecord$car3 : '0'))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord7 = state.tripRecord) !== null && _state$tripRecord7 !== void 0 && _state$tripRecord7.startDate ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord8 = state.tripRecord) === null || _state$tripRecord8 === void 0 ? void 0 : _state$tripRecord8.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord9 = state.tripRecord) !== null && _state$tripRecord9 !== void 0 && _state$tripRecord9.endDate ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord10 = state.tripRecord) === null || _state$tripRecord10 === void 0 ? void 0 : _state$tripRecord10.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.tripRecord.agentPol) || '-'))]), _c('td', {
            staticClass: "text-truncate text-capitalize"
          }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.tripRecord.polGrabType) || '-'))]), _c('td', {
            staticClass: "text-truncate text-capitalize"
          }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.tripRecord.polFcName) || '-'))]), _c('td', {
            staticClass: "text-truncate text-capitalize"
          }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.tripRecord.polMvName) || '-'))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord11 = state.tripRecord) !== null && _state$tripRecord11 !== void 0 && _state$tripRecord11.polArrivalTime ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord12 = state.tripRecord) === null || _state$tripRecord12 === void 0 ? void 0 : _state$tripRecord12.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$tripRecord13 = state.tripRecord) === null || _state$tripRecord13 === void 0 ? void 0 : _state$tripRecord13.polBlDate))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$tripRecord14 = state.tripRecord) === null || _state$tripRecord14 === void 0 ? void 0 : _state$tripRecord14.polNor))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$tripRecord15 = state.tripRecord) === null || _state$tripRecord15 === void 0 ? void 0 : _state$tripRecord15.polNoa))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord16 = state.tripRecord) !== null && _state$tripRecord16 !== void 0 && _state$tripRecord16.polAnchorUp ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord17 = state.tripRecord) === null || _state$tripRecord17 === void 0 ? void 0 : _state$tripRecord17.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord18 = state.tripRecord) !== null && _state$tripRecord18 !== void 0 && _state$tripRecord18.polBerthing ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord19 = state.tripRecord) === null || _state$tripRecord19 === void 0 ? void 0 : _state$tripRecord19.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord20 = state.tripRecord) !== null && _state$tripRecord20 !== void 0 && _state$tripRecord20.polCommenced ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord21 = state.tripRecord) === null || _state$tripRecord21 === void 0 ? void 0 : _state$tripRecord21.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord22 = state.tripRecord) !== null && _state$tripRecord22 !== void 0 && _state$tripRecord22.polCompleted ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord23 = state.tripRecord) === null || _state$tripRecord23 === void 0 ? void 0 : _state$tripRecord23.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s((_state$tripRecord$pol = state === null || state === void 0 ? void 0 : (_state$tripRecord24 = state.tripRecord) === null || _state$tripRecord24 === void 0 ? void 0 : _state$tripRecord24.polDraughtSurvey) !== null && _state$tripRecord$pol !== void 0 ? _state$tripRecord$pol : 0))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$tripRecord25 = state.tripRecord) === null || _state$tripRecord25 === void 0 ? void 0 : _state$tripRecord25.polCastIn))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord26 = state.tripRecord) !== null && _state$tripRecord26 !== void 0 && _state$tripRecord26.polCastOut ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord27 = state.tripRecord) === null || _state$tripRecord27 === void 0 ? void 0 : _state$tripRecord27.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord28 = state.tripRecord) !== null && _state$tripRecord28 !== void 0 && _state$tripRecord28.polLhv ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord29 = state.tripRecord) === null || _state$tripRecord29 === void 0 ? void 0 : _state$tripRecord29.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord30 = state.tripRecord) !== null && _state$tripRecord30 !== void 0 && _state$tripRecord30.polDocOnBoard ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord31 = state.tripRecord) === null || _state$tripRecord31 === void 0 ? void 0 : _state$tripRecord31.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord32 = state.tripRecord) !== null && _state$tripRecord32 !== void 0 && _state$tripRecord32.polDeparture ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord33 = state.tripRecord) === null || _state$tripRecord33 === void 0 ? void 0 : _state$tripRecord33.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.tripRecord.agentPod) || '-'))]), _c('td', {
            staticClass: "text-truncate text-capitalize"
          }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.tripRecord.podGrabType) || '-'))]), _c('td', {
            staticClass: "text-truncate text-capitalize"
          }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.tripRecord.podFcName) || '-'))]), _c('td', {
            staticClass: "text-truncate text-capitalize"
          }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.tripRecord.podMvName) || '-'))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord34 = state.tripRecord) !== null && _state$tripRecord34 !== void 0 && _state$tripRecord34.podArrivalTime ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord35 = state.tripRecord) === null || _state$tripRecord35 === void 0 ? void 0 : _state$tripRecord35.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$tripRecord36 = state.tripRecord) === null || _state$tripRecord36 === void 0 ? void 0 : _state$tripRecord36.podBlDate))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$tripRecord37 = state.tripRecord) === null || _state$tripRecord37 === void 0 ? void 0 : _state$tripRecord37.podNor))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$tripRecord38 = state.tripRecord) === null || _state$tripRecord38 === void 0 ? void 0 : _state$tripRecord38.podNoa))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord39 = state.tripRecord) !== null && _state$tripRecord39 !== void 0 && _state$tripRecord39.podAnchorUp ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord40 = state.tripRecord) === null || _state$tripRecord40 === void 0 ? void 0 : _state$tripRecord40.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord41 = state.tripRecord) !== null && _state$tripRecord41 !== void 0 && _state$tripRecord41.podBerthing ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord42 = state.tripRecord) === null || _state$tripRecord42 === void 0 ? void 0 : _state$tripRecord42.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord43 = state.tripRecord) !== null && _state$tripRecord43 !== void 0 && _state$tripRecord43.podCommenced ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord44 = state.tripRecord) === null || _state$tripRecord44 === void 0 ? void 0 : _state$tripRecord44.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord45 = state.tripRecord) !== null && _state$tripRecord45 !== void 0 && _state$tripRecord45.podCompleted ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord46 = state.tripRecord) === null || _state$tripRecord46 === void 0 ? void 0 : _state$tripRecord46.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s((_state$tripRecord$pod = state === null || state === void 0 ? void 0 : (_state$tripRecord47 = state.tripRecord) === null || _state$tripRecord47 === void 0 ? void 0 : _state$tripRecord47.podDraughtSurvey) !== null && _state$tripRecord$pod !== void 0 ? _state$tripRecord$pod : 0))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v("-")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord48 = state.tripRecord) !== null && _state$tripRecord48 !== void 0 && _state$tripRecord48.podCastOut ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord49 = state.tripRecord) === null || _state$tripRecord49 === void 0 ? void 0 : _state$tripRecord49.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord50 = state.tripRecord) !== null && _state$tripRecord50 !== void 0 && _state$tripRecord50.podLhv ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord51 = state.tripRecord) === null || _state$tripRecord51 === void 0 ? void 0 : _state$tripRecord51.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord52 = state.tripRecord) !== null && _state$tripRecord52 !== void 0 && _state$tripRecord52.podDocOnBoard ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord53 = state.tripRecord) === null || _state$tripRecord53 === void 0 ? void 0 : _state$tripRecord53.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && (_state$tripRecord54 = state.tripRecord) !== null && _state$tripRecord54 !== void 0 && _state$tripRecord54.podDeparture ? _vm.moment(state === null || state === void 0 ? void 0 : (_state$tripRecord55 = state.tripRecord) === null || _state$tripRecord55 === void 0 ? void 0 : _state$tripRecord55.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v("-")]), _c('td', {
            staticClass: "text-center align-middle",
            staticStyle: {
              "width": "5px",
              "position": "sticky",
              "right": "0",
              "z-index": "1"
            }
          }, [_c('b-dropdown', {
            attrs: {
              "size": "sm",
              "variant": "dark",
              "toggle-class": "text-decoration-none",
              "text": "Actions",
              "dropright": "",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_vm._v(" Actions "), _c('i', {
                  staticClass: "fa fa-chevron-down ml-1"
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideDetailRealisasi('PREVIEW', state);
              }
            }
          }, [_c('i', {
            staticClass: "fas fa-eye mr-2"
          }), _vm._v(" Detail ")]), _c('b-dropdown-item', {
            attrs: {
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideEditOperational('EDIT', state);
              }
            }
          }, [_c('i', {
            staticClass: "fas fa-arrow-circle-down mr-2"
          }), _vm._v(" Edit ")]), !(state !== null && state !== void 0 && state.activities) ? _c('b-dropdown-divider') : _vm._e(), !(state !== null && state !== void 0 && state.activities) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteTripRecord(state === null || state === void 0 ? void 0 : state.tripRecord.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash mr-2"
          }), _vm._v(" Remove ")]) : _vm._e()], 1)], 1)]);
        }), 0)])]) : _vm._e()])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "md": "12"
          }
        }, [_vm.list.length > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": 1,
            "align": "center"
          },
          model: {
            value: _vm.page,
            callback: function callback($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1) : _c('strong', {
          staticClass: "text-center text-muted"
        }, [_vm._v("Data not found.")])])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideDetailRealisasi",
      "title": "Detail Real Shipment",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('SlideDetailRealisasi', {
          attrs: {
            "id": _vm.id,
            "closeSlideDetailRealisasi": _vm.closeSlideDetailRealisasi
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideDetailRealisasi,
      callback: function callback($$v) {
        _vm.slideDetailRealisasi = $$v;
      },
      expression: "slideDetailRealisasi"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideEditOperational",
      "title": "Edit Operational",
      "width": "80%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('SlideEditOperational', {
          attrs: {
            "id": _vm.id,
            "closeSlideEditOperational": _vm.closeSlideEditOperational
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideEditOperational,
      callback: function callback($$v) {
        _vm.slideEditOperational = $$v;
      },
      expression: "slideEditOperational"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
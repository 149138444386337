var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-2 px-3"
  }, [_vm._l(_vm.itemActions, function (item, index) {
    var _vm$fleet;
    return [item.permission ? _c('b-button', {
      key: index,
      staticClass: "text-truncate w-100 mb-1 d-flex justify-content-start align-items-center",
      attrs: {
        "variant": 'outline-' + item.variant
      },
      on: {
        "click": item.action
      }
    }, [_c('i', {
      class: item.icon
    }), _vm._v(" " + _vm._s(item.label) + _vm._s((_vm$fleet = _vm.fleet) === null || _vm$fleet === void 0 ? void 0 : _vm$fleet.unscheduledDocking.includes('SPECIAL')) + " ")]) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$maintenance$data;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', _vm._l(_vm.columns, function (col, index) {
    var _col$label;
    return _c('th', {
      key: index,
      staticClass: "text-truncate",
      class: col.style,
      staticStyle: {
        "background-color": "#334697",
        "color": "white"
      }
    }, [_vm._v(_vm._s((_col$label = col.label) !== null && _col$label !== void 0 ? _col$label : col))]);
  }), 0)]), _c('tbody', _vm._l(_vm.maintenance.data, function (job, index) {
    var _job$jobName, _job$equipmentName, _job$jobClass, _job$trigger, _job$interval, _job$lastMaintenanceH;
    return _c('tr', {
      key: index,
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_c('td', {
      staticClass: "text-center sticky-column column-1",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-truncate text-left sticky-column column-2",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('viewDetailMaintenance', {
            id: job.id,
            type: 'VIEW_DETAIL'
          });
        }
      }
    }, [_vm._v(_vm._s(job.vehicleName))])]), _c('td', {
      staticClass: "text-truncate text-left sticky-column column-3",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_vm._v(_vm._s((_job$jobName = job.jobName) !== null && _job$jobName !== void 0 ? _job$jobName : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$equipmentName = job.equipmentName) !== null && _job$equipmentName !== void 0 ? _job$equipmentName : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$jobClass = job.jobClass) !== null && _job$jobClass !== void 0 ? _job$jobClass : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$trigger = job.trigger) !== null && _job$trigger !== void 0 ? _job$trigger : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$interval = job.interval) !== null && _job$interval !== void 0 ? _job$interval : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s(job.lastMaintenanceDate ? _vm.moment(job.lastMaintenanceDate, 'DD-MM-YYYY').format('DD MMM YYYY') : '-') + " "), job.lastMaintenanceHours ? _c('span', [_vm._v("|")]) : _vm._e(), _vm._v(" " + _vm._s((_job$lastMaintenanceH = job.lastMaintenanceHours) !== null && _job$lastMaintenanceH !== void 0 ? _job$lastMaintenanceH : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s(job.dueDate ? _vm.moment(job.dueDate, 'DD-MM-YYYY').locale('id').format('DD MMMM YYYY') : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_c('a', {
      staticClass: "p-1 d-flex align-items-center px-2 rounded-sm",
      staticStyle: {
        "background-color": "#eceeed"
      },
      attrs: {
        "href": "#"
      }
    }, [_c('i', {
      staticClass: "fa fa-download mr-2",
      staticStyle: {
        "color": "#3862dd"
      }
    }), _vm._v(" Attachment.pdf ")])]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [job.status === 'OVERDUE' ? [_c('b-badge', {
      attrs: {
        "id": 'popover-status' + index,
        "variant": "danger"
      }
    }, [_vm._v("OVERDUE / " + _vm._s(job.statusDetail && job.statusDetail.includes('days') ? _vm.generateDayDurations(parseInt(job.statusDetail.replace('days', '').trim())).durationString : job.statusDetail))])] : job.status === 'TOWARDS' ? [_c('b-badge', {
      attrs: {
        "id": 'popover-status' + index,
        "variant": "info"
      }
    }, [_vm._v("TOWARDS")])] : job.status === 'REVIEW' ? [_c('b-badge', {
      attrs: {
        "id": 'popover-status' + index,
        "variant": "warning"
      }
    }, [_vm._v("REVIEW")])] : job.status === 'APPROVE' ? [_c('b-badge', {
      attrs: {
        "id": 'popover-status' + index,
        "variant": "success"
      }
    }, [_vm._v("APPROVE")])] : job.status === 'POSTPONE' ? [_c('b-badge', {
      attrs: {
        "id": 'popover-status' + index,
        "variant": "info"
      }
    }, [_vm._v("TOWARDS (POSTPONE)")])] : job.status === 'NEW' ? [_c('b-badge', {
      attrs: {
        "id": 'popover-status' + index,
        "variant": "primary"
      }
    }, [_vm._v("ACTIVE")])] : [_c('b-badge', {
      attrs: {
        "variant": "light"
      }
    }, [_vm._v(_vm._s(job.status))])]], 2), _c('td', [_c('b-dropdown', {
      attrs: {
        "size": "sm",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_vm._v(" Actions ")])];
        },
        proxy: true
      }], null, true)
    }, [_vm.canAdd && ['TOWARDS', 'OVERDUE', 'POSTPONE', 'NEW'].includes(job.status) ? _c('b-dropdown-item', {
      attrs: {
        "size": "sm",
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('viewDetailMaintenance', {
            id: job.id,
            type: 'ON_MAINTENANCE'
          });
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-tasks"
    }), _vm._v(" Maintenance")]) : _vm._e(), ['APPROVE'].includes(job.status) && _vm.canApprove ? _c('b-dropdown-item', {
      attrs: {
        "size": "sm",
        "href": "#",
        "variant": "info"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('onApprove', job.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-thumbs-up"
    }), _vm._v(" Approve")]) : _vm._e(), ['TOWARDS', 'OVERDUE', 'NEW'].includes(job.status) ? _c('b-dropdown-item', {
      attrs: {
        "size": "sm",
        "href": "#",
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('viewDetailMaintenance', {
            id: job.id,
            type: 'ON_POSTPONE'
          });
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-clock"
    }), _vm._v(" Postpone")]) : _vm._e(), ['MAINTENANCE', 'REVIEW'].includes(job.status) && _vm.canReview ? _c('b-dropdown-item', {
      attrs: {
        "size": "sm",
        "href": "#",
        "variant": "info"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('onReview', job.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-search"
    }), _vm._v(" Review")]) : _vm._e(), ['APPROVE', 'REVIEW'].includes(job.status) ? _c('b-dropdown-item', {
      attrs: {
        "size": "sm",
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('viewDetailMaintenance', {
            id: job.id,
            type: 'ON_MAINTENANCE',
            isEdit: true
          });
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pen"
    }), _vm._v(" Edit")]) : _vm._e(), ['TOWARDS', 'OVERDUE', 'NEW'].includes(job.status) ? _c('b-dropdown-item', {
      attrs: {
        "size": "sm",
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('viewDetailMaintenance', {
            id: job.id,
            type: 'ON_CONVERT'
          });
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-tools"
    }), _vm._v(" Convert Repair")]) : _vm._e()], 1)], 1)]);
  }), 0)])]), !_vm.maintenance.isLoading && !((_vm$maintenance$data = _vm.maintenance.data) !== null && _vm$maintenance$data !== void 0 && _vm$maintenance$data.length) ? [_c('p', {
    staticClass: "text-black"
  }, [_vm._v(" No data available ")])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
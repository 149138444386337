var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Vessel Running Hours")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center border rounded shadow p-4"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center rounded shadow-sm p-4"
        }, [_c('div', {
          staticClass: "w-50 p-5 border rounded mr-4"
        }, [_c('img', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "src": require('@src/assets/images/Km3FO09.png'),
            "alt": "images"
          }
        })]), _c('div', {
          staticClass: "w-50"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('div', {
          staticClass: "mr-2 mb-2"
        }, [_c('IconFleet')], 1), _c('div', {
          staticClass: "d-flex flex-column align-items-start"
        }, [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("TB. TCP 401 🇮🇩")]), _c('span', [_vm._v("PT. Energy Transporter Indonesia")])])]), _c('div', {
          staticClass: "d-flex"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Fleet Type")]), _c('th', [_vm._v("Tugboat")])]), _c('tr', [_c('td', [_vm._v("Call Sign")]), _c('th', [_vm._v("ETI")])]), _c('tr', [_c('td', [_vm._v("Flag")]), _c('th', [_vm._v("Indonesia")])]), _c('tr', [_c('td', [_vm._v("Year & Building")]), _c('th', [_vm._v("2015 / Singapore")])])])])])])])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "rounded shadow-sm p-4"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('div', {
          staticClass: "mr-2 mb-2"
        }, [_c('ClockIcon')], 1), _c('div', {
          staticClass: "d-flex flex-column align-items-start"
        }, [_c('strong', [_vm._v("Total Running")]), _c('span', [_vm._v("Last Updated : 15 January 2024")])])]), _c('div', {
          staticClass: "d-flex"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Main Engine Portside")]), _c('th', [_vm._v("1.550 Hours")])]), _c('tr', [_c('td', [_vm._v("Main Engine Starboard")]), _c('th', [_vm._v("1.550 Hours")])]), _c('tr', [_c('td', [_vm._v("Auxiliary Engine Portside")]), _c('th', [_vm._v("1.550 Hours")])]), _c('tr', [_c('td', [_vm._v("Auxiliary Engine Starboard")]), _c('th', [_vm._v("1.550 Hours")])])])])])])])], 1)], 1)]), _c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between gap-2 w-100"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-form-group', {
          staticClass: "mr-2",
          staticStyle: {
            "width": "300px"
          },
          attrs: {
            "label": "Engine Position",
            "label-for": "from"
          }
        }, [_c('b-form-select', {
          staticClass: "w-100",
          attrs: {
            "plain": "",
            "placeholder": "Select..."
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": ""
                }
              }, [_vm._v("Main Engine Portside")])];
            },
            proxy: true
          }])
        })], 1), _c('b-form-group', {
          staticClass: "mr-2",
          staticStyle: {
            "width": "300px"
          },
          attrs: {
            "label": "Month",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-select', {
          staticClass: "w-100",
          attrs: {
            "plain": "",
            "placeholder": "Select..."
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": ""
                }
              }, [_vm._v("January")])];
            },
            proxy: true
          }])
        })], 1), _c('button', {
          staticClass: "btn",
          style: {
            backgroundColor: '#2BDCAA',
            color: 'white',
            marginTop: '20px'
          },
          attrs: {
            "type": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('button', {
          staticClass: "btn btn-outline-primary mr-2",
          style: {
            marginTop: '20px'
          },
          attrs: {
            "type": "button",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-download"
        }), _vm._v(" Import Running Hours")]), _c('button', {
          staticClass: "btn mr-2",
          style: {
            backgroundColor: '#2BDCAA',
            color: 'white',
            marginTop: '20px'
          },
          attrs: {
            "type": "button",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-upload"
        }), _vm._v(" Export Running Hours")]), _c('button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.form-rh",
            modifiers: {
              "form-rh": true
            }
          }],
          staticClass: "btn",
          style: {
            backgroundColor: '#3A5DE7',
            color: 'white',
            marginTop: '20px'
          },
          attrs: {
            "type": "button",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Input Running Hours")])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Engine Name")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Engine Branch")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Upload By")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Input Date")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Input RH")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Total RH")]), _c('th', {
          staticClass: "text-truncate text-center",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-truncate"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Main Engine Portside")])], 1), _c('td', {
          staticClass: "text-truncate"
        }, [_vm._v("MITSUBISI - S123PK-MPTK")]), _c('td', {
          staticClass: "text-truncate"
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          staticClass: "text-truncate"
        }, [_vm._v("15 January 2024")]), _c('td', {
          staticClass: "text-truncate"
        }, [_vm._v("24 Hours")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("1.550 RH")]), _c('td', {
          staticClass: "text-center"
        }, [_c('button', {
          staticClass: "btn btn-outline-primary mr-2",
          attrs: {
            "type": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-pen"
        })]), _c('button', {
          staticClass: "btn btn-outline-danger",
          attrs: {
            "type": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])])])])])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "form-rh",
      "title": "Form Input Running Hours",
      "width": "50%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('form', {
          attrs: {
            "action": "#",
            "method": "post"
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('div', {
          staticClass: "p-4"
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center border rounded shadow p-4 w-100"
        }, [_c('div', {
          staticClass: "d-flex flex-column rounded shadow-sm p-4 w-100"
        }, [_c('div', {
          staticClass: "d-flex w-100"
        }, [_c('div', {
          staticClass: "mr-2 mb-2"
        }, [_c('ClockIcon')], 1), _c('div', {
          staticClass: "d-flex flex-column align-items-start"
        }, [_c('strong', [_vm._v("Total Running")]), _c('span', [_vm._v("Last Updated : 15 January 2024")])])]), _c('div', {
          staticClass: "d-flex"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Engine Name")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Engine Branch")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "background-color": "#334697",
            "color": "white"
          }
        }, [_vm._v("Total RH")])])]), _c('tbody', [_c('td', {
          staticClass: "text-truncate"
        }, [_vm._v("Main Engine Portside")]), _c('td', {
          staticClass: "text-truncate"
        }, [_vm._v("MITSUBISI - S123PK-MPTK")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("1.550 RH")])])])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-borderless"
        }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Tanggal Input")]), _c('td', [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "date"
          }
        })])]), _c('tr', [_c('td', [_vm._v("Main Engine Portside")]), _c('td', [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Hours")])])])])]), _c('tr', [_c('td', [_vm._v("Main Engine Starboard")]), _c('td', [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Hours")])])])])]), _c('tr', [_c('td', [_vm._v("Auxiliary Engine Portside")]), _c('td', [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Hours")])])])])]), _c('tr', [_c('td', [_vm._v("Auxiliary Engine Starboard")]), _c('td', [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Hours")])])])])])])]), _c('b-form-group', {
          staticClass: "mb-2",
          attrs: {
            "label": "Notes *"
          }
        }, [_c('wysiwyg')], 1), _c('div', {
          staticClass: "d-flex align-items-center justify-content-end w-100"
        }, [_c('button', {
          staticClass: "btn btn-outline-primary mr-2",
          style: {
            marginTop: '20px'
          },
          attrs: {
            "type": "button"
          }
        }, [_vm._v("Cancel & Discard")]), _c('button', {
          staticClass: "btn mr-2",
          style: {
            backgroundColor: '#2BDCAA',
            color: 'white',
            marginTop: '20px'
          },
          attrs: {
            "type": "button"
          }
        }, [_vm._v("Save & New")]), _c('button', {
          staticClass: "btn",
          style: {
            backgroundColor: '#3A5DE7',
            color: 'white',
            marginTop: '20px'
          },
          attrs: {
            "type": "button"
          }
        }, [_vm._v("Save & Close")])])], 1)], 1)], 1)])];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-12 d-flex justify-content-end mb-2">
        <!-- <b-button variant="danger" @click="$router.push('/mi/list-initial')"
          >Back</b-button
        > -->
        <template v-if="detailFillSurvey">
          <b-button
          v-if="!isEditMode === true"
          @click="isEditMode = !isEditMode"
          variant="primary"
          >Update Claim Analysis</b-button
          >
          <b-button v-else variant="primary" @click="isEditMode = !isEditMode"
          >Preview Claim Analysis</b-button
          >
        </template>
        <b-button v-if="isEditMode" class="ml-2" type="submit" variant="outline-primary">Submit</b-button>
      </div>
    </div>
    <div style="font-size: 14px">
      <form action="" @submit.prevent="onSave">
        <div class="px-3">
          <b-row style="background: #f2f1f1" class="pt-3">
            <b-col md="4">
              <b-form-group
                label="Policy Number *"
                label-for="police_number"
                label-class="mb-0 font-500"
              >
                <b-badge
                  class="form-control-static m-0"
                  variant="success"
                  v-if="detailFillSurvey && !isEditMode"
                >
                  {{ form.police_number }}
                </b-badge>
                <b-form-input
                  v-else
                  v-model="form.police_number"
                  required
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Fleet *"
                label-for="fleets"
                label-class="mb-0 font-500"
              >
                <p
                  v-if="detailFillSurvey && !isEditMode"
                  class="form-control-static mb-0 mt-1"
                  >{{ detailMi.vehicle.name }}</p
                >
                <b-form-input
                  v-else
                  readonly
                  :value="detailMi.vehicle.name || '-'"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Cause of Loss *"
                label-for="col"
                label-class="mb-0 font-500"
              >
                <p
                  v-if="detailFillSurvey && !isEditMode"
                  class="form-control-static mb-0 mt-1"
                  >{{ detailMi.type_loss.name }}</p
                >
                <b-form-input
                  v-else
                  readonly
                  :value="detailMi.type_loss.name || '-'"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row style="background: #f9fafb" class="pt-3">
            <b-col md="4">
              <b-form-group
                label="Place of Loss (POL) *"
                label-for="pol"
                label-class="mb-0 font-500"
              >
                <p
                  v-if="detailFillSurvey && !isEditMode"
                  class="form-control-static mb-0 mt-1"
                  >{{ detailMi.place_of_loss }}</p
                >
                <b-form-input
                  v-else
                  readonly
                  :value="detailMi.place_of_loss || '-'"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date of Loss (DOL) *"
                label-for="dol"
                label-class="mb-0 font-500"
              >
                <p
                  v-if="detailFillSurvey && !isEditMode"
                  class="form-control-static mb-0 mt-1"
                  >{{ detailMi.date_of_loss }}</p
                >
                <b-form-input
                  v-else
                  readonly
                  :value="detailMi.date_of_loss || '-'"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Periode of Coverage*"
                label-for="periodeOfCoverage"
                label-class="mb-0 font-500"
              >
                <p
                  v-if="detailFillSurvey && !isEditMode"
                  class="form-control-static mb-0 mt-1"
                  >{{ form.coverage_period || '-' }}</p
                >
                <date-picker
                  v-else
                  type="year"
                  v-model="form.coverage_period"
                  range
                  value-type="YYYY"
                  format="YYYY"
                  placeholder="Select Year"
                ></date-picker>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4" v-if="detailSurvey && !loading.survey">
          <table class="table table-striped">
            <thead>
              <th width="60px" class="text-center">NO</th>
              <th
                v-for="(header, headerIndex) in detailSurvey.headers"
                :width="headerIndex === 3 ? '450px' : ''"
                :key="headerIndex"
                class="text-truncate"
                >{{ header.label_name }}</th
              >
            </thead>
            <tbody v-if="detailSurvey.headers[0]">
              <tr
                v-for="(number, numberIndex) in detailSurvey.headers[0].contents
                  .length"
                :key="numberIndex"
              >
                <td class="text-center">{{ number }}</td>
                <td
                  v-for="(header, headerIndexes) in detailSurvey.headers"
                  :key="headerIndexes"
                  :class="{ 'text-center': headerIndexes == 2 }"
                >
                  <template v-if="header.contents[number - 1]">
                    <template v-if="header.contents[number - 1].text">
                      {{ header.contents[number - 1].text }}
                    </template>
                    <template v-else-if="header.contents[number - 1].input">
                      <template v-if="detailFillSurvey && !isEditMode">
                        <p
                          class="form-control-static mb-0 mt-1"
                          v-if="headerIndexes != 2"
                          >{{ header.contents[number - 1].input.value }}
                        </p>
                        <b-badge
                          v-else
                          class="p-3"
                          :variant="
                            indicatorColor(
                              header.contents[number - 1].input.value
                            )
                          "
                          style="font-size: 16px"
                          >{{
                            header.contents[number - 1].input.value
                          }}</b-badge
                        >
                        <input
                          type="hidden"
                          v-if="
                            header.contents[number - 1].input.alias === 'max100'
                          "
                          class="survey-input-max100"
                          :value="header.contents[number - 1].input.value"
                        />
                      </template>
                      <template v-else>
                        <b-form-input
                          @blur="onGenerateTotal()"
                          class="survey-input-max100"
                          type="number"
                          v-if="
                            header.contents[number - 1].input.alias === 'max100'
                          "
                          :maxlength="3"
                          :min="1"
                          :max="100"
                          v-model="header.contents[number - 1].input.value"
                        ></b-form-input>
                        <b-form-textarea
                          rows="6"
                          v-else-if="
                            header.contents[number - 1].input.alias === 'field'
                          "
                          v-model="header.contents[number - 1].input.value"
                        ></b-form-textarea>
                      </template>
                    </template>
                  </template>
                </td>
              </tr>
              <tr class="" style="border-bottom: 1px solid">
                <b-td colspan="3" class="text-right">
                  <h5><b>Total</b></h5>
                </b-td>
                <b-td class="text-center"
                  ><b-badge
                    class="p-3"
                    variant="danger"
                    style="font-size: 16px"
                    >{{ totalAll }}</b-badge
                  >
                </b-td>
                <b-td>&nbsp;</b-td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="py-5 my-5 d-flex justify-content-center">
          <b-spinner></b-spinner>
        </div>
        <b-row class="px-3 pb-3">
          <b-col md="4" class="border">
            <h5 class="my-2">Value Indicator</h5>
            <table class="table table-striped">
              <tbody>
                <tr v-for="(item, index) in indicators" :key="index">
                  <td>
                    <b-row>
                      <b-col cols="4" class="pl-4"
                        ><b-badge
                          :variant="item.color"
                          class="py-2"
                          style="min-width: 120px"
                          >{{ item.value }}</b-badge
                        ></b-col
                      >
                      <b-col cols="8"
                        ><h6>{{ item.label }}</h6></b-col
                      >
                    </b-row>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col md="4">
            <h5 class="my-2 p-2 px-3" style="background: #f9fafb"
              >Conclusion</h5
            >
            <b-form-group>
              <p
                v-if="detailFillSurvey && !isEditMode"
                class="form-control-static mb-0 mt-1"
                >{{ form.conclusion }}</p
              >
              <b-form-textarea
                v-else
                v-model="form.conclusion"
                id="exampleFormControlTextarea1"
                required
                rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <h5 class="my-2 p-2 px-3" style="background: #f9fafb"
              >Comments or Suggestions</h5
            >
            <b-form-group>
              <p
                v-if="detailFillSurvey && !isEditMode"
                class="form-control-static mb-0 mt-1"
                >{{ form.comments }}
              </p>
              <b-form-textarea
                v-else
                v-model="form.comments"
                id="exampleFormControlTextarea1"
                required
                rows="5"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col
            cols="12"
            md="12"
            class="pull-right d-flex justify-content-end"
          >
            <p
              v-if="detailFillSurvey && !isEditMode"
              class="form-control-static mb-0 mt-1"
            ></p>
            <b-button v-else type="submit" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
      </form>
    </div>
    <b-row style="font-size: 16px">
      <b-col md="12" v-if="detailMi">
        <!-- <template v-if="detailFillSurvey">
              <b-button 
              v-if="!isEditMode === true"
              @click="isEditMode = !isEditMode" 
              variant="primary">Edit</b-button>
              <b-button v-else variant="primary" @click="isEditMode = !isEditMode">Preview</b-button>
            </template> -->
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '@/src/config/pluginInit'
import IqCard from '@components/xray/cards/iq-card'
import { fleetsActions, miActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  name: 'Portal',
  components: { StepNavigation, StepWizard, IqCard },
  props: {
    detailMi: null,
  },
  data() {
    return {
      permission_user: JSON.parse(localStorage.getItem('profile')).role.roles,
      totalAll: 0,
      isEditMode: false,
      // detailMi: null,
      detailSurvey: null,
      detailFillSurvey: null,
      form: {
        _saving: false,
        police_number: null,
        coverage_period: null,
        conclusion: null,
        comments: null,
      },
      indicators: [
        {
          value: '80 - 100',
          color: 'danger',
          label: 'Recommended',
        },
        {
          value: '65 - 79',
          color: 'warning',
          label: 'With Consideration',
        },
        {
          value: '55 - 64',
          color: 'info',
          label: 'It Needs Special Attention',
        },
        {
          value: '0 - 54',
          color: 'success',
          label: 'Not Recommended',
        },
      ],
      loading: {
        survey: false,
        claimForm: false,
      },
    }
  },
  watch: {
    totalAll(value) {
      this.form.conclusion = ''
      const total = value ? Number(value) : 0

      if (total >= 0 && total <= 54) {
        this.form.conclusion = 'Not Recommended'
      } else if (total >= 55 && total <= 64) {
        this.form.conclusion = 'It Needs Special Attention'
      } else if (total >= 65 && total <= 79) {
        this.form.conclusion = 'With Consideration'
      } else if (total >= 80) {
        this.form.conclusion = 'Recommended'
      }
    },
  },
  computed: {
    userPermission() {
      if (
        [
          'SUPER ADMIN',
          'MARINE INSURANCE',
          'MARINE INSURANCE ADMIN',
          'MARINE INSURANCE MANAGER',
        ].includes(this.permission_user)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...fleetsActions,
    ...miActions,
    async handleInit() {
      if (this.$route.query.formId) {
        // await this.fetchMi()
        await this.fetchClaimForm()
        await this.fetchSurvey()
        this.onGenerateTotal()
      }
    },
    // async fetchMi () {
    //   const { data, status } = await this.fetchDetailMi({
    //     id: this.$route.query.formId,
    //     with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories'
    //   })

    //   if ([500, 404, 403].includes(status)) {
    //     this.$swal(
    //       `Oops!`,
    //       'Ada Yang Salah',
    //       'error'
    //     )
    //     this.form._saving = false
    //     return
    //   }

    //   // this.detailMi = data
    // },
    async fetchSurvey() {
      try {
        this.loading.survey = true
        const { data, status } = await this.getClaimAnalysisByFormId({
          id: this.$route.query.formId,
          survey: 'init_claim',
        })

        if ([500, 404, 403].includes(status)) {
          this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
          this.form._saving = false
          return
        }

        this.detailSurvey = data

        if (this.detailSurvey.form) {
          this.form.coverage_period = this.detailSurvey.form.coverage_period
            ? this.detailSurvey.form.coverage_period.toString()
            : ''
          this.form.conclusion = !this.form.conclusion
            ? this.detailSurvey.form.conclusion
            : this.form.conclusion
          this.form.comments = !this.form.comments
            ? this.detailSurvey.form.comments
            : this.form.comments
        }

        if (this.detailSurvey.headers) {
          this.detailSurvey.headers.map((header, headerIndex) => {
            if (this.detailFillSurvey.headers[headerIndex] && header.contents) {
              header.contents.map((content, contentIndex) => {
                if (
                  this.detailFillSurvey.headers[headerIndex].contents[
                    contentIndex
                  ] &&
                  this.detailFillSurvey.headers[headerIndex].contents[
                    contentIndex
                  ].value &&
                  content.input
                ) {
                  content.input.value =
                    this.detailFillSurvey.headers[headerIndex].contents[
                      contentIndex
                    ].value.value_anything
                }
              })
            }
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading.survey = false
      }
    },
    async fetchClaimForm() {
      const { data, status } = await this.getFormClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim',
      })

      if (['error'].includes(status)) {
        this.form._saving = false
        return
      }

      this.detailFillSurvey = data

      if (this.detailFillSurvey.form) {
        this.form.coverage_period = this.detailFillSurvey.form.coverage_period
          ? this.detailFillSurvey.form.coverage_period.toString()
          : ''
      }

      if (this.detailFillSurvey.claim_analysis) {
        this.form.conclusion = this.detailFillSurvey.claim_analysis.conclusion
        this.form.comments = this.detailFillSurvey.claim_analysis.comments
        this.form.police_number =
          this.detailFillSurvey.claim_analysis.police_number
      }
    },

    async onSave(e) {
      e.preventDefault()
      this.form._saving = true
      const contents = []
      const mapContents = this.detailSurvey.headers
        .filter(
          (header) =>
            header.type === 'user_input' &&
            header.contents[0] &&
            header.contents[0].input
        )
        .map((header) => header.contents)

      if (mapContents) {
        mapContents.forEach((content) => {
          content.forEach((c) => {
            contents.push({
              id: c.id,
              value: c.input ? c.input.value : c.text,
            })
          })
        })
      }

      const { data, status } = await this.saveClaimAnalysis({
        form_id: this.$route.query.formId,
        survey_id: this.detailSurvey.survey.id,
        total: this.totalAll,
        conclusion: this.form.conclusion,
        comments: this.form.comments,
        police_number: this.form.police_number,
        coverage_period: this.form.coverage_period[0],
        coverage_period_to: this.form.coverage_period[1],
        contents,
      })

      if (status === 422) {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.form._saving = false
        return
      }

      if ([500, 404, 403, 409].includes(status)) {
        this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
        this.form._saving = false
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      this.handleInit()
      this.isEditMode = false
      this.form._saving = false
    },
    onGenerateTotal() {
      let ret = 0

      const inputs = document.getElementsByClassName('survey-input-max100')

      if (inputs) {
        const filteredContents = []

        for (const input of inputs) {
          if (typeof input.value !== 'undefined' && input.value !== '') {
            filteredContents.push(input.value)
          }
        }

        filteredContents.forEach((input) => {
          ret += parseInt(input)
        })

        if (filteredContents.length > 0) {
          ret = ret / filteredContents.length
        }

        this.totalAll = Math.round(ret)
      }
    },
    indicatorColor(value) {
      let color = 'success'
      if (value > 55) color = 'info'
      if (value > 65) color = 'warning'
      if (value > 80) color = 'danger'
      return color
    },
  },
  async mounted() {
    xray.index()
    await this.handleInit()
  },
}
</script>
<style >

.font-500{
  font-weight: 500 !important;
}

table th {
  background: #334697;
  color: white;
}
</style>

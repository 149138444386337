var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center rounded shadow-sm p-4"
  }, [_c('div', {
    staticClass: "w-50 border rounded mr-4"
  }, [_c('img', {
    staticStyle: {
      "width": "100%",
      "height": "265px"
    },
    attrs: {
      "src": require('../../../assets/images/example-fleets.png'),
      "alt": "images"
    }
  })]), _c('div', {
    staticClass: "w-50"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "mr-2 mb-2"
  }, [_c('IconFleet')], 1), _c('div', {
    staticClass: "d-flex flex-column align-items-start"
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("TB. TCP 401 🇮🇩")]), _c('span', [_vm._v("PT. Energy Transporter Indonesia")])])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Fleet Type")]), _c('th', [_vm._v("Tugboat")])]), _c('tr', [_c('td', [_vm._v("Call Sign")]), _c('th', [_vm._v("ETI")])]), _c('tr', [_c('td', [_vm._v("Flag")]), _c('th', [_vm._v("Indonesia")])]), _c('tr', [_c('td', [_vm._v("Year & Building")]), _c('th', [_vm._v("2015 / Singapore")])])])])])])])]), _c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "rounded shadow-sm p-4"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "mr-2 mb-2"
  }, [_c('ClockIcon')], 1), _c('div', {
    staticClass: "d-flex w-100 align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start mr-3"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("Total Running")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Last Updated :"), _c('strong', [_vm._v(" 15 January 2024")])])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center m-0",
    attrs: {
      "block": "",
      "variant": "warning"
    }
  }, [_c('i', {
    staticClass: "fa fa-clock mr-1"
  }), _vm._v(" Detail RH ")])], 1)])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Main Engine Portside")]), _c('th', [_vm._v("1.550 Hours")])]), _c('tr', [_c('td', [_vm._v("Main Engine Starboard")]), _c('th', [_vm._v("1.550 Hours")])]), _c('tr', [_c('td', [_vm._v("Auxiliary Engine Portside")]), _c('th', [_vm._v("1.550 Hours")])]), _c('tr', [_c('td', [_vm._v("Auxiliary Engine Starboard")]), _c('th', [_vm._v("1.550 Hours")])])])])])])]), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "p-3 rounded",
    staticStyle: {
      "background-color": "#4044AC"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex rounded p-3",
    staticStyle: {
      "background-color": "#ffffff"
    }
  }, [_c('div', {
    staticClass: "p-2 mr-2 rounded-lg",
    staticStyle: {
      "background-color": "#334697"
    }
  }, [_c('FleetIcon', {
    attrs: {
      "fill": "white",
      "stroke": "white"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("140")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Total Job Maintenance Plan / Initiation")])])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex rounded p-3",
    staticStyle: {
      "background-color": "#ffffff"
    }
  }, [_c('div', {
    staticClass: "p-2 mr-2 rounded-lg",
    staticStyle: {
      "background-color": "#3C5DE7"
    }
  }, [_c('PaperIcon', {
    attrs: {
      "fill": "white",
      "stroke": "white"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("120")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Equipment Maintenance Plan / Initiation")])])])])], 1)], 1)]), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Period",
      "label-for": "period"
    }
  }, [_c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "date",
      "range": "",
      "placeholder": "Select date range"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Trigger",
      "label-for": "trigger"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "placeholder": "Select...",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All")]), _c('option', {
    attrs: {
      "value": "owned"
    }
  }, [_vm._v("Owned by Company")])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter Type",
      "label-for": "filter_type"
    }
  }, [_vm._l(_vm.typeOptions, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      staticClass: "custom-radio-color-checked",
      attrs: {
        "inline": "",
        "color": item.color,
        "name": "color",
        "value": item.value,
        "disabled": item.disabled,
        "required": ""
      }
    }, [_c('span', [_vm._v(_vm._s(item.label))])])];
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Type",
      "label-for": "job-type"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": [],
      "placeholder": "Select..."
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('button', {
    staticClass: "btn",
    style: {
      backgroundColor: '#2BDCAA',
      color: 'white',
      marginTop: '30px'
    },
    attrs: {
      "type": "button",
      "block": ""
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])])], 1), _c('div', {
    staticClass: "d-flex w-100 border my-4"
  }), _c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary text-sm"
  }, [_vm._v("Summary Board")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex rounded p-3 border shadow",
    staticStyle: {
      "background-color": "#ffffff"
    }
  }, [_c('div', {
    staticClass: "p-2 mr-2 rounded-lg",
    staticStyle: {
      "background-color": "#334697"
    }
  }, [_c('PaperIcon', {
    attrs: {
      "fill": "white",
      "stroke": "white"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("20")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Active")])])])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex rounded p-3 border shadow",
    staticStyle: {
      "background-color": "#ffffff"
    }
  }, [_c('div', {
    staticClass: "p-2 mr-2 rounded-lg",
    staticStyle: {
      "background-color": "#ffd3A0"
    }
  }, [_c('FleetIcon', {
    attrs: {
      "fill": "black",
      "stroke": "black"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("5")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Postponed Maintenance")])])])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex rounded p-3 border shadow",
    staticStyle: {
      "background-color": "#ffffff"
    }
  }, [_c('div', {
    staticClass: "p-2 mr-2 rounded-lg",
    staticStyle: {
      "background-color": "#2bdcaa"
    }
  }, [_c('PaperIcon', {
    attrs: {
      "fill": "white",
      "stroke": "white"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("20")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Finished Maintenance")])])])])], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary text-sm"
  }, [_vm._v("Active Maintenance")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex rounded p-3 border shadow",
    staticStyle: {
      "background-color": "#ffffff"
    }
  }, [_c('div', {
    staticClass: "p-2 mr-2 rounded-lg",
    staticStyle: {
      "background-color": "#71ffd7"
    }
  }, [_c('FleetIcon', {
    attrs: {
      "fill": "black",
      "stroke": "black"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("54")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Toward Maintenance")])])])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex rounded p-3 border shadow",
    staticStyle: {
      "background-color": "#ffffff"
    }
  }, [_c('div', {
    staticClass: "p-2 mr-2 rounded-lg",
    staticStyle: {
      "background-color": "#ffd1d1"
    }
  }, [_c('FleetIcon', {
    attrs: {
      "fill": "black",
      "stroke": "black"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("15")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Overdue Maintenance")])])])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex rounded p-3 border shadow",
    staticStyle: {
      "background-color": "#ffffff"
    }
  }, [_c('div', {
    staticClass: "p-2 mr-2 rounded-lg",
    staticStyle: {
      "background-color": "#b9d1ff"
    }
  }, [_c('FleetIcon', {
    attrs: {
      "fill": "black",
      "stroke": "black"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("32")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v("Next 3 Month")])])])])], 1)], 1), _c('div', {
    staticClass: "d-flex w-100 border my-4"
  }), _c('div', {
    staticClass: "d-flex w-100 align-items-center justify-content-between mb-4"
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-file-excel"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Export ")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "text-black mr-2"
  }, [_vm._v("View :")]), _c('b-button', {
    staticClass: "d-flex align-items-center mr-2",
    attrs: {
      "variant": _vm.selectedView === 'list' ? 'primary' : "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.selectedView = 'list';
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-bars"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Job List Data ")])]), _c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": _vm.selectedView === 'group' ? 'primary' : "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.selectedView = 'group';
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-layer-group"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Group By Component ")])])], 1)], 1), _vm.selectedView === 'list' ? _c('div', {
    staticClass: "d-flex flex-column w-100 overflow-auto",
    staticStyle: {
      "max-height": "400px"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', {
    staticStyle: {
      "background": "#334697",
      "color": "#fff"
    }
  }, [_c('th', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Job Name")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Equipment Name")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Finish")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Postponed")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Last Maintenance")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Frequency")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Due Date / Hours")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Job Type")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("History Data")])]), _c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("1")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Lubricating Oil Cooler - TB. TCP 401")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Main Engine 1 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "danger"
    }
  }, [_c('span', [_vm._v("Overdue / 8436 RH")])])], 1), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("45")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("3")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Jul 2024 | 280 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("300 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("580 Hours")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Overhaul")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.viewHistoryRecordDetail = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Detail Data ")])])], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("2")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Lubricating Oil Cooler - TB. TCP 401")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Main Engine 2 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("Active")])])], 1), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("45")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("0")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Jul 2024")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("3 Month")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Oktober 2024")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Replace")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.viewHistoryRecordDetail = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Detail Data ")])])], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("3")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Emergency/Extra Battery - TB. TCP 401")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Emergency/Extra Battery")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "info"
    }
  }, [_c('span', [_vm._v("Initiation")])])], 1), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("0")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("0")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("-")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("1 Month")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("01 Oktober 2024")]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v("Greasing")]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.viewHistoryRecordDetail = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Detail Data ")])])], 1)])])])]) : _vm._e(), _vm.selectedView === 'group' ? _c('div', {
    staticClass: "d-flex flex-column w-100 overflow-auto",
    staticStyle: {
      "max-height": "400px"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', {
    staticStyle: {
      "background": "#334697",
      "color": "#fff"
    }
  }, [_c('th', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Equipment Name")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Job Data")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Postponed")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Repair Handling")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("Finish")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("History Data")])]), _c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("1")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Main Engine 1 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "primary"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "dark"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.viewHistoryRecordDetail = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Detail Data ")])])], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("2")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Main Engine 2 - Lubricating Oil Cooler")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "primary"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "dark"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.viewHistoryRecordDetail = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Detail Data ")])])], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("3")]), _c('td', {
    staticClass: "text-truncate text-primary"
  }, [_vm._v("Emergency/Extra Battery")]), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "primary"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "dark"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate text-center"
  }, [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("10")])])], 1), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.viewHistoryRecordDetail = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Detail Data ")])])], 1)])])])]) : _vm._e(), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_vm._v(" Total: 1   |   Per page: 20 ")]), _c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": 1,
      "per-page": 20,
      "align": "right"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    staticStyle: {
      "background-color": "#F3F6F7"
    },
    attrs: {
      "right": "",
      "width": "100%",
      "bg-variant": "white",
      "title": "Maintenance History Record - TB. TCP 401"
    },
    model: {
      value: _vm.viewHistoryRecordDetail,
      callback: function callback($$v) {
        _vm.viewHistoryRecordDetail = $$v;
      },
      expression: "viewHistoryRecordDetail"
    }
  }, [_c('HistoryRecordDetail')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
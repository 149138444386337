<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
            <h5 class="card-title text-primary"><strong>SHIPMENT PLANNING</strong></h5>
        </template>

        <!-- FILTER START -->
        <b-modal v-model="modal.filter" @ok="onFilter" title="Filter Data" ok-title="Filter" centered>
          <b-row class="">
            <b-col md="12">
              <b-form-group label="Period" label-for="Period" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <date-picker v-model="dateRange" type="date" range placeholder="Select date range" :style="{width: '100%'}"></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Cargo Type" label-for="cargo" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <b-form-select plain :options="optionsCargoType" v-model="params.cargo_type_id"> 
                  <b-form-select-option :value="null">All</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contract Type" label-for="cargo" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <b-form-select plain v-model="params.contract_type_id"> 
                  <b-form-select-option :value="null">All</b-form-select-option>
                  <b-form-select-option v-for="(option, index) in optionsContractType" :value="option.id" :key="index">{{option.name}}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Stage Status" label-for="stage_id" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <b-form-select plain v-model="params.stage_id" class="mb-2" :options="optionsStage">
                  <b-form-select-option :value="null">All</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>   
            <!-- <b-col md="12">
              <b-form-group label="Keyword" label-for="keyword" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <b-form-input type="text" id="keyword" v-model="params.keyword" placeholder="Search project..."></b-form-input>
              </b-form-group>
            </b-col> -->
            <b-col md="12">
              <b-form-group label="Ownership" label-for="fleet_ownership" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <b-form-select plain v-model="params.fleet_ownership" class="mb-2">
                  <b-form-select-option value="">All</b-form-select-option>
                  <b-form-select-option value="own-ship">Own Ship</b-form-select-option>
                  <b-form-select-option value="chartered">Chartered</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>  
            <b-col md="12" v-if="params.fleet_ownership">
              <b-form-group label="Vehicle" label-for="vehicle_id"   label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <v-select
                  label="text"
                  name="vehicle_id"
                  v-model="params.vehicle_id"
                  :options="optionsFleets"
                  :reduce="(optionsFleets) => optionsFleets.value"
                  placeholder="Select Fleet"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>
        
         <!-- DISPLAY  -->
        <b-row class="my-3 px-4 d-flex justify-content-end">
          <b-col md="3" class="d-flex">
            <!-- <b-form class="d-flex" @submit.prevent="onFilter">
              <b-form-input size="md" placeholder="search project..." style="width:220px;" v-model="params.keyword"></b-form-input>
              <b-button variant="primary" class="mx-2" type="submit">
                <i class="fa fa-search"></i>
              </b-button>
            </b-form> -->
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="toggleModalFilter"
            >
              <i class="fas fa-sliders"></i>
              <p class="text-truncate m-0"> Filter Data</p>
            </b-button>
          </b-col>
          <b-col md="9" class="d-flex justify-content-end">
            <strong style="align-self: center">Display : &nbsp;</strong>
            <div class="btn-group">
              <button
                type="button"
                @click="view_style = 'list'"
                class="btn btn-outline-primary"
                :class="{ active: view_style === 'list' }"
              ><span class="fa fa-list"></span> List</button>
              <button
                type="button"
                @click="view_style = 'kanban'"
                class="btn btn-outline-primary"
                :class="{ active: view_style === 'kanban' }"
              ><span class="fa fa-th-large"></span> Kanban</button>
              <button
                type="button"
                @click="view_style = 'gantt-chart'"
                class="btn btn-outline-primary"
                :class="{ active: view_style === 'gantt-chart' }"
              ><span class="fa fa-bars"></span> Gantt Chart</button>
            </div>
            <div style="border-left: 1px solid;" class="mx-2"></div>
            <template >
              <b-button @click="refreshDataTable" variant="primary" class="float-right mr-1"><i class="fa fa-refresh"></i> &nbsp; Refresh Data</b-button>
              <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="mr-1">
                <template v-slot:button-content>
                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                    <i class="fa fa-download mr-1"></i> Export
                  </span>
                </template>
                <b-dropdown-item href="#" variant="dark" @click="onExportShipmentPlanning"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
              </b-dropdown>
              <b-button v-if="addPermission" @click="openSlideFormAddPlan('ADD', null)" variant="primary" class="float-right mr-1"><i class="fa fa-plus"></i> &nbsp; Add Plan</b-button>
            </template>
          </b-col>
        </b-row>
        <!-- DISPLAY END -->
        
        <!-- SHOW FILTER VALUE -->

        <div style="padding: 12px 40px;">
          <b-row class="border rounded-md pt-2">
              <b-col md="2">
                <b-form-group label="Cargo Type :">
                  <p class="mb-0 text-black" style="font-weight: 500;">{{optionsCargoType.find((el, i)=>el.id == params.cargo_type_id)?.name ?? 'All'}}</p>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Contract Type :" >
                  <p class="mb-0 text-black" style="font-weight: 500;">{{optionsContractType.find((el, i)=>el.id == params.contract_type_id)?.name ?? 'All'}}</p>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Stage Status :">
                  <p class="mb-0 text-black" style="font-weight: 500;">{{optionsStage.find((el, i)=>el.value == params.stage_id)?.text ?? 'All'}}</p>
                </b-form-group>
              </b-col>   
              <b-col md="2">
                <b-form-group label="Ownership">
                  <p class="mb-0 text-black" style="font-weight: 500;">{{ params.ownership  ?? "All"}}</p>
                </b-form-group>
              </b-col>  
              <b-col md="4">
                <b-form-group label="Period :" v-if="dateRange.length">
                  <div class="d-flex">
                    <p class="mb-0 text-black" style="font-weight: 500;" >{{ dateRange[0] ? moment(dateRange[0]).format("DD MMMM YYYY"):'-' }} - <span>{{ dateRange[1] ? moment(dateRange[1]).format("DD MMMM YYYY"):'-' }}</span></p>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
        </div>
        
        <!-- END SHOW FILTER VALUE -->


        <!-- LIST -->
        <template v-if="view_style === 'list'">
          <b-row class="m-2">
            <b-col md="12" v-if="!loading">
              <div class="d-flex overflow-auto " style="height:400px">
                <table class="table table-responsive">
                  <thead>
                    <tr>
                      <th class="text-center">NO</th>
                      <th class="text-center text-truncate " style="width:150px">PERIODE</th>
                      <th class="text-center text-truncate ">PROJECT</th>
                      <th class="text-center text-truncate ">THIRD PARTY COMPANY</th>
                      <th class="text-center text-truncate ">FLEETS</th>
                      <th class="text-center text-truncate ">TYPE</th>
                      <th class="text-center text-truncate ">JUMLAH SHIPMENT</th>
                      <th class="text-center text-truncate ">CARGO TYPE</th>
                      <th class="text-center text-truncate ">CONTRACT TYPE</th>
                      <th class="text-center text-truncate ">TOTAL VOLUME</th>
                      <th class="text-center text-truncate ">STAGE</th>
                      <th class="text-center text-truncate ">CREATED BY</th>
                      <th class="text-center text-truncate ">APPROVAL</th>
                      <th class="text-center">ACTION</th>
                    </tr>
                  </thead>
                  <tbody v-if="listShipmentPlanningTable.data.length > 0">
                    <tr v-for="(state, index) in listShipmentPlanningTable.data" :key="index">
                      <th class="text-center bg-white text-black">{{ index + 1 }}</th>
                      <td class="text-center bg-white text-black">
                        <div class="d-flex align-items-center">
                          <strong class="text-truncate">{{ moment(state?.periode_start).format('DD MMM YYYY') }}</strong>
                          <span class="mx-2">s/d</span>
                          <strong class="text-truncate">{{ moment(state?.periode_end).format('DD MMM YYYY') }}</strong>
                        </div>
                      </td>
                      <td class="text-truncate">
                        <span class="hover text-primary" style="cursor:pointer" @click="openSlideFormAddPlan('PREVIEW', state?.id)">{{ state?.project }}</span>
                      </td>
                      <td class="text-truncate">
                        <div class="d-flex align-items-center">
                          <b-badge :class="`border ${state?.third_party_company ? 'border-primary text-primary' : 'border-dark text-dark'} text-uppercase mr-2`" variant="none">{{state?.third_party ? 'Yes' : 'No'}}</b-badge>
                          <span v-if="state?.third_party_company">{{state?.third_party_company}}</span>
                        </div>
                      </td>
                      <td class="text-center bg-white text-black" style="width: 150px">
                        <div class="d-flex align-items-center" v-if="!state?.third_party_company">
                          <b-badge v-for="(state, index) in state?.fleets" :key="index" class="border border-primary text-primary text-uppercase mr-2" variant="none">{{ state?.vehicle_name }}</b-badge>
                        </div>
                        <b-badge v-else :class="`border border-dark text-dark text-uppercase`" variant="none">NO FLEET</b-badge>
                      </td>
                      <th class="text-center bg-white text-black">
                        <b-badge class="border border-primary text-primary text-uppercase" variant="none">{{ state?.project_type === 'existing' ? 'EXISTING' : 'NEW PROJECT' }}</b-badge>
                      </th>
                      <td class="text-center text-truncate ">{{ state?.shipment }} SHIPMENT</td>
                      <td class="text-center text-truncate ">{{ state?.cargo_type?.name }}</td>
                      <td class="text-center text-truncate ">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                          <span>{{ state?.contract_type?.name }}</span>  
                          <strong :class="`${state?.contract_type?.is_spal ? 'text-success' : 'text-warning'}`"><small>{{ state?.contract_type?.is_spal ? 'Yes, using spal' : 'Not using spal' }}</small></strong>
                        </div>
                      </td>
                      <td class="text-center text-truncate">{{ state?.volume ? state?.volume?.toLocaleString() : '0' }} {{ state?.unit }}</td>
                      <td class="text-center bg-white text-black">
                        <b-dropdown v-b-tooltip.top="'Action'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              {{state?.project_stage?.title}}
                            </span>
                          </template>
                          <b-dropdown-item href="#" 
                            :variant="state?.detail_project_stage_id === obj.id ? 'primary' : 'secondary'"
                            v-for="(obj, objIndex) in listProjectStage" :key="objIndex"
                          >
                            <strong @click="onChangeStatusStage(index, state?.id, obj.id)">{{ obj?.title }}</strong>
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                      <td class="text-center text-primary text-truncate">{{ state?.project_log?.[0]?.name }}</td>
                      <td class="text-center text-primary">
                        <div class="d-flex align-items-center justify-content-center">
                          {{ state?.count_approval }}/{{ state?.many_approval }} <i class="fa fa-user ml-2" /> 
                        </div>  
                      </td>
                      <td class="text-center bg-white text-black" v-if="updatePermission || deletePermission">
                        <b-dropdown 
                          size="lg"
                          variant="link iq-bg-primary iq-border-radius-10"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary" @click="openSlideFormAddPlan('PREVIEW', state?.id)">
                            <i class="fa fa-eye mr-2"></i>Detail
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="warning" @click="openPfaSlide(state?.id)" v-if="state?.is_pfa">
                            <i class="fa fa-info-circle mr-2"></i>PFA
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="primary" @click="openProjectFormSlide(state?.id, state?.project)">
                            <i class="fa fa-info-circle mr-2"></i>Collection Forms
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="success" @click="openSlideSpalProcess('ADD', state?.id)" v-if="state?.project_stage?.spal_process && state?.contract_type?.is_spal">
                            <i class="fa fa-info-circle mr-2"></i>Spal Process
                          </b-dropdown-item>
                          <b-dropdown-divider v-if="approvalPermission(state?.user_approval) === true || ['System Checker'].includes(userProfileName) && Number(state?.count_approval) !== Number(state?.many_approval)"></b-dropdown-divider>
                          <b-dropdown-item href="#" variant="primary" v-if="approvalPermission(state?.user_approval) === true || ['System Checker'].includes(userProfileName) && Number(state?.count_approval) !== Number(state?.many_approval)" v-b-modal.project-approval @click="projectId = state?.id">
                            <i class="fa fa-check mr-2"></i>Approval
                          </b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#" variant="danger" @click="onDeleteProjectContract(state)">
                            <i class="fa fa-trash mr-2"></i>Delete
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="11" class="text-center align-middle"><strong class="text-muted">No Data Shipment Planning.</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3" v-if="listShipmentPlanningTable.data.length > 0">
                <b-pagination
                  v-model="listShipmentPlanningTable.meta.page"
                  :total-rows="listShipmentPlanningTable.meta.totalItem"
                  @change="getListShipmentPlanningTable"
                  :per-page="listShipmentPlanningTable.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
            </b-col>
            <b-col md="12" v-else> 
              <div  class="alert alert-info">
              Loading...
              </div>
            </b-col>
          </b-row>
        </template>

        <!-- KANBAN -->
        <template  v-if="view_style === 'kanban'">
          <Kanban 
            :kanban-data="kanbanData" 
            :open-slide-detail-project-contract="openSlideDetailProjectContract" 
            :openSlideFormAddPlan="openSlideFormAddPlan" 
            :openSlideSpalProcess="openSlideSpalProcess" 
            :on-add-list="onAddList"
            :on-card-move="onCardMove"
            :on-move-set-end-stage="onMoveSetEndStage"
            :on-remove-list="onRemoveList"
            :on-change-prioritize="onChangePrioritize" 
            :on-delete-project-contract="onDeleteProjectContract" 
            :on-edit-title="onEditTitle" 
            :on-denied="onDenied" 
            :on-spal-process="onSpalProcess" 
            :on-running-process="onRunningProcess" 
            :open-pfa-slide="openPfaSlide" 
            :open-project-form-slide="openProjectFormSlide" 
            :on-save-approval="onSaveApproval" 
          />
        </template>

        <!-- GANTT CHART -->
        <template v-if="view_style === 'gantt-chart'">
          <GanttChart 
            :gantt-chart-rows="ganttChartRows" 
            :on-update-periode-date="onUpdatePeriodeDate" 
            :open-slide-detail-project-contract="openSlideDetailProjectContract" 
            :openSlideFormAddPlan="openSlideFormAddPlan" 
            :on-delete-project-contract="onDeleteProjectContract" 
            :open-pfa-slide="openPfaSlide" 
            :open-project-form-slide="openProjectFormSlide" 
          />
        </template>

        <!-- SLIDE - FORM ADD PLAN -->
        <b-sidebar
          v-model="slideAddRakor"
          id="add_rakor"
          :title="['PREVIEW', 'EDIT'].includes(action) ? 'DETAIL PLAN' : `ADD PLAN`"
          width="100%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <FormAddPlan 
              :refresh-data-table="refreshDataTable" 
              :refreshDataTableWithoutCloseModal="refreshDataTableWithoutCloseModal" 
              :action="action"
              :edit-id="editId"
              :openInspectionChecklistSlide="openInspectionChecklistSlide"
              :optionsCompanies="optionsCompanies"
              :optionsCustomerClass="optionsCustomerClass"
              @updateAction="updateAction" 
            />
          </template>
        </b-sidebar>
        <!-- SLIDE - FORM ADD PLAN END -->

        <!-- SLIDE - PFA -->
        <b-sidebar
          v-model="slideAddPFA"
          id="slideAddPFA"
          title="ADD PFA(PRE FIXTURE ANALYSIS)"
          width="80%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideFormPFA 
              :pfa-id="pfaId" 
              :pfaAction="'ADD'" 
              :options-cargo-type="optionsCargoType" 
              :optionsFleets="optionsFleets" 
              :openPfaSlide="openPfaSlide" 
              :refreshDataTable="refreshDataTable" 
              :onExportPFA="onExportPFA" 
            />
          </template>
        </b-sidebar>
        <!-- SLIDE - PFA END -->
        
        <!-- SLIDE - SPAL PROCESS -->
        <b-sidebar
          v-model="slideSpalProcess"
          id="slideSpalProcess"
          title="SPAL Process"
          width="70%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideSpalProcess 
              :id="editId"
              :closeSlideSpalProcess="closeSlideSpalProcess"
              :refreshDataTableWithoutCloseModal="refreshDataTableWithoutCloseModal"
              :refreshDataTable="refreshDataTable"
              :action="'ADD'"
            />
          </template>
        </b-sidebar>
        <!-- SLIDE - SPAL PROCESS END -->

        <!-- SLIDE - COLLECTION FORMS -->
        <b-sidebar
          v-model="slideProjectForms"
          id="slideProjectForms"
          :title="projectName + '/Collection of Forms'"
          width="80%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideProjectForms 
              :form-id="editId" 
              :open-inspection-checklist-slide="openInspectionChecklistSlide" 
            />
          </template>
        </b-sidebar>
        <!-- SLIDE - COLLECTION FORMS END -->
        
        <!-- SLIDE - INSPECTION CHECKLIST -->
        <b-sidebar
          v-model="isPreviewInspectionChecklist"
          id="isPreviewInspectionChecklist"
          :title="'Preview Inspection Checklist'"
          width="70%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideInspectionChecklist
              :is-preview-inspection-checklist="isPreviewInspectionChecklist"
              :inspection-checklist-data="inspectionChecklistData"
            />
          </template>
        </b-sidebar>
        <!-- SLIDE - INSPECTION CHECKLIST END -->  
        
        <!-- SLIDE - CREATE INSPECTION CHECKLIST -->
        <b-sidebar
          v-model="isAddInspectionChecklist"
          id="isAddInspectionChecklist"
          :title="'Create Inspection Checklist'"
          width="70%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <div class="p-3">
              <SlideCreateInspectionChecklist
                :inspection-checklist-data="inspectionChecklistData"
                :refresh-slide-forms="refreshSlideForms"
                :isAddInspectionChecklist="isAddInspectionChecklist"
              />
            </div>
          </template>
        </b-sidebar>
        <!-- SLIDE - INSPECTION CHECKLIST END --> 

        <!-- MODAL APPROVAL -->
        <b-modal
          id="project-approval"
          size="md"
          title="APPROVAL"
          cancel-title="Close"
          hide-footer
        >
          <template #default="{ hide }">
            <b-row>
              <b-col md="12">
                <b-form-textarea
                  rows="3"
                  name="remarks"
                  placeholder="Remarks..."
                  class="mb-2"
                  v-model="remarks"
                ></b-form-textarea>
              </b-col>
              <b-col lg="12">
                <div class="border d-flex w-100 my-4" />
              </b-col>
              <b-col lg="12" v-if="approval_loading" align="center">
                <span class="spinner-border spinner-border-sm" />
              </b-col>
              <b-col lg="6" v-if="!approval_loading">
                <b-button variant="warning" block @click="onSaveApproval('rejected')">
                  <i class="fa fa-ban mr-2" ></i> Reject
                </b-button> 
              </b-col>
              <b-col lg="6" v-if="!approval_loading">
                <b-button variant="success" block @click="onSaveApproval('approved')">
                  <i class="fa fa-check mr-2" />Approve
                </b-button> 
              </b-col>
            </b-row>
          </template>
        </b-modal>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'
import GanttChart from '@src/components/busdev/GanttChart.vue'
import Kanban from '@src/components/busdev/Kanban.vue' 
import FormAddPlan from './FormAddPlan.vue' 
import FormEditAndDetailPlan from './FormEditAndDetailPlan.vue' 
import SlideProjectForms from './SlideProjectForms.vue' 
import SlideFormPFA from './SlideFormPFA.vue' 
import SlideInspectionChecklist from './SlideInspectionChecklist.vue' 
import SlideCreateInspectionChecklist from './SlideCreateInspectionChecklist.vue' 
import SlideSpalProcess from './SlideSpalProcess.vue' 
import FormInspectionChecklist from '../InspectionChecklist/FormInspectionChecklist.vue' 
import { saveAs } from 'file-saver'

export default {
  name: 'TabShipmentPlanning',
  data() {
    return {
      action:'',

      exporting: false,
      saving: false,
      loading:false,
      detail_project_loading:false,
      fleet_loading:false,

      projectName:'',
      view_style:'list',

      isEdit:false,
      editId:null,
      pfaId:null,

      approval_loading:false,
      projectId:null,
      remarks:'',
      
      slideSpalProcess:false,
      slideAddPFA:false,
    
      slideDetailProjectContract: false,
      slideAddRakor: false,
      slideDetailProject: false,
      slideProjectForms: false,

      isPreviewInspectionChecklist: false,
      isAddInspectionChecklist: false,
      inspectionChecklistData: null,

      showModalAddCard: false,

      projectOptions: [],
      optionsCompanies: [],
      optionsCustomerClass: [],
      optionsTerms: [],
      optionsFleets: [],
      optionsContractType: [],
      optionsCargoType: [],
      optionsStage:[],
      listProjectStage:[],
      
      ownership: [
        {
          name: 'ownship',
          label: 'OWN SHIP',
          value: 'own-ship',
          disabled: false
        },
        {
          name: 'chartered',
          label: 'CHARTERED',
          value: 'chartered',
          disabled: false
        }
      ],

      dateRange:[
        new Date(moment().startOf('year')),
        new Date(moment().endOf('year')),
      ],
      params:{
        periode_start:null,
        periode_end:null,
        contract_type_id:null,
        cargo_type_id:null,
        fleet_ownership:'',
        keyword:'',
        vehicle_id:null,
        stage_id:null
      },

      listDetailShipmentPlanning:[],
      listShipmentPlanningTable: {
        data:[],
        meta:null
      },  
      
      listShipment:[],
      projects:[],

      kanbanData:[],
      ganttChartRows: [],

      userProfileId: JSON.parse(localStorage.getItem('profile')).id,
      userProfileName: JSON.parse(localStorage.getItem('profile')).userDetail.name,
      modal:{
        filter:false
      }
    }
  },
  async mounted () {
    await this.getListShipments()
    await this.getListShipmentPlanningTable()
    await this.getListProjectStage()
    await this.getContractType()
    await this.getCargoType()
    await this.getTerms()
    await this.fetchListProjects()
    await this.getListCustomerClass()
    await this.getProjectList()
    await this.fetchFleets() 
    await this.fetchPerusahaan() 

    this.combineDataIntoKanban()
    this.convertDataIntoGanttChart()
  },
  components:{
    GanttChart,
    Kanban,
    FormAddPlan,
    FormEditAndDetailPlan,
    SlideProjectForms,
    SlideFormPFA,
    SlideInspectionChecklist,
    FormInspectionChecklist,
    SlideCreateInspectionChecklist,
    SlideSpalProcess,
  },
  computed:{
    addPermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Business Development Record').add

      return hasAccess
    },
    updatePermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Business Development Record').update

      return hasAccess
    },
    deletePermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Business Development Record').delete

      return hasAccess
    },
  },

  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    ...busdevActions,
    ...crewingActions,
    toggleModalFilter(){
      this.modal.filter = !this.modal.filter
    },
    showVehicleName(value){
      let vehicle = this.optionsFleets.find(obj => obj.value == value)

      return vehicle?.text ?? '-'
    },
    updateAction(newAction){
      this.action = newAction
    },
    
    openSlideFormAddPlan(value, id){
      this.editId = null
      this.action = value
      this.slideAddRakor = !this.slideAddRakor;

      if(id) this.editId = id
    },
    openSlideSpalProcess(value, id){
      this.editId = null
      this.slideSpalProcess = !this.slideSpalProcess;

      if(id) this.editId = id
    }, 
  
    closeSlideSpalProcess(){
      this.editId = null
      this.slideSpalProcess = false
    },
    handleOpenModalApproval(id){
      this.projectId  = id
    },
    approvalPermission(userArr){
      let tempArr = userArr?.map(val => val.id)
      let countApproval = userArr?.filter(user => user.status_approval !== "").length
      let manyApproval = userArr?.length

      if (countApproval == manyApproval){
        return false
      } else if(tempArr.includes(this.userProfileId)){
        return true
      }  else { 
        return false
      }
      // return true
    },
    prioritizeProject(prioritize){
      if(prioritize == '1'){
        return 'Low'
      } else if (prioritize == '2'){
        return 'Medium'
      }else if (prioritize == '3'){
        return 'High'
      }else if (prioritize == '4'){
        return 'Very High'
      }else if (prioritize == '5'){
        return 'Immediately'
      } else {
        return "-"
      }
      // 'Prioritize: 1 = low, 2 = medium, 3 = high, 4 = very high, 5 = immediately'
    },
    async refreshSlideForms(id){
      await this.getListShipments()
      await this.getListShipmentPlanningTable()

      this.editId = null

      this.slideProjectForms = false

      this.isPreviewInspectionChecklist = false
      this.isAddInspectionChecklist = false
    },
    openInspectionChecklistSlide(isPreview, isAdd, data, form_id, vehicle_id, vehicleType){
      this.inspectionChecklistData = {}

      if(isPreview){
        this.isPreviewInspectionChecklist = isPreview
        this.isAddInspectionChecklist = false
        this.inspectionChecklistData = {
          ...data,
          code: data?.collection_of_forms?.code,
          name: data?.collection_of_forms?.name,
          description: data?.collection_of_forms?.description,
          status: data?.collection_of_forms?.status,
          using_remarks: data?.collection_of_forms?.using_remarks,
          question_type_id: data?.collection_of_forms?.question_type_id,
          question_type: data?.collection_of_forms?.question_type,
          question_type_content: data?.collection_of_forms?.question_type_content ? JSON.parse(data?.collection_of_forms?.question_type_content) : [],
        }
        this.inspectionChecklistData.vehicle_id = vehicle_id
        this.inspectionChecklistData.vehicle_type = vehicleType
        this.inspectionChecklistData.form_id = form_id
      } else if (isAdd){
        this.isPreviewInspectionChecklist = false
        this.isAddInspectionChecklist = isAdd

        this.inspectionChecklistData.code = data?.inspection_checklist[0]?.collection_of_forms?.code
        this.inspectionChecklistData.name = data?.inspection_checklist[0]?.collection_of_forms?.name
        this.inspectionChecklistData.form_id = form_id
        this.inspectionChecklistData.vehicle_id = vehicle_id
        this.inspectionChecklistData.vehicle_type = vehicleType
      }
    }, 
    openProjectFormSlide(id, name){
      this.editId = null
      this.slideProjectForms = true

      this.projectName = name
      this.editId = id
    }, 
    openPfaSlide(id){
      this.slideAddPFA = !this.slideAddPFA
      this.pfaId = id
    }, 
    openSlideDetailProjectContract(id){
      this.editId = id
      this.slideDetailProjectContract = !this.slideDetailProjectContract
    },
    refreshData(){
      this.slideNewCustomer = false
      this.slideAddRakor = true
    },
    async refreshDataTableWithoutCloseModal(){
      await this.getListShipments()
      await this.getListShipmentPlanningTable()
      this.combineDataIntoKanban()
      this.convertDataIntoGanttChart()
    },
    async refreshDataTable(){
      this.slideNewCustomer = false
      this.slideDetailProjectContract = false
      this.slideAddRakor = false
      this.slideSpalProcess = false
      this.editId = null
      this.action = ''

      await this.getListShipments()
      await this.getListShipmentPlanningTable()
      this.combineDataIntoKanban()
      this.convertDataIntoGanttChart()
    },
    async fetchPerusahaan() {
      let res = await this.fetchCompanies()
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsCompanies.push({
              value: state.id,
              text: state.company,
            })
          })
        }
      }
    },
    async getListCustomerClass() {
      let params = {
        page:1, 
        perPage: 9999999
      }
      let res = await this.fetchCustomerClass(params)
      if (res.data.status === true) {
        if(res?.data?.data?.data?.length > 0)
          res?.data?.data?.data?.map((state) => {
            this.optionsCustomerClass.push({
              value: state.id,
              text: state.name,
            })
          })
        
      }
    },
    async getTerms() {
      let res = await this.fetchTerms()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsTerms.push({
            value: state.id,
            text: state.name,
          })
        })
      }
    },  
    async getCargoType() {
      let res = await this.fetchCargoType()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsCargoType.push({
            ...state,
            value: state.id,
            text: state.name,
          })
        })
      }
    }, 
    async getContractType() {
      let res = await this.fetchContractType()
      if (res.data.status === true && res.data.data.length > 0) {
        this.optionsContractType = res.data.data
      }
    },

    async fetchListProjects () {
      this.projects = []
      let params = {
        perPage: 10000,
        page: 1
      }

      let res = await this.getProjects(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.projects = res.data.data
        }
      } 
    },
    async getProjectList () {
      const res = await this.fetchProjects()
      if (res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.projectOptions.push({
              ...val,
              text: `${val.project.project_name} - ${val.name}`,
              value: val.id
            })
          })
        }
      }
    },
    async fetchFleets(ownership) {
      this.fleet_loading = true
      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        ownership
      })

      if(!params.ownership) delete params.ownership
      
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.optionsFleets = res.data.map((val) => ({
            text: val.name,
            value: val.id,
          }))
        }
      }

      this.fleet_loading = false
    },
    async getListProjectStage () {
      this.listProjectStage = []
      let res = await this.fetchDetailProjectStage()
      if (res?.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.optionsStage = res.data.data.map(val => {
            return {
              text:val.title,
              value:val.id
            }
          })

          this.optionsStage = this.optionsStage.filter(obj => ['Running', 'Waiting', 'Rejected'].includes(obj.text))
          this.listProjectStage = res.data.data
        }
      } 
    },
    
    // GANTT CHART
    convertDataIntoGanttChart(){
      this.ganttChartRows = []
      this.listShipment.map((val, index) => {
        this.ganttChartRows.push({
          label: `#${index+1}-${val.project}`,
          id:val.id,
          bars: [
            {
              start: moment(val.periode_start).format('YYYY-MM-DD HH:mm'),
              end: moment(val.periode_end).format('YYYY-MM-DD HH:mm'),
              label: `${val.project_type === 'new_poject' ? 'New Project' : 'Existing' } - ${val.volume} MT - ${val.shipment} SHIPMENT`,
              project_contract_id:val.id,
              ganttBarConfig: {
                color: 'white',
                backgroundColor: '#408e2f',
                handles: false,
                immobile: true,
                id:val.id
              }
            }
          ]
        })
      })
    },
    async onUpdatePeriodeDate(id, start, end){
      let payload = {
        id,
        periode_start: moment(start).format('YYYY-MM-DD'),
        periode_end: moment(end).format('YYYY-MM-DD')
      }

      this.$swal({
          title: `Are you sure want to change periode?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.updatePeriodeDate({data: payload})

            if(res.data.status === true){
              await this.getListShipments()
              await this.getListShipmentPlanningTable()
              this.convertDataIntoGanttChart()
              this.combineDataIntoKanban()
              this.$swal(
                'Success!',
                'Success update periode.',
                'success'
              )
            }
          }
      })
    }, 

    async onSaveApproval(status_approval){
      this.approval_loading = true
      let payload = {
        id: Number(this.userProfileId),
        status_approval: status_approval,
        name: this.userProfileName,
        remarks: this.remarks,
      }   

      if(this.projectId){
        this.$swal({
            title: `Are you sure want to ${status_approval}?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${status_approval} it!`
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.saveApprovalUser({data: payload, id: Number(this.projectId)})

            if(res.data.status === true){
              this.$swal(
                'Success!',
                'Success ' + status_approval,
                'success'
              )

              await this.getListShipments()
              await this.getListShipmentPlanningTable()
              this.convertDataIntoGanttChart()
              this.combineDataIntoKanban()

              this.approval_loading = false
              this.projectId = null
              this.remarks = ''

              this.$nextTick(() => {
                this.$bvModal.hide('project-approval')
              })
            } else {
              this.approval_loading = false
              this.projectId = null
              this.remarks = ''
              
              this.$nextTick(() => {
                this.$bvModal.hide('project-approval')
              })
              this.$swal(
                'Oops!',
                res?.data?.message,
                'error'
              )
            }
          }
        })
      }

      this.approval_loading = false
    },

    // KANBAN 
    combineDataIntoKanban(){
      if(this.listProjectStage?.length > 0) {
        let tempData = []
        this.kanbanData = []

        this.listProjectStage.map(val => {
          let tempTasks = []

          this.listShipment.map(obj => {
            if(val.id === obj.detail_project_stage_id){
              tempTasks.push(obj)
            }
          })
          
          tempData.push({
            ...val,
            edit:false, 
            tasks: tempTasks
          })
        })

        this.kanbanData = tempData
      }
    },
    async onAddList(columnTitle){
      let lastObject = this.listProjectStage.slice(-1)[0];

      let payload = {
        title: columnTitle,
        order: lastObject?.order + 1,
      }

      const res = await this.createDetailProjectStage({data: payload})

      if(res?.data?.status == true){
        this.kanbanData.push({
          ...res.data.data,
          tasks:[]
        })
      }
    },
    async onDenied(id){
      let data = this.listProjectStage.map(val => {
        if(val.id === id){
          val.is_deny = 1
        } else {
          val.is_deny = 0
        }

        return val
      })

      let payload = {
        project_stage_id:id,
        data
      }

      const res = await this.changeDenyStage({data: payload})
      console.log(res)

      if(res?.data?.status == true){
        this.listProjectStage = payload.data
        this.combineDataIntoKanban()
      } else {
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }
    },
    async onSpalProcess(id){
      let data = this.listProjectStage.map(val => {
        if(val.id === id){
          val.spal_process = 1
        } else {
          val.spal_process = 0
        }

        return val
      })

      let payload = {
        project_stage_id:id,
        data
      }

      const res = await this.changeSpalProcessStage({data: payload})

      if(res?.data?.status == true){
        this.listProjectStage = payload.data
        this.combineDataIntoKanban()
      } else {
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }
    },
    async onRunningProcess(id){
      let data = this.listProjectStage.map(val => {
        if(val.id === id){
          val.is_running = 1
        } else {
          val.is_running = 0
        }

        return val
      })

      let payload = {
        project_stage_id:id,
        data
      }

      const res = await this.changeRunningProcessStage({data: payload})

      if(res?.data?.status == true){
        this.listProjectStage = payload.data
        this.combineDataIntoKanban()
      } else {
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }
    },
    async onEditTitle(id, title){
      let payload = {
        id,
        title
      }

      console.log(id)
      console.log(title)

      const res = await this.updateStageTitle({data: payload})

      if(res?.data?.status == true){
        let kanban = this.kanbanData.find(obj => obj.id === id)

        kanban.title = title
      }
    },
    async onCardMove(){
      let readyToSave = []
      this.kanbanData.map(val => {
        if(val.tasks.length > 0){
          readyToSave.push({
            detail_project_stage_id: val.id,
            tasks:val.tasks.map(obj => {
              return {
                id: obj.id
              }
            })
          })
        }
      })

      const res = await this.saveMovedProjects({data: readyToSave})

      if(res?.data?.status === true){
        console.log('Saved Moved Card')
        await this.getListShipments()
        await this.getListShipmentPlanningTable()
      }
    }, 
    async onMoveSetEndStage(){
      let readyToSave = []
 
      this.kanbanData.map((val, index) => {
        readyToSave.push({
          id:val.id,
          order: index + 1,
          is_end_stage: 0
        })
      })

      let lastIndex = readyToSave.length - 1
      readyToSave[lastIndex].is_end_stage = 1

      const res = await this.savedMovedStage({data: readyToSave})

      if(res?.data?.status === true){
        console.log('Saved Moved Stage')
        await this.getListProjectStage()
        this.combineDataIntoKanban()
      }
    },
    async onRemoveList(column){
      if(column.tasks.length > 0) return this.$swal(`Oops!`, `Cannot Delete Stage, when there's a project in it.`, 'warning')

      const res = await this.deleteDetailProjectStage({id: column.id})

      if(res?.data?.status === true){
        await this.getListProjectStage()
        this.combineDataIntoKanban()
        console.log('List Deleted')
      }
    },  
    async onChangeStatusStage(index, id, project_stage_id){
      let payload = {
        id,
        project_stage_id
      }

      const res = await this.changeStatusStage({data: payload})

      if(res.data.status === true){
        console.log('Status Stage Changed')
        this.refreshDataTable()
        await this.getListProjectStage()
        this.combineDataIntoKanban()
      }
    },
    async onChangePrioritize(id, value){
      let payload = {
        id,
        prioritize: value.toString(),
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
        "created_date": moment().format('YYYY-MM-DD'),
      }
      this.$swal({
          title: `Are you sure want to change priority?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.changeValuePrioritize({data: payload})

            if(res.data.status === true){
              console.log('Prioritize Changed')
              await this.getListShipments()
              await this.getListShipmentPlanningTable()
              this.combineDataIntoKanban()
            }
          }
      })
    },
    async getListShipmentPlanningTable (parameter){
      this.loading = true
      this.listShipmentPlanningTable = {
        data:[],
        meta:null
      }
      let params = {  
        page:1,
        perPage:50,
        status:1,
        ...parameter
      }

      let res = await this.fetchShipmentPlanningTable(params)

      if (res.data.status === true && res.data.data?.data.length > 0) {
        this.listShipmentPlanningTable.data = res.data.data?.data
        this.listShipmentPlanningTable.meta = res.data.data?.meta
      } 
      this.loading = false
    },   
    
    async getListShipments (parameter){
      this.listShipment = []
      let params = {  
        status:1,
        ...parameter
      }

      let res = await this.fetchShipmentPlanningTableAll(params)

      if (res.data.status === true && res.data.data?.data.length > 0) {
        this.listShipment = res.data.data?.data
      } 
    },  

    // ON DELETE
    async onDeleteProjectContract(data){
      this.$swal({
          title: `Are you sure want to delete this?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
            let payloadDelete = {
              name: this.userProfileName,
              date_log: moment().format('YYYY-MM-DD'),
              user_id: Number(this.userProfileId)
            }
            const res = await this.deleteProjectContract({id: data.id, data:payloadDelete })

            if(res.data?.status === true ){
              await this.refreshDataTable()

              this.$swal(
                'Success!',
                'Success deleted data.',
                'success'
              )
            } else {
              this.$swal(
                  'Failed!',
                  'Something went wrong.',
                  'error'
              )
              return 
            }
          }
      })
    },

    // FILTER
    async onFilter(){
      let params = {
        ...this.params,
        periode_start:this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('YYYY-MM-DD') : null ,
        periode_end:this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('YYYY-MM-DD') : null,
      }

      if(!params.keyword) delete params.keyword
      if(!params.cargo_type_id) delete params.cargo_type_id
      if(!params.contract_type_id) delete params.contract_type_id
      if(!params.fleet_ownership) delete params.fleet_ownership
      if(!params.periode_start) delete params.periode_start
      if(!params.periode_end) delete params.periode_end

      await this.getListShipments(params)
      await this.getListShipmentPlanningTable(params)
      this.combineDataIntoKanban()
      this.convertDataIntoGanttChart()
    },

    async onExportPFA(project_contract_id, file){
      let params = {
        project_contract_id,
        file
      }
      const res = await this.exportPFA(params)

      if(file == 'excel'){
      if(res.status === 'success'){
        saveAs(res.data, 'PRE FIXTURE ANALYSIS.xlsx')
      } else {
        console.log('Something went wrong')
      }
      } else if(file == 'pdf'){
      if(res.status === 'success'){
        saveAs(res.data, 'PRE FIXTURE ANALYSIS.pdf')
      } else {
        console.log('Something went wrong')
      }
      } else {
      console.log('Something went wrong')
      }
      
    }, 
    async onExportShipmentPlanning() {
        this.exporting = true
        try {
          let params = {
            ...this.params,
          }

          for (const prop in params) {
            if (params.hasOwnProperty(prop)) {
              // Check conditions for each property and delete if necessary
              if ((params[prop] === '') || (params[prop] === null)) {
                delete params[prop];
              }
            }
          }

          let res = await this.exportShipmentPlanningTable(params)

          if(res.status === 'success'){
            saveAs(res.data, 'List Shipment Planning.xlsx')
          }
        } catch (error) {
          this.$swal(
            'Failed Export!',
            'Something went wrong.',
            'error'
          ) 
        }

        this.exporting = false
      },
  }
}
</script>

<style lang="scss">
$on-hold: #FB7D44;
$in-progress: #2A92BF;
$needs-review: #F4CE46;
$approved: #00B961;
* {
  box-sizing: border-box;
}
body {
  background: #33363D;
  color: white;
  font-family: "Lato";
  font-weight: 300;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
}
.drag-column {
  .drag-column-header > div {
    width: 100%;
    h5 > a {
      float: right;
    }
  }
  &-on-hold {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $on-hold;
    }
  }
  &-in-progress {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $in-progress;
    }
  }
  &-needs-review {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $needs-review;
    }
  }
  &-approved {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $approved;
    }
  }
}
.section {
  padding: 20px;
  text-align: center;
  a {
    color: white;
    text-decoration: none;
    font-weight: 300;
  }
  h4 {
    font-weight: 400;
    a {
      font-weight: 600;
    }
  }
}
</style>
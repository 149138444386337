var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border p-1"
  }, [_c('h6', {
    staticClass: "mb-1",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("Proximity Level Indicator")]), _c('table', {
    staticClass: "table table-striped table-sm mb-0"
  }, [_c('tbody', _vm._l(_vm.indicators, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_vm._v(_vm._s(index + 1))]), _c('b-col', {
      staticClass: "px-3",
      attrs: {
        "cols": "6"
      }
    }, [_c('b-badge', {
      staticClass: "w-100 py-1",
      attrs: {
        "variant": item.variant
      }
    }, [_vm._v(_vm._s(item.label))])], 1)], 1)], 1)]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
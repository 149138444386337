var render = function render(){
  var _vm$tripData, _vm$tripData2, _vm$tripData2$tripRec, _vm$tripData3, _vm$tripData3$tripRec, _vm$tripData4, _vm$tripData4$tripRec, _vm$tripData5, _vm$tripData5$tripRec, _vm$tripData6, _vm$tripData6$tripRec, _vm$tripData7, _vm$tripData7$tripRec, _vm$tripData8, _vm$tripData8$tripRec, _vm$tripData9, _vm$tripData9$matrix, _vm$tripData9$matrix$, _vm$tripData9$matrix$2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-3"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h6', {
    staticClass: "mb-1",
    staticStyle: {
      "color": "#3a5de7"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.projectName))])]), _c('div', _vm._l((_vm$tripData = _vm.tripData) === null || _vm$tripData === void 0 ? void 0 : _vm$tripData.vehicle, function (vehicle, vehicleIndex) {
    return _c('b-badge', {
      staticClass: "p-2 ml-1",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(vehicle === null || vehicle === void 0 ? void 0 : vehicle.name))]);
  }), 1)])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("POL")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v(_vm._s((_vm$tripData2 = _vm.tripData) === null || _vm$tripData2 === void 0 ? void 0 : (_vm$tripData2$tripRec = _vm$tripData2.tripRecord) === null || _vm$tripData2$tripRec === void 0 ? void 0 : _vm$tripData2$tripRec.portLoading))])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Actual COB")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v(_vm._s((_vm$tripData3 = _vm.tripData) === null || _vm$tripData3 === void 0 ? void 0 : (_vm$tripData3$tripRec = _vm$tripData3.tripRecord) === null || _vm$tripData3$tripRec === void 0 ? void 0 : _vm$tripData3$tripRec.cargoUnloading) + " MT")])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("POD")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v(_vm._s((_vm$tripData4 = _vm.tripData) === null || _vm$tripData4 === void 0 ? void 0 : (_vm$tripData4$tripRec = _vm$tripData4.tripRecord) === null || _vm$tripData4$tripRec === void 0 ? void 0 : _vm$tripData4$tripRec.portDischarge))])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Cargo Type")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v(_vm._s((_vm$tripData5 = _vm.tripData) === null || _vm$tripData5 === void 0 ? void 0 : (_vm$tripData5$tripRec = _vm$tripData5.tripRecord) === null || _vm$tripData5$tripRec === void 0 ? void 0 : _vm$tripData5$tripRec.cargoType))])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Arrival Time POL")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v(_vm._s((_vm$tripData6 = _vm.tripData) === null || _vm$tripData6 === void 0 ? void 0 : (_vm$tripData6$tripRec = _vm$tripData6.tripRecord) === null || _vm$tripData6$tripRec === void 0 ? void 0 : _vm$tripData6$tripRec.polArrivalTime))])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Completed")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v(_vm._s((_vm$tripData7 = _vm.tripData) === null || _vm$tripData7 === void 0 ? void 0 : (_vm$tripData7$tripRec = _vm$tripData7.tripRecord) === null || _vm$tripData7$tripRec === void 0 ? void 0 : _vm$tripData7$tripRec.podCompleted))])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Arrival Time POD")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v(_vm._s((_vm$tripData8 = _vm.tripData) === null || _vm$tripData8 === void 0 ? void 0 : (_vm$tripData8$tripRec = _vm$tripData8.tripRecord) === null || _vm$tripData8$tripRec === void 0 ? void 0 : _vm$tripData8$tripRec.podArrivalTime))])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Total Steaming Time")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v(_vm._s((_vm$tripData9 = _vm.tripData) === null || _vm$tripData9 === void 0 ? void 0 : (_vm$tripData9$matrix = _vm$tripData9.matrix) === null || _vm$tripData9$matrix === void 0 ? void 0 : (_vm$tripData9$matrix$ = _vm$tripData9$matrix.find(function (obj) {
    return obj.name === 'Total Steaming';
  })) === null || _vm$tripData9$matrix$ === void 0 ? void 0 : (_vm$tripData9$matrix$2 = _vm$tripData9$matrix$.duration) === null || _vm$tripData9$matrix$2 === void 0 ? void 0 : _vm$tripData9$matrix$2.toLocaleString()))])])]), _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('p', {
    staticClass: "text-black mb-1",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("Input Type")]), _c('div', {
    staticClass: "btn-group"
  }, _vm._l(_vm.inputTypeOpt, function (btn, i) {
    return _c('b-button', {
      key: i,
      class: {
        active: _vm.inputTypeSelected == btn
      },
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          _vm.inputTypeSelected = btn;
        }
      }
    }, [_vm._v(_vm._s(btn))]);
  }), 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }), _vm.inputTypeSelected === 'By Shipment Plan Data' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("Project")]), _c('div', {
    staticClass: "d-flex overflow-auto",
    staticStyle: {
      "max-height": "300px"
    }
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center table-info text-truncate",
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("SHIPPER")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("TYPE")]), _c('th', {
    staticClass: "text-right table-info text-truncate"
  }, [_vm._v("SHIPMENT PLAN")]), _c('th', {
    staticClass: "text-right table-info text-truncate"
  }, [_vm._v("VOLUME PLAN")]), _c('th', {
    staticClass: "text-right table-info text-truncate"
  }, [_vm._v("SHIPMENT REAL")]), _c('th', {
    staticClass: "text-right table-info text-truncate"
  }, [_vm._v("VOLUME REAL")]), _c('th', {
    staticClass: "text-center table-info"
  }, [_vm._v("STAGE")])])]), _vm.listRunning.data.length > 0 ? _c('tbody', _vm._l(_vm.listRunning.data, function (state, index) {
    var _state$customer$accou, _state$customer, _state$cargo_type, _state$volume, _state$volume_real;
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center bg-white text-black"
    }, [_c('b-form-checkbox', {
      on: {
        "change": function change($event) {
          return _vm.onChecked(index);
        }
      },
      model: {
        value: state.checked,
        callback: function callback($$v) {
          _vm.$set(state, "checked", $$v);
        },
        expression: "state.checked"
      }
    })], 1), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "size": "34",
        "variant": "warning",
        "src": require('../../../assets/images/user/11.png')
      }
    }), _c('h6', {
      staticClass: "ml-2 text-primary"
    }, [_c('strong', [_vm._v(" " + _vm._s((_state$customer$accou = state === null || state === void 0 ? void 0 : (_state$customer = state.customer) === null || _state$customer === void 0 ? void 0 : _state$customer.account_name) !== null && _state$customer$accou !== void 0 ? _state$customer$accou : 'N/A') + " ")])])], 1)]), _c('td', {
      staticClass: "text-center text-truncate text-primary"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$cargo_type = state.cargo_type) === null || _state$cargo_type === void 0 ? void 0 : _state$cargo_type.name))]), _c('td', {
      staticClass: "text-right text-truncate text-primary"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.shipment) + " SHIPMENT")]), _c('td', {
      staticClass: "text-right text-truncate text-primary"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.volume ? state === null || state === void 0 ? void 0 : (_state$volume = state.volume) === null || _state$volume === void 0 ? void 0 : _state$volume.toLocaleString() : '0') + " " + _vm._s(state === null || state === void 0 ? void 0 : state.unit))]), _c('td', {
      staticClass: "text-right text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.shipment_real) + " SHIPMENT")]), _c('td', {
      staticClass: "text-right text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.volume_real ? state === null || state === void 0 ? void 0 : (_state$volume_real = state.volume_real) === null || _state$volume_real === void 0 ? void 0 : _state$volume_real.toLocaleString() : '0') + " " + _vm._s(state === null || state === void 0 ? void 0 : state.unit))]), _c('td', {
      staticClass: "text-center bg-white text-black"
    }, [_c('b-dropdown', {
      staticClass: "float-right mr-1",
      attrs: {
        "disabled": "",
        "variant": "link iq-bg-primary",
        "toggle-class": "text-decoration-none",
        "no-caret": "",
        "size": "sm"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          var _state$project_stage;
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : (_state$project_stage = state.project_stage) === null || _state$project_stage === void 0 ? void 0 : _state$project_stage.title) + " ")])];
        },
        proxy: true
      }], null, true)
    })], 1)]);
  }), 0) : _vm._e()])]), _vm.listRunning.data.length > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.listRunning.meta.totalItem,
      "per-page": _vm.listRunning.meta.perPage,
      "first-number": "",
      "align": "center"
    },
    on: {
      "change": _vm.getListRunningShipmentTable
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.inputTypeSelected === 'By Shipment Plan Data' && _vm.form.bdProjectContractParentId ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("Project Plan")]), _c('div', {
    staticClass: "d-flex overflow-auto",
    staticStyle: {
      "max-height": "300px"
    }
  }, [_c('table', {
    staticClass: "table table-sm table-bordered"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center table-info text-truncate table-info align-middle",
    staticStyle: {
      "width": "5px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-center table-info text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("SHIPMENT PLAN DETAIL NUMBER")]), _c('th', {
    staticClass: "text-center table-info text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("DESCRIPTION")]), _c('th', {
    staticClass: "text-center table-info text-truncate",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center table-info text-truncate",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center table-info text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("DRAUGHT TYPE")]), _c('th', {
    staticClass: "text-center table-info text-truncate",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("LOCATION")]), _c('th', {
    staticClass: "text-center table-info text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("SHIPMENT MONTH")]), _c('th', {
    staticClass: "text-center table-info text-truncate",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PLAN DATE")]), _c('th', {
    staticClass: "text-center table-info text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PLAN QUANTITY")]), _c('th', {
    staticClass: "text-center table-info text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("REMARKS")])]), _c('tr', [_c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("OWNERSHIP")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("TYPE")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("FLEETS")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("TYPE")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("ACC. DATE")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("TERMS")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("POL")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("POD")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("ETA POL")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("ETA POD")])])]), _vm.listProjectPlan.length > 0 ? _c('tbody', _vm._l(_vm.listProjectPlan, function (state, index) {
    var _state$fleet_type, _state$cargo_type2, _state$terms;
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center bg-white text-black"
    }, [_c('b-form-checkbox', {
      on: {
        "change": function change($event) {
          return _vm.onCheckedPlan(index);
        }
      },
      model: {
        value: state.checked,
        callback: function callback($$v) {
          _vm.$set(state, "checked", $$v);
        },
        expression: "state.checked"
      }
    })], 1), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.shipment_plan_detail_number))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.description))]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase mb-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(state.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED'))])], 1), _c('td', {
      staticClass: "text-truncate"
    }, [_c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase mb-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s((_state$fleet_type = state.fleet_type) === null || _state$fleet_type === void 0 ? void 0 : _state$fleet_type.name))])], 1), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_vm._l(state.fleets, function (objDetail, detailIndex) {
      return [objDetail.vehicle_id ? _c('b-badge', {
        key: detailIndex,
        staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(_vm._s(_vm.showVehicleName(objDetail.vehicle_id)))]) : _vm._e()];
    })], 2)]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$cargo_type2 = state.cargo_type) === null || _state$cargo_type2 === void 0 ? void 0 : _state$cargo_type2.name))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.acceptance_date ? _vm.moment(state === null || state === void 0 ? void 0 : state.acceptance_date).format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$terms = state.terms) === null || _state$terms === void 0 ? void 0 : _state$terms.name))]), _c('span', [_vm._v("Remarks: " + _vm._s(state === null || state === void 0 ? void 0 : state.terms_remarks))])])]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(_vm.capitalizeString(state === null || state === void 0 ? void 0 : state.draught_type)))]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex w-100"
    }, _vm._l(state.pol, function (obj, polIndex) {
      var _obj$value;
      return _c('b-badge', {
        key: polIndex,
        staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(" " + _vm._s((obj === null || obj === void 0 ? void 0 : obj.value) === "Others" ? obj.valueOther : (_obj$value = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value !== void 0 ? _obj$value : '-') + " ")]);
    }), 1)]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex w-100"
    }, _vm._l(state.pod, function (obj, podIndex) {
      var _obj$value2;
      return _c('b-badge', {
        key: podIndex,
        staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(" " + _vm._s((obj === null || obj === void 0 ? void 0 : obj.value) === "Others" ? obj.valueOther : (_obj$value2 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value2 !== void 0 ? _obj$value2 : '-') + " ")]);
    }), 1)]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.shipment))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state.eta_pol ? _vm.moment(state.eta_pol).format('DD MMM YYYY') : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state.eta_pod ? _vm.moment(state.eta_pod).format('DD MMM YYYY') : '-'))]), _c('td', {
      staticClass: "text-truncate text-right"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.plan_quantity.toLocaleString()))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.remarks))])]);
  }), 0) : _vm._e()])])]) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currency",
      "label-for": "currency"
    }
  }, [_c('v-select', {
    staticClass: "mr-2 w-100",
    staticStyle: {
      "background-color": "#ffffff"
    },
    attrs: {
      "label": "text",
      "options": _vm.optionsCurrency,
      "reduce": function reduce(optionsCurrency) {
        return optionsCurrency.value;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.currencyId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currencyId", $$v);
      },
      expression: "form.currencyId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Freight Rate",
      "label-for": "fr"
    }
  }, [_c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "id": "fr",
      "type": "number"
    },
    model: {
      value: _vm.form.freightRateValue,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "freightRateValue", $$v);
      },
      expression: "form.freightRateValue"
    }
  }), _c('small', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(parseFloat(_vm.form.freightRateValue).toLocaleString()))])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "inline": ""
    },
    model: {
      value: _vm.form.taxable,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "taxable", $$v);
      },
      expression: "form.taxable"
    }
  }, [_c('b-form-radio', {
    key: "include",
    attrs: {
      "value": "include",
      "name": "ppn"
    }
  }, [_vm._v("Include PPN")]), _c('b-form-radio', {
    key: "exclude",
    attrs: {
      "value": "exclude",
      "name": "ppn"
    }
  }, [_vm._v("Exclude PPN")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.form.taxable === 'include' ? _c('b-form-group', {
    staticClass: "d-flex flex-column w-100",
    attrs: {
      "label": "Tax Value",
      "label-for": "fr"
    }
  }, [_c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "id": "fr",
      "type": "number"
    },
    model: {
      value: _vm.form.taxableValue,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "taxableValue", $$v);
      },
      expression: "form.taxableValue"
    }
  }), _c('small', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(parseFloat(_vm.form.taxableValue).toLocaleString()))])], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Total",
      "label-for": "total"
    }
  }, [_c('h5', {
    staticClass: "bg-warning px-4 p-2 rounded"
  }, [_c('strong', [_vm._v("IDR " + _vm._s(_vm.freightRateTotal.toLocaleString()) + ",-")])])])], 1)], 1), _c('b-row', [_c('div', {
    staticClass: "d-flex w-100 mb-4 border"
  }), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "iq-bg-danger text-right",
    attrs: {
      "variant": "none"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('input-freight-rate');
      }
    }
  }, [_vm._v("Cancel")]), _c('b-button', {
    staticClass: "ml-2 text-right",
    attrs: {
      "type": "button",
      "variant": "primary",
      "disabled": _vm.saving
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSave();
      }
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm mr-2"
  }) : _c('i', {
    staticClass: "fa fa-save mr-2"
  }), _vm._v(" Submit ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-row class="m-4">
    <b-col md="12" v-if="!loading">
      <b-row>
        <b-col md="6">
          <fieldset class="form-group border p-3 iq-bg-info">
            <legend class="w-auto px-2 text-primary h6"><strong>LOADING ACTIVITY RECORD</strong></legend>
            <b-form-row>
              <b-col md="6">
                <b-form-group label="Port of Loading" label-for="port_of_loading">
                  <v-select v-model="formOperationalActivity.portLoading" label="text" :options="positionList" :reduce="positionList => positionList.value" placeholder="Select..." class="bg-white"></v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Agent POL" label-for="agent_pol" class="text-truncate">
                  <b-form-input v-model="formOperationalActivity.agentPol" type="text" id="agent_pol" placeholder="Type Text" class="bg-white"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Is Pol Sts Mv:" label-for="kind-fleet">
                    <b-form-checkbox v-model="formOperationalActivity.polStsMv" name="check-button" switch inline>
                      No / Yes
                    </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pol Mv Name" label-for="pol_mv_name" class="text-truncate" >
                  <b-form-input v-model="formOperationalActivity.polMvName" type="text" id="pol_mv_name" placeholder="Type Text" :class="{'bg-white':formOperationalActivity.polStsMv}" :disabled="!formOperationalActivity.polStsMv"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pol grab Type" label-for="pod_grab_type">
                  <v-select v-model="formOperationalActivity.polGrabType" label='text' :options="polGrabTypeOpt" :reduce="item => item.value"  placeholder="Select Pol Grab Type" class="bg-white"></v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pol Fc Name" label-for="pol_fc_name" class="text-truncate">
                  <b-form-input v-model="formOperationalActivity.polFcName" :class="{'bg-white' : formOperationalActivity.polGrabType == 'gear_and_grab'}" :disabled="formOperationalActivity.polGrabType !== 'gear_and_grab'" type="text" id="pol_mv_name" placeholder="Type Text"></b-form-input>
                </b-form-group>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="6" class="mb-2"> 
                <b-form-group label="NOR" label-for="nor" class="text-truncate mb-0">
                  <date-picker v-model="formOperationalActivity.polNor" class="custom-date-picker w-100" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="6" class="mb-2"> 
                <b-form-group label="NOA" label-for="noa" class="text-truncate mb-0">
                  <date-picker v-model="formOperationalActivity.polNoa" class="custom-date-picker w-100" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Anchor Up" label-for="polAnchorUp" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.polArrivalTime, formOperationalActivity.polAnchorUp, 'Anchor Up', 'Arrival Time', 1)" class="custom-date-picker w-100" v-model="formOperationalActivity.polAnchorUp" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 1" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col  md="6" class="mb-2">
                <b-form-group label="In Position / Berthing" label-for="polBerthing" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.polAnchorUp, formOperationalActivity.polBerthing, 'Berthing', 'Anchor Up', 2)"  class="custom-date-picker w-100" v-model="formOperationalActivity.polBerthing" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 2" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Commenced" label-for="polCommenced" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.polBerthing, formOperationalActivity.polCommenced, 'Commenced', 'Berthing', 3)" class="custom-date-picker"v-model="formOperationalActivity.polCommenced" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 3" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Completed" label-for="completed" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.polCommenced, formOperationalActivity.polCompleted, 'Completed', 'Commenced', 4)" class="custom-date-picker w-100" v-model="formOperationalActivity.polCompleted" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 4" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6">
                <b-form-group label="Draught Survey" label-for="polDraughtSurvey" class="text-truncate mb-0">
                  <b-form-input style="background-color:white" v-model="formOperationalActivity.polDraughtSurvey" type="number" step="0.0001" id="polDraughtSurvey" placeholder="Type Number"></b-form-input>
                </b-form-group>
                <span class="text-black">{{ formOperationalActivity.polDraughtSurvey ? Number(formOperationalActivity.polDraughtSurvey).toLocaleString() : 0 }}</span>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="6" class="mb-2"> 
                <b-form-group label="BL Date" label-for="blDate" class="text-truncate mb-0">
                  <date-picker class="custom-date-picker w-100" v-model="formOperationalActivity.polBlDate" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 5" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="6" class="mb-2"> 
                <b-form-group label="Cast In" label-for="polCastIn" class="text-truncate mb-0">
                  <date-picker class="custom-date-picker w-100" v-model="formOperationalActivity.polCastIn" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 5" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Cast out" label-for="polCastOut" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.polCompleted, formOperationalActivity.polCastOut, 'Cast Out', 'Completed', 5)"  class="custom-date-picker w-100" v-model="formOperationalActivity.polCastOut" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 5" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="LHV" label-for="polLhv" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.polCastOut, formOperationalActivity.polLhv, 'LHV', 'Cast Out', 6)"  class="custom-date-picker w-100" v-model="formOperationalActivity.polLhv" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 6" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2"  v-if="showDocumentOnBoard">
                <b-form-group label="Document On Board POL" label-for="document_on_board" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.polLhv, formOperationalActivity.polDocOnBoard, 'Doc on Board', 'LHV', 7)" class="custom-date-picker w-100" v-model="formOperationalActivity.polDocOnBoard" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 7" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Departure to POD" label-for="polDeparture" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.polDocOnBoard, formOperationalActivity.polDeparture, 'Departure', 'Doc on Board', 8)" class="custom-date-picker w-100" v-model="formOperationalActivity.polDeparture" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 8" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="12" class="mb-2"> 
                <b-form-group label="Remarks" label-for="polCastIn" class="text-truncate mb-0">
                  <b-form-textarea placeholder="Type Text...">
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>
          </fieldset>
        </b-col>
        <b-col md="6">
          <fieldset class="form-group border p-3 iq-bg-info">
            <legend class="w-auto px-2 text-primary h6"><strong>DISCHARGE ACTIVITY RECORD</strong></legend>
            <b-form-row>
              <b-col md="6">
                <b-form-group label="Port of Discharge" label-for="port_of_discharge">
                  <v-select class="bg-white" v-model="formOperationalActivity.portDischarge" label="text" :options="positionList" :reduce="positionList => positionList.value" placeholder="Select..."></v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Agent POD" label-for="agent_pod" class="text-truncate">
                  <b-form-input v-model="formOperationalActivity.agentPod" type="text" id="agent_pod" placeholder="Type Text" class="bg-white" ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Is Pod Sts Mv:" label-for="pod_sts_mv">
                    <b-form-checkbox v-model="formOperationalActivity.podStsMv" name="check-button" switch inline>
                      No / Yes 
                    </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pod Mv Name" label-for="pod_mv_name" class="text-truncate">
                  <b-form-input v-model="formOperationalActivity.podMvName" type="text" id="pol_mv_name" placeholder="Type Text" :class="{'bg-white':formOperationalActivity.podStsMv}" :disabled="!formOperationalActivity.podStsMv"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pod grab Type" label-for="pod_grab_type">
                  <v-select v-model="formOperationalActivity.podGrabType" label='text' :options="polGrabTypeOpt" :reduce="item => item.value"  placeholder="Select POd Grab Type" class="bg-white"></v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pod Fc Name" label-for="pod_fc_name" class="text-truncate">
                  <b-form-input v-model="formOperationalActivity.podFcName" :class="{'bg-white' : formOperationalActivity.podGrabType == 'gear_and_grab'}" :disabled="formOperationalActivity.podGrabType !== 'gear_and_grab'" type="text" id="pol_mv_name" placeholder="Type Text"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Arrival Time POD" label-for="arrival_time_pod" class="text-truncate">
                  <date-picker @change="validationTime(currentValidTime, formOperationalActivity.podArrivalTime)"  class="custom-date-picker w-100" v-model="formOperationalActivity.podArrivalTime" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="6" class="mb-2"> 
                <b-form-group label="NOR" label-for="nor" class="text-truncate mb-0">
                  <date-picker v-model="formOperationalActivity.podNor" class="custom-date-picker w-100" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="6" class="mb-2"> 
                <b-form-group label="NOA" label-for="noa" class="text-truncate mb-0">
                  <date-picker v-model="formOperationalActivity.podNoa" class="custom-date-picker w-100" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Anchor Up" label-for="podAnchorUp" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.podArrivalTime, formOperationalActivity.podAnchorUp, 'Anchor Up', 'Arrival Time', 11)" class="custom-date-picker w-100" v-model="formOperationalActivity.podAnchorUp" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 11" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="In Position / Berthing" label-for="in_position_pod" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.podAnchorUp, formOperationalActivity.podBerthing, 'Berthing', 'Anchor Up', 12)" class="custom-date-picker w-100" v-model="formOperationalActivity.podBerthing" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 12" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Commenced" label-for="commenced_pod" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.podBerthing, formOperationalActivity.podCommenced, 'Commenced', 'Berthing', 13)" class="custom-date-picker w-100" v-model="formOperationalActivity.podCommenced" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 13" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Completed" label-for="completed_pod" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.podCommenced, formOperationalActivity.podCompleted, 'Completed', 'Commenced', 14)" class="custom-date-picker w-100" v-model="formOperationalActivity.podCompleted" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 14" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6">
                <b-form-group label="Draught Survey" label-for="podDraughtSurvey" class="text-truncate mb-0">
                  <b-form-input style="background-color:white" v-model="formOperationalActivity.podDraughtSurvey" type="number" step="0.0001" id="podDraughtSurvey" placeholder="Type Number"></b-form-input>
                </b-form-group>
                <span class="text-black">{{ formOperationalActivity.podDraughtSurvey ? Number(formOperationalActivity.podDraughtSurvey).toLocaleString() : 0 }}</span>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="6" class="mb-2"> 
                <b-form-group label="BL Date" label-for="blDate" class="text-truncate mb-0">
                  <date-picker class="custom-date-picker w-100" v-model="formOperationalActivity.podBlDate" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
              </b-col>
              <!-- Data Not Yet Provided -->
              <b-col md="6" class="mb-2"> 
                <b-form-group label="Cast In" label-for="polCastIn" class="text-truncate mb-0">
                  <date-picker class="custom-date-picker w-100"  v-model="formOperationalActivity.podCastIn" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 5" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Cast Out" label-for="podCastOut" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.podCompleted, formOperationalActivity.podCastOut, 'Cast Out', 'Completed', 15)" class="custom-date-picker w-100" v-model="formOperationalActivity.podCastOut" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 15" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <!-- <b-col md="6" class="mb-2">
                <b-form-group label="LHV" label-for="podLhv" class="text-truncate mb-0"> 
                  <date-picker @change="validationTime(formOperationalActivity.podCastOut, formOperationalActivity.podLhv, 'LHV', 'Cast Out', 16)" class="custom-date-picker w-100" v-model="formOperationalActivity.podLhv" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 16" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col> -->
              <b-col v-if="showDocumentOnBoard" md="6"  class="mb-2">
                <b-form-group label="Document On Board POD" label-for="document_on_board" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.podLhv, formOperationalActivity.docOnBoard, 'Doc on Board', 'LHV', 17)" class="custom-date-picker w-100" v-model="formOperationalActivity.docOnBoard" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 17" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Departure to POL" label-for="podDeparture" class="text-truncate mb-0">
                  <date-picker @change="validationTime(formOperationalActivity.docOnBoard, formOperationalActivity.podDeparture, 'Departure', 'Doc on Board', 18)" class="custom-date-picker w-100" v-model="formOperationalActivity.podDeparture" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
                <small v-if="errorTimeText && errorTimeIndex === 18" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
              </b-col>
                <!-- Data Not Yet Provided -->
                <b-col md="12" class="mb-2"> 
                <b-form-group label="Remarks" label-for="polCastIn" class="text-truncate mb-0">
                  <b-form-textarea placeholder="Type Text...">
                  </b-form-textarea>
                </b-form-group>
              </b-col>

            </b-form-row>
          </fieldset>
        </b-col>
        <b-col md="12" class="text-right mt-3">
          <b-button type="button" variant="primary" @click="saveActivity()"><i class="fa fa-save"></i> Save</b-button>
          <b-button type="button" variant="none" class="iq-bg-danger ml-3" @click="closeSlideEditOperational()"><i class="fa fa-close"></i> Cancel Edit</b-button>
        </b-col>
      </b-row>
    </b-col>
    
    <b-col md="12" v-else>
      <div class="alert alert-info m-4">
        Loading...
      </div>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { fleetsActions, voyagesActions, busdevActions, portsActions } from '@src/Utils/helper'

var date = new Date()

export default {
  name: 'SlideEditOperational',
  props:{
    id:{
      type:Number,
      default:null
    },
    closeSlideEditOperational:{
      type:Function,
      default:() => {}
    }
  },
  components:{
  },
  data(){
    return {
      loading:false,
      loadingForm: false,
      loadingCheck: false,

      detailData: null,
      polGrabTypeOpt:[
        {
          text:'Gearless',
          value:'gearless'
        },
        {
          text:'Gear and Grab',
          value:'gear_and_grab'
        }
      ],
      currentValidTime: null,
      errorTimeText: '',
      errorTimeIndex: null,
      errorValidation: false,

      formOperationalActivity: {
        portLoading: '',
        portLoadingOther: '',
        portDischarge: '',
        portDischargeOther: '',
        agentPol: '',
        agentPod: '',
        "polCargoUnit": "",
        "polAnchorUp": "",
        "polArrivalTime":"",
        "polBerthing":"",
        "polCommenced":"",
        "polCompleted":"",
        "polDocOnBoard":"", 
        "polDeparture":"",
        "polCastOut":"",
        "polLhv":"",
        "podCargoUnit":"",
        "podAnchorUp":"",
        "podLhv":"",
        "podCastOut":"",
        "podDeparture":"",
        "podArrivalTime":"",
        "podBerthing":"",
        "podCommenced":"",
        "podCompleted":"",
        "polDraughtSurvey": 0,
        "podDraughtSurvey": 0,
        polStsMv:false,
        polMvName:'',
        polGrabType:'gearless',
        polFcName:'',
        podStsMv:false,
        podMvName:'',
        podGrabType:'gearless',
        podFcName:'',
        polNoa: null,
        polNor: null,
        podNoa: null,
        podNor: null,

        polBlDate: null, 
        polCastIn: null,
        podBlDate: null, 
        podCastIn: null
      },
      isEdit: false,
      isActivityId: '',
      fleet: {
        vehicleType: {
          name: ''
        }
      },
      fleetEdit: null,
      activityList: [],
      fleetList: [],
      dmsLat: {
        degrees: '',
        minutes: '',
        seconds: '',
        directions: 'S'
      },
      dmsLon: {
        degrees: '',
        minutes: '',
        seconds: '',
        directions: 'E'
      },
      locked: false,

      loadingForm: false,
      loadingCheck: false,
      // End Connect
      nextStep: false,
      secondFleet: '7',
      anotherFleet: '3',
      startDate: '2021-04-13T13:45:00',
      endDate: '',
      reason: null,
      moreLoading: true,
      breakdown: false,
      breakdownAnother: false,
      endMoreLoading: true,
      selectedAction: 'UNTOWING',
      action: [
        {
          label: 'Not Towing',
          color: 'default',
          value: 'UNTOWING'
        },
        {
          label: 'Towing Tug/Barge',
          color: 'primary',
          value: 'SINGLE_TOWING'
        },
        {
          label: 'Double Towing',
          color: 'warning',
          value: 'DOUBLE_TOWING'
        }
      ],
      projectValue: '',
      projectOptions: [],
      project: 2,
      projectList: [
        { value: '1', text: 'Arutmin Project' },
        { value: '2', text: 'KPC Project' },
        { value: '3', text: 'AI Project' },
        { value: '5', text: 'Virtue Dragon Nickel Industry Project' },
        { value: '6', text: 'Pracadi Project' },
        { value: '7', text: 'Conch Project' },
        { value: '8', text: 'Tsingshan Steel Indonesia Project' }
      ],
      subProject: 1,
      subProjectList: [
        { value: '1', text: 'Transhipment' },
        { value: '2', text: 'Long Hauling' },
        { value: '3', text: 'Armada Oil Barge' },
        { value: '4', text: 'Finance' },
        { value: '5', text: 'Loading' },
        { value: '6', text: 'Unloading' }
      ],
      position: '',
      positionOther: '',
      positionList: [
        { value: 'Others', text: 'Others Position' }
      ],
      reasonList: [],
      portList: [
        { value: 'Asam-Asam', text: 'Asam-Asam' },
        { value: 'Sangatta', text: 'Sangatta' },
        { value: 'Paiton', text: 'Paiton' },
        { value: 'Bengalon', text: 'Bengalon' },
        { value: 'Tanjung Bara', text: 'Tanjung Bara' },
        { value: 'Tanjung Awar-Awar', text: 'Tanjung Awar-Awar' },
        { value: 'Rembang', text: 'Rembang' }
      ],

      picList: [],
      agentPOL: 'TCP',
      agentPOD: 'SAJ',
      agentList: [
        { value: 'TCP', text: 'TCP' },
        { value: 'SAJ', text: 'SAJ' },
        { value: 'ANTAM', text: 'ANTAM' }
      ],
      typeOfCargo: 'COAL',
      typeOfCargoOther: '',
      cargoOptions: [
        { value: 'COAL', text: 'COAL' },
        { value: 'FUEL', text: 'FUEL' },
        { value: 'OIL', text: 'OIL' },
        { value: 'NICKEL', text: 'NICKEL' },
        { value: 'CRANE-CONTAINER', text: 'CRANE CONTAINER' },
        { value: 'Others', text: 'Others...' }
      ],

      voyageActive: {
        single: 'active',
        disable: 'active',
        number: 'loading',
        color: 'danger'
      },
      voyagefor: [
        {
          name: 'number',
          label: 'Loading',
          value: 'loading',
          disabled: false
        },
        {
          name: 'number',
          label: 'Discharge',
          value: 'discharge',
          disabled: false
        },
        {
          name: 'number',
          label: 'Others',
          value: 'others',
          disabled: false
        }
      ],

      polGrabTypeOpt:[
        {
          text:'Gearless',
          value:'gearless'
        },
        {
          text:'Gear and Grab',
          value:'gear_and_grab'
        }
      ],

      currentValidTime: null,
      errorTimeText: '',
      errorTimeIndex: null,
      errorValidation: false,

      optionsFleets: [],

      unfinishedTrip: [],
      finishedTrip: [],

      crossTrip:false,
      crossTripList: [],
    }
  },
  async mounted () {
    this.getPortList()
  },
  computed: {
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.getDetailTrip(value)
        }
      },
    },
  },
  computed:{
    showDocumentOnBoard () {
      return ![6].includes(this.fleet?.vehicleType?.id)
    },
  },
  methods:{
    ...voyagesActions,
    ...busdevActions,
    ...fleetsActions,
    ...portsActions,
    async getDetailTrip(tripId){
      let params = {
        tripId,
        vehicleId: Number(this.$route.query.vehicleId),
      }
      let res = await this.getVoyageTripDetail(params)

      if(res.status === 'success'){
        let data = res?.data

        this.matrix = data?.matrix
        this.activities = data?.activities
        this.summaryActivities = data?.summaryActivities

        this.detailData = data?.tripRecord
        this.vehicle = data?.vehicle

        this.formOperationalActivity = {
          ...this.formOperationalActivity,
          ...data?.tripRecord,
          portLoading: data?.tripRecord?.portLoading?.id,
          // portLoadingOther: '',
          portDischarge: data?.tripRecord?.portDischarge?.id,
          // portDischargeOther: '',
        }
      }
    },

    validationTime(oldTime, currentTime, labelCurrent, labelBefore, indexes){
      // Parse the datetime strings using Moment.js
      let old = moment(new Date(oldTime), 'DD-MM-YYYY HH:mm');
      let current = moment(new Date(currentTime), 'DD-MM-YYYY HH:mm');

      this.errorTimeIndex = indexes

      if (current.isSameOrBefore(old)) {
        this.errorTimeText = `${labelCurrent} must greater than ${labelBefore}`
        this.errorValidation =  true
        return
      } 

      this.errorTimeText = ''
      this.currentValidTime = currentTime
      this.errorValidation =  false
    },

    async getPortList() {
      let params = {
        active: true,
        search: '',
        page: 1,
        perPage: 250
      }
      const res = await this.getPorts(params)
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.positionList.push({ value: val.id, text: val.portName })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.data,
          'error'
        )
      }
    },

    async saveActivity () {
      if(this.errorValidation) return this.$swal(
        'Error',
          this.errorTimeText,
        'warning'
      )

      let payload = {
        ...this.formOperationalActivity,
      }
      let res = await this.updateTripRecordById({ id: Number(this.id), data: payload })
      if (res.status == 'success') {
        this.$swal('Update Activity Success!', res.data.message, 'success')
        this.closeSlideEditOperational()
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    }
  }
}
</script>
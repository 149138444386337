<template>
  <div>
    <b-row>
      <b-col sm="8">
        <iq-card headerClass="align-items-center py-2">
          <template v-slot:headerTitle>
            <div class="">
              <h4 class="card-title text-primary"
                ><strong>MASTER TRIP DISTANCE</strong></h4
              >
            </div>
          </template>
          <template #headerAction>
            <b-form class="row w-100" @submit.prevent="getMasterTripDistance()">
              <b-col cols="12" md="10" class="p-0">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Search..."
                  v-model="mastertripDistance.params.name"
                ></b-form-input>
              </b-col>
              <b-col cols="12" md="2" class="p-0">
                <b-button type="submit" variant="primary" class="ml-2"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-col>
            </b-form>
          </template>
          <template v-slot:body>
            <div v-if="mastertripDistance._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div
                class="table-responsive"
                v-if="
                  mastertripDistance.data && mastertripDistance.data.length > 0
                "
              >
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="10%">No</th>
                      <th width="25%" class="text-truncate">Vehicle Type</th>
                      <th width="25%" class="text-truncate">Port From</th>
                      <th width="25%" class="text-truncate">Port To</th>
                      <th width="10%" class="text-truncate">Distance</th>
                      <th width="10%" class="text-truncate">Units</th>
                      <th width="15%" class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(p, i) in mastertripDistance.data"
                      :key="`p-${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        <div class="d-flex flex-wrap">
                          <b-badge v-for="(fleetType, fIndex) in p?.vehicle_types" :key="fIndex" class="border border-primary text-primary text-uppercase mr-1 mb-1" variant="none">{{ fleetType?.name }}</b-badge>
                        </div>
                      </td>
                      <td>{{ p.port_from_value ?? '-' }}</td>
                      <td>{{ p.port_to_value ?? '-' }}</td>
                      <td>{{ p.distance ?? '-' }}</td>
                      <td>{{ p.units ?? '-' }}</td>
                      <td>
                        <div class="d-flex justify-content-center">
                          <b-button
                            variant="success"
                            size="sm"
                            class="mr-2"
                            @click="editFormTripDistance(p)"
                            ><i class="fa fa-edit"></i
                          ></b-button>
                          <b-button
                            variant="danger"
                            size="sm"
                            @click="onDeleteTripDistance(p)"
                          >
                            <i
                              v-if="!delete_contract_type"
                              class="fa fa-trash"
                            ></i>
                            <template v-else>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span
                              >Deleting...
                            </template>
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="
                  mastertripDistance.data && mastertripDistance.data.length > 0
                "
              >
                <b-pagination
                  v-model="mastertripDistance.meta.page"
                  :total-rows="mastertripDistance.meta.totalItem"
                  @change="getMasterTripDistance"
                  :per-page="mastertripDistance.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
              <p v-else class="text-center my-4"
                >No Trip Distance data found.</p
              >
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4">
        <iq-card
          :class="{
            'border border-danger shadow-lg': master_trip_type_edit,
          }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title"
              >{{ master_trip_type_edit ? 'Edit' : 'Add' }} Trip Distance</h4
            >
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="onSaveMasterTripDistance()">
              <b-form-group label="Port From" label-for="port-from">
                <!-- {{ formTripDistance.port_from_value }} -->
                <v-select
                  v-model="formTripDistance.port_from_id"
                  label="text"
                  :options="portOptions"
                  :reduce="(obj) => obj.value"
                  placeholder="Select port"
                ></v-select>
              </b-form-group>
              <b-form-group label="Port To" label-for="port-to">
                <v-select
                  v-model="formTripDistance.port_to_id"
                  label="text"
                  :options="portOptions"
                  :reduce="(obj) => obj.value"
                  placeholder="Select Port"
                ></v-select>
              </b-form-group>
              <b-form-group label="Vehicle Types" label-for="vehicle-types">
                <v-multiselect
                  v-model="formTripDistance.vehicle_type_ids"
                  id="vehicle-types"
                  placeholder="Search vehicle types"
                  label="name"
                  track-by="id"
                  :options="vehicleTypeOptions"
                  :multiple="true"
                >
                  <span slot="noResult">Category not found.</span>
                </v-multiselect>
              </b-form-group>
              <b-form-group label="Units" label-for="units">
                <b-form-input
                  v-model="formTripDistance.units"
                  id="units"
                  type="text"
                  placeholder="Type text"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Distance" label-for="distance">
                <b-form-input
                  required
                  v-model="formTripDistance.distance"
                  id="distance"
                  type="number"
                  placeholder="Type number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Target Minimal Days"
                label-for="target_min_days"
              >
                <b-input-group name="target_min_days">
                  <b-form-input
                    v-model="formTripDistance.target_min_days"
                    id="target_min_days"
                    type="number"
                    placeholder="type number"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button class="" disabled>
                      <p class="m-0 text-sm">Day</p>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Target Maximal Days"
                label-for="target_max_days"
              >
                <b-input-group name="target_max_days">
                  <b-form-input
                    v-model="formTripDistance.target_max_days"
                    id="target_max_days"
                    type="number"
                    placeholder="type number"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button class="" disabled>
                      <p class="m-0 text-sm">Day</p>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Target Minimal Minutes"
                label-for="target_min_minutes"
              >
                <b-form-input
                  v-model="formTripDistance.target_min_minutes"
                  id="target_min_minutes"
                  type="number"
                  placeholder="type number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Target Maximal Minutes"
                label-for="target_max_minutes"
              >
                <b-form-input
                  v-model="formTripDistance.target_max_minutes"
                  id="target_max_minutes"
                  type="number"
                  placeholder="type number"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Minimum Speed" label-for="speed_min">
                <b-form-input
                  v-model="formTripDistance.speed_max"
                  id="speed_min"
                  type="number"
                  placeholder="Type number"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Maximal Speed" label-for="speed_max">
                <b-form-input
                  v-model="formTripDistance.speed_min"
                  id="speed_max"
                  type="number"
                  placeholder="type number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Kpi Fuel Comsumption"
                label-for="kpi_fuel_consumptions"
              >
                <b-form-input
                  v-model="formTripDistance.kpi_fuel_consumptions"
                  id="kpi_fuel_consumptions"
                  type="number"
                  placeholder="type number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Kpi Fresh Water Comsumption"
                label-for="kpi_fresh_water_consumptions"
              >
                <b-form-input
                  v-model="formTripDistance.kpi_fresh_water_consumptions"
                  id="kpi_fresh_water_consumptions"
                  type="number"
                  placeholder="type number"
                ></b-form-input>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                  <span v-if="!master_trip_type_edit">Add</span>
                  <span v-else>Save Edit</span>
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click="resetformMastertrip()"
                  >Cancel</b-button
                >
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { busdevActions, portsActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MaterTripDistance',
  data() {
    return {
      mastertripDistance: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1,
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },

      loading_contract_type: false,
      saving_trip_distance_type: false,
      delete_contract_type: false,

      formTripDistance: {
        port_from_id: '',
        port_from_value: '',
        port_to_id: '',
        port_to_value: '',
        distance: '',
        units: '',
        target_min_days: '',
        target_max_days: '',
        target_min_minutes: '',
        target_max_minutes: '',
        speed_max: '',
        speed_min: '',
        kpi_fuel_consumptions: '',
        kpi_fresh_water_consumptions: '',
        vehicle_type_ids: [],
      },

      portOptions: [],
      vehicleTypeOptions: [],

      master_trip_type_edit: null,
      master_trip_distance_id: null,
    }
  },
  created() {
    this.getMasterTripDistance = _.debounce(this.getMasterTripDistance, 200)
  },
  methods: {
    ...busdevActions,
    ...portsActions,
    async getMasterTripDistance(page) {
      this.mastertripDistance._loading = true
      this.mastertripDistance.data = []
      let params = {
        ...this.mastertripDistance.params,
        page: page || 1,
      }
      if (!params.name) delete params.name

      const res = await this.fetcMasterTripDistances(params)

      if (res.data.status == true) {
        this.mastertripDistance.data = res.data.data
      } else {
        this.mastertripDistance._loading = false
        this.$swal(`Oops!`, res.data.message, 'error')
      }
      this.mastertripDistance._loading = false
    },

    async onSaveMasterTripDistance() {
      this.saving_trip_distance_type = true
      let payload = {
        ...this.formTripDistance,
        vehicle_type_ids: this.formTripDistance.vehicle_type_ids.map(
          (el) => el.id
        ),
      }
      let res = null
      if (this.master_trip_type_edit && this.master_trip_distance_id) {
        res = await this.updateMasterTripDistance({
          data: payload,
          id: this.master_trip_distance_id,
        })
      } else if (!this.master_trip_type_edit) {
        res = await this.addMasterTripDistances({ data: payload })
      }

      if (res.data.status === true) {
        this.$swal(
          `${
            this.master_trip_type_edit ? 'Edit' : 'Add'
          } Mater Trip Distance Success!`,
          res.data.message,
          'success'
        )
        this.resetformMastertrip()
        this.getMasterTripDistance()
        this.saving_trip_distance_type = false
      } else {
        this.$swal(`Oops!`, res.data, 'error')
        this.saving_trip_distance_type = false
      }
      this.saving_trip_distance_type = false
    },

    resetformMastertrip() {
      for (const key in this.formTripDistance) {
        if (key == 'vehicle_type_ids') {
          this.formTripDistance[key] = []
        } else {
          this.formTripDistance[key] = ''
        }
      }
      this.master_trip_type_edit = false
      this.master_trip_distance_id = null
    },

    editFormTripDistance(data) {
      this.formTripDistance.vehicle_type_ids = data['vehicle_types']
      for (const key in this.formTripDistance) {
        for (const key2 in data) {
          if (key == key2) {
            this.formTripDistance[key] = data[key2]
          }
        }
      }
      this.master_trip_type_edit = true
      this.master_trip_distance_id = data.id
    },

    async onDeleteTripDistance(data) {
      this.$swal({
        title: 'Delete Mater Trip Distance?',
        text: `Mater Trip Distance ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_trip_distance_type = true
          let res = await this.deleteMasterTripDistance({ id: data.id })
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_trip_distance_type = false
          } else {
            this.$swal(
              `Mater Trip Distance deleted!`,
              `Mater Trip Distance ${data.name} successfully deleted`,
              'success'
            )
            await this.getMasterTripDistance()
            this.saving_trip_distance_type = false
          }
        }
      })
    },

    async fetchPorts() {
      try {
        const res = await this.getPorts({ page: 1 })
        this.portOptions = res.data.data
        this.portOptions.forEach((el, i) => {
          el.text = el.portName
          el.value = el.id
        })
      } catch (error) {
        console.log(error)
      }
    },

    async fetchVehicleTypeOpt() {
      try {
        const res = await this.fetchVehicleType({ page: 1 })
        this.vehicleTypeOptions = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    setPortValue(newVal, key) {
      if (newVal) {
        this.formTripDistance[key] = this.portOptions.find(
          (el, i) => el.id == newVal
        ).portName
      } else {
        this.formTripDistance[key] = ''
      }
    },
  },
  watch: {
    'formTripDistance.port_from_id': function (newVal) {
      this.setPortValue(newVal, 'port_from_value')
    },
    'formTripDistance.port_to_id': function (newVal) {
      this.setPortValue(newVal, 'port_to_value')
    },
  },
  async mounted() {
    await this.getMasterTripDistance()
    await this.fetchPorts()
    await this.fetchVehicleTypeOpt()
  },
}
</script>

<style scoped></style>

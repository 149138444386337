var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h4', {
    staticClass: "text-primary mb-2"
  }, [_vm._v("Detail Information")]), _c('table', {
    staticClass: "table mb-0 table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    attrs: {
      "required": "",
      "type": "text",
      "name": "location",
      "placeholder": "Type Text ..."
    },
    model: {
      value: _vm.form.data.location,
      callback: function callback($$v) {
        _vm.$set(_vm.form.data, "location", $$v);
      },
      expression: "form.data.location"
    }
  })], 1), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Tanggal")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "required": "",
      "name": "accident_at"
    },
    model: {
      value: _vm.form.data.accident_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form.data, "accident_at", $$v);
      },
      expression: "form.data.accident_at"
    }
  })], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Site & Group")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Type Text ...",
      "name": "site"
    },
    model: {
      value: _vm.form.data.site,
      callback: function callback($$v) {
        _vm.$set(_vm.form.data, "site", $$v);
      },
      expression: "form.data.site"
    }
  })], 1), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("P.I.C")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Type Text ...",
      "name": "pic"
    },
    model: {
      value: _vm.form.data.pic,
      callback: function callback($$v) {
        _vm.$set(_vm.form.data, "pic", $$v);
      },
      expression: "form.data.pic"
    }
  })], 1)])])]), _c('div', {
    staticClass: "overflow-auto mt-2",
    staticStyle: {
      "max-height": "630px"
    }
  }, _vm._l(_vm.form.data.contents, function (state, index) {
    return _c('div', {
      key: "add-" + index,
      staticClass: "mb-2"
    }, [_c('h4', {
      staticClass: "text-primary mb-2"
    }, [_vm._v("Repair #" + _vm._s(index + 1))]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Equipment Name",
        "label-for": "equipmentName"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "text",
        "name": "name",
        "placeholder": "Type Text ...",
        "required": ""
      },
      model: {
        value: state.name,
        callback: function callback($$v) {
          _vm.$set(state, "name", $$v);
        },
        expression: "state.name"
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Work Item",
        "label-for": "repairTitle"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "text",
        "name": "title",
        "placeholder": "Type Text ...",
        "required": ""
      },
      model: {
        value: state.title,
        callback: function callback($$v) {
          _vm.$set(state, "title", $$v);
        },
        expression: "state.title"
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Type R & M *",
        "label-for": "type_repair"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "name": "task_type_id",
        "options": _vm.optionsTaskType,
        "size": "lg",
        "required": ""
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Type R & M")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.task_type_id,
        callback: function callback($$v) {
          _vm.$set(state, "task_type_id", $$v);
        },
        expression: "state.task_type_id"
      }
    })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Department *",
        "label-for": "department"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "name": "department_id",
        "options": _vm.optionsDepartment,
        "size": "lg",
        "required": ""
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Department")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.department_id,
        callback: function callback($$v) {
          _vm.$set(state, "department_id", $$v);
        },
        expression: "state.department_id"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Section *",
        "label-for": "section"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "name": "section",
        "size": "lg",
        "required": ""
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": ''
            }
          }, [_vm._v("Select Section")]), _c('b-form-select-option', {
            attrs: {
              "value": 'DECK'
            }
          }, [_vm._v("Deck")]), _c('b-form-select-option', {
            attrs: {
              "value": 'ENGINE'
            }
          }, [_vm._v("Engine")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.section,
        callback: function callback($$v) {
          _vm.$set(state, "section", $$v);
        },
        expression: "state.section"
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Category *",
        "label-for": "category"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "name": "category_id",
        "options": _vm.optionsCategory,
        "size": "lg",
        "required": ""
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Category")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.category_id,
        callback: function callback($$v) {
          _vm.$set(state, "category_id", $$v);
        },
        expression: "state.category_id"
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Classification *",
        "label-for": "classification"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "name": "classification_id",
        "options": _vm.optionsClassification,
        "size": "lg",
        "required": ""
      },
      on: {
        "change": function change($event) {
          return _vm.handleCountDaysBasedClassification(state);
        }
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Classification")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.classification_id,
        callback: function callback($$v) {
          _vm.$set(state, "classification_id", $$v);
        },
        expression: "state.classification_id"
      }
    })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Cause *",
        "label-for": "cause"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "name": "case_id",
        "options": _vm.optionsCause,
        "size": "lg",
        "required": ""
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Cause")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.case_id,
        callback: function callback($$v) {
          _vm.$set(state, "case_id", $$v);
        },
        expression: "state.case_id"
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Kind of Task *",
        "label-for": "kind_of_task"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "name": "kind_task_id",
        "options": _vm.optionsKindTask,
        "size": "lg",
        "required": ""
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Kind of Task")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.kind_task_id,
        callback: function callback($$v) {
          _vm.$set(state, "kind_task_id", $$v);
        },
        expression: "state.kind_task_id"
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Berita Acara",
        "label-for": "attactment_file"
      }
    }, [_c('b-form-file', {
      attrs: {
        "plain": "",
        "multiple": "",
        "name": "file",
        "id": "attactment_file",
        "accept": "image/*,application/*"
      },
      model: {
        value: state.file,
        callback: function callback($$v) {
          _vm.$set(state, "file", $$v);
        },
        expression: "state.file"
      }
    })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Rencana Mulai *",
        "label-for": "mulai"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "date",
        "name": "start_at",
        "id": "mulai",
        "required": ""
      },
      on: {
        "change": function change() {
          _vm.calculateDays(state);
          _vm.handleCountDaysBasedClassification(state);
        }
      },
      model: {
        value: state.start_at,
        callback: function callback($$v) {
          _vm.$set(state, "start_at", $$v);
        },
        expression: "state.start_at"
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Rencana Selesai *",
        "label-for": "selesai"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "date",
        "name": "",
        "id": "selesai",
        "required": ""
      },
      on: {
        "change": function change($event) {
          return _vm.calculateDays(state);
        }
      },
      model: {
        value: state.estimated_finished_at,
        callback: function callback($$v) {
          _vm.$set(state, "estimated_finished_at", $$v);
        },
        expression: "state.estimated_finished_at"
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Estimasi Hari",
        "label-for": "estimasi_hari"
      }
    }, [_c('b-button', {
      staticClass: "mb-1",
      attrs: {
        "variant": "outline-primary"
      }
    }, [_vm._v(" Perkiraan Pekerjaan "), _c('b-badge', {
      staticClass: "ml-2",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days) + " Hari")])], 1)], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Description",
        "label-for": "description"
      }
    }, [_c('b-form-textarea', {
      attrs: {
        "id": "description",
        "rows": "3",
        "name": "",
        "placeholder": "Type Text ..."
      },
      model: {
        value: state.description,
        callback: function callback($$v) {
          _vm.$set(state, "description", $$v);
        },
        expression: "state.description"
      }
    })], 1)], 1)], 1), _c('b-row', {
      staticClass: "mt-2"
    }, [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex w-100 align-items-center justify-content-between"
    }, [_c('b-card-text', [_c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Sign * : "), _c('b-badge', {
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v("Mandatory (Must be Input)")])], 1)]), index !== 0 ? _c('b-button', {
      staticClass: "pull-right",
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.removeRepairJob(_vm.form.data.contents, index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    }), _vm._v(" Delete Repair Job ")]) : _vm._e()], 1)])], 1)], 1);
  }), 0), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.addRepairJob(_vm.form.data.contents);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" ADD JOB REPAIR & MAINTENANCE ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
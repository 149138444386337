<template>
  <b-row>
    <b-col lg="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"
          ><strong>MASTER MISCELLANEOUS CHARGE</strong></h4
          >
        </template>
        <template v-slot:headerAction>
          <!-- <b-button variant="primary">
            <template v-if="!loading_export_power">
              <i class="fa fa-file-excel"></i> &nbsp; Export
            </template>
            <template v-else>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
              Exporting...
            </template>
          </b-button> -->
        </template>
        <template v-slot:body>
          <b-form class="row" @submit.prevent="getMiscellaneousCharge()">
            <b-col cols="12" md="3">
              <b-form-group
                label="Search Master Miscellaneous Charge :"
                label-for="kind-fleet"
              >
                <b-form-input
                  id="name"
                  class="w-100"
                  type="text"
                  placeholder="Search..."
                  v-model="list.params.name"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-group label="Search" label-for="button_search">
                <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
              </b-form-group>
            </b-col>
          </b-form>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="8">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">List Master Miscellaneous Charge</h4>
        </template>
        <template v-slot:body>
          <div v-if="list._loading" class="text-center my-5">
            <b-spinner type="grow" label="Spinning"></b-spinner>
          </div>
          <template v-else>
            <div class="table-responsive" v-if="list.data && list.data.length > 0">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="10%">No</th>
                    <th width="25%">Name</th>
                    <th width="10%">Calculation</th>
                    <th width="10%">Status</th>
                    <th width="15%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, i) in list.data" :key="`p-${i}`">
                    <td>{{ i + 1 }}</td>
                    <td>{{ p.name }}</td>
                    <td>{{ p.is_calculation ? 'Yes' : 'No' }}</td>
                    <td>{{ p.status ? 'Active' : 'Inactive' }}</td>
                    <td>
                      <b-button variant="success" size="sm" class="mr-2" @click="editForm(p)"><i class="fa fa-edit"></i></b-button>
                      <b-button variant="danger" size="sm" @click="onDelete(p)">
                        <i v-if="!deleted" class="fa fa-trash"></i>
                        <template v-else>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                        </template>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="list.data && list.data.length > 0">
              <b-pagination
                v-model="list.meta.page"
                :total-rows="list.meta.totalItem"
                @change="getMiscellaneousCharge"
                :per-page="list.meta.perPage"
                first-number
                align="center"
              ></b-pagination>
            </div>
            <p v-else class="text-center my-4">No Miscellaneous Charge data found.</p>
          </template>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="4">
      <iq-card
        :class="{
          'border border-danger shadow-lg': edit,
        }"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title"
          >{{ edit ? 'Edit' : 'Add' }} Miscellaneous Charge</h4
          >
        </template>
        <template v-slot:body>
          <b-form @submit.prevent="onSave()">
            <b-form-group label="Name" label-for="name">
              <b-form-input v-model="form.name" id="name" type="text" placeholder="Type text" maxlength="30"></b-form-input>
            </b-form-group>
            <b-form-group label="Alias" label-for="name">
              <b-form-input v-model="form.alias" id="alias" type="text" placeholder="Type text" maxlength="30"></b-form-input>
            </b-form-group>
            <b-form-group label="Calculation?" label-for="is_calculation">
              <b-form-checkbox v-model="form.is_calculation" name="check-button" switch inline>Active / Inactive
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Static / Dynamic?" label-for="is_static_dynamic" v-if="form.is_calculation">
              <b-form-checkbox v-model="form.is_static_dynamic" name="check-button" switch inline>Yes / No
              </b-form-checkbox>
            </b-form-group>
            <template v-if="form.is_calculation && form.is_static_dynamic">
              <b-form-group label="Operator" label-for="operator">
                <b-form-select
                  plain
                  v-model="form.operator"
                  size="sm"
                  placeholder="Select options..."
                  :options="optionsOperator"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >Select Operator</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>  
              <b-form-group label="Multiplication Value" label-for="multiplication" v-if="form.is_static_dynamic">
                <b-form-input v-model="form.multiplication" type="number" placeholder="Type number" step="0.000001"></b-form-input>
                <small>{{ form.multiplication.toLocaleString() }}</small>
              </b-form-group>
            </template>
            <b-form-group label="Status" label-for="status">
              <b-form-checkbox v-model="form.status" name="check-button" switch inline>Active / Inactive
              </b-form-checkbox>
            </b-form-group>
            <hr />
            <b-form-group class="text-right">
              <b-button type="submit" variant="primary">
                <span v-if="!edit">Add</span>
                <span v-else>Save Edit</span>
              </b-button>
              <b-button
                type="button"
                variant="danger"
                class="iq-bg-danger ml-3"
                @click="resetForm()"
              >Cancel</b-button>
            </b-form-group>
          </b-form>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MasterMiscellaneousCharge',
  data () {
    return {
      optionsOperator:[
        {
          text:'Addition (+)',
          value:'addition'
        },
        {
          text:'Subtraction (-)',
          value:'subtraction'
        },
        {
          text:'Multiplication (*)',
          value:'multiplication'
        },
        {
          text:'Division (/)',
          value:'division'
        },
        {
          text:'Modulus (%)',
          value:'modulus'
        }
      ],
      list: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 10,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      loading:false,
      saving:false,
      deleted:false,

      form: {
        "name": "",
        "alias": "",
        "is_calculation": false,
        "is_static_dynamic": false,
        "multiplication": null,
        operator:'',
        "status": true
      },
      edit: null,
      id: null
    }
  },
  async mounted () {
    await this.getMiscellaneousCharge()
  },
  created () {
    this.getMiscellaneousCharge = _.debounce(this.getMiscellaneousCharge, 200)
  },
  methods: {
    ...busdevActions,
    async getMiscellaneousCharge (page) {
      this.list._loading = true
      this.list.data = []
      let params = {
        ...this.list.params,
        page: page || 1
      }
      if(!params.name) delete params.name 

      const res = await this.fetchMiscellaneousCharge(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.list.data = res.data.data?.data
          this.list.meta = res.data.data?.meta
        }
        this.list._loading = false
      } else {
        this.list._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    resetForm () {
      this.form = {
        "name": "",
        "alias": "",
        "is_calculation": false,
        "is_static_dynamic": false,
        "multiplication": null,
        operator:'',
        "status": true
      }
      this.edit = false
      this.id = null
    },

    editForm (data) {
      this.form = {
        "name": data?.name,
        "alias": data?.alias,
        "is_calculation":data.is_calculation,
        "is_static_dynamic":data.is_static_dynamic,
        "operator":data.operator,
        "multiplication":data.multiplication,
        "status":data.status == 1 ? true : false
      }
      this.edit = true
      this.id = data.id
    },

    async onDelete (data) {
      this.$swal({
        title: 'Delete Miscellaneous Charge?',
        text: `Miscellaneous Charge ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving = true
          let res = await this.deleteMiscellaneousCharge({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving = false
          } else {
            this.$swal(
              `Miscellaneous Charge deleted!`,
              `Miscellaneous Charge ${data.name} successfully deleted`,
              'success'
            )
            await this.getMiscellaneousCharge()
            this.saving = false
          }
        }
      })
    },
    async onSave () {
      this.saving = true
      let payload = {
        ...this.form,
        status: this.form.status ? 1 : 0
      }

      let res = null
      if(this.edit && this.edit){
        res = await this.updateMiscellaneousCharge({data: payload, id: this.id})
      } else if (!this.edit) {
        res = await this.createMiscellaneousCharge({data: payload})
      }

      if (res.data.status === true) {
        this.$swal(`${this.edit ? 'Edit' : "Add"} Miscellaneous Charge Success!`, res.data.message, 'success')
        this.resetForm()
        this.getMiscellaneousCharge()
        this.saving = false
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.saving = false
      }
      this.saving = false
    },
  }
}
</script>

<style scoped>
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.activeRecommendation ? _c('div', [_c('b-modal', {
    attrs: {
      "size": "lg",
      "ok-title": "Filter",
      "title": "Filter Data",
      "centered": ""
    },
    on: {
      "ok": function ok($event) {
        return _vm.handleFetchRecommendation(1);
      }
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', {
    staticClass: "px-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Date Range",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "date",
      "value-type": "YYYY-MM-DD",
      "range": "",
      "placeholder": "Select date range"
    },
    on: {
      "change": _vm.handleDateRange
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Fleet/Vessel",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_vm.vehicleId ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.fleets.find(function (fleet) {
    return fleet.code === _vm.vehicleId;
  }) ? _vm.fleets.find(function (fleet) {
    return fleet.code === _vm.vehicleId;
  }).name : '-') + " ")]) : _c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.fleets,
      "reduce": function reduce(fleetList) {
        return fleetList.code;
      }
    },
    model: {
      value: _vm.recommendations.params.vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.recommendations.params, "vehicle_id", $$v);
      },
      expression: "recommendations.params.vehicle_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Type of Coverage",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.typeCoverages,
      "reduce": function reduce(typeOfCoverage) {
        return typeOfCoverage.code;
      }
    },
    model: {
      value: _vm.recommendations.params.type_coverage_id,
      callback: function callback($$v) {
        _vm.$set(_vm.recommendations.params, "type_coverage_id", $$v);
      },
      expression: "recommendations.params.type_coverage_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Type of Loss",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.typeLoss,
      "reduce": function reduce(typeOfLoss) {
        return typeOfLoss.code;
      }
    },
    model: {
      value: _vm.recommendations.params.type_loss_id,
      callback: function callback($$v) {
        _vm.$set(_vm.recommendations.params, "type_loss_id", $$v);
      },
      expression: "recommendations.params.type_loss_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label-for": "dateIssued",
      "label": "Status",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.statusOptions,
      "reduce": function reduce(statusOptions) {
        return statusOptions.value;
      }
    },
    model: {
      value: _vm.recommendations.params.status_id,
      callback: function callback($$v) {
        _vm.$set(_vm.recommendations.params, "status_id", $$v);
      },
      expression: "recommendations.params.status_id"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Recommendation Form List")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$fleets$find, _vm$typeCoverages$fin, _vm$typeLoss$find;
        return [_c('div', {
          staticClass: "d-flex justify-content-between mb-3"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.toggleModalFilter
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data")])])], 1), _c('div', [_vm.canExport || _vm.canExportRecommendation ? _c('router-link', {
          attrs: {
            "to": "#"
          }
        }, [_c('button', {
          staticClass: "btn btn-outline-primary mr-1"
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v(" Export")])]) : _vm._e(), _vm.canAdd && _vm.canAddRecommendation ? _c('router-link', {
          attrs: {
            "to": "/mi/add-form-recommendation"
          }
        }, [_c('button', {
          staticClass: "btn btn-primary"
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Form Recommendation")])]) : _vm._e()], 1)]), _c('div', {
          staticClass: "px-3 mb-3"
        }, [_c('b-row', {
          staticClass: "py-3 border"
        }, [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Range"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.moment(_vm.dateRange[0]).format('DD MMM YYYY')) + " - " + _vm._s(_vm.moment(_vm.dateRange[1]).format('DD MMM YYYY')) + " ")])])], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_vm.recommendations.params.vehicle_id ? _c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Fleet/Vessel"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s((_vm$fleets$find = _vm.fleets.find(function (el) {
          return el.code == _vm.recommendations.params.vehicle_id;
        })) === null || _vm$fleets$find === void 0 ? void 0 : _vm$fleets$find.name) + " ")])]) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_vm.recommendations.params.type_coverage_id ? _c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Type of Coverage"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s((_vm$typeCoverages$fin = _vm.typeCoverages.find(function (el) {
          return el.code == _vm.recommendations.params.type_coverage_id;
        })) === null || _vm$typeCoverages$fin === void 0 ? void 0 : _vm$typeCoverages$fin.name) + " ")])]) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_vm.recommendations.params.type_loss_id ? _c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Type of Loss"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s((_vm$typeLoss$find = _vm.typeLoss.find(function (el) {
          return el.code == _vm.recommendations.params.type_loss_id;
        })) === null || _vm$typeLoss$find === void 0 ? void 0 : _vm$typeLoss$find.name) + " ")])]) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_vm.recommendations.params.status_id ? _c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Status"
          }
        }, [_c('h6', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.statusOptions.find(function (el) {
          return el.value == _vm.recommendations.params.status_id;
        }).text) + " ")])]) : _vm._e()], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "12"
          }
        }), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table', {
          staticClass: "table table-borderless table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Problem / Issue")]), _c('th', [_vm._v("Cause of Loss")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Closed Date")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Detail")]), _c('th', [_vm._v("Action")])])]), _vm.recommendations.data.length === 0 || _vm.recommendations.isLoading ? _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "10"
          }
        }, [_vm._v(_vm._s(_vm.recommendations.isLoading ? 'Loading...' : 'No Data'))])])]) : _vm._e(), _vm._l(_vm.recommendations.data, function (data, index) {
          var _data$vessel_case, _data$root_cause, _data$recommendation;
          return _c('tbody', {
            key: index
          }, [_c('tr', [_c('td', {
            staticStyle: {
              "width": "4%"
            }
          }, [_vm._v(_vm._s(_vm.recommendations.meta.pageNumber + index))]), _c('td', {
            staticStyle: {
              "width": "400px"
            }
          }, [_vm._v(_vm._s(data.problem))]), _c('td', [_vm._v(_vm._s(_vm.causesOfLoss.find(function (val) {
            return data.cause_of_loss_id === val.code;
          }) ? _vm.causesOfLoss.find(function (val) {
            return data.cause_of_loss_id === val.code;
          }).name : '-'))]), _c('td', [_vm._v(_vm._s(data.status))]), _c('td', [_vm._v(_vm._s(data.closed_at ? _vm.moment(data.closed_at).format('DD MMM YYYY') : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-button', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "rekomendation-".concat(index),
              expression: "`rekomendation-${index}`"
            }],
            staticClass: "text-truncate",
            staticStyle: {
              "font-size": "small"
            },
            attrs: {
              "variant": "primary",
              "size": "sm"
            }
          }, [_vm._v("View Detail")])], 1), _c('td', {
            staticClass: "d-flex"
          }, [_vm.canEditRecommendation ? _c('b-button', {
            staticClass: "mr-1",
            attrs: {
              "variant": "outline-primary",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.handleEditRecommendation(data.id, data.form_id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.canDeleteRecommendation ? _c('b-button', {
            attrs: {
              "variant": "outline-danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeleteRecommendation(data);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]), _c('tr', [_c('td', {
            staticStyle: {
              "padding": "0 !important"
            },
            attrs: {
              "colspan": "7"
            }
          }, [_c('b-collapse', {
            attrs: {
              "id": "rekomendation-".concat(index)
            }
          }, [_c('div', {
            staticClass: "pl-5"
          }, [_c('div', {
            staticClass: "p-3",
            staticStyle: {
              "background": "#c7ebff"
            }
          }, [_c('h6', [_c('Strong', [_vm._v("Vessel Case")])], 1), _c('h6', [_vm._v(_vm._s((_data$vessel_case = data.vessel_case) !== null && _data$vessel_case !== void 0 ? _data$vessel_case : '-'))])]), _c('div', {
            staticClass: "p-3",
            staticStyle: {
              "background": "#f1faff"
            }
          }, [_c('h6', [_c('Strong', [_vm._v("Route Cause")])], 1), _c('h6', [_vm._v(_vm._s((_data$root_cause = data.root_cause) !== null && _data$root_cause !== void 0 ? _data$root_cause : '-'))])]), _c('div', {
            staticClass: "p-3",
            staticStyle: {
              "background": "#c7ebff"
            }
          }, [_c('h6', [_c('Strong', [_vm._v("Rekomendasi")])], 1), _c('h6', [_vm._v(_vm._s((_data$recommendation = data.recommendation) !== null && _data$recommendation !== void 0 ? _data$recommendation : '-'))])])])])], 1)])]);
        })], 2), _c('div', {
          staticClass: "mt-3 mb-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.recommendations.meta.total,
            "per-page": _vm.recommendations.meta.perPage,
            "align": "center"
          },
          on: {
            "input": _vm.handleFetchRecommendation
          },
          model: {
            value: _vm.recommendations.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.recommendations.params, "page", $$v);
            },
            expression: "recommendations.params.page"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }], null, false, 4042199744)
  })], 1)], 1)], 1) : _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("You don't have permission to access this menu")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }

<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h5 class="card-title text-primary"><strong>Filter Data</strong></h5>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Period"
                label-for="period"
              >
                <date-picker type="date" class="w-100" range placeholder="Select date range"></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Company"
                label-for="compnay"
              >
                <v-select
                  label="text"
                  :options="[]"
                  placeholder="Select..."
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Group Data"
                label-for="group"
              >
                <v-select
                  label="text"
                  :options="[]"
                  placeholder="Select..."
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <button type="button" class="btn" :style="{ backgroundColor: '#2BDCAA', color: 'white', marginTop:'30px' }" block><i class="fa fa-search" /></button>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>

    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h5 class="card-title text-primary"><strong>Cargo Analysis</strong></h5>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12">
              <CargoAnalysis />
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>

    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h5 class="card-title text-primary"><strong>Sales Analysis</strong></h5>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12">
              <SalesAnalysis />
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import CargoAnalysis from './components/CargoAnalysis.vue'
import SalesAnalysis from './components/SalesAnalysis.vue'
export default {
 name:'CargoSalesAnalysis',
 components:{ CargoAnalysis, SalesAnalysis },
 data() {
   return {
     
   }
 },
 mounted() {
   
 },
 methods: {
   
 },
}
</script>
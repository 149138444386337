<template>
  <div>
    <template v-if="!loading.rca">
    <form v-if="detailMi" action="" @submit.prevent="onSave">
      <b-alert v-if="!rootCauseAnalysis" variant="danger" class="d-flex align-items-center" show>Isi RCA Terlebih Dahulu  <b-button  class="ml-3" variant="primary" :to="`/mi/root-cause-analysis?formId=${$route.query.formId}`">Disini</b-button></b-alert>
        <b-row v-else>
          <b-col md="12" class="mb-3 text-right">
            <!-- <b-button  variant="primary" :disabled="form._saving" type="submit">{{form._saving ? 'Menyimpan...' : 'Simpan'}}</b-button> -->
            <b-button  variant="primary" class="ml-2" @click="isEditMode = !isEditMode">{{ isEditMode ? 'Preview' : 'Update'}} Rca Final</b-button>
          </b-col>
          <b-col md="12" class="mb-4">
            <b-row>
              <b-col md="12" class="mb-3"><h5 class="border-bottom py-2">Assessor of the Accident</h5></b-col>
              <b-col md="12">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td style="width: 25%;">
                        <b-form-group label="Name">
                          <b-form-input v-if="isEditMode" class="w-100" v-model="rootCauseAnalysis.assessor_fullname" readonly type="text" placeholder="Name..."></b-form-input>
                          <h6 v-else style="font-weight: 500">{{ rootCauseAnalysis.assessor_fullname ?? '-'}}</h6>
                          </b-form-group>
                        </td>
                        <td style="width: 25%;">
                          <b-form-group label="Vessel">
                            <b-form-input v-if="isEditMode" class="w-100" :value="detailMi.vehicle.name" readonly type="text" placeholder="Vessel..."></b-form-input>
                            <h6 v-else style="font-weight: 500">{{ detailMi.vehicle.name ?? '-' }}</h6>
                          </b-form-group>
                        </td>
                        <td style="width: 25%;">
                          <b-form-group label="Company">
                            <b-form-select v-if="isEditMode" plain v-model="rootCauseAnalysis.assessor_company_id" disabled :options="companies" size="lg">
                              <template v-slot:first>
                                <b-form-select-option :value="null">Select Company</b-form-select-option>
                              </template>
                            </b-form-select>
                            <h6 v-else style="font-weight: 500">{{ rootCauseAnalysis.assessor_company_id ? companies.find((el)=> el.value ==  rootCauseAnalysis.assessor_company_id ).text :'-'}}</h6>
                        </b-form-group>
                      </td>
                      <td style="width: 25%;">
                        <b-form-group label="Report Number">
                          <b-form-input v-if="isEditMode" class="w-100" v-model="form.report_number" type="text" placeholder="Report Number..."></b-form-input>
                          <h6 v-else style="font-weight: 500">{{ form.report_number ?? '-' }}</h6>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 25%;">
                        <b-form-group label="Position">
                          <b-form-input v-if="isEditMode" class="w-100" v-model="rootCauseAnalysis.assessor_position" readonly type="text" placeholder="Name..."></b-form-input>
                          <h6 v-else style="font-weight: 500">{{ rootCauseAnalysis.assessor_position ?? '-' }}</h6>
                        </b-form-group>
                      </td>
                      <td style="width: 25%;">   
                        <b-form-group label="Place of Loss">
                          <b-form-input v-if="isEditMode" class="w-100" :value="detailMi.place_of_loss" readonly type="text" placeholder="Name..."></b-form-input>
                          <h6 v-else style="font-weight: 500">{{ detailMi.place_of_loss ?? '-' }}</h6>
                        </b-form-group>
                      </td>
                      <td style="width: 25%;">
                        <b-form-group label="Type of Loss">
                          <b-form-input v-if="isEditMode"  class="w-100" :value="detailMi.type_loss.name" readonly type="text" placeholder="Name..."></b-form-input>
                          <h6 v-else style="font-weight: 500">{{ detailMi.type_loss.name ?? '-' }}</h6>
                        </b-form-group>
                      </td>
                      <td>
                        <b-form-group label="Type of Coverage">
                          <b-form-input v-if="isEditMode" class="w-100" :value="detailMi.type_coverage.name" readonly type="text" placeholder="Name..."></b-form-input>
                          <h6 v-else style="font-weight: 500">{{ detailMi.type_coverage.name ?? '-' }}</h6>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 25%;">
                        <b-form-group label="Date of Loss">
                          <date-picker  v-if="isEditMode" type="datetime" v-model="rootCauseAnalysis.date_of_loss" disabled value-type="YYYY-MM-DD" format="DD MMM YYYY" class="w-100" placeholder="Select date and time"></date-picker>
                          <h6 v-else style="font-weight: 500">{{ rootCauseAnalysis.date_of_loss ?? '-' }}</h6>
                        </b-form-group>
                      </td>
                      <td style="width: 25%;">
                        <b-form-group label="Starting Date *">
                          <date-picker v-if="isEditMode"  type="date" is-required v-model="form.starting_date" value-type="YYYY-MM-DD" format="DD MMM YYYY" class="w-100" placeholder="Select date and time"></date-picker>
                          <h6 v-else style="font-weight: 500">{{ form.starting_date ?moment(form.starting_date).format('DD MMM YYYY') : '-' }}</h6>
                        </b-form-group>
                      </td>
                      <td style="width: 25%;">
                        <b-form-group label="Closing Date *">
                          <date-picker v-if="isEditMode"  type="date" is-required v-model="form.closing_date" value-type="YYYY-MM-DD" format="DD MMM YYYY" class="w-100" placeholder="Select date and time"></date-picker>
                          <h6 v-else style="font-weight: 500">{{ form.closing_date ?moment(form.closing_date).format('DD MMM YYYY') : '-' }}</h6>
                        </b-form-group>
                      </td>
                      <td style="width: 25%;"></td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
    <!--          <b-col md="3">-->
    <!--            <b-form-group label="Images (multiple) *">-->
    <!--              <b-form-file class="w-100" multiple placeholder="Select file..."></b-form-file>-->
    <!--            </b-form-group>-->
    <!--          </b-col>-->
            </b-row>
          </b-col>
          <b-col md="12" class="mb-5">
            <TabPaneRcaFinal :activeTab="activeTab" @clickTab="(val)=> activeTab = val"/>
          </b-col>
          <b-col md="12" class="mt-2 m-auto" v-if="activeTab == 1">
            <div class="my-3">
              <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                <div class="bg-primary p-2 mr-2" style="border-radius: 8px;">
                  <h4 class="text-white">
                    A.1
                  </h4>
                </div>
                <h5>Investigation Team</h5>
              </div>
              <div>
                <table class="table table-striped w-100" v-if="form.investigation_teams?.length">
                  <thead>
                    <tr>
                      <th width="20%">Team List</th>
                      <th width="20%">Name</th>
                      <th width="20%">Position</th>
                      <th width="20%">Remarks</th>
                      <th width="10%" v-if="isEditMode">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in form.investigation_teams" :key="index">
                    <td>
                      <b-form-input v-if="isEditMode" v-model="data.team" class="w-100" type="text" placeholder="Name..."></b-form-input>
                      <h6 v-else >{{ data.team ?? '-' }}</h6>
                    </td>
                    <td>
                      <b-form-input v-if="isEditMode" v-model="data.name" class="w-100" type="text" placeholder="Name..."></b-form-input>
                      <h6 v-else >{{ data.name ?? '-' }}</h6>
                    </td>
                    <td>
                      <b-form-input v-if="isEditMode" v-model="data.position" class="w-100" type="text" placeholder="Position..."></b-form-input>
                      <h6 v-else >{{ data.position ?? '-' }}</h6>
                    </td>
                    <td>
                      <b-form-input v-if="isEditMode" v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                      <h6 v-else >{{ data.remarks ?? '-' }}</h6>
                    </td>
                    <td v-if="isEditMode">
                      <b-button  @click="removeInvestigationTeam(index)" variant="danger">Remove</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="text-center" v-else>
                  No Data Available!
                </div>
                <b-button v-if="isEditMode"  variant="warning" @click="addInvestigationTeam()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </div>
            </div>
            <div class="my-3">
              <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                <div class="bg-primary p-2 mr-2" style="border-radius: 8px;">
                  <h4 class="text-white">
                    A.2
                  </h4>
                </div>
                <h5>Direct Witness</h5>
              </div>
              <div>
                <table class="table table-striped w-100" v-if="form.direct_witnesses?.length">
                  <thead>
                  <tr>
                    <th width="10%">No</th>
                    <th width="20%">Name</th>
                    <th width="20%">Position</th>
                    <th width="20%">Remarks</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in form.direct_witnesses" :key="index">
                    <td>
                      {{index+1}}
                    </td>
                    <td>
                      {{data.detail ? data.detail.name : '-'}}
    <!--                  <b-form-select plain v-model="data.detail" size="lg">-->
    <!--                    <b-form-select-option :value="null">(Please select)</b-form-select-option>-->
    <!--                    <b-form-select-option v-for="(listRca, indexRca) in listRootCauseAnalysis" :key="indexRca" :value="listRca">{{listRca.assessor_fullname}}</b-form-select-option>-->
    <!--                  </b-form-select>-->
                    </td>
                    <td>
                      {{data.detail ? data.detail.position : '-'}}
                    </td>
                    <td>
                      {{data.detail ? data.detail.remarks : '-'}}
                    </td>
                    <td>
    <!--                  <b-button  @click="removeDirectWitnesses(index)" variant="danger">Remove</b-button>-->
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="text-center" v-else>
                  No Data Available!
                </div>
    <!--            <b-button  variant="warning" @click="addDirectWitnesses()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>-->
              </div>
            </div>
            <div class="my-3">
              <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                <div class="bg-primary p-2 mr-2" style="border-radius: 8px;">
                  <h4 class="text-white">
                    A.3
                  </h4>
                </div>
                <h5>Indirect Witness</h5>
              </div>
              <div>
                <table class="table table-striped w-100" v-if="form.indirect_witnesses?.length">
                  <thead>
                  <tr>
                    <th width="10%">No</th>
                    <th width="20%">Name</th>
                    <th width="20%">Position</th>
                    <th width="20%">Remarks</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in form.indirect_witnesses" :key="index">
                    <td>
                      {{index+1}}
                    </td>
                    <td>
                      {{data.detail ? data.detail.name : '-'}}
                      <!--                  <b-form-select plain v-model="data.detail" size="lg">-->
                      <!--                    <b-form-select-option :value="null">(Please select)</b-form-select-option>-->
                      <!--                    <b-form-select-option v-for="(listRca, indexRca) in listRootCauseAnalysis" :key="indexRca" :value="listRca">{{listRca.assessor_fullname}}</b-form-select-option>-->
                      <!--                  </b-form-select>-->
                    </td>
                    <td>
                      {{data.detail ? data.detail.position : '-'}}
                    </td>
                    <td>
                      {{data.detail ? data.detail.remarks : '-'}}
                    </td>
                    <td>
                      <!--                  <b-button  @click="removeIndirectWitnesses(index)" variant="danger">Remove</b-button>-->
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="text-center" v-else>
                  No Data Available!
                </div>
    <!--            <b-button  variant="warning" @click="addIndirectWitnesses()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>-->
              </div>
            </div>
          </b-col>
    
          <!--    supporting document-->
          <b-col md="12" class="mt-2" v-if="activeTab == 2">
            <div>
              <table class="table w-100">
                <thead>
                <tr>
                  <th width="5%" class="text-center">No</th>
                  <th width="20%" >Document</th>
                  <th width="5%" class="text-center">Original</th>
                  <th width="5%" class="text-center">Copy</th>
                  <th width="15%" >PIC</th>
                  <th width="15%">Attachment</th>
                  <th width="20%">Remarks</th>
                  <th v-if="isEditMode" width="10%" class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in form.supporting_documents" :key="index">
                  <td class="text-center">
                    {{index+1}}
                  </td>
                  <td>
                    <b-form-input v-if="isEditMode" v-model="data.document" required class="w-100" type="text" placeholder="Name..."></b-form-input>
                    <h6 v-else>{{ data.document ?? '-' }}</h6>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox :disabled="!isEditMode" v-model="data.original" value="v" unchecked-value="x" class="w-100"></b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox :disabled="!isEditMode" v-model="data.copy" value="v" unchecked-value="x" class="w-100"></b-form-checkbox>
                  </td>
                  <td>
                    <b-form-input v-if="isEditMode" v-model="data.pic" required class="w-100" type="text" placeholder="PIC..."></b-form-input>
                    <h6 v-else>{{ data.pic ?? '-' }}</h6>
                  </td>
                  <td>
                    <div v-if="data.document" class="d-flex justify-content-between">
                      <a :href="data.document" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                      <a v-if="isEditMode" href="#" @click.prevent="data.document = '';data.file = ''" class="text-danger" v-b-tooltip.hover title="Download" download><i class="fa fa-trash"></i></a>
                    </div>
                    <template v-else>
                      <div class="custom-file">
                        <input type="file" @change="onSupportingDocumentsFileChanged(index, $event)" accept="image/png,image/jpg,image/jpeg" class="custom-file-input" id="validatedCustomFile">
                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                      </div>
                    </template>
                  </td>
                  <td>
                    <b-form-input v-if="isEditMode" v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                    <h6 v-else>{{ data.remarks ?? '-' }}</h6>
                  </td>
                  <td class="text-center" v-if="isEditMode">
                    <b-button  @click="removeSupportingDocuments(index)" variant="danger">Remove</b-button>
                  </td>
                </tr>
                </tbody>
              </table>
              <b-button v-if="isEditMode"  variant="warning" @click="addSupportingDocuments()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
            </div>
          </b-col>
    
          <!--    details of investigation-->
          <b-col md="12" class="mt-2" v-if="activeTab == 3">
            <b-row v-if="isEditMode">
              <b-col md="12" class="mt-2">
                <b-form-group label="Images (multiple) *">
                  <b-form-file class="w-100" accept="image/*" @change="onImagesFileChanged" multiple placeholder="Select file..."></b-form-file>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-else class="mb-4">
              <b-col class="d-flex justify-content-center py-3" md="3" v-for="(img, index) in form.images" :key="index" >
                <div class="bg-white p-2 border shadow" style="border-radius: 14px">
                  <img class="border" style="border-radius: 12px;" height="240" width="360" :src="img" alt="">
                </div>
              </b-col>
            </b-row>
          </b-col>
    
          <!--    summary of accident-->
          <b-col md="12" class="mt-2 mb-4" v-if="activeTab == 4">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <b-form-group label="The Problem *" label-class="font-500">
                      <b-form-textarea class="bg-white" v-if="isEditMode" required v-model="form.problem" rows="4"></b-form-textarea>
                      <h6 v-else>{{form.problem ?? '-'}}</h6>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group  label="Cause Of Loss *" label-class="font-500">
                      <b-form-textarea class="bg-white" v-if="isEditMode"  required v-model="form.cause_of_loss" rows="4"></b-form-textarea>
                      <h6 v-else>{{ form.cause_of_loss ?? '-' }}</h6>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group label="Impact Of Damage *" label-class="font-500">
                      <b-form-textarea class="bg-white" v-if="isEditMode" required v-model="form.impact" rows="4"></b-form-textarea>
                      <h6 v-else>{{ form.cause_of_loss ?? '-' }}</h6>
                    </b-form-group>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-row>
              <b-col md="12" class="mt-3">
                <div class="w-100">
                  <h5 class="mb-2" style="font-weight: 600">D.1 Cost of Damage</h5>
                  <div>
                    <table class="table w-100 table-striped">
                      <thead>
                        <tr>
                          <th width="5%">No</th>
                          <th width="20%">Description</th>
                          <th width="20%">Remarks</th>
                          <th width="15%" class="text-right">Cost</th>
                          <th width="15%" class="text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(data, index) in form.damage_costs" :key="index">
                        <td>
                          {{index+1}}
                        </td>
                        <td>
                          <b-form-input v-if="isEditMode" v-model="data.description" class="w-100" type="text" placeholder="Name..."></b-form-input>
                          <h6 v-else>{{ data.description ?? '-' }}</h6>
                        </td>
                        <td>
                          <b-form-input v-if="isEditMode" v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                          <h6 v-else>{{ data.remarks ?? '-' }}</h6>
                        </td>
                        <td class="text-right">
                          <b-input-group v-if="isEditMode"  class="d-flex justify-content-end">
                            <InputCurrency v-model="data.cost" type="number"></InputCurrency>
                          </b-input-group>
                          <h6 v-else >Rp. {{ data.cost ? numberFormat(data.total): '-' }}</h6>
                        </td>
                        <td class="text-right">
                          <b-input-group v-if="isEditMode" class="d-flex justify-content-end">
                            <InputCurrency  v-model="data.total" type="number"></InputCurrency>
                          </b-input-group>
                          <h6 v-else >Rp. {{ data.total ?  numberFormat(data.total) : '-' }}</h6>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <th style="background: #FFEFE1;"></th>
                        <th style="background: #FFEFE1;" colspan="2" class="text-black">
                          TOTAL
                        </th>
                        <th style="background: #FFEFE1;" class="text-black text-right">
                          Rp. {{ numberFormat(costOfDamagesSumCost) }}
                        </th>
                        <th style="background: #FFEFE1;" class="text-black text-right">
                          Rp. {{ numberFormat(costOfDamagesSumTotal) }}
                        </th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div>
                  <h5 style="font-weight: 600" class="my-2 border-bottom pb-2">D.2 Cause & Effect (Findings) - For Details please see attachment of Fishbone Diagram for each Witness</h5>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="12">
                <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                  <div class="bg-primary p-2 mr-2" style="border-radius: 8px;">
                    <h5 class="text-white" style="font-weight: 500">
                      D.2.1
                    </h5>
                  </div>
                  <h5>Human</h5>
                </div>
              </b-col>
              <b-col md="10">
                <div>
                  <table class="table w-100">
                    <thead>
                    <tr>
                      <th width="5%">No</th>
                      <th width="40%">Findings</th>
                      <th width="10%" class="text-center">Proximity Level</th>
                      <th width="10%" v-if="isEditMode">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data, index) in findingsByPrefix(1)" :key="index">
                      <td>
                        D.2.1-{{index+1}}
                      </td>
                      <td>
                        <b-form-textarea v-if="isEditMode" required v-model="data.text" rows="2"></b-form-textarea>
                        <h6 v-else>{{ data.text ?? '-' }}</h6>
                      </td>
                      <td class="text-center">
                        <b-form-select v-if="isEditMode" plain v-model="data.proximity" size="lg">
                          <b-form-select-option :value="null">(Please select)</b-form-select-option>
                          <b-form-select-option v-for="n in 5" :key="n" :value="n">{{n}}</b-form-select-option>
                        </b-form-select>
                        <h6 v-else>{{ data.proximity ?? '-' }}</h6>
                      </td>
                      <td v-if="isEditMode">
                        <b-button  @click="removeFindings(data.value)" variant="danger">Remove</b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <b-button v-if="isEditMode" variant="warning" @click="addFindings(1)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
                </div>
              </b-col>
              <b-col md="2">
               <LevelIndocator/>
              </b-col>
              <!-- <b-col md="3">
                <img src="/img/level-priority.png" alt="">
              </b-col> -->
            </b-row>
            <b-row class="mb-3">
              <b-col md="12">
                <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                  <div class="bg-primary p-2 mr-2" style="border-radius: 8px;">
                    <h5 class="text-white" style="font-weight: 500">
                      D.2.2
                    </h5>
                  </div>
                  <h5>Machinery/Equipment etc</h5>
                </div>
              </b-col>
              <b-col md="10">
                <div>
                  <table class="table w-100">
                    <thead>
                    <tr>
                      <th width="5%">No</th>
                      <th width="40%">Findings</th>
                      <th width="10%" class="text-center">Proximity Level</th>
                      <th width="10%" v-if="isEditMode">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data, index) in findingsByPrefix(2)" :key="index">
                      <td>
                        D.2.2-{{index+1}}
                      </td>
                      <td>
                        <b-form-textarea v-if="isEditMode" required v-model="data.text" rows="2"></b-form-textarea>
                        <h6>{{ data.text ?? '-' }}</h6>
                      </td>
                      <td class="text-center">
                        <b-form-select v-if="isEditMode" plain v-model="data.proximity" size="lg">
                          <b-form-select-option :value="null">(Please select)</b-form-select-option>
                          <b-form-select-option v-for="n in 5" :key="n" :value="n">{{n}}</b-form-select-option>
                        </b-form-select>
                        <h6 v-else>{{ data.proximity ?? '-' }}</h6>
                      </td>
                      <td v-if="isEditMode">
                        <b-button  @click="removeFindings(data.value)" variant="danger">Remove</b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <b-button v-if="isEditMode" variant="warning" @click="addFindings(2)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
                </div>
              </b-col>
              <b-col md="2">
               <LevelIndocator/>
                <!-- <img src="/img/level-priority.png" alt=""> -->
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="12">
                <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                  <div class="bg-primary p-2 mr-2" style="border-radius: 8px;">
                    <h5 class="text-white" style="font-weight: 500">
                      D.2.3
                    </h5>
                  </div>
                  <h5>Control, System, Method,Process</h5>
                </div>
              </b-col>
              <b-col md="10">
                <div>
                  <table class="table w-100">
                    <thead>
                    <tr>
                      <th width="5%">No</th>
                      <th width="40%">Findings</th>
                      <th width="10%" class="text-center">Proximity Level</th>
                      <th width="10%" v-if="isEditMode">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data, index) in findingsByPrefix(3)" :key="index">
                      <td>
                        D.2.3-{{index+1}}
                      </td>
                      <td>
                        <b-form-textarea v-if="isEditMode"  required v-model="data.text" rows="2"></b-form-textarea>
                        <h6 v-else>{{ data.text ?? '-' }}</h6>
                      </td>
                      <td class="text-center">
                        <b-form-select v-if="isEditMode" plain v-model="data.proximity" size="lg">
                          <b-form-select-option :value="null">(Please select)</b-form-select-option>
                          <b-form-select-option v-for="n in 5" :key="n" :value="n">{{n}}</b-form-select-option>
                        </b-form-select>
                        <h6 v-else>{{ data.proximity ?? '-' }}</h6>
                      </td>
                      <td v-if="isEditMode">
                        <b-button  @click="removeFindings(data.value)" variant="danger">Remove</b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <b-button v-if="isEditMode" variant="warning" @click="addFindings(3)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
                </div>
              </b-col>
              <b-col md="2">
               <LevelIndocator/>
              </b-col>
              <!-- <b-col md="3">
                <img src="/img/level-priority.png" alt="">
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12">
                <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                  <div class="bg-primary p-2 mr-2" style="border-radius: 8px;">
                    <h5 class="text-white" style="font-weight: 500">
                      D.2.4
                    </h5>
                  </div>
                  <h5>Other Factor</h5>
                </div>
              </b-col>
              <b-col md="10">
                <div>
                  <table class="table w-100">
                    <thead>
                    <tr>
                      <th width="5%">No</th>
                      <th width="40%">Findings</th>
                      <th width="10%">Proximity Level</th>
                      <th width="10%" v-if="isEditMode">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data, index) in findingsByPrefix(4)" :key="index">
                      <td>
                        D.2.4-{{index+1}}
                      </td>
                      <td>
                        <b-form-textarea v-if="isEditMode" required v-model="data.text" rows="2"></b-form-textarea>
                        <h6 v-else>{{data.text}}</h6>
                      </td>
                      <td>
                        <b-form-select v-if="isEditMode" plain v-model="data.proximity" size="lg">
                          <b-form-select-option :value="null">(Please select)</b-form-select-option>
                          <b-form-select-option v-for="(n, i) in 5" :key="i" :value="n">{{n}}</b-form-select-option>
                        </b-form-select>
                        <h6 v-else>{{ data.proximity ?? '-' }}</h6>
                      </td>
                      <td v-if="isEditMode">
                        <b-button  @click="removeFindings(data.value)" variant="danger">Remove</b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <b-button v-if="isEditMode" variant="warning" @click="addFindings(4)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
                </div>
              </b-col>
              <b-col md="2">
               <LevelIndocator/>
              </b-col>
              <!-- <b-col md="3">
                <img src="/img/level-priority.png" alt="">
              </b-col> -->
            </b-row>
          </b-col>
    
          <!-- claim opportunity -->
          <b-col md="12" class="mt-2" v-if="activeTab == 5">
            <b-row>
              <b-col md="12">
                <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                  <div class="bg-primary p-2 px-3 mr-2" style="border-radius: 8px;">
                    <h5 class="text-white" style="font-weight: 500">
                      E.1
                    </h5>
                  </div>
                  <h5>Initial Claim Analysis</h5>
                </div>
              </b-col>
              <b-col md="12">
                <div>
                  <div v-if="detailSurvey">
                    <b-table-simple responsive width="100%" striped class="mb-0">
                      <b-thead>
                        <b-th width="64px" class="text-center">NO</b-th>
                        <b-th v-for="(header, headerIndex) in detailSurvey.headers" :key="headerIndex">{{header.label_name}}</b-th>
                      </b-thead>
                      <b-tbody v-if="detailSurvey.headers[0]">
                        <b-tr v-for="(number, idx) in detailSurvey.headers[0].contents.length" :key="idx" >
                          <b-td width="64px" class="text-center">{{number}}</b-td>
                          <b-td  v-for="(header, headerIndex) in detailSurvey.headers" :key="headerIndex">
                            <template v-if="header.contents[number-1]">
                              <template v-if="headerIndex == 2">
                                <b-badge class="p-2 px-3" variant="success" style="font-size: 16px;">
                                    {{header.contents[number-1].input.value}}
                                </b-badge>
                              </template>
                              <template v-else>
                                <template v-if="header.contents[number-1].text">
                                  {{header.contents[number-1].text}}
                                </template>
                                <template v-else-if="header.contents[number-1].input">
                                  {{header.contents[number-1].input.value}}
                                </template>
                                <template v-else>
                                  -
                                </template>
                              </template>
                            </template>
                          </b-td>
                        </b-tr>
                        <b-tr style="background: #FFEFE1;">
                          <b-td colspan="3" class="text-right"><h5><b>Nilai Seluruhnya</b></h5></b-td>
                          <b-td >
                            <b-badge class="p-2 px-3" variant="success" style="font-size: 16px;">
                              {{ totalAll }}
                            </b-badge>
                          </b-td>
                          <b-td>&nbsp;</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div v-else>
                    -
                  </div>
                  <template v-if="detailFillSurvey && detailFillSurvey.claim_analysis">
                    <div class="d-flex p-3" style="background: #F9FAFB;">
                      <div class=" mr-3">
                        <h6 style="font-weight: 600;">Conclusion</h6>
                        <div>
                          {{detailFillSurvey.claim_analysis.conclusion || '-'}}
                        </div>
                      </div>
                      <div class="">
                        <h6 style="font-weight: 600;">Comments</h6>
                        <div>
                          {{detailFillSurvey.claim_analysis.comments || '-'}}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </b-col>
            </b-row>
            <b-row class="my-4">
              <b-col md="12">
                <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                  <div class="bg-primary p-2 px-3 mr-2" style="border-radius: 8px;">
                    <h5 class="text-white" style="font-weight: 500">
                      E.2
                    </h5>
                  </div>
                  <h5>Document Control</h5>
                </div>
              </b-col>
              <b-col md="12">
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="20%">Document</th>
                    <th width="20%">Remarks</th>
                    <th width="10%">Status</th>
                    <th width="10%" v-if="isEditMode">Action</th>
                  </tr>
                  </thead>
                  <tbody v-if="form.document_controls.length">
                    <tr v-for="(data, index) in form.document_controls" :key="index">
                      <td>
                        {{index+1}}
                      </td>
                      <td>
                        <b-form-input v-if="isEditMode" v-model="data.document" required class="w-100" type="text" placeholder="Name..."></b-form-input>
                        <h6 v-else>{{ data.document ?? '-' }}</h6>
                      </td>
                      <td>
                        <b-form-input v-if="isEditMode" v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                        <h6 v-else>{{ data.remarks ?? '-' }}</h6>
                      </td>
                      <td>
                        <b-form-select v-if="isEditMode" v-model="data.status" plain>
                          <b-form-select-option :value="null">Status</b-form-select-option>
                          <b-form-select-option value="Top Priority">Top Priority</b-form-select-option>
                          <b-form-select-option value="Priority">Priority</b-form-select-option>
                        </b-form-select>
                        <h6>{{ data.status ?? '-' }}</h6>
                      </td>
                      <td v-if="isEditMode">
                        <b-button  @click="removeDocumentControls(index)" variant="danger">Remove</b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 class="text-center" v-if="!form.document_controls.length">No Data Available</h6>
              </b-col>
              <b-col md="12" v-if="isEditMode">
                <b-button  variant="warning" @click="addDocumentControls()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                  <div class="bg-primary p-2 px-3 mr-2" style="border-radius: 8px;">
                    <h5 class="text-white" style="font-weight: 500">
                      E.3
                    </h5>
                  </div>
                  <h5>Cost of Loss</h5>
                </div>
              </b-col>
              <b-col md="12">
                <table class="table w-100 table-striped" >
                  <thead>
                  <tr>
                    <th width="3%" class="text-center">No</th>
                    <th width="22%">Description</th>
                    <th width="22%">Remarks</th>
                    <th width="15%" class="text-right" >Cost</th>
                    <th width="15%" class="text-right pr-5">Total</th>
                    <th width="10%" v-if="isEditMode">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in form.loss_costs" :key="index">
                    <td width="3%" class="text-center">
                      {{index+1}}
                    </td>
                    <td>
                      <b-form-input v-if="isEditMode" v-model="data.description" class="w-100" type="text" placeholder="Name..."></b-form-input>
                      <h6 v-else>{{data.description ?? '-'}}</h6>
                    </td>
                    <td>
                      <b-form-input v-if="isEditMode" v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                      <h6 v-else>{{data.remarks ?? '-'}}</h6>
                    </td>
                    <td class="text-right">
                      <b-input-group prepend="Rp" v-if="isEditMode">
                        <b-form-input v-model="data.cost" type="number"></b-form-input>
                      </b-input-group>
                      <h6 v-else>Rp. {{numberFormat(data.cost)}}</h6>
                    </td>
                    <td class="text-right pr-5">
                      <b-input-group v-if="isEditMode" prepend="Rp">
                        <b-form-input v-model="data.total" type="number"></b-form-input>
                      </b-input-group>
                      <h6 v-else>Rp. {{numberFormat(data.total)}}</h6>
                    </td>
                    <td v-if="isEditMode" class="text-center">
                      <b-button  @click="removeCostLoss(index)" variant="danger">Remove</b-button>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th bgcolor="#ffd700" colspan="2" class="text-center">
                      TOTAL
                    </th>
                    <th bgcolor="#ffd700">
                    </th>
                    <th class="text-right" bgcolor="#ffd700">
                      Rp. {{ numberFormat(costOfLossSumCost) }}
                    </th>
                    <th class="text-right pr-5" bgcolor="#ffd700">
                      Rp. {{ numberFormat(costOfLossSumTotal) }}
                    </th>
                    <th bgcolor="#ffd700" v-if="isEditMode">
                    </th>
                  </tr>
                  </tfoot>
                </table>
                <b-button v-if="isEditMode"  variant="warning" @click="addCostLoss()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col md="12">
                <div class="d-flex align-items-center py-2 mb-3 border-bottom">
                  <div class="bg-primary p-2 px-3 mr-2" style="border-radius: 8px;">
                    <h5 class="text-white" style="font-weight: 500">
                      E.4
                    </h5>
                  </div>
                  <h5>Impact to Loss Ratio</h5>
                </div>
              </b-col>
              <b-col md="12">
                <div>
                  <h6 class="my-3"><strong>Individual</strong></h6>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th width="20%" class="text-center">Years</th>
                      <th width="20%" class="text-center">Premium</th>
                      <th width="20%" class="text-center">Claim Loss</th>
                      <th width="20%" class="text-center">Loss Ratio</th>
                    </tr>
                    </thead>
                    <tbody v-if="detailLossRatio.length">
                      <template v-for="(ratio, idx) in detailLossRatio" >
                        <tr :key="idx" v-if="ratio.individual">
                          <td class="text-center"><strong>{{ ratio.individual.year }}</strong></td>
                          <td class="text-center">{{ formatCurrency(ratio.individual.premium) }},-</td>
                          <td class="text-center">{{ formatCurrency(ratio.individual.claim_loss) }},-</td>
                          <td class="text-center">{{ ratio.individual.loss_ratio }}%</td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="4" class="text-center">No Data Available</td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 class="my-3"><strong>Accumulation</strong></h6>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th width="20%" class="text-center">Years</th>
                      <th width="20%" class="text-center">Acc Premium</th>
                      <th width="20%" class="text-center">Acc Loss</th>
                      <th width="20%" class="text-center">Acc Loss Ratio</th>
                    </tr>
                    </thead>
                    <tbody v-if="detailLossRatio.length">
                    <template v-for="(ratio, idx) in detailLossRatio" >
                      <tr :key="idx" v-if="ratio.accumulative">
                        <td class="text-center"><strong>{{ ratio.accumulative.year }}</strong></td>
                        <td class="text-center">{{ formatCurrency(ratio.accumulative.premium) }},-</td>
                        <td class="text-center">{{ formatCurrency(ratio.accumulative.claim_loss) }},-</td>
                        <td class="text-center">{{ ratio.accumulative.loss_ratio }}%</td>
                      </tr>
                    </template>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="4" class="text-center">No Data Available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- claim opportunity end-->
    
          <!-- Conclusion -->
          <b-col md="12" class="mt-2" v-if="activeTab == 6">
            <b-row>
              <b-col md="12">
                <div>
                  <div>
                    <table class="table">
                      <thead>
                      <tr>
                        <th width="5%" class="text-center" rowspan="2">No</th>
                        <th width="30%" class="text-center" rowspan="2">Damage</th>
                        <th width="10%" class="text-center" colspan="6">Root Cause &amp; Effect (Findings)</th>
                      </tr>
                      <tr>
                        <th width="10%" class="text-center text-truncate">Human</th>
                        <th width="10%" class="text-center text-truncate">Machinery/ Equipment</th>
                        <th width="10%" class="text-center text-truncate">Control, System, Method,Process</th>
                        <th width="10%" class="text-center text-truncate">Other Factor</th>
                        <th width="15%" class="text-center" rowspan="2">Status</th>
                        <th width="10%" class="text-center" rowspan="2" v-if="isEditMode">Action</th>
                      </tr>
                      </thead>
                      <tbody v-for="(data, index) in form.conclusions" :key="index">
                      <template >
                        <tr v-for="(row, indexRow) in data.rows" :key="indexRow">
                          <td v-if="indexRow === 0" class="text-center" :rowspan="data.rows.length + 1">
                            {{index+1}}
                          </td>
                          <td v-if="indexRow === 0" :rowspan="data.rows.length + 1">
                            <template v-if="isEditMode">
                              <b-form-textarea  required v-model="data.damage" rows="3">4</b-form-textarea>
                              <b-button  size="sm" @click="removeConclusion(index)" variant="link">Remove</b-button>
                            </template>
                            <h6 v-else>{{ data.damage ?? '-' }}</h6>
                          </td>
                          <td class="text-center">
                            <b-form-select v-if="isEditMode" v-model="row.human" required plain>
                              <b-form-select-option value="-">-</b-form-select-option>
                              <b-form-select-option v-for="(finding, idx) in findingsByPrefix(1)" :value="finding.value" :key="idx">{{finding.value}}</b-form-select-option>
                            </b-form-select>
                            <h6 v-else>{{row.human}}</h6>
                          </td>
                          <td class="text-center">
                            <b-form-select v-if="isEditMode" v-model="row.equipment" required plain>
                              <b-form-select-option value="-">-</b-form-select-option>
                              <b-form-select-option v-for="(finding, idx) in findingsByPrefix(2)" :value="finding.value" :key="idx">{{finding.value}}</b-form-select-option>
                            </b-form-select>
                            <h6 v-else>{{ row.equipment ?? '-' }}</h6>
                          </td>
                          <td class="text-center">
                            <b-form-select v-if="isEditMode" v-model="row.csmp" required plain>
                              <b-form-select-option value="-">-</b-form-select-option>
                              <b-form-select-option v-for="(finding, idx) in findingsByPrefix(3)" :value="finding.value" :key="idx">{{finding.value}}</b-form-select-option>
                            </b-form-select>
                            <h6 v-else>{{ row.csmp ?? '-' }}</h6>
                          </td>
                          <td class="text-center">
                            <b-form-select v-if="isEditMode" v-model="row.other" required plain>
                              <b-form-select-option value="-">-</b-form-select-option>
                              <b-form-select-option v-for="(finding, idx) in findingsByPrefix(4)" :key="idx" :value="finding.value">{{finding.value}}</b-form-select-option>
                            </b-form-select>
                            <h6 v-else>{{ row.other ?? '-' }}</h6>
                          </td>
                          <td class="d-flex justify-content-center">
                            <b-form-select v-if="isEditMode" v-model="row.status" plain>
                              <b-form-select-option :value="null">Status</b-form-select-option>
                              <b-form-select-option value="fatal">Fatal</b-form-select-option>
                              <b-form-select-option value="danger">Danger</b-form-select-option>
                              <b-form-select-option value="alert">Alert</b-form-select-option>
                              <b-form-select-option value="normal">Normal</b-form-select-option>
                            </b-form-select>
                            <div v-else style="width: max-content; border-radius: 8px" class="px-4 text-xs text-capitalize" :class="{'bg-danger text-white' : row.status === 'fatal',
                                'bg-warning text-white' : row.status === 'danger',
                                'bg-info text-white' : row.status === 'alert',
                                'bg-success text-white' : row.status === 'normal',}">{{row.status}}</div>
                          </td>
                          <td class="text-center"  v-if="isEditMode" >
                            <b-button v-if="indexRow > 0"  @click="removeConclusionRow(index, indexRow)"  variant="danger"><i class="fa fa-trash"></i></b-button>
                          </td>
                        </tr>
                        <tr v-if="isEditMode">
                          <td colspan="6">
                            <b-button  variant="warning" @click="addConclusionRow(index)" block class="mt-2 mb-3" ><i class="fa fa-plus"></i> Add More Data</b-button>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                    <b-button v-if="isEditMode"  variant="warning" @click="addConclusion()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- Conclusion End -->
    
          <!-- Recommendation -->
          <b-col md="12" class="mt-2" v-if="activeTab == 7">
            <b-row>
              <b-col md="12">
                <div>
                  <div>
                    <table class="table">
                      <thead>
                      <tr>
                        <th width="30%" rowspan="2">Helpful</th>
                        <th width="30%" rowspan="2">Harmful</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <div class="font-500">Strength</div>
                          <b-form-textarea v-if="isEditMode" required v-model="form.recommendation.strength" rows="3"></b-form-textarea>
                          <h6 v-else>{{ form.recommendation.strength ?? '-' }}</h6>
                        </td>
                        <td>
                          <div class="font-500">Weakness</div>
                          <b-form-textarea v-if="isEditMode"  required v-model="form.recommendation.weakness" rows="3"></b-form-textarea>
                          <h6 v-else>{{ form.recommendation.weakness ?? '-' }}</h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="font-500">Opportunity</div>
                          <b-form-textarea v-if="isEditMode"  required v-model="form.recommendation.opportunity" rows="3"></b-form-textarea>
                          <h6 v-else>{{ form.recommendation.opportunity ?? '-' }}</h6>
                        </td>
                        <td>
                          <div class="font-500">Threat</div>
                          <b-form-textarea v-if="isEditMode"  required v-model="form.recommendation.threat" rows="3"></b-form-textarea>
                          <h6 v-else>{{ form.recommendation.threat ?? '-' }}</h6>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- Recommendation End -->
    
          <b-col md="12" class="mb-3 text-right" v-if="isEditMode">
            <b-button  variant="primary" :disabled="form._saving" type="submit">{{form._saving ? 'Menyimpan...' : 'Simpan'}}</b-button>
          </b-col>
        </b-row>
      </form>
    </template>
    <div v-else class="d-flex justify-content-center py-5 my-5">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>
<script>
import { xray } from '@/src/config/pluginInit';
import { companiesActions, filesActions, fleetsActions, miActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'
import TabPaneRcaFinal from '../component/TabPaneRcaFinal.vue';
import moment from '@/node_modules/moment-timezone/index';
import InputCurrency from '@/src/components/global/InputCurrency.vue';
import LevelIndocator from '../component/LevelIndocator.vue';

export default {
  name: 'FinalReportRootCauseAnalysisForm',
  components:{
    TabPaneRcaFinal,
    InputCurrency,
    LevelIndocator
  },
  async mounted () {
    xray.index()
    try {     
      this.loading.rca = true 
      if (this.$route.query.formId) {
        this.fetchCompanies()
        await this.fetchMi()
        await this.fetchDetailRCAFinal()
        await this.fetchFormSurvey()
        await this.fetchSurvey()
      }
    } catch (error) {
      console.log(error);
    }finally{
      this.loading.rca = false
    }
  },
  computed: {
    userPermission(){
      if(['SUPER ADMIN', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'MARINE INSURANCE MANAGER'].includes(this.permission_user)){
        return true
      } else{
        return false
      } 
    },
    totalAll () {
      let ret = 0

      if (this.detailSurvey) {
        let header = this.detailSurvey.headers.find(header => header.type === 'user_input' && header.contents[0] && header.contents[0].input && header.contents[0].input.alias === 'max100')

        if (header) {
          let filteredContents = header.contents.filter(content => content.input.value)

          filteredContents.forEach(content => {
            ret += parseInt(content.input.value)
          })

          if (filteredContents.length > 0) {
            ret = ret / filteredContents.length
          }
        }
      }

      return Math.round(ret)
    },
    rootCauseAnalysis () {
      return this.detailMi && this.detailMi.rcas ? this.detailMi.rcas[0] : null
    },
    listRootCauseAnalysis () {
      return this.detailMi && this.detailMi.rcas ? this.detailMi.rcas : []
    },
    costOfDamagesSumCost () {
      let total = 0
      this.form.damage_costs.map(cod => total += (cod.cost ? parseInt(cod.cost) : 0))
      return total
    },
    costOfDamagesSumTotal () {
      let total = 0
      this.form.damage_costs.map(cod => total += (cod.total ? parseInt(cod.total) : 0))
      return total
    },
    costOfLossSumCost () {
      let total = 0
      this.form.loss_costs.map(cod => total += (cod.cost ? parseInt(cod.cost) : 0))
      return total
    },
    costOfLossSumTotal () {
      let total = 0
      this.form.loss_costs.map(cod => total += (cod.total ? parseInt(cod.total) : 0))
      return total
    }
  },
  data () {
    return {
      isEditMode:false,
      activeTab:1,
      permission_user:JSON.parse(localStorage.getItem('profile')).role.roles,
      detailMi: null,
      detailFillSurvey: null,
      detailSurvey: null,
      detailLossRatio: [],
      form: {
        _saving: false,
        'images': [],
        'investigation_teams': [],
        'direct_witnesses': [],
        'indirect_witnesses': [],
        'supporting_documents': [],
        'document_controls': [],
        'problem': '',
        'cause_of_loss': '',
        'impact': '',
        'report_number': '',
        'starting_date': '',
        'closing_date': '',
        'damage_costs': [],
        'loss_costs': [],
        'findings': [],
        'conclusions': [],
        'recommendation': {
          'strength': '',
          'weakness': '',
          'opportunity': '',
          'threat': ''
        }
      },
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      state: [
        {
          name: 'single',
          label: 'Male',
          value: 'male',
          disabled: false
        },
        {
          name: 'single',
          label: 'Female',
          value: 'female',
          disabled: false
        }
      ],
      marital: [
        { value: '1', text: 'Single' },
        { value: '2', text: 'Married' },
        { value: '3', text: 'Divorce' }
      ],
      options3: [
        { value: '1', text: 'Transcoal Pacific' },
        { value: '2', text: 'Energi Transporter' },
        { value: '3', text: 'Sentra Makmur Lines' }
      ],
      companies: [],
      loading:{
        rca:false
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    ...filesActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    formatCurrency (number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 2
      })
      return formatter.format(Number.isNaN(number) ? 0 : number)
    },
    async fetchCompanies () {
      let res = await this.getCompanies()
      this.companies = res.data.map(val => ({ value: val.id, text: val.company }))
    },
    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories,loss_ratio,rcas,rca_final,cost_breakdown'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      if (data) {
        this.detailMi = data
        if (data.witnesses) {
          if (data.witnesses.direct) {
            this.form.direct_witnesses = data.witnesses.direct.map(direct => {
              return {
                detail: direct
              }
            })
          }
          if (data.witnesses.indirect) {
            this.form.indirect_witnesses = data.witnesses.indirect.map(indirect => {
              return {
                detail: indirect
              }
            })
          }
        }
        if (data.cost_breakdown && data.cost_breakdown.data) {
          this.form.damage_costs = data.cost_breakdown.data.map(cost => {
            return {
              'description': cost.description,
              'cost': cost.value,
              'total': cost.value,
              'remarks': ''
            }
          })
          this.form.loss_costs = data.cost_breakdown.data.map(cost => {
            return {
              'description': cost.description,
              'cost': cost.value,
              'total': cost.value,
              'remarks': ''
            }
          })
        }

        if (data.type_coverage && data.type_coverage === 'Hull & Machinery') {
          await this.handleFetchLossRatioInput()
        } else {
          await this.handleFetchLossRatio()
        }
      }
    },
    async fetchDetailRCAFinal () {
      try {
        const { data, status } = await this.getRCAFinal(this.$route.query.formId)
  
        if ([500, 404, 403, 'error'].includes(status)) {
          this.form._saving = false
          return
        }
  
        if (data) {
          this.form.images = data.images
          this.form.starting_date = data.starting_date
          this.form.closing_date = data.closing_date
          this.form.investigation_teams = data.investigation_teams
          // TODO delete soon if not used anymore
          // this.form.direct_witnesses = []
          // this.form.indirect_witnesses = []
          // data.direct_witnesses.map(d => {
          //   this.form.direct_witnesses.push({
          //     detail: this.listRootCauseAnalysis.find(rca => rca.id === d)
          //   })
          // })
          // data.indirect_witnesses.map(d => {
          //   this.form.indirect_witnesses.push({
          //     detail: this.listRootCauseAnalysis.find(rca => rca.id === d)
          //   })
          // })
          this.form.supporting_documents = data.supporting_documents
          this.form.images = data.images
          this.form.document_controls = data.document_controls
          this.form.damage_costs = data.damage_costs.length > 0 ? data.damage_costs : this.form.damage_costs
          this.form.problem = data.problem
          this.form.cause_of_loss = data.cause_of_loss
          this.form.impact = data.impact
          this.form.loss_costs = data.loss_costs
          this.form.findings = data.findings
          this.form.conclusions = data.conclusions
          this.form.report_number = data.report_number
          this.form.recommendation = data.recommendation
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSurvey () {
      const { data, status } = await this.getClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.detailSurvey = data

      if (this.detailSurvey.headers && this.detailFillSurvey.headers) {
        this.detailSurvey.headers.map((header, headerIndex) => {
          if (this.detailFillSurvey.headers[headerIndex] && header.contents) {
            header.contents.map((content, contentIndex) => {
              if (this.detailFillSurvey.headers[headerIndex].contents[contentIndex] &&
                this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value &&
                content.input) {
                content.input.value = this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value.value_anything
              }
            })
          }
        })
      }
    },
    async fetchFormSurvey () {
      const { data, status } = await this.getFormClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.detailFillSurvey = data
    },
    async handleFetchLossRatio () {
      const { data, status } = await this.getLossRatio({
        data: {
          id: this.$route.query.formId,
          all: true
        }
      })

      if ([500, 404, 403, 'error'].includes(status)) {
        return false
      }

      if (data) {
        this.detailLossRatio = data
      }
    },
    async handleFetchLossRatioInput () {
      const { data, status } = await this.getLossRatioInput({
        data: {
          id: this.$route.query.formId,
          all: true

        }
      })

      if ([500, 404, 403].includes(status)) {
        return
      }

      this.form.sources = []

      if (data.individual) {
        this.detailLossRatio.push({
          'individual': data.individual
        })
      }

      if (data.accumulative) {
        this.detailLossRatio.push({
          'accumulative': data.accumulative
        })
      }
    },

    async onImagesFileChanged (event) {
      const files = [...event.target.files].map(file => {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })
      })
      const res = await Promise.all(files)
      if (res) {
        res.map(r => {
          this.form.images.push(r)
        })
      }
    },
    addInvestigationTeam () {
      this.form.investigation_teams.push({
        'team': '',
        'name': '',
        'position': '',
        'remarks': ''
      })
    },
    removeInvestigationTeam (index) {
      this.form.investigation_teams.splice(index, 1)
    },
    // TODO delete soon if not used anymore
    // addDirectWitnesses () {
    //   this.form.direct_witnesses.push({
    //     detail: undefined
    //   })
    // },
    // removeDirectWitnesses (index) {
    //   this.form.direct_witnesses.splice(index, 1)
    // },
    // addIndirectWitnesses () {
    //   this.form.indirect_witnesses.push({
    //     detail: undefined
    //   })
    // },
    // removeIndirectWitnesses (index) {
    //   this.form.indirect_witnesses.splice(index, 1)
    // },
    addDocumentControls () {
      this.form.document_controls.push({
        'document': '',
        'remarks': '',
        'status': ''
      })
    },
    removeDocumentControls (index) {
      this.form.document_controls.splice(index, 1)
    },
    addSupportingDocuments () {
      this.form.supporting_documents.push({
        'document': '',
        'file': '',
        'original': 'x',
        'copy': 'x',
        'pic': '',
        'remarks': ''
      })
    },
    removeSupportingDocuments (index) {
      this.form.supporting_documents.splice(index, 1)
    },
    async onSupportingDocumentsFileChanged (index, event) {
      const { data, status } = await this.handleFileRemoteUpload(event)

      if (status === 'success') {
        this.form.supporting_documents[index].file = data.url
        this.form.supporting_documents[index].document = data.url
        return true
      }

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.form.supporting_documents[index].file = reader.result
        this.form.supporting_documents[index].document = reader.result
      }
    },
    addCostOfDamages () {
      this.form.damage_costs.push({
        'description': '',
        'cost': '',
        'total': '',
        'remarks': ''
      })
    },
    removeCostOfDamages (index) {
      this.form.damage_costs.splice(index, 1)
    },
    addCostLoss () {
      this.form.loss_costs.push({
        'description': '',
        'cost': '',
        'total': '',
        'remarks': ''
      })
    },
    removeCostLoss (index) {
      this.form.loss_costs.splice(index, 1)
    },
    findingsByPrefix (prefixNumber) {
      return this.form.findings.filter(finding => finding.value.includes(`D.5.${prefixNumber}-`))
    },
    addFindings (prefixNumber) {
      let filterFinding = this.findingsByPrefix(prefixNumber)
      let incrementNumber = filterFinding.length + 1

      this.form.findings.push({
        'value': `D.5.${prefixNumber}-${incrementNumber}`,
        'text': '',
        'proximity': 1
      })
    },
    removeFindings (value) {
      let index = this.form.findings.findIndex(finding => finding.value === value)
      this.form.findings.splice(index, 1)
    },
    addConclusion () {
      this.form.conclusions.push({
        'damage': '',
        'rows': [
          {
            'human': '-',
            'equipment': '-',
            'csmp': '-',
            'other': '-',
            'status': '-'
          }
        ]
      })
    },
    removeConclusion (index) {
      this.form.conclusions.splice(index, 1)
    },
    addConclusionRow (index) {
      if (this.form.conclusions[index]) {
        this.form.conclusions[index].rows.push({
          'human': '-',
          'equipment': '-',
          'csmp': '-',
          'other': '-',
          'status': '-'
        })
      }
    },
    removeConclusionRow (index, conclusionRow) {
      if (this.form.conclusions[index]) {
        this.form.conclusions[index].rows.splice(conclusionRow, 1)
      }
    },
    async onSave () {
      let payload = { ...this.form }

      // TODO delete soon if not used anymore
      // payload.direct_witnesses = payload.direct_witnesses.map(data => data.detail ? data.detail.id : null)
      // payload.indirect_witnesses = payload.indirect_witnesses.map(data => data.detail ? data.detail.id : null)
      delete payload.direct_witnesses
      delete payload.indirect_witnesses

      this.form._saving = true

      const { data, status } = await this.createRCAFinal({
        form_id: this.$route.query.formId,
        ...payload
      })

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.form._saving = false
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form._saving = false
    },
    async handleFileRemoteUpload (event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      const { data, status } = await this.upload(formData)

      if (status === 'error') {
        return {
          status
        }
      }

      return {
        data, status
      }
    }
  }
}
</script>
<style>

.font-500{
  font-weight: 500;
}

</style>
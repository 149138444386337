var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Filter Data")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "period"
          }
        }, [_c('date-picker', {
          staticClass: "w-100",
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company",
            "label-for": "compnay"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": [],
            "placeholder": "Select..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Group Data",
            "label-for": "group"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": [],
            "placeholder": "Select..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('button', {
          staticClass: "btn",
          style: {
            backgroundColor: '#2BDCAA',
            color: 'white',
            marginTop: '30px'
          },
          attrs: {
            "type": "button",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Cargo Analysis")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('CargoAnalysis')], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Sales Analysis")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('SalesAnalysis')], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
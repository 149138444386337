<template>
  <div class="">
    <div class="d-flex justify-content-end py-3">
      <b-dropdown text="Filter Column" variant="primary" class="mr-2">
        <div class="p-3">
          <template v-for="(col, i) in columns">
            <div v-if="i > 2" :key="i">
              <b-form-checkbox
                v-if="col?.label != 'STATUS'"
                v-model="col.value"
                class="text-truncate"
                :id="`${i}`"
                >{{ col.label }}</b-form-checkbox
              >
            </div>
          </template>
        </div>
      </b-dropdown>
      <template>
        <slot name="table-action"/>
      </template>
    </div>
    <div class="d-flex overflow-auto relative">
      <table class="table">
        <thead>
          <tr>
            <template v-for="(col, index) in columns">
              <th
                v-if="col.value"
                class="text-left text-truncate text-white"
                :class="{
                  'column-no sticky-column': index == 0,
                  'column-name sticky-column': index == 1,
                  'column-action text-center': index == 2,
                }"
                style="background: #334697"
                :key="index"
                >{{ col.label }}</th
              >
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(data, index) in tableData.body"
            :key="index"
            :style="`background:${
              index % 2 != 0 ? 'white' : '#f5f5f5'
            };left:3rem`"
          >
            <td
              v-if="columnValue('No')"
              class="text-center column-no sticky-column"
              :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
              >{{ indexes + index }}</td
            >
            <!-- <td v-if="!isPreview">
                <router-link
                  :to="{
                    path: `/crewing/profile`,
                    query: {
                      user: 'crew',
                      id: data.id,
                      preview: isPreview,
                    },
                  }" class="text-truncate">{{ data.fullname }}</router-link><br />
                  <small style="color: gray" class="text-truncate">NIP : <b>{{ data?.nip ?? '-' }}</b><br />Seaferer Code :{{ data?.personal_data?.seaferer_code ?? '-' }}</small><br />
                  <small class="text-danger text-truncate" v-if="data?.is_delete">Waiting approval to delete this crew</small>
              </td>
              <td v-else>
                <router-link
                  target="_blank"
                  :to="{
                    path: `/crewing/profile`,
                    query: {
                      user: 'crew',
                      id: data.id,
                      preview: isPreview,
                    },
                  }" class="text-truncate">{{ data.fullname }}</router-link><br />
                  <small style="color: gray" class="text-truncate">NIP : <b>{{ data?.nip ?? '-' }}</b><br />Seaferer Code :{{ data?.personal_data?.seaferer_code ?? '-' }}</small><br />
                  <small class="text-danger text-truncate" v-if="data?.is_delete">Waiting approval to delete this crew</small>
              </td> -->
            <td
              class="text-truncate sticky-column"
              :style="`background:${
                index % 2 != 0 ? 'white' : '#f5f5f5'
              };left:2.7rem`"
            >
              <router-link
                :to="{
                  path: `/crewing/profile`,
                  query: {
                    user: 'crew',
                    id: data.id,
                    preview: isPreview,
                  },
                }" class="text-truncate">
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="34"
                    variant="warning"
                    :src="data.personal_data?.photo_url"
                  ></b-avatar>
                  <h6 class="ml-2 text-primary"
                    ><strong>{{ data?.fullname }} - {{ data?.nip }}</strong></h6
                  >
                </div>
              </router-link>
            </td>
            <td 
              class="column-action eft-26"
              :style="`background:${
                index % 2 != 0 ? 'white' : '#f5f5f5'
              }`"
              v-if="!isPreview && columnValue('ACTIONS')">
              <b-dropdown
                size="sm"
                variant="dark"
                toggle-class="text-decoration-none"
                text="Actions"
                dropright
                no-caret
              >
                <template v-slot:button-content>
                  Actions
                  <i class="fa fa-chevron-down ml-1"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  v-b-toggle.swap-position
                  variant="primary"
                  @click="() => setDataUser(data)"
                  ><i class="fas fa-arrow-circle-down mr-2"></i>Swap
                  Position</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  v-b-modal.notice_sign_off
                  variant="primary"
                  @click="() => setDataUser(data)"
                >
                  <i class="fas fa-arrow-circle-down mr-2"></i>Notice Sign Off
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  v-b-toggle.sign-off
                  variant="primary"
                  @click="() => setDataUser(data)"
                  ><i class="fas fa-arrow-circle-down mr-2"></i>Sign
                  Off</b-dropdown-item
                >
                <b-dropdown-item v-if="!data.status_condite_time">
                  <router-link
                    :to="{
                      path:
                        data?.position_id === 1 || data?.position_id === 2
                          ? `/crewing/condite/${data.id}`
                          : `/crewing/conduct/${data.id}`,
                      params: { id: data.id },
                    }"
                  >
                    <i class="fas fa-arrow-circle-down mr-2"></i>Penilaian
                    Conduite</router-link
                  >
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  variant="primary"
                  @click="() => handleDownloadPKL(data.id, data.fullname)"
                  ><i class="fas fa-file mr-2"></i>Download Surat
                  PKL</b-dropdown-item
                >
                <b-dropdown-divider />
                <b-dropdown-item
                  href="#"
                  v-b-toggle.blacklist-crew
                  @click="() => setDataUser(data)"
                  variant="danger"
                  ><i class="fa fa-ban mr-2"></i>Black List</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  v-b-modal.delete-crew
                  @click="() => setDataUser(data)"
                  variant="danger"
                  ><i class="fa fa-user mr-2"></i>Delete Crew</b-dropdown-item
                >
              </b-dropdown>
            </td>
            <td v-if="columnValue('SEAFERER CODE')" class="text-truncate text-left">{{
              data?.personal_data?.seaferer_code ?? '-'
            }}</td>
            <td
              v-if="columnValue('FLEET NAME')"
              class="text-left text-truncate"
              style="text-align: left !important"
              >{{ data?.vehicle?.name ?? '-' }}</td
            >
            <td v-if="columnValue('COMPANY')" class="text-left text-truncate">{{
              data?.company?.company ?? '-'
            }}</td>
            <!-- <small style="color: gray" class="text-truncate">{{ data?.company?.company ?? '-' }}</small> -->
            <td v-if="columnValue('POSITION')" 
            class="text-left text-truncate"
              >{{ data?.position?.name ?? '-' }}
            </td>
            <td v-if="columnValue('SIGN ON')"  class="text-left text-truncate">{{
              data?.sign_on
                ? moment(data?.sign_on).format('DD MMM YYYY HH:mm')
                : '-'
            }}</td>
            <td v-if="columnValue('JOIN ON BOARD')" class="text-left text-truncate"
              >{{
                data?.join_on_board
                  ? moment(data?.join_on_board).format('DD MMM YYYY HH:mm')
                  : '-'
              }}
            </td>
            <td v-if="columnValue('LENGTH OF JOINT')" class="text-center text-truncate">
              <b-badge
                class="text-truncate"
                :variant="
                  joinOnBoardStatus(data) === 'More than 1 year'
                    ? 'success d-block'
                    : joinOnBoardStatus(data) === 'More than 6 month'
                    ? 'primary d-block'
                    : joinOnBoardStatus(data) === 'More than 3 month'
                    ? 'warning d-block'
                    : 'dark d-block'
                "
                v-if="joinOnBoardStatus(data)"
                >{{ joinOnBoardStatus(data) }}
              </b-badge>
            </td>
            <td v-if="columnValue('SIGN OFF')" class="text-left text-truncate">
              {{
                data?.sign_off
                  ? moment(data?.sign_off).format('DD MMM YYYY HH:mm')
                  : '-'
              }}
            </td>
            <td v-if="columnValue('STATUS')" class="text-left" style="padding-right:120px ;">
              <b-badge
                class="mb-0 text-truncate"
                :variant="`${
                  data?.status_emp_crewing?.alias === 'active'
                    ? 'success'
                    : 'warning'
                } d-block`"
                >{{ data?.status_emp_crewing?.name ?? '-' }}
              </b-badge>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  data() {
    return {
      key: 'crew',
      columns: [
        { label: 'No', value: true },
        { label: 'CREW NAME', value: true },
        { label: 'ACTIONS', value: true },
        { label: 'SEAFERER CODE', value: true },
        { label: 'FLEET NAME', value: true },
        { label: 'COMPANY', value: true },
        { label: 'POSITION', value: true },
        { label: 'SIGN ON', value: true },
        { label: 'JOIN ON BOARD', value: true },
        { label: 'LENGTH OF JOINT', value: true },
        { label: 'SIGN OFF', value: true },
        { label: 'STATUS', value: true },
      ],
    }
  },
  props: {
    indexes: {
      type: Number,
      default: 1,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: false,
    },
    tableData: {
      type: Object,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return {
          id: null,
          key: '',
        }
      },
    },
  },
  methods: {
    ...crewingActions,
    setDataUser(state) {
      this.form.id = state.id
      this.form.key = 'crew'
    },
    joinOnBoardStatus(data) {
      if (!data.join_on_board) return ''

      if (data.working_period > 2) {
        data.status_condite_time = true
      }

      if (data.working_period > 12) {
        return 'More than 1 year'
      } else if (data.working_period > 6) {
        return 'More than 6 month'
      } else if (data.working_period > 3) {
        return 'More than 3 month'
      } else {
        return 'Less than 3 month'
      }
    },

    expiredContract(data) {
      return moment(data.sign_off).format('DD-MM-YYYY') <
        moment(new Date()).format('DD-MM-YYYY') &&
        data.status_emp_crewing?.name === 'Active'
        ? 'Out of Contract'
        : ''
    },
    columnValue(label) {
      const value = this.columns.find((el) => el.label == label)?.value
      return value
    },
    async handleDownloadPKL(id, name) {
      const res = await this.fetchDownloadPKL({ id })
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `File PKL - ${name}.pdf`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
  },
}
</script>
<style scoped>
.sticky-column {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}
.column-no{
  left: 0;
  width: 44px;
}
.column-name{
  left: 43px;
  width: 200px;
}
.column-action{
  position: absolute;
  right: 16px;
  width: 120px;
}
.text-left-important {
  text-align: left !important;
}
table th {
  background: #334697;
}
</style>

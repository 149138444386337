
<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h5 class="card-title text-primary"><strong>Budget vs Actual Sales</strong></h5>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <b-form-group
                    label="Search"
                    label-for="search"
                    class="mr-2"
                  >
                    <b-form-input placeholder="AI Project - PLTU" /> 
                  </b-form-group>
                  <button type="button" class="btn" :style="{ backgroundColor: '#2BDCAA', color: 'white', marginTop:'20px' }" block><i class="fa fa-search" /></button>
                </div>
                <b-button variant="primary">
                  <router-link
                    :to="{
                      path: `budget/create`,
                      query: {
                        type: 'sales',
                      },
                    }"
                    class="text-white"
                  >
                    Create Budget Sales
                  </router-link>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="overflow-auto" style="max-height:600px">
                <table class="table table-sm table-borderless table-hover">
                  <thead>
                    <tr>
                      <th rowspan="2" class="align-middle" style="min-width:250px; background-color: #EDEDED">Project</th>
                      <th colspan="2" class="text-center"  style="background-color: #D5D5D5">Total</th>
                      <template v-for="(val, index) in ['January', 'February', 'Maret', 'April', 'Mei', 'June', 'July', 'August', 'September', 'October', 'November', 'Desember']">
                        <th :key="index" colspan="2" class="text-center" style="background-color: #D5D5D5">{{ val }}</th>
                      </template>
                    </tr>
                    <tr>
                      <template v-for="index in 13">
                        <th class="text-center" :key="index" style="min-width:100px; background-color: #EDEDED">Budget</th>
                        <th class="text-center" style="min-width:100px; background-color: #EDEDED">Actual</th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template>
                      <tr @click="toggleRow(`row-1`)" style="cursor:pointer">
                        <td style="padding-left:1rem"  class="text-truncate">
                          <div class="d-flex align-items-center">
                            <ChevronArrow color="black" />
                            <span class="ml-2">AI PROJECT - PLTU (BG)</span>
                          </div>
                        </td>
                        <template v-for="index in 26">
                          <td class="text-truncate text-center" :key="index">5.000,00</td>
                        </template>
                      </tr>
                      <template v-if="isRowVisible(`row-1`)" :class="{ 'collapsed-row': !isRowVisible(`row-1`), 'expanded-row': isRowVisible(`row-1`) }">
                        <tr @click="toggleRow(`row-child-1`)" style="cursor:pointer; background-color: #dce3ff">
                          <td style="padding-left:2rem"  class="text-truncate">
                            <div class="d-flex align-items-center">
                              <ChevronArrow color="black" />
                              <span class="ml-2">Labuan</span>
                            </div>
                          </td>
                          <td v-for="index in 26" class="text-truncate text-center" :key="index">5.000,00</td>
                        </tr>
                      </template>
                      <template v-if="isRowVisible(`row-child-1`)" :class="{ 'collapsed-row': !isRowVisible(`row-child-1`), 'expanded-row': isRowVisible(`row-child-1`) }">
                        <tr>
                          <td style="padding-left:3rem" class="text-truncate"><span>300 Feet Owned</span></td>
                          <td v-for="index in 26" class="text-truncate text-center" :key="index">5.000,00</td>
                        </tr>
                        <tr>
                          <td style="padding-left:3rem" class="pl-6 text-truncate"><span>300 Feet Third Party</span></td>
                          <td v-for="index in 26" class="text-truncate text-center" :key="index">5.000,00</td>
                        </tr>
                        <tr>
                          <td style="padding-left:3rem" class="pl-6 text-truncate"><span>330 Feet Owned</span></td>
                          <td v-for="index in 26" class="text-truncate text-center" :key="index">5.000,00</td>
                        </tr>
                        <tr>
                          <td style="padding-left:3rem" class="pl-6 text-truncate"><span>330 Feet Third Party</span></td>
                          <td v-for="index in 26" class="text-truncate text-center" :key="index">5.000,00</td>
                        </tr>
                      </template>
                      <tr>
                        <th class="text-center text-white" style="background-color: #07c607">TOTAL PROJECT</th>
                        <th v-for="index in 26" class="text-truncate text-center text-white" style="background-color: #07c607" :key="index">5.000,00</th>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import ChevronArrow from './components/icon/ChevronArrow.vue'
export default {
 name:'BudgetActualSales',
 components:{ ChevronArrow },
 data() {
   return {
     visibleRows: {},
     lists: []
   }
 },
 mounted() {
   
 },
 methods: {
  toggleRow(id) {
    // const isCurrentlyVisible = this.visibleRows[id];
    this.$set(this.visibleRows, id, !this.visibleRows[id]);

    // if (id === 'row-1' && !isCurrentlyVisible) {
    //   this.visibleRows['row-child-1'] = false;
    // }
  },
  isRowVisible(id) {
    return !!this.visibleRows[id];
  },
 },
}
</script>

<style scoped>
/* Collapsible Row Styles */
.collapsed-row .collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;
}

.expanded-row .collapsible-content {
  max-height: 100px; /* Set to a height that fits the content */
  transition: max-height 0.3s ease-in;
  opacity: 1;
}

.collapsible-content {
  transition: opacity 0.3s ease, max-height 0.3s ease;
}
</style>
<template>
  <b-container fluid>
    <form  @submit.prevent="onSave" action="#">
      <b-row v-if="edit_loading">
        <b-col md="12">
          <div class="text-center my-5">
            <b-spinner type="grow" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="12">
          <b-form-group
            label="Vehicle"
            label-for="vehicle_id"
          >
            <v-multiselect 
              v-model="form.vehicle_id" 
              :options="listVehicle" 
              placeholder="Select vehicle" 
              track-by="name" 
              label="name"
              @input="onSelectedVehicle"
            >
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </v-multiselect>
          </b-form-group>
        </b-col>
        <b-col md="12" v-if="loading_fleet_form">
          <div class="d-flex align-items-center">
            <i class="spinner-border spinner-border-sm text-primary mr-2"></i>
            <span class="text-primary">Fetching forms, please wait a moment.</span>
          </div>
        </b-col>

        <b-col md="12" v-if="form.vehicle_id && !loading_fleet_form">
          <b-form-group
            label="Collection Forms"
            label-for="collection_forms"
          >
            <v-multiselect 
              v-model="form.collection_of_forms_id" 
              :options="listCollectionForm" 
              placeholder="Select forms" 
              track-by="name" 
              label="name"
              @input="onSelectedForm"
              :disabled="loading_fleet_form"
            >
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </v-multiselect>
          </b-form-group>
        </b-col>

        <b-col md="12" v-if="form.vehicle_id && form.collection_of_forms_id">
          <b-form-group label="Attachments" label-for="attactment_file">
            <b-form-file plain multiple v-model="files" name='files' id="attactment_file" accept="image/*,application/*" @change="onUploadFiles"></b-form-file>
            <small class="text-muted">
              File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
              File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
            </small>
          </b-form-group>
        </b-col>

        <b-col md="12" v-if="detailForm">
          <b-row class="mt-2">
            <div class="border my-2 mx-3 w-100"></div>
            <b-col md="12" >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Title">
                    <b-form-input v-model="form.title" type="text" placeholder="Type Text" required  />
                  </b-form-group>
                </b-col>
                <b-col md="6" v-for="(state, index) in detailForm?.field" :key="index">
                  <b-form-group :label="state?.label">
                    <b-form-input v-model="state.value" type="number" placeholder="Type Number" v-if="state.type === 'Numeric Type'" :required="state?.required" />
                    <b-form-input v-model="state.value" placeholder="Type Text" v-if="state.type === 'Text Type'" :required="state?.required" />
                    <b-form-textarea v-model="state.value" placeholder="Type Text" v-if="state.type === 'Textarea'" :required="state?.required" />
                    <b-form-input v-model="state.value"  type="date" v-if="state.type === 'Date'" :required="state?.required" />
                    <b-form-input v-model="state.value" type="datetime-local" v-if="state.type === 'Datetime'" :required="state?.required" />
                    <b-form-checkbox v-model="state.value" name="check-button" switch v-if="state.type === 'Boolean Type'">Yes / No </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <div class="border my-2 mx-3 w-100"></div>
            
            <b-col md="12">
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary">Question Type Content</legend>
                <div v-if="detailForm.collection_of_forms_category.length > 0">
                  <table class="table table-striped" v-for="(parentQuestion, parentQuestionIndex) in detailForm.collection_of_forms_category" :key="parentQuestionIndex">
                    <thead>
                      <tr v-if="!parentQuestion.parent_id && parentQuestion?.inspection_checklist_question?.length <= 0">
                        <th colspan="6" class="align-middle text-center">{{ parentQuestion?.name }}</th>
                      </tr>
                      <tr v-else>
                        <th style="width:5%" class="align-middle text-center">No</th>
                        <th style="width:45%" class="align-middle text-center">{{parentQuestion?.name }}</th>
                        <template v-if="Array.isArray(detailForm?.question_type_content)">
                          <th style="min-width:20%" class="text-truncate" v-for="(questionType, questionTypeIndex) in detailForm?.question_type_content" :key="questionTypeIndex">
                            {{ questionType?.label }}
                          </th>
                        </template>
                        <template v-else>
                          <th style="width:50%" v-if="['date', 'single-lines-text-box', 'datetime', 'numerical-value'].includes(detailForm.question_type.code)">{{ detailForm?.question_type_content?.label }}</th>
                        </template>
                        <th style="width:20%" v-if="detailForm.using_remarks">Remarks</th>
                      </tr>
                    </thead>
                    <tbody v-if="parentQuestion?.inspection_checklist_question?.length > 0">
                      <tr v-for="(question, questionIndex) in parentQuestion?.inspection_checklist_question" :key="questionIndex">
                        <td style="max-width:20px">{{ questionIndex + 1 }}</td>
                        <td>{{ question.question }}</td>
                        <template v-if="detailForm.question_type.code == 'multiple-choice-multiple-answers-allowed'">
                          <td v-for="(questionType, questionTypeIndex) in detailForm?.question_type_content" :key="questionTypeIndex">
                            <b-form-checkbox v-model="question.answer[questionTypeIndex].value" :value="questionType?.label" :unchecked-value="''" />
                          </td>
                        </template> 
                        <template v-if="detailForm.question_type.code == 'multiple-choice-only-one-answer'">
                          <td v-for="(questionType, questionTypeIndex) in detailForm.collection_of_forms_category[parentQuestionIndex].inspection_checklist_question[questionIndex].answer" :key="questionTypeIndex">
                            <template>
                              <b-form-checkbox  
                                name="check-button" 
                                switch
                                inline
                                v-model="questionType.value" 
                                @change="onChangeAnswer(parentQuestionIndex, questionIndex, questionTypeIndex)" 
                              />
                            </template>
                          </td>
                        </template>
                        <template v-if="['single-lines-text-box'].includes(detailForm.question_type.code)">
                          <td>
                            <b-form-input v-model="question.answer" type="text" />
                          </td>
                        </template>
                        <template v-if="['multiple-lines-text-box'].includes(detailForm.question_type.code)">
                          <td v-for="(questionType, questionTypeIndex) in detailForm?.question_type_content" :key="questionTypeIndex">
                            <b-form-input v-model="question.answer[questionTypeIndex].value" placeholder="Type Text" />
                          </td>
                        </template>
                        <template v-if="['numerical-value'].includes(detailForm.question_type.code)">
                          <td>
                            <b-form-input  v-model="question.answer" type="number" />
                          </td>
                        </template>
                        <template v-if="['date'].includes(detailForm.question_type.code)">
                          <td>
                            <b-form-input v-model="question.answer" type="date" />
                          </td>
                        </template>
                        <template v-if="['datetime'].includes(detailForm.question_type.code)">
                          <td>
                            <b-form-input v-model="question.answer" type="datetime-local" />
                          </td>
                        </template>
                        <td v-if="detailForm.using_remarks">
                          <b-form-textarea v-model="question.remarks" placeholder="Type Text" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </fieldset>
            </b-col>

            <div class="border my-2 mx-3 w-100"></div>

            <b-col md="12" class="mt-2">
              <b-row>
                <b-col md="3" v-for="(state, index) in detailForm?.signature" :key="index">
                  <b-form-group :label="state?.label">
                    <b-form-input v-model="state.value" type="number" placeholder="Type Number" v-if="state.type === 'Numeric Type'" :required="state?.required" />
                    <b-form-input v-model="state.value" placeholder="Type Text" v-if="state.type === 'Text Type'" :required="state?.required" />
                    <b-form-textarea v-model="state.value" placeholder="Type Text" v-if="state.type === 'Textarea'" :required="state?.required" />
                    <b-form-input v-model="state.value" type="date" v-if="state.type === 'Date'" :required="state?.required" />
                    <b-form-input v-model="state.value" type="datelocal-time" v-if="state.type === 'Datetime'" :required="state?.required" />
                    <b-form-checkbox v-model="state.value" name="check-button" switch v-if="state.type === 'Boolean Type'" >Yes / No </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="12" v-if="form.vehicle_id && form.collection_of_forms_id">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
              block
              variant="danger"
              type="button"
              style="width:15%"
              class="mr-4 mt-2"
            >
              <i class="fa fa-remove"></i>
              <span>Cancel</span>
            </b-button>

            <b-button
              :disabled="saving"
              block
              variant="primary"
              type="submit"
              style="width:20%"
            >
              <span
                v-if="saving"
                class="spinner-border spinner-border-sm"
              />
              <div v-else>
                <i class="fa fa-save"></i>
                <span>Submit Data</span>
              </div>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
import { busdevActions, fleetsActions } from '@src/Utils/helper'
import _ from 'lodash'
import SlideDetailForm from '@src/views/Setting/SlideDetailForm.vue'

export default {
  name: 'FormInspectionChecklist',
  props:{
    resetFormToInitialValue: {
      type: Function,
      default: true
    },
  },
  components:{
    SlideDetailForm
  },
  data () {
    return {
      isOnEdit:false,
    
      files: [],
      listCollectionForm: [],
      listVehicle: [],
      listTypeData: [
        {text:'Numeric Type', value:'Numeric Type'},
        {text:'Text Type', value:'Text Type'},
        {text:'Textarea', value:'Textarea'},
        {text:'Date', value:'Date'},
        {text:'Datetime', value:'Datetime'},
        {text:'Boolean Type', value:'Boolean Type'},
        {text:'Select Option', value:'Select Option'},
      ],

      loading_fleet_form: false,
      edit_loading: false,
      loading:false,
      saving:false,
      delete:false,

      detailForm:null,

      form: {
        vehicle_id: null,
        collection_of_forms_id: null,
        title:''
      },
    }
  },
  watch:{
    'editId': {
      handler(value) {
        if(value){
          // this.getCollectionFormsById()
        } else {
          this.resetForm()
        }
      },
      immediate: true,
    },
  },
  async mounted () {
    await this.fetchFleets()
  },
  methods: {
    ...busdevActions,
    ...fleetsActions,
    answerData(data, value){
      if(data === value){
        return '✅' 
      }else {
        return '☑️'
      }
    }, 
    onUploadFiles(e){
      const input = e.target
      console.log(input.files, "filesfiles")
      // console.log(this.files, "FILES")

      // let formData = new FormData()
      // if(input?.files?.length > 0){
      //   input?.files.forEach((file, fileIndex) => {
      //     formData.append(`file[${fileIndex}]`, file);
      //   });
      // }
    }, 
    handleOneAnswer(data, value){
      data = value
    },
    onChangeAnswer(firstIndex, secondIndex, thirdIndex){
      this.detailForm.collection_of_forms_category[firstIndex].inspection_checklist_question[secondIndex].answer.forEach((val, valIndex) => {
        if(valIndex !== thirdIndex){
          val.value = false
        }
      })
    },
    async onSelectedVehicle(){
      if(this.form.vehicle_id.vehicleType.id) await this.getVehicleTypeForm()
    }, 
    onSelectedForm(){
      this.detailForm = this.form.collection_of_forms_id

      this.detailForm.question_type_content = JSON.parse(this.detailForm?.question_type_content)
      this.detailForm.signature = JSON.parse(this.detailForm?.signature).map(val => {return {...val, value:''}})
      this.detailForm.field = JSON.parse(this.detailForm?.field).map(val => {return {...val, value:''}})
      this.detailForm.collection_of_forms_category = this.modifyQuestionsValue(this.detailForm?.collection_of_forms_category, this.detailForm?.question_type?.code, this.detailForm?.question_type_content)

      this.form.title = `${this.form?.vehicle_id?.vehicleType?.name} - Inspection Report`
    },
    modifyQuestionsValue(oldVal, questionType, content){
      let newVal = oldVal
      if(questionType === 'multiple-lines-text-box'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.remarks = ''
              obj.answer = content.map(() => {
                  return {
                    value: ''
                  }
                })
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'single-lines-text-box'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'numerical-value'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          
          return val
        })
      } else if(questionType === 'date'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'datetime'){ 
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'multiple-choice-only-one-answer'){
        newVal = newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = content.map((s,i) => {
                  return {
                    index: i,
                    value: ''
                  }
                })
              obj.remarks = ''
              return obj
            })
          }
          
          return val
        })
      } else if(questionType === 'multiple-choice-multiple-answers-allowed'){ 
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.remarks = ''
              obj.answer = content.map((s,i) => {
                  return {
                    index: i,
                    value: ''
                  }
                })
              return obj
            })
          }
          return val
        })
      }

      return newVal
    },
    async fetchFleets() {
      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
      })
      
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.listVehicle = res.data
        }
      }
    },

    async getVehicleTypeForm() {
      this.loading_fleet_form = true
      this.listCollectionForm = []

      let params = {
        page:1,
        perPage:100000,
        vehicle_type_id: Number(this.form.vehicle_id.vehicleType.id)
      }

      let res = await this.fetchVehicleTypeForm(params)
      console.log(res)

      if (res?.data?.status === true && res?.data?.data?.data?.length > 0) {
        this.listCollectionForm = res.data.data?.data?.[0]?.collection_forms
        this.loading_fleet_form = false
      }

      this.loading_fleet_form = false
    },

    resetForm () {
      this.form = {
        vehicle_id: null,
        collection_of_forms_id: null,
        title:''
      }
      this.detailForm = null
    },

    async onSave () {
      this.saving = true
      let tempPayload = {
        ...this.form,
        ...this.detailForm
      }

      let payload = {
        vehicle_id: tempPayload.vehicle_id.id,
        collection_of_forms_id: tempPayload.collection_of_forms_id.id,
        field: tempPayload.collection_of_forms_id.field.map(val => {
          return {
            label: val.label,
            type: val.type,
            required: val.required,
            value: val.value,
          }
        }),
        signature: tempPayload.collection_of_forms_id.signature.map(val => {
          return {
            label: val.label,
            type: val.type,
            required: val.required,
            value: val.value,
          }
        }),
        question_content: JSON.stringify(tempPayload.collection_of_forms_category),
        title: this.form.title
      }

      payload.field = JSON.stringify(payload.field)
      payload.signature = JSON.stringify(payload.signature)

      let res = await this.createInspectionChecklist({data: payload})

      if (res?.data?.status === true) {
  
        let formData = new FormData()
        if(this.files?.length > 0){
          this.files.forEach((file, fileIndex) => {
            formData.append(`file[${fileIndex}]`, file);
          });
          
          await this.createOrUpdateInspectionChecklistFiles({inspection_checklist_id: res?.data?.data?.id, data: formData})
        }

        this.$swal(`Add Inspection Checklist Success!`, res?.data?.message, 'success')
        this.resetForm()
        this.saving = false

        this.resetFormToInitialValue()
      } else {
        this.$swal(
          `Oops!`,
          res?.data,
          'error'
        )
        this.saving = false
      }
      this.saving = false
    },
  }
}
</script>

<style scoped>
</style>

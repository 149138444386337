import axios from './auth'

const BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getVehicleDetails({ commit }, params = {}) {
    const url = `${BASE_URL}/vehicle-status-details/vehicle/${params.vehicleId}`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async createVehicleStatusDetails({ commit }, payload = {}) {
    const url = `${BASE_URL}/vehicle-status-details`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  }, 
  async updateVehicleStatusDetails({ commit }, payload = {}) {
    const url = `${BASE_URL}/vehicle-status-details/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  },

  async upsertGeneralSettings({ commit }, payload = {}) {
    const url = `${BASE_URL}/general-settings`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  }, 
  
  async setEmailSetting({ commit }, payload = {}) {
    const url = `${BASE_URL}/email-notification-settings/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  },
  async getGeneralSettings({ commit }, params = {}) {
    const url = `${BASE_URL}/general-settings`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getEmailNotificationSettings({ commit }, params = {}) {
    const url = `${BASE_URL}/email-notification-settings`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getDynamicColumns({ commit }, params = {}) {
    const url = `${BASE_URL}/dynamic-columns`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },  
  async getColumnBySelectedModel({ commit }, params = {}) {
    const url = `${BASE_URL}/email-notification-settings/columns`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async getListPositions({ commit }, params = {}) {
    const url = `${BASE_URL}/positions`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  }, 
  async createPositions({ commit }, payload = {}) {
    const url = `${BASE_URL}/positions`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updatePositions({ commit }, payload = {}) {
    const url = `${BASE_URL}/positions/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deletePositions({ commit }, payload = {}) {
    const url = `${BASE_URL}/positions/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
}
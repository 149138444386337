<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <div class="overflow-auto d-flex">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th class="text-left align-middle text-truncate">Fleet</th>
                <th class="text-left align-middle text-truncate">Field</th>
                <th class="text-left align-middle text-truncate">Signature</th>
                <th class="text-left align-middle text-truncate">Status</th>
                <th class="text-center align-middle" style="width: 20px">#</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="formData?.inspection_checklist.length > 0">
                <tr v-for="(state, index) in formData.inspection_checklist" :key="index">
                  <td>
                    <div class="d-flex flex-column">
                      <strong>{{ state?.vehicle?.name ?? '-' }}</strong>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                        <div class="d-flex mb-1" v-for="(field, fieldIndex) in state?.field" :key="fieldIndex">
                          <strong class="mr-2">{{ field?.label }}: </strong>
                          <span>{{ field?.value ? handleTypeDate(field?.value) : '-' }}</span>
                        </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                        <div class="d-flex mb-1" v-for="(signature, signatureIndex) in state?.signature" :key="signatureIndex">
                          <strong class="mr-2">{{ signature?.label }}: </strong>
                          <span>{{ signature?.value ? handleTypeDate(signature?.value) : '-' }}</span>
                        </div>
                    </div>
                  </td>
                  <td>
                    <strong class="text-primary text-capitalize">{{ state?.status }}</strong>
                  </td>
                  <td>
                    <b-dropdown v-b-tooltip.top="'Action'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                      <template v-slot:button-content>
                          <span class="dropdown-toggle p-0" id="dropdownMenuButton5" formData-toggle="dropdown">
                          <i class="fa fa-file m-0"></i>
                          </span>
                      </template>
                      <b-dropdown-item 
                        href="#" 
                        variant="primary" 
                        @click="openInspectionChecklistSlide(false, true, state, formData?.id. formData?.vehicle_id, formData.vehicle?.vehicle_type)">
                        <i class="fa fa-eye mr-2"></i>Preview</b-dropdown-item> 
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item 
                        href="#" 
                        variant="primary" 
                        @click="onExport(state?.id, 'pdf')">
                        <i class="fa fa-file mr-2"></i>Export PDF</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colSpan="6" class="text-center">
                    There's no inspection yet.
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { fleetsActions, crewingActions, busdevActions } from '@src/Utils/helper'
  import { saveAs } from 'file-saver'

  export default {
    name:'TabFormList',
    props:{
      formData:{
        type:Object,
        default:null
      },
      openInspectionChecklistSlide:{
        type:Function,
        default: () => {}
      },
    },
    components:{
    },
    data(){
      return{
        loading: false,
      }
    },
    watch:{

    },
    mounted(){
      console.log(this.formData, "this.formData")
    },
    methods:{
      ...busdevActions,
      handleTypeDate(value){
        if(this.validDate(value)){
          return moment(value).format('DD MMMM YYYY')
        } else if (this.validDateTime(value)){
          return moment(value).format('HH:mm A')
        } else {
          return value
        }
      },
      validDate(val, format = 'YYYY-MM-DD'){
        return moment(val, format, true).isValid();
      },  
      validDateTime(val, format = 'YYYY-MM-DDTHH:mm'){
        return moment(val, format, true).isValid();
      },  

      async onExport(id, fileType){
        const res = await this.exportInspectionChecklist({id, fileType})

        if(fileType == 'excel'){
        if(res.status === 'success'){
          saveAs(res.data, 'Inspection Checklist.xlsx')
        } else {
          console.log('Something went wrong')
        }
        } else if(fileType == 'pdf'){
        if(res.status === 'success'){
          saveAs(res.data, 'Inspection Checklist.pdf')
        } else {
          console.log('Something went wrong')
        }
        } else {
        console.log('Something went wrong')
        }
      }
    }
  } 
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height"
          body-class="">
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">Vessel Running Hours</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="mb-4">
                <div class="d-flex align-items-center border rounded shadow p-4">
                  <b-row>
                    <b-col md="8">
                      <div class="d-flex align-items-center rounded shadow-sm p-4">
                        <div class="w-50 p-5 border rounded mr-4">
                          <img
                            :src="require('@src/assets/images/Km3FO09.png')"
                            alt="images"
                            style="width:100%;"
                          />
                        </div>
                        <div class="w-50">
                          <div class="d-flex">
                            <!-- icon -->
                            <div class="mr-2 mb-2">
                              <IconFleet />
                            </div>
                            <div class="d-flex flex-column align-items-start">
                              <strong class="text-primary">TB. TCP 401 🇮🇩</strong>
                              <span>PT. Energy Transporter Indonesia</span>
                            </div>
                          </div>
                          <div class="d-flex">
                            <table class="table table-striped">
                              <tbody>
                                <tr>
                                  <td>Fleet Type</td>
                                  <th>Tugboat</th>
                                </tr>
                                <tr>
                                  <td>Call Sign</td>
                                  <th>ETI</th>
                                </tr>
                                <tr>
                                  <td>Flag</td>
                                  <th>Indonesia</th>
                                </tr>
                                <tr>
                                  <td>Year & Building</td>
                                  <th>2015 / Singapore</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <div class="rounded shadow-sm p-4">
                        <div class="d-flex">
                          <!-- icon -->
                          <div class="mr-2 mb-2">
                            <ClockIcon />
                          </div>
                          <div class="d-flex flex-column align-items-start">
                            <strong>Total Running</strong>
                            <span>Last Updated : 15 January 2024</span>
                          </div>
                        </div>
                        <div class="d-flex">
                          <table class="table table-striped">
                            <tbody>
                              <tr>
                                <td>Main Engine Portside</td>
                                <th>1.550 Hours</th>
                              </tr>
                              <tr>
                                <td>Main Engine Starboard</td>
                                <th>1.550 Hours</th>
                              </tr>
                              <tr>
                                <td>Auxiliary Engine Portside</td>
                                <th>1.550 Hours</th>
                              </tr>
                              <tr>
                                <td>Auxiliary Engine Starboard</td>
                                <th>1.550 Hours</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col md="12" class="mb-4">
                <div class="d-flex align-items-center justify-content-between gap-2 w-100">
                  <div class="d-flex align-items-center">
                    <b-form-group label="Engine Position" label-for="from" class="mr-2" style="width:300px">
                      <b-form-select plain class="w-100" placeholder="Select...">
                        <template v-slot:first>
                          <b-form-select-option value="">Main Engine Portside</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group label="Month" label-for="kind-fleet" class="mr-2" style="width:300px">
                      <b-form-select plain class="w-100" placeholder="Select...">
                        <template v-slot:first>
                          <b-form-select-option value="">January</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <button type="button" class="btn"  :style="{ backgroundColor: '#2BDCAA', color: 'white', marginTop:'20px' }"><i class="fa fa-search" /></button>
                  </div>
                  <div class="d-flex align-items-center">
                    <button type="button" class="btn btn-outline-primary mr-2"  :style="{ marginTop:'20px' }" block><i class="fa fa-download" /> Import Running Hours</button>
                    <button type="button" class="btn mr-2"  :style="{ backgroundColor: '#2BDCAA', color: 'white', marginTop:'20px' }" block><i class="fa fa-upload" /> Export Running Hours</button>
                    <button type="button" class="btn"  :style="{ backgroundColor: '#3A5DE7', color: 'white', marginTop:'20px' }" block v-b-toggle.form-rh><i class="fa fa-plus" /> Input Running Hours</button>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="d-flex overflow-auto">
                  <table class="table table-striped">
                    <thead>
                      <tr >
                        <th class="text-truncate " style="background-color:#334697; color:white">No</th>
                        <th class="text-truncate " style="background-color:#334697; color:white">Engine Name</th>
                        <th class="text-truncate " style="background-color:#334697; color:white">Engine Branch</th>
                        <th class="text-truncate " style="background-color:#334697; color:white">Upload By</th>
                        <th class="text-truncate " style="background-color:#334697; color:white">Input Date</th>
                        <th class="text-truncate " style="background-color:#334697; color:white">Input RH</th>
                        <th class="text-truncate " style="background-color:#334697; color:white">Total RH</th>
                        <th class="text-truncate text-center" style="background-color:#334697; color:white">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td class="text-center">1</td>
                      <td class="text-truncate"><b-badge variant="success">Main Engine Portside</b-badge></td>
                      <td class="text-truncate">MITSUBISI - S123PK-MPTK</td>
                      <td class="text-truncate">Nurul Akbar</td>
                      <td class="text-truncate">15 January 2024</td>
                      <td class="text-truncate">24 Hours</td>
                      <th class="text-truncate">1.550 RH</th>
                      <td class="text-center">
                        <button type="button" class="btn btn-outline-primary mr-2"><i class="fa fa-pen" /></button>
                        <button type="button" class="btn btn-outline-danger"><i class="fa fa-trash" /></button>
                      </td>
                    </tbody>
                  </table>
                </div>
              </b-col> 
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col md="12">
        <b-sidebar
          id="form-rh"
          title="Form Input Running Hours"
          width="50%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow
        >
          <template #default="{ hide }">
            <form action="#" method="post" @submit.prevent="onSubmit">
              <div class="p-4">
                <b-row>
                  <b-col md="12" class="mb-4">
                    <div class="d-flex align-items-center border rounded shadow p-4 w-100">
                      <div class="d-flex flex-column rounded shadow-sm p-4 w-100">
                        <div class="d-flex w-100">
                          <div class="mr-2 mb-2">
                            <ClockIcon />
                          </div>
                          <div class="d-flex flex-column align-items-start">
                            <strong>Total Running</strong>
                            <span>Last Updated : 15 January 2024</span>
                          </div>
                        </div>
                        <div class="d-flex">
                          <table class="table table-striped">
                            <thead>
                              <tr >
                                <th class="text-truncate " style="background-color:#334697; color:white">Engine Name</th>
                                <th class="text-truncate " style="background-color:#334697; color:white">Engine Branch</th>
                                <th class="text-truncate " style="background-color:#334697; color:white">Total RH</th>
                              </tr>
                            </thead>
                            <tbody>
                              <td class="text-truncate">Main Engine Portside</td>
                              <td class="text-truncate">MITSUBISI - S123PK-MPTK</td>
                              <th class="text-truncate">1.550 RH</th>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </b-col>

                  <b-col md="12">
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                          <td>Tanggal Input</td>
                          <td>
                            <input type="date" class="form-control" />
                          </td>
                        </tr>
                        <tr>
                          <td>Main Engine Portside</td>
                          <td>
                            <div class="input-group">
                              <input type="number" class="form-control" />
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-primary" id="inputGroupPrepend2">Hours</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Main Engine Starboard</td>
                          <td>
                            <div class="input-group">
                              <input type="number" class="form-control" />
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-primary" id="inputGroupPrepend2">Hours</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Auxiliary Engine Portside</td>
                          <td>
                            <div class="input-group">
                              <input type="number" class="form-control" />
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-primary" id="inputGroupPrepend2">Hours</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Auxiliary Engine Starboard</td>
                          <td>
                            <div class="input-group">
                              <input type="number" class="form-control" />
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-primary" id="inputGroupPrepend2">Hours</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <b-form-group label="Notes *" class="mb-2">
                      <wysiwyg  />
                    </b-form-group>

                    <div class="d-flex align-items-center justify-content-end w-100">
                      <button type="button" class="btn btn-outline-primary mr-2"  :style="{ marginTop:'20px' }">Cancel & Discard</button>
                      <button type="button" class="btn mr-2"  :style="{ backgroundColor: '#2BDCAA', color: 'white', marginTop:'20px' }">Save & New</button>
                      <button type="button" class="btn"  :style="{ backgroundColor: '#3A5DE7', color: 'white', marginTop:'20px' }" >Save & Close</button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </form>
          </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { fleetsActions, maintenanceActions } from '@src/Utils/helper'
import moment from 'moment'
import AdvancedFilter from './Components/AdvancedFilter.vue'
import IconFleet from './Components/Icons/IconFleet'
import ClockIcon from './Components/Icons/ClockIcon'

export default {
  name: 'RunningHoursNewDetail',
  components:{
    AdvancedFilter,
    IconFleet,
    ClockIcon,
  },
  data () {
    return {
      dateRange: [],
      total: 0,
      currentPage: 1,
      vehicleTypes: [],
      selectedFleetEquipment: [],
      fleets: [],
      isLoading: false,
      runningHours: [],
      params: {
        action: '',
        search: '',
        status: null,
        startDate: null,
        endDate: null,
        perPage: 25,
        page: 1,
        vehicleIds: [],
        jobTypeIds: [],
        jobClassIds: []
      },

      form: {
        selectedVehicle: null,
        selectedCategory: {
          checked: true,
          value: 1,
          text: 'Tugboat'
        },
        date: '',
        remark: '',
        vehicleId: '',
        powers: []
      },

      selectedDetailVehicleId: null
    }
  },
  watch: {
    'form.selectedCategory' (value) {
      if(value) {
        this.selectedFleetEquipment = []
      }
    }
  },
  computed: {
    fleetsByCategory () {
      if (this.form.selectedCategory) {
        return this.fleets.filter(fleet => parseInt(fleet.vehicleType.id) === parseInt(this.form.selectedCategory.value))
      }

      return this.fleets
    }
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    closeSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', `${id}`)
    },

    async fetchFleets () {
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 999999,
        showAll: true,
        active: true,
        search: ''
      })
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.fleets.push({
              name: el.name,
              code: el.id,
              vehicleType: el.vehicleType,
            })
          })
        }
      }
    },
    async fetchFleetTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      this.vehicleTypes = []
      if (res.data) {
        res.data.forEach(f => {
          this.vehicleTypes.push({
            checked: true,
            value: f.id,
            text: f.name,
            disabled: false
          })
        })
      }
    },
    async getRunningHoursList () {
      this.isLoading = true
      this.runningHours = []

      const paramsQ = {
        vehicleIds: this.selectedFleetEquipment.map(fleet => fleet.code).toString(),
        vehicleTypeIds: this.vehicleTypes.filter(type => type.checked).map(fleet => fleet.value).toString(),
        perPage: this.params.perPage,
        page: this.params.page,
        startDate: this.params.startDate,
        endDate: this.params.endDate
      }

      if (paramsQ.search === '') {
        delete paramsQ.search
      }

      if (paramsQ.vehicleIds === '') {
        delete paramsQ.vehicleIds
      }

      if (paramsQ.vehicleTypeIds === '') {
        delete paramsQ.vehicleTypeIds
      }

      const res = await this.getRunningHours(paramsQ)

      if (res.status == 'success') {

        if (res.data.data && res.data.data.length > 0) {
          this.runningHours = res.data.data
          this.total = res.data.data.length
        }

        this.isLoading = false
      } else {
        this.isLoading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async getLatestRunningHoursList ({ code }) {
      const res = await this.getLatestRunningHours(code)
      if (res.status === 'success') {
        if (res.data.data) {
          let data = res.data.data
          if (data.powers) {
            this.form.powers = data.powers.map(power => {
              power.runningHours = this.selectedDetailVehicleId ? power.runningHours : 0
              return { ...power, fixActualRunningHours: power.actualRunningHours }
            })
          }

          this.form.vehicleId = code
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },

    async save () {
      this.form.date = this.$options.filters.parseDate(
        this.form.date,
        'DD-MM-YYYY'
      )
      const res = await this.saveRunningHours({
        method: this.selectedDetailVehicleId ? 'put' : 'post',
        data: this.form
      })
      if (res.status === 'success') {
        this.resetForm()
        this.$bvModal.hide('form-rh')
        this.getRunningHoursList()

        this.$swal(
          `Maintenance Running Hours!`,
          `Maintenance Running Hours successfully Saved`,
          'success'
        )
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    edit (data) {
      this.form.date = this.$options.filters.parseDate(
        data.date,
        'YYYY-MM-DD'
      )
      this.form.remark = data.remark
      this.form.selectedVehicle = {
        name: data.vehicle.name,
        code: data.vehicle.id
      }
      this.selectedDetailVehicleId = data.vehicle.id
      this.getLatestRunningHoursList({ code: data.vehicle.id })
      this.$bvModal.show('form-rh')
    },

    deleteData (vehicleId, date) {
      this.$swal({
        title: 'Delete Running Hours?',
        text: `Running Hours will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteRunningHours({ vehicleId, date })
          if (res.status !== 'success') {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.getRunningHoursList()
            this.resetForm()
            this.$swal(
              `Maintenance Running Hours!`,
              `Maintenance Running Hours successfully deleted`,
              'success'
            )
          }
        }
      })
    },

    resetForm () {
      this.selectedDetailVehicleId = null
      this.form = {
        selectedVehicle: null,
        selectedCategory: null,
        date: '',
        remark: '',
        vehicleId: '',
        powers: []
      }
    },
    onChangeDate () {
      this.params.startDate = this.dateRange ? moment(this.dateRange[0]).format('DD-MM-YYYY') : null
      this.params.endDate = this.dateRange ? moment(this.dateRange[1]).format('DD-MM-YYYY') : null
      this.form.date = this.form.date ? moment(this.form.date).format('DD-MM-YYYY') : ''

      if(this.params.startDate && this.params.endDate) {
        let a = moment(this.dateRange[0])
        let b = moment(this.dateRange[1])
        let diffDays = b.diff(a, 'days')

        if(diffDays > 31) {
          this.$swal(
            `Oops!`,
            'Maksimal Rentang Tanggal adalah 31 Hari',
            'error'
          )
          this.dateRange = []
        }
      }
    },
    calculateActualRunningHours (index) {
      if (this.form.powers[index]) {
        this.form.powers[index].actualRunningHours = parseInt(this.form.powers[index].fixActualRunningHours) + parseInt(this.form.powers[index].runningHours)
      }
    },
    formatter24 (e) {
      let number = parseInt(e)
      return !number || (number >= 0 && number <= 24 ? number : 24)
    },
    initFilterDateRange () {
      var date = new Date(); var y = date.getFullYear(); var m = date.getMonth()
      var firstDay = new Date(y, m, 1)
      var lastDay = new Date(y, m + 1, 0)

      this.dateRange = [firstDay, lastDay]
    },
    onSubmit (e) {

    }
  },
  async mounted () {
    this.initFilterDateRange()
    this.onChangeDate()
    this.fetchFleets()
    await this.fetchFleetTypes()
    await this.getRunningHoursList()
    xray.index()
  }
}
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h5', [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("General Settings")])]), _c('span', [_vm._v("Dashboard > "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("General Settings")])])])]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    staticClass: "rounded-0 position-static",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Setting List Settings")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', _vm._l(_vm.sections, function (section, index) {
          return _c('div', {
            key: index,
            staticClass: "d-flex p-2",
            class: [_vm.activeSection === index ? 'bg-primary' : ''],
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.scrollToSection(index);
              }
            }
          }, [_c('h6', {
            class: [_vm.activeSection === index ? 'text-white' : '']
          }, [_vm._v(_vm._s(section))])]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', {
    ref: "scrollContainer",
    staticClass: "d-flex flex-column overflow-auto",
    staticStyle: {
      "height": "78vh"
    },
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('iq-card', {
    ref: "section0",
    staticClass: "rounded-0",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('div', {
          staticClass: "position-absolute left-0",
          style: {
            borderLeft: "5px solid #334697",
            left: 0
          }
        }), _c('div', {
          staticClass: "px-4",
          style: {
            borderLeft: "3px solid #334697",
            marginLeft: "-20px"
          }
        }, [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Brand Settings")])]), _c('span', [_vm._v("Edit your brand details")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('iq-card', {
          staticClass: "rounded-0 shadow",
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('div', {
                staticClass: "px-4",
                style: {
                  borderLeft: "3px solid #334697",
                  marginLeft: "-20px"
                }
              }, [_c('strong', {
                staticClass: "card-title text-primary"
              }, [_vm._v("Logo Dark")])])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "d-flex w-100 border mb-3",
                style: {
                  marginTop: "-20px"
                }
              }), _c('div', {
                staticClass: "d-flex flex-column align-items-center justify-content-between",
                staticStyle: {
                  "height": "170px"
                }
              }, [_c('img', {
                style: {
                  width: '120px',
                  height: '120px'
                },
                attrs: {
                  "src": _vm.darkLogo ? _vm.darkLogo : _vm.form.darkLogo || 'https://iconape.com/wp-content/files/sh/336155/png/logo.png',
                  "alt": "logo-light"
                }
              }), _c('b-button', {
                attrs: {
                  "variant": "dark",
                  "type": "button"
                },
                on: {
                  "click": function click($event) {
                    return _vm.triggerFileUpload('darkLogo');
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-upload"
              }), _vm._v(" Choose file here ")]), _c('input', {
                ref: "darkLogo",
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "accept": "image/png"
                },
                on: {
                  "change": function change($event) {
                    return _vm.uploadFile($event, 'darkLogo');
                  }
                }
              })], 1)])], 1)];
            },
            proxy: true
          }])
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('iq-card', {
          staticClass: "rounded-0 shadow",
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('div', {
                staticClass: "px-4",
                style: {
                  borderLeft: "3px solid #334697",
                  marginLeft: "-20px"
                }
              }, [_c('strong', {
                staticClass: "card-title text-primary"
              }, [_vm._v("Logo Light")])])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "d-flex w-100 border mb-3",
                style: {
                  marginTop: "-20px"
                }
              }), _c('div', {
                staticClass: "d-flex flex-column align-items-center justify-content-between",
                staticStyle: {
                  "height": "170px"
                }
              }, [_c('img', {
                style: {
                  width: '120px',
                  height: '120px'
                },
                attrs: {
                  "src": _vm.lightLogo ? _vm.lightLogo : _vm.form.lightLogo || 'https://iconape.com/wp-content/files/sh/336155/png/logo.png',
                  "alt": "logo-light"
                }
              }), _c('b-button', {
                attrs: {
                  "variant": "dark",
                  "type": "button"
                },
                on: {
                  "click": function click($event) {
                    return _vm.triggerFileUpload('lightLogo');
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-upload"
              }), _vm._v(" Choose file here ")]), _c('input', {
                ref: "lightLogo",
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "accept": "image/png"
                },
                on: {
                  "change": function change($event) {
                    return _vm.uploadFile($event, 'lightLogo');
                  }
                }
              })], 1)])], 1)];
            },
            proxy: true
          }])
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('iq-card', {
          staticClass: "rounded-0 shadow",
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('div', {
                staticClass: "px-4",
                style: {
                  borderLeft: "3px solid #334697",
                  marginLeft: "-20px"
                }
              }, [_c('strong', {
                staticClass: "card-title text-primary"
              }, [_vm._v("Favicon")])])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "d-flex w-100 border mb-3",
                style: {
                  marginTop: "-20px"
                }
              }), _c('div', {
                staticClass: "d-flex flex-column align-items-center justify-content-between",
                staticStyle: {
                  "height": "170px"
                }
              }, [_c('img', {
                style: {
                  width: '120px',
                  height: '120px'
                },
                attrs: {
                  "src": _vm.favicon ? _vm.favicon : _vm.form.favicon || 'https://iconape.com/wp-content/files/sh/336155/png/logo.png',
                  "alt": "logo-light"
                }
              }), _c('b-button', {
                attrs: {
                  "variant": "dark",
                  "type": "button"
                },
                on: {
                  "click": function click($event) {
                    return _vm.triggerFileUpload('favicon');
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-upload"
              }), _vm._v(" Choose file here ")]), _c('input', {
                ref: "favicon",
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "accept": ".ico"
                },
                on: {
                  "change": function change($event) {
                    return _vm.uploadFile($event, 'favicon');
                  }
                }
              })], 1)])], 1)];
            },
            proxy: true
          }])
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Title Text",
            "label-for": "title"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.form.titleText,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "titleText", $$v);
            },
            expression: "form.titleText"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Footer Text",
            "label-for": "title"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.form.footerText,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "footerText", $$v);
            },
            expression: "form.footerText"
          }
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column text-black"
        }, [_c('strong', [_vm._v("Theme Customizer")]), _c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('i', {
          staticClass: "fa fa-brush mr-2"
        }), _c('strong', [_vm._v("Primary color settings")])]), _c('color-picker', {
          attrs: {
            "width": 200,
            "height": 200
          },
          model: {
            value: _vm.form.primaryColor,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "primaryColor", $$v);
            },
            expression: "form.primaryColor"
          }
        }), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "mt-2 mr-2"
        }, [_vm._v("Selected Color: "), _c('strong', {
          staticClass: "text-uppercase",
          style: {
            color: _vm.form.primaryColor
          }
        }, [_vm._v(_vm._s(_vm.form.primaryColor))])]), _c('svg', {
          attrs: {
            "height": "24",
            "width": "24"
          }
        }, [_c('circle', {
          attrs: {
            "cx": "16",
            "cy": "16",
            "r": "15",
            "fill": _vm.form.primaryColor
          }
        })])])], 1)]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column text-black"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('i', {
          staticClass: "fa fa-square mr-2"
        }), _c('strong', [_vm._v("Sidebar settings")])]), _c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.useTransparentLayout,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "useTransparentLayout", $$v);
            },
            expression: "form.useTransparentLayout"
          }
        }, [_vm._v(" Transparent Layout ")])], 1)]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column text-black"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('i', {
          staticClass: "fa fa-sun mr-2"
        }), _c('strong', [_vm._v("Layout settings")])]), _c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.useDarkLayout,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "useDarkLayout", $$v);
            },
            expression: "form.useDarkLayout"
          }
        }, [_vm._v(" Dark Layout ")])], 1)]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 my-2"
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onResetSettings('brand');
            }
          }
        }, [_vm._v(" Reset ")])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onSaveSettings('brand');
            }
          }
        }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    ref: "section1",
    staticClass: "rounded-0",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('div', {
          staticClass: "position-absolute left-0",
          style: {
            borderLeft: "5px solid #334697",
            left: 0
          }
        }), _c('div', {
          staticClass: "px-4",
          style: {
            borderLeft: "3px solid #334697",
            marginLeft: "-20px"
          }
        }, [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("System Settings")])]), _c('span', [_vm._v("Edit your system details")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Currency",
            "label-for": "currency_id"
          }
        }, [_c('v-select', {
          staticStyle: {
            "background-color": "#ffffff"
          },
          attrs: {
            "label": "text",
            "options": _vm.optionsCurrency,
            "reduce": function reduce(optionsCurrency) {
              return optionsCurrency.value;
            },
            "placeholder": "Select..."
          },
          model: {
            value: _vm.form.currencyId,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "currencyId", $$v);
            },
            expression: "form.currencyId"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Currency Symbol *",
            "label-for": "currency_id"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Rp"
          },
          model: {
            value: _vm.form.currencySymbol,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "currencySymbol", $$v);
            },
            expression: "form.currencySymbol"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_vm._l(_vm.statusOptions, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "disabled": item.disabled
            },
            model: {
              value: _vm.form.status,
              callback: function callback($$v) {
                _vm.$set(_vm.form, "status", $$v);
              },
              expression: "form.status"
            }
          }, [_c('span', [_vm._v(_vm._s(item.label))])])];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Decimal Number Format",
            "label-for": "num_format"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "0",
            "type": "number"
          },
          model: {
            value: _vm.form.decimalNumberFormat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "decimalNumberFormat", $$v);
            },
            expression: "form.decimalNumberFormat"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Date Format",
            "label-for": "date_format"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "0",
            "type": "date"
          },
          model: {
            value: _vm.form.dateFormat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "dateFormat", $$v);
            },
            expression: "form.dateFormat"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Time Format",
            "label-for": "time_format"
          }
        }, [_c('b-form-select', {
          staticClass: "form-control",
          attrs: {
            "plain": "",
            "name": "time_format"
          },
          model: {
            value: _vm.form.timeFormat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "timeFormat", $$v);
            },
            expression: "form.timeFormat"
          }
        }, [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("Select format...")]), _c('option', {
          attrs: {
            "value": "HH:mm"
          }
        }, [_vm._v("24 Hour Format")]), _c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("12 Hour Format")])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 my-2"
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onResetSettings('system');
            }
          }
        }, [_vm._v(" Reset ")])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onSaveSettings('system');
            }
          }
        }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    ref: "section2",
    staticClass: "rounded-0",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('div', {
          staticClass: "position-absolute left-0",
          style: {
            borderLeft: "5px solid #334697",
            left: 0
          }
        }), _c('div', {
          staticClass: "px-4",
          style: {
            borderLeft: "3px solid #334697",
            marginLeft: "-20px"
          }
        }, [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Company Settings")])]), _c('span', [_vm._v("Edit your company details")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company Name *",
            "label-for": "company_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Type text...",
            "type": "text"
          },
          model: {
            value: _vm.form.companyName,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "companyName", $$v);
            },
            expression: "form.companyName"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Address",
            "label-for": "address"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Type text...",
            "type": "text"
          },
          model: {
            value: _vm.form.companyAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "companyAddress", $$v);
            },
            expression: "form.companyAddress"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Zip/Post Code",
            "label-for": "post_code"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Type text...",
            "type": "text"
          },
          model: {
            value: _vm.form.postalCode,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "postalCode", $$v);
            },
            expression: "form.postalCode"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Country",
            "label-for": "country"
          }
        }, [_c('v-select', {
          staticStyle: {
            "background-color": "#ffffff"
          },
          attrs: {
            "label": "text",
            "options": _vm.optionsCountry,
            "reduce": function reduce(optionsCountry) {
              return optionsCountry.value;
            },
            "placeholder": "Select..."
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Telephone",
            "label-for": "telephone"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "62+",
            "type": "number"
          },
          model: {
            value: _vm.form.telephone,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "telephone", $$v);
            },
            expression: "form.telephone"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "System Email *",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "example@example.com",
            "type": "email"
          },
          model: {
            value: _vm.form.systemEmail,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "systemEmail", $$v);
            },
            expression: "form.systemEmail"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Email (from name) *",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "example@example.com",
            "type": "text"
          },
          model: {
            value: _vm.form.email,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "email", $$v);
            },
            expression: "form.email"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company Registration Number *",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Type Number",
            "type": "text"
          },
          model: {
            value: _vm.form.companyRegistrationNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "companyRegistrationNumber", $$v);
            },
            expression: "form.companyRegistrationNumber"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company Start Time *",
            "label-for": "email"
          }
        }, [_c('b-form-timepicker', {
          attrs: {
            "locale": "id"
          },
          model: {
            value: _vm.form.companyStartTime,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "companyStartTime", $$v);
            },
            expression: "form.companyStartTime"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company End Time *",
            "label-for": "email"
          }
        }, [_c('b-form-timepicker', {
          attrs: {
            "locale": "id"
          },
          model: {
            value: _vm.form.companyEndTime,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "companyEndTime", $$v);
            },
            expression: "form.companyEndTime"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "IP Restrict",
            "label-for": "email"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.ipRestrict,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "ipRestrict", $$v);
            },
            expression: "form.ipRestrict"
          }
        }, [_vm._v(" Off / On ")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Timezone",
            "label-for": "country"
          }
        }, [_c('v-select', {
          staticStyle: {
            "background-color": "#ffffff"
          },
          attrs: {
            "label": "text",
            "options": _vm.optionsTimezone,
            "reduce": function reduce(optionsTimezone) {
              return optionsTimezone.value;
            },
            "placeholder": "Select..."
          },
          model: {
            value: _vm.form.timezone,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "timezone", $$v);
            },
            expression: "form.timezone"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.useTaxNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "useTaxNumber", $$v);
            },
            expression: "form.useTaxNumber"
          }
        }, [_vm._v(" Tax Number ")])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_vm._l(_vm.optionsTax, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "disabled": item.disabled
            },
            model: {
              value: _vm.form.taxType,
              callback: function callback($$v) {
                _vm.$set(_vm.form, "taxType", $$v);
              },
              expression: "form.taxType"
            }
          }, [_c('span', [_vm._v(_vm._s(item.label))])])];
        })], 2), _c('b-form-input', {
          staticClass: "mt-2",
          attrs: {
            "placeholder": "Enter VAT / GST Number...",
            "type": "number"
          },
          model: {
            value: _vm.form.taxNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "taxNumber", $$v);
            },
            expression: "form.taxNumber"
          }
        })], 1)]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 my-2"
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onResetSettings('company');
            }
          }
        }, [_vm._v(" Reset ")])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onSaveSettings('company');
            }
          }
        }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    ref: "section3",
    staticClass: "rounded-0",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('div', {
          staticClass: "position-absolute left-0",
          style: {
            borderLeft: "5px solid #334697",
            left: 0
          }
        }), _c('div', {
          staticClass: "px-4",
          style: {
            borderLeft: "3px solid #334697",
            marginLeft: "-20px"
          }
        }, [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Email Notification Settings")])]), _c('span', [_vm._v("Edit email notification settings")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', _vm._l(_vm.emailSettings, function (state, index) {
          return _c('b-col', {
            key: index,
            attrs: {
              "md": "12"
            }
          }, [_c('h5', {
            staticClass: "mb-2"
          }, [_c('strong', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))])]), _c('b-row', _vm._l(state === null || state === void 0 ? void 0 : state.emailNotificationSettings, function (obj, objIndex) {
            return _c('b-col', {
              key: objIndex,
              staticClass: "mb-3",
              attrs: {
                "md": "6"
              }
            }, [_c('div', {
              staticClass: "d-flex w-100 align-items-center justify-content-between p-2 border border-dark rounded text-black"
            }, [_c('div', {
              staticClass: "d-flex align-items-center"
            }, [_c('span', {
              staticClass: "mr-2"
            }, [_vm._v(_vm._s(obj.name))]), _c('b-badge', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle.email-setting",
                modifiers: {
                  "email-setting": true
                }
              }],
              staticStyle: {
                "cursor": "pointer"
              },
              attrs: {
                "variant": "primary"
              },
              on: {
                "click": function click($event) {
                  _vm.formEmailSetting = obj;
                }
              }
            }, [_c('i', {
              staticClass: "fa fa-pen"
            })])], 1), _c('b-form-checkbox', {
              staticStyle: {
                "margin-right": "-8px"
              },
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": ""
              },
              model: {
                value: obj.useActive,
                callback: function callback($$v) {
                  _vm.$set(obj, "useActive", $$v);
                },
                expression: "obj.useActive"
              }
            })], 1)]);
          }), 1)], 1);
        }), 1)];
      },
      proxy: true
    }])
  })], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "email-setting",
      "title": "Email Notification Setting",
      "width": "80%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('form', {
          attrs: {
            "action": "#",
            "method": "post"
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveEmailSetting.apply(null, arguments);
            }
          }
        }, [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Title Text",
            "label-for": "title"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.formEmailSetting.title,
            callback: function callback($$v) {
              _vm.$set(_vm.formEmailSetting, "title", $$v);
            },
            expression: "formEmailSetting.title"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Sub title",
            "label-for": "title"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.formEmailSetting.subtitle,
            callback: function callback($$v) {
              _vm.$set(_vm.formEmailSetting, "subtitle", $$v);
            },
            expression: "formEmailSetting.subtitle"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Model",
            "label-for": "currency_id"
          }
        }, [_c('v-select', {
          staticStyle: {
            "background-color": "#ffffff",
            "z-index": "99999"
          },
          attrs: {
            "label": "model",
            "options": _vm.optionsModelColumns,
            "reduce": function reduce(optionsModelColumns) {
              return optionsModelColumns.model;
            },
            "placeholder": "Select..."
          },
          model: {
            value: _vm.formEmailSetting.model,
            callback: function callback($$v) {
              _vm.$set(_vm.formEmailSetting, "model", $$v);
            },
            expression: "formEmailSetting.model"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Show Shortcode",
            "label-for": "currency_id"
          }
        }, [_c('div', {
          staticClass: "form-control text-center bg-primary",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": _vm.fetchColumnBySelectedModel
          }
        }, [_vm._v(" Shortcode ")])])], 1), _vm.listColumns.length > 0 ? _c('b-col', {
          staticClass: "my-4",
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "w-100 d-flex flex-wrap"
        }, _vm._l(_vm.listColumns, function (state, index) {
          return _c('div', {
            key: index,
            class: "px-3 p-2 table-primary mr-2 mb-2 rounded-lg",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onSelectedColumns(index);
              }
            }
          }, [_c('span', [_vm._v(_vm._s(state))])]);
        }), 0)]) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Content",
            "label-for": "content"
          }
        }, [_c('editor', {
          attrs: {
            "api-key": "a1koqlofb8tq75y26avurdkgz0u4mx1mdih2u3u8ey2krd5c",
            "init": _vm.editorConfig
          },
          on: {
            "onEditorChange": _vm.handleEditorChange
          },
          model: {
            value: _vm.formEmailSetting.content,
            callback: function callback($$v) {
              _vm.$set(_vm.formEmailSetting, "content", $$v);
            },
            expression: "formEmailSetting.content"
          }
        })], 1)], 1)], 1)], 1), _c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_c('strong', [_vm._v("Preview")]), _c('div', {
          staticClass: "notification-box"
        }, [_c('img', {
          staticClass: "img-fluid",
          staticStyle: {
            "width": "300px"
          },
          attrs: {
            "src": require('@src/assets/images/logo/logo-telis.png'),
            "alt": "logo-telis"
          }
        }), _c('h4', {
          staticClass: "text-white"
        }, [_vm._v(_vm._s(_vm.formEmailSetting.title))]), _c('p', {
          staticClass: "text-white"
        }, [_vm._v(_vm._s(_vm.formEmailSetting.subtitle))])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "content-box",
          domProps: {
            "innerHTML": _vm._s(_vm.formEmailSetting.content)
          }
        })]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "footer-box"
        }, [_c('img', {
          staticClass: "img-fluid",
          staticStyle: {
            "width": "300px"
          },
          attrs: {
            "src": require('@src/assets/images/logo/tcp-logo.png'),
            "alt": "logo-telis"
          }
        }), _c('div', {
          staticClass: "mb-2"
        }, [_c('span', [_vm._v("Bakrie Tower lantai 9, Komplek Rasuna Epicentrum Jl. HR Rasuna Said, Karet Kuningan, Setiabudi, Jakarta, 12940.")])]), _c('div', {
          staticClass: "d-flex w-100 flex-column"
        }, [_c('span', [_vm._v("Telp: +62-21 2994 1389")]), _c('span', [_vm._v("Fax : +62-21 2994 2886")]), _c('span', [_vm._v("Website: www.transcoalpacific.com")]), _c('span', [_vm._v("Email : info@transcoalpacific.com")])])])])], 1), _c('b-row', {
          staticClass: "mt-2 p-4"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "type": "button"
          }
        }, [_vm._v(" Cancel ")])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onSaveEmailSetting.apply(null, arguments);
            }
          }
        }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <form @submit.prevent="onSave()">
      <b-row v-if="detailMi">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="outline-primary" @click="$emit('openModalEmail', 'Evaluation', detailMi.id)" class="mr-2"> Send Email Notification  <i class="fa fa-send ml-1"></i></b-button>
          <b-button variant="primary" @click="isEditMode = !isEditMode"
            >{{ isEditMode ? 'Preview' : 'Update' }} Evaluation</b-button
          >
        </b-col>
        <b-col cols="12" md="6" class="mb-3">
          <h6 class="text-primary border-bottom pb-1 mb-3"
            ><b>INFORMATION OF ACCIDENT/INCIDENT</b></h6
          >
          <b-row>
            <b-col md="6">
              <b-form-group label="Company *" label-for="company">
                <h6 style="font-weight: 500">{{
                  detailMi.company.company ?? '-'
                }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Fleet Name *" label-for="fleets">
                <h6 style="font-weight: 500">{{
                  detailMi.vehicle?.name ?? '-'
                }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Date of Loss (DOL) *" label-for="dol">
                <h6 style="font-weight: 500">{{
                  detailMi.date_of_loss ?? '-'
                }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Place of Loss (POL) *" label-for="pol">
                <h6 style="font-weight: 500">{{
                  detailMi.place_of_loss ?? '-'
                }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Type of Coverage *" label-for="coverage">
                <h6 style="font-weight: 500">{{
                  detailMi.type_coverage.name ?? '-'
                }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Type of Loss *" label-for="loss">
                <h6 style="font-weight: 500">{{
                  detailMi.type_loss.name ?? '-'
                }}</h6>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6" class="mb-3">
          <h6 class="text-primary border-bottom pb-1 mb-3"><b>CLAIMANT</b></h6>
          <b-row>
            <b-col md="6">
              <b-form-group label="Company *" label-for="company">
                <h6 style="font-weight: 500">{{
                  detailMi.company.company ?? '-'
                }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Name *" label-for="name">
                <h6 style="font-weight: 500">{{ detailMi?.name ?? '-' }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Position *" label-for="position">
                <h6 style="font-weight: 500">{{ detailMi.position ?? '-' }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Department of FLeet *"
                label-for="departments"
              >
                <h6 style="font-weight: 500">{{
                  detailMi.department ? detailMi.department?.name : '-'
                }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Cause of Loss" label-for="cause_of_loss">
                <h6 style="font-weight: 500">{{
                  detailMi.cause_of_loss ? detailMi.cause_of_loss?.name : '-'
                }}</h6>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12">
          <h5 class="mb-2 text-primary" style="font-weight: 600"
            >Recommendation</h5
          >
          <div class="table-responsive">
            <table class="table mb-0 table-borderless table-box-shadow">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Permasalahan</th>
                  <th>Vessel Case</th>
                  <th>Root Cause</th>
                  <th>Rekomendasi</th>
                  <th>Cause of Loss</th>
                  <th>Status</th>
                  <th>Closed Date</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="recommendations.length">
                  <tr
                    v-for="(recommendation, idx) in recommendations"
                    :key="recommendation.id"
                  >
                    <td>{{ idx + 1 }}. </td>
                    <td>{{ recommendation.problem }}</td>
                    <td>{{ recommendation.vessel_case }}</td>
                    <td>{{ recommendation.root_cause }}</td>
                    <td>{{ recommendation.recommendation }}</td>
                    <td>{{ recommendation.cause_of_loss_id }}</td>
                    <td>{{ recommendation.status }}</td>
                    <td>{{ recommendation.closed_at }}</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="8" class="text-center">No Data Available!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col cols="12" class="mt-4">
          <h5 class="mb-2 text-primary" style="font-weight: 600"
            >Notif Mail to Users</h5
          >
          <b-form-group class="mt-2">
            <v-multiselect
              v-model="notifMailUser"
              placeholder="Notif user"
              label="name"
              track-by="id"
              :options="optionsUser"
              :multiple="true"
            >
              <span slot="noResult">Data not found.</span>
            </v-multiselect>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mt-4">
          <h5 class="mb-2 text-primary" style="font-weight: 600"
            >Input Evaluation</h5
          >
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-truncate">No.</th>
                  <th class="text-truncate">Tahapan Kegiatan</th>
                  <th class="text-truncate">Sasaran Tahapan (Milestone)</th>
                  <th class="text-truncate">Uraian Tugas</th>
                  <th class="text-truncate">Indikator Keberhasilan</th>
                  <th class="text-truncate">Metode Evaluasi</th>
                  <th class="text-truncate">Frekuensi</th>
                  <th class="text-truncate">Pelaksana</th>
                  <th class="text-truncate">Kebutuhan Tindak lanjut</th>
                  <th class="text-truncate" v-if="isEditMode">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(form, idx) in forms" :key="idx">
                  <td>{{ idx + 1 }}</td>
                  <td>
                    <b-form-textarea
                      v-if="isEditMode"
                      v-model="form.activity_stage"
                      id="tahapan-kegiatan"
                      rows="3"
                    >
                    </b-form-textarea>
                    <h6 v-else>{{ form.activity_stage ?? '-' }}</h6>
                  </td>
                  <td>
                    <b-form-textarea
                      v-if="isEditMode"
                      v-model="form.milestone"
                      id="sasaran-tahapan"
                      rows="3"
                    ></b-form-textarea>
                    <h6 v-else>{{ form.milestone ?? '-' }}</h6>
                  </td>
                  <td>
                    <b-form-textarea
                      v-if="isEditMode"
                      v-model="form.job_description"
                      id="uraian-tugas"
                      rows="3"
                    >
                    </b-form-textarea>
                    <h6 v-else>{{ form.job_description ?? '-' }}</h6>
                  </td>
                  <td>
                    <b-form-textarea
                      v-if="isEditMode"
                      v-model="form.success_indicator"
                      id="indikator-keberhasilan"
                      rows="3"
                    >
                    </b-form-textarea>
                    <h6 v-else>{{ form.success_indicator ?? '-' }}</h6>
                  </td>
                  <td>
                    <b-form-textarea
                    v-if="isEditMode"
                    v-model="form.evaluation_result"
                    id="hasil-evaluasi"
                    rows="3"
                    >
                  </b-form-textarea>
                  <h6 v-else>{{ form.evaluation_result ?? '-' }}</h6>
                </td>
                <td>
                  <b-form-textarea
                  v-if="isEditMode"
                  v-model="form.frequency"
                  id="frekuensi"
                  rows="3"
                  ></b-form-textarea>
                  <h6 v-else>{{ form.frequency ?? '-' }}</h6>
                </td>
                <td>
                  <b-form-textarea
                  v-if="isEditMode"
                  v-model="form.executor"
                  id="pelaksana"
                  rows="3"
                  ></b-form-textarea>
                  <h6 v-else>{{ form.frequency ?? '-' }}</h6>
                </td>
                <td>
                  <template v-if="isEditMode" >
                    <b-form-radio
                    v-for="(item, index) in typeFormInsurance"
                    v-model="form.follow_up_needs"
                    class="custom-radio-color-checked"
                    inline
                    :color="item.color"
                    :name="`follow_up_needs-${idx + 1}`"
                    :key="index"
                    :value="item.value"
                    :id="`follow_up_needs-${item.value}-${idx + 1}`"
                    ><strong>
                      <h4>{{ item.label }}</h4>
                    </strong></b-form-radio
                    >
                  </template>
                  <h6 v-else class="text-capitalize text-center">{{ form.follow_up_needs }}</h6>
                  </td>
                  <td v-if="isEditMode">
                    <b-button
                      @click="deleteForm(idx)"
                      variant="danger"
                      size="sm"
                      ><i class="fa fa-trash"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-button
              v-if="isEditMode"
              @click="addForms"
              variant="warning"
              block
              class="mt-2 mb-3"
              ><i class="fa fa-plus"></i> Add More Data</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="text-right mb-4">
          <b-button
            v-if="isEditMode"
            type="submit"
            :disabled="saving"
            variant="primary"
            class="px-5"
            >Submit</b-button
          >
          <!-- <b-button
            @click="$router.go(-1)"
            :disabled="saving"
            variant="none"
            class="iq-bg-danger ml-3"
            >Cancel
          </b-button> -->
        </b-col>
      </b-row>
    </form>
  </div>
</template>
<script>
import { xray } from '@/src/config/pluginInit'
import { miActions, crewingActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  name: 'AddFormEvaluation',
  components: { StepNavigation, StepWizard },
  async mounted() {
    xray.index()
    if (!this.$route.query.formId) {
      this.$router.push({
        name: 'mi.listInitial',
      })
    }
    await this.fetchDataUserExpert()
    // await this.getIncidents()
    await this.getRecommendationList()
    await this.fetchList()
  },
  props: {
    detailMi: null,
  },
  data() {
    return {
      permission_user: JSON.parse(localStorage.getItem('profile')).role.roles,
      image: require('@/src/assets/images/logo.png'),
      saving: false,
      vehicleList: [],
      departmentList: [],
      recommendations: [],

      optionsUser: [],
      notifMailUser: [],

      forms: [
        {
          id: null,
          activity_stage: '',
          milestone: '',
          job_description: '',
          success_indicator: '',
          evaluation_result: '',
          frequency: '',
          executor: '',
          follow_up_needs: 'yes',
        },
      ],
      typeFormInsurance: [
        {
          label: 'Yes',
          color: 'success',
          value: 'yes',
        },
        {
          label: 'No',
          color: 'danger',
          value: 'no',
        },
      ],
      isEditMode: false,
    }
  },
  computed: {
    userPermission() {
      if (
        [
          'SUPER ADMIN',
          'MARINE INSURANCE',
          'MARINE INSURANCE ADMIN',
          'MARINE INSURANCE MANAGER',
        ].includes(this.permission_user)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...miActions,
    ...crewingActions,
    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 10000,
      }
      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          this.optionsUser = data.map((val) => {
            return {
              ...val,
              name: val.userDetail?.name,
            }
          })
        }
      }
    },
    async getRecommendationList() {
      const params = {
        form_id: this.$route.query.formId,
        pagination: 0,
      }
      const res = await this.getRecommendation(params)

      if (res.status === 'success' && res.data) {
        const { data } = await this.getMasterCauseOfLoss()
        if (res.data.data) {
          this.recommendations = res.data.data.map((val) => ({
            ...val,
            cause_of_loss_id: data.find((d) => d.id === val.cause_of_loss_id)
              ?.name,
          }))
        }
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async fetchList() {
      const params = {
        form_id: this.$route.query.formId,
      }
      const res = await this.getEvaluation(params)

      if (res.status === 'success' && res.data) {
        if (res.data.length) {
          this.forms = res.data
        }
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    deleteForm(index) {
      this.forms.splice(index, 1)
    },
    async onSave() {
      const payload = {
        form_id: this.$route.query.formId,
        data: this.forms,
      }

      this.saving = true

      const { status, data } = await this.saveEvaluation(payload)

      if (status !== 'success') {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.saving = false
        return
      }

      let emailPayload = {
        user_id: [],
      }

      if (this.notifMailUser.length > 0) {
        this.notifMailUser.map((obj) => {
          emailPayload.user_id.push(obj.id)
        })

        await this.sendEmailRecommendation({
          data: emailPayload,
          id: data[0].form_id,
        })
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      this.saving = false
    },
    addForms() {
      this.forms.push({
        id: null,
        activity_stage: '',
        milestone: '',
        job_description: '',
        success_indicator: '',
        evaluation_result: '',
        frequency: '',
        executor: '',
        follow_up_needs: 'yes',
      })
    },
  },
}
</script>

<template>
  <form @submit.prevent="onSave">
    <b-row class="m-4">
      <b-col md="12" class="mb-4">
        <b-form-group
          label="Filter Type"
          label-for="type"
        >
          <template v-for="(item, index) in optionsFilterType">
            <b-form-radio
              class="custom-radio-color-checked"
              inline
              :color="item.color"
              name="color"
              :key="index"
              :value="item.value"
              :disabled="item.disabled"
              required
              v-model="filterType"
            >
              <span>{{ item.label }}</span>
            </b-form-radio>
          </template>
        </b-form-group> 
      </b-col>
      <b-col md="12" v-if="filterType !== 'ACTIVE'">
        <h5 class="text-primary"><strong>Detail Information</strong></h5>
        <div class="d-flex w-100 border my-1" />
        <b-row class="mb-2">
          <template v-if="filterType === 'SELL'">
            <b-col md="6">
              <b-form-group
                label="Date of Sale*"
                label-for="sale"
              >
                <b-form-input
                  type="date"
                  name="dateOfSell"
                  placeholder="Type Text..."
                  v-model="form.dateOfSell"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Type of Sale*"
                label-for="sale"
              >
                <b-form-select
                  plain
                  v-model="form.typeOfSell"
                  size="sm"
                  name="type_sell"
                >
                  <template v-slot:first>
                    <b-form-select-option value="DIRECT">Direct</b-form-select-option>
                  </template>
                  <b-form-select-option value="AUCTION">Auction</b-form-select-option>
                  <b-form-select-option value="BROKER">Broker</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </template>
          <template v-if="filterType === 'ON_LOAN'">
            <b-col md="6">
              <b-form-group
                label="Date of Loan*"
                label-for="sale"
              >
                <b-form-input
                  type="date"
                  name="dateOfSell"
                  placeholder="Type Text..."
                  v-model="form.dateOfLoan"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Plan of Return Loan*"
                label-for="sale"
              >
                <b-form-input
                  type="date"
                  name="planOfReturnLoan"
                  placeholder="Type Text..."
                  v-model="form.planOfReturnLoan"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </template>
          <template v-if="filterType === 'SINKED'">
            <b-col md="6">
              <b-form-group
                label="Date of Accident"
                label-for="sale"
              >
                <b-form-input
                  type="date"
                  name="dateOfAccident"
                  placeholder="Type Text..."
                  v-model="form.dateOfAccident"
                ></b-form-input>
              </b-form-group>
            </b-col> 
            <b-col md="6">
              <b-form-group
                label="Link to Case (Marine Insurance)"
                label-for="sale"
              >
                <v-select
                  label="text"
                  v-model="form.linkToCaseId"
                  :options="linkToCaseOptions"
                  :reduce="(option) => option.value"
                  :clearable="false"
                ></v-select>
              </b-form-group>  
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Latitude"
                label-for="latitude"
              >
                <b-form-input
                  type="text"
                  name="latitude"
                  placeholder="Type Text..."
                  v-model="form.latitude"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Longitude"
                label-for="longitude"
              >
                <b-form-input
                  type="text"
                  name="longitude"
                  placeholder="Type Text..."
                  v-model="form.longitude"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </template>
          <b-col md="6">
            <b-form-group
              label="Type of Owner*"
              label-for="sale"
            >
              <b-form-select
                plain
                v-model="form.typeOfOwner"
                size="sm"
                name="typeOfOwner"
              >
                <template v-slot:first>
                  <b-form-select-option value="COMPANY">Company / Institution / Organization</b-form-select-option>
                </template>
                <b-form-select-option value="PERSONAL">Persoal</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Name of Owner*"
              label-for="nameOfOwner"
            >
              <b-form-input
                type="text"
                name="nameOfOwner"
                placeholder="Type Text..."
                v-model="form.nameOfOwner"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Phone Number"
              label-for="owner"
            >
              <div class="input-group">
                <div class="input-group-prepend">
                  <v-select
                    label="text"
                    v-model="form.numberCode"
                    :options="optionsNumberCode"
                    :reduce="(option) => option.value"
                    :clearable="false"
                  ></v-select>
                  <!-- <span class="input-group-text">🇮🇩+62</span> -->
                </div>
                <input type="number" v-model="form.phoneNumber" class="form-control">
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nationality"
              label-for="nationality"
            >
              <b-form-select
                plain
                v-model="form.nationality"
                size="sm"
                name="nationality"
              >
                <template v-slot:first>
                  <b-form-select-option value="wni">Warga Negara Indonesia (WNI)</b-form-select-option>
                  <b-form-select-option value="wna">Warga Negara Asing (WNA)</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="NIB"
              label-for="owner"
            >
              <b-form-input
                type="text"
                name="nib"
                placeholder="Type Text..."
                v-model="form.nib"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email Address"
              label-for="email"
            >
              <b-form-input
                type="text"
                name="email"
                placeholder="Type Text..."
                v-model="form.email"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <h5 class="text-primary"><strong>Address</strong></h5>
        <div class="d-flex w-100 border my-1" />
        <b-row class="mb-2" v-if="form.nationality === 'wni'">
          <b-form-group class="col-md-6" label="Provinsi" label-for="Provinsi">
            <v-select
              id="provinsi"
              label="name"
              class="style-chooser"
              v-model="form.province"
              :options="option.provience"
              :reduce="(option) => option.name"
              placeholder="Pilih Provinsi"
              :clearable="false"
              :disabled="checked"
              @input="(e) => onSelect(e, 'provinsi')"
            ></v-select>
          </b-form-group>
          <b-form-group class="col-md-6" label="Kota / Kabupaten" label-for="Kota">
            <v-select
                id="Kota"
                label="name"
                v-model="form.city"
                :options="option.cities"
                :reduce="(option) => option.name"
                :placeholder="form.district ? 'Pilih Kabupaten':'Pilih Provinsi Terlebih Dahulu'"
                :clearable="false"
                :disabled="checked || this.loadingKota || !form.province"
                @input="(e) => onSelect(e, 'kabupaten')"
              ></v-select>
          </b-form-group>
          <b-form-group class="col-md-6" label="Kecamatan" label-for="Kecamatan">
            <v-select
                id="Kecamatan"
                label="name"
                v-model="form.district"
                :options="option.districts"
                :reduce="(option) => option.name"
                :placeholder="form.city ? 'Pilih Kecamatan':'Pilih Kabupaten Terlebih Dahulu'"
                :clearable="false"
                :disabled="checked || this.loadingKecamatan || !form.city"
                @input="(e) => onSelect(e, 'kecamatan')"
              ></v-select>
          </b-form-group>
          <b-form-group class="col-md-6" label="Kelurahan" label-for="Kelurahan">
            <v-select
                id="Kecamatan"
                label="name"
                v-model="form.subDistrict"
                :options="option.districts"
                :reduce="(option) => option.name"
                :placeholder="form.district ? 'Pilih Kelurahan':'Pilih Kecamatan Terlebih Dahulu'"
                :clearable="false"
                :disabled="checked || this.loadingKelurahan || !form.district"
              ></v-select>
          </b-form-group>
          <b-form-group class="col-md-6" label="Kode POS" label-for="kode_pos">
            <b-form-input
              id="kode_pos"
              style="height: 35px"
              type="number"
              name="kode_pos"
              :disabled="checked"
              v-model="form.kode_pos"
              @keydown="checkKeyNumber"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="col-md-12"
            label="Alamat Domisili"
            label-for="alamat_domisili"
          >
            <b-form-textarea
              id="alamat_domisili"
              name="alamat_domisili"
              rows="1"
              v-model="form.alamat"
              :disabled="checked"
              required
              placeholder="Alamat..."
            ></b-form-textarea>
          </b-form-group>
        </b-row>

        <b-form-group
          v-else
            class="col-md-12"
            label="Alamat Domisili"
            label-for="alamat_domisili"
          >
            <b-form-textarea
              id="alamat_domisili"
              name="alamat_domisili"
              rows="1"
              v-model="form.alamat"
              :disabled="checked"
              required
              placeholder="Alamat..."
            ></b-form-textarea>
        </b-form-group>

        <template v-if="filterType !== 'SINKED'">
          <h5 class="text-primary"><strong>Other Information</strong></h5>
          <div class="d-flex w-100 border my-1" />
          <b-row class="mb-2">
            <b-col md="6">
              <b-form-group label="Currency" label-for="currencyId">
                <v-select 
                  v-model="form.currencyId" 
                  label="text" 
                  :options="optionsCurrency" 
                  :reduce="optionsCurrency => optionsCurrency.value" 
                  placeholder="Select..." 
                  style="background-color:#ffffff;"
                  class="mr-2 w-100"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Rate" label-for="rate">
                <b-form-input
                  id="rate"
                  name="rate"
                  type="number"
                  placeholder="0"
                  v-model="form.rate"
                ></b-form-input>
                <small>{{ formatCurrency(Number(form.rate)) }}</small>
              </b-form-group>
            </b-col> 
            <b-col md="6">
              <b-form-group label="Tax" label-for="tax">
                <b-form-radio-group inline v-model="form.tax">
                  <b-form-radio value="INCLUDE_PPN" name="INCLUDE_PPN" key="INCLUDE_PPN">Include PPN</b-form-radio>
                  <b-form-radio value="EXCLUDE_PPN" name="EXCLUDE_PPN" key="EXCLUDE_PPN">Exclude PPN</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="6" />
            <b-col md="6">
              <b-form-group label="Price of Buy" label-for="price_buy">
                <b-form-input
                  name="price_buy"
                  type="number"
                  placeholder="0"
                  v-model="form.priceOfBuy"
                ></b-form-input>
                <small v-if="optionsCurrency?.find(obj => obj?.value === form.currencyId)?.text === 'IDR'">{{ formatCurrency(Number(form.priceOfBuy)) }}</small>
                <small v-else>{{ formatUSD(Number(form.priceOfBuy)) }}</small>
              </b-form-group>
            </b-col> 
            <b-col md="6">
              <b-form-group label="Price of Sell" label-for="priceOfSell">
                <b-form-input
                  name="priceOfSell"
                  type="number"
                  placeholder="0"
                  v-model="form.priceOfSell"
                ></b-form-input>
                <small v-if="optionsCurrency?.find(obj => obj?.value === form.currencyId)?.text === 'IDR'">{{ formatCurrency(Number(form.priceOfSell)) }}</small>
                <small v-else>{{ formatUSD(Number(form.priceOfBuy)) }}</small>
              </b-form-group>
            </b-col>  
            <b-col md="12">
              <b-form-group label="Reason" label-for="chronology">
                <wysiwyg :style="{ height: '200px' }" v-model="form.chronology" />
              </b-form-group>
            </b-col> 
          </b-row>
        </template>

        <template v-if="filterType === 'SINKED'">
          <h5 class="text-primary"><strong>Crew on Duties</strong></h5>
          <div class="d-flex w-100 border my-1" />
          <b-row>
            <b-col md="12">
              <table class="table table-borderless table-sm">
                <thead>
                  <tr v-for="(val, index) in form.crewOnDuties" :key="index">
                    <td>
                      <b-form-group label="Name of Crew" label-for="rate" class="d-flex flex-column">
                        <v-select
                          label="label"
                          v-model="val.crewName"
                          :options="crewList"
                          :reduce="(option) => option.value"
                          :clearable="false"
                          @input="() => {
                            val.crewPosition = crewList.find(obj => obj.value === val.crewName)?.position
                          }"
                        ></v-select>
                        <div class="d-flex align-items-center" v-if="loadingCrew">
                          <i class="spinner-border spinner-border-sm text-primary mr-2"></i>
                          <span class="text-primary">Fetching Crew, please wait a moment.</span>
                        </div>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="Crew Position" label-for="crewPosition">
                        <b-form-input
                          name="crewPosition"
                          type="text"
                          placeholder="Type Text..."
                          v-model="val.crewPosition"
                        ></b-form-input>
                      </b-form-group>
                    </td>
                    <td style="width:10px">
                      <div class="px-3 py-1 rounded-lg bg-danger mt-3" style="cursor:pointer" @click="form.crewOnDuties.splice(index, 1)">
                        <i class="fa fa-trash" />
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>
            </b-col>
          </b-row>
          <button type="button" block class="btn w-100 mb-4" :style="{ backgroundColor: '#2BDCAA', color: 'white' }" block @click="form.crewOnDuties.push({
            crewName:'',
            crewPosition:'',
          })"><i class="fa fa-plus-circle" /> Add More Data</button>
        </template>   
        
        <template v-if="filterType === 'SINKED'">
          <h5 class="text-primary"><strong>Insurance Information</strong></h5>
          <div class="d-flex w-100 border my-1" />
          <b-row class="mb-2">
            <b-col md="6">
              <b-form-group label="Status" label-for="currencyId">
                <template v-for="(item, index) in optionsStatusInsurance">
                  <b-form-radio
                    class="custom-radio-color-checked"
                    inline
                    :color="item.color"
                    name="color"
                    :key="index"
                    :value="item.value"
                    :disabled="item.disabled"
                    required
                    v-model="form.sinkedStatus"
                  >
                    <span>{{ item.label }}</span>
                  </b-form-radio>
                </template>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Insurance Company" label-for="insuranceCompany">
                <b-form-input
                  name="insuranceCompany"
                  type="text"
                  placeholder="Type Text"
                  v-model="form.insuranceCompany"
                ></b-form-input>
              </b-form-group>
            </b-col> 
            <b-col md="12">
              <b-form-group label="Insurance Address" label-for="insuranceAddress">
                <b-form-textarea rows="2" placeholder="Text Type" class="mt-2" v-model="form.insuranceAddress" /> 
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Replacement Type"
                label-for="replacementType"
              >
                <b-form-select
                  plain
                  v-model="form.replacementType"
                  size="sm"
                  name="replacementType"
                >
                  <template v-slot:first>
                    <b-form-select-option value="FUNDS">Funds</b-form-select-option>
                  </template>
                  <b-form-select-option value="FLEET">Fleet</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="`Currency of ${form.replacementType}`" label-for="currencyId">
                <v-select 
                  v-model="form.currencyId" 
                  label="text" 
                  :options="optionsCurrency" 
                  :reduce="optionsCurrency => optionsCurrency.value" 
                  placeholder="Select..." 
                  style="background-color:#ffffff;"
                  class="mr-2 w-100"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="`Value of ${form.replacementType}`" label-for="valueOfFund">
                <b-form-input
                  name="valueOfFund"
                  type="number"
                  placeholder="0"
                  v-model="form.valueOfFund"
                ></b-form-input>
                <small v-if="optionsCurrency?.find(obj => obj?.value === form.currencyId)?.text === 'IDR'">{{ formatCurrency(Number(form.valueOfFund)) }}</small>
                <small v-else>{{ formatUSD(Number(form.valueOfFund)) }}</small>
              </b-form-group>
            </b-col>  
            <b-col md="6">
              <b-form-group label="Paid To" label-for="paidTo">
                <b-form-input
                  name="paidTo"
                  type="text"
                  placeholder="Type Text"
                  v-model="form.paidTo"
                ></b-form-input>
              </b-form-group>
            </b-col> 
          </b-row>
        </template>

        <h5 class="text-primary"><strong>File Attachment</strong></h5>
        <div class="d-flex w-100 border my-1" />
        <b-row>
          <b-col md="12">
            <div class="overflow-auto" style="max-height: 300px">
              <table class="table table-borderless">
                <thead>
                  <tr v-for="(val, index) in form.attachments" :key="index">
                    <td>
                      <b-form-group label="File Name" label-for="rate">
                        <b-form-input
                          name="file_name"
                          type="text"
                          placeholder="Type Text..."
                          v-model="val.name"
                        ></b-form-input>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="Upload Document" label-for="file">
                        <b-form-file
                          name="file"
                          type="file"
                          v-model="val.file"
                          @change="onUploadFile($event, index)"
                          accept="application/*"
                        ></b-form-file>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="Remarks" label-for="remarks">
                        <b-form-input
                          name="remarks"
                          type="text"
                          placeholder="Type Text..."
                          v-model="val.remarks"
                        ></b-form-input>
                      </b-form-group>
                    </td>
                    <td style="width:10px">
                      <div class="px-3 py-1 rounded-lg bg-danger mt-3" style="cursor:pointer" @click="form.attachments.splice(index, 1)">
                        <i class="fa fa-trash" />
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </b-col>
        </b-row>
        <button type="button" block class="btn w-100" :style="{ backgroundColor: '#2BDCAA', color: 'white' }" block @click="form.attachments.push({
          file_name:'',
          file:'',
          remarks:'',
        })"><i class="fa fa-plus-circle" /> Add More Data</button>
      </b-col>
    </b-row> 

    <b-row class="px-4">
      <b-col md="12">
        <div class="d-flex w-100 border my-4" />
      </b-col>
      <b-col md="6">
        <b-button @click="onOpenSlideFleetStatus(null)" variant="outline-primary" type="button" block class="w-100" block>Cancel & Close</b-button>
      </b-col> 
      <b-col md="6">
        <b-button variant="primary" type="submit" block class="w-100" block>Submit</b-button>
      </b-col>
    </b-row>
  </form>
</template>

<script>

import { crewingActions, filesActions, busdevActions, vehiclesActions, opsV2Actions, miActions } from '@src/Utils/helper'
import numberCode from '@src/core/json/number-code.json';

export default {
  name: 'SlideFleetStatusUpdate',
  props: {
    fleetId: {
      default: null,
      type: Number,
    },
    statusFleet: {
      default: '',
      type: String,
    },
    onOpenSlideFleetStatus: {
      default: () => {},
      type: Function,
    },
  },
  data() {
    return {
      optionsFilterType: [
        {
        label: 'Active',
        color: 'primary',
        value: 'ACTIVE',
        },
        {
          label: 'Sell',
          color: 'primary',
          value: 'SELL',
        },
        {
          label: 'On Loan',
          color: 'primary',
          value: 'ON_LOAN',
        },{
          label: 'Sinked',
          color: 'primary',
          value: 'SINKED',
        }
      ],
      optionsStatusInsurance: [
        {
          label: 'No Covered',
          color: 'primary',
          value: 'NO_COVERED',
        },
        {
          label: 'Half Risk',
          color: 'primary',
          value: 'HALF_RISK',
        },
        {
          label: 'Claim All Risk',
          color: 'primary',
          value: 'CLAIM_ALL_RISK',
        },
      ],
      statusList: [
        {
          name:'Active',
          code: 'active'
        },{
          name:'Towards',
          code: 'toward'
        },{
          name:'Overdue',
          code: 'overdue'
        },{
          name:'Next 3 Month',
          code: 'next-3-month'
        },{
          name:'Postponed',
          code: 'postponed'
        },{
          name:'Finish',
          code: 'finish'
        }
      ],

      vehicleData: null,
      loadingCrew: false,
      checked: false,
      saving: false,
      _loading: false,
      loadingProvinsi: false,
      loadingKota: false,
      loadingKecamatan: false,
      loadingKelurahan: false,
      option: {
        provience: [],
        cities: [],
        districts: [],
        villages: [],
      },
      optionsNumberCode:[],
      optionsCurrency:[],
      crewList:[],
      linkToCaseOptions:[],
      filterType: this.statusFleet || 'ACTIVE', // ACTIVE, SELL, ON_LOAN, SINKED

      form: {
        "vehicleId": null,
        "dateOfSell": "",
        "typeOfSell": "DIRECT", // DIRECT, AUCTION, BROKER
        "typeOfOwner": "COMPANY", // PERSONAL, COMPANY
        "nameOfOwner": "",
        "numberCode": "ID",
        "phoneNumber": "",
        "nationality": "wni", // wni, wna
        "idNumber": "",
        "npwpNumber": "",
        "nib": "",
        "email": "",
        "dateOfLoan": null,
        "planOfReturnLoan": null,
        "dateOfAccident": null,
        "latitude": "",
        "longitude": "",
        "province": "",
        "city": "",
        "district": "",
        "subDistrict": "",
        "address": "",
        "chronology":"",
        vehicleId:null,
        linkToCaseId:null,
        "currencyId": 1,
        "rate": 1,
        "tax": "INCLUDE_PPN", // INCLUDE_PPN, EXCLUDE_PPN
        "priceOfBuy": 0,
        "priceOfSell": 0,
        "reason": "",
        "sinkedStatus": "NO_COVERED", // NO_COVERED, HALF_RISK, CLAIM_ALL_RISK
        insuranceCompany:"",
        insuranceAddress:"",
        replacementType:"FUNDS", // FUNDS, FLEET
        valueOfFund:0,
        paidTo:"",
        "attachments": [
          {
            "name": "",
            "remark": ""
          },
        ],
        "crewOnDuties": [
          {
            "crewName": "",
            "crewPosition": ""
          },
        ]
      },
      formOnloan: {
        "vehicleId": null,
        "dateOfLoan": "",
        "planOfReturnLoan": "",
        "typeOfOwner": "PERSONAL", // PERSONAL
        "nameOfOwner": "",
        "phoneNumber": "",
        "nationality": "wni", // wni, wna
        "idNumber": "",
        "npwpNumber": "",
        "nib": "",
        "email": "",
        "province": "",
        "city": "",
        "district": "",
        "subDistrict": "",
        "address": "",
        "currencyId": 1,
        "rate": 1,
        "tax": "INCLUDE_PPN", // INCLUDE_PPN, EXCLUDE_PPN
        "priceOfLoan": 0,
        "reason": "",
        "attachments": [
          {
            "name": "",
            "remark": ""
          },
        ]
      },
      formSinked:{
        "vehicleId": null,
        "dateOfAccident": "",
        "linkToCaseId": null,
        "latitude": "",
        "longitude": "",
        "province": "",
        "city": "",
        "district": "",
        "subDistrict": "",
        "address": "",
        "chronology": "",
        "sinkedStatus": "NO_COVERED", // NO_COVERED, HALF_RISK, CLAIM_ALL_RISK
        "insuranceCompany": "",
        "insuranceAddress": "",
        replacementType:"FUNDS", // FUNDS, FLEET
        "valueOfFund": 0,
        "paidTo": "",
        "currencyId": 1,
        "attachments": [
          {
            "name": "",
            "remark": ""
          },
        ],
        "crewOnDuties": [
          {
            "crewName": "",
            "crewPosition": ""
          },
        ]
      },
    }
  },
  async mounted() {
    this.loadingCrew = true
    await this.fetchProvinsi()
    await this.getCurrency()
    await this.getNumberCode()
    await this.getAllMi()
    await this.getCrewList()
    this.loadingCrew = false
  },
  watch:{
    'fleetId': {
      deep: true,
      immediate: true,
      handler: async function (value) {
        if (value) {
          this.filterType = this.statusFleet || 'ACTIVE'
          await this.fetchVehicleDetails()
        }
      },
    },
  },
  methods:{
    ...filesActions,
    ...crewingActions,
    ...busdevActions,
    ...vehiclesActions,
    ...opsV2Actions,
    ...miActions,
    async onUploadFile (event, index) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        this.form.attachments[index].name = res.data.fileName
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    checkKeyNumber(event) {
      const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
      if (!allowedKeys.includes(event.key) && !/^[0-9]$/.test(event.key)) {
        event.preventDefault(); // Prevent non-numeric input and numbers below 0
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    formatUSD(value){
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    },
    async getCrewList() {
      const { status, data } = await this.fetchListCrewSelectBox()
      if (status && data && data?.length > 0) {
        this.crewList = data?.map((val) => {
          return {
            value: `${val?.fullname}`,
            label: val?.fullname,
            position: val?.position?.name
          }
        })
      }
    },
    async getAllMi () {
      const { data } = await this.getMi({
        with: 'type_loss,vehicle',
        pagination: 0
      })

      if (data.length) {
        this.linkToCaseOptions = data.map(val => ({
          value: val.id,
          text: `${val.vehicle.name} - ${val.type_form.split('_').join(' ')} - ${val.type_loss.name}`
        }))
      }
    },
    async getNumberCode() {
      numberCode.map((state) => {
        this.optionsNumberCode.push({
          dial_code:state?.dial_code,
          value: state.code,
          text: `${state.emoji}${state?.dial_code}`,
        })
      })
    },
    async getCurrency() {
      let res = await this.fetchCurrency()
      if (res.data.status === true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsCurrency.push({
              value: state.id,
              text: state.alias,
            })
          })
        }
      }

      this.form.currencyId = this.optionsCurrency?.find(obj => obj.text === 'IDR')?.value
    },  
    async fetchProvinsi() {
      this._loading = true
      let res = await this.fetchProvience()

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.option.provience.push({
              id: state.id,
              name: state.name,
            })
          })
          this._loading = false
        }
      }

      this._loading = false
    },
    async fetchKota(id) {
      this.loadingKota = true
      let method = 'fetchCities'

      const { data } = await this[method]({ id: id })
      this.option.cities = []
      this.option.districts = []
      this.option.villages = []

      for (const property in data) {
        this.option.cities.push({
          id: property,
          name: data[property],
        })
      }

      this.loadingKota = false
    },
    async fetchKecamatan(id) {
      this.loadingKecamatan = true
      let method = 'fetchDistricts'

      const { data } = await this[method]({ id: id })
      this.option.districts = []
      this.option.villages = []
      for (const property in data) {
        this.option.districts.push({
          id: property,
          name: data[property],
        })
      }

      this.loadingKecamatan = false
    },
    async fetchKelurahan(id) {
      this.loadingKelurahan = true
      let method = 'fetchVillages'

      const { data } = await this[method]({ id: id })
      this.option.villages = []
      for (const property in data) {
        this.option.villages.push({
          id: property,
          name: data[property],
        })
      }

      this.loadingKelurahan = false
    },
    onSelect(e, name) {
      if (name == 'provinsi') {
        const { id } = this.option.provience.find((el) => el.name === e)
        this.fetchKota(id)
      }
      if (name == 'kabupaten') {
        const { id } = this.option.cities.find((el) => el.name === e)
        this.fetchKecamatan(id)
      }
      if (name == 'kecamatan') {
        const { id } = this.option.districts.find((el) => el.name === e)
        this.fetchKelurahan(id)
      }
    },
    async fetchOptions() {
      if (this.form.province) {
        const { id } = this.option.provience.find(
          (el) => el.name === this.form.province
        )
        await this.fetchKota(id)
      }
      if (this.form.city) {
        const { id } = this.option.cities.find(
          (el) => el.name === this.form.city
        )
        await this.fetchKecamatan(id)
      }
      if (this.form.district) {
        const { id } = this.option.districts.find(
          (el) => el.name === this.form.district
        )
        await this.fetchKelurahan(id)
      }
    },  
    
    async fetchVehicleDetails() {
      let params = {vehicleId: Number(this.fleetId)}
      let res = await this.getVehicleDetails(params)

      if(res.data?.data) {
        this.vehicleData = res.data?.data
        this.form = {
          ...res.data?.data
        }
      }
    },

    async onSave() {
      let payload = {
        data: {
          ...this.form,
          vehicleId: Number(this.fleetId),
          phoneNumber: `${this.optionsNumberCode?.find(obj => obj.value === this.form.numberCode)?.dial_code}${this.form.phoneNumber}`,
          filterType: this.filterType
        }
      }

      this.saving = true

      if(this.filterType === 'ACTIVE') {
        payload = {
          vehicleId: Number(this.fleetId),
          filterType: this.filterType
        }
      }

      let res
      if(!this.vehicleData){
        res = await this.createVehicleStatusDetails({data: payload})
      } else {
        res = await this.updateVehicleStatusDetails({data: payload, id: this.vehicleData?.id})
      }

      if (res.status !== 'success') {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.saving = false
        return
      }

      // await this.updateVehicle({
      //   vehicle_id: Number(this.fleetId),
      //   data: {status: this.filterType}
      // })

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.onOpenSlideFleetStatus(null)
      this.saving = false
    },
  
  }
}
</script>


var render = function render(){
  var _vm$optionsCurrency, _vm$optionsCurrency$f, _vm$optionsCurrency2, _vm$optionsCurrency2$, _attrs, _vm$optionsCurrency3, _vm$optionsCurrency3$, _attrs2, _attrs3, _attrs4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    staticClass: "m-4"
  }, [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter Type",
      "label-for": "type"
    }
  }, [_vm._l(_vm.optionsFilterType, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      staticClass: "custom-radio-color-checked",
      attrs: {
        "inline": "",
        "color": item.color,
        "name": "color",
        "value": item.value,
        "disabled": item.disabled,
        "required": ""
      },
      model: {
        value: _vm.filterType,
        callback: function callback($$v) {
          _vm.filterType = $$v;
        },
        expression: "filterType"
      }
    }, [_c('span', [_vm._v(_vm._s(item.label))])])];
  })], 2)], 1), _vm.filterType !== 'ACTIVE' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h5', {
    staticClass: "text-primary"
  }, [_c('strong', [_vm._v("Detail Information")])]), _c('div', {
    staticClass: "d-flex w-100 border my-1"
  }), _c('b-row', {
    staticClass: "mb-2"
  }, [_vm.filterType === 'SELL' ? [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Sale*",
      "label-for": "sale"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "name": "dateOfSell",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.dateOfSell,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dateOfSell", $$v);
      },
      expression: "form.dateOfSell"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Sale*",
      "label-for": "sale"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "name": "type_sell"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": "DIRECT"
          }
        }, [_vm._v("Direct")])];
      },
      proxy: true
    }], null, false, 232862117),
    model: {
      value: _vm.form.typeOfSell,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "typeOfSell", $$v);
      },
      expression: "form.typeOfSell"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": "AUCTION"
    }
  }, [_vm._v("Auction")]), _c('b-form-select-option', {
    attrs: {
      "value": "BROKER"
    }
  }, [_vm._v("Broker")])], 1)], 1)], 1)] : _vm._e(), _vm.filterType === 'ON_LOAN' ? [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Loan*",
      "label-for": "sale"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "name": "dateOfSell",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.dateOfLoan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dateOfLoan", $$v);
      },
      expression: "form.dateOfLoan"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Plan of Return Loan*",
      "label-for": "sale"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "name": "planOfReturnLoan",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.planOfReturnLoan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "planOfReturnLoan", $$v);
      },
      expression: "form.planOfReturnLoan"
    }
  })], 1)], 1)] : _vm._e(), _vm.filterType === 'SINKED' ? [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Accident",
      "label-for": "sale"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "name": "dateOfAccident",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.dateOfAccident,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dateOfAccident", $$v);
      },
      expression: "form.dateOfAccident"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Link to Case (Marine Insurance)",
      "label-for": "sale"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.linkToCaseOptions,
      "reduce": function reduce(option) {
        return option.value;
      },
      "clearable": false
    },
    model: {
      value: _vm.form.linkToCaseId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "linkToCaseId", $$v);
      },
      expression: "form.linkToCaseId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Latitude",
      "label-for": "latitude"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "name": "latitude",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.latitude,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "latitude", $$v);
      },
      expression: "form.latitude"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Longitude",
      "label-for": "longitude"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "name": "longitude",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.longitude,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "longitude", $$v);
      },
      expression: "form.longitude"
    }
  })], 1)], 1)] : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Owner*",
      "label-for": "sale"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "name": "typeOfOwner"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": "COMPANY"
          }
        }, [_vm._v("Company / Institution / Organization")])];
      },
      proxy: true
    }], null, false, 2296693898),
    model: {
      value: _vm.form.typeOfOwner,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "typeOfOwner", $$v);
      },
      expression: "form.typeOfOwner"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": "PERSONAL"
    }
  }, [_vm._v("Persoal")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name of Owner*",
      "label-for": "nameOfOwner"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "name": "nameOfOwner",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.nameOfOwner,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameOfOwner", $$v);
      },
      expression: "form.nameOfOwner"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phone Number",
      "label-for": "owner"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optionsNumberCode,
      "reduce": function reduce(option) {
        return option.value;
      },
      "clearable": false
    },
    model: {
      value: _vm.form.numberCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "numberCode", $$v);
      },
      expression: "form.numberCode"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phoneNumber,
      expression: "form.phoneNumber"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.form.phoneNumber
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "phoneNumber", $event.target.value);
      }
    }
  })])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nationality",
      "label-for": "nationality"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "name": "nationality"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": "wni"
          }
        }, [_vm._v("Warga Negara Indonesia (WNI)")]), _c('b-form-select-option', {
          attrs: {
            "value": "wna"
          }
        }, [_vm._v("Warga Negara Asing (WNA)")])];
      },
      proxy: true
    }], null, false, 1596671917),
    model: {
      value: _vm.form.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nationality", $$v);
      },
      expression: "form.nationality"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NIB",
      "label-for": "owner"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "name": "nib",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.nib,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nib", $$v);
      },
      expression: "form.nib"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email Address",
      "label-for": "email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "name": "email",
      "placeholder": "Type Text..."
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1)], 2), _c('h5', {
    staticClass: "text-primary"
  }, [_c('strong', [_vm._v("Address")])]), _c('div', {
    staticClass: "d-flex w-100 border my-1"
  }), _vm.form.nationality === 'wni' ? _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Provinsi",
      "label-for": "Provinsi"
    }
  }, [_c('v-select', {
    staticClass: "style-chooser",
    attrs: {
      "id": "provinsi",
      "label": "name",
      "options": _vm.option.provience,
      "reduce": function reduce(option) {
        return option.name;
      },
      "placeholder": "Pilih Provinsi",
      "clearable": false,
      "disabled": _vm.checked
    },
    on: {
      "input": function input(e) {
        return _vm.onSelect(e, 'provinsi');
      }
    },
    model: {
      value: _vm.form.province,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "province", $$v);
      },
      expression: "form.province"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Kota / Kabupaten",
      "label-for": "Kota"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "Kota",
      "label": "name",
      "options": _vm.option.cities,
      "reduce": function reduce(option) {
        return option.name;
      },
      "placeholder": _vm.form.district ? 'Pilih Kabupaten' : 'Pilih Provinsi Terlebih Dahulu',
      "clearable": false,
      "disabled": _vm.checked || this.loadingKota || !_vm.form.province
    },
    on: {
      "input": function input(e) {
        return _vm.onSelect(e, 'kabupaten');
      }
    },
    model: {
      value: _vm.form.city,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "city", $$v);
      },
      expression: "form.city"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Kecamatan",
      "label-for": "Kecamatan"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "Kecamatan",
      "label": "name",
      "options": _vm.option.districts,
      "reduce": function reduce(option) {
        return option.name;
      },
      "placeholder": _vm.form.city ? 'Pilih Kecamatan' : 'Pilih Kabupaten Terlebih Dahulu',
      "clearable": false,
      "disabled": _vm.checked || this.loadingKecamatan || !_vm.form.city
    },
    on: {
      "input": function input(e) {
        return _vm.onSelect(e, 'kecamatan');
      }
    },
    model: {
      value: _vm.form.district,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "district", $$v);
      },
      expression: "form.district"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Kelurahan",
      "label-for": "Kelurahan"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "Kecamatan",
      "label": "name",
      "options": _vm.option.districts,
      "reduce": function reduce(option) {
        return option.name;
      },
      "placeholder": _vm.form.district ? 'Pilih Kelurahan' : 'Pilih Kecamatan Terlebih Dahulu',
      "clearable": false,
      "disabled": _vm.checked || this.loadingKelurahan || !_vm.form.district
    },
    model: {
      value: _vm.form.subDistrict,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subDistrict", $$v);
      },
      expression: "form.subDistrict"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Kode POS",
      "label-for": "kode_pos"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "height": "35px"
    },
    attrs: {
      "id": "kode_pos",
      "type": "number",
      "name": "kode_pos",
      "disabled": _vm.checked,
      "required": ""
    },
    on: {
      "keydown": _vm.checkKeyNumber
    },
    model: {
      value: _vm.form.kode_pos,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kode_pos", $$v);
      },
      expression: "form.kode_pos"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-12",
    attrs: {
      "label": "Alamat Domisili",
      "label-for": "alamat_domisili"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "alamat_domisili",
      "name": "alamat_domisili",
      "rows": "1",
      "disabled": _vm.checked,
      "required": "",
      "placeholder": "Alamat..."
    },
    model: {
      value: _vm.form.alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "alamat", $$v);
      },
      expression: "form.alamat"
    }
  })], 1)], 1) : _c('b-form-group', {
    staticClass: "col-md-12",
    attrs: {
      "label": "Alamat Domisili",
      "label-for": "alamat_domisili"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "alamat_domisili",
      "name": "alamat_domisili",
      "rows": "1",
      "disabled": _vm.checked,
      "required": "",
      "placeholder": "Alamat..."
    },
    model: {
      value: _vm.form.alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "alamat", $$v);
      },
      expression: "form.alamat"
    }
  })], 1), _vm.filterType !== 'SINKED' ? [_c('h5', {
    staticClass: "text-primary"
  }, [_c('strong', [_vm._v("Other Information")])]), _c('div', {
    staticClass: "d-flex w-100 border my-1"
  }), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currency",
      "label-for": "currencyId"
    }
  }, [_c('v-select', {
    staticClass: "mr-2 w-100",
    staticStyle: {
      "background-color": "#ffffff"
    },
    attrs: {
      "label": "text",
      "options": _vm.optionsCurrency,
      "reduce": function reduce(optionsCurrency) {
        return optionsCurrency.value;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.currencyId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currencyId", $$v);
      },
      expression: "form.currencyId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rate",
      "label-for": "rate"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "rate",
      "name": "rate",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.rate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rate", $$v);
      },
      expression: "form.rate"
    }
  }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(_vm.form.rate))))])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax",
      "label-for": "tax"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "inline": ""
    },
    model: {
      value: _vm.form.tax,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tax", $$v);
      },
      expression: "form.tax"
    }
  }, [_c('b-form-radio', {
    key: "INCLUDE_PPN",
    attrs: {
      "value": "INCLUDE_PPN",
      "name": "INCLUDE_PPN"
    }
  }, [_vm._v("Include PPN")]), _c('b-form-radio', {
    key: "EXCLUDE_PPN",
    attrs: {
      "value": "EXCLUDE_PPN",
      "name": "EXCLUDE_PPN"
    }
  }, [_vm._v("Exclude PPN")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Price of Buy",
      "label-for": "price_buy"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "price_buy",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.priceOfBuy,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "priceOfBuy", $$v);
      },
      expression: "form.priceOfBuy"
    }
  }), ((_vm$optionsCurrency = _vm.optionsCurrency) === null || _vm$optionsCurrency === void 0 ? void 0 : (_vm$optionsCurrency$f = _vm$optionsCurrency.find(function (obj) {
    return (obj === null || obj === void 0 ? void 0 : obj.value) === _vm.form.currencyId;
  })) === null || _vm$optionsCurrency$f === void 0 ? void 0 : _vm$optionsCurrency$f.text) === 'IDR' ? _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(_vm.form.priceOfBuy))))]) : _c('small', [_vm._v(_vm._s(_vm.formatUSD(Number(_vm.form.priceOfBuy))))])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Price of Sell",
      "label-for": "priceOfSell"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "priceOfSell",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.priceOfSell,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "priceOfSell", $$v);
      },
      expression: "form.priceOfSell"
    }
  }), ((_vm$optionsCurrency2 = _vm.optionsCurrency) === null || _vm$optionsCurrency2 === void 0 ? void 0 : (_vm$optionsCurrency2$ = _vm$optionsCurrency2.find(function (obj) {
    return (obj === null || obj === void 0 ? void 0 : obj.value) === _vm.form.currencyId;
  })) === null || _vm$optionsCurrency2$ === void 0 ? void 0 : _vm$optionsCurrency2$.text) === 'IDR' ? _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(_vm.form.priceOfSell))))]) : _c('small', [_vm._v(_vm._s(_vm.formatUSD(Number(_vm.form.priceOfBuy))))])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reason",
      "label-for": "chronology"
    }
  }, [_c('wysiwyg', {
    style: {
      height: '200px'
    },
    model: {
      value: _vm.form.chronology,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "chronology", $$v);
      },
      expression: "form.chronology"
    }
  })], 1)], 1)], 1)] : _vm._e(), _vm.filterType === 'SINKED' ? [_c('h5', {
    staticClass: "text-primary"
  }, [_c('strong', [_vm._v("Crew on Duties")])]), _c('div', {
    staticClass: "d-flex w-100 border my-1"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-borderless table-sm"
  }, [_c('thead', _vm._l(_vm.form.crewOnDuties, function (val, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('b-form-group', {
      staticClass: "d-flex flex-column",
      attrs: {
        "label": "Name of Crew",
        "label-for": "rate"
      }
    }, [_c('v-select', {
      attrs: {
        "label": "label",
        "options": _vm.crewList,
        "reduce": function reduce(option) {
          return option.value;
        },
        "clearable": false
      },
      on: {
        "input": function input() {
          var _vm$crewList$find;
          val.crewPosition = (_vm$crewList$find = _vm.crewList.find(function (obj) {
            return obj.value === val.crewName;
          })) === null || _vm$crewList$find === void 0 ? void 0 : _vm$crewList$find.position;
        }
      },
      model: {
        value: val.crewName,
        callback: function callback($$v) {
          _vm.$set(val, "crewName", $$v);
        },
        expression: "val.crewName"
      }
    }), _vm.loadingCrew ? _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('i', {
      staticClass: "spinner-border spinner-border-sm text-primary mr-2"
    }), _c('span', {
      staticClass: "text-primary"
    }, [_vm._v("Fetching Crew, please wait a moment.")])]) : _vm._e()], 1)], 1), _c('td', [_c('b-form-group', {
      attrs: {
        "label": "Crew Position",
        "label-for": "crewPosition"
      }
    }, [_c('b-form-input', {
      attrs: {
        "name": "crewPosition",
        "type": "text",
        "placeholder": "Type Text..."
      },
      model: {
        value: val.crewPosition,
        callback: function callback($$v) {
          _vm.$set(val, "crewPosition", $$v);
        },
        expression: "val.crewPosition"
      }
    })], 1)], 1), _c('td', {
      staticStyle: {
        "width": "10px"
      }
    }, [_c('div', {
      staticClass: "px-3 py-1 rounded-lg bg-danger mt-3",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.crewOnDuties.splice(index, 1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])])]);
  }), 0)])])], 1), _c('button', {
    staticClass: "btn w-100 mb-4",
    style: {
      backgroundColor: '#2BDCAA',
      color: 'white'
    },
    attrs: (_attrs = {
      "type": "button",
      "block": ""
    }, _attrs["block"] = "", _attrs),
    on: {
      "click": function click($event) {
        return _vm.form.crewOnDuties.push({
          crewName: '',
          crewPosition: ''
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus-circle"
  }), _vm._v(" Add More Data")])] : _vm._e(), _vm.filterType === 'SINKED' ? [_c('h5', {
    staticClass: "text-primary"
  }, [_c('strong', [_vm._v("Insurance Information")])]), _c('div', {
    staticClass: "d-flex w-100 border my-1"
  }), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "currencyId"
    }
  }, [_vm._l(_vm.optionsStatusInsurance, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      staticClass: "custom-radio-color-checked",
      attrs: {
        "inline": "",
        "color": item.color,
        "name": "color",
        "value": item.value,
        "disabled": item.disabled,
        "required": ""
      },
      model: {
        value: _vm.form.sinkedStatus,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "sinkedStatus", $$v);
        },
        expression: "form.sinkedStatus"
      }
    }, [_c('span', [_vm._v(_vm._s(item.label))])])];
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Insurance Company",
      "label-for": "insuranceCompany"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "insuranceCompany",
      "type": "text",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.insuranceCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "insuranceCompany", $$v);
      },
      expression: "form.insuranceCompany"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Insurance Address",
      "label-for": "insuranceAddress"
    }
  }, [_c('b-form-textarea', {
    staticClass: "mt-2",
    attrs: {
      "rows": "2",
      "placeholder": "Text Type"
    },
    model: {
      value: _vm.form.insuranceAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "insuranceAddress", $$v);
      },
      expression: "form.insuranceAddress"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Replacement Type",
      "label-for": "replacementType"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "name": "replacementType"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": "FUNDS"
          }
        }, [_vm._v("Funds")])];
      },
      proxy: true
    }], null, false, 3883392037),
    model: {
      value: _vm.form.replacementType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "replacementType", $$v);
      },
      expression: "form.replacementType"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": "FLEET"
    }
  }, [_vm._v("Fleet")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currency of ".concat(_vm.form.replacementType),
      "label-for": "currencyId"
    }
  }, [_c('v-select', {
    staticClass: "mr-2 w-100",
    staticStyle: {
      "background-color": "#ffffff"
    },
    attrs: {
      "label": "text",
      "options": _vm.optionsCurrency,
      "reduce": function reduce(optionsCurrency) {
        return optionsCurrency.value;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.currencyId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currencyId", $$v);
      },
      expression: "form.currencyId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Value of ".concat(_vm.form.replacementType),
      "label-for": "valueOfFund"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "valueOfFund",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.valueOfFund,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "valueOfFund", $$v);
      },
      expression: "form.valueOfFund"
    }
  }), ((_vm$optionsCurrency3 = _vm.optionsCurrency) === null || _vm$optionsCurrency3 === void 0 ? void 0 : (_vm$optionsCurrency3$ = _vm$optionsCurrency3.find(function (obj) {
    return (obj === null || obj === void 0 ? void 0 : obj.value) === _vm.form.currencyId;
  })) === null || _vm$optionsCurrency3$ === void 0 ? void 0 : _vm$optionsCurrency3$.text) === 'IDR' ? _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(_vm.form.valueOfFund))))]) : _c('small', [_vm._v(_vm._s(_vm.formatUSD(Number(_vm.form.valueOfFund))))])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Paid To",
      "label-for": "paidTo"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "paidTo",
      "type": "text",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.paidTo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paidTo", $$v);
      },
      expression: "form.paidTo"
    }
  })], 1)], 1)], 1)] : _vm._e(), _c('h5', {
    staticClass: "text-primary"
  }, [_c('strong', [_vm._v("File Attachment")])]), _c('div', {
    staticClass: "d-flex w-100 border my-1"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto",
    staticStyle: {
      "max-height": "300px"
    }
  }, [_c('table', {
    staticClass: "table table-borderless"
  }, [_c('thead', _vm._l(_vm.form.attachments, function (val, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('b-form-group', {
      attrs: {
        "label": "File Name",
        "label-for": "rate"
      }
    }, [_c('b-form-input', {
      attrs: {
        "name": "file_name",
        "type": "text",
        "placeholder": "Type Text..."
      },
      model: {
        value: val.name,
        callback: function callback($$v) {
          _vm.$set(val, "name", $$v);
        },
        expression: "val.name"
      }
    })], 1)], 1), _c('td', [_c('b-form-group', {
      attrs: {
        "label": "Upload Document",
        "label-for": "file"
      }
    }, [_c('b-form-file', {
      attrs: {
        "name": "file",
        "type": "file",
        "accept": "application/*"
      },
      on: {
        "change": function change($event) {
          return _vm.onUploadFile($event, index);
        }
      },
      model: {
        value: val.file,
        callback: function callback($$v) {
          _vm.$set(val, "file", $$v);
        },
        expression: "val.file"
      }
    })], 1)], 1), _c('td', [_c('b-form-group', {
      attrs: {
        "label": "Remarks",
        "label-for": "remarks"
      }
    }, [_c('b-form-input', {
      attrs: {
        "name": "remarks",
        "type": "text",
        "placeholder": "Type Text..."
      },
      model: {
        value: val.remarks,
        callback: function callback($$v) {
          _vm.$set(val, "remarks", $$v);
        },
        expression: "val.remarks"
      }
    })], 1)], 1), _c('td', {
      staticStyle: {
        "width": "10px"
      }
    }, [_c('div', {
      staticClass: "px-3 py-1 rounded-lg bg-danger mt-3",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.attachments.splice(index, 1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])])]);
  }), 0)])])])], 1), _c('button', {
    staticClass: "btn w-100",
    style: {
      backgroundColor: '#2BDCAA',
      color: 'white'
    },
    attrs: (_attrs2 = {
      "type": "button",
      "block": ""
    }, _attrs2["block"] = "", _attrs2),
    on: {
      "click": function click($event) {
        return _vm.form.attachments.push({
          file_name: '',
          file: '',
          remarks: ''
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus-circle"
  }), _vm._v(" Add More Data")])], 2) : _vm._e()], 1), _c('b-row', {
    staticClass: "px-4"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 border my-4"
  })]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-button', {
    staticClass: "w-100",
    attrs: (_attrs3 = {
      "variant": "outline-primary",
      "type": "button",
      "block": ""
    }, _attrs3["block"] = "", _attrs3),
    on: {
      "click": function click($event) {
        return _vm.onOpenSlideFleetStatus(null);
      }
    }
  }, [_vm._v("Cancel & Close")])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-button', {
    staticClass: "w-100",
    attrs: (_attrs4 = {
      "variant": "primary",
      "type": "submit",
      "block": ""
    }, _attrs4["block"] = "", _attrs4)
  }, [_vm._v("Submit")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
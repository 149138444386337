<template>
  <div v-if="detailMi" class="px-3">
    <form @submit.prevent="onSaveHeader">
      <b-row class="mb-3">
        <b-col md="12" class="text-right">
          <b-button variant="primary" @click="isEditMode = !isEditMode" >{{ isEditMode ? 'Preview' : 'Edit' }} Management Summary</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-3">
          <h6 class="card-title text-primary border-bottom pb-2"
            ><b>INFORMATION OF ACCIDENT/INCIDENT</b></h6
          >
          <b-row>
            <b-col md="3">
              <b-form-group label="Company *" label-for="company">
                <h6 style="font-weight: 500">{{ detailMi.company.company }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Fleet Name *" label-for="fleets">
                <h6 style="font-weight: 500">{{ detailMi.vehicle.name }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Date of Loss (DOL) *" label-for="dol">
                <h6 style="font-weight: 500">{{ detailMi.date_of_loss }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Place of Loss (POL) *" label-for="pol">
                <h6 style="font-weight: 500">{{ detailMi.place_of_loss }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Type of Coverage *" label-for="coverage">
                <h6 style="font-weight: 500">{{
                  detailMi.type_coverage.name
                }}</h6>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Type of Loss *" label-for="loss">
                <h6 style="font-weight: 500">{{ detailMi.type_loss.name }}</h6>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="12" class="mb-3">
          <h6 class="card-title text-primary border-bottom pb-2"
            ><b>COVERAGE INFORMATION OF THE VESSEL</b></h6
          >
          <b-row>
            <b-col md="3">
              <b-form-group label="Insurer" label-for="insurer">
                <template v-if="!isEditMode">
                  <p class="form-control-static">{{
                    form.insurer_id || '-'
                  }}</p>
                </template>
                <b-form-input
                  v-else
                  required
                  v-model="form.insurer_id"
                  type="text"
                  :disabled="permission_user === 'MANAGEMENT'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Policy Number" label-for="name">
                <template v-if="!isEditMode">
                  <p class="form-control-static">{{
                    form.policy_number || '-'
                  }}</p>
                </template>
                <b-form-input
                  v-else
                  required
                  v-model="form.policy_number"
                  type="text"
                  :disabled="permission_user === 'MANAGEMENT'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Periode of Coverage" label-for="position">
                <div class="d-flex">
                  <h6
                    class="form-control-static"
                    v-for="(item, i) in form.coverage_period"
                    :key="i"
                    >{{ item || '-' }}</h6
                  >
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Surveyor" label-for="insurer">
                <template v-if="!isEditMode">
                  <p class="form-control-static">{{ form.surveyor || '-' }}</p>
                </template>
                <b-form-input
                  v-else
                  type="text"
                  required
                  v-model="form.surveyor"
                  :disabled="permission_user === 'MANAGEMENT'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Adjuster" label-for="insurer">
                <template v-if="!isEditMode">
                  <p class="form-control-static">{{ form.adjuster || '-' }}</p>
                </template>
                <b-form-input
                  v-else
                  type="text"
                  required
                  v-model="form.adjuster"
                  :disabled="permission_user === 'MANAGEMENT'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Total Sum Insured</label>
                <template v-if="!isEditMode">
                  <p class="form-control-static"
                    >{{form.currency_insurer_id
                        ? currencies.find(
                            (currency) =>
                              currency.id === form.currency_sum_insurer_id
                          )?.alias
                        : '-'
                    }}
                    {{ form.sum_insured || '-' }}</p
                  >
                </template>
                <b-row v-else>
                  <b-col md="4" class="pr-2">
                    <v-select
                      v-model="form.currency_sum_insurer_id"
                      label="alias"
                      style="width: 100%"
                      :options="currencies"
                      :reduce="(currency) => currency.id"
                      :disabled="permission_user === 'MANAGEMENT'"
                    >
                    </v-select>
                  </b-col>
                  <b-col md="8" class="pl-2">
                    <InputCurrency
                      :disabled="permission_user === 'MANAGEMENT'"
                      placeholder="Input Currency"
                      v-model="form.sum_insured"
                    />
                    <!-- <b-form-text
                      v-if="form.currency_sum_insurer_id === 3"
                      class="text-muted"
                      >{{ formatCurrency(form.sum_insured) }}</b-form-text
                    > -->
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Limit of Liability</label>
                <template v-if="!isEditMode">
                  <p class="form-control-static"
                    >{{form.currency_insurer_id
                        ? currencies.find(
                            (currency) =>
                              currency.id === form.currency_limit_of_liability_id
                          )?.alias
                        : '-'
                    }}
                    {{ form.limit_of_liability || '-' }}</p
                  >
                </template>
                <b-row v-else>
                  <b-col md="4" class="pr-2">
                    <v-select
                      v-model="form.currency_limit_of_liability_id"
                      label="alias"
                      style="width: 100%"
                      :options="currencies"
                      :reduce="(currency) => currency.id"
                      :disabled="permission_user === 'MANAGEMENT'"
                    >
                    </v-select>
                  </b-col>
                  <b-col md="8" class="pl-2">
                    <InputCurrency
                      placeholder="Input Currency"
                      v-model="form.limit_of_liability"
                      :disabled="permission_user === 'MANAGEMENT'"
                    />
                    <!-- <b-form-text
                      v-if="form.currency_limit_of_liability_id === 3"
                      class="text-muted"
                      >{{
                        formatCurrency(form.limit_of_liability)
                      }}</b-form-text
                    > -->
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Deductible</label>
                <template v-if="!isEditMode">
                  <p class="form-control-static"
                    >{{form.currency_insurer_id
                        ? currencies.find(
                            (currency) =>
                              currency.id === form.currency_deductible_id
                          )?.alias
                        : '-'
                    }}
                    {{ form.deductible || '-' }}</p
                  >
                </template>
                <b-row v-else>
                  <b-col md="4" class="pr-2">
                    <v-select
                      v-model="form.currency_deductible_id"
                      label="alias"
                      style="width: 100%"
                      :options="currencies"
                      :reduce="(currency) => currency.id"
                      :disabled="permission_user === 'MANAGEMENT'"
                    >
                    </v-select>
                  </b-col>
                  <b-col md="8" class="pl-2">
                    <InputCurrency
                      v-model="form.deductible"
                      :disabled="permission_user === 'MANAGEMENT'"
                      placeholder="Input Currency"
                    />
                    <!-- <b-form-text
                      v-if="form.currency_deductible_id === 3"
                      class="text-muted"
                      >{{ formatCurrency(form.deductible) }}</b-form-text
                    > -->
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="12" class="mb-4">
          <h6 class="card-title text-primary border-bottom pb-2"
            ><b>CLAIM PROPOSED</b></h6
          >
          <b-row>
            <b-col md="4">
              <b-form-group label="Actual or Riil Cost" label-for="Actual">
                <template v-if="!isEditMode">
                  <p class="form-control-static"
                    >{{
                      form.currency_actual_id
                        ? currencies.find(
                            (currency) =>
                              currency.id === form.currency_actual_id
                          )?.alias
                        : '-'
                    }}
                    {{ form.claim_proposed_actual || '-' }}</p
                  >
                </template>
                <b-row v-else>
                  <b-col md="3">
                    <v-select
                      v-model="form.currency_actual_id"
                      label="alias"
                      style="width: 100%"
                      :options="currencies"
                      :reduce="(currency) => currency.id"
                    >
                    </v-select>
                  </b-col>
                  <b-col md="7">
                    <b-form-group>
                      <InputCurrency
                        v-model="form.claim_proposed_actual"
                        placeholder="Input Currency"
                      ></InputCurrency>
                      <!-- <b-form-text
                        v-if="form.currency_actual_id === 3"
                        class="text-muted"
                        >{{
                          formatCurrency(form.claim_proposed_actual)
                        }}</b-form-text
                      > -->
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Proposed Cost to Insurer" label-for="Actual">
                <template v-if="!isEditMode">
                  <p class="form-control-static"
                    >{{
                      form.currency_insurer_id
                        ? currencies.find(
                            (currency) =>
                              currency.id === form.currency_insurer_id
                          )?.alias
                        : '-'
                    }}
                    {{ form.claim_proposed_insurer || '-' }}</p
                  >
                </template>
                <b-row v-else>
                  <b-col md="3">
                    <v-select
                      v-model="form.currency_insurer_id"
                      label="alias"
                      style="width: 100%"
                      :options="currencies"
                      :reduce="(currency) => currency.id"
                    >
                    </v-select>
                  </b-col>
                  <b-col md="7">
                    <b-form-group>
                      <InputCurrency
                        v-model="form.claim_proposed_insurer"
                        placeholder="Input Currency"
                      ></InputCurrency>
                      <!-- <b-form-text
                        v-if="form.currency_insurer_id === 3"
                        class="text-muted"
                        >{{
                          formatCurrency(form.claim_proposed_insurer)
                        }}</b-form-text
                      > -->
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Estimate cost Approved by Insurer"
                label-for="estimate"
              >
                <template v-if="!isEditMode">
                  <p class="form-control-static"
                    >{{
                      form.currency_approved_id
                        ? currencies.find(
                            (currency) =>
                              currency.id === form.currency_approved_id
                          )?.alias
                        : '-'
                    }}
                    {{ form.claim_proposed_approved || '-' }}</p
                  >
                </template>
                <b-row v-else>
                  <b-col md="3">
                    <v-select
                      v-model="form.currency_approved_id"
                      label="alias"
                      style="width: 100%"
                      :options="currencies"
                      :reduce="(currency) => currency.id"
                    >
                    </v-select>
                  </b-col>
                  <b-col md="7">
                    <b-form-group>
                      <InputCurrency
                        v-model="form.claim_proposed_approved"
                        placeholder="Input Currency"
                      ></InputCurrency>
                      <!-- <b-form-text
                        v-if="form.currency_approved_id === 3"
                        class="text-muted"
                        >{{
                          formatCurrency(form.claim_proposed_approved)
                        }}</b-form-text
                      > -->
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="12" class="mb-4">
          <h5 class="card-title text-primary d-flex justify-content-between">
            <b>{{
              claimProposedInputMode ? 'ACTUAL CLAIM PROGRESS' : 'RECORD CLAIM'
            }}</b>
            <b-button
              variant="info"
              v-if="!claimProposedInputMode && role !== 'MANAGEMENT'"
              class="ml-3"
              v-b-toggle.add-record-claim
              ><i class="ion-plus"></i>New Record</b-button
            >
          </h5>
          <div class="table-responsive">
            <table class="table" >
              <thead>
                <tr>
                  <th scope="col" colspan="2">Update</th>
                  <th scope="col" rowspan="2" style="vertical-align: middle"
                    >Update Claim</th
                  >
                  <th scope="col" rowspan="2" style="vertical-align: middle"
                    >Barriers</th
                  >
                  <th scope="col" rowspan="2" style="vertical-align: middle"
                    >Est. Settle Date</th
                  >
                  <th scope="col" colspan="2">Management</th>
                  <th scope="col" rowspan="2">Action</th>
                </tr>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">By User</th>
                  <th scope="col">Comment</th>
                  <th scope="col">User & Date</th>
                </tr>
              </thead>
              <tbody>
                <!-- Content -->
                <tr v-for="(data, index) in claimPurposed" :key="index">
                  <td>
                    {{
                      data.date ? moment(data.date).format('DD MMM YYYY') : '-'
                    }}
                  </td>
                  <td>{{ data.creator_user || '-' }}</td>
                  <td>{{ data.update_claim || '-' }}</td>
                  <td>{{ data.barriers || '-' }}</td>
                  <td>
                    {{
                      data.date ? moment(data.date).format('DD MMM YYYY') : '-'
                    }}
                    <p>{{ data.follow_up_response || '-' }}</p>
                  </td>
                  <td>{{ data.management_comment || '-' }}</td>
                  <td
                    >{{ data.management_user || '-' }}
                    <p>{{ data.management_date || '-' }}</p></td
                  >
                  <td>
                    <b-button
                      variant="success"
                      size="sm"
                      class="mr-2"
                      @click="onPopulateFormClaimPurposed(data)"
                      v-b-toggle.add-record-claim
                    >
                      <i class="fa fa-comment"></i>
                    </b-button>
                    <b-button
                      variant="danger"
                      v-if="
                        role == 'SUPER ADMIN' ||
                        'Super Admin' ||
                        'Marine Insurance' ||
                        'MARINE INSURANCE'
                      "
                      size="sm"
                      @click="onDeleteClaimRecord(data.id)"
                    >
                      <i class="fa fa-trash"></i>
                    </b-button>
                  </td>
                </tr>
                <!-- End Content -->
              </tbody>
            </table>
            <b-sidebar  width="600px" id="add-record-claim" header-class="bg-white" right backdrop title="Add Claim Record" body-class="p-3 m-0" >
              <div class="mx-3">
                <b-row class="bg-white py-3" style="border-radius: 12px;">
                <b-col cols="12">
                  <b-form-group label="Update Claim">
                    <p class="form-control-static" v-if="role === 'MANAGEMENT'" >{{ formChild.update_claim || '-' }}</p>
                      <b-form-textarea
                        v-else
                        v-model="formChild.update_claim"
                        rows="5"
                      ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Barries" label-for="Barries">
                    <p class="form-control-static" v-if="role === 'MANAGEMENT'">{{ formChild.barriers || '-' }}</p>
                    <b-form-textarea v-else v-model="formChild.barriers" rows="5"></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Status" label-for="status">
                    <p class="form-control-static" v-if="role === 'MANAGEMENT'">{{ formChild.follow_up_response || '-' }}</p>
                      <b-form-select
                        v-else
                        v-model="formChild.follow_up_response"
                        size="sm"
                      >
                        <b-form-select-option value="settled"
                          >Settled</b-form-select-option
                        >
                        <b-form-select-option value="on_progress"
                          >On Going</b-form-select-option
                        >
                        <b-form-select-option value="withdrawal "
                          >Withdrawal</b-form-select-option
                        >
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Estimate settled date" label-for="estimate">
                    <p class="form-control-static" v-if="role === 'MANAGEMENT'">{{ formChild.date || '-' }}</p>
                    <date-picker
                      v-else
                      v-model="formChild.date"
                      type="date"
                      value-type="YYYY-MM-DD"
                      placeholder="Select date"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12" class="pull-right text-right">
                  <div>
                    <b-button
                      v-b-toggle.add-record-claim
                      @click="onSaveClaimPurposed"
                      :disabled="
                        formChild.date === '' ||
                        formChild.update_claim === '' ||
                        formChild.barriers === '' ||
                        formChild.follow_up_response === ''
                      "
                      variant="primary"
                      >Submit</b-button
                    >
                    <b-button
                      v-b-toggle.add-record-claim
                      variant="outline"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </b-row>
              </div>
            </b-sidebar>
          </div>
        </b-col>
        <b-col cols="12" md="12" class="mb-4">
          <div class="table-responsive">
            <table class="table">
              <thead class="bg-warning">
                <tr>
                  <th scope="col">FINAL ADJUSTMENT</th>
                  <th scope="col">DEDUCTIBLE</th>
                  <th scope="col">NET CLAIM LOST</th>
                  <th scope="col">TOTAL CLAIM DAYS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <template v-if="!isEditMode">
                      <p class="form-control-static"
                        >{{
                          form.currency_final_adjustment_id
                            ? currencies.find(
                                (currency) =>
                                  currency.id ===
                                  form.currency_final_adjustment_id
                              )?.alias
                            : '-'
                        }}
                        {{ form.final_adjusment || '-' }}</p
                      >
                    </template>
                    <b-row v-else>
                      <b-col md="5">
                        <v-select
                          v-model="form.currency_final_adjustment_id"
                          label="alias"
                          style="width: 100%"
                          :options="currencies"
                          :reduce="(currency) => currency.id"
                        >
                        </v-select>
                      </b-col>
                      <b-col md="7">
                        <b-form-input
                          type="number"
                          required
                          v-model="form.final_adjusment"
                          placeholder="Input Currency"
                        ></b-form-input>
                        <!-- <b-form-text
                          v-if="form.currency_final_adjustment_id === 3"
                          class="text-muted"
                        >
                          {{ formatCurrency(form.final_adjusment) }}
                        </b-form-text> -->
                      </b-col>
                    </b-row>
                  </td>
                  <td>
                    <template v-if="!isEditMode">
                      <p class="form-control-static"
                        >{{
                          form.currency_deductible_id
                            ? currencies.find(
                                (currency) =>
                                  currency.id === form.currency_deductible_id
                              )?.alias
                            : '-'
                        }}
                        {{ form.deductible || '-' }}</p
                      >
                    </template>
                    <b-row v-else>
                      <b-col md="5">
                        <v-select
                          v-model="form.currency_deductible_id"
                          label="alias"
                          style="width: 100%"
                          :options="currencies"
                          :reduce="(currency) => currency.id"
                        >
                        </v-select>
                      </b-col>
                      <b-col md="7">
                        <b-form-input
                          type="number"
                          required
                          v-model="form.deductible"
                          placeholder="Input Currency"
                        ></b-form-input>
                        <!-- <b-form-text
                          v-if="form.currency_deductible_id === 3"
                          class="text-muted"
                        >
                          {{ formatCurrency(form.deductible) }}
                        </b-form-text> -->
                      </b-col>
                    </b-row>
                  </td>
                  <td>
                    <template v-if="!isEditMode">
                      <p class="form-control-static"
                        >{{
                          form.currency_net_claim_id
                            ? currencies.find(
                                (currency) =>
                                  currency.id === form.currency_net_claim_id
                              )?.alias
                            : '-'
                        }}
                        {{ form.net_claim_cost || '-' }}</p
                      >
                    </template>
                    <b-row v-else>
                      <b-col md="5">
                        <v-select
                          v-model="form.currency_net_claim_id"
                          label="alias"
                          style="width: 100%"
                          :options="currencies"
                          :reduce="(currency) => currency.id"
                        >
                        </v-select>
                      </b-col>
                      <b-col md="7">
                        <b-form-input
                          type="number"
                          required
                          v-model="form.net_claim_cost"
                          placeholder="Input Currency"
                        ></b-form-input>
                        <!-- <b-form-text
                          v-if="form.currency_net_claim_id === 3"
                          class="text-muted"
                        >
                          {{ formatCurrency(form.net_claim_cost) }}
                        </b-form-text> -->
                      </b-col>
                    </b-row>
                  </td>
                  <td>
                    <p class="form-control-static">{{
                      form.total_claim_days || '-'
                    }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-4"
          v-if="claimProposedInputMode && role === 'MANAGEMENT'"
        >
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Management Comment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b-form-textarea
                    v-model="formChild.management_comment"
                    rows="5"
                  ></b-form-textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col
          md="6"
          class="mt-4"
          v-if="claimProposedInputMode && role === 'MANAGEMENT'"
        >
          <hr />
          <span class="mr-5"><strong>User</strong></span
          ><span class="">
            <b-form-input
              v-model="formChild.management_user"
              rows="5"
            ></b-form-input>
          </span>
        </b-col>
        <b-col
          md="6"
          class="mt-4 mb-0"
          v-if="claimProposedInputMode && role === 'MANAGEMENT'"
        >
          <hr />
          <span class="mr-5"><strong>Date</strong></span
          ><span class="">
            <date-picker
              v-model="formChild.management_date"
              style="width: 100%"
              type="date"
              value-type="YYYY-MM-DD"
              placeholder="Select date range"
            ></date-picker>
          </span>
        </b-col>
        <b-col cols="12" class="text-right" v-if="isEditMode">
          <b-button type="submit" variant="primary">Submit</b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>
<script>
import InputCurrency from '@/src/components/global/InputCurrency.vue'
import { xray } from '@/src/config/pluginInit'
import { miActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'
import moment from 'moment'

export default {
  name: 'ManagementSummaryStep',
  components: { StepNavigation, StepWizard, InputCurrency },
  props: {
    detailMi: null,
  },
  data() {
    return {
      isEditMode: false,
      permission_user: JSON.parse(localStorage.getItem('profile')).role.roles,
      claimProposedInputMode: false,
      priceFleet: null,
      insurers: [],
      currencies: [],
      statementTruth: false,
      currency: null,
      currencyOptions: [
        { value: 'idr', text: 'IDR' },
        { value: 'usd', text: 'USD' },
        { value: 'sgd', text: 'SGD' },
      ],
      claimPurposed: null,
      form: {
        saving: false,
        id: '',
        claim_proposed_actual: 0,
        creator_date: '',
        claim_proposed_insurer: '',
        claim_proposed_approved: '',
        claim_progress_update: '',
        claim_progress_barriers: '',
        claim_estimate_settled: '',
        final_adjusment: '',
        deductible: '',
        net_claim_cost: '',
        total_claim_days: '',
        creator_fullname: '',
        approver_fullname: '',
        approver_date: '',
        currency_actual_id: 3,
        currency_insurer_id: 3,
        currency_approved_id: 3,
        currency_deductible_id: 3,
        currency_net_claim_id: 3,
        currency_final_adjustment_id: 3,
        currency_limit_of_liability_id: 3,
        currency_sum_insurer_id: 3,
        est_settled_date: '',
        act_settled_date: '',
        insurer_id: '',
        policy_number: '',
        surveyor: '',
        adjuster: '',
        coverage_period: [],
        sum_insured: '',
        limit_of_liability: '',
      },
      formChild: {
        id: '',
        date: '',
        creator_user: '',
        update_claim: '',
        barriers: '',
        follow_up_response: '',
        management_comment: '',
        management_date: '',
        management_user: '',
      },
      lsProfile: JSON.parse(localStorage.getItem('profile')),
    }
  },
  mounted() {
    xray.index()

    if (this.$route.query.formId) {
      this.fetchMi()
    }
  },
  methods: {
    ...miActions,
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    moment(date) {
      return moment(date)
    },
    async fetchMi() {
      // const { data, status } = await this.fetchDetailMi({
      //   id: this.$route.query.formId,
      //   with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories,summary',
      // })

      // if ([500, 404, 403].includes(status)) {
      //   this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
      //   return
      // }
      this.fetchFleetPrices()
      this.fetchInsurers()
      this.fetchCurrencies()
      this.fetchFormSummary()
    },
    async fetchFleetPrices(page = 1) {
      const params = {
        date: this.detailMi.date_of_loss,
        type: 'company',
        values: [this.detailMi.vehicle.company_id],
        page,
      }

      const { status, data } = await this.getFleetPriceList(params)

      if (status === 'success') {
        if (data && data.data) {
          const flatData = data.data.map((d) => d.types).flat()
          const getType = flatData.find(
            (type) => type.id === this.detailMi.vehicle.vehicle_type_id
          )
          if (getType) {
            const getPriceFleet = getType.data.find(
              (d) => d.vehicle_id === this.detailMi.vehicle.id
            )
            if (getPriceFleet) {
              this.priceFleet = getPriceFleet
              this.form.coverage_period = [getPriceFleet.from, getPriceFleet.to]
              this.form.deductible = getPriceFleet.deductible_value
              this.form.net_claim_cost = getPriceFleet.deductible_value
              this.form.sum_insured = getPriceFleet.value
            }
          }
        }
      }
    },
    async fetchInsurers() {
      this.insurers = []
      let res = await this.getInsurers({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.insurers.push({
              name: el.name,
              code: el.id,
            })
          })
        }
      }
    },
    async fetchCurrencies() {
      const { data } = await this.getCurrencies()
      this.currencies = data
    },
    async fetchFormSummary() {
      const { data } = await this.getFormSummary({
        id: this.$route.query.formId,
        // with: 'insurers',
      })

      if (data) {
        this.form.insurer_id = data.insurer_id
        this.form.policy_number = data.policy_number
        this.form.coverage_period = [
          this.detailMi.coverage_period,
          this.detailMi.coverage_period_to,
        ]
        this.form.surveyor = data.surveyor
        this.form.adjuster = data.adjuster

        // this.form.currency_actual_id = data.currency_actual_id
        // this.form.currency_limit_of_liability_id = data?.currency_limit_of_liability_id
        // this.form.currency_sum_insurer_id = data?.currency_sum_insurer_id
        // this.form.currency_net_claim_id = data.currency_net_claim_id

        // this.form.currency_approved_id = data.currency_approved_id

        // this.form.currency_deductible_id = data.currency_deductible_id
        // this.form.currency_final_adjustment_id = data.currency_final_adjustment_id

        this.form.currency_insurer_id = data.currency_insurer_id
        this.form.claim_proposed_insurer = data.claim_proposed_insurer
        this.form.claim_proposed_actual = data.claim_proposed_actual
        this.form.claim_estimate_settled = data.claim_estimate_settled
        this.form.claim_progress_barriers = data.claim_progress_barriers
        this.form.claim_progress_update = data.claim_progress_update
        this.form.claim_proposed_approved = data.claim_proposed_approved
        this.form.final_adjusment = data.final_adjusment
        this.form.deductible = data.deductible
        this.form.net_claim_cost = data.net_claim_cost
        this.form.sum_insured = data.sum_insured
        this.form.limit_of_liability = data.limit_of_liability

        this.claimPurposed = data.data

        if (this.claimPurposed[0]) {
          this.form.total_claim_days = moment().diff(
            moment(this.claimPurposed[0].date),
            'days'
          )
        }
      }
    },
    async onSaveHeader() {
      let payload = { ...this.form }

      delete payload.coverage_period

      this.form.saving = true

      const { data, status } = await this.createSummaryManagement({
        formId: this.$route.query.formId,
        ...payload,
      })

      if (status === 422) {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.form.saving = false
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(`Oops!`, 'API Ada Yang Salah', 'error')
        this.form.saving = false
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form.saving = false
    },
    async onSaveClaimPurposed() {
      let payload = { ...this.formChild }
      payload.creator_user = this.lsProfile.userDetail.name

      if (!this.detailMi.summary) {
        this.$swal(`Oops!`, 'Data Summary Tidak Ada', 'error')
        return
      }

      if (payload.id === '') {
        delete payload.id
      }

      const { data, status } = await this.createSummaryManagementClaimPurposed({
        summaryId: this.detailMi.summary.id,
        ...payload,
      })

      if (status === 422) {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(`Oops!`, 'API Ada Yang Salah', 'error')
        return
      }
      await this.fetchFormSummary()
      this.claimProposedInputMode = false
    },
    onResetFormClaimPurposed() {
      this.formChild = {
        id: '',
        date: '',
        creator_user: '',
        update_claim: '',
        barriers: '',
        follow_up_response: '',
        management_comment: '',
        management_date: '',
        management_user: '',
      }
    },
    onPopulateFormClaimPurposed(data) {
      this.formChild = {
        id: data.id,
        date: data.date,
        creator_user: data.creator_user,
        update_claim: data.update_claim,
        barriers: data.barriers,
        follow_up_response: data.follow_up_response,
        management_comment: data.management_comment,
        management_date: data.management_date,
        management_user: data.management_user,
      }

      // this.claimProposedInputMode = true
    },
    async onDeleteClaimRecord(id) {
      this.$swal({
        title: 'Hapus Claim Record?',
        text: `Aksi tidak dapat diurungkan`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteSummaryManagementClaimPurposed({
            id,
          })
          this.$swal(`Record Terhapus!`, ``, 'success')
          await this.fetchFormSummary()
          this.claimProposedInputMode = false
        }
      })
    },
  },
  computed: {
    id() {
      return this.$route.query.id
    },
    role() {
      return this.lsProfile ? this.lsProfile.role.roles : ''
    },
  },
}
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-button variant="primary" class="float-right mr-1" size="sm" @click="openSlideFormTripDocument('ADD', null)"><i class="fa fa-plus"></i> &nbsp; ADD DATA</b-button>
      </b-col>
      <!-- FILTER -->
      <b-col md="12">
        <b-row>
          <b-col md="3">
            <b-form-group label="Search" label-for="Search">
              <b-form-input v-model="params.search"  placeholder="Search..."></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="1">
            <b-form-group style label="Filter" label-for="button_search">
              <b-button variant="primary" @click="getListTripDocument" type='button'><i class="fa fa-search"></i></b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- CONTENT TABLE -->
      <b-col md="12">
        <div class="d-flex overflow-auto">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="align-middle " style="width:5px">No</th>
                <th class="text-center text-truncate" style="min-width: 300px">Name of Document</th>
                <th class="text-center text-truncate">Doc for Trip</th>
                <th class="text-center text-truncate">Document</th>
                <th style="width:5px">Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="list.length > 0">
                <tr v-for="(state, index) in list" :key="index">
                  <td>{{index + 1}}</td>
                  <td class="text-truncate">{{state?.name_document ?? '-'}}</td>
                  <td class="text-truncate">{{state?.project_plan?.shipment_plan_detail_number ?? '-'}}</td>
                  <td>
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center mb-1" style="cursor:pointer" v-for="(file, fileIndex) in state?.trip_attachment" :key="fileIndex"  @click="handleOpenFilePreview(`${apiUrl}/${file?.file_location}`, file.file_name)">
                        <i class="fa fa-file text-primary mr-2" />
                        <span class="text-primary text-truncate">{{file.file_name}}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <b-dropdown 
                      size="lg"
                      variant="link iq-bg-primary iq-border-radius-10"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" variant="primary" @click="openSlideFormTripDocument('PREVIEW', state?.id)">
                        <i class="fa fa-eye mr-2"></i>Detail
                      </b-dropdown-item>
                      <b-dropdown-item href="#" variant="primary" @click="openSlideFormTripDocument('EDIT', state?.id)">
                        <i class="fa fa-pen mr-2"></i>Edit
                      </b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item href="#" variant="danger" @click="onDeleteTripDocument(state)">
                        <i class="fa fa-trash mr-2"></i>Delete
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div class="mt-3" v-if="list.length > 0">
          <b-pagination
            first-number
            align="center"
          ></b-pagination>
        </div>
        <div class="d-flex align-items-center justify-content-center w-100 my-2 mb-4" v-else>
          <strong class="text-muted">No Data Trip Document.</strong>
        </div>
      </b-col>

       <!-- SLIDE - FORM TRIP LIST -->
       <b-col md="12">
        <b-sidebar
          v-model="slideFormTripDocument"
          id="slideFormTripDocument"
          :title="`Create Trip Document`"
          width="70%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideTripDocument
              :closeSlideFormTripDocument="closeSlideFormTripDocument"
              :refreshData="refreshData"
              :projectData="projectData"
              :showVehicleName="showVehicleName"
              :id="id"
              :action="action"
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - FORM TRIP LISTT END -->
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'
  import SlideTripDocument from './SlideTripDocument.vue'
  import { saveAs } from 'file-saver'

  export default {
    name:'TabTripDocument',
    props:{
      projectData:{
        type:Object,
        default:null
      }, 
      showVehicleName:{
        type:Function,
        default: () => {}
      },
    },
    components:{
      SlideTripDocument
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,

        slideFormTripDocument:false,

        id:null,
        action:'',

        params: {
          search:'',
        },

        list:[],
        meta:null,
      }
    },
    watch:{

    },
    mounted(){
      this.getListTripDocument()
    },
    methods:{
      ...busdevActions,
      async handleOpenFilePreview(url, filename = "File") {
        try {
          const response = await fetch(`${url}`);
          if (!response.ok) {
            throw new Error("Failed to fetch the PDF");
          }
          const blob = await response.blob(); 
          saveAs(blob, `${filename}.pdf`); 
        } catch (error) {
          console.error("Error downloading the PDF:", error);
        }
      },
      closeSlideFormTripDocument(){
        this.id = null
        this.action = ''
        this.slideFormTripDocument = false
      },  
      openSlideFormTripDocument(value, id){
        this.id = null
        this.action = value
        this.slideFormTripDocument = !this.slideFormTripDocument;

        if(id) this.id = id
      },

      async getListTripDocument(page){
        let params = {
          params:{
            ...this.params,
            page: page || 1,
            perPage: 25,
          },
          project_contract_id: this.projectData.id
        }

        // Iterate over each property in params
        for (const prop in params.params) {
          if (params.params.hasOwnProperty(prop)) {
            // Check conditions for each property and delete if necessary
            if ((params.params[prop] === '') || (params.params[prop] === null)) {
              delete params.params[prop];
            }
          }
        }

        const res = await this.fetchTripDocument(params)

        if(res.data.status === true){
          this.list = res.data.data.list
          this.meta = res.data.data.meta
        } else {
          this.list = []
          this.meta = {}
        }
      },
      refreshData(){
        this.getListTripDocument()
      },

      async onDeleteTripDocument(data){
        this.$swal({
            title: `Are you sure want to trip document ${data?.name_document}?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
          const res = await this.deleteTripDocument({id:data.id})
            if(res.status === false || res.status === 400 || res.status === 500){
              this.$swal(
                  'Failed!',
                  'Something went wrong.',
                  'error'
              )
              return 
            } 

            this.$swal(
              'Success!',
              'Success delete Trip.',
              'success'
            )
            this.refreshData()
          }
        })
      },
    }
  }
</script>


<template>
  <b-row class="m-4">
    <b-col md="12" v-if="!loading">
      <fieldset class="form-group border p-3">
        <legend class="w-auto px-2 text-primary">DETAIL TRIP</legend>
        <b-row  class="p-2">
          <b-col md="6">
            <b-row class="m-2">
              <b-col md="12">
                <div class="d-flex flex-wrap">
                  <b-form-group v-for="(state, index) in vehicle" :key="index" :label="`Fleet - ${index+1}`" class="mr-5">
                    <strong class="text-muted">{{state?.name}}</strong>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row class="m-2 py-2" style="background-color:#E5F9FF">
              <b-col md="12" class="mb-2">
                <strong class="text-primary">LOADING ACTIVITY RECORD</strong>
              </b-col>
              <b-col md="6">
                <b-form-group label="Port of Loading" label-for="port_of_loading">
                  <strong>{{ detailData?.portLoading?.portName }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Agent POL" label-for="agent_pol" class="text-truncate">
                  <strong>{{ detailData?.portLoading?.agentPol }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Is Pol Sts Mv:" label-for="kind-fleet">
                  <strong>{{ detailData?.polStsMv ? 'Yes' : 'No' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pol Mv Name" label-for="pol_mv_name" class="text-truncate" >
                  <strong>{{ detailData?.polMvName }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pol grab Type" label-for="pod_grab_type">
                  <strong class="text-capitalize">{{ detailData?.polGrabType }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pol Fc Name" label-for="pol_fc_name" class="text-truncate">
                  <strong>{{ detailData?.polFcName }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2"> 
                <b-form-group label="NOR" label-for="nor" class="text-truncate mb-0">
                  <strong>{{ detailData?.polNor }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2"> 
                <b-form-group label="NOA" label-for="noa" class="text-truncate mb-0">
                  <strong>{{ detailData?.polNoa }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Anchor Up" label-for="polAnchorUp" class="text-truncate mb-0">
                  <strong>{{ detailData?.polAnchorUp }}</strong>
                </b-form-group>
              </b-col>
              <b-col  md="6" class="mb-2">
                <b-form-group label="In Position / Berthing" label-for="polBerthing" class="text-truncate mb-0">
                  <strong>{{ detailData?.polBerthing }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Commenced" label-for="polCommenced" class="text-truncate mb-0">
                  <strong>{{ detailData?.polCommenced }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Completed" label-for="completed" class="text-truncate mb-0">
                  <strong>{{ detailData?.polCompleted }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Draught Survey" label-for="polDraughtSurvey" class="text-truncate mb-0">
                  <strong>{{ detailData?.polDraughtSurveyDate }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2"> 
                <b-form-group label="BL Date" label-for="blDate" class="text-truncate mb-0">
                  <strong>{{ detailData?.polBlDate }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2"> 
                <b-form-group label="Cast In" label-for="polCastIn" class="text-truncate mb-0">
                  <strong>{{ detailData?.polCastIn }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Cast out" label-for="polCastOut" class="text-truncate mb-0">
                  <strong>{{ detailData?.polCastOut }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="LHV" label-for="polLhv" class="text-truncate mb-0">
                  <strong>{{ detailData?.polLhv }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Document On Board POL" label-for="document_on_board" class="text-truncate mb-0">
                  <strong>{{ detailData?.polDocOnBoard }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Departure to POD" label-for="polDeparture" class="text-truncate mb-0">
                  <strong>{{ detailData?.polDeparture }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mb-2"> 
                <b-form-group label="Remarks" label-for="polCastIn" class="text-truncate mb-0">
                  <strong>{{ detailData?.remark }}</strong>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="m-2">
              <b-col md="6">
                <b-form-group label="PROJECT" style="opacity:0">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="PROJECT" style="opacity:0">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="m-2  py-2" style="background-color:#EFFEE6">
              <b-col md="12" class="mb-2">
                <strong class="text-primary">DISCHARGE ACTIVITY RECORD</strong>
              </b-col>
              <b-col md="6">
                <b-form-group label="Port of Discharge" label-for="port_of_loading">
                  <strong>{{ detailData?.portDischarge?.portName }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Agent POD" label-for="agent_pol" class="text-truncate">
                  <strong>{{ detailData?.agentPod }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Is Pod Sts Mv:" label-for="kind-fleet">
                  <strong>{{ detailData?.podStsMv ? 'Yes' : 'No' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pod Mv Name" label-for="pod_mv_name" class="text-truncate" >
                  <strong>{{ detailData?.podMvName }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pod grab Type" label-for="pod_grab_type">
                  <strong class="text-capitalize">{{ detailData?.podGrabType }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Pod Fc Name" label-for="pol_fc_name" class="text-truncate">
                  <strong>{{ detailData?.podFcName }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2"> 
                <b-form-group label="NOR" label-for="nor" class="text-truncate mb-0">
                  <strong>{{ detailData?.podNor }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2"> 
                <b-form-group label="NOA" label-for="noa" class="text-truncate mb-0">
                  <strong>{{ detailData?.podNoa }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Anchor Up" label-for="podAnchorUp" class="text-truncate mb-0">
                  <strong>{{ detailData?.podAnchorUp }}</strong>
                </b-form-group>
              </b-col>
              <b-col  md="6" class="mb-2">
                <b-form-group label="In Position / Berthing" label-for="podBerthing" class="text-truncate mb-0">
                  <strong>{{ detailData?.podBerthing }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Commenced" label-for="podCommenced" class="text-truncate mb-0">
                  <strong>{{ detailData?.podCommenced }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Completed" label-for="podCompleted" class="text-truncate mb-0">
                  <strong>{{ detailData?.podCompleted }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Draught Survey" label-for="podDraughtSurvey" class="text-truncate mb-0">
                  <strong>{{ detailData?.podDraughtSurveyDate }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2"> 
                <b-form-group label="BL Date" label-for="blDate" class="text-truncate mb-0">
                  <strong>{{ detailData?.podBlDate }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2"> 
                <b-form-group label="Cast In" label-for="podCastIn" class="text-truncate mb-0">
                  <strong>{{ detailData?.podCastIn }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Cast out" label-for="podCastOut" class="text-truncate mb-0">
                  <strong>{{ detailData?.podCastOut }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="LHV" label-for="podLhv" class="text-truncate mb-0">
                  <strong>{{ detailData?.podLhv }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Document On Board POD" label-for="document_on_board" class="text-truncate mb-0">
                  <strong>{{ detailData?.podDocOnBoard }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-2">
                <b-form-group label="Departure to POD" label-for="podDeparture" class="text-truncate mb-0">
                  <strong>{{ detailData?.podDeparture }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mb-2"> 
                <b-form-group label="Remarks" label-for="polCastIn" class="text-truncate mb-0">
                  <strong>{{ detailData?.remark }}</strong>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        
        <!-- TABLE CONTENT -->
        <b-row class="p-3">
          <b-col md="12">
            <h4><strong class="text-primary">Activities</strong></h4>
            <hr>
            <div class="d-flex overflow-auto" style="max-heigth: 400px">
              <table class="table table-striped">
                  <thead>
                    <tr>
                      <th style="width:5px" class="text-center text-truncate">NO</th>
                      <th class="text-center text-truncate">START DATE</th>                       
                      <th class="text-center text-truncate">END DATE</th>                       
                      <th class="text-center text-truncate">REASON</th>                       
                      <th class="text-center text-truncate">HOURS</th>                       
                      <th class="text-center text-truncate">CARGO LOADING</th>                       
                      <th class="text-center text-truncate">CARGO UNLOADING</th>                       
                      <th class="text-center text-truncate">CURRENT POSITION</th>                       
                      <th class="text-center text-truncate">REMARKS</th>                       
                      <th class="text-center text-truncate">GPS</th>                       
                      <th class="text-center text-truncate">SPEED</th>                       
                      <th class="text-center text-truncate">REASON</th>                                             
                      <th class="text-center" style="width:5px; position: sticky; right: 0; z-index: 1">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(state, index) in activities" :key="index">
                      <td class="text-center">{{index + 1}}</td>
                      <td class="text-center text-truncate">{{ state?.startDate ? moment(state?.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.endDate ? moment(state?.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.reason?.name ?? '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.activityDurationFormat }}</td>
                      <td class="text-center text-truncate">{{ state?.cargoLoading ?? '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.cargoUnloading ?? '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.currentPosition ?? '-' }}</td>
                      <td class="text-center">{{ state?.remark ?? '-' }}</td>
                      <td class="text-center">-</td>
                      <td class="text-center">{{ state?.speed }}</td>
                      <td class="text-center">{{ state?.reason?.name }}</td>
                      <td class="text-center" style="width:5px; position: sticky; right: 0; z-index: 1">
                        <b-dropdown 
                          size="lg"
                          variant="link iq-bg-primary iq-border-radius-10"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary" @click="toEditHistory(state.id, true)">
                            <i class="fa fa-eye mr-2"></i>Detail
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="primary" @click="toEditHistory(state.id, false)">
                            <i class="fa fa-pen mr-2"></i>Edit
                          </b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#" variant="danger" @click="onDeleteActivity(state)">
                            <i class="fa fa-trash mr-2"></i>Delete
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </b-col>
        </b-row>
 
         <!-- TABLE CONTENT MATRIX -->
         <b-row class="p-3">
          <b-col md="12">
            <h4><strong class="text-primary">Voyage Matrix</strong></h4>
            <hr>
            <div class="d-flex overflow-auto" style="max-heigth: 400px">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="text-center text-truncate text-uppercase" v-for="(head, headIndex) in matrix" :key="headIndex">
                      {{head?.name}}
                    </th>                       
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center text-truncate" v-for="(body, bodyIndex) in matrix" :key="bodyIndex">
                      {{body?.durationFormat ?? '-'}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </fieldset>
    </b-col>
    
    <b-col md="12" v-else>
      <div class="alert alert-info m-4">
        Loading...
      </div>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { fleetsActions, voyagesActions } from '@src/Utils/helper'

export default {
  name: 'SlideDetailRealisasi',
  props:{
    id:{
      type:Number,
      default:null
    },
    closeSlideDetailRealisasi:{
      type:Function,
      default: () => {}
    }
  },
  components:{
  },
  data(){
  return {
    loading:false,

    detailData: null,
    vehicle: null,
    matrix: [],
    activities: [],
    summaryActivities: [],
  }
  },
  async mounted () {
  },
  computed: {
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.getDetailTrip(value)
        }
      },
    },
  },
  methods:{
    ...voyagesActions,
    toEditHistory(activityId, preview) {
      this.$router.push({
        name: 'dashboard.operational-input',
        query: {
          vehicleId: this.$route.query.vehicleId,
          activityEditId: activityId,
          preview
        },
      })
    },
    async getDetailTrip(tripId){
      let params = {
        tripId,
        vehicleId: Number(this.$route.query.vehicleId),
      }
      let res = await this.getVoyageTripDetail(params)
      console.log(res)
      if(res.status === 'success'){
        let data = res?.data

        this.matrix = data?.matrix
        this.activities = data?.activities
        this.summaryActivities = data?.summaryActivities

        this.detailData = data?.tripRecord
        this.vehicle = data?.vehicle
      }
    },
    async onDeleteActivity (activityData) {
      let activity = activityData

      this.$swal({
        title: 'Delete Activity?',
        text: `Activity will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const vehicleId = this.$route.query.vehicleId
          let res = await this.deleteActivityById({ vehicleId, id: activity.id })
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            await this.getDetailTrip(Number(this.id))
            this.closeSlideDetailRealisasi()
            this.$swal(
              `Activity deleted!`,
              `Activity successfully deleted`,
              'success'
            )
          }
        }
      })
    },
  }

}
</script>
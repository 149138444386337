var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.incident && !_vm.isLoading ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditMode = !_vm.isEditMode;
      }
    }
  }, [_vm._v(_vm._s(!_vm.isEditMode ? 'Update' : 'Preview') + " Management Approval")])], 1), _c('b-col', {
    staticClass: "px-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('ComponentClaimStep', {
    attrs: {
      "incident": _vm.incident,
      "readonly": "",
      "isEditMode": _vm.isEditMode,
      "companies": _vm.companies,
      "vehicles": [{
        value: _vm.incident.vehicle.id,
        label: _vm.incident.vehicle.name
      }],
      "departments": [{
        value: _vm.incident.department.id,
        label: _vm.incident.department.name
      }],
      "coverages": [{
        value: _vm.incident.type_coverage.id,
        label: _vm.incident.type_coverage.name
      }],
      "losses": [{
        value: _vm.incident.type_loss.id,
        label: _vm.incident.type_loss.name
      }]
    }
  }), _c('b-col', {
    staticClass: "px-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "pt-3",
    staticStyle: {
      "background": "#f2f1f1"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Approve By"
    }
  }, [_vm.isEditMode ? _c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "type": "text",
      "readonly": _vm.$route.query.state === 'view',
      "placeholder": "Input Name"
    },
    model: {
      value: _vm.incident.creator_fullname,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "creator_fullname", $$v);
      },
      expression: "incident.creator_fullname"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.incident.creator_fullname))])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date"
    }
  }, [_vm.isEditMode ? _c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "date",
      "readonly": _vm.$route.query.state === 'view',
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.incident.creator_date,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "creator_date", $$v);
      },
      expression: "incident.creator_date"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.moment(_vm.incident.creator_date).format('DD MMM YYYY')))])], 1)], 1)], 1)], 1), ['MANAGEMENT', 'management'].includes(_vm.role) ? [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h5', {
    staticClass: "card-title text-success"
  }, [_c('b', [_vm._v("MANAGEMENT APPROVAL AND COMMENT")])]), _c('b-form-group', {
    attrs: {
      "label": "Decribe of Approval"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "exampleFormControlTextarea1",
      "readonly": _vm.$route.query.state === 'view',
      "rows": "3"
    },
    model: {
      value: _vm.incident.approver_description,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "approver_description", $$v);
      },
      expression: "incident.approver_description"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "6"
    }
  }, [_c('hr'), _c('b-form-input', {
    attrs: {
      "type": "text",
      "readonly": _vm.$route.query.state === 'view',
      "placeholder": "Input Name"
    },
    model: {
      value: _vm.incident.approver_fullname,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "approver_fullname", $$v);
      },
      expression: "incident.approver_fullname"
    }
  })], 1), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "6"
    }
  }, [_c('hr'), _c('span', {
    staticClass: "mr-5"
  }, [_c('strong', [_vm._v("Date")])]), _c('span', {
    staticClass: "ml-5"
  }, [_c('date-picker', {
    attrs: {
      "type": "date",
      "readonly": _vm.$route.query.state === 'view',
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.incident.approver_date,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "approver_date", $$v);
      },
      expression: "incident.approver_date"
    }
  })], 1)])] : _vm._e(), _vm.isEditMode ? [_vm.$route.query.state !== 'view' ? _c('b-col', {
    staticClass: "pull-right text-center mt-5",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "mb-3",
    model: {
      value: _vm.statementTruth,
      callback: function callback($$v) {
        _vm.statementTruth = $$v;
      },
      expression: "statementTruth"
    }
  }, [_vm._v(" I already read all and I approve this case ")])], 1) : _vm._e(), _vm.$route.query.state !== 'view' ? _c('b-col', {
    staticClass: "pull-right text-right mt-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "disabled": !_vm.statementTruth,
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onSave(_vm.incident.creator_fullname, _vm.incident.creator_date, _vm.incident.approver_description, _vm.incident.approver_fullname, _vm.incident.approver_date);
      }
    }
  }, [_vm._v("Submit")])], 1) : _vm._e()] : _vm._e(), _c('b-col', {
    staticClass: "mb-5",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  })], 2)], 1)], 1) : _c('div', {
    staticClass: "my-5 py-5 d-flex justify-content-center"
  }, [_c('b-spinner')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$rootCauseAnalysis, _vm$detailMi$vehicle$, _vm$form$report_numbe, _vm$rootCauseAnalysis2, _vm$detailMi$place_of, _vm$detailMi$type_los, _vm$detailMi$type_cov, _vm$rootCauseAnalysis3, _vm$form$investigatio, _vm$form$direct_witne, _vm$form$indirect_wit, _vm$form$problem, _vm$form$cause_of_los, _vm$form$cause_of_los2, _vm$form$recommendati, _vm$form$recommendati2, _vm$form$recommendati3, _vm$form$recommendati4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.loading.rca ? [_vm.detailMi ? _c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [!_vm.rootCauseAnalysis ? _c('b-alert', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v("Isi RCA Terlebih Dahulu "), _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "variant": "primary",
      "to": "/mi/root-cause-analysis?formId=".concat(_vm.$route.query.formId)
    }
  }, [_vm._v("Disini")])], 1) : _c('b-row', [_c('b-col', {
    staticClass: "mb-3 text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditMode = !_vm.isEditMode;
      }
    }
  }, [_vm._v(_vm._s(_vm.isEditMode ? 'Preview' : 'Update') + " Rca Final")])], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('h5', {
    staticClass: "border-bottom py-2"
  }, [_vm._v("Assessor of the Accident")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_vm.isEditMode ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    },
    model: {
      value: _vm.rootCauseAnalysis.assessor_fullname,
      callback: function callback($$v) {
        _vm.$set(_vm.rootCauseAnalysis, "assessor_fullname", $$v);
      },
      expression: "rootCauseAnalysis.assessor_fullname"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$rootCauseAnalysis = _vm.rootCauseAnalysis.assessor_fullname) !== null && _vm$rootCauseAnalysis !== void 0 ? _vm$rootCauseAnalysis : '-'))])], 1)], 1), _c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vessel"
    }
  }, [_vm.isEditMode ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.detailMi.vehicle.name,
      "readonly": "",
      "type": "text",
      "placeholder": "Vessel..."
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$vehicle$ = _vm.detailMi.vehicle.name) !== null && _vm$detailMi$vehicle$ !== void 0 ? _vm$detailMi$vehicle$ : '-'))])], 1)], 1), _c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company"
    }
  }, [_vm.isEditMode ? _c('b-form-select', {
    attrs: {
      "plain": "",
      "disabled": "",
      "options": _vm.companies,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Company")])];
      },
      proxy: true
    }], null, false, 3660131153),
    model: {
      value: _vm.rootCauseAnalysis.assessor_company_id,
      callback: function callback($$v) {
        _vm.$set(_vm.rootCauseAnalysis, "assessor_company_id", $$v);
      },
      expression: "rootCauseAnalysis.assessor_company_id"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.rootCauseAnalysis.assessor_company_id ? _vm.companies.find(function (el) {
    return el.value == _vm.rootCauseAnalysis.assessor_company_id;
  }).text : '-'))])], 1)], 1), _c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Report Number"
    }
  }, [_vm.isEditMode ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Report Number..."
    },
    model: {
      value: _vm.form.report_number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "report_number", $$v);
      },
      expression: "form.report_number"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$form$report_numbe = _vm.form.report_number) !== null && _vm$form$report_numbe !== void 0 ? _vm$form$report_numbe : '-'))])], 1)], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Position"
    }
  }, [_vm.isEditMode ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    },
    model: {
      value: _vm.rootCauseAnalysis.assessor_position,
      callback: function callback($$v) {
        _vm.$set(_vm.rootCauseAnalysis, "assessor_position", $$v);
      },
      expression: "rootCauseAnalysis.assessor_position"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$rootCauseAnalysis2 = _vm.rootCauseAnalysis.assessor_position) !== null && _vm$rootCauseAnalysis2 !== void 0 ? _vm$rootCauseAnalysis2 : '-'))])], 1)], 1), _c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Place of Loss"
    }
  }, [_vm.isEditMode ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.detailMi.place_of_loss,
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$place_of = _vm.detailMi.place_of_loss) !== null && _vm$detailMi$place_of !== void 0 ? _vm$detailMi$place_of : '-'))])], 1)], 1), _c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Loss"
    }
  }, [_vm.isEditMode ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.detailMi.type_loss.name,
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$type_los = _vm.detailMi.type_loss.name) !== null && _vm$detailMi$type_los !== void 0 ? _vm$detailMi$type_los : '-'))])], 1)], 1), _c('td', [_c('b-form-group', {
    attrs: {
      "label": "Type of Coverage"
    }
  }, [_vm.isEditMode ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.detailMi.type_coverage.name,
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$detailMi$type_cov = _vm.detailMi.type_coverage.name) !== null && _vm$detailMi$type_cov !== void 0 ? _vm$detailMi$type_cov : '-'))])], 1)], 1)]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Loss"
    }
  }, [_vm.isEditMode ? _c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "datetime",
      "disabled": "",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.rootCauseAnalysis.date_of_loss,
      callback: function callback($$v) {
        _vm.$set(_vm.rootCauseAnalysis, "date_of_loss", $$v);
      },
      expression: "rootCauseAnalysis.date_of_loss"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$rootCauseAnalysis3 = _vm.rootCauseAnalysis.date_of_loss) !== null && _vm$rootCauseAnalysis3 !== void 0 ? _vm$rootCauseAnalysis3 : '-'))])], 1)], 1), _c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Starting Date *"
    }
  }, [_vm.isEditMode ? _c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "date",
      "is-required": "",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.form.starting_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "starting_date", $$v);
      },
      expression: "form.starting_date"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.form.starting_date ? _vm.moment(_vm.form.starting_date).format('DD MMM YYYY') : '-'))])], 1)], 1), _c('td', {
    staticStyle: {
      "width": "25%"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Closing Date *"
    }
  }, [_vm.isEditMode ? _c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "date",
      "is-required": "",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.form.closing_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "closing_date", $$v);
      },
      expression: "form.closing_date"
    }
  }) : _c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.form.closing_date ? _vm.moment(_vm.form.closing_date).format('DD MMM YYYY') : '-'))])], 1)], 1), _c('td', {
    staticStyle: {
      "width": "25%"
    }
  })])])])])], 1), _c('b-row')], 1), _c('b-col', {
    staticClass: "mb-5",
    attrs: {
      "md": "12"
    }
  }, [_c('TabPaneRcaFinal', {
    attrs: {
      "activeTab": _vm.activeTab
    },
    on: {
      "clickTab": function clickTab(val) {
        return _vm.activeTab = val;
      }
    }
  })], 1), _vm.activeTab == 1 ? _c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "my-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(" A.1 ")])]), _c('h5', [_vm._v("Investigation Team")])]), _c('div', [(_vm$form$investigatio = _vm.form.investigation_teams) !== null && _vm$form$investigatio !== void 0 && _vm$form$investigatio.length ? _c('table', {
    staticClass: "table table-striped w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Team List")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _vm.isEditMode ? _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.investigation_teams, function (data, index) {
    var _data$team, _data$name, _data$position, _data$remarks;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.team,
        callback: function callback($$v) {
          _vm.$set(data, "team", $$v);
        },
        expression: "data.team"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$team = data.team) !== null && _data$team !== void 0 ? _data$team : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.name,
        callback: function callback($$v) {
          _vm.$set(data, "name", $$v);
        },
        expression: "data.name"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$name = data.name) !== null && _data$name !== void 0 ? _data$name : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Position..."
      },
      model: {
        value: data.position,
        callback: function callback($$v) {
          _vm.$set(data, "position", $$v);
        },
        expression: "data.position"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$position = data.position) !== null && _data$position !== void 0 ? _data$position : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$remarks = data.remarks) !== null && _data$remarks !== void 0 ? _data$remarks : '-'))])], 1), _vm.isEditMode ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeInvestigationTeam(index);
        }
      }
    }, [_vm._v("Remove")])], 1) : _vm._e()]);
  }), 0)]) : _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" No Data Available! ")]), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addInvestigationTeam();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "my-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(" A.2 ")])]), _c('h5', [_vm._v("Direct Witness")])]), _c('div', [(_vm$form$direct_witne = _vm.form.direct_witnesses) !== null && _vm$form$direct_witne !== void 0 && _vm$form$direct_witne.length ? _c('table', {
    staticClass: "table table-striped w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.form.direct_witnesses, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.position : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.remarks : '-') + " ")]), _c('td')]);
  }), 0)]) : _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" No Data Available! ")])])]), _c('div', {
    staticClass: "my-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(" A.3 ")])]), _c('h5', [_vm._v("Indirect Witness")])]), _c('div', [(_vm$form$indirect_wit = _vm.form.indirect_witnesses) !== null && _vm$form$indirect_wit !== void 0 && _vm$form$indirect_wit.length ? _c('table', {
    staticClass: "table table-striped w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.form.indirect_witnesses, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.position : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.remarks : '-') + " ")]), _c('td')]);
  }), 0)]) : _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" No Data Available! ")])])])]) : _vm._e(), _vm.activeTab == 2 ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Document")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Original")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Copy")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("PIC")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Attachment")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _vm.isEditMode ? _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.supporting_documents, function (data, index) {
    var _data$document, _data$pic, _data$remarks2;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "required": "",
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.document,
        callback: function callback($$v) {
          _vm.$set(data, "document", $$v);
        },
        expression: "data.document"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$document = data.document) !== null && _data$document !== void 0 ? _data$document : '-'))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-form-checkbox', {
      staticClass: "w-100",
      attrs: {
        "disabled": !_vm.isEditMode,
        "value": "v",
        "unchecked-value": "x"
      },
      model: {
        value: data.original,
        callback: function callback($$v) {
          _vm.$set(data, "original", $$v);
        },
        expression: "data.original"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-form-checkbox', {
      staticClass: "w-100",
      attrs: {
        "disabled": !_vm.isEditMode,
        "value": "v",
        "unchecked-value": "x"
      },
      model: {
        value: data.copy,
        callback: function callback($$v) {
          _vm.$set(data, "copy", $$v);
        },
        expression: "data.copy"
      }
    })], 1), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "required": "",
        "type": "text",
        "placeholder": "PIC..."
      },
      model: {
        value: data.pic,
        callback: function callback($$v) {
          _vm.$set(data, "pic", $$v);
        },
        expression: "data.pic"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$pic = data.pic) !== null && _data$pic !== void 0 ? _data$pic : '-'))])], 1), _c('td', [data.document ? _c('div', {
      staticClass: "d-flex justify-content-between"
    }, [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-primary",
      attrs: {
        "href": data.document,
        "title": "Download File",
        "download": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    }), _vm._v(" Download")]), _vm.isEditMode ? _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-danger",
      attrs: {
        "href": "#",
        "title": "Download",
        "download": ""
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          data.document = '';
          data.file = '';
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })]) : _vm._e()]) : [_c('div', {
      staticClass: "custom-file"
    }, [_c('input', {
      staticClass: "custom-file-input",
      attrs: {
        "type": "file",
        "accept": "image/png,image/jpg,image/jpeg",
        "id": "validatedCustomFile"
      },
      on: {
        "change": function change($event) {
          return _vm.onSupportingDocumentsFileChanged(index, $event);
        }
      }
    }), _c('label', {
      staticClass: "custom-file-label",
      attrs: {
        "for": "validatedCustomFile"
      }
    }, [_vm._v("Browse file...")])])]], 2), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$remarks2 = data.remarks) !== null && _data$remarks2 !== void 0 ? _data$remarks2 : '-'))])], 1), _vm.isEditMode ? _c('td', {
      staticClass: "text-center"
    }, [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeSupportingDocuments(index);
        }
      }
    }, [_vm._v("Remove")])], 1) : _vm._e()]);
  }), 0)]), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addSupportingDocuments();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)]) : _vm._e(), _vm.activeTab == 3 ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_vm.isEditMode ? _c('b-row', [_c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Images (multiple) *"
    }
  }, [_c('b-form-file', {
    staticClass: "w-100",
    attrs: {
      "accept": "image/*",
      "multiple": "",
      "placeholder": "Select file..."
    },
    on: {
      "change": _vm.onImagesFileChanged
    }
  })], 1)], 1)], 1) : _c('b-row', {
    staticClass: "mb-4"
  }, _vm._l(_vm.form.images, function (img, index) {
    return _c('b-col', {
      key: index,
      staticClass: "d-flex justify-content-center py-3",
      attrs: {
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "bg-white p-2 border shadow",
      staticStyle: {
        "border-radius": "14px"
      }
    }, [_c('img', {
      staticClass: "border",
      staticStyle: {
        "border-radius": "12px"
      },
      attrs: {
        "height": "240",
        "width": "360",
        "src": img,
        "alt": ""
      }
    })])]);
  }), 1)], 1) : _vm._e(), _vm.activeTab == 4 ? _c('b-col', {
    staticClass: "mt-2 mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "The Problem *",
      "label-class": "font-500"
    }
  }, [_vm.isEditMode ? _c('b-form-textarea', {
    staticClass: "bg-white",
    attrs: {
      "required": "",
      "rows": "4"
    },
    model: {
      value: _vm.form.problem,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "problem", $$v);
      },
      expression: "form.problem"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$problem = _vm.form.problem) !== null && _vm$form$problem !== void 0 ? _vm$form$problem : '-'))])], 1)], 1), _c('td', [_c('b-form-group', {
    attrs: {
      "label": "Cause Of Loss *",
      "label-class": "font-500"
    }
  }, [_vm.isEditMode ? _c('b-form-textarea', {
    staticClass: "bg-white",
    attrs: {
      "required": "",
      "rows": "4"
    },
    model: {
      value: _vm.form.cause_of_loss,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cause_of_loss", $$v);
      },
      expression: "form.cause_of_loss"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$cause_of_los = _vm.form.cause_of_loss) !== null && _vm$form$cause_of_los !== void 0 ? _vm$form$cause_of_los : '-'))])], 1)], 1), _c('td', [_c('b-form-group', {
    attrs: {
      "label": "Impact Of Damage *",
      "label-class": "font-500"
    }
  }, [_vm.isEditMode ? _c('b-form-textarea', {
    staticClass: "bg-white",
    attrs: {
      "required": "",
      "rows": "4"
    },
    model: {
      value: _vm.form.impact,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "impact", $$v);
      },
      expression: "form.impact"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$cause_of_los2 = _vm.form.cause_of_loss) !== null && _vm$form$cause_of_los2 !== void 0 ? _vm$form$cause_of_los2 : '-'))])], 1)], 1)])])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('h5', {
    staticClass: "mb-2",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("D.1 Cost of Damage")]), _c('div', [_c('table', {
    staticClass: "table w-100 table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Description")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    staticClass: "text-right",
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Cost")]), _c('th', {
    staticClass: "text-right",
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Total")])])]), _c('tbody', _vm._l(_vm.form.damage_costs, function (data, index) {
    var _data$description, _data$remarks3;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.description,
        callback: function callback($$v) {
          _vm.$set(data, "description", $$v);
        },
        expression: "data.description"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$description = data.description) !== null && _data$description !== void 0 ? _data$description : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$remarks3 = data.remarks) !== null && _data$remarks3 !== void 0 ? _data$remarks3 : '-'))])], 1), _c('td', {
      staticClass: "text-right"
    }, [_vm.isEditMode ? _c('b-input-group', {
      staticClass: "d-flex justify-content-end"
    }, [_c('InputCurrency', {
      attrs: {
        "type": "number"
      },
      model: {
        value: data.cost,
        callback: function callback($$v) {
          _vm.$set(data, "cost", $$v);
        },
        expression: "data.cost"
      }
    })], 1) : _c('h6', [_vm._v("Rp. " + _vm._s(data.cost ? _vm.numberFormat(data.total) : '-'))])], 1), _c('td', {
      staticClass: "text-right"
    }, [_vm.isEditMode ? _c('b-input-group', {
      staticClass: "d-flex justify-content-end"
    }, [_c('InputCurrency', {
      attrs: {
        "type": "number"
      },
      model: {
        value: data.total,
        callback: function callback($$v) {
          _vm.$set(data, "total", $$v);
        },
        expression: "data.total"
      }
    })], 1) : _c('h6', [_vm._v("Rp. " + _vm._s(data.total ? _vm.numberFormat(data.total) : '-'))])], 1)]);
  }), 0), _c('tfoot', [_c('tr', [_c('th', {
    staticStyle: {
      "background": "#FFEFE1"
    }
  }), _c('th', {
    staticClass: "text-black",
    staticStyle: {
      "background": "#FFEFE1"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" TOTAL ")]), _c('th', {
    staticClass: "text-black text-right",
    staticStyle: {
      "background": "#FFEFE1"
    }
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.costOfDamagesSumCost)) + " ")]), _c('th', {
    staticClass: "text-black text-right",
    staticStyle: {
      "background": "#FFEFE1"
    }
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.costOfDamagesSumTotal)) + " ")])])])])])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h5', {
    staticClass: "my-2 border-bottom pb-2",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("D.2 Cause & Effect (Findings) - For Details please see attachment of Fishbone Diagram for each Witness")])])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h5', {
    staticClass: "text-white",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" D.2.1 ")])]), _c('h5', [_vm._v("Human")])])]), _c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Proximity Level")]), _vm.isEditMode ? _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.findingsByPrefix(1), function (data, index) {
    var _data$text, _data$proximity;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" D.2.1-" + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "required": "",
        "rows": "2"
      },
      model: {
        value: data.text,
        callback: function callback($$v) {
          _vm.$set(data, "text", $$v);
        },
        expression: "data.text"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$text = data.text) !== null && _data$text !== void 0 ? _data$text : '-'))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_vm.isEditMode ? _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      model: {
        value: data.proximity,
        callback: function callback($$v) {
          _vm.$set(data, "proximity", $$v);
        },
        expression: "data.proximity"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("(Please select)")]), _vm._l(5, function (n) {
      return _c('b-form-select-option', {
        key: n,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    })], 2) : _c('h6', [_vm._v(_vm._s((_data$proximity = data.proximity) !== null && _data$proximity !== void 0 ? _data$proximity : '-'))])], 1), _vm.isEditMode ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFindings(data.value);
        }
      }
    }, [_vm._v("Remove")])], 1) : _vm._e()]);
  }), 0)]), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addFindings(1);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('LevelIndocator')], 1)], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h5', {
    staticClass: "text-white",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" D.2.2 ")])]), _c('h5', [_vm._v("Machinery/Equipment etc")])])]), _c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Proximity Level")]), _vm.isEditMode ? _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.findingsByPrefix(2), function (data, index) {
    var _data$text2, _data$proximity2;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" D.2.2-" + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "required": "",
        "rows": "2"
      },
      model: {
        value: data.text,
        callback: function callback($$v) {
          _vm.$set(data, "text", $$v);
        },
        expression: "data.text"
      }
    }) : _vm._e(), _c('h6', [_vm._v(_vm._s((_data$text2 = data.text) !== null && _data$text2 !== void 0 ? _data$text2 : '-'))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_vm.isEditMode ? _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      model: {
        value: data.proximity,
        callback: function callback($$v) {
          _vm.$set(data, "proximity", $$v);
        },
        expression: "data.proximity"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("(Please select)")]), _vm._l(5, function (n) {
      return _c('b-form-select-option', {
        key: n,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    })], 2) : _c('h6', [_vm._v(_vm._s((_data$proximity2 = data.proximity) !== null && _data$proximity2 !== void 0 ? _data$proximity2 : '-'))])], 1), _vm.isEditMode ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFindings(data.value);
        }
      }
    }, [_vm._v("Remove")])], 1) : _vm._e()]);
  }), 0)]), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addFindings(2);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('LevelIndocator')], 1)], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h5', {
    staticClass: "text-white",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" D.2.3 ")])]), _c('h5', [_vm._v("Control, System, Method,Process")])])]), _c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Proximity Level")]), _vm.isEditMode ? _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.findingsByPrefix(3), function (data, index) {
    var _data$text3, _data$proximity3;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" D.2.3-" + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "required": "",
        "rows": "2"
      },
      model: {
        value: data.text,
        callback: function callback($$v) {
          _vm.$set(data, "text", $$v);
        },
        expression: "data.text"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$text3 = data.text) !== null && _data$text3 !== void 0 ? _data$text3 : '-'))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_vm.isEditMode ? _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      model: {
        value: data.proximity,
        callback: function callback($$v) {
          _vm.$set(data, "proximity", $$v);
        },
        expression: "data.proximity"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("(Please select)")]), _vm._l(5, function (n) {
      return _c('b-form-select-option', {
        key: n,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    })], 2) : _c('h6', [_vm._v(_vm._s((_data$proximity3 = data.proximity) !== null && _data$proximity3 !== void 0 ? _data$proximity3 : '-'))])], 1), _vm.isEditMode ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFindings(data.value);
        }
      }
    }, [_vm._v("Remove")])], 1) : _vm._e()]);
  }), 0)]), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addFindings(3);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('LevelIndocator')], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h5', {
    staticClass: "text-white",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" D.2.4 ")])]), _c('h5', [_vm._v("Other Factor")])])]), _c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Proximity Level")]), _vm.isEditMode ? _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.findingsByPrefix(4), function (data, index) {
    var _data$proximity4;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" D.2.4-" + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
      attrs: {
        "required": "",
        "rows": "2"
      },
      model: {
        value: data.text,
        callback: function callback($$v) {
          _vm.$set(data, "text", $$v);
        },
        expression: "data.text"
      }
    }) : _c('h6', [_vm._v(_vm._s(data.text))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      model: {
        value: data.proximity,
        callback: function callback($$v) {
          _vm.$set(data, "proximity", $$v);
        },
        expression: "data.proximity"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("(Please select)")]), _vm._l(5, function (n, i) {
      return _c('b-form-select-option', {
        key: i,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    })], 2) : _c('h6', [_vm._v(_vm._s((_data$proximity4 = data.proximity) !== null && _data$proximity4 !== void 0 ? _data$proximity4 : '-'))])], 1), _vm.isEditMode ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFindings(data.value);
        }
      }
    }, [_vm._v("Remove")])], 1) : _vm._e()]);
  }), 0)]), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addFindings(4);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('LevelIndocator')], 1)], 1)], 1) : _vm._e(), _vm.activeTab == 5 ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 px-3 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h5', {
    staticClass: "text-white",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" E.1 ")])]), _c('h5', [_vm._v("Initial Claim Analysis")])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_vm.detailSurvey ? _c('div', [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "responsive": "",
      "width": "100%",
      "striped": ""
    }
  }, [_c('b-thead', [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "width": "64px"
    }
  }, [_vm._v("NO")]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
    return _c('b-th', {
      key: headerIndex
    }, [_vm._v(_vm._s(header.label_name))]);
  })], 2), _vm.detailSurvey.headers[0] ? _c('b-tbody', [_vm._l(_vm.detailSurvey.headers[0].contents.length, function (number, idx) {
    return _c('b-tr', {
      key: idx
    }, [_c('b-td', {
      staticClass: "text-center",
      attrs: {
        "width": "64px"
      }
    }, [_vm._v(_vm._s(number))]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
      return _c('b-td', {
        key: headerIndex
      }, [header.contents[number - 1] ? [headerIndex == 2 ? [_c('b-badge', {
        staticClass: "p-2 px-3",
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "variant": "success"
        }
      }, [_vm._v(" " + _vm._s(header.contents[number - 1].input.value) + " ")])] : [header.contents[number - 1].text ? [_vm._v(" " + _vm._s(header.contents[number - 1].text) + " ")] : header.contents[number - 1].input ? [_vm._v(" " + _vm._s(header.contents[number - 1].input.value) + " ")] : [_vm._v(" - ")]]] : _vm._e()], 2);
    })], 2);
  }), _c('b-tr', {
    staticStyle: {
      "background": "#FFEFE1"
    }
  }, [_c('b-td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('h5', [_c('b', [_vm._v("Nilai Seluruhnya")])])]), _c('b-td', [_c('b-badge', {
    staticClass: "p-2 px-3",
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalAll) + " ")])], 1), _c('b-td', [_vm._v(" ")])], 1)], 2) : _vm._e()], 1)], 1) : _c('div', [_vm._v(" - ")]), _vm.detailFillSurvey && _vm.detailFillSurvey.claim_analysis ? [_c('div', {
    staticClass: "d-flex p-3",
    staticStyle: {
      "background": "#F9FAFB"
    }
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Conclusion")]), _c('div', [_vm._v(" " + _vm._s(_vm.detailFillSurvey.claim_analysis.conclusion || '-') + " ")])]), _c('div', {}, [_c('h6', {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Comments")]), _c('div', [_vm._v(" " + _vm._s(_vm.detailFillSurvey.claim_analysis.comments || '-') + " ")])])])] : _vm._e()], 2)])], 1), _c('b-row', {
    staticClass: "my-4"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 px-3 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h5', {
    staticClass: "text-white",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" E.2 ")])]), _c('h5', [_vm._v("Document Control")])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Document")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Status")]), _vm.isEditMode ? _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _vm.form.document_controls.length ? _c('tbody', _vm._l(_vm.form.document_controls, function (data, index) {
    var _data$document2, _data$remarks4, _data$status;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "required": "",
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.document,
        callback: function callback($$v) {
          _vm.$set(data, "document", $$v);
        },
        expression: "data.document"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$document2 = data.document) !== null && _data$document2 !== void 0 ? _data$document2 : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$remarks4 = data.remarks) !== null && _data$remarks4 !== void 0 ? _data$remarks4 : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-select', {
      attrs: {
        "plain": ""
      },
      model: {
        value: data.status,
        callback: function callback($$v) {
          _vm.$set(data, "status", $$v);
        },
        expression: "data.status"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("Status")]), _c('b-form-select-option', {
      attrs: {
        "value": "Top Priority"
      }
    }, [_vm._v("Top Priority")]), _c('b-form-select-option', {
      attrs: {
        "value": "Priority"
      }
    }, [_vm._v("Priority")])], 1) : _vm._e(), _c('h6', [_vm._v(_vm._s((_data$status = data.status) !== null && _data$status !== void 0 ? _data$status : '-'))])], 1), _vm.isEditMode ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeDocumentControls(index);
        }
      }
    }, [_vm._v("Remove")])], 1) : _vm._e()]);
  }), 0) : _vm._e()]), !_vm.form.document_controls.length ? _c('h6', {
    staticClass: "text-center"
  }, [_vm._v("No Data Available")]) : _vm._e()]), _vm.isEditMode ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addDocumentControls();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 px-3 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h5', {
    staticClass: "text-white",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" E.3 ")])]), _c('h5', [_vm._v("Cost of Loss")])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "3%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "22%"
    }
  }, [_vm._v("Description")]), _c('th', {
    attrs: {
      "width": "22%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    staticClass: "text-right",
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Cost")]), _c('th', {
    staticClass: "text-right pr-5",
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Total")]), _vm.isEditMode ? _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.loss_costs, function (data, index) {
    var _data$description2, _data$remarks5;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center",
      attrs: {
        "width": "3%"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.description,
        callback: function callback($$v) {
          _vm.$set(data, "description", $$v);
        },
        expression: "data.description"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$description2 = data.description) !== null && _data$description2 !== void 0 ? _data$description2 : '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    }) : _c('h6', [_vm._v(_vm._s((_data$remarks5 = data.remarks) !== null && _data$remarks5 !== void 0 ? _data$remarks5 : '-'))])], 1), _c('td', {
      staticClass: "text-right"
    }, [_vm.isEditMode ? _c('b-input-group', {
      attrs: {
        "prepend": "Rp"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "number"
      },
      model: {
        value: data.cost,
        callback: function callback($$v) {
          _vm.$set(data, "cost", $$v);
        },
        expression: "data.cost"
      }
    })], 1) : _c('h6', [_vm._v("Rp. " + _vm._s(_vm.numberFormat(data.cost)))])], 1), _c('td', {
      staticClass: "text-right pr-5"
    }, [_vm.isEditMode ? _c('b-input-group', {
      attrs: {
        "prepend": "Rp"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "number"
      },
      model: {
        value: data.total,
        callback: function callback($$v) {
          _vm.$set(data, "total", $$v);
        },
        expression: "data.total"
      }
    })], 1) : _c('h6', [_vm._v("Rp. " + _vm._s(_vm.numberFormat(data.total)))])], 1), _vm.isEditMode ? _c('td', {
      staticClass: "text-center"
    }, [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeCostLoss(index);
        }
      }
    }, [_vm._v("Remove")])], 1) : _vm._e()]);
  }), 0), _c('tfoot', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700",
      "colspan": "2"
    }
  }, [_vm._v(" TOTAL ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }), _c('th', {
    staticClass: "text-right",
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.costOfLossSumCost)) + " ")]), _c('th', {
    staticClass: "text-right pr-5",
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.costOfLossSumTotal)) + " ")]), _vm.isEditMode ? _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }) : _vm._e()])])]), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addCostLoss();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2 mb-3 border-bottom"
  }, [_c('div', {
    staticClass: "bg-primary p-2 px-3 mr-2",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('h5', {
    staticClass: "text-white",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" E.4 ")])]), _c('h5', [_vm._v("Impact to Loss Ratio")])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("Individual")])]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Years")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Premium")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Claim Loss")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Loss Ratio")])])]), _vm.detailLossRatio.length ? _c('tbody', [_vm._l(_vm.detailLossRatio, function (ratio, idx) {
    return [ratio.individual ? _c('tr', {
      key: idx
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('strong', [_vm._v(_vm._s(ratio.individual.year))])]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.formatCurrency(ratio.individual.premium)) + ",-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.formatCurrency(ratio.individual.claim_loss)) + ",-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(ratio.individual.loss_ratio) + "%")])]) : _vm._e()];
  })], 2) : _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("No Data Available")])])])]), _c('h6', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("Accumulation")])]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Years")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Acc Premium")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Acc Loss")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Acc Loss Ratio")])])]), _vm.detailLossRatio.length ? _c('tbody', [_vm._l(_vm.detailLossRatio, function (ratio, idx) {
    return [ratio.accumulative ? _c('tr', {
      key: idx
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('strong', [_vm._v(_vm._s(ratio.accumulative.year))])]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.formatCurrency(ratio.accumulative.premium)) + ",-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.formatCurrency(ratio.accumulative.claim_loss)) + ",-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(ratio.accumulative.loss_ratio) + "%")])]) : _vm._e()];
  })], 2) : _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("No Data Available")])])])])])])], 1)], 1) : _vm._e(), _vm.activeTab == 6 ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('div', [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%",
      "rowspan": "2"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%",
      "rowspan": "2"
    }
  }, [_vm._v("Damage")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%",
      "colspan": "6"
    }
  }, [_vm._v("Root Cause & Effect (Findings)")])]), _c('tr', [_c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Human")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Machinery/ Equipment")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Control, System, Method,Process")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Other Factor")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "15%",
      "rowspan": "2"
    }
  }, [_vm._v("Status")]), _vm.isEditMode ? _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%",
      "rowspan": "2"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _vm._l(_vm.form.conclusions, function (data, index) {
    return _c('tbody', {
      key: index
    }, [[_vm._l(data.rows, function (row, indexRow) {
      var _data$damage, _row$equipment, _row$csmp, _row$other;
      return _c('tr', {
        key: indexRow
      }, [indexRow === 0 ? _c('td', {
        staticClass: "text-center",
        attrs: {
          "rowspan": data.rows.length + 1
        }
      }, [_vm._v(" " + _vm._s(index + 1) + " ")]) : _vm._e(), indexRow === 0 ? _c('td', {
        attrs: {
          "rowspan": data.rows.length + 1
        }
      }, [_vm.isEditMode ? [_c('b-form-textarea', {
        attrs: {
          "required": "",
          "rows": "3"
        },
        model: {
          value: data.damage,
          callback: function callback($$v) {
            _vm.$set(data, "damage", $$v);
          },
          expression: "data.damage"
        }
      }, [_vm._v("4")]), _c('b-button', {
        attrs: {
          "size": "sm",
          "variant": "link"
        },
        on: {
          "click": function click($event) {
            return _vm.removeConclusion(index);
          }
        }
      }, [_vm._v("Remove")])] : _c('h6', [_vm._v(_vm._s((_data$damage = data.damage) !== null && _data$damage !== void 0 ? _data$damage : '-'))])], 2) : _vm._e(), _c('td', {
        staticClass: "text-center"
      }, [_vm.isEditMode ? _c('b-form-select', {
        attrs: {
          "required": "",
          "plain": ""
        },
        model: {
          value: row.human,
          callback: function callback($$v) {
            _vm.$set(row, "human", $$v);
          },
          expression: "row.human"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": "-"
        }
      }, [_vm._v("-")]), _vm._l(_vm.findingsByPrefix(1), function (finding, idx) {
        return _c('b-form-select-option', {
          key: idx,
          attrs: {
            "value": finding.value
          }
        }, [_vm._v(_vm._s(finding.value))]);
      })], 2) : _c('h6', [_vm._v(_vm._s(row.human))])], 1), _c('td', {
        staticClass: "text-center"
      }, [_vm.isEditMode ? _c('b-form-select', {
        attrs: {
          "required": "",
          "plain": ""
        },
        model: {
          value: row.equipment,
          callback: function callback($$v) {
            _vm.$set(row, "equipment", $$v);
          },
          expression: "row.equipment"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": "-"
        }
      }, [_vm._v("-")]), _vm._l(_vm.findingsByPrefix(2), function (finding, idx) {
        return _c('b-form-select-option', {
          key: idx,
          attrs: {
            "value": finding.value
          }
        }, [_vm._v(_vm._s(finding.value))]);
      })], 2) : _c('h6', [_vm._v(_vm._s((_row$equipment = row.equipment) !== null && _row$equipment !== void 0 ? _row$equipment : '-'))])], 1), _c('td', {
        staticClass: "text-center"
      }, [_vm.isEditMode ? _c('b-form-select', {
        attrs: {
          "required": "",
          "plain": ""
        },
        model: {
          value: row.csmp,
          callback: function callback($$v) {
            _vm.$set(row, "csmp", $$v);
          },
          expression: "row.csmp"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": "-"
        }
      }, [_vm._v("-")]), _vm._l(_vm.findingsByPrefix(3), function (finding, idx) {
        return _c('b-form-select-option', {
          key: idx,
          attrs: {
            "value": finding.value
          }
        }, [_vm._v(_vm._s(finding.value))]);
      })], 2) : _c('h6', [_vm._v(_vm._s((_row$csmp = row.csmp) !== null && _row$csmp !== void 0 ? _row$csmp : '-'))])], 1), _c('td', {
        staticClass: "text-center"
      }, [_vm.isEditMode ? _c('b-form-select', {
        attrs: {
          "required": "",
          "plain": ""
        },
        model: {
          value: row.other,
          callback: function callback($$v) {
            _vm.$set(row, "other", $$v);
          },
          expression: "row.other"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": "-"
        }
      }, [_vm._v("-")]), _vm._l(_vm.findingsByPrefix(4), function (finding, idx) {
        return _c('b-form-select-option', {
          key: idx,
          attrs: {
            "value": finding.value
          }
        }, [_vm._v(_vm._s(finding.value))]);
      })], 2) : _c('h6', [_vm._v(_vm._s((_row$other = row.other) !== null && _row$other !== void 0 ? _row$other : '-'))])], 1), _c('td', {
        staticClass: "d-flex justify-content-center"
      }, [_vm.isEditMode ? _c('b-form-select', {
        attrs: {
          "plain": ""
        },
        model: {
          value: row.status,
          callback: function callback($$v) {
            _vm.$set(row, "status", $$v);
          },
          expression: "row.status"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": null
        }
      }, [_vm._v("Status")]), _c('b-form-select-option', {
        attrs: {
          "value": "fatal"
        }
      }, [_vm._v("Fatal")]), _c('b-form-select-option', {
        attrs: {
          "value": "danger"
        }
      }, [_vm._v("Danger")]), _c('b-form-select-option', {
        attrs: {
          "value": "alert"
        }
      }, [_vm._v("Alert")]), _c('b-form-select-option', {
        attrs: {
          "value": "normal"
        }
      }, [_vm._v("Normal")])], 1) : _c('div', {
        staticClass: "px-4 text-xs text-capitalize",
        class: {
          'bg-danger text-white': row.status === 'fatal',
          'bg-warning text-white': row.status === 'danger',
          'bg-info text-white': row.status === 'alert',
          'bg-success text-white': row.status === 'normal'
        },
        staticStyle: {
          "width": "max-content",
          "border-radius": "8px"
        }
      }, [_vm._v(_vm._s(row.status))])], 1), _vm.isEditMode ? _c('td', {
        staticClass: "text-center"
      }, [indexRow > 0 ? _c('b-button', {
        attrs: {
          "variant": "danger"
        },
        on: {
          "click": function click($event) {
            return _vm.removeConclusionRow(index, indexRow);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-trash"
      })]) : _vm._e()], 1) : _vm._e()]);
    }), _vm.isEditMode ? _c('tr', [_c('td', {
      attrs: {
        "colspan": "6"
      }
    }, [_c('b-button', {
      staticClass: "mt-2 mb-3",
      attrs: {
        "variant": "warning",
        "block": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addConclusionRow(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-plus"
    }), _vm._v(" Add More Data")])], 1)]) : _vm._e()]], 2);
  })], 2), _vm.isEditMode ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addConclusion();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)])])], 1)], 1) : _vm._e(), _vm.activeTab == 7 ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('div', [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "30%",
      "rowspan": "2"
    }
  }, [_vm._v("Helpful")]), _c('th', {
    attrs: {
      "width": "30%",
      "rowspan": "2"
    }
  }, [_vm._v("Harmful")])])]), _c('tbody', [_c('tr', [_c('td', [_c('div', {
    staticClass: "font-500"
  }, [_vm._v("Strength")]), _vm.isEditMode ? _c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.recommendation.strength,
      callback: function callback($$v) {
        _vm.$set(_vm.form.recommendation, "strength", $$v);
      },
      expression: "form.recommendation.strength"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$recommendati = _vm.form.recommendation.strength) !== null && _vm$form$recommendati !== void 0 ? _vm$form$recommendati : '-'))])], 1), _c('td', [_c('div', {
    staticClass: "font-500"
  }, [_vm._v("Weakness")]), _vm.isEditMode ? _c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.recommendation.weakness,
      callback: function callback($$v) {
        _vm.$set(_vm.form.recommendation, "weakness", $$v);
      },
      expression: "form.recommendation.weakness"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$recommendati2 = _vm.form.recommendation.weakness) !== null && _vm$form$recommendati2 !== void 0 ? _vm$form$recommendati2 : '-'))])], 1)]), _c('tr', [_c('td', [_c('div', {
    staticClass: "font-500"
  }, [_vm._v("Opportunity")]), _vm.isEditMode ? _c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.recommendation.opportunity,
      callback: function callback($$v) {
        _vm.$set(_vm.form.recommendation, "opportunity", $$v);
      },
      expression: "form.recommendation.opportunity"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$recommendati3 = _vm.form.recommendation.opportunity) !== null && _vm$form$recommendati3 !== void 0 ? _vm$form$recommendati3 : '-'))])], 1), _c('td', [_c('div', {
    staticClass: "font-500"
  }, [_vm._v("Threat")]), _vm.isEditMode ? _c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.recommendation.threat,
      callback: function callback($$v) {
        _vm.$set(_vm.form.recommendation, "threat", $$v);
      },
      expression: "form.recommendation.threat"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$recommendati4 = _vm.form.recommendation.threat) !== null && _vm$form$recommendati4 !== void 0 ? _vm$form$recommendati4 : '-'))])], 1)])])])])])])], 1)], 1) : _vm._e(), _vm.isEditMode ? _c('b-col', {
    staticClass: "mb-3 text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.form._saving,
      "type": "submit"
    }
  }, [_vm._v(_vm._s(_vm.form._saving ? 'Menyimpan...' : 'Simpan'))])], 1) : _vm._e()], 1)], 1) : _vm._e()] : _c('div', {
    staticClass: "d-flex justify-content-center py-5 my-5"
  }, [_c('b-spinner')], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$data, _vm$data2, _vm$data3, _vm$data3$question_co, _vm$data4, _vm$data20;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-4"
  }, [_vm.data ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.exporting
    },
    on: {
      "click": function click($event) {
        return _vm.onExport(_vm.inspectionChecklistData.id, 'pdf');
      }
    }
  }, [_vm.exporting ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('i', {
    staticClass: "fa fa-file mr-2"
  }), _vm._v(" Export PDF ")])], 1), !_vm.loading_fleet_form ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.title))])])], 1), _vm._l((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.field, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": state === null || state === void 0 ? void 0 : state.label
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.handleTypeDate(state === null || state === void 0 ? void 0 : state.value)))])])], 1);
  })], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), _vm.loading_fleet_form ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('i', {
    staticClass: "spinner-border spinner-border-sm text-primary mr-2"
  }), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("Fetching forms, please wait a moment.")])])]) : _vm._e(), !_vm.loading_fleet_form ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Question Type Content")]), ((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$question_co = _vm$data3.question_content) === null || _vm$data3$question_co === void 0 ? void 0 : _vm$data3$question_co.length) > 0 ? _c('div', {
    staticClass: "w-100"
  }, _vm._l((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.question_content, function (parentQuestion, parentQuestionIndex) {
    var _parentQuestion$inspe, _vm$data5, _vm$data6, _vm$data7, _vm$data7$question_ty, _vm$data8, _vm$data8$question_ty, _vm$data9, _vm$data9$question_co;
    return _c('table', {
      key: parentQuestionIndex,
      staticClass: "table table-striped"
    }, [_c('thead', [!parentQuestion.parent_id && (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe === void 0 ? void 0 : _parentQuestion$inspe.length) <= 0 ? _c('tr', [_c('th', {
      staticClass: "align-middle text-center",
      attrs: {
        "colspan": "6"
      }
    }, [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))])]) : _c('tr', [_c('th', {
      staticStyle: {
        "max-width": "20px"
      }
    }, [_vm._v("No")]), _c('th', [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))]), Array.isArray((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : _vm$data5.question_type_content) ? _vm._l((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : _vm$data6.question_type_content, function (questionType, questionTypeIndex) {
      return _c('th', {
        key: questionTypeIndex
      }, [_vm._v(" " + _vm._s(questionType === null || questionType === void 0 ? void 0 : questionType.label) + " ")]);
    }) : [['date', 'single-lines-text-box', 'datetime', 'numerical-value'].includes((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : (_vm$data7$question_ty = _vm$data7.question_type) === null || _vm$data7$question_ty === void 0 ? void 0 : _vm$data7$question_ty.code) ? _c('th', [_vm._v(_vm._s((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : (_vm$data8$question_ty = _vm$data8.question_type_content) === null || _vm$data8$question_ty === void 0 ? void 0 : _vm$data8$question_ty.label))]) : _vm._e()], _vm.data.using_remarks ? _c('th', [_vm._v("Remarks")]) : _vm._e()], 2)]), ((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : (_vm$data9$question_co = _vm$data9.question_content) === null || _vm$data9$question_co === void 0 ? void 0 : _vm$data9$question_co.length) > 0 ? _c('tbody', _vm._l(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.inspection_checklist_question, function (question, questionIndex) {
      var _vm$data10, _vm$data10$question_t, _vm$data11, _vm$data12, _vm$data12$question_t, _vm$data13, _vm$data14, _vm$data14$question_t, _vm$data15, _vm$data15$question_t, _vm$data16, _vm$data17, _vm$data17$question_t, _vm$data18, _vm$data18$question_t, _vm$data19, _vm$data19$question_t;
      return _c('tr', {
        key: questionIndex
      }, [_c('td', {
        staticStyle: {
          "max-width": "20px"
        }
      }, [_vm._v(_vm._s(questionIndex + 1))]), _c('td', [_vm._v(_vm._s(question.question))]), ((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : (_vm$data10$question_t = _vm$data10.question_type) === null || _vm$data10$question_t === void 0 ? void 0 : _vm$data10$question_t.code) == 'multiple-choice-multiple-answers-allowed' ? _vm._l((_vm$data11 = _vm.data) === null || _vm$data11 === void 0 ? void 0 : _vm$data11.question_type_content, function (questionType, questionTypeIndex) {
        var _question$answer, _question$answer$ques;
        return _c('td', {
          key: questionTypeIndex
        }, [_c('span', [_vm._v(_vm._s((_question$answer = question.answer) !== null && _question$answer !== void 0 && (_question$answer$ques = _question$answer[questionTypeIndex]) !== null && _question$answer$ques !== void 0 && _question$answer$ques.value ? '✔️' : ''))])]);
      }) : _vm._e(), ((_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : (_vm$data12$question_t = _vm$data12.question_type) === null || _vm$data12$question_t === void 0 ? void 0 : _vm$data12$question_t.code) == 'multiple-choice-only-one-answer' ? _vm._l((_vm$data13 = _vm.data) === null || _vm$data13 === void 0 ? void 0 : _vm$data13.question_type_content, function (questionType, questionTypeIndex) {
        var _question$answer2, _question$answer2$que;
        return _c('td', {
          key: questionTypeIndex
        }, [_c('span', [_vm._v(_vm._s((_question$answer2 = question.answer) !== null && _question$answer2 !== void 0 && (_question$answer2$que = _question$answer2[questionTypeIndex]) !== null && _question$answer2$que !== void 0 && _question$answer2$que.value ? '✔️' : ''))])]);
      }) : _vm._e(), ['single-lines-text-box'].includes((_vm$data14 = _vm.data) === null || _vm$data14 === void 0 ? void 0 : (_vm$data14$question_t = _vm$data14.question_type) === null || _vm$data14$question_t === void 0 ? void 0 : _vm$data14$question_t.code) ? [_c('td', [_c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question === null || question === void 0 ? void 0 : question.answer)))])])] : _vm._e(), ['multiple-lines-text-box'].includes((_vm$data15 = _vm.data) === null || _vm$data15 === void 0 ? void 0 : (_vm$data15$question_t = _vm$data15.question_type) === null || _vm$data15$question_t === void 0 ? void 0 : _vm$data15$question_t.code) ? _vm._l((_vm$data16 = _vm.data) === null || _vm$data16 === void 0 ? void 0 : _vm$data16.question_type_content, function (questionType, questionTypeIndex) {
        return _c('td', {
          key: questionTypeIndex
        }, [_c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question.answer[questionTypeIndex].value)))])]);
      }) : _vm._e(), ['numerical-value'].includes((_vm$data17 = _vm.data) === null || _vm$data17 === void 0 ? void 0 : (_vm$data17$question_t = _vm$data17.question_type) === null || _vm$data17$question_t === void 0 ? void 0 : _vm$data17$question_t.code) ? [_c('td', [_c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question.answer)))])])] : _vm._e(), ['date'].includes((_vm$data18 = _vm.data) === null || _vm$data18 === void 0 ? void 0 : (_vm$data18$question_t = _vm$data18.question_type) === null || _vm$data18$question_t === void 0 ? void 0 : _vm$data18$question_t.code) ? [_c('td', [_c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question.answer)))])])] : _vm._e(), ['datetime'].includes((_vm$data19 = _vm.data) === null || _vm$data19 === void 0 ? void 0 : (_vm$data19$question_t = _vm$data19.question_type) === null || _vm$data19$question_t === void 0 ? void 0 : _vm$data19$question_t.code) ? [_c('td', [_c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question.answer)))])])] : _vm._e(), _vm.data.using_remarks ? _c('td', [_c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question === null || question === void 0 ? void 0 : question.remarks)))])]) : _vm._e()], 2);
    }), 0) : _vm._e()]);
  }), 0) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), !_vm.loading_fleet_form ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_vm._l((_vm$data20 = _vm.data) === null || _vm$data20 === void 0 ? void 0 : _vm$data20.signature, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "3"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": state === null || state === void 0 ? void 0 : state.label
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.handleTypeDate(state === null || state === void 0 ? void 0 : state.value)))])])], 1);
  })], 2)], 1) : _vm._e()], 1)], 1) : _vm._e(), !_vm.data ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('span', [_vm._v("No Project Forms Data.")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return _vm._l(_vm.steps, function (item, index) {
          return _c('div', {
            key: index
          }, [_c('div', {
            staticClass: "border p-3 mb-2 d-flex align-items-center justify-content-between cursor-pointer",
            staticStyle: {
              "border-radius": "8px"
            },
            on: {
              "click": function click($event) {
                item.collapse = !item.collapse;
              }
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('i', {
            staticClass: "fa fa-caret-right mr-2 caret-animation",
            class: {
              'rotate-0': !item.collapse,
              'rotate-90': item.collapse
            }
          }), _c('p', {
            staticClass: "m-0",
            staticStyle: {
              "font-size": "16px",
              "font-weight": "500"
            }
          }, [_vm._v(_vm._s(item.title))]), _c('p', {
            staticClass: "m-0 ml-2",
            class: {
              'text-success': item.isDataExist,
              'text-danger': !item.isDataExist
            },
            staticStyle: {
              "font-size": "14px",
              "font-weight": "500"
            }
          }, [_vm._v(_vm._s(item.isDataExist ? '(Data Has Been Inputted)' : '(No Data Yet)'))])]), item.isDataExist ? _c('i', {
            staticClass: "fa fa-check text-success"
          }) : _c('i', {
            staticClass: "fa fa-ban text-danger"
          })]), _c('b-collapse', {
            staticClass: "mt-4",
            attrs: {
              "id": "step-".concat(index)
            },
            model: {
              value: item.collapse,
              callback: function callback($$v) {
                _vm.$set(item, "collapse", $$v);
              },
              expression: "item.collapse"
            }
          }, [item.component ? [item.collapse ? _c(item.component, {
            tag: "component",
            attrs: {
              "detailMi": _vm.detailMi,
              "companies": _vm.companies
            },
            on: {
              "openModalEmail": _vm.handleOpenModalEmail
            }
          }) : _vm._e()] : _vm._e()], 2)], 1);
        });
      },
      proxy: true
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "send-email-modal",
      "size": "lg",
      "title": "Send Email ".concat(_vm.emailTitle),
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 border my-2"
  })]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mt-2",
    attrs: {
      "label": "Notif mail to user",
      "label-for": "notifMailUser"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Notif user",
      "label": "name",
      "track-by": "id",
      "options": _vm.filteredExpertUsers,
      "multiple": true
    },
    model: {
      value: _vm.notifMailUser,
      callback: function callback($$v) {
        _vm.notifMailUser = $$v;
      },
      expression: "notifMailUser"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Data not found.")])])], 1)], 1), _vm.emailTitle === 'Accident' ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mt-2",
    attrs: {
      "label": "Approval mail to user",
      "label-for": "approvalMailUser"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Notif user",
      "label": "name",
      "track-by": "id",
      "options": _vm.filteredExpertUsers,
      "multiple": true
    },
    model: {
      value: _vm.approvalMailUser,
      callback: function callback($$v) {
        _vm.approvalMailUser = $$v;
      },
      expression: "approvalMailUser"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Data not found.")])])], 1)], 1) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 border my-2"
  })]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendEmailToUser.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-send mr-2"
  }), _vm._v(" Send Mail")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
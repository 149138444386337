<template>
  <ApexChart
    :element="`line-chart-budget-technical-${element}`"
    :chartOption="lineChartOption"
  />
</template>
<script>
export default {
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    element: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lineChartOption: {
        chart: {
          type: 'line',
          height: 300,
          zoom: {
            enabled: false,
          },
        },
        series: [
          {
            data: this.chartData,
            color: '#334697',
          },
        ],
        stroke: {
          curve: 'straight',
          width: [3],
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Dec',
          ],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val // memformat label sumbu y menjadi persentase
            },
          },
          max: 100, // set nilai maksimum pada sumbu y
          tickAmount: 5,
        },
        fill: {
          opacity: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        title: {
          text: this.title,
          floating: true,
          offsetY: 0,
          opacity: '0.7',
          align: 'center',
          style: {
            color: '#0088AF',
          },
        },
      },
      count: 0,
    }
  },
}
</script>

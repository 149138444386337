<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-row>
          <b-col lg="3" class="px-3">
            <div class="d-flex mb-2" >
              <b-form-input placeholder="Search fleet..." class="mr-2" @input="filterFleetByName" v-model="searchFleet" />
              <b-button variant="primary" size="sm" @click="onOpenSlideDetailVehicle">Select</b-button>
            </div>
            <div class="d-flex flex-column overflow-auto px-3 mb-4 mt-2" style="max-height:100vh">
              <div  v-for="(fleet, i) in filteredItems" :key="`fleet-${i}`" @click="onCheckedFleet(fleet, i)">
                <b-form-checkbox type="checkbox" :checked="fleet.checked"></b-form-checkbox>
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <img 
                    :src="require('@src/assets/images/fleet/PusherBarge.png')"
                    alt="#"
                    style="width:200px; height:100px"
                  />
                  <small><strong>{{ fleet?.text }}</strong></small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col lg="9">
            <b-row>
              <b-col lg="12" class="mt-2 mb-4">
                <div class="">
                  <tab-nav :pills="true" class=" d-flex">
                    <tab-nav-items
                      class="p-0"
                      :active="true"
                      href="#operational"
                      title="Operational"
                    />
                    <tab-nav-items
                      class="p-0"
                      :active="false"
                      href="#certificate"
                      title="Certificate"
                    /> 
                    <tab-nav-items
                      v-if="fleetId && fleetDetail?.vehicleType?.crewing"
                      class="p-0"
                      :active="false"
                      href="#list-crew"
                      title="List Crew"
                    />  
                  </tab-nav>
                </div>
              </b-col>
              <b-col lg="12">
                <div id="pills-tabContent-2" class="tab-content">
                  <tab-content-item :active="true" id="operational">
                    <template v-if="fleetId">
                      <TabOperational :fleet-id="fleetId" :preview="true" />
                    </template>
                    <div class="alert alert-dark m-4" v-else>
                     Select Fleet
                    </div>
                  </tab-content-item>
                  <tab-content-item :active="false" id="certificate">
                    <template v-if="fleetId">
                      <TabCertificate :fleet-id="fleetId" :preview="true" />
                    </template>
                    <div class="alert alert-dark m-4" v-else>
                     Select Fleet
                    </div>
                  </tab-content-item>
                  <tab-content-item :active="false" id="list-crew" v-if="fleetId && fleetDetail?.vehicleType?.crewing">
                    <template v-if="crewList?.data?.body?.length > 0 ">
                      <TableContentCrewList  
                        :table-data="crewList.data"
                        :indexes="crewList.meta.indexes"
                        :current-page="crewList.meta.currentPage"
                        :isPreview="true"
                      />
                    </template>
                    <template v-else>
                      <strong class="text-primary">Data List Crew Not Found.</strong>
                    </template>
                  </tab-content-item>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { fleetsActions, crewingActions } from '@src/Utils/helper'
  import TabOperational from '@src/components/Operational/TabOperational.vue'
  import TabCertificate from '@src/components/certificate/TabCertificate.vue'
  import TableContentCrewList from '@/src/components/crewing/crew/TableContentCrewList.vue'

  export default {
    name:'SlidePreviewDetailVehicle',
    props:{
      fleet: {
        type: Number,
        default: 0,
      },
      ownership: {
        type:String,
        default: ''
      },
      existedFleet: {
        type:Array,
        default: () => []
      }, 
      optionsFleets: {
        type:Array,
        default: () => []
      },
      onOpenSlideDetailVehicle: {
        type:Function,
        default: true
      }
    },
    components:{
      TabOperational,
      TabCertificate,
      TableContentCrewList,
    },
    data(){
      return{
        newOptionsFleets:[],

        searchFleet:'',
        filteredItems: [],

        fleetId: null,
        fleetDetail: null,

        crewList: {
          data: {
            head: [],
            body: [],
          },
          meta: {
            perPage: 0,
            currentPage: 0,
            total: 0,
            totalPage: 0,
            indexes:1
          },
        },
      }
    },
    watch:{
      'ownership': {
        deep:true,
        immediate:true,
        handler(){
          this.createNewOptionsFleet()
        }
      },
      'fleet':{
        handler(value){
          this.filteredItems.map(val => {
            if(val.value === this.fleet){
              val.checked = true
            } else {
              val.checked = false
            }
            return val
          })
        }
      },
      'fleetId':{
        handler(value){
          console.log(value, "FLEET ID")
          this.fetchFleetDetail(value)
        }
      },
      'optionsFleets':{
        // deep:true,
        immediate:true,
        handler(value){
          this.createNewOptionsFleet()
        }
      }
    },
    mounted(){},
    methods:{
      ...crewingActions,
      ...fleetsActions,
      async fetchFleetDetail (vehicleId) {
        const res = await this.getFleetById(vehicleId)

        if (res.status === 'success') {
          this.fleetDetail = res.data

          const params = {
            page: 1,
            vehicle_id: vehicleId,
          }

          const { status, data } = await this.fetchCrewList(params)

          if (status === 'success' && data.data) {
            this.crewList.meta.perPage = data.per_page
            this.crewList.meta.currentPage = data.current_page
            this.crewList.meta.total = data.total
            this.crewList.meta.totalPage = Math.round(data.total / data.per_page)
            this.crewList.meta.indexes = data.from

            this.crewList.data.body = data.data
            this.crewList.data.head = [
              'NO',
              'NAMA',
              'FLEET',
              'JABATAN',
              'JOIN ON BOARD',
              'SIGN ON',
              'SIGN OFF',
              'STATUS',
            ]
          }
        }
      },
      onCheckedFleet(data, object){
        let existed = this.existedFleet?.map(obj => obj.vehicle_id)

        if(existed.includes(data.value)) return this.$swal(
            'Already Selected!',
            'This vehicle already selected, choose others',
            'warning'
          )
        
        this.filteredItems.map(val => {
          if(val.value === data.value){
            val.checked = true
            this.fleetId = val.value
            this.updateParent()
          } else {
            val.checked = false
          }
          return val
        })
      },
      updateParent() {
        // Emit an event to notify the parent component about the change
        this.$emit('updateVehicleId', this.fleetId);
      },
      createNewOptionsFleet(){
        this.newOptionsFleets = []

        this.optionsFleets?.map(val => {
          this.newOptionsFleets.push({
            ...val,
            checked:false
          })
        })

        this.filteredItems = this.newOptionsFleets
        
      },
      filterFleetByName() {
        this.filteredItems = this.newOptionsFleets?.filter(item => {
          // Use item.name.toLowerCase() for case-insensitive search
          return item.text.toLowerCase().includes(this.searchFleet.toLowerCase());
        });
      }
    }
  }
</script>

<template>
  <b-row>
    <b-col md="12">
      <iq-card headerClass="bg-head-cargo-analysis">
        <template v-slot:headerTitle>
          <h4 class="card-title text-white"><strong>BUDGET vs ACTUAL CARGO - PT.ENERGY TRANSPORTER INDONESIA</strong></h4>
        </template>
        <template v-slot:body>
          <div style="min-height: 350px;">
            <div class="d-flex justify-content-between mb-3">
              <div class="">
                <h5 class="text-primary"
                  ><strong>{{ chartSeries[chartIdx].title }}</strong></h5
                >
              </div>
              <div class="d-flex btn-group">
                <b-button
                  :variant="chartIdx == 0 ? 'outline-primary' : 'primary'"
                  size="sm"
                  class="mr-2"
                  :disabled="chartIdx == 0"
                  @click="prev()"
                >
                  <i class="fas fa-chevron-left"></i>
                </b-button>
                <b-button
                  :variant="
                    chartIdx + 1 == chartSeries.length
                      ? 'outline-primary'
                      : 'primary'
                  "
                  size="sm"
                  :disabled="chartIdx + 1 == chartSeries.length"
                  @click="next()"
                >
                  <i class="fas fa-chevron-right"></i>
                </b-button>
              </div>
            </div>
            <div v-for="(chart, index) in chartSeries" :key="index">
              <template v-if="index == chartIdx">
                <ChartCargoAnalysis
                  :series="chart.series"
                  :element="`${index}`"
                />
              </template>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>

    <b-col md="12">
      <iq-card headerClass="bg-head-cargo-analysis">
        <template v-slot:headerTitle>
          <h4 class="card-title text-white"><strong>BUDGET vs ACTUAL CARGO BASE PROJECT - PT.ENERGY TRANSPORTER INDONESIA</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="6">
              <div style="min-height: 350px">
                <div v-for="(chart, index) in barSeries" :key="index">
                  <template v-if="index == chartIdx">
                    <BarCargoAnalysis
                      :series="chart.series"
                      :element="`${index}`"
                    />
                  </template>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div style="height: 350px">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Project</th>
                      <th class="text-right">Budget</th>
                      <th class="text-right">Actual</th>
                      <th class="text-right">Varance</th>
                      <th class="text-right">Varance %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-truncate">AI PROJECT - PLTU (BG)</td>
                      <td class="text-right">1.778.500</td>
                      <td class="text-right">2.246.237</td>
                      <td class="text-right">(467.737)</td>
                      <td class="text-right">-26,30%</td>
                    </tr>
                    <tr>
                      <td class="text-truncate">AI PROJECT - PLTU (BG)</td>
                      <td class="text-right">1.778.500</td>
                      <td class="text-right">2.246.237</td>
                      <td class="text-right">(467.737)</td>
                      <td class="text-right">-26,30%</td>
                    </tr>
                    <tr>
                      <td class="text-truncate">AI PROJECT - PLTU (BG)</td>
                      <td class="text-right">1.778.500</td>
                      <td class="text-right">2.246.237</td>
                      <td class="text-right">(467.737)</td>
                      <td class="text-right">-26,30%</td>
                    </tr>
                    <tr>
                      <td class="text-truncate">AI PROJECT - PLTU (BG)</td>
                      <td class="text-right">1.778.500</td>
                      <td class="text-right">2.246.237</td>
                      <td class="text-right">(467.737)</td>
                      <td class="text-right">-26,30%</td>
                    </tr>
                    <tr>
                      <td class="text-truncate">AI PROJECT - PLTU (BG)</td>
                      <td class="text-right">1.778.500</td>
                      <td class="text-right">2.246.237</td>
                      <td class="text-right">(467.737)</td>
                      <td class="text-right">-26,30%</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style="background-color: #008000" class="text-white">
                      <th>TOTAL</th>
                      <th class="text-right">4.324.500</th>
                      <th class="text-right">4.324.500</th>
                      <th class="text-right">4.324.500</th>
                      <th class="text-right">30,40%</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import ChartCargoAnalysis from './chart/ChartCargoAnalysis.vue'
import BarCargoAnalysis from './chart/BarCargoAnalysis.vue'

export default {
  name: 'CargoAnalysis',
  components: {
    ChartCargoAnalysis,
    BarCargoAnalysis,
  },
  data() {
    return {
      chartIdx: 0,
      chartSeries: [
        {
          series: [
            {
              name: 'Target',
              type: 'bar',
              data: [50, 73, 31, 40, 41, 49, 65, 85, 90, 60, 70, 80],
              labels: {
                formatter: (val) => {
                  return val + '%' // memformat label sumbu y menjadi persentase
                },
              },
            },
            {
              name: 'Budget',
              type: 'line',
              data: [40, 80, 37, 36, 44, 45, 50, 58, 90, 60, 50, 70],
              color:'red',
              labels: {
                formatter: (val) => {
                  return val + '%' // memformat label sumbu y menjadi persentase
                },
              },
            },
          ],
        },
        {
          series: [
            {
              name: 'Target',
              type: 'bar',
              data: [30, 50, 70, 80, 75, 90, 78, 85, 90, 70, 70, 80],
              labels: {
                formatter: (val) => {
                  return val + '%' // memformat label sumbu y menjadi persentase
                },
              },
            },
            {
              name: 'Budget',
              type: 'line',
              data: [60, 90, 80, 90, 60, 69, 90, 79, 60, 70, 80, 90],
              color:'red',
              labels: {
                formatter: (val) => {
                  return val + '%' // memformat label sumbu y menjadi persentase
                },
              },
            },
          ],
        },
        {
          series: [
            {
              name: 'Target',
              type: 'bar',
              data: [69, 90, 79, 60, 70, 80, 65, 85, 90, 60, 70, 80],
              labels: {
                formatter: (val) => {
                  return val + '%' // memformat label sumbu y menjadi persentase
                },
              },
            },
            {
              name: 'Budget',
              type: 'line',
              data: [20, 29, 37, 60, 90, 80, 90, 60, 69, 90, 50, 70],
              color:'red',
              labels: {
                formatter: (val) => {
                  return val + '%' // memformat label sumbu y menjadi persentase
                },
              },
            },
          ],
        },
      ],

      barSeries: [
        {
          series: [
            {
              name: 'Budget',
              type: 'bar',
              data: [50, 73, 31, 40, 41, 49],
              color:'#FFA500',
            },
            {
              name: 'Actual',
              type: 'bar',
              data: [40, 80, 37, 36, 44, 45],
              color:'#3a5de7',
            },
          ],
        },
      ],
    }
  },
  methods: {
    next() {
      this.chartIdx = this.chartIdx + 1
    },
    prev() {
      this.chartIdx = this.chartIdx - 1
    },
  },
}
</script>
<style>
.bg-head-cargo-analysis {
  background: #07c607;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-4"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 iq-bg-info"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary h6"
  }, [_c('strong', [_vm._v("LOADING ACTIVITY RECORD")])]), _c('b-form-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Port of Loading",
      "label-for": "port_of_loading"
    }
  }, [_c('v-select', {
    staticClass: "bg-white",
    attrs: {
      "label": "text",
      "options": _vm.positionList,
      "reduce": function reduce(positionList) {
        return positionList.value;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.formOperationalActivity.portLoading,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "portLoading", $$v);
      },
      expression: "formOperationalActivity.portLoading"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Agent POL",
      "label-for": "agent_pol"
    }
  }, [_c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "type": "text",
      "id": "agent_pol",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.formOperationalActivity.agentPol,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "agentPol", $$v);
      },
      expression: "formOperationalActivity.agentPol"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Is Pol Sts Mv:",
      "label-for": "kind-fleet"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formOperationalActivity.polStsMv,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polStsMv", $$v);
      },
      expression: "formOperationalActivity.polStsMv"
    }
  }, [_vm._v(" No / Yes ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Pol Mv Name",
      "label-for": "pol_mv_name"
    }
  }, [_c('b-form-input', {
    class: {
      'bg-white': _vm.formOperationalActivity.polStsMv
    },
    attrs: {
      "type": "text",
      "id": "pol_mv_name",
      "placeholder": "Type Text",
      "disabled": !_vm.formOperationalActivity.polStsMv
    },
    model: {
      value: _vm.formOperationalActivity.polMvName,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polMvName", $$v);
      },
      expression: "formOperationalActivity.polMvName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pol grab Type",
      "label-for": "pod_grab_type"
    }
  }, [_c('v-select', {
    staticClass: "bg-white",
    attrs: {
      "label": "text",
      "options": _vm.polGrabTypeOpt,
      "reduce": function reduce(item) {
        return item.value;
      },
      "placeholder": "Select Pol Grab Type"
    },
    model: {
      value: _vm.formOperationalActivity.polGrabType,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polGrabType", $$v);
      },
      expression: "formOperationalActivity.polGrabType"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Pol Fc Name",
      "label-for": "pol_fc_name"
    }
  }, [_c('b-form-input', {
    class: {
      'bg-white': _vm.formOperationalActivity.polGrabType == 'gear_and_grab'
    },
    attrs: {
      "disabled": _vm.formOperationalActivity.polGrabType !== 'gear_and_grab',
      "type": "text",
      "id": "pol_mv_name",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.formOperationalActivity.polFcName,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polFcName", $$v);
      },
      expression: "formOperationalActivity.polFcName"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "NOR",
      "label-for": "nor"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    model: {
      value: _vm.formOperationalActivity.polNor,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polNor", $$v);
      },
      expression: "formOperationalActivity.polNor"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "NOA",
      "label-for": "noa"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    model: {
      value: _vm.formOperationalActivity.polNoa,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polNoa", $$v);
      },
      expression: "formOperationalActivity.polNoa"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Anchor Up",
      "label-for": "polAnchorUp"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.polArrivalTime, _vm.formOperationalActivity.polAnchorUp, 'Anchor Up', 'Arrival Time', 1);
      }
    },
    model: {
      value: _vm.formOperationalActivity.polAnchorUp,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polAnchorUp", $$v);
      },
      expression: "formOperationalActivity.polAnchorUp"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 1 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "In Position / Berthing",
      "label-for": "polBerthing"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.polAnchorUp, _vm.formOperationalActivity.polBerthing, 'Berthing', 'Anchor Up', 2);
      }
    },
    model: {
      value: _vm.formOperationalActivity.polBerthing,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polBerthing", $$v);
      },
      expression: "formOperationalActivity.polBerthing"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 2 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Commenced",
      "label-for": "polCommenced"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.polBerthing, _vm.formOperationalActivity.polCommenced, 'Commenced', 'Berthing', 3);
      }
    },
    model: {
      value: _vm.formOperationalActivity.polCommenced,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polCommenced", $$v);
      },
      expression: "formOperationalActivity.polCommenced"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 3 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Completed",
      "label-for": "completed"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.polCommenced, _vm.formOperationalActivity.polCompleted, 'Completed', 'Commenced', 4);
      }
    },
    model: {
      value: _vm.formOperationalActivity.polCompleted,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polCompleted", $$v);
      },
      expression: "formOperationalActivity.polCompleted"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 4 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Draught Survey",
      "label-for": "polDraughtSurvey"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "type": "number",
      "step": "0.0001",
      "id": "polDraughtSurvey",
      "placeholder": "Type Number"
    },
    model: {
      value: _vm.formOperationalActivity.polDraughtSurvey,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polDraughtSurvey", $$v);
      },
      expression: "formOperationalActivity.polDraughtSurvey"
    }
  })], 1), _c('span', {
    staticClass: "text-black"
  }, [_vm._v(_vm._s(_vm.formOperationalActivity.polDraughtSurvey ? Number(_vm.formOperationalActivity.polDraughtSurvey).toLocaleString() : 0))])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "BL Date",
      "label-for": "blDate"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    model: {
      value: _vm.formOperationalActivity.polBlDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polBlDate", $$v);
      },
      expression: "formOperationalActivity.polBlDate"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 5 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Cast In",
      "label-for": "polCastIn"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    model: {
      value: _vm.formOperationalActivity.polCastIn,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polCastIn", $$v);
      },
      expression: "formOperationalActivity.polCastIn"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 5 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Cast out",
      "label-for": "polCastOut"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.polCompleted, _vm.formOperationalActivity.polCastOut, 'Cast Out', 'Completed', 5);
      }
    },
    model: {
      value: _vm.formOperationalActivity.polCastOut,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polCastOut", $$v);
      },
      expression: "formOperationalActivity.polCastOut"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 5 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "LHV",
      "label-for": "polLhv"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.polCastOut, _vm.formOperationalActivity.polLhv, 'LHV', 'Cast Out', 6);
      }
    },
    model: {
      value: _vm.formOperationalActivity.polLhv,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polLhv", $$v);
      },
      expression: "formOperationalActivity.polLhv"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 6 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _vm.showDocumentOnBoard ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Document On Board POL",
      "label-for": "document_on_board"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.polLhv, _vm.formOperationalActivity.polDocOnBoard, 'Doc on Board', 'LHV', 7);
      }
    },
    model: {
      value: _vm.formOperationalActivity.polDocOnBoard,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polDocOnBoard", $$v);
      },
      expression: "formOperationalActivity.polDocOnBoard"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 7 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1) : _vm._e(), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Departure to POD",
      "label-for": "polDeparture"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.polDocOnBoard, _vm.formOperationalActivity.polDeparture, 'Departure', 'Doc on Board', 8);
      }
    },
    model: {
      value: _vm.formOperationalActivity.polDeparture,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "polDeparture", $$v);
      },
      expression: "formOperationalActivity.polDeparture"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 8 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Remarks",
      "label-for": "polCastIn"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Text..."
    }
  })], 1)], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 iq-bg-info"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary h6"
  }, [_c('strong', [_vm._v("DISCHARGE ACTIVITY RECORD")])]), _c('b-form-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Port of Discharge",
      "label-for": "port_of_discharge"
    }
  }, [_c('v-select', {
    staticClass: "bg-white",
    attrs: {
      "label": "text",
      "options": _vm.positionList,
      "reduce": function reduce(positionList) {
        return positionList.value;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.formOperationalActivity.portDischarge,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "portDischarge", $$v);
      },
      expression: "formOperationalActivity.portDischarge"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Agent POD",
      "label-for": "agent_pod"
    }
  }, [_c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "type": "text",
      "id": "agent_pod",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.formOperationalActivity.agentPod,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "agentPod", $$v);
      },
      expression: "formOperationalActivity.agentPod"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Is Pod Sts Mv:",
      "label-for": "pod_sts_mv"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formOperationalActivity.podStsMv,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podStsMv", $$v);
      },
      expression: "formOperationalActivity.podStsMv"
    }
  }, [_vm._v(" No / Yes ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Pod Mv Name",
      "label-for": "pod_mv_name"
    }
  }, [_c('b-form-input', {
    class: {
      'bg-white': _vm.formOperationalActivity.podStsMv
    },
    attrs: {
      "type": "text",
      "id": "pol_mv_name",
      "placeholder": "Type Text",
      "disabled": !_vm.formOperationalActivity.podStsMv
    },
    model: {
      value: _vm.formOperationalActivity.podMvName,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podMvName", $$v);
      },
      expression: "formOperationalActivity.podMvName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pod grab Type",
      "label-for": "pod_grab_type"
    }
  }, [_c('v-select', {
    staticClass: "bg-white",
    attrs: {
      "label": "text",
      "options": _vm.polGrabTypeOpt,
      "reduce": function reduce(item) {
        return item.value;
      },
      "placeholder": "Select POd Grab Type"
    },
    model: {
      value: _vm.formOperationalActivity.podGrabType,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podGrabType", $$v);
      },
      expression: "formOperationalActivity.podGrabType"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Pod Fc Name",
      "label-for": "pod_fc_name"
    }
  }, [_c('b-form-input', {
    class: {
      'bg-white': _vm.formOperationalActivity.podGrabType == 'gear_and_grab'
    },
    attrs: {
      "disabled": _vm.formOperationalActivity.podGrabType !== 'gear_and_grab',
      "type": "text",
      "id": "pol_mv_name",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.formOperationalActivity.podFcName,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podFcName", $$v);
      },
      expression: "formOperationalActivity.podFcName"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Arrival Time POD",
      "label-for": "arrival_time_pod"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.currentValidTime, _vm.formOperationalActivity.podArrivalTime);
      }
    },
    model: {
      value: _vm.formOperationalActivity.podArrivalTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podArrivalTime", $$v);
      },
      expression: "formOperationalActivity.podArrivalTime"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "NOR",
      "label-for": "nor"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    model: {
      value: _vm.formOperationalActivity.podNor,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podNor", $$v);
      },
      expression: "formOperationalActivity.podNor"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "NOA",
      "label-for": "noa"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    model: {
      value: _vm.formOperationalActivity.podNoa,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podNoa", $$v);
      },
      expression: "formOperationalActivity.podNoa"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Anchor Up",
      "label-for": "podAnchorUp"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.podArrivalTime, _vm.formOperationalActivity.podAnchorUp, 'Anchor Up', 'Arrival Time', 11);
      }
    },
    model: {
      value: _vm.formOperationalActivity.podAnchorUp,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podAnchorUp", $$v);
      },
      expression: "formOperationalActivity.podAnchorUp"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 11 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "In Position / Berthing",
      "label-for": "in_position_pod"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.podAnchorUp, _vm.formOperationalActivity.podBerthing, 'Berthing', 'Anchor Up', 12);
      }
    },
    model: {
      value: _vm.formOperationalActivity.podBerthing,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podBerthing", $$v);
      },
      expression: "formOperationalActivity.podBerthing"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 12 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Commenced",
      "label-for": "commenced_pod"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.podBerthing, _vm.formOperationalActivity.podCommenced, 'Commenced', 'Berthing', 13);
      }
    },
    model: {
      value: _vm.formOperationalActivity.podCommenced,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podCommenced", $$v);
      },
      expression: "formOperationalActivity.podCommenced"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 13 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Completed",
      "label-for": "completed_pod"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.podCommenced, _vm.formOperationalActivity.podCompleted, 'Completed', 'Commenced', 14);
      }
    },
    model: {
      value: _vm.formOperationalActivity.podCompleted,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podCompleted", $$v);
      },
      expression: "formOperationalActivity.podCompleted"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 14 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Draught Survey",
      "label-for": "podDraughtSurvey"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "type": "number",
      "step": "0.0001",
      "id": "podDraughtSurvey",
      "placeholder": "Type Number"
    },
    model: {
      value: _vm.formOperationalActivity.podDraughtSurvey,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podDraughtSurvey", $$v);
      },
      expression: "formOperationalActivity.podDraughtSurvey"
    }
  })], 1), _c('span', {
    staticClass: "text-black"
  }, [_vm._v(_vm._s(_vm.formOperationalActivity.podDraughtSurvey ? Number(_vm.formOperationalActivity.podDraughtSurvey).toLocaleString() : 0))])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "BL Date",
      "label-for": "blDate"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    model: {
      value: _vm.formOperationalActivity.podBlDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podBlDate", $$v);
      },
      expression: "formOperationalActivity.podBlDate"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Cast In",
      "label-for": "polCastIn"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    model: {
      value: _vm.formOperationalActivity.podCastIn,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podCastIn", $$v);
      },
      expression: "formOperationalActivity.podCastIn"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 5 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Cast Out",
      "label-for": "podCastOut"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.podCompleted, _vm.formOperationalActivity.podCastOut, 'Cast Out', 'Completed', 15);
      }
    },
    model: {
      value: _vm.formOperationalActivity.podCastOut,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podCastOut", $$v);
      },
      expression: "formOperationalActivity.podCastOut"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 15 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _vm.showDocumentOnBoard ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Document On Board POD",
      "label-for": "document_on_board"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.podLhv, _vm.formOperationalActivity.docOnBoard, 'Doc on Board', 'LHV', 17);
      }
    },
    model: {
      value: _vm.formOperationalActivity.docOnBoard,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "docOnBoard", $$v);
      },
      expression: "formOperationalActivity.docOnBoard"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 17 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1) : _vm._e(), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Departure to POL",
      "label-for": "podDeparture"
    }
  }, [_c('date-picker', {
    staticClass: "custom-date-picker w-100",
    attrs: {
      "value-type": "DD-MM-YYYY HH:mm",
      "type": "datetime",
      "placeholder": "Select date",
      "format": "DD-MM-YYYY HH:mm"
    },
    on: {
      "change": function change($event) {
        return _vm.validationTime(_vm.formOperationalActivity.docOnBoard, _vm.formOperationalActivity.podDeparture, 'Departure', 'Doc on Board', 18);
      }
    },
    model: {
      value: _vm.formOperationalActivity.podDeparture,
      callback: function callback($$v) {
        _vm.$set(_vm.formOperationalActivity, "podDeparture", $$v);
      },
      expression: "formOperationalActivity.podDeparture"
    }
  })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 18 ? _c('small', {
    staticClass: "text-danger",
    staticStyle: {
      "margin-top": "-30px"
    }
  }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Remarks",
      "label-for": "polCastIn"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Text..."
    }
  })], 1)], 1)], 1)], 1)]), _c('b-col', {
    staticClass: "text-right mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "button",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.saveActivity();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Save")]), _c('b-button', {
    staticClass: "iq-bg-danger ml-3",
    attrs: {
      "type": "button",
      "variant": "none"
    },
    on: {
      "click": function click($event) {
        return _vm.closeSlideEditOperational();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-close"
  }), _vm._v(" Cancel Edit")])], 1)], 1)], 1) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$currencies$find, _vm$currencies$find2, _vm$currencies$find3, _vm$currencies$find4, _vm$currencies$find5, _vm$currencies$find6, _vm$currencies$find7, _vm$currencies$find8, _vm$currencies$find9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.detailMi ? _c('div', {
    staticClass: "px-3"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveHeader.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditMode = !_vm.isEditMode;
      }
    }
  }, [_vm._v(_vm._s(_vm.isEditMode ? 'Preview' : 'Edit') + " Management Summary")])], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('h6', {
    staticClass: "card-title text-primary border-bottom pb-2"
  }, [_c('b', [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company *",
      "label-for": "company"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.company.company))])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet Name *",
      "label-for": "fleets"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.vehicle.name))])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Loss (DOL) *",
      "label-for": "dol"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.date_of_loss))])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Place of Loss (POL) *",
      "label-for": "pol"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.place_of_loss))])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Coverage *",
      "label-for": "coverage"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.type_coverage.name))])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Loss *",
      "label-for": "loss"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.type_loss.name))])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('h6', {
    staticClass: "card-title text-primary border-bottom pb-2"
  }, [_c('b', [_vm._v("COVERAGE INFORMATION OF THE VESSEL")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Insurer",
      "label-for": "insurer"
    }
  }, [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.insurer_id || '-'))])] : _c('b-form-input', {
    attrs: {
      "required": "",
      "type": "text",
      "disabled": _vm.permission_user === 'MANAGEMENT'
    },
    model: {
      value: _vm.form.insurer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "insurer_id", $$v);
      },
      expression: "form.insurer_id"
    }
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Policy Number",
      "label-for": "name"
    }
  }, [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.policy_number || '-'))])] : _c('b-form-input', {
    attrs: {
      "required": "",
      "type": "text",
      "disabled": _vm.permission_user === 'MANAGEMENT'
    },
    model: {
      value: _vm.form.policy_number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "policy_number", $$v);
      },
      expression: "form.policy_number"
    }
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Periode of Coverage",
      "label-for": "position"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, _vm._l(_vm.form.coverage_period, function (item, i) {
    return _c('h6', {
      key: i,
      staticClass: "form-control-static"
    }, [_vm._v(_vm._s(item || '-'))]);
  }), 0)])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Surveyor",
      "label-for": "insurer"
    }
  }, [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.surveyor || '-'))])] : _c('b-form-input', {
    attrs: {
      "type": "text",
      "required": "",
      "disabled": _vm.permission_user === 'MANAGEMENT'
    },
    model: {
      value: _vm.form.surveyor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyor", $$v);
      },
      expression: "form.surveyor"
    }
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Adjuster",
      "label-for": "insurer"
    }
  }, [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.adjuster || '-'))])] : _c('b-form-input', {
    attrs: {
      "type": "text",
      "required": "",
      "disabled": _vm.permission_user === 'MANAGEMENT'
    },
    model: {
      value: _vm.form.adjuster,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "adjuster", $$v);
      },
      expression: "form.adjuster"
    }
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Total Sum Insured")]), !_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_insurer_id ? (_vm$currencies$find = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_sum_insurer_id;
  })) === null || _vm$currencies$find === void 0 ? void 0 : _vm$currencies$find.alias : '-') + " " + _vm._s(_vm.form.sum_insured || '-'))])] : _c('b-row', [_c('b-col', {
    staticClass: "pr-2",
    attrs: {
      "md": "4"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      },
      "disabled": _vm.permission_user === 'MANAGEMENT'
    },
    model: {
      value: _vm.form.currency_sum_insurer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_sum_insurer_id", $$v);
      },
      expression: "form.currency_sum_insurer_id"
    }
  })], 1), _c('b-col', {
    staticClass: "pl-2",
    attrs: {
      "md": "8"
    }
  }, [_c('InputCurrency', {
    attrs: {
      "disabled": _vm.permission_user === 'MANAGEMENT',
      "placeholder": "Input Currency"
    },
    model: {
      value: _vm.form.sum_insured,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sum_insured", $$v);
      },
      expression: "form.sum_insured"
    }
  })], 1)], 1)], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Limit of Liability")]), !_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_insurer_id ? (_vm$currencies$find2 = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_limit_of_liability_id;
  })) === null || _vm$currencies$find2 === void 0 ? void 0 : _vm$currencies$find2.alias : '-') + " " + _vm._s(_vm.form.limit_of_liability || '-'))])] : _c('b-row', [_c('b-col', {
    staticClass: "pr-2",
    attrs: {
      "md": "4"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      },
      "disabled": _vm.permission_user === 'MANAGEMENT'
    },
    model: {
      value: _vm.form.currency_limit_of_liability_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_limit_of_liability_id", $$v);
      },
      expression: "form.currency_limit_of_liability_id"
    }
  })], 1), _c('b-col', {
    staticClass: "pl-2",
    attrs: {
      "md": "8"
    }
  }, [_c('InputCurrency', {
    attrs: {
      "placeholder": "Input Currency",
      "disabled": _vm.permission_user === 'MANAGEMENT'
    },
    model: {
      value: _vm.form.limit_of_liability,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "limit_of_liability", $$v);
      },
      expression: "form.limit_of_liability"
    }
  })], 1)], 1)], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Deductible")]), !_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_insurer_id ? (_vm$currencies$find3 = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_deductible_id;
  })) === null || _vm$currencies$find3 === void 0 ? void 0 : _vm$currencies$find3.alias : '-') + " " + _vm._s(_vm.form.deductible || '-'))])] : _c('b-row', [_c('b-col', {
    staticClass: "pr-2",
    attrs: {
      "md": "4"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      },
      "disabled": _vm.permission_user === 'MANAGEMENT'
    },
    model: {
      value: _vm.form.currency_deductible_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_deductible_id", $$v);
      },
      expression: "form.currency_deductible_id"
    }
  })], 1), _c('b-col', {
    staticClass: "pl-2",
    attrs: {
      "md": "8"
    }
  }, [_c('InputCurrency', {
    attrs: {
      "disabled": _vm.permission_user === 'MANAGEMENT',
      "placeholder": "Input Currency"
    },
    model: {
      value: _vm.form.deductible,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "deductible", $$v);
      },
      expression: "form.deductible"
    }
  })], 1)], 1)], 2)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('h6', {
    staticClass: "card-title text-primary border-bottom pb-2"
  }, [_c('b', [_vm._v("CLAIM PROPOSED")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual or Riil Cost",
      "label-for": "Actual"
    }
  }, [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_actual_id ? (_vm$currencies$find4 = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_actual_id;
  })) === null || _vm$currencies$find4 === void 0 ? void 0 : _vm$currencies$find4.alias : '-') + " " + _vm._s(_vm.form.claim_proposed_actual || '-'))])] : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      }
    },
    model: {
      value: _vm.form.currency_actual_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_actual_id", $$v);
      },
      expression: "form.currency_actual_id"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-group', [_c('InputCurrency', {
    attrs: {
      "placeholder": "Input Currency"
    },
    model: {
      value: _vm.form.claim_proposed_actual,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "claim_proposed_actual", $$v);
      },
      expression: "form.claim_proposed_actual"
    }
  })], 1)], 1)], 1)], 2)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Proposed Cost to Insurer",
      "label-for": "Actual"
    }
  }, [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_insurer_id ? (_vm$currencies$find5 = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_insurer_id;
  })) === null || _vm$currencies$find5 === void 0 ? void 0 : _vm$currencies$find5.alias : '-') + " " + _vm._s(_vm.form.claim_proposed_insurer || '-'))])] : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      }
    },
    model: {
      value: _vm.form.currency_insurer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_insurer_id", $$v);
      },
      expression: "form.currency_insurer_id"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-group', [_c('InputCurrency', {
    attrs: {
      "placeholder": "Input Currency"
    },
    model: {
      value: _vm.form.claim_proposed_insurer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "claim_proposed_insurer", $$v);
      },
      expression: "form.claim_proposed_insurer"
    }
  })], 1)], 1)], 1)], 2)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimate cost Approved by Insurer",
      "label-for": "estimate"
    }
  }, [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_approved_id ? (_vm$currencies$find6 = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_approved_id;
  })) === null || _vm$currencies$find6 === void 0 ? void 0 : _vm$currencies$find6.alias : '-') + " " + _vm._s(_vm.form.claim_proposed_approved || '-'))])] : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      }
    },
    model: {
      value: _vm.form.currency_approved_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_approved_id", $$v);
      },
      expression: "form.currency_approved_id"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-group', [_c('InputCurrency', {
    attrs: {
      "placeholder": "Input Currency"
    },
    model: {
      value: _vm.form.claim_proposed_approved,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "claim_proposed_approved", $$v);
      },
      expression: "form.claim_proposed_approved"
    }
  })], 1)], 1)], 1)], 2)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('h5', {
    staticClass: "card-title text-primary d-flex justify-content-between"
  }, [_c('b', [_vm._v(_vm._s(_vm.claimProposedInputMode ? 'ACTUAL CLAIM PROGRESS' : 'RECORD CLAIM'))]), !_vm.claimProposedInputMode && _vm.role !== 'MANAGEMENT' ? _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.add-record-claim",
      modifiers: {
        "add-record-claim": true
      }
    }],
    staticClass: "ml-3",
    attrs: {
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "ion-plus"
  }), _vm._v("New Record")]) : _vm._e()], 1), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col",
      "colspan": "2"
    }
  }, [_vm._v("Update")]), _c('th', {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "scope": "col",
      "rowspan": "2"
    }
  }, [_vm._v("Update Claim")]), _c('th', {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "scope": "col",
      "rowspan": "2"
    }
  }, [_vm._v("Barriers")]), _c('th', {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "scope": "col",
      "rowspan": "2"
    }
  }, [_vm._v("Est. Settle Date")]), _c('th', {
    attrs: {
      "scope": "col",
      "colspan": "2"
    }
  }, [_vm._v("Management")]), _c('th', {
    attrs: {
      "scope": "col",
      "rowspan": "2"
    }
  }, [_vm._v("Action")])]), _c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Date")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("By User")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Comment")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("User & Date")])])]), _c('tbody', _vm._l(_vm.claimPurposed, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(data.date ? _vm.moment(data.date).format('DD MMM YYYY') : '-') + " ")]), _c('td', [_vm._v(_vm._s(data.creator_user || '-'))]), _c('td', [_vm._v(_vm._s(data.update_claim || '-'))]), _c('td', [_vm._v(_vm._s(data.barriers || '-'))]), _c('td', [_vm._v(" " + _vm._s(data.date ? _vm.moment(data.date).format('DD MMM YYYY') : '-') + " "), _c('p', [_vm._v(_vm._s(data.follow_up_response || '-'))])]), _c('td', [_vm._v(_vm._s(data.management_comment || '-'))]), _c('td', [_vm._v(_vm._s(data.management_user || '-') + " "), _c('p', [_vm._v(_vm._s(data.management_date || '-'))])]), _c('td', [_c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.add-record-claim",
        modifiers: {
          "add-record-claim": true
        }
      }],
      staticClass: "mr-2",
      attrs: {
        "variant": "success",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onPopulateFormClaimPurposed(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-comment"
    })]), _vm.role == 'SUPER ADMIN' || 'Super Admin' || 'Marine Insurance' || 'MARINE INSURANCE' ? _c('b-button', {
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteClaimRecord(data.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })]) : _vm._e()], 1)]);
  }), 0)]), _c('b-sidebar', {
    attrs: {
      "width": "600px",
      "id": "add-record-claim",
      "header-class": "bg-white",
      "right": "",
      "backdrop": "",
      "title": "Add Claim Record",
      "body-class": "p-3 m-0"
    }
  }, [_c('div', {
    staticClass: "mx-3"
  }, [_c('b-row', {
    staticClass: "bg-white py-3",
    staticStyle: {
      "border-radius": "12px"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Update Claim"
    }
  }, [_vm.role === 'MANAGEMENT' ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.formChild.update_claim || '-'))]) : _c('b-form-textarea', {
    attrs: {
      "rows": "5"
    },
    model: {
      value: _vm.formChild.update_claim,
      callback: function callback($$v) {
        _vm.$set(_vm.formChild, "update_claim", $$v);
      },
      expression: "formChild.update_claim"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Barries",
      "label-for": "Barries"
    }
  }, [_vm.role === 'MANAGEMENT' ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.formChild.barriers || '-'))]) : _c('b-form-textarea', {
    attrs: {
      "rows": "5"
    },
    model: {
      value: _vm.formChild.barriers,
      callback: function callback($$v) {
        _vm.$set(_vm.formChild, "barriers", $$v);
      },
      expression: "formChild.barriers"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_vm.role === 'MANAGEMENT' ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.formChild.follow_up_response || '-'))]) : _c('b-form-select', {
    attrs: {
      "size": "sm"
    },
    model: {
      value: _vm.formChild.follow_up_response,
      callback: function callback($$v) {
        _vm.$set(_vm.formChild, "follow_up_response", $$v);
      },
      expression: "formChild.follow_up_response"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": "settled"
    }
  }, [_vm._v("Settled")]), _c('b-form-select-option', {
    attrs: {
      "value": "on_progress"
    }
  }, [_vm._v("On Going")]), _c('b-form-select-option', {
    attrs: {
      "value": "withdrawal "
    }
  }, [_vm._v("Withdrawal")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimate settled date",
      "label-for": "estimate"
    }
  }, [_vm.role === 'MANAGEMENT' ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.formChild.date || '-'))]) : _c('date-picker', {
    attrs: {
      "type": "date",
      "value-type": "YYYY-MM-DD",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.formChild.date,
      callback: function callback($$v) {
        _vm.$set(_vm.formChild, "date", $$v);
      },
      expression: "formChild.date"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "pull-right text-right",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.add-record-claim",
      modifiers: {
        "add-record-claim": true
      }
    }],
    attrs: {
      "disabled": _vm.formChild.date === '' || _vm.formChild.update_claim === '' || _vm.formChild.barriers === '' || _vm.formChild.follow_up_response === '',
      "variant": "primary"
    },
    on: {
      "click": _vm.onSaveClaimPurposed
    }
  }, [_vm._v("Submit")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.add-record-claim",
      modifiers: {
        "add-record-claim": true
      }
    }],
    attrs: {
      "variant": "outline"
    }
  }, [_vm._v("Cancel")])], 1)])], 1)], 1)])], 1)]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', {
    staticClass: "bg-warning"
  }, [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("FINAL ADJUSTMENT")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("DEDUCTIBLE")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("NET CLAIM LOST")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("TOTAL CLAIM DAYS")])])]), _c('tbody', [_c('tr', [_c('td', [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_final_adjustment_id ? (_vm$currencies$find7 = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_final_adjustment_id;
  })) === null || _vm$currencies$find7 === void 0 ? void 0 : _vm$currencies$find7.alias : '-') + " " + _vm._s(_vm.form.final_adjusment || '-'))])] : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      }
    },
    model: {
      value: _vm.form.currency_final_adjustment_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_final_adjustment_id", $$v);
      },
      expression: "form.currency_final_adjustment_id"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "required": "",
      "placeholder": "Input Currency"
    },
    model: {
      value: _vm.form.final_adjusment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "final_adjusment", $$v);
      },
      expression: "form.final_adjusment"
    }
  })], 1)], 1)], 2), _c('td', [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_deductible_id ? (_vm$currencies$find8 = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_deductible_id;
  })) === null || _vm$currencies$find8 === void 0 ? void 0 : _vm$currencies$find8.alias : '-') + " " + _vm._s(_vm.form.deductible || '-'))])] : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      }
    },
    model: {
      value: _vm.form.currency_deductible_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_deductible_id", $$v);
      },
      expression: "form.currency_deductible_id"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "required": "",
      "placeholder": "Input Currency"
    },
    model: {
      value: _vm.form.deductible,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "deductible", $$v);
      },
      expression: "form.deductible"
    }
  })], 1)], 1)], 2), _c('td', [!_vm.isEditMode ? [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.currency_net_claim_id ? (_vm$currencies$find9 = _vm.currencies.find(function (currency) {
    return currency.id === _vm.form.currency_net_claim_id;
  })) === null || _vm$currencies$find9 === void 0 ? void 0 : _vm$currencies$find9.alias : '-') + " " + _vm._s(_vm.form.net_claim_cost || '-'))])] : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('v-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "alias",
      "options": _vm.currencies,
      "reduce": function reduce(currency) {
        return currency.id;
      }
    },
    model: {
      value: _vm.form.currency_net_claim_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency_net_claim_id", $$v);
      },
      expression: "form.currency_net_claim_id"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "required": "",
      "placeholder": "Input Currency"
    },
    model: {
      value: _vm.form.net_claim_cost,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "net_claim_cost", $$v);
      },
      expression: "form.net_claim_cost"
    }
  })], 1)], 1)], 2), _c('td', [_c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.form.total_claim_days || '-'))])])])])])])]), _vm.claimProposedInputMode && _vm.role === 'MANAGEMENT' ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', {
    staticClass: "thead-dark"
  }, [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Management Comment")])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-textarea', {
    attrs: {
      "rows": "5"
    },
    model: {
      value: _vm.formChild.management_comment,
      callback: function callback($$v) {
        _vm.$set(_vm.formChild, "management_comment", $$v);
      },
      expression: "formChild.management_comment"
    }
  })], 1)])])])]) : _vm._e(), _vm.claimProposedInputMode && _vm.role === 'MANAGEMENT' ? _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "6"
    }
  }, [_c('hr'), _c('span', {
    staticClass: "mr-5"
  }, [_c('strong', [_vm._v("User")])]), _c('span', {}, [_c('b-form-input', {
    attrs: {
      "rows": "5"
    },
    model: {
      value: _vm.formChild.management_user,
      callback: function callback($$v) {
        _vm.$set(_vm.formChild, "management_user", $$v);
      },
      expression: "formChild.management_user"
    }
  })], 1)]) : _vm._e(), _vm.claimProposedInputMode && _vm.role === 'MANAGEMENT' ? _c('b-col', {
    staticClass: "mt-4 mb-0",
    attrs: {
      "md": "6"
    }
  }, [_c('hr'), _c('span', {
    staticClass: "mr-5"
  }, [_c('strong', [_vm._v("Date")])]), _c('span', {}, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "date",
      "value-type": "YYYY-MM-DD",
      "placeholder": "Select date range"
    },
    model: {
      value: _vm.formChild.management_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formChild, "management_date", $$v);
      },
      expression: "formChild.management_date"
    }
  })], 1)]) : _vm._e(), _vm.isEditMode ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v("Submit")])], 1) : _vm._e()], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
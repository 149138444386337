var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$route.params.id && _vm.isFetching ? _c('div', [_c('p', [_vm._v("Fetching Data...")])]) : _c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("Assessor of the Accident")])]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Name..."
    },
    model: {
      value: _vm.assessor_fullname,
      callback: function callback($$v) {
        _vm.assessor_fullname = $$v;
      },
      expression: "assessor_fullname"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.companyList,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Company")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.assessor_company_id,
      callback: function callback($$v) {
        _vm.assessor_company_id = $$v;
      },
      expression: "assessor_company_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Position"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Position..."
    },
    model: {
      value: _vm.assessor_position,
      callback: function callback($$v) {
        _vm.assessor_position = $$v;
      },
      expression: "assessor_position"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("1")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("PERSONAL DATA OF PERSON INVOLVED")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    staticClass: "text-right text-primary",
    attrs: {
      "md": "1"
    }
  }, [_c('h3', [_vm._v("#1")])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Name..."
    },
    model: {
      value: _vm.personal_fullname,
      callback: function callback($$v) {
        _vm.personal_fullname = $$v;
      },
      expression: "personal_fullname"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sex"
    }
  }, [_vm._l(_vm.state, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      attrs: {
        "inline": "",
        "name": item.name,
        "value": item.value
      },
      model: {
        value: _vm.personal_gender,
        callback: function callback($$v) {
          _vm.personal_gender = $$v;
        },
        expression: "personal_gender"
      }
    }, [_vm._v(_vm._s(item.label))])];
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Place of Birth"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Place of Birth..."
    },
    model: {
      value: _vm.personal_birthplace,
      callback: function callback($$v) {
        _vm.personal_birthplace = $$v;
      },
      expression: "personal_birthplace"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Birth"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "date",
      "format": "YYYY-MM-DD",
      "value-type": "format",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.personal_birthdate,
      callback: function callback($$v) {
        _vm.personal_birthdate = $$v;
      },
      expression: "personal_birthdate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Marital Status"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.marital,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Marital Status")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.personal_marital_status,
      callback: function callback($$v) {
        _vm.personal_marital_status = $$v;
      },
      expression: "personal_marital_status"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.companyList,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Company")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.personal_company_id,
      callback: function callback($$v) {
        _vm.personal_company_id = $$v;
      },
      expression: "personal_company_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Position"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Position..."
    },
    model: {
      value: _vm.personal_position,
      callback: function callback($$v) {
        _vm.personal_position = $$v;
      },
      expression: "personal_position"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Length of Work"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "md",
      "append": "Month"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.working_experience,
      callback: function callback($$v) {
        _vm.working_experience = $$v;
      },
      expression: "working_experience"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Photo"
    }
  }, [_c('b-form-file', {
    attrs: {
      "accept": "image"
    },
    model: {
      value: _vm.personal_photo,
      callback: function callback($$v) {
        _vm.personal_photo = $$v;
      },
      expression: "personal_photo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sign Picture"
    }
  }, [_c('b-form-file', {
    attrs: {
      "accept": "image"
    },
    model: {
      value: _vm.personal_sign,
      callback: function callback($$v) {
        _vm.personal_sign = $$v;
      },
      expression: "personal_sign"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_vm._l(_vm.types, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      attrs: {
        "inline": "",
        "name": item.name,
        "value": item.value
      },
      model: {
        value: _vm.type,
        callback: function callback($$v) {
          _vm.type = $$v;
        },
        expression: "type"
      }
    }, [_vm._v(_vm._s(item.label))])];
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  })], 1), _c('hr')], 1)], 1), _c('hr')], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("2")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("DEFINE THE PROBLEM")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-2 mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "primary"
    }
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("BACKGROUND INFORMATION ABOUT THE ACCIDENT OR PROBLEM")])])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2 mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "bg-light p-4 font-size-18"
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("What part, Product, Process, or Services Involved")])])]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "3"
    },
    model: {
      value: _vm.explanation_damage,
      callback: function callback($$v) {
        _vm.explanation_damage = $$v;
      },
      expression: "explanation_damage"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2 mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "bg-light p-4 font-size-18"
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("Explain the Problem(s)")])])]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "4"
    },
    model: {
      value: _vm.explanation_problem,
      callback: function callback($$v) {
        _vm.explanation_problem = $$v;
      },
      expression: "explanation_problem"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2 mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "bg-light p-4 font-size-18"
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("when in the life cycle of the unit is the problem detected ? (e.g at the source, intial inspection, final inspection, end user, etc)-Explain")])])]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "4"
    },
    model: {
      value: _vm.explanation_detected,
      callback: function callback($$v) {
        _vm.explanation_detected = $$v;
      },
      expression: "explanation_detected"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-2 mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("EXPECTATIONS !")])])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2 mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "bg-light p-4 font-size-18"
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("RESULT OF DEFINING THE PROBLEM")])])]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "4"
    },
    model: {
      value: _vm.explanation_result,
      callback: function callback($$v) {
        _vm.explanation_result = $$v;
      },
      expression: "explanation_result"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("3")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("COLLECTING INFORMATION")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _vm._l(_vm.whys, function (why, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      staticClass: "text-right text-primary",
      attrs: {
        "md": "1"
      }
    }, [_c('h3', [_vm._v("WHY #" + _vm._s(index + 1))]), _c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.whys.splice(index, 1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1), _c('b-col', {
      attrs: {
        "md": "11"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "DEFECT, PROBLEM OR ERROR "
      }
    }, [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "DEFECT, PROBLEM OR ERROR ..."
      },
      model: {
        value: why.defect,
        callback: function callback($$v) {
          _vm.$set(why, "defect", $$v);
        },
        expression: "why.defect"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "CAUSE"
      }
    }, [_c('b-form-textarea', {
      staticClass: "w-100",
      attrs: {
        "placeholder": "CAUSE"
      },
      model: {
        value: why.cause,
        callback: function callback($$v) {
          _vm.$set(why, "cause", $$v);
        },
        expression: "why.cause"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "3"
      }
    }), _c('b-col', {
      attrs: {
        "md": "3"
      }
    })], 1), _c('hr')], 1)], 1);
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addWhys
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)], 1), _c('hr'), _vm._l(_vm.whatisLength, function (index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      staticClass: "text-right text-primary",
      attrs: {
        "md": "1"
      }
    }, [_c('h5', [_vm._v("WHAT IS? WHAT IS NOT? #" + _vm._s(index))]), _c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          _vm.whatisLength -= 1;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1), _c('b-col', {
      attrs: {
        "md": "11"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "WHAT IS?"
      }
    }, [_c('b-form-textarea', {
      staticClass: "w-100",
      attrs: {
        "placeholder": "WHAT IS?"
      },
      model: {
        value: _vm.whatis[index - 1],
        callback: function callback($$v) {
          _vm.$set(_vm.whatis, index - 1, $$v);
        },
        expression: "whatis[index - 1]"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "WHAT IS NOT?"
      }
    }, [_c('b-form-textarea', {
      staticClass: "w-100",
      attrs: {
        "placeholder": "WHAT IS NOT?"
      },
      model: {
        value: _vm.whatisnot[index - 1],
        callback: function callback($$v) {
          _vm.$set(_vm.whatisnot, index - 1, $$v);
        },
        expression: "whatisnot[index - 1]"
      }
    })], 1)], 1)], 1), _c('hr')], 1)], 1);
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addWhatis
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)], 1)], 2), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("4")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("IDENTIFY THE ROOT CAUSE")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vessel"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "value": _vm.vessel.name,
      "placeholder": "Select Vessel",
      "readonly": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "FMEA CASE :"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "FMEA CASE"
    },
    model: {
      value: _vm.fmea_case,
      callback: function callback($$v) {
        _vm.fmea_case = $$v;
      },
      expression: "fmea_case"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "FMEA Date (Orig.) :"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "datetime",
      "format": "YYYY-MM-DD HH:mm:ss",
      "value-type": "format",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.fmea_date,
      callback: function callback($$v) {
        _vm.fmea_date = $$v;
      },
      expression: "fmea_date"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Date of Loss"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "datetime",
      "value": _vm.date_of_loss,
      "placeholder": "Select date and time",
      "readonly": ""
    }
  })], 1)], 1)], 1), _c('hr'), _vm._l(_vm.identificationLength, function (index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      staticClass: "text-right text-primary",
      attrs: {
        "md": "1"
      }
    }, [_c('h3', [_vm._v("#" + _vm._s(index))]), _c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          _vm.identificationLength -= 1;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1), _c('b-col', {
      attrs: {
        "md": "10"
      }
    }, [_c('b-row', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.identifications,
        expression: "identifications"
      }]
    }, [_vm._l(_vm.identifications, function (form) {
      return [_c('b-col', {
        key: form.id,
        attrs: {
          "md": form.description ? '12' : '6'
        }
      }, [form.description ? _c('b-row', {
        staticClass: "mt-2 mb-2"
      }, [_c('b-col', {
        attrs: {
          "md": "12"
        }
      }, [_c('div', {
        staticClass: "bg-light p-4 font-size-18"
      }, [_c('strong', {
        staticClass: "text-center"
      }, [_vm._v(_vm._s(form.title))])])]), _c('b-col', {
        attrs: {
          "md": "12"
        }
      }, [_c('div', {
        staticClass: "bg-yellow p-4"
      }, [_c('div', {
        staticClass: "font-size-16"
      }, [_c('strong', {
        staticClass: "text-center"
      }, [_vm._v(_vm._s(form.description) + " ")])]), _c('b-form-textarea', {
        attrs: {
          "rows": "2"
        },
        model: {
          value: form.answers[index - 1],
          callback: function callback($$v) {
            _vm.$set(form.answers, index - 1, $$v);
          },
          expression: "form.answers[index - 1]"
        }
      })], 1)])], 1) : _c('b-row', {
        staticClass: "mt-2 mb-2"
      }, [form.title == 'RPN' ? [_c('div', [_vm._v(" asdashdjasbdjh ")])] : _vm._e(), _c('b-col', {
        attrs: {
          "md": "12"
        }
      }, [_c('div', {
        staticClass: "p-4 font-size-18",
        class: form.title === 'RPN' ? 'bg-primary' : 'bg-info'
      }, [_c('strong', {
        staticClass: "text-center"
      }, [_vm._v(_vm._s(form.title) + " " + _vm._s(form.title !== 'RPN' ? '(1 to 10)' : '(Risk Priority Number)'))])])]), _c('b-col', {
        attrs: {
          "md": "12"
        }
      }, [_c('div', {
        staticClass: "p-4"
      }, [_vm._v(" tandain "), _c('b-form-input', {
        attrs: {
          "type": "number",
          "readonly": form.title === 'RPN',
          "min": form.title !== 'RPN' ? 1 : '',
          "max": form.title !== 'RPN' ? 10 : ''
        },
        on: {
          "input": function input($event) {
            return _vm.setRPN(index - 1);
          }
        },
        model: {
          value: form.answers[index - 1],
          callback: function callback($$v) {
            _vm.$set(form.answers, index - 1, $$v);
          },
          expression: "form.answers[index - 1]"
        }
      }), form.title === 'Detection' ? _c('table', {
        staticClass: "table w-100 mt-3 table-bordered"
      }, [_c('tr', [_c('th', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#faebd7",
          "width": "30%"
        }
      }, [_vm._v(" RATING ")]), _c('th', {
        attrs: {
          "bgcolor": "#faebd7",
          "width": "70%"
        }
      }, [_vm._v(" DETECTION RATING DESCRIPTION ")])]), _c('tr', [_c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#b22222"
        }
      }, [_vm._v(" 10 ")]), _c('td', [_vm._v(" Controls will not or can not detect the existence of a failure, no known controls available to detect failure mode ")])]), _c('tr', [_c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#b22222"
        }
      }, [_vm._v(" 9 ")]), _c('td', [_vm._v(" controls probably will not detect the exitence of failure ")])]), _c('tr', [_c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#dc143c"
        }
      }, [_vm._v(" 8 ")]), _c('td', {
        attrs: {
          "rowspan": "2"
        }
      }, [_vm._v(" Controls have a poor change of detecting the existence of a failure mode ")])]), _c('tr', [_c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#dc143c"
        }
      }, [_vm._v(" 7 ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 6 ")]), _c('td', {
        attrs: {
          "rowspan": "2"
        }
      }, [_vm._v(" Controls may detect the existence of a failure mode ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 5 ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 4 ")]), _c('td', {
        attrs: {
          "rowspan": "2"
        }
      }, [_vm._v(" Control have a good chance of detecting failure mode, process Minor - relatively few failures and minor impact to automatically detects failure mode ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#ffd700"
        }
      }, [_vm._v(" 3 ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#7fff00"
        }
      }, [_vm._v(" 2 ")]), _c('td', {
        attrs: {
          "rowspan": "2"
        }
      }, [_vm._v(" current control almost centrain to detect the failure mode, reliable detection control are known with similar processes. Process automatically prevents further processing ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#7fff00"
        }
      }, [_vm._v(" 1 ")])])]) : _vm._e()], 1)])], 2)], 1), form.title === 'Occurrence' ? _c('b-col', {
        key: form.id,
        attrs: {
          "md": "6"
        }
      }, [_c('table', {
        staticClass: "table w-100 table-bordered"
      }, [_c('tr', [_c('th', {
        attrs: {
          "bgcolor": "#faebd7",
          "width": "70%"
        }
      }, [_vm._v(" DESCRIPTION OF OCCURRENCE OF CAUSAL FACTORS ")]), _c('th', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#faebd7",
          "width": "30%"
        }
      }, [_vm._v(" RATING ")])]), _c('tr', [_c('td', [_vm._v(" Failure is almost inevitable ")]), _c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#b22222"
        }
      }, [_vm._v(" 10 ")])]), _c('tr', [_c('td', {
        attrs: {
          "rowspan": "2"
        }
      }, [_vm._v(" This Process or similar processes have often ")]), _c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#b22222"
        }
      }, [_vm._v(" 9 ")])]), _c('tr', [_c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#dc143c"
        }
      }, [_vm._v(" 8 ")])]), _c('tr', [_c('td', {
        attrs: {
          "rowspan": "2"
        }
      }, [_vm._v(" This Process has occasional failures but will impact damage to Vessel, in major or Minor ")]), _c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#dc143c"
        }
      }, [_vm._v(" 7 ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 6 ")])]), _c('tr', [_c('td', {
        attrs: {
          "rowspan": "3"
        }
      }, [_vm._v(" slated failures associated with similar processes ")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 5 ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 4 ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#ffd700"
        }
      }, [_vm._v(" 3 ")])]), _c('tr', [_c('td', [_vm._v(" Only Isolated Failures associated with this process or almost indentical processes ")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#ffd700"
        }
      }, [_vm._v(" 2 ")])]), _c('tr', [_c('td', [_vm._v(" Failure unlike, No Failures ever associated with this process or almost identical processes ")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#7fff00"
        }
      }, [_vm._v(" 1 ")])])])]) : _vm._e(), form.title === 'Occurrence, Severity' ? _c('b-col', {
        key: form.id,
        attrs: {
          "md": "6"
        }
      }, [_c('table', {
        staticClass: "table w-100 table-bordered"
      }, [_c('tr', [_c('th', {
        attrs: {
          "bgcolor": "#faebd7",
          "width": "70%"
        }
      }, [_vm._v(" SEVERITY/INFLUENCE RATING DESCRIPTION ")]), _c('th', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#faebd7",
          "width": "30%"
        }
      }, [_vm._v(" RATING ")])]), _c('tr', [_c('td', {
        attrs: {
          "rowspan": "2"
        }
      }, [_vm._v(" Very High-Hazardous, Vessel become inactive or cannot be operated and can cause injury or death for the crew, passengers, or other parties ( the effect directly to loss/damage of primary functions of the Vessel) ")]), _c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#b22222"
        }
      }, [_vm._v(" 10 ")])]), _c('tr', [_c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#b22222"
        }
      }, [_vm._v(" 9 ")])]), _c('tr', [_c('td', {
        attrs: {
          "rowspan": "2"
        }
      }, [_vm._v(" High - Repeated failures made the vessel have the same problem -negligence, incompetence, misconduct, error in judgment by a person, and latent defect of the vessel ")]), _c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#dc143c"
        }
      }, [_vm._v(" 8 ")])]), _c('tr', [_c('td', {
        staticClass: "text-white text-center",
        attrs: {
          "bgcolor": "#dc143c"
        }
      }, [_vm._v(" 7 ")])]), _c('tr', [_c('td', {
        attrs: {
          "rowspan": "3"
        }
      }, [_vm._v(" Moderate -Some occasional Failures, ")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 6 ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 5 ")])]), _c('tr', [_c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#d2691e"
        }
      }, [_vm._v(" 4 ")])]), _c('tr', [_c('td', [_vm._v(" Minor - relatively few failures and minor impact to automatically detects failure mode Vessel or other parties ")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#ffd700"
        }
      }, [_vm._v(" 3 ")])]), _c('tr', [_c('td', [_vm._v(" Very Minor ")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#ffd700"
        }
      }, [_vm._v(" 2 ")])]), _c('tr', [_c('td', [_vm._v(" No Effect -No Danger ")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "bgcolor": "#7fff00"
        }
      }, [_vm._v(" 1 ")])])])]) : _vm._e()];
    })], 2), _c('hr')], 1)], 1);
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addIdentifications
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)], 1), _c('hr')], 2), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("5")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("IMPLEMENT CORRECTIVE ACTIONS")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _vm._l(_vm.implementations, function (implementation, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      staticClass: "text-right text-primary",
      attrs: {
        "md": "1"
      }
    }, [_c('h3', [_vm._v(" #" + _vm._s(index + 1))]), _c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.implementations.splice(index, 1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1), _c('b-col', {
      attrs: {
        "md": "10"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Tool"
      }
    }, [index <= 3 ? _c('b-form-select', {
      staticClass: "w-100",
      attrs: {
        "plain": "",
        "size": "sm"
      },
      on: {
        "change": function change($event) {
          return _vm.handleImplementToolChange(index);
        }
      },
      model: {
        value: implementation.tool,
        callback: function callback($$v) {
          _vm.$set(implementation, "tool", $$v);
        },
        expression: "implementation.tool"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": "Five Whys"
      }
    }, [_vm._v("Five Whys")]), _c('b-form-select-option', {
      attrs: {
        "value": "Comparative Analysis"
      }
    }, [_vm._v("Comparative Analysis")]), _c('b-form-select-option', {
      attrs: {
        "value": "Failure Mode and Effect Analysis (FMEA)"
      }
    }, [_vm._v("Failure Mode and Effect Analysis (FMEA)")]), _c('b-form-select-option', {
      attrs: {
        "value": "DOE"
      }
    }, [_vm._v("DOE")])], 1) : _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Tool"
      },
      model: {
        value: implementation.tool,
        callback: function callback($$v) {
          _vm.$set(implementation, "tool", $$v);
        },
        expression: "implementation.tool"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Question"
      }
    }, [_c('b-form-textarea', {
      staticClass: "w-100",
      attrs: {
        "row": "2",
        "placeholder": "Question"
      },
      model: {
        value: implementation.question,
        callback: function callback($$v) {
          _vm.$set(implementation, "question", $$v);
        },
        expression: "implementation.question"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Findings"
      }
    }, [_c('b-form-textarea', {
      staticClass: "w-100",
      attrs: {
        "row": "2",
        "placeholder": "Findings"
      },
      model: {
        value: implementation.finding,
        callback: function callback($$v) {
          _vm.$set(implementation, "finding", $$v);
        },
        expression: "implementation.finding"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Corrective Actions"
      }
    }, [_c('b-form-textarea', {
      staticClass: "w-100",
      attrs: {
        "row": "2",
        "placeholder": "Corrective Actions"
      },
      model: {
        value: implementation.corrective_action,
        callback: function callback($$v) {
          _vm.$set(implementation, "corrective_action", $$v);
        },
        expression: "implementation.corrective_action"
      }
    })], 1)], 1)], 1), _c('hr')], 1)], 1);
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addImplementations
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)], 1), _c('hr')], 2), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("6")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("FISHBONE DIAGRAM")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Diagram Name :"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Diagram Name"
    },
    model: {
      value: _vm.diagram_title,
      callback: function callback($$v) {
        _vm.diagram_title = $$v;
      },
      expression: "diagram_title"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Photo"
    }
  }, [_c('b-form-file', {
    attrs: {
      "accept": "image"
    },
    model: {
      value: _vm.diagram_photo,
      callback: function callback($$v) {
        _vm.diagram_photo = $$v;
      },
      expression: "diagram_photo"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', _vm._l(_vm.diagrams, function (diagram, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "12"
      }
    }, [_c('b-row', {
      staticClass: "mt-2 mb-2"
    }, [_c('b-col', {
      attrs: {
        "md": "5"
      }
    }, [_c('div', {
      staticClass: "bg-light p-4 font-size-18"
    }, [_c('b-form-group', {
      attrs: {
        "label": "Header Name :"
      }
    }, [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Header Name"
      },
      model: {
        value: diagram.header,
        callback: function callback($$v) {
          _vm.$set(diagram, "header", $$v);
        },
        expression: "diagram.header"
      }
    })], 1)], 1), _c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.diagrams.splice(index, 1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1), _c('b-col', {
      attrs: {
        "md": "7"
      }
    }, [_vm._l(diagram.bodyLength, function (idx) {
      return _c('b-row', {
        key: idx,
        staticClass: "mb-3"
      }, [_c('b-col', {
        staticClass: "text-right text-primary",
        attrs: {
          "md": "1"
        }
      }, [_c('h3', [_vm._v(" #" + _vm._s(idx))]), _c('b-button', {
        attrs: {
          "variant": "danger"
        },
        on: {
          "click": function click($event) {
            diagram.bodyLength -= 1;
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-trash"
      })])], 1), _c('b-col', {
        attrs: {
          "md": "11"
        }
      }, [_c('b-form-textarea', {
        attrs: {
          "rows": "2"
        },
        model: {
          value: diagram.body[idx - 1],
          callback: function callback($$v) {
            _vm.$set(diagram.body, idx - 1, $$v);
          },
          expression: "diagram.body[idx - 1]"
        }
      })], 1)], 1);
    }), _c('b-col', {
      staticClass: "text-right",
      attrs: {
        "md": "12"
      }
    }, [_c('b-button', {
      staticClass: "mt-2 mb-3",
      attrs: {
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          return _vm.addDiagramBody(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-plus"
    }), _vm._v(" Add More Item")])], 1)], 2)], 1)], 1);
  }), 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addDiagrams
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)], 1), _c('hr')], 1), _c('b-col', [_c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "success",
      "block": ""
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_c('i', {
    staticClass: "fa fa-check"
  }), _vm._v(" Submit Form")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
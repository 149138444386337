var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "maintenance-summary-board"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Filter Maintenance Dashboard")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mb-2"
        }, [_c('AdvancedFilter', {
          attrs: {
            "title": "Advance Filter - Maintenance Dashboard"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('b-row', [_vm.tab !== 'TASK_LIST' ? _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Date Range:",
                  "label-for": "Date Range"
                }
              }, [_c('date-picker', {
                staticStyle: {
                  "width": "100%"
                },
                attrs: {
                  "format": "DD MMM YYYY",
                  "type": "date",
                  "range": "",
                  "placeholder": "Select date range"
                },
                on: {
                  "change": function change($event) {
                    return _vm.onChangeDate();
                  }
                },
                model: {
                  value: _vm.maintenance.dateRange,
                  callback: function callback($$v) {
                    _vm.$set(_vm.maintenance, "dateRange", $$v);
                  },
                  expression: "maintenance.dateRange"
                }
              })], 1)], 1) : _vm._e(), !_vm.queryVehicleId ? _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Fleet/Equipment:",
                  "label-for": "fleet-equipment"
                }
              }, [_c('v-multiselect', {
                attrs: {
                  "disabled": _vm.queryVehicleId,
                  "tag-placeholder": "Category",
                  "placeholder": "Search or add a category",
                  "label": "name",
                  "track-by": "code",
                  "options": _vm.fleetEquipments,
                  "multiple": true,
                  "taggable": true
                },
                model: {
                  value: _vm.maintenance.params.vehicleIds,
                  callback: function callback($$v) {
                    _vm.$set(_vm.maintenance.params, "vehicleIds", $$v);
                  },
                  expression: "maintenance.params.vehicleIds"
                }
              })], 1)], 1) : _vm._e(), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Choose your Job Filter",
                  "label-for": "fleet-equipment"
                }
              }, [_vm._l(_vm.jobOptions, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  staticClass: "custom-radio-color-checked",
                  attrs: {
                    "inline": "",
                    "color": item.color,
                    "name": "color",
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.maintenance.selectedJob,
                    callback: function callback($$v) {
                      _vm.$set(_vm.maintenance, "selectedJob", $$v);
                    },
                    expression: "maintenance.selectedJob"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2)], 1), _vm.maintenance.selectedJob === 'job_class' ? _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Job Class",
                  "label-for": "fleet-equipment"
                }
              }, [_c('v-multiselect', {
                attrs: {
                  "tag-placeholder": "Category",
                  "placeholder": "Search or add a job class",
                  "label": "name",
                  "track-by": "code",
                  "options": _vm.jobClassList,
                  "multiple": true,
                  "taggable": true
                },
                model: {
                  value: _vm.maintenance.params.jobClassIds,
                  callback: function callback($$v) {
                    _vm.$set(_vm.maintenance.params, "jobClassIds", $$v);
                  },
                  expression: "maintenance.params.jobClassIds"
                }
              })], 1)], 1) : _vm._e(), _vm.maintenance.selectedJob === 'job_type' ? _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Job Type",
                  "label-for": "fleet-equipment"
                }
              }, [_c('v-multiselect', {
                attrs: {
                  "tag-placeholder": "Category",
                  "placeholder": "Search or add a job type",
                  "label": "name",
                  "track-by": "code",
                  "options": _vm.jobTypeList,
                  "multiple": true,
                  "taggable": true
                },
                model: {
                  value: _vm.maintenance.params.jobTypeIds,
                  callback: function callback($$v) {
                    _vm.$set(_vm.maintenance.params, "jobTypeIds", $$v);
                  },
                  expression: "maintenance.params.jobTypeIds"
                }
              })], 1)], 1) : _vm._e(), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Job Name/Equipment",
                  "label-for": "equipment"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "search..."
                },
                model: {
                  value: _vm.maintenance.params.search,
                  callback: function callback($$v) {
                    _vm.$set(_vm.maintenance.params, "search", $$v);
                  },
                  expression: "maintenance.params.search"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Status",
                  "label-for": "status"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "name": "status"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": ''
                      }
                    }, [_vm._v("All")]), _c('b-form-select-option', {
                      attrs: {
                        "value": 'ACTIVE'
                      }
                    }, [_vm._v("ACTIVE")]), _c('b-form-select-option', {
                      attrs: {
                        "value": 'REVIEW'
                      }
                    }, [_vm._v("REVIEW")]), _c('b-form-select-option', {
                      attrs: {
                        "value": 'APPROVE'
                      }
                    }, [_vm._v("APPROVE")]), _c('b-form-select-option', {
                      attrs: {
                        "value": 'MAINTENANCE'
                      }
                    }, [_vm._v("MAINTENANCE")]), _c('b-form-select-option', {
                      attrs: {
                        "value": 'OVERDUE'
                      }
                    }, [_vm._v("OVERDUE")]), _c('b-form-select-option', {
                      attrs: {
                        "value": 'TOWARDS'
                      }
                    }, [_vm._v("TOWARDS")]), _c('b-form-select-option', {
                      attrs: {
                        "value": 'FINISH'
                      }
                    }, [_vm._v("FINISH")])];
                  },
                  proxy: true
                }]),
                model: {
                  value: _vm.maintenance.params.status,
                  callback: function callback($$v) {
                    _vm.$set(_vm.maintenance.params, "status", $$v);
                  },
                  expression: "maintenance.params.status"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": _vm.tab !== 'TASK_LIST' ? '3' : '6'
                }
              }, [_c('button', {
                staticClass: "btn w-100",
                style: {
                  backgroundColor: '#2BDCAA',
                  color: 'white',
                  marginTop: '32px'
                },
                attrs: {
                  "type": "button",
                  "block": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.getMaintenanceBoard();
                  }
                }
              }, [_c('span', {
                staticClass: "mr-2"
              }, [_vm._v("Filter Data")]), _vm._v(" "), _c('i', {
                staticClass: "fa fa-search"
              })])])], 1)];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "nav-fill mb-3",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#task-list",
            "title": "Task List"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'TASK_LIST';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#finished",
            "title": "Finished"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'FINISHED';
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "task-list"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "blue"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white text-center"
  }, [_c('h1', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.maintenance.meta.towards))])]), _c('blockquote', {
    staticClass: "blockquote mb-0 text-center"
  }, [_c('p', {
    staticClass: "font-size-22"
  }, [_c('strong', [_vm._v("Towards Jobs")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "danger"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white text-center"
  }, [_c('h1', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.maintenance.meta.overdue))])]), _c('blockquote', {
    staticClass: "blockquote mb-0 text-center"
  }, [_c('p', {
    staticClass: "font-size-22"
  }, [_c('strong', [_vm._v("Overdue")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "warning"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white text-center"
  }, [_c('h1', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.maintenance.meta.postponed))])]), _c('blockquote', {
    staticClass: "blockquote mb-0 text-center"
  }, [_c('p', {
    staticClass: "font-size-22"
  }, [_c('strong', [_vm._v("Postponed")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between",
          on: {
            "click": function click($event) {
              _vm.maintenance.params.status = 'MONTHS3';
            }
          }
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("Next 3 Month")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.maintenance.meta['3months']))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fa fa-calendar-week"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between",
          on: {
            "click": function click($event) {
              _vm.maintenance.params.status = 'MONTHS6';
            }
          }
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("Next 6 Month")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.maintenance.meta['6months']))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fa fa-calendar-alt"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between",
          on: {
            "click": function click($event) {
              _vm.maintenance.params.status = 'MONTHS12';
            }
          }
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("Next 12 Month")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.maintenance.meta['12months']))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-success"
        }, [_c('i', {
          staticClass: "fa fa-calendar"
        })])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch pt-3 bg-light"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  }), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select Your Data:"
    }
  }, [_c('v-select', {
    staticClass: "bg-light text-white",
    attrs: {
      "label": "text",
      "options": _vm.statusList,
      "reduce": function reduce(statusList) {
        return statusList.value;
      },
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    model: {
      value: _vm.maintenance.params.status,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenance.params, "status", $$v);
      },
      expression: "maintenance.params.status"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Task List Data " + _vm._s(_vm.queryVehicleId && _vm.maintenance.params.vehicleIds[0] ? ' - ' + _vm.maintenance.params.vehicleIds[0].name : ''))]), _vm._v(" "), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(_vm._s(_vm.selectedStatusList.text))])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "mt-3 mb-3",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("No")]), !_vm.queryVehicleId ? _c('th', {
          staticClass: "text-center text-truncate",
          staticStyle: {
            "min-width": "250px"
          }
        }, [_vm._v("Fleet/Equipment")]) : _vm._e(), _c('th', {
          staticClass: "text-center text-truncate",
          staticStyle: {
            "min-width": "200px"
          }
        }, [_vm._v("Job Name - Code")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Equipment Name")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Frequency")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Due Date / Hours")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Action")])])]), _c('tbody', [_vm._l(_vm.maintenance.data, function (job, index) {
          return [_c('tr', {
            key: index
          }, [_c('th', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(index + 1))]), !_vm.queryVehicleId ? _c('td', {
            staticClass: "text-left"
          }, [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'VIEW_DETAIL');
              }
            }
          }, [_vm._v(_vm._s(job.vehicleName) + " - " + _vm._s(job.equipmentName))])]) : _vm._e(), _c('td', {
            staticClass: "text-left"
          }, [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'VIEW_DETAIL');
              }
            }
          }, [_vm._v(_vm._s(job.jobName))])]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(job.componentName))]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(job.interval))]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(job.lastMaintenanceDate ? _vm.moment(job.lastMaintenanceDate, "DD-MM-YYYY").format('DD MMM YYYY') : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_c('span', [_vm._v(_vm._s(job.dueDate))]), job.pendingDate === null || job.pendingDate === '' || job.pendingDate === undefined ? _c('div') : _c('div', [_c('small', [_vm._v("Last Due #1 : " + _vm._s(job.pendingDate))])])]), _c('td', {
            staticClass: "text-center"
          }, [job.status === 'OVERDUE' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "danger"
            }
          }, [_vm._v("OVERDUE / " + _vm._s(job.statusDetail && job.statusDetail.includes('days') ? _vm.generateDayDurations(parseInt(job.statusDetail.replace('days', '').trim())).durationString : job.statusDetail))])] : job.status === 'TOWARDS' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "info"
            }
          }, [_vm._v("TOWARDS")])] : job.status === 'REVIEW' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "warning"
            }
          }, [_vm._v("REVIEW")])] : job.status === 'APPROVE' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "success"
            }
          }, [_vm._v("APPROVE")])] : job.status === 'POSTPONE' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "info"
            }
          }, [_vm._v("TOWARDS (POSTPONE)")])] : job.status === 'NEW' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "primary"
            }
          }, [_vm._v("ACTIVE")])] : [_c('b-badge', {
            attrs: {
              "variant": "light"
            }
          }, [_vm._v(_vm._s(job.status))])]], 2), _c('td', [_c('b-dropdown', {
            attrs: {
              "size": "sm",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_vm.canAdd && ['TOWARDS', 'OVERDUE', 'POSTPONE', 'NEW'].includes(job.status) ? _c('b-dropdown-item', {
            attrs: {
              "size": "sm",
              "href": "#",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'ON_MAINTENANCE');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-tasks"
          }), _vm._v(" Maintenance")]) : _vm._e(), ['APPROVE'].includes(job.status) && _vm.canApprove ? _c('b-dropdown-item', {
            attrs: {
              "size": "sm",
              "href": "#",
              "variant": "info"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onApprove(job.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-thumbs-up"
          }), _vm._v(" Approve")]) : _vm._e(), ['TOWARDS', 'OVERDUE', 'NEW'].includes(job.status) ? _c('b-dropdown-item', {
            attrs: {
              "size": "sm",
              "href": "#",
              "variant": "warning"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'ON_POSTPONE');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-clock"
          }), _vm._v(" Postpone")]) : _vm._e(), ['MAINTENANCE', 'REVIEW'].includes(job.status) && _vm.canReview ? _c('b-dropdown-item', {
            attrs: {
              "size": "sm",
              "href": "#",
              "variant": "info"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onReview(job.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-search"
          }), _vm._v(" Review")]) : _vm._e(), ['APPROVE', 'REVIEW'].includes(job.status) ? _c('b-dropdown-item', {
            attrs: {
              "size": "sm",
              "href": "#",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'ON_MAINTENANCE', true);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-pen"
          }), _vm._v(" Edit")]) : _vm._e(), ['TOWARDS', 'OVERDUE', 'NEW'].includes(job.status) ? _c('b-dropdown-item', {
            attrs: {
              "size": "sm",
              "href": "#",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'ON_CONVERT');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-tools"
          }), _vm._v(" Convert Repair")]) : _vm._e()], 1)], 1)])];
        })], 2)])])])], 1), _c('b-row', {
          staticClass: "mt-4"
        }, [_c('b-col', {
          attrs: {
            "col-6": ""
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.maintenance.meta.total) + "   |   Per page: " + _vm._s(_vm.maintenance.meta.perPage) + " ")]), _c('b-col', {
          attrs: {
            "col-6": ""
          }
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.maintenance.meta.total,
            "per-page": _vm.maintenance.meta.perPage,
            "align": "right"
          },
          on: {
            "input": _vm.getMaintenanceList
          },
          model: {
            value: _vm.maintenance.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.maintenance.params, "page", $$v);
            },
            expression: "maintenance.params.page"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "finished"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "p-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "bg-variant": "default"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-card-title', {
    staticClass: "text-center"
  }, [_c('h3', [_vm._v(_vm._s(_vm.maintenanceFinished.meta.total))])]), _c('blockquote', {
    staticClass: "blockquote mb-0 text-center"
  }, [_c('p', {
    staticClass: "font-size-18"
  }, [_c('strong', [_vm._v("Finished")])])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Date Range"
    }
  }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.computedDateRangeText))])])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Fleet / Equipment"
    }
  }, [_vm.maintenance.params.vehicleIds.length === 0 ? _c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" Semua Fleet ")]) : _vm._e(), _vm._l(_vm.maintenance.params.vehicleIds, function (vehicle, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v(" " + _vm._s(vehicle.name) + " ")]);
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Job"
    }
  }, [_vm.maintenance.selectedJob === 'job_class' ? _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("Job Class")]) : _vm.maintenance.selectedJob === 'job_type' ? _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("Job Type")]) : _vm._e(), _vm.maintenance.selectedJob === 'job_class' ? _c('h6', [_c('strong', [_vm._v(_vm._s(_vm.maintenance.params.jobClassIds.map(function (jobClass) {
    return jobClass.name;
  }).join(', ')))])]) : _vm.maintenance.selectedJob === 'job_type' ? _c('h6', [_c('strong', [_vm._v(_vm._s(_vm.maintenance.params.jobTypeIds.map(function (jobType) {
    return jobType.name;
  }).join(', ')))])]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Keyword Search"
    }
  }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.maintenance.params.search || '-'))])])])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "text-center"
        }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "min-width": "250px"
          }
        }, [_vm._v("Fleet/Equipment")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "min-width": "200px"
          }
        }, [_vm._v("Job Name")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Component Name")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Frequency")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Finish Maintenance")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Cost")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("File")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Approved by")])])]), _c('tbody', _vm._l(_vm.maintenanceFinished.data, function (job, index) {
          var _job$cost;
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-left"
          }, [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'VIEW_DETAIL');
              }
            }
          }, [_vm._v(_vm._s(job.vehicleName))])]), _c('td', {
            staticClass: "text-left"
          }, [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'VIEW_DETAIL');
              }
            }
          }, [_vm._v(_vm._s(job.jobName))])]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(job.componentName))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(job.interval))]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(job.lastMaintenanceDate ? _vm.moment(job.lastMaintenanceDate, "DD-MM-YYYY").format('DD MMM YYYY') : '-'))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_c('span', [_vm._v(_vm._s(job.dueDate))])]), _c('td', {
            staticClass: "text-truncate text-right"
          }, [_vm._v(_vm._s((_job$cost = job === null || job === void 0 ? void 0 : job.cost) !== null && _job$cost !== void 0 ? _job$cost : 0))]), _c('td', [_vm._v("-")]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('b-badge', {
            attrs: {
              "variant": "success"
            }
          }, [_vm._v(_vm._s(job.status))])], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(job.approveBy))])]);
        }), 0)])])];
      },
      proxy: true
    }])
  }, [_c('b-col', [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_vm._v(" Total: " + _vm._s(_vm.maintenanceFinished.meta.total) + "   |   Per page: " + _vm._s(_vm.maintenanceFinished.meta.perPage) + " ")]), _c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.maintenanceFinished.meta.total,
      "per-page": _vm.maintenanceFinished.meta.perPage,
      "align": "right"
    },
    on: {
      "input": _vm.getMaintenanceFinishedList
    },
    model: {
      value: _vm.maintenanceFinished.params.page,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceFinished.params, "page", $$v);
      },
      expression: "maintenanceFinished.params.page"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-sidebar', {
    attrs: {
      "id": "view-detail-maintenance",
      "title": "View Job Maintenance",
      "width": "90%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_vm.viewDetailMaintenanceSidebar ? _c('ViewMaintenanceDetail', {
          attrs: {
            "isEdit": _vm.isEdit,
            "jobClassList": _vm.jobClassList,
            "jobTypeList": _vm.jobTypeList,
            "maintenance-id": _vm.selectedMaintenanceId,
            "action": _vm.actionMaintenanceSidebar,
            "onApprove": _vm.onApprove,
            "onReview": _vm.onReview
          },
          on: {
            "save": function save($event) {
              return _vm.closeDetailMaintenance();
            }
          }
        }) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.viewDetailMaintenanceSidebar,
      callback: function callback($$v) {
        _vm.viewDetailMaintenanceSidebar = $$v;
      },
      expression: "viewDetailMaintenanceSidebar"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
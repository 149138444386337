var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-4"
  }, [_vm.detailMi && _vm.detailSurvey && !_vm.loading.survey ? _c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-end mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v("Export")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditMode = !_vm.isEditMode;
      }
    }
  }, [_vm._v(_vm._s(_vm.isEditMode ? 'Preview' : 'Update') + " Cost Breakdown")])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped table-bordered"
  }, [_c('thead', [_c('tr', {
    attrs: {
      "width": "100%"
    }
  }, [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("NO")]), _c('th', {
    attrs: {
      "width": "45%"
    }
  }, [_vm._v("Description/Item")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Amount")])])]), _c('tbody', [_vm._l(_vm.listBreakdowns, function (cb, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(cb.description))]), _vm.isEditMode ? _c('td', [_c('div', {
      staticClass: "d-flex justify-content-end w-100",
      staticStyle: {
        "width": "250px"
      }
    }, [_c('InputCurrency', {
      attrs: {
        "placeholder": "Text Number..."
      },
      model: {
        value: cb.value,
        callback: function callback($$v) {
          _vm.$set(cb, "value", $$v);
        },
        expression: "cb.value"
      }
    })], 1)]) : _c('td', {
      staticClass: "text-right pr-4"
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(_vm.formatCurrency(cb.value)))])])]);
  }), _c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_c('h6', [_c('b', [_vm._v("Total")])])]), _c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "1"
    }
  }, [_c('h6', [_c('b', [_vm._v("IDR. " + _vm._s(_vm.numberFormat(_vm.subTotal)))])])])]), _c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_c('h6', [_c('b', [_vm._v("Deductible")])])]), _c('td', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "colspan": "1"
    }
  }, [_vm.isEditMode ? _c('div', {
    staticStyle: {
      "max-width": "250px"
    }
  }, [_c('InputCurrency', {
    model: {
      value: _vm.form.deductible,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "deductible", $$v);
      },
      expression: "form.deductible"
    }
  })], 1) : _c('h6', [_c('b', [_vm._v("IDR. " + _vm._s(_vm.numberFormat(_vm.form.deductible)))])]), _vm.detailMi.type_coverage && _vm.detailMi.type_coverage.name === 'Hull & Machinery' ? [_c('br'), _c('small', [_vm._v("Price List Kapal HM x Deductible %")])] : _vm._e()], 2)]), _c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_c('h6', [_c('b', [_vm._v("Grand Total")])])]), _c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "1"
    }
  }, [_c('h6', [_c('b', [_vm._v("IDR. " + _vm._s(_vm.numberFormat(_vm.grandTotal)))])])])])], 2)])]), _c('b-col', {
    staticClass: "text-right mb-5",
    attrs: {
      "offset": "6",
      "cols": "6"
    }
  }, [_vm.isEditMode ? _c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit Cost Breakdown")]) : _vm._e()], 1)], 1)], 1) : _c('div', {
    staticClass: "py-5 my-5 d-flex justify-content-center"
  }, [_c('b-spinner')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
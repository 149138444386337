<template>
  <div>
    <ApexChart
      :element="`chart-cargo-${element}`"
      :chartOption="chartCargoAnalysis"
    />
  </div>
</template>
<script>
export default {
  props: {
    series: {
      type: Array,
      default: () => {
        return []
      },
    },
    element: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartCargoAnalysis: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        colors: ['#40A0FC', '#FFF000'],
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
            dataLabels: {
              position: 'center', // Set position to 'top'
              hideOverflowingLabels: true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '13px',
          },
        },
        legend: {
          show: true,
        },
        series: this.series,
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val // memformat label sumbu y menjadi persentase
            },
          },
          max: 100, // set nilai maksimum pada sumbu y
          tickAmount: 5,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
        fill: {
          type: 'gradient', // Menambahkan fill tipe gradient
          gradient: {
            shade: 'light',
            type: 'vertical', // Gradasi vertikal
            shadeIntensity: 0.5,
            gradientToColors: ['#1235C5'], // Warna akhir gradasi
            inverseColors: false,
            opacityFrom: 0.85,
            opacityTo: 0.95,
            stops: [0, 100], // Titik awal dan akhir gradasi
          },
        },
      },
    }
  },
}
</script>

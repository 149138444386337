<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h5 class="card-title text-primary text-capitalize d-flex align-items-center">
              <i @click="$router.go(-1)" style="cursor:pointer" class="fa fa-arrow-left mr-3" /> <strong>create budget {{ $route.query.type }}</strong>
            </h5>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      label="Period"
                      label-for="period"
                    >
                      <date-picker type="date" class="w-100" range placeholder="Select date range"></date-picker>
                    </b-form-group>
                  </b-col> 
                  <b-col md="4">
                    <b-form-group
                      label="Fleet Type"
                      label-for="fleet_type"
                    >
                      <v-multiselect size="sm" v-model="form.fleetType" class="w-auto" tag-placeholder="Fleet type" placeholder="Select status..." label="label" track-by="value" :options="optionsFleetTypes" :multiple="true" :taggable="true" ></v-multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Status"
                      label-for="status"
                    >
                      <template v-for="(item, index) in statusOptions">
                       <b-form-radio
                          class="custom-radio-color-checked"
                          inline
                          :color="item.color"
                          name="color"
                          :key="index"
                          v-model="form.status"
                          :disabled="item.disabled"
                        >
                          <span>{{ item.label }}</span>
                        </b-form-radio>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Notes"
                      label-for="notes"
                    >
                      <wysiwyg :style="{ height: '200px' }" v-model="form.notes" />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-row class="border mx-1">
                      <b-col md="12" class="mb-2">
                        <b-row style="background-color:#e7f3ff" class="py-2">
                          <b-col md="6">
                            <b-form-group
                              label="Project Name"
                              label-for="status"
                            >
                              <b-form-input placeholder="Arutmin Project" style="background-color:#ffffff" />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Add Sub Project"
                              label-for="status"
                            >
                              <b-form-checkbox
                                name="add_sub_project"
                                switch
                                inline
                                v-model="form.add_sub_project"
                              >
                                No / Yes
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>

                      <template v-if="form.add_sub_project" >
                        <b-col md="12">
                          <div class="overflow-auto" style="max-height: 500px">
                            <template v-for="(val, index) in form.sub_project" >
                              <table :key="index" class="table table-sm table-borderless table-hover">
                                <tr>
                                  <td style="width: 5px; margin-left: 1rem; cursor: pointer;" class="text-center" v-b-toggle="`row-${index}`">
                                    <ChevronArrow color="black" />
                                  </td>
                                  <td class="p-2">
                                    <b-form-input class="mx-2" placeholder="Labuan" />
                                  </td>
                                  <td class="p-2">
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control text-right"
                                      />
                                      <div class="input-group-prepend"  v-if="$route.query.type === 'cargo'">
                                        <span class="input-group-text bg-primary" id="rp">Tons</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td style="width: 5px;" class="text-center">
                                    <div class="bg-danger p-1 px-2" @click="form.sub_project.splice(index, 1)" style="cursor: pointer">
                                      <i class="fa fa-trash" />
                                    </div>
                                  </td>
                                </tr>
                              </table>
                              <b-collapse :id="`row-${index}`" class="collapse-row">
                                <div class="mx-5 mb-4 border">
                                  <table class="table table-borderless table-hover px-4 table-striped">
                                    <tr style="background-color:#e7f3ff">
                                      <th>Fleet Capacity</th>
                                      <th>Fleet Ownership</th>
                                      <th>Loading</th>
                                      <th style="width: 5px;"></th>
                                    </tr>
                                    <tr v-for="(obj, objIndex) in val.sub_project_child" :key="objIndex">
                                      <td>
                                        <b-form-input class="mr-2" placeholder="Labuan" />
                                      </td>
                                      <td>
                                        <b-form-select  
                                          plain
                                          size="sm"
                                          placeholder="Select..."
                                          class="mr-2"
                                        >
                                          <option value="1">Owned</option>
                                          <option value="2">Third Party</option>
                                        </b-form-select>
                                      </td>
                                      <td>
                                        <div class="input-group">
                                          <input
                                            type="number"
                                            class="form-control text-right"
                                          />
                                          <div class="input-group-prepend" v-if="$route.query.type === 'cargo'">
                                            <span class="input-group-text bg-primary" id="rp">Tons</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td @click="val.sub_project_child.splice(objIndex, 1)" style="width: 5px; cursor: pointer" class="text-danger text-center px-2"><i class="fa fa-trash" /></td>
                                    </tr>
                                  </table>
                                  <button
                                    type="button"
                                    class="btn ml-3 mb-3 mt-0"
                                    style="background-color:#e7f3ff"
                                    @click="val.sub_project_child.push({
                                      fleet_capacity: '',
                                      fleet_ownership: '',
                                      loading: null,
                                    })"
                                  >
                                    <strong>Add Budget Cargo</strong>
                                  </button>
                                </div>
                              </b-collapse>
                            </template>
                          </div>
                        </b-col>
                      </template>

                      <b-col md="12" v-if="form.add_sub_project">
                        <div class="w-100 d-flex my-2" />
                        <button
                          type="button"
                          class="btn mb-3"
                          style="background-color:#3c5de7"
                          @click="form.sub_project.push({
                            project_name: '',
                            project_value: null,
                            sub_project_child: [{
                              fleet_capacity: '',
                              fleet_ownership: '',
                              loading: null,
                            }]
                          })"
                        >
                          <strong class="text-white">Add Budget Cargo</strong>
                        </button>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="12" class="mt-4">
                    <div class='d-flex align-items-center justify-content-end'>
                      <button
                        type="submit"
                        class="btn btn-outline-primary mr-2"
                      >
                      Cancel
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ChevronArrow from './components/icon/ChevronArrow.vue'

export default {
  name:'FormBudget',
  components:{ ChevronArrow },
  data() {
    return {
      statusOptions: [{
          label: 'Active',
          color: 'primary',
          value: 1,
        },
        {
          label: 'Inactive',
          color: 'dark',
          value: 0,
        }
      ],

      optionsFleetTypes: [{
          label:'Tugboat',
          value: 1
        },{
          label:'Barge',
          value: 2
        },{
          label:'Cargo',
          value: 3
        },{
          label:'Pusher Tug',
          value: 4
        },{
          label:'Mother Vessel',
          value: 5
        }
      ],

      form:{
        status:1,
        fleetType:[],
        notes:'',
        project_name:'',
        add_sub_project: true,
        sub_project:[
          {
            project_name: '',
            project_value: null,
            sub_project_child: [{
              fleet_capacity: '',
              fleet_ownership: '',
              loading: null,
            }]
          }
        ]
      }
    }
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>
<template>
  <div>
    <div class="mb-4">
      <CustomNav
        :items="navItems"
        @item-click="(val) => (viewMenu = val)"
      ></CustomNav>
    </div>
    <b-row v-if="viewMenu == 'category'">
      <b-col sm="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>List Master Data Category</strong>
            </h4>
          </template>
          <template #headerAction>
            <b-form
              class="row w-100"
              @submit.prevent="getInspectionChecklistCategory()"
            >
              <b-col cols="12" md="10" class="p-0">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Search..."
                  v-model="categoryList.params.name"
                ></b-form-input>
              </b-col>
              <b-col cols="12" md="2" class="p-0">
                <b-button type="submit" variant="primary" class="ml-2"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-col>
            </b-form>
          </template>
          <template v-slot:body>
            <div v-if="categoryList._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div
                class="table-responsive"
                v-if="categoryList.data && categoryList.data.length > 0"
              >
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="10%">No</th>
                      <th class="text-truncate" style="min-width: 300px"
                        >Name</th
                      >
                      <th class="text-truncate">Alias</th>
                      <th class="text-truncate">Vehicle Type</th>
                      <th class="text-truncate">Maintenance Component</th>
                      <th class="text-truncate">Vehicle Type Section</th>
                      <th class="text-truncate">Status</th>
                      <th width="15%" class="text-truncate text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(p, i) in categoryList.data" :key="`p-${i}`">
                      <td>{{ i + 1 }}</td>
                      <td
                        style="min-width: 300px"
                        :class="`${!p.parent_id ? 'font-weight-bold' : ''}`"
                      >{{ p.name }}</td>
                      <td class="text-truncate">{{ parseStr(p.alias) }}</td>
                      <td class="text-truncate">{{ parseStr(p.vehicle_type?.name) }}</td>
                      <td class="text-truncate">{{
                        p.maintenance_component?.component_name ?? '-'
                      }}</td>
                      <td class="text-truncate text-center">{{
                        p.vehicle_type_section?.name ?? '-'
                      }}</td>
                      <td class="text-truncate">{{
                        p.status ? 'Active' : 'Inactive'
                      }}</td>
                      <td>
                        <div class="d-flex">
                          <b-button
                            variant="success"
                            size="sm"
                            class="mr-2"
                            @click="editFormCategory(p)"
                            ><i class="fa fa-edit"></i
                          ></b-button>
                          <b-button
                            variant="danger"
                            size="sm"
                            @click="onDeleteCategory(p)"
                          >
                            <i v-if="!delete_category" class="fa fa-trash"></i>
                            <template v-else>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span
                              >Deleting...
                            </template>
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="categoryList.data && categoryList.data.length > 0">
                <b-pagination
                  v-model="categoryList.meta.page"
                  :total-rows="categoryList.meta.totalItem"
                  @change="getInspectionChecklistCategory"
                  :per-page="categoryList.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
              <p v-else class="text-center my-4">No Category data found.</p>
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4">
        <iq-card
          :class="{
            'border border-danger shadow-lg': category_edit,
          }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title"
              >{{ category_edit ? 'Edit' : 'Add' }} Category</h4
            >
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="onSaveCategory()">
              <b-form-group label="Category" label-for="name">
                <b-form-input
                  v-model="formCategory.name"
                  id="name"
                  type="text"
                  placeholder="Type text"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Parent" label-for="parent_id">
                <v-select
                  v-model="formCategory.parent_id"
                  label="text"
                  :options="listParent"
                  :reduce="(obj) => obj.value"
                  placeholder="Select parent"
                ></v-select>
              </b-form-group>
              <b-form-group label="Alias" label-for="alias">
                <b-form-input
                  v-model="formCategory.alias"
                  id="alias"
                  type="text"
                  placeholder="Type text"
                  maxlength="30"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Vehicle Type"
                label-for="vehicle_type_id"
              >
                <v-select
                  :options="optionsVehicleType"
                  v-model="formCategory.vehicle_type_id"
                  :reduce="option => option.value"
                  label="text"
                  :clearable="false"
                  placeholder="Select..."
                ></v-select>
              </b-form-group> 
              <b-form-group
                label="Maintenance Component"
                label-for="maintenance_component_id"
              >
                <v-select
                  :options="optionsMaintenanceComponent"
                  v-model="formCategory.maintenance_component_id"
                  :reduce="option=> option.value"
                  label="text"
                  :clearable="false"
                  placeholder="Select..."
                  :disabled="!formCategory.vehicle_type_id"
                ></v-select>  
              </b-form-group>
              <b-form-group
                label="Vehicle Type Section"
                label-for="vehicle_type_m_section_id"
              >
                <v-select
                  :options="listVehicleTypeMasterSection"
                  v-model="formCategory.vehicle_type_m_section_id"
                  :reduce="option=> option.value"
                  label="text"
                  :clearable="false"
                  placeholder="Select..."
                ></v-select>
              </b-form-group>
              <b-form-group label="Status" label-for="status">
                <b-form-checkbox
                  v-model="formCategory.status"
                  name="check-button"
                  switch
                  inline
                  >Active / Inactive
                </b-form-checkbox>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                  <span v-if="!category_edit">Add</span>
                  <span v-else>Save Edit</span>
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click="resetFormCategory()"
                  >Cancel</b-button
                >
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row v-if="viewMenu == 'question'">
      <b-col sm="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>List Master Data Question</strong>
            </h4>
          </template>
          <template #headerAction>
            <b-form
              class="row w-100"
              @submit.prevent="getInspectionChecklistQuestion()"
            >
              <b-col cols="12" md="10" class="p-0">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Search..."
                  v-model="questionList.params.question"
                ></b-form-input>
              </b-col>
              <b-col cols="12" md="2" class="p-0">
                <b-button type="submit" variant="primary" class="ml-2"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-col>
            </b-form>
          </template>
          <template v-slot:body>
            <div v-if="questionList._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div v-if="questionList.data && questionList.data.length > 0">
                <div class="table-responsive">
                  <table
                    class="table table-striped"
                    v-for="(parentQuestion, parentQuestionIndex) in questionList.data" :key="parentQuestionIndex"
                  >
                    <thead>
                      <tr v-if="!parentQuestion.parent_id && parentQuestion?.inspection_checklist_question?.length <= 0">
                        <th colspan="6" class="align-middle text-center bg-secondary">{{ parentQuestion?.name }}</th>
                      </tr>
                      <tr v-else @click="toggleRow(`row-${parentQuestionIndex}`)" style="cursor:pointer">
                        <th style="width: 5px;">No</th>
                        <th style="max-width: 100px;" class="text-truncate">{{ parentQuestion?.name }}</th>
                        <th style="width: 150px;">Alias</th>
                        <th style="width: 150px;">Status</th>
                        <th class="text-center" style="width: 5px;">#</th>
                      </tr>
                    </thead>
                    <tbody v-if="parentQuestion?.inspection_checklist_question?.length > 0">
                      <template v-if="isRowVisible(`row-${parentQuestionIndex}`)" :class="{ 'collapsed-row': !isRowVisible(`row-${parentQuestionIndex}`), 'expanded-row': isRowVisible(`row-${parentQuestionIndex}`) }">
                        <tr v-for="(question, questionId) in parentQuestion?.inspection_checklist_question" :key="questionId">
                          <td>{{ questionId + 1 }}</td>
                          <td>{{ question.question }}</td>
                          <td>{{ question.alias }}</td>
                          <td
                            :class="
                              question.status ? 'text-success' : 'text-danger'
                            "
                            >{{ question.status ? 'Active' : 'Inactive' }}</td
                          >
                          <td>
                            <div class="d-flex">
                              <b-button variant="success" size="sm" class="mr-2"
                                ><i
                                  class="fa fa-edit"
                                  @click="editFormQuestion(question)"
                                ></i
                              ></b-button>
                              <b-button
                                variant="danger"
                                size="sm"
                                @click="onDeleteQuestion(question)"
                              >
                                <i
                                  v-if="!delete_question"
                                  class="fa fa-trash"
                                ></i>
                                <template v-else>
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span
                                  >Deleting...
                                </template>
                              </b-button>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <p v-else class="text-center my-4">No Question data found.</p>
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4">
        <iq-card
          :class="{
            'border border-danger shadow-lg': question_edit,
          }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title"
              >{{ question_edit ? 'Edit' : 'Add' }} Question</h4
            >
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="onSaveQuestion()">
              <b-form-group label="Question" label-for="question">
                <b-form-input
                  v-model="formQuestion.question"
                  id="question"
                  type="text"
                  placeholder="Type text"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Alias" label-for="alias">
                <b-form-input
                  v-model="formQuestion.alias"
                  id="alias"
                  type="text"
                  placeholder="Type text"
                  maxlength="30"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Checklist Category"
                label-for="checklist_category_id"
              >
                <v-select
                  v-model="formQuestion.checklist_category_id"
                  label="text"
                  :options="listCategory"
                  :reduce="(obj) => obj.value"
                  placeholder="Select checklist category"
                  required
                ></v-select>
              </b-form-group>
              <b-form-group label="Status" label-for="status">
                <b-form-checkbox
                  v-model="formQuestion.status"
                  name="check-button"
                  switch
                  inline
                  >Active / Inactive
                </b-form-checkbox>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                  <span
                    v-if="saving_question"
                    class="spinner-border spinner-border-sm"
                  />
                  <span v-else-if="!question_edit && !saving_question"
                    >Add</span
                  >
                  <span v-else-if="question_edit && !saving_question"
                    >Save Edit</span
                  >
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click="resetFormQuestion()"
                  >Cancel</b-button
                >
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CustomNav from '@/src/components/global/CustomNav.vue'
import { busdevActions, maintenanceActions, srActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MasterChecklist',
  components: {
    CustomNav,
  },
  data() {
    return {
      visibleRows: {},

      listVehicleTypeMasterSection: [],
      optionsMaintenanceComponent: [],
      optionsVehicleType: [],

      listCategory: [],
      listParent: [],
      viewMenu: 'category',
      questionList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          question: '',
          // active: true,
          perPage: 10000,
          page: 1,
        },
        meta: {
          perPage: 0,
          page: 0,
          totalItem: 0,
          totalPages: 0,
        },
      },
      categoryList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1,
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      loading_question: false,
      saving_question: false,
      delete_question: false,

      loading_category: false,
      saving_category: false,
      delete_category: false,

      formQuestion: {
        alias: '',
        question: '',
        checklist_category_id: null,
        status: true,
      },
      formCategory: {
        alias: '',
        name: '',
        maintenance_component_id: null,
        vehicle_type_m_section_id: null,
        parent_id: null,
        status: true,
      },
      question_edit: null,
      question_id: null,
      category_edit: null,
      category_id: null,
      navItems: [
        { label: 'Master Data Category', value: 'category' },
        { label: 'Master Data Question', value: 'question' },
      ],
    }
  },
  async mounted() {
    await this.getInspectionChecklistCategory()
    await this.getListCategory()
    await this.getListVehicleType()
    await this.getVehicleTypeMasterSection()

    await this.getInspectionChecklistQuestion()
  },
  created() {
    this.getInspectionChecklistCategory = _.debounce(
      this.getInspectionChecklistCategory,
      200
    )
    this.getInspectionChecklistQuestion = _.debounce(
      this.getInspectionChecklistQuestion,
      200
    )
  },
  watch: {
    'formCategory.vehicle_type_id': {
      deep: true,
      immediate:true,
      handler: function (value) {
        if (value) {
          this.fetchComponent()
        }
      },
    }
  },
  methods: {
    ...busdevActions,
    ...maintenanceActions,
    ...srActions,
    parseStr(str) {
      if(!str) return ''
      return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    toggleRow(id) {
      this.$set(this.visibleRows, id, !this.visibleRows[id]);
    },
    isRowVisible(id) {
      return !!this.visibleRows[id];
    },

    async getListVehicleType() {
      let res = await this.fetchListVehicleType()
      if (res.status === 'error') {
        this.optionsVehicleType = []
      } else {
        res.data.data.map(val => {
          this.optionsVehicleType.push({
            text:val.name,
            value:val.id
          })
        })
      }
    },
    async getVehicleTypeMasterSection () {
      let params = {
        page: 1,
        perPage:100000,
        status:1
      }

      const res = await this.fetchVehicleTypeMasterSection(params)

      if (res.data.status == true && res?.data?.data?.data.length > 0) {
        res?.data?.data?.data.map(val => {
          this.listVehicleTypeMasterSection.push({
            text:val.name,
            value:val.id
          })
        })
      } 
    },
    async fetchComponent() {
      let params = {
        vehicleTypeId: Number(this.formCategory.vehicle_type_id)
      }

      const res = await this.getComponent(params)
      this.optionsMaintenanceComponent = res?.data?.maintenanceComponents?.length > 0 ? 
        res?.data?.maintenanceComponents?.map(val => {
          return {
            value: val.component.id,
            text: val.component.componentName
          }
        }) : []

      console.log(this.optionsMaintenanceComponent, "this.optionsMaintenanceComponent")
    }, 
    async getListCategory() {
      let params = {
        page: 1,
        perPage: 10000,
        status: 1,
      }

      const res = await this.fetchInspectionChecklistCategory(params)

      if (res.data.status == true && res?.data?.data?.data.length > 0) {
        res.data.data?.data?.map((val) => {
          this.listCategory.push({
            text: val.name,
            value: val.id,
          })
        })
        res.data.data?.data?.map((val) => {
          if (val.parent_id === null) {
            this.listParent.push({
              text: val.name,
              value: val.id,
            })
          }
        })
      }
    },
    async getInspectionChecklistCategory(page) {
      this.categoryList._loading = true
      this.categoryList.data = []
      let params = {
        ...this.categoryList.params,
        page: page || 1,
      }
      if (!params.name) delete params.name

      const res = await this.fetchInspectionChecklistCategory(params)

      if (res.data.status == true) {
        if (res?.data?.data?.data.length > 0) {
          this.categoryList.data = res.data.data?.data
          this.categoryList.meta = res.data.data?.meta
        }
        this.categoryList._loading = false
      } else {
        this.categoryList._loading = false
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async getInspectionChecklistQuestion(page) {
      this.questionList._loading = true
      this.questionList.data = []
      let params = {
        ...this.questionList.params,
        page: page || 1,
      }
      if (!params.question) delete params.question

      const res = await this.fetchInspectionChecklistQuestion(params)

      if (res.data.status == true) {
        if (res?.data?.data?.data.length > 0) {
          this.questionList.data = res.data.data?.data
          this.questionList.meta = res.data.data?.meta
        }
        this.questionList._loading = false
      } else {
        this.questionList._loading = false
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },

    async onSaveCategory() {
      this.saving_category = true
      let payload = {
        ...this.formCategory,
        parent_id: this.formCategory.parent_id ?? null,
        status: this.formCategory.status ? 1 : 0,
      }

      let res = null
      if (this.category_edit && this.category_edit) {
        res = await this.updateInspectionChecklistCategory({
          data: payload,
          id: this.category_id,
        })
      } else if (!this.category_edit) {
        res = await this.createInspectionChecklistCategory({ data: payload })
      }

      console.log(res.data, 'what is this')

      if (res.data.status === true) {
        this.$swal(
          `${
            this.category_edit ? 'Edit' : 'Add'
          } Inspection Checklist Category Success!`,
          res.data.message,
          'success'
        )
        this.resetFormCategory()
        this.getInspectionChecklistCategory()
        this.saving_category = false
      } else {
        this.$swal(`Oops!`, res.data, 'error')
        this.saving_category = false
      }
      this.saving_category = false
    },
    async onSaveQuestion() {
      this.saving_question = true
      let payload = {
        ...this.formQuestion,
        status: this.formQuestion.status ? 1 : 0,
      }

      let res = null
      if (this.question_edit && this.question_id) {
        res = await this.updateInspectionChecklistQuestion({
          data: payload,
          id: this.question_id,
        })
      } else if (!this.question_edit) {
        res = await this.createInspectionChecklistQuestion({ data: payload })
      }

      console.log(res.data, 'what is this')

      if (res.data.status === true) {
        this.$swal(
          `${
            this.question_edit ? 'Edit' : 'Add'
          } Inspection Checklist Question Success!`,
          res.data.message,
          'success'
        )
        this.resetFormQuestion()
        this.getInspectionChecklistQuestion()
        this.saving_question = false
      } else {
        this.$swal(`Oops!`, res.data, 'error')
        this.saving_question = false
      }
      this.saving_question = false
    },

    resetFormCategory(data) {
      this.formCategory = {
        alias: '',
        name: '',
        maintenance_component_id: null,
        vehicle_type_m_section_id: null,
        parent_id: null,
        status: true,
      }
      this.category_edit = false
      this.category_id = null
    },
    resetFormQuestion(data) {
      this.formQuestion = {
        alias: '',
        question: '',
        checklist_category_id: null,
        status: true,
      }
      this.question_edit = false
      this.question_id = null
    },

    editFormCategory(data) {
      this.formCategory = {
        alias: data.alias,
        name: data.name,
        vehicle_type_id: data.vehicle_type_id,
        maintenance_component_id: data.maintenance_component_id,
        vehicle_type_m_section_id: data.vehicle_type_m_section_id,
        parent_id: Number(data.parent_id),
        status: data.status == 1 ? true : false,
      }
      this.category_edit = true
      this.category_id = data.id
    },
    editFormQuestion(data) {
      this.formQuestion = {
        alias: data?.alias,
        question: data?.question,
        checklist_category_id: data?.checklist_category_id,
        status: data?.status == 1 ? true : false,
      }
      this.question_edit = true
      this.question_id = data.id
    },

    async onDeleteCategory(data) {
      this.$swal({
        title: 'Delete Category?',
        text: `Category ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_category = true
          let res = await this.deleteInspectionChecklistCategory({
            id: data.id,
          })
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_category = false
          } else {
            this.$swal(
              `Category deleted!`,
              `Category ${data.name} successfully deleted`,
              'success'
            )
            await this.getInspectionChecklistCategory()
            this.saving_category = false
          }
        }
      })
    },
    async onDeleteQuestion(data) {
      this.$swal({
        title: 'Delete Question?',
        text: `${data.question} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_question = true
          let res = await this.deleteInspectionChecklistQuestion({
            id: data.id,
          })
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_question = false
          } else {
            this.$swal(
              `Question deleted!`,
              `${data.name} successfully deleted`,
              'success'
            )
            await this.getInspectionChecklistQuestion()
            this.saving_question = false
          }
        }
      })
    },
  },
}
</script>

<style scoped>
/* Collapsible Row Styles */
.collapsed-row .collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;
}

.expanded-row .collapsible-content {
  max-height: 100px; /* Set to a height that fits the content */
  transition: max-height 0.3s ease-in;
  opacity: 1;
}

.collapsible-content {
  transition: opacity 0.3s ease, max-height 0.3s ease;
}
</style>

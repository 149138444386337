var render = function render(){
  var _vm$form$assessor_ful, _vm$form$assessor_erm, _vm$form$target_pic, _vm$form$target_locat;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "pl-3",
    staticStyle: {
      "width": "20%"
    }
  }, [_c('TabPaneVertical', {
    attrs: {
      "activeTab": _vm.activeTab
    },
    on: {
      "clickTab": function clickTab(val) {
        return _vm.activeTab = val;
      }
    }
  })], 1), _c('div', {
    staticStyle: {
      "width": "80%"
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_vm.detailMi ? _c('b-row', [_c('b-col', {
    staticClass: "text-right mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isPreview = !_vm.isPreview;
      }
    }
  }, [_vm._v(_vm._s(_vm.isPreview ? 'Edit' : 'Preview') + " ERM")])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-row', {
    staticClass: "px-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('strong', [_vm._v("Enterprise Risk Management Assessor :")])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [!_vm.isPreview ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "required": "",
      "type": "text",
      "placeholder": "Name..."
    },
    model: {
      value: _vm.form.assessor_fullname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "assessor_fullname", $$v);
      },
      expression: "form.assessor_fullname"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$assessor_ful = _vm.form.assessor_fullname) !== null && _vm$form$assessor_ful !== void 0 ? _vm$form$assessor_ful : '-'))])], 1), _c('b-form-group', {
    attrs: {
      "label": "Department"
    }
  }, [_vm.isPreview ? [_vm._v(" " + _vm._s(_vm.departments.find(function (department) {
    return department.code === _vm.form.assessor_department_id;
  }) ? _vm.departments.find(function (department) {
    return department.code === _vm.form.assessor_department_id;
  }).name : '-') + " ")] : _c('v-select', {
    attrs: {
      "required": "",
      "label": "name",
      "options": _vm.departments,
      "reduce": function reduce(department) {
        return department.code;
      }
    },
    model: {
      value: _vm.form.assessor_department_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "assessor_department_id", $$v);
      },
      expression: "form.assessor_department_id"
    }
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company"
    }
  }, [_vm.isPreview ? [_vm._v(" " + _vm._s(_vm.companies.find(function (company) {
    return company.id === _vm.form.assessor_company_id;
  }) ? _vm.companies.find(function (company) {
    return company.id === _vm.form.assessor_company_id;
  }).company : '-') + " ")] : _c('v-select', {
    attrs: {
      "required": "",
      "label": "company",
      "options": _vm.companies,
      "reduce": function reduce(companyOptions) {
        return companyOptions.id;
      }
    },
    model: {
      value: _vm.form.assessor_company_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "assessor_company_id", $$v);
      },
      expression: "form.assessor_company_id"
    }
  })], 2), _c('b-form-group', {
    attrs: {
      "label": "ERM Credential "
    }
  }, [!_vm.isPreview ? _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "required": "",
      "type": "text",
      "placeholder": ""
    },
    model: {
      value: _vm.form.assessor_erm_credential,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "assessor_erm_credential", $$v);
      },
      expression: "form.assessor_erm_credential"
    }
  }) : _c('h6', [_vm._v(_vm._s((_vm$form$assessor_erm = _vm.form.assessor_erm_credential) !== null && _vm$form$assessor_erm !== void 0 ? _vm$form$assessor_erm : '-'))])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-row', {
    staticClass: "px-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('strong', [_vm._v("Enterprise Risk Management Target :")])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Person In Charge"
    }
  }, [_vm.isPreview ? _c('h6', [_vm._v(_vm._s((_vm$form$target_pic = _vm.form.target_pic) !== null && _vm$form$target_pic !== void 0 ? _vm$form$target_pic : '-'))]) : _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "required": "",
      "type": "text",
      "placeholder": "PIC"
    },
    model: {
      value: _vm.form.target_pic,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "target_pic", $$v);
      },
      expression: "form.target_pic"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Department"
    }
  }, [_vm.isPreview ? [_vm._v(" " + _vm._s(_vm.departments.find(function (department) {
    return department.code === _vm.form.target_department_id;
  }) ? _vm.departments.find(function (department) {
    return department.code === _vm.form.target_department_id;
  }).name : '-') + " ")] : _c('v-select', {
    attrs: {
      "required": "",
      "multiple": "",
      "label": "name",
      "options": _vm.departments,
      "reduce": function reduce(department) {
        return department.code;
      }
    },
    model: {
      value: _vm.form.target_department_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "target_department_id", $$v);
      },
      expression: "form.target_department_id"
    }
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Location"
    }
  }, [_vm.isPreview ? _c('h6', [_vm._v(_vm._s((_vm$form$target_locat = _vm.form.target_location) !== null && _vm$form$target_locat !== void 0 ? _vm$form$target_locat : '-'))]) : _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "required": "",
      "type": "text",
      "placeholder": ""
    },
    model: {
      value: _vm.form.target_location,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "target_location", $$v);
      },
      expression: "form.target_location"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Month Assessment"
    }
  }, [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(_vm.moment(_vm.form.date_range[0]).format('DD MMM YYYY')) + " - " + _vm._s(_vm.moment(_vm.form.date_range[2]).format('DD MMM YYYY')))]) : _c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.isPreview,
      "required": "",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "type": "date",
      "range": "",
      "placeholder": "Select date range"
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeDate();
      }
    },
    model: {
      value: _vm.form.date_range,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date_range", $$v);
      },
      expression: "form.date_range"
    }
  })], 1)], 1)], 1)], 1), _vm.activeTab == 1 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "text-primary mb-2"
  }, [_c('strong', [_vm._v("Sasaran Smart")])]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "4%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("Sasaran")]), _c('th', {
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("Spesific")]), _c('th', {
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("Measurable")]), _c('th', {
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("Achievable")]), _c('th', {
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("Relevant")]), _c('th', {
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("Time-Bound")]), !_vm.isPreview ? _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "4%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.sasaran_smart, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.sasaran) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "placeholder": "Input your text...",
        "rows": "4"
      },
      on: {
        "change": function change($event) {
          return _vm.onSasaranSmartChanged(index);
        }
      },
      model: {
        value: data.sasaran,
        callback: function callback($$v) {
          _vm.$set(data, "sasaran", $$v);
        },
        expression: "data.sasaran"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.spesific) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      on: {
        "change": function change($event) {
          return _vm.onSasaranSmartChanged(index);
        }
      },
      model: {
        value: data.spesific,
        callback: function callback($$v) {
          _vm.$set(data, "spesific", $$v);
        },
        expression: "data.spesific"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.measurable) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.measurable,
        callback: function callback($$v) {
          _vm.$set(data, "measurable", $$v);
        },
        expression: "data.measurable"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.achievable) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.achievable,
        callback: function callback($$v) {
          _vm.$set(data, "achievable", $$v);
        },
        expression: "data.achievable"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.relevant) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.relevant,
        callback: function callback($$v) {
          _vm.$set(data, "relevant", $$v);
        },
        expression: "data.relevant"
      }
    })], 2), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(_vm.moment(data.timebound).format('DD MMM YYYY')))]) : _c('date-picker', {
      attrs: {
        "disabled": _vm.isPreview,
        "value-type": "YYYY-MM-DD",
        "format": "DD MMM YYYY",
        "type": "date",
        "placeholder": "Select date"
      },
      model: {
        value: data.timebound,
        callback: function callback($$v) {
          _vm.$set(data, "timebound", $$v);
        },
        expression: "data.timebound"
      }
    })], 1), !_vm.isPreview ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeSasaranSmart(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1) : _vm._e()]);
  }), 0)]), !_vm.isPreview ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addSasaranSmart
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)]) : _vm._e(), _vm.activeTab == 2 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary mb-2"
  }, [_c('strong', [_vm._v("Penamaan Risiko")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Sasaran")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Nama Risiko")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Perisitiwa")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Sebab")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Konsekuensi")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Kategori Risiko")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Tindakan")])])]), _c('tbody', _vm._l(_vm.form.penamaan_resiko, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.sasaran) + " ")] : _c('b-form-textarea', {
      model: {
        value: data.sasaran,
        callback: function callback($$v) {
          _vm.$set(data, "sasaran", $$v);
        },
        expression: "data.sasaran"
      }
    })], 2), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(data.risiko ? _vm.listRisk.find(function (el) {
      return el.value == data.risiko;
    }).text : '-'))]) : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.listRisk,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Risk")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.risiko,
        callback: function callback($$v) {
          _vm.$set(data, "risiko", $$v);
        },
        expression: "data.risiko"
      }
    })], 1), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.peristiwa) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.peristiwa,
        callback: function callback($$v) {
          _vm.$set(data, "peristiwa", $$v);
        },
        expression: "data.peristiwa"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.sebab) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.sebab,
        callback: function callback($$v) {
          _vm.$set(data, "sebab", $$v);
        },
        expression: "data.sebab"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.konsekuensi) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.konsekuensi,
        callback: function callback($$v) {
          _vm.$set(data, "konsekuensi", $$v);
        },
        expression: "data.konsekuensi"
      }
    })], 2), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(data.kategori_risiko ? _vm.catRisk.find(function (el) {
      return el.value == data.kategori_risiko;
    }).text : '-'))]) : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.catRisk,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.kategori_risiko,
        callback: function callback($$v) {
          _vm.$set(data, "kategori_risiko", $$v);
        },
        expression: "data.kategori_risiko"
      }
    })], 1), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.tindakan) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      on: {
        "change": function change($event) {
          return _vm.onPenamaanResikoChanged(index);
        }
      },
      model: {
        value: data.tindakan,
        callback: function callback($$v) {
          _vm.$set(data, "tindakan", $$v);
        },
        expression: "data.tindakan"
      }
    })], 2)]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 3 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Prinsip Manajemen Risiko")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "4%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Prinsip Manajemen Risiko")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Definisi Prinsip")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Implementasi Manajemen Risiko")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Status Implementasi")])])]), _c('tbody', _vm._l(_vm.form.prinsip_manajemen_resiko, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('p', [_vm._v(_vm._s(data.prinsip))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.definisi))])]), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.implementasi) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.implementasi,
        callback: function callback($$v) {
          _vm.$set(data, "implementasi", $$v);
        },
        expression: "data.implementasi"
      }
    })], 2), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(data.status ? _vm.statusImplementasi.find(function (el) {
      return el.value == data.status;
    }).text : '-'))]) : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.statusImplementasi,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.status,
        callback: function callback($$v) {
          _vm.$set(data, "status", $$v);
        },
        expression: "data.status"
      }
    })], 1)]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 4 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "text-primary mb-2"
  }, [_c('b', [_vm._v("Kepemimpinan Dan Komitmen")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "24%"
    }
  }, [_vm._v("Pemimpin Risiko")]), _c('th', {
    attrs: {
      "width": "24%"
    }
  }, [_vm._v("Uraian Tugas Pemimpin Risiko")]), _c('th', {
    attrs: {
      "width": "24%"
    }
  }, [_vm._v("Dokumen Pendukung")]), _c('th', {
    attrs: {
      "width": "24%"
    }
  }, [_vm._v("Remarks")]), !_vm.isPreview ? _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "4%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.kepemimpinan_komitmen, function (data, index) {
    var _data$pimpinan;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s((_data$pimpinan = data.pimpinan) !== null && _data$pimpinan !== void 0 ? _data$pimpinan : '-'))]) : _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "required": "",
        "type": "text",
        "placeholder": ""
      },
      model: {
        value: data.pimpinan,
        callback: function callback($$v) {
          _vm.$set(data, "pimpinan", $$v);
        },
        expression: "data.pimpinan"
      }
    })], 1), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.tugas) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.tugas,
        callback: function callback($$v) {
          _vm.$set(data, "tugas", $$v);
        },
        expression: "data.tugas"
      }
    })], 2), _c('td', [data.dokumen || _vm.isPreview ? _c('div', {
      staticClass: "d-flex justify-content-between"
    }, [data.dokumen ? [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-primary",
      attrs: {
        "href": data.dokumen,
        "title": "Download File",
        "download": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    }), _vm._v(" Download")]), !_vm.isPreview ? _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-danger",
      attrs: {
        "href": "#",
        "title": "Hapus",
        "download": ""
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          data.dokumen = '';
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })]) : _vm._e()] : [_c('div', [_vm._v(" - ")])]], 2) : [_c('div', {
      staticClass: "custom-file"
    }, [_c('input', {
      staticClass: "custom-file-input",
      attrs: {
        "id": "commitment-file-input-".concat(index),
        "type": "file",
        "accept": "image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx"
      },
      on: {
        "change": function change($event) {
          return _vm.onKepemimpinanKomitmentFileChanged(index, $event);
        }
      }
    }), _c('label', {
      staticClass: "custom-file-label",
      attrs: {
        "for": "commitment-file-input-".concat(index)
      }
    }, [_vm._v("Browse file...")])]), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(" File Type * : "), _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v("3 MB / File")])], 1)]], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.remarks) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 2), !_vm.isPreview ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeKepemimpinanKomitment(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1) : _vm._e()]);
  }), 0)]), !_vm.isPreview ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addKepemimpinanKomitment
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)], 1)], 1)]) : _vm._e(), _vm.activeTab == 5 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Analisa Pemangku Kepentingan")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("NO")]), _c('th', {
    attrs: {
      "width": "11%"
    }
  }, [_vm._v("Status")]), _c('th', {
    attrs: {
      "width": "11%"
    }
  }, [_vm._v("Nama")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Kebutuhan/Tantangan")]), _c('th', {
    attrs: {
      "width": "11%"
    }
  }, [_vm._v("Dukungan")]), _c('th', {
    attrs: {
      "width": "11%"
    }
  }, [_vm._v("Ancaman")]), _c('th', {
    attrs: {
      "width": "11%"
    }
  }, [_vm._v("Strategi")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Deskripsi Tindakan")]), _c('th', {
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Remarks")]), !_vm.isPreview ? _c('th', {
    attrs: {
      "width": "3%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.analisa_pemangku_jabatan, function (data, index) {
    var _data$nama;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(data.status ? _vm.statusKesesuaian.find(function (el) {
      return el.value == data.status;
    }).text : '-'))]) : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.statusKesesuaian,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.status,
        callback: function callback($$v) {
          _vm.$set(data, "status", $$v);
        },
        expression: "data.status"
      }
    })], 1), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s((_data$nama = data.nama) !== null && _data$nama !== void 0 ? _data$nama : '-'))]) : _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": ""
      },
      model: {
        value: data.nama,
        callback: function callback($$v) {
          _vm.$set(data, "nama", $$v);
        },
        expression: "data.nama"
      }
    })], 1), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.tantangan) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.tantangan,
        callback: function callback($$v) {
          _vm.$set(data, "tantangan", $$v);
        },
        expression: "data.tantangan"
      }
    })], 2), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(data.dukungan ? _vm.dukunganAncaman.find(function (el) {
      return el.value == data.dukungan;
    }).text : '-'))]) : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.dukunganAncaman,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.dukungan,
        callback: function callback($$v) {
          _vm.$set(data, "dukungan", $$v);
        },
        expression: "data.dukungan"
      }
    })], 1), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(data.ancaman ? _vm.dukunganAncaman.find(function (el) {
      return el.value == data.ancaman;
    }).text : '-'))]) : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.dukunganAncaman,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.ancaman,
        callback: function callback($$v) {
          _vm.$set(data, "ancaman", $$v);
        },
        expression: "data.ancaman"
      }
    })], 1), _c('td', [_vm.isPreview ? _c('h6', [_vm._v(_vm._s(data.strategi ? _vm.listStrategi.find(function (el) {
      return el.value == data.strategi;
    }).text : '-'))]) : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.listStrategi,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.strategi,
        callback: function callback($$v) {
          _vm.$set(data, "strategi", $$v);
        },
        expression: "data.strategi"
      }
    })], 1), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.tindakan) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.tindakan,
        callback: function callback($$v) {
          _vm.$set(data, "tindakan", $$v);
        },
        expression: "data.tindakan"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.remarks) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 2), !_vm.isPreview ? _c('td', {
      staticClass: "text-center"
    }, [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeAnalisaPemangkuJabatan(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1) : _vm._e()]);
  }), 0)]), !_vm.isPreview ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addAnalisaPemangkuJabatan
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)], 1)], 1)]) : _vm._e(), _vm.activeTab == 6 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Integrasi - Sasaran Smart")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Status Kesesuaian")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Deskripsi")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Dokumen Pendukung")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.integrasi_sasaran_smart, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('p', [_vm._v(_vm._s(data.kesesuaian))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.deskripsi))])]), _c('td', [data.dokumen || _vm.isPreview ? _c('div', {
      staticClass: "d-flex justify-content-between"
    }, [data.dokumen ? _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-primary",
      attrs: {
        "href": data.dokumen,
        "title": "Download File",
        "download": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    }), _vm._v(" Download")]) : _c('h6', [_vm._v("-")]), !_vm.isPreview ? _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-danger",
      attrs: {
        "href": "#",
        "title": "Hapus",
        "download": ""
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          data.dokumen = '';
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })]) : _vm._e()]) : [_c('div', {
      staticClass: "custom-file"
    }, [_c('input', {
      staticClass: "custom-file-input",
      attrs: {
        "id": "integrasi-sasaran-smart-file-input-".concat(index),
        "type": "file",
        "accept": "image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx"
      },
      on: {
        "change": function change($event) {
          return _vm.onIntegrasiSasaranSmartFileChanged(index, $event);
        }
      }
    }), _c('label', {
      staticClass: "custom-file-label",
      attrs: {
        "for": "integrasi-sasaran-smart-file-input-".concat(index)
      }
    }, [_vm._v("Browse file...")])]), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(" File Type * : "), _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v("3 MB / File")])], 1)]], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.remarks) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 2)]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 7 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "text-primary mb-2"
  }, [_c('b', [_vm._v("Evaluasi")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "13%"
    }
  }, [_vm._v("Tahapan Kegiatan")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "13%"
    }
  }, [_vm._v("Sasaran Tahapan (Milestone)")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "13%"
    }
  }, [_vm._v("Uraian Tugas")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "13%"
    }
  }, [_vm._v("Indikator Keberhasilan")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Metode Evaluasi")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Frekuensi")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Remarks")]), !_vm.isPreview ? _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.evaluasi, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.kegiatan) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.kegiatan,
        callback: function callback($$v) {
          _vm.$set(data, "kegiatan", $$v);
        },
        expression: "data.kegiatan"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.sasaran) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.sasaran,
        callback: function callback($$v) {
          _vm.$set(data, "sasaran", $$v);
        },
        expression: "data.sasaran"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.uraian) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.uraian,
        callback: function callback($$v) {
          _vm.$set(data, "uraian", $$v);
        },
        expression: "data.uraian"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.keberhasilan) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.keberhasilan,
        callback: function callback($$v) {
          _vm.$set(data, "keberhasilan", $$v);
        },
        expression: "data.keberhasilan"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.evaluasi) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.evaluasi,
        callback: function callback($$v) {
          _vm.$set(data, "evaluasi", $$v);
        },
        expression: "data.evaluasi"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.frekuensi) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.frekuensi,
        callback: function callback($$v) {
          _vm.$set(data, "frekuensi", $$v);
        },
        expression: "data.frekuensi"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.remarks) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 2), !_vm.isPreview ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeEvaluasi(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1) : _vm._e()]);
  }), 0)]), !_vm.isPreview ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addEvaluasi
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)], 1)], 1)]) : _vm._e(), _vm.activeTab == 8 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Perbaikan Sinambung")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Tahapan")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Sasaran Tahapan")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Uraian Tugas")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Indikator Keberhasilan")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Hasil Evaluasi")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Frekuensi")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "14%"
    }
  }, [_vm._v("Pelaksana")])])]), _c('tbody', _vm._l(_vm.form.perbaikan_sinambung, function (data, index) {
    var _data$pelaksana;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('p', [_vm._v(_vm._s(data.tahapan))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.sasaran))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.uraian))])]), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.keberhasilan || '-') + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4",
        "placeholder": "Berjalannya unit manajemen resiko dengan pencapaian Terkendalinya accident/ incident setiap bulannya dengan minimum 2 major case"
      },
      model: {
        value: data.keberhasilan,
        callback: function callback($$v) {
          _vm.$set(data, "keberhasilan", $$v);
        },
        expression: "data.keberhasilan"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.evaluasi || '-') + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.evaluasi,
        callback: function callback($$v) {
          _vm.$set(data, "evaluasi", $$v);
        },
        expression: "data.evaluasi"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.frekuensi || '-') + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4",
        "placeholder": "Dilakukan pengawasan serta review lebih lanjut terkait hambatan-hambatan yang terjadi dalam prosesnya"
      },
      model: {
        value: data.frekuensi,
        callback: function callback($$v) {
          _vm.$set(data, "frekuensi", $$v);
        },
        expression: "data.frekuensi"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s((_data$pelaksana = data.pelaksana) !== null && _data$pelaksana !== void 0 ? _data$pelaksana : '-') + " ")] : _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Capt Doddy"
      },
      model: {
        value: data.pelaksana,
        callback: function callback($$v) {
          _vm.$set(data, "pelaksana", $$v);
        },
        expression: "data.pelaksana"
      }
    })], 2)]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 9 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Komunikasi Dan Konsultasi")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "4%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Pemangku")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Konten")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Tujuan")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Komunikator")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Waktu")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Frekuensi")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Media/Metode")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("PIC")]), !_vm.isPreview ? _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "4%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.komunikasi_konsultasi, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.pemangku || '-') + " ")] : _c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": ""
      },
      model: {
        value: data.pemangku,
        callback: function callback($$v) {
          _vm.$set(data, "pemangku", $$v);
        },
        expression: "data.pemangku"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.konten || '-') + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4",
        "placeholder": "Minimalisir risiko dan kerugian dari kejadian-kejadian yang terjadi dan melakukan evaluasi atas kendali yang sudah dan akan dilakukan kedepannya."
      },
      model: {
        value: data.konten,
        callback: function callback($$v) {
          _vm.$set(data, "konten", $$v);
        },
        expression: "data.konten"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.tujuan || '-') + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4",
        "placeholder": "Koordinasi dan induksi"
      },
      model: {
        value: data.tujuan,
        callback: function callback($$v) {
          _vm.$set(data, "tujuan", $$v);
        },
        expression: "data.tujuan"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.komunikator || '-') + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4",
        "placeholder": "Superintendent, PE, PC, Radio Room, QHSE"
      },
      model: {
        value: data.komunikator,
        callback: function callback($$v) {
          _vm.$set(data, "komunikator", $$v);
        },
        expression: "data.komunikator"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.waktu || '-') + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4",
        "placeholder": "WEEKLY Meeting"
      },
      model: {
        value: data.waktu,
        callback: function callback($$v) {
          _vm.$set(data, "waktu", $$v);
        },
        expression: "data.waktu"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.frekuensi ? _vm.frekuensi.find(function (el) {
      return el.value == data.frekuensi;
    }).text : '-') + " ")] : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.frekuensi,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.frekuensi,
        callback: function callback($$v) {
          _vm.$set(data, "frekuensi", $$v);
        },
        expression: "data.frekuensi"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.metode || '-') + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4",
        "placeholder": "Melalui presentasi capaian kendali saat rapat dilakukan"
      },
      model: {
        value: data.metode,
        callback: function callback($$v) {
          _vm.$set(data, "metode", $$v);
        },
        expression: "data.metode"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.pic ? _vm.userLevel.find(function (el) {
      return el.value == data.pic;
    }).text : '-') + " ")] : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.userLevel,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.pic,
        callback: function callback($$v) {
          _vm.$set(data, "pic", $$v);
        },
        expression: "data.pic"
      }
    })], 2), !_vm.isPreview ? _c('td', {
      staticClass: "text-center"
    }, [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeKomunikasiKonsultasi(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1) : _vm._e()]);
  }), 0)]), !_vm.isPreview ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addKomunikasiKonsultasi
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)], 1)], 1)]) : _vm._e(), _vm.activeTab == 10 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Raci Matrix")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto"
  }, [_vm.raciMatrix ? _c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("Details")]), _vm.raciMatrix.columns ? _c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": _vm.raciMatrix.columns.length
    }
  }, [_vm._v("Owner Risk")]) : _vm._e()]), _vm.raciMatrix.columns ? _c('tr', _vm._l(_vm.raciMatrix.columns, function (col, colIndex) {
    return _c('th', {
      key: colIndex,
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(col.name))]);
  }), 0) : _vm._e()]), _vm.raciMatrix.rows ? _c('tbody', _vm._l(_vm.raciMatrix.rows, function (row, rowIndex) {
    return _c('tr', {
      key: rowIndex
    }, [_c('td', {
      staticClass: "text-truncate"
    }, [_c('strong', [_vm._v(_vm._s(row.name))])]), _vm.raciMatrix.columns ? _vm._l(_vm.raciMatrix.columns, function (col, colIndex) {
      var _vm$raxiMatrixFormVal;
      return _c('td', {
        key: colIndex,
        staticClass: "text-center"
      }, [_vm.isPreview ? _c('div', {
        staticClass: "d-flex"
      }, [(_vm$raxiMatrixFormVal = _vm.raxiMatrixFormValue(row.id, col.id)) !== null && _vm$raxiMatrixFormVal !== void 0 && _vm$raxiMatrixFormVal.length ? _vm._l(_vm.raxiMatrixFormValue(row.id, col.id), function (item, i) {
        return _c('p', {
          key: i
        }, [_vm._v(_vm._s(item) + _vm._s(i + 1 !== _vm.raxiMatrixFormValue(row.id, col.id).length ? ',' : ''))]);
      }) : [_vm._v(" - ")]], 2) : _c('v-select', {
        attrs: {
          "value": _vm.raxiMatrixFormValue(row.id, col.id),
          "multiple": "",
          "options": ['R', 'A', 'C', 'I']
        },
        on: {
          "input": function input($event) {
            return _vm.onRaciMatrixChanged(row.id, col.id, $event);
          }
        }
      })], 1);
    }) : _vm._e()], 2);
  }), 0) : _vm._e()]) : _vm._e()])])], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-danger text-center text-white"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v("R")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('p', [_c('strong', [_vm._v("\"Responsible\"")]), _vm._v(" adalah orang atau unit yang harus melaksanakan kegiatan atau aktivitas dalam mekanisme informasi dan pelaporan")])]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-warning text-center text-white"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v("A")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('p', [_c('strong', [_vm._v("\"Accountable\"")]), _vm._v(" adalah orang atau unit yang berhak membuat keputusan atas kegiatan dalam mekanisme informasi dan pelaporan")])]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-success text-center text-white"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v("C")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('p', [_c('strong', [_vm._v("\"Consulted\"")]), _vm._v(" adalah orang atau unit yang harus di ajak konsultasi atau dilibatkan sebelum atau saat kegiatan dalam mekanisme informasi dan pelaporan")])]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v("I")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('p', [_c('strong', [_vm._v("\"Informed\"")]), _vm._v(" adalah orang atau unit yang harus di beri cukup informasi mengenal kegiatan dalam mekanisme informasi, berikut pelaporan hasil dan proses pelaksanaan")])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 11 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Kriteria Konsekuensi")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-truncate text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Skala Konsekuensi")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("Deskripsi Skala")]), _c('th', {
    staticClass: "text-truncate text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Deskripsi Kriteria")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.kriteria_konsekuensi, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(data.skala || '-'))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.deskripsi_skala || '-'))])]), _c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(data.deskripsi_kriteria || '-'))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.remarks || '-'))])])]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 12 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Kriteria Kemungkinan")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Skala Kemungkinan")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("Deskripsi Skala")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Deskripsi Kriteria")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.kriteria_kemungkinan, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(data.skala))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.deskripsi_skala))])]), _c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(data.deskripsi_kriteria))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.remarks))])])]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 13 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Efektifitas Kendali")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Skala Efiktifitas Kendali")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("Deskripsi Skala")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Deskripsi Kriteria")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.efektifitas_kendali, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(data.skala))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.deskripsi_skala))])]), _c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(data.deskripsi_kriteria))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.remarks))])])]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 14 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Kriteria Evaluasi")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Efiktifitas Kendali")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Selera Risiko")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Toleransi Risiko")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Tindakan & Eskalasi")])])]), _c('tbody', _vm._l(_vm.form.kriteria_evaluasi, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(data.skala))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.selera))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.toleransi))])]), _c('td', [_c('p', [_vm._v(_vm._s(data.tindakan))])])]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 15 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Identifikasi Risiko")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Sasaran")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Peristiwa Risiko")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Penyebab")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Akibat")]), !_vm.isPreview ? _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.form.identifikasi_resiko, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.sasaran) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.sasaran,
        callback: function callback($$v) {
          _vm.$set(data, "sasaran", $$v);
        },
        expression: "data.sasaran"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.peristiwa) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.peristiwa,
        callback: function callback($$v) {
          _vm.$set(data, "peristiwa", $$v);
        },
        expression: "data.peristiwa"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.penyebab) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.penyebab,
        callback: function callback($$v) {
          _vm.$set(data, "penyebab", $$v);
        },
        expression: "data.penyebab"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.akibat) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.akibat,
        callback: function callback($$v) {
          _vm.$set(data, "akibat", $$v);
        },
        expression: "data.akibat"
      }
    })], 2), !_vm.isPreview ? _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeIdentifikasiResiko(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1) : _vm._e()]);
  }), 0)]), !_vm.isPreview ? _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.addIdentifikasiResiko
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")]) : _vm._e()], 1)], 1)], 1)]) : _vm._e(), _vm.activeTab == 16 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Analisis Dan Evaluasi Risiko")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "4%"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Peristiwa Risiko")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "8%"
    }
  }, [_vm._v("Efektivitas")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "8%"
    }
  }, [_vm._v("Konsekuensi")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "8%"
    }
  }, [_vm._v("Kemungkinan")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "8%"
    }
  }, [_vm._v("Total Eksposur")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Tingkat Risiko")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "8%"
    }
  }, [_vm._v("Priority Risk")]), _c('th', {
    staticClass: "text-truncate",
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Evaluasi Risiko")])])]), _c('tbody', _vm._l(_vm.form.analisis_evaluasi_resiko, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.peristiwa) + " ")] : _c('b-form-textarea', {
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        "rows": "4"
      },
      model: {
        value: data.peristiwa,
        callback: function callback($$v) {
          _vm.$set(data, "peristiwa", $$v);
        },
        expression: "data.peristiwa"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.efektifitas) + " ")] : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.number,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": ''
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.efektifitas,
        callback: function callback($$v) {
          _vm.$set(data, "efektifitas", $$v);
        },
        expression: "data.efektifitas"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.konsekuensi) + " ")] : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.number,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": ''
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.konsekuensi,
        callback: function callback($$v) {
          _vm.$set(data, "konsekuensi", $$v);
        },
        expression: "data.konsekuensi"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.kemungkinan) + " ")] : _c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.number,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": ''
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.kemungkinan,
        callback: function callback($$v) {
          _vm.$set(data, "kemungkinan", $$v);
        },
        expression: "data.kemungkinan"
      }
    })], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.eksposur) + " ")] : _c('p', [_vm._v(" " + _vm._s(data.eksposur = Number(data.konsekuensi) * Number(data.kemungkinan)) + " ")])], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.tingkat_risiko) + " ")] : _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": "Rendah"
            }
          }, [_vm._v("Rendah")]), _c('b-form-select-option', {
            attrs: {
              "value": "Sedang"
            }
          }, [_vm._v("Sedang")]), _c('b-form-select-option', {
            attrs: {
              "value": "Tinggi"
            }
          }, [_vm._v("Tinggi")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.tingkat_risiko,
        callback: function callback($$v) {
          _vm.$set(data, "tingkat_risiko", $$v);
        },
        expression: "data.tingkat_risiko"
      }
    })], 2), _c('td', {
      staticClass: "text-center"
    }, [_vm.isPreview ? [_vm._v(" " + _vm._s(data.prioritas) + " ")] : _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.prioritas,
        callback: function callback($$v) {
          _vm.$set(data, "prioritas", $$v);
        },
        expression: "data.prioritas"
      }
    }, _vm._l(10, function (n, i) {
      return _c('b-form-select-option', {
        key: i,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    }), 1)], 2), _c('td', [_vm.isPreview ? [_vm._v(" " + _vm._s(data.evaluasi) + " ")] : _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": "Tolak"
            }
          }, [_vm._v("Tolak")]), _c('b-form-select-option', {
            attrs: {
              "value": "Turunkan"
            }
          }, [_vm._v("Turunkan")]), _c('b-form-select-option', {
            attrs: {
              "value": "Berbagi"
            }
          }, [_vm._v("Berbagi")]), _c('b-form-select-option', {
            attrs: {
              "value": "Terima"
            }
          }, [_vm._v("Terima")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.evaluasi,
        callback: function callback($$v) {
          _vm.$set(data, "evaluasi", $$v);
        },
        expression: "data.evaluasi"
      }
    })], 2)]);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 17 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Peta Risiko - Before")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("Likelihood/Kemungkinan/")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("Impact/Konsekuensi")])]), _c('tr', _vm._l(5, function (col) {
    return _c('th', {
      key: col,
      staticClass: "text-center"
    }, [_vm._v(_vm._s(col))]);
  }), 0)]), _c('tbody', _vm._l(5, function (row) {
    return _c('tr', {
      key: row
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(row))])]), _vm._l(5, function (col) {
      return _c('td', {
        key: col,
        staticClass: "text-center",
        class: {
          'bg-danger text-white': _vm.petaRisikoBefore(row, col).length > 0
        }
      }, [_vm._v(" " + _vm._s(_vm.petaRisikoBefore(row, col).map(function (r, ir) {
        return ir + 1;
      }).join(',')) + " ")]);
    })], 2);
  }), 0)])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 18 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Perlakuan Risiko")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Peristiwa Risiko")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Total Eksposur")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Evaluasi Risiko")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Tindakan/Kendali")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Frekuensi Pemantauan")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Frekuensi Pelaporan")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Konsekuensi Residual")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Kemungkinan Residual")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Total Eksposur Residual")])])]), _c('tbody', _vm._l(_vm.form.perlakuan_resiko, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(data.peristiwa))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.total_eksposur))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.evaluasi_resiko))]), _c('td', [_vm._v(" " + _vm._s(data.tindakan) + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.frekuensi,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.frekuensi_pemantauan,
        callback: function callback($$v) {
          _vm.$set(data, "frekuensi_pemantauan", $$v);
        },
        expression: "data.frekuensi_pemantauan"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.frekuensi,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.frekuensi_pelaporan,
        callback: function callback($$v) {
          _vm.$set(data, "frekuensi_pelaporan", $$v);
        },
        expression: "data.frekuensi_pelaporan"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.number,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.konsekuensi_residual,
        callback: function callback($$v) {
          _vm.$set(data, "konsekuensi_residual", $$v);
        },
        expression: "data.konsekuensi_residual"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "options": _vm.number,
        "size": "lg"
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: data.kemungkinan_residual,
        callback: function callback($$v) {
          _vm.$set(data, "kemungkinan_residual", $$v);
        },
        expression: "data.kemungkinan_residual"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(data.total_eksposur_residual = Number(data.konsekuensi_residual) * Number(data.kemungkinan_residual)) + " ")])]);
  }), 0)])])])], 1)], 1)]) : _vm._e(), _vm.activeTab == 19 ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('b', [_vm._v("Peta Risiko - After")])]), _c('b-row', [_c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-2"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("Likelihood/Kemungkinan/")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("Impact/Konsekuensi")])]), _c('tr', _vm._l(5, function (col) {
    return _c('th', {
      key: col,
      staticClass: "text-center"
    }, [_vm._v(_vm._s(col))]);
  }), 0)]), _c('tbody', _vm._l(5, function (row) {
    return _c('tr', {
      key: row
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(row))])]), _vm._l(5, function (col) {
      return _c('td', {
        key: col,
        staticClass: "text-center",
        class: {
          'bg-success text-white': _vm.petaRisikoAfter(row, col).length > 0
        }
      }, [_vm._v(" " + _vm._s(_vm.petaRisikoAfter(row, col).map(function (r, ir) {
        return ir + 1;
      }).join(',')) + " ")]);
    })], 2);
  }), 0)])])], 1)], 1)]) : _vm._e(), _c('b-col', {
    staticClass: "text-right mb-4",
    attrs: {
      "md": "12"
    }
  }, [!_vm.isPreview ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "type": "submit",
      "disabled": _vm.form._saving,
      "variant": "primary"
    }
  }, [_vm._v("Simpan Data")]) : _vm._e()], 1)], 1) : _vm._e()], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
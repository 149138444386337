<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="mb-3">
        <b-form-group label="Claim Type">
          <template v-for="(item, index) in typeFormInsurance">
            <b-form-radio
              :key="index"
              v-model="incident.type_form"
              class=""
              inline
              :color="item.color"
              name="color"
              :value="item.value"
              :disabled="readonly"
            >
              <h6>{{ item.label }}</h6></b-form-radio
            >
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <h6 class="card-title text-primary pb-2 border-bottom"
          ><b>INFORMATION OF ACCIDENT/INCIDENT</b></h6
        >
        <b-row>
          <b-col md="6">
            <b-form-group label="Company" label-for="company">
              <!-- <b-form-select
                v-if="isEditMode"
                plain
                v-model="incident.company_id"
                size="lg"
                :disabled="readonly"
              >
                <b-form-select-option :value="null"
                  >Select Company</b-form-select-option
                >
                <b-form-select-option
                  v-for="(company, i) in companies"
                  :key="i"
                  :value="company.value"
                >
                  {{ company.label }}
                </b-form-select-option>
              </b-form-select> -->
              <h6 style="font-weight: 500">{{
                companies.find((el) => el.value == incident.company_id).label
              }}</h6>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Fleet Name" label-for="vehicles">
              <!-- <b-form-select
                v-if="isEditMode"
                plain
                v-model="incident.vehicle_id"
                size="lg"
                :disabled="readonly"
              >
                <b-form-select-option :value="null"
                  >Select Fleets</b-form-select-option
                >
                <b-form-select-option
                  v-for="(vehicle, i) in vehicles"
                  :key="i"
                  :value="vehicle.value"
                >
                  {{ vehicle.label }}
                </b-form-select-option>
              </b-form-select> -->
              <h6 style="font-weight: 500">{{
                vehicles.find((el) => el.value == incident.vehicle_id).label
              }}</h6>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Date of Loss (DOL)" label-for="dol">
              <!-- <b-form-input
                v-if="isEditMode"
                type="text"
                v-model="incident.date_of_loss"
                :disabled="readonly"
              ></b-form-input> -->
              <h6 style="font-weight: 500">{{
                moment(incident.date_of_loss).format('DD MMM YYYY')
              }}</h6>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Place of Loss (POL)" label-for="pol">
              <!-- <b-form-input
                v-if="isEditMode"
                type="text"
                v-model="incident.place_of_loss"
                :disabled="readonly"
              ></b-form-input> -->
              <h6 style="font-weight: 500">{{ incident.place_of_loss }}</h6>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Type of Coverage" label-for="coverage">
              <!-- <b-form-select
                v-if="isEditMode"
                plain
                v-model="incident.type_coverage_id"
                size="lg"
                :disabled="readonly"
              >
                <b-form-select-option :value="null"
                  >Select of Coverage</b-form-select-option
                >
                <b-form-select-option
                  v-for="(coverage, i) in coverages"
                  :key="i"
                  :value="coverage.value"
                >
                  {{ coverage.label }}
                </b-form-select-option>
              </b-form-select> -->
              <h6 style="font-weight: 500">{{
                incident.type_coverage_id
                  ? coverages.find(
                      (el) => el.value == incident.type_coverage_id
                    ).label
                  : '-'
              }}</h6>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Type of Loss" label-for="loss">
              <!-- <b-form-select
                v-if="isEditMode"
                plain
                v-model="incident.type_loss_id"
                size="lg"
                :disabled="readonly"
              >
                <b-form-select-option :value="null"
                  >Select of Loss</b-form-select-option
                >
                <b-form-select-option
                  v-for="(loss, i) in losses"
                  :key="i"
                  :value="loss.value"
                >
                  {{ loss.label }}
                </b-form-select-option>
              </b-form-select> -->
              <h6 style="font-weight: 500">{{
                incident.type_coverage_id
                  ? losses.find((el) => el.value == incident.type_loss_id).label
                  : '-'
              }}</h6>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <h6 class="card-title text-primary pb-2 border-bottom"
          ><b>CLAIMANT</b></h6
        >
        <b-row>
          <b-col md="6">
            <b-form-group label="Company" label-for="company">
              <!-- <b-form-select
                plain
                v-model="incident.company_claimant_id"
                size="lg"
                :disabled="readonly"
              >
                <b-form-select-option :value="null"
                  >Select Company</b-form-select-option
                >
                <b-form-select-option
                  v-for="(company, i) in companies"
                  :key="i"
                  :value="company.value"
                >
                  {{ company.label }}
                </b-form-select-option>
              </b-form-select> -->
              <h6 style="font-weight: 500">{{
                incident.company_claimant_id
                  ? companies.find(
                      (el) => el.value == incident.company_claimant_id
                    ).label
                  : '-'
              }}</h6>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Name" label-for="name">
              <!-- <b-form-input
                type="text"
                v-model="incident.name"
                :disabled="readonly"
              ></b-form-input> -->
              <h6 style="font-weight: 500">{{ incident.name ?? '-' }}</h6>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Position" label-for="position">
              <!-- <b-form-input
              type="text"
              v-model="incident.position"
              :disabled="readonly"
              ></b-form-input> -->
              <h6 style="font-weight: 500">{{ incident.position ?? '-' }}</h6>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Department of FLeetasdasd"
              label-for="departments"
            >
              <!-- <b-form-select
                plain
                v-model="incident.department_id"
                size="lg"
                :disabled="readonly"
              >
                <b-form-select-option :value="null"
                  >Select Department</b-form-select-option
                >
                <b-form-select-option
                  v-for="(department, i) in departments"
                  :key="i"
                  :value="department.value"
                >
                  {{ department.label }}
                </b-form-select-option>
              </b-form-select> -->
              <h6 style="font-weight: 500">{{
                incident.department_id
                  ? departments.find((el) => el.value == incident.department_id)
                      .label
                  : '-'
              }}</h6>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Detail Chronology">
          <!-- <b-form-textarea
            id="exampleFormControlTextarea1"
            rows="5"
            v-model="incident.details_of_chronology"
            :disabled="readonly"
          ></b-form-textarea> -->
          <h6 style="font-weight: 500">{{
            incident.details_of_chronology ?? '-'
          }}</h6>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Estimate Damage or Loss">
          <!-- <b-form-textarea
          id="exampleFormControlTextarea1"
          rows="5"
          v-model="incident.estimate_damage"
          :disabled="readonly"
          ></b-form-textarea> -->
          <h6 style="font-weight: 500">{{
            incident.estimate_damage ?? '-'
          }}</h6>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="6">
        <b-form-group label="Decribe of Conclusion">
          <!-- <b-form-textarea
          id="exampleFormControlTextarea1"
          rows="3"
          v-model="incident.conclusion"
          ></b-form-textarea> -->
          <h6 style="font-weight: 500">{{ incident.conclusion ?? '-' }}</h6>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="6">
        <b-form-group label="Estimate Cost">
          <!-- <div class="input-group">
            <div class="input-group-append">
              <span class="input-group-text">Rp</span>
            </div>
            <b-form-input
            type="number"
            v-model="incident.cost"
            :disabled="readonly"
            ></b-form-input>
          </div>
          <b-form-text class="text-muted">{{
            formatCurrency(incident.cost)
          }}</b-form-text> -->
          <h6 style="font-weight: 500">{{
            formatCurrency(incident.cost) ?? '-'
          }}</h6>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import moment from '@/node_modules/moment-timezone/index'
import { xray } from '@/src/config/pluginInit'

export default {
  mounted() {
    xray.index()
  },
  props: {
    incident: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    companies: {
      type: Array,
      default: () => [],
    },
    vehicles: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    coverages: {
      type: Array,
      default: () => [],
    },
    losses: {
      type: Array,
      default: () => [],
    },
    isEditMode: Boolean,
  },
  data() {
    return {
      statementTruth: false,
      typeFormInsurance: [
        {
          label: 'Claim',
          color: 'warning',
          value: 'claim',
        },
        {
          label: 'Non Claim',
          color: 'info',
          value: 'non_claim',
        },
      ],
    }
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
  },
}
</script>

<template>
  <svg width="8" height="13" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33333 5.5L0 0.5V10.5L5.33333 5.5Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: 'white',
      type: String,
    },
  },
}
</script>
  
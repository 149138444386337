<template>
  <div class="px-3">
    <b-row class="mb-2">
      <b-col md="12">
        <h6 class="mb-1" style="color: #3a5de7" ><strong>{{ projectName }}</strong></h6>
        <div>
          <b-badge variant="primary" class="p-2 ml-1" v-for="(vehicle, vehicleIndex) in tripData?.vehicle">{{vehicle?.name}}</b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <p class="mb-0 text-black col-3">POL</p>
      <p class="mb-0 text-black col-3">: <strong>{{ tripData?.tripRecord?.portLoading }}</strong></p>
      <p class="mb-0 text-black col-3">Actual COB</p>
      <p class="mb-0 text-black col-3">: <strong>{{ tripData?.tripRecord?.cargoUnloading }} MT</strong></p>
      <p class="mb-0 text-black col-3">POD</p>
      <p class="mb-0 text-black col-3">: <strong>{{ tripData?.tripRecord?.portDischarge }}</strong></p>
      <p class="mb-0 text-black col-3">Cargo Type</p>
      <p class="mb-0 text-black col-3">: <strong>{{ tripData?.tripRecord?.cargoType }}</strong></p>
      <p class="mb-0 text-black col-3">Arrival Time POL</p>
      <p class="mb-0 text-black col-3">: <strong>{{ tripData?.tripRecord?.polArrivalTime }}</strong></p>
      <p class="mb-0 text-black col-3">Completed</p>
      <p class="mb-0 text-black col-3">: <strong>{{ tripData?.tripRecord?.podCompleted }}</strong></p>
      <p class="mb-0 text-black col-3">Arrival Time POD</p>
      <p class="mb-0 text-black col-3">: <strong>{{ tripData?.tripRecord?.podArrivalTime }}</strong></p>
      <p class="mb-0 text-black col-3">Total Steaming Time</p>
      <p class="mb-0 text-black col-3">: <strong>{{ tripData?.matrix?.find(obj => obj.name === 'Total Steaming')?.duration?.toLocaleString() }}</strong></p>
    </b-row>
    <b-row>
      <b-col md="6" class="mb-2">
        <p class="text-black mb-1" style="font-weight: 500">Input Type</p>
        <div class="btn-group">
          <b-button
            variant="outline-primary"
            v-for="(btn, i) in inputTypeOpt"
            :key="i"
            @click="inputTypeSelected = btn"
            :class="{ active: inputTypeSelected == btn }"
            >{{ btn }}</b-button
          >
        </div>
      </b-col>
      <b-col md="6"></b-col>
      <b-col md="12" v-if="inputTypeSelected === 'By Shipment Plan Data'">
        <strong class="text-primary">Project</strong>
        <div class="d-flex overflow-auto" style="max-height: 300px">
          <table class="table">
            <thead>
              <tr>
                <th style="width:5px" class="text-center table-info text-truncate">#</th>
                <th class="text-center table-info text-truncate">SHIPPER</th>                       
                <th class="text-center table-info text-truncate">TYPE</th>                       
                <th class="text-right table-info text-truncate">SHIPMENT PLAN</th>                       
                <th class="text-right table-info text-truncate">VOLUME PLAN</th>                       
                <th class="text-right table-info text-truncate">SHIPMENT REAL</th>                       
                <th class="text-right table-info text-truncate">VOLUME REAL</th>                       
                <th class="text-center table-info">STAGE</th>                       
              </tr>
            </thead>
            <tbody v-if="listRunning.data.length > 0">
              <tr v-for="(state, index) in listRunning.data" :key="index">
                <th class="text-center bg-white text-black">
                  <b-form-checkbox v-model="state.checked" @change="onChecked(index)" />
                </th>
                <td class="text-truncate">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      size="34"
                      variant="warning"
                      :src="require('../../../assets/images/user/11.png')"
                    ></b-avatar>
                    <h6 class="ml-2 text-primary">
                      <strong>
                        {{ state?.customer?.account_name ?? 'N/A' }}
                      </strong>
                    </h6>
                  </div>
                </td>
                <td class="text-center text-truncate text-primary">{{ state?.cargo_type?.name }}</td>
                <td class="text-right text-truncate text-primary">{{ state?.shipment }} SHIPMENT</td>
                <td class="text-right text-truncate text-primary">{{ state?.volume ? state?.volume?.toLocaleString() : '0' }} {{ state?.unit }}</td>
                <td class="text-right text-truncate ">{{ state?.shipment_real }} SHIPMENT</td>
                <td class="text-right text-truncate">{{ state?.volume_real ? state?.volume_real?.toLocaleString() : '0' }} {{ state?.unit }}</td>
                <td class="text-center bg-white text-black">
                  <b-dropdown disabled  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                    <template v-slot:button-content>
                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                        {{state?.project_stage?.title}}
                      </span>
                    </template>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3" v-if="listRunning.data.length > 0">
          <b-pagination
            v-model="page"
            :total-rows="listRunning.meta.totalItem"
            @change="getListRunningShipmentTable"
            :per-page="listRunning.meta.perPage"
            first-number
            align="center"
          ></b-pagination>
        </div>
      </b-col>
      <b-col md="12" v-if="inputTypeSelected === 'By Shipment Plan Data' && form.bdProjectContractParentId">
        <strong class="text-primary">Project Plan</strong>
        <div class="d-flex overflow-auto" style="max-height: 300px">
          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th style="width:5px" rowspan="2" class="text-center table-info text-truncate table-info align-middle">#</th>
                <th class="text-center table-info text-truncate align-middle" rowspan="2">SHIPMENT PLAN DETAIL NUMBER</th>                       
                <th class="text-center table-info text-truncate align-middle" rowspan="2">DESCRIPTION</th>                       
                <th class="text-center table-info text-truncate" colspan="3">FLEET</th>                       
                <th class="text-center table-info text-truncate" colspan="3">CARGO TYPE</th>                       
                <th class="text-center table-info text-truncate align-middle" rowspan="2">DRAUGHT TYPE</th>                       
                <th class="text-center table-info text-truncate" colspan="2">LOCATION</th>                       
                <th class="text-center table-info text-truncate align-middle" rowspan="2">SHIPMENT MONTH</th>                       
                <th class="text-center table-info text-truncate" colspan="2">PLAN DATE</th>                                             
                <th class="text-center table-info text-truncate align-middle" rowspan="2">PLAN QUANTITY</th>                       
                <th class="text-center table-info text-truncate align-middle" rowspan="2">REMARKS</th>                                           
              </tr>
              <tr>
                <th class="text-center table-info text-truncate">OWNERSHIP</th>
                <th class="text-center table-info text-truncate">TYPE</th>
                <th class="text-center table-info text-truncate">FLEETS</th>
                <th class="text-center table-info text-truncate">TYPE</th>
                <th class="text-center table-info text-truncate">ACC. DATE</th>
                <th class="text-center table-info text-truncate">TERMS</th>
                <th class="text-center table-info text-truncate">POL</th>
                <th class="text-center table-info text-truncate">POD</th>
                <th class="text-center table-info text-truncate">ETA POL</th>
                <th class="text-center table-info text-truncate">ETA POD</th>
              </tr>
            </thead>
            <tbody v-if="listProjectPlan.length > 0">
              <tr v-for="(state, index) in listProjectPlan" :key="index">
                <th class="text-center bg-white text-black">
                  <b-form-checkbox v-model="state.checked" @change="onCheckedPlan(index)" />
                </th>
                <td class="text-truncate">{{ state?.shipment_plan_detail_number }}</td>
                <td class="text-truncate">{{ state?.description }}</td>
                <td class="text-truncate">
                  <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ state.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                </td>
                <td class="text-truncate">
                  <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ state.fleet_type?.name }}</b-badge>
                </td>
                <td class="text-truncate">
                  <div class="d-flex">
                    <template v-for="(objDetail, detailIndex) in state.fleets">
                      <b-badge :key="detailIndex" v-if="objDetail.vehicle_id" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">{{ showVehicleName(objDetail.vehicle_id) }}</b-badge>
                    </template>
                  </div>
                </td>
                <td class="text-truncate">{{ state?.cargo_type?.name }}</td>
                <td class="text-truncate">{{ state?.acceptance_date ? moment(state?.acceptance_date).format('DD MMM YYYY HH:mm') : '-' }}</td>
                <td class="text-truncate">
                  <div class="d-flex flex-column">
                    <span>{{ state?.terms?.name }}</span>
                    <span>Remarks: {{ state?.terms_remarks }}</span>
                  </div>
                </td>
                <td class="text-truncate">{{ capitalizeString(state?.draught_type) }}</td>
                <td class="text-truncate">
                  <div class="d-flex w-100">
                    <b-badge 
                      v-for="(obj, polIndex) in state.pol" :key="polIndex" 
                      class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                      variant="none"
                    >
                      {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                    </b-badge>
                  </div>
                </td>
                <td class="text-truncate">
                  <div class="d-flex w-100">
                    <b-badge 
                      v-for="(obj, podIndex) in state.pod" :key="podIndex" 
                      class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                      variant="none"
                    >
                      {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                    </b-badge>
                  </div>
                </td>
                <td class="text-truncate">{{ state?.shipment }}</td>
                <td class="text-truncate">{{ state.eta_pol ? moment(state.eta_pol).format('DD MMM YYYY') : '-'  }}</td>
                <td class="text-truncate">{{ state.eta_pod ? moment(state.eta_pod).format('DD MMM YYYY') : '-'  }}</td>
                <td class="text-truncate text-right">{{ state?.plan_quantity.toLocaleString() }}</td>
                <td class="text-truncate">{{ state?.remarks }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col md="6">
        <b-form-group label="Currency" label-for="currency">
          <v-select 
            v-model="form.currencyId" 
            label="text" 
            :options="optionsCurrency" 
            :reduce="optionsCurrency => optionsCurrency.value" 
            placeholder="Select..." 
            style="background-color:#ffffff;"
            class="mr-2 w-100"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Freight Rate" label-for="fr">
          <b-form-input class="text-right" id="fr" type="number" v-model="form.freightRateValue"></b-form-input>
          <small class="text-right">{{  parseFloat(form.freightRateValue).toLocaleString() }}</small>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-radio-group inline v-model="form.taxable">
          <b-form-radio value="include" name="ppn" key="include">Include PPN</b-form-radio>
          <b-form-radio value="exclude" name="ppn" key="exclude">Exclude PPN</b-form-radio>
        </b-form-radio-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Tax Value" class="d-flex flex-column w-100" label-for="fr" v-if="form.taxable === 'include'">
          <b-form-input class="text-right" id="fr" type="number" v-model="form.taxableValue"></b-form-input>
          <small class="text-right">{{ parseFloat(form.taxableValue).toLocaleString() }}</small>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Total" label-for="total">
          <h5 class="bg-warning px-4 p-2 rounded"><strong>IDR {{ freightRateTotal.toLocaleString() }},-</strong></h5>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <div class="d-flex w-100 mb-4 border" />
      <b-col md="12" class="text-center">
        <b-button variant="none" class="iq-bg-danger text-right" @click="$bvModal.hide('input-freight-rate')">Cancel</b-button>
        <b-button type="button" variant="primary" @click.prevent="onSave()" class="ml-2 text-right" :disabled="saving">
          <span v-if="saving" class="spinner-border spinner-border-sm mr-2" />  <i v-else class="fa fa-save mr-2"></i> Submit
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import moment from 'moment'
import { projectsActions, voyagesActions, fleetsActions, busdevActions, crewingActions, portsActions } from '@src/Utils/helper'

export default {
  props:{
    tripData:{
      type: Object,
      default: {}
    }, 
    projectName:{
      type:String,
      default: ''
    },   
    fleets:{
      type: Array,
      default: []
    }, 
    refreshData:{
      type: Function,
      default: () => {}
    }, 
  },
  data() {
    return {
      inputTypeOpt: ['By Shipment Plan Data', 'By Form Input'],
      inputTypeSelected: 'By Shipment Plan Data',

      page:1,
      saving:false,

      optionsCurrency:[],
      
      listRunning: {
        data:[],
        meta:null
      },

      listProjectPlan: [],

      form: {
        bdProjectContractParentId:null,
        bdProjectContractPlanId:null,
        "currencyId": null,
        "freightRateValue":0,
        "taxable": "exclude",
        "taxableValue": 12,
      }
    }
  },
  mounted() {
    this.getListRunningShipmentTable()
    this.getCurrency()
  },
  computed: {
    freightRateTotal() {
      return (parseFloat(this.form.freightRateValue) * parseFloat(this.tripData?.tripRecord?.cargoUnloading)) + this.tripData?.tripRecord?.deductions?.reduce((acc, obj) => acc + parseFloat(obj.amount), 0)
    },
  },
  methods: {
    ...busdevActions,
    ...voyagesActions,
    ...crewingActions,
    capitalizeString(str) {
      return str
        .split('_') // Split the string into an array
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' '); // Join the words back into a single string with spaces
    },
    showVehicleName(value){
      let vehicle = this.fleets.find(obj => obj.code == value)
      return vehicle?.name
    }, 
    async getCurrency() {
      let res = await this.fetchCurrency()
      if (res.data.status === true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsCurrency.push({
              value: state.id,
              text: state.alias,
            })
          })
        }
      }

      this.form.currencyId = this.optionsCurrency?.find(obj => obj.text === 'IDR')?.value
    }, 
    
    async getListRunningShipmentTable (page = 1){
      this.listRunning = {
        data:[],
        meta:null
      }
      let params = {
        page: page,
        perPage:50,
        periode_start:this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('YYYY-MM-DD') : null ,
        periode_end:this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('YYYY-MM-DD') : null,
      }

      if(!params.keyword) delete params.keyword
      if(!params.cargo_type_id) delete params.cargo_type_id
      if(!params.fleet_ownership) delete params.fleet_ownership
      if(!params.periode_start) delete params.periode_start
      if(!params.periode_end) delete params.periode_end

      let res = await this.fetchRunningShipmentTable(params)

      if (res.data.status === true && res.data.data?.data.length > 0) {
        this.listRunning.data = res.data.data?.data?.map(val => {
          return {
            ...val,
            checked:false
          }
        })
        this.listRunning.meta = res.data.data?.meta
      } 
    }, 
    
    onChecked(index){
      let projectData = this.listRunning.data[index]
      
      this.listRunning.data = this.listRunning.data.map((val, valIndex) => {
        if(valIndex !== index) {
          val.checked = false
        } 

        return val
      })

      if(this.listRunning.data[index].checked){
        this.form.bdProjectContractParentId = projectData.id
  
        this.listProjectPlan = this.listRunning.data[index].project_contract_plan?.map(val => {
          return {
            ...val,
            checked: false
          }
        })
  
        this.form = {
          ...this.form,
          bdProjectContractParentId: projectData.id,
          "currencyId": projectData?.currency_id,
          "freightRateValue": projectData?.freight_rate_est,
          "taxable": projectData?.ppn,
          "taxableValue": 12,
        }
      } else {
        this.form = {
          bdProjectContractParentId: null,
          bdProjectContractPlanId: null,
          "currencyId": null,
          "freightRateValue": 0,
          "taxable": 'exlcude',
          "taxableValue": 12,
        }
      }
    },
    onCheckedPlan(index){
      this.listProjectPlan = this.listProjectPlan.map((val, valIndex) => {
        if(valIndex !== index) {
          val.checked = false
        } 

        return val
      })
      if(this.listProjectPlan[index].checked){
        this.form.bdProjectContractPlanId = this.listProjectPlan[index].id
      } else {
        this.form.bdProjectContractPlanId = null
      }
    },
    async onSave(){

      if(this.inputTypeOpt == 'By Shipment Plan Data' && (!this.form.bdProjectContractPlanId || !this.form.bdProjectContractParentId)) {
        this.$swal('Validation.', 'Check Project & Project plan first, before Save it.', 'warning')
        return
      }
      this.saving = true
  
      let payload = {
        ...this.form
      }
  
      let res = await this.updateTripRecordById({ id: this.tripData?.tripRecord.id, data: payload})
  
      if (res.status == 'success') {
        this.$swal('Update Activity Success!', res.data.message, 'success')
        this.$bvModal.hide('input-freight-rate')
        this.refreshData()
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
  
      this.saving = false
    }
  },

}
</script>

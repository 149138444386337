<template>
  <b-row class="m-4">
    <b-col md="12" v-if="data">
      <b-row class="mt-2">
        <b-col md="12" class="text-right">
          <b-button variant="primary" @click="onExport(inspectionChecklistData.id, 'pdf')" :disabled="exporting">
            <span
              v-if="exporting"
              class="spinner-border spinner-border-sm"
            />
            <i v-else class="fa fa-file mr-2" /> 
            Export PDF 
          </b-button>
        </b-col>

        <b-col md="12" v-if="!loading_fleet_form">
          <div class="border my-2 mx-3 w-100"></div>
          <b-row>
            <b-col md="12">
              <b-form-group label="Title">
                <strong>{{ data?.title }}</strong>
              </b-form-group>
            </b-col>
            <template>
              <b-col md="6" v-for="(state, index) in data?.field" :key="index">
                <b-form-group :label="state?.label">
                  <strong>{{ handleTypeDate(state?.value) }}</strong>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-col>

        <div class="border my-2 mx-3 w-100"></div>

        <b-col md="12" v-if="loading_fleet_form">
          <div class="d-flex align-items-center">
            <i class="spinner-border spinner-border-sm text-primary mr-2"></i>
            <span class="text-primary">Fetching forms, please wait a moment.</span>
          </div>
        </b-col>
        
        <b-col md="12" v-if="!loading_fleet_form">
          <fieldset class="form-group border p-3">
            <legend class="w-auto px-2 text-primary">Question Type Content</legend>
            <div v-if="data?.question_content?.length > 0" class="w-100">
              <table class="table table-striped" v-for="(parentQuestion, parentQuestionIndex) in data?.question_content" :key="parentQuestionIndex">
                <thead>
                  <tr v-if="!parentQuestion.parent_id && parentQuestion?.inspection_checklist_question?.length <= 0">
                    <th colspan="6" class="align-middle text-center">{{ parentQuestion?.name }}</th>
                  </tr>
                  <tr v-else>
                    <th style="max-width:20px">No</th>
                    <th>{{parentQuestion?.name }}</th>
                    <template v-if="Array.isArray(data?.question_type_content)">
                      <th v-for="(questionType, questionTypeIndex) in data?.question_type_content" :key="questionTypeIndex">
                        {{ questionType?.label }}
                      </th>
                    </template>
                    <template v-else>
                      <th v-if="['date', 'single-lines-text-box', 'datetime', 'numerical-value'].includes(data?.question_type?.code)">{{ data?.question_type_content?.label }}</th>
                    </template>
                    <th v-if="data.using_remarks">Remarks</th>
                  </tr>
                </thead>
                <tbody v-if="data?.question_content?.length > 0">
                  <tr v-for="(question, questionIndex) in parentQuestion?.inspection_checklist_question" :key="questionIndex">
                    <td style="max-width:20px">{{ questionIndex + 1 }}</td>
                    <td>{{ question.question }}</td>
                    <template v-if="data?.question_type?.code == 'multiple-choice-multiple-answers-allowed'">
                      <td v-for="(questionType, questionTypeIndex) in data?.question_type_content" :key="questionTypeIndex">
                        <span>{{ question.answer?.[questionTypeIndex]?.value ? '✔️' : '' }}</span>
                      </td>
                    </template>
                    <template v-if="data?.question_type?.code == 'multiple-choice-only-one-answer'">
                      <td v-for="(questionType, questionTypeIndex) in data?.question_type_content" :key="questionTypeIndex">
                        <span>{{ question.answer?.[questionTypeIndex]?.value ? '✔️' : '' }}</span>
                      </td>
                    </template>
                    <template v-if="['single-lines-text-box'].includes(data?.question_type?.code)">
                      <td>
                        <span>{{ handleTypeDate(question?.answer) }}</span>
                      </td>
                    </template>
                    <template v-if="['multiple-lines-text-box'].includes(data?.question_type?.code)">
                      <td v-for="(questionType, questionTypeIndex) in data?.question_type_content" :key="questionTypeIndex">
                        <span>{{ handleTypeDate(question.answer[questionTypeIndex].value) }}</span>
                      </td>
                    </template>
                    <template v-if="['numerical-value'].includes(data?.question_type?.code)">
                      <td>
                        <span>{{ handleTypeDate(question.answer) }}</span>
                      </td>
                    </template>
                    <template v-if="['date'].includes(data?.question_type?.code)">
                      <td>
                        <span>{{ handleTypeDate(question.answer) }}</span>
                      </td>
                    </template>
                    <template v-if="['datetime'].includes(data?.question_type?.code)">
                      <td>
                        <span>{{ handleTypeDate(question.answer) }}</span>
                      </td>
                    </template>
                    <td v-if="data.using_remarks">
                      <span>{{ handleTypeDate(question?.remarks) }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </b-col>

        <div class="border my-2 mx-3 w-100"></div>

        <b-col md="12" class="mt-2" v-if="!loading_fleet_form">
          <b-row>
            <template>
              <b-col md="3" v-for="(state, index) in data?.signature" :key="index">
                <b-form-group :label="state?.label">
                  <strong>{{ handleTypeDate(state?.value) }}</strong>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    
    <b-col lg="12" v-if="!data">
      <span>No Project Forms Data.</span>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'

export default {
  name: 'SlideInspectionChecklist',
  props:{
    isAddInspectionChecklist:{
      type:Boolean,
      default:false
    },
    isPreviewInspectionChecklist:{
      type:Boolean,
      default:false
    }, 
    inspectionChecklistData:{
      type:Object,
      default: () => {}
    }, 
  },
  data() {
    return {
      saving: false, 
      exporting: false, 
      loading_fleet_form: false, 
      
      data: null, 
      form:{
        vehicle_id: null,
        collection_of_forms_id: null,
        title: '',
      },
 
    }
  },
  async mounted () {

  },
  computed: {},
  watch:{
    'inspectionChecklistData.id': {
      handler(n, o) {
        this.modifyData()
      },
      immediate: true,
      deep: true,
    },
  },
  methods:{
    ...projectsActions, 
    ...companiesActions, 
    ...fleetsActions, 
    ...busdevActions, 
    ...crewingActions,
    handleTypeDate(value){
      if(this.validDate(value)){
        return moment(value).format('DD MMMM YYYY')
      } else if (this.validDateTime(value)){
        return moment(value).format('HH:mm A')
      } else {
        return value
      }
    },
    validDate(val, format = 'YYYY-MM-DD'){
      return moment(val, format, true).isValid();
    },  
    validDateTime(val, format = 'YYYY-MM-DDTHH:mm'){
      return moment(val, format, true).isValid();
    },  
    modifyQuestionsValue(oldVal, questionType, content){
      let newVal = oldVal
      if(questionType === 'multiple-lines-text-box'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.remarks = ''
              obj.answer = content.map(() => {
                  return {
                    value: ''
                  }
                })
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'single-lines-text-box'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'numerical-value'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          
          return val
        })
      } else if(questionType === 'date'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'datetime'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'multiple-choice-only-one-answer'){
        newVal = newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = content.map((s,i) => {
                  return {
                    index: i,
                    value: ''
                  }
                })
              obj.remarks = ''
              return obj
            })
          }
          
          return val
        })
      } else if(questionType === 'multiple-choice-multiple-answers-allowed'){ 
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.remarks = ''
              obj.answer = content.map((s,i) => {
                  return {
                    index: i,
                    value: ''
                  }
                })
              return obj
            })
          }
          return val
        })
      }

      return newVal
    },
    async modifyData(){
      console.log(this.isPreviewInspectionChecklist, "this.isPreviewInspectionChecklist")
      if(this.isPreviewInspectionChecklist){
        let tempData = this.inspectionChecklistData

        this.form.title = tempData.title
        this.form.collection_of_forms_id = tempData?.collection_of_forms_id
        this.form.vehicle_id = tempData?.vehicle_id
      
        this.data = tempData
      } else if (this.isAddInspectionChecklist) {
        this.inspectionChecklistData.vehicle_id = this.inspectionChecklistData.vehicle_id?.filter(val => val?.vehicle_id)
        let tempData = this.inspectionChecklistData

        this.loading_fleet_form = true

        const res = await this.fetchCollectionFormById({id: tempData?.form_id})

        if(res.data.status === true){
          let detailForm = res.data.data

          detailForm.question_type_content = detailForm?.inspection_checklist?.length > 0 ? JSON.parse(detailForm?.question_type_content) : detailForm?.collection_of_forms_question
          detailForm.signature = JSON.parse(detailForm?.signature).map(val => {return {...val, value:''}})
          detailForm.field = JSON.parse(detailForm?.field).map(val => {return {...val, value:''}})
          detailForm.collection_of_forms_category = this.modifyQuestionsValue(detailForm?.collection_of_forms_category, detailForm?.question_type?.code, detailForm?.inspection_checklist?.length > 0 ? detailForm?.question_type_content : detailForm?.collection_of_forms_question)

          this.form.title = `${tempData?.vehicle_type?.name} - ${detailForm?.name}`
          this.form.collection_of_forms_id = tempData?.form_id

          this.data = detailForm
        }

        this.loading_fleet_form = false
      } else {
        this.data = null
      }

      console.log(this.data, "this.data")
    },
    resetForm(){
      this.form = {
        vehicle_id: null,
        collection_of_forms_id: null,
        title: '',
      }
    },

    async onExport(id, fileType){
      this.exporting = true
      console.log(id, fileType,"is clickerd?")
      const res = await this.exportInspectionChecklist({id, fileType})

      if(fileType == 'excel'){
        if(res.status === 'success'){
          saveAs(res.data, 'Inspection Checklist.xlsx')
        } else {
          console.log('Something went wrong')
        }
      } else if(fileType == 'pdf'){
        if(res.status === 'success'){
          saveAs(res.data, 'Inspection Checklist.pdf')
        } else {
          console.log('Something went wrong')
        }
        } else {
        console.log('Something went wrong')
      }
      this.exporting = false
    },

    async onSave () {
      this.saving = true
      let tempPayload = {
        ...this.form,
        ...this.data
      }

      let payload = {
        vehicle_id: tempPayload.vehicle_id,
        collection_of_forms_id: tempPayload.collection_of_forms_id,
        field: tempPayload.field.map(val => {
          return {
            label: val?.label,
            type: val?.type,
            required: val?.required,
            value: val?.value,
          }
        }),
        signature: tempPayload.signature.map(val => {
          return {
            label: val?.label,
            type: val?.type,
            required: val?.required,
            value: val?.value,
          }
        }),
        question_content:JSON.stringify(tempPayload.question_content),
        title: this.form.title
      }

      payload.field = JSON.stringify(payload.field)
      payload.signature = JSON.stringify(payload.signature)
      
      let  res = await this.createInspectionChecklist({data: payload})

      if (res?.data?.status === true) {
        this.$swal(`Add Inspection Checklist Success!`, res?.data?.message, 'success')
       
        this.resetForm()
        this.saving = false
      } else {
        this.$swal(
          `Oops!`,
          res?.data,
          'error'
        )
        this.saving = false
      }
      this.saving = false
    }
  }
}
</script>

<template>
  <table v-if="ratings.length" class="table w-100 table-bordered">
    <tr>
      <th bgcolor="#faebd7" width="70%">
        {{ headerTitle }}
      </th>
      <th bgcolor="#faebd7" width="30%" class="text-center"> RATING </th>
    </tr>
    <tbody>
      <template v-for="(item, i) in ratings">
        <tr :key="`detail-rating-` + i">
          <td :rowspan="item.rating.length">
            {{ item.label }}
          </td>
          <td :bgcolor="item.rating[0].color" class="text-center text-white">{{
            item.rating[0].value
          }}</td>
        </tr>
        <template v-for="(rate, ratingIdx) in item.rating">
          <tr v-if="ratingIdx > 0" :key="`rating-${ratingIdx}-${i}`">
            <td :bgcolor="rate.color" class="text-white text-center">
              {{ rate.value }}
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>
<script>
export default {
  props:{
      ratings: {
        type: Array,
        default: () => [],
      },
      headerTitle:''
  }
}
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              ><strong>Root Cause Analysis Form</strong></h4
            >
          </template>
          <template v-slot:body>
            <div>
              <div v-if="$route.params.id && isFetching">
                <p>Fetching Data...</p>
              </div>
              <b-row v-else>
                <b-col md="12" class="mb-4">
                  <b-row>
                    <b-col md="12" class="mb-3 d-flex justify-content-between">
                      <h5 class="font-500">Assessor of the Accident</h5>
                      <b-button
                        v-if="$route.params.id"
                        variant="primary"
                        @click="isEditMode = !isEditMode"
                        >{{ isEditMode ? 'Preview' : 'Edit' }} RCA</b-button
                      >
                    </b-col>
                    <b-col cols="12">
                      <div class="px-3">
                        <b-row style="background: #f2f1f1" class="pt-3">
                          <b-col md="4">
                            <b-form-group
                              label="Name"
                              label-for="Name"
                              label-class="font-500"
                            >
                              <b-form-input
                                v-if="isEditMode"
                                class="w-100"
                                type="text"
                                v-model="assessor_fullname"
                                placeholder="Name..."
                              ></b-form-input>
                              <h6 v-else>{{ assessor_fullname || '-' }}</h6>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group
                              label="Company"
                              label-for="company"
                              label-class="font-500"
                            >
                              <b-form-select
                                v-if="isEditMode"
                                plain
                                v-model="assessor_company_id"
                                :options="companyList"
                                size="lg"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null"
                                    >Select Company</b-form-select-option
                                  >
                                </template>
                              </b-form-select>
                              <h6 v-else>{{
                                assessor_company_id
                                  ? companyList.find(
                                      (el) => el.value == assessor_company_id
                                    )?.text
                                  : '-'
                              }}</h6>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group
                              label="Position"
                              label-for="porisition"
                              label-class="font-500"
                            >
                              <b-form-input
                                v-if="isEditMode"
                                class="w-100"
                                type="text"
                                v-model="assessor_position"
                                placeholder="Position..."
                              ></b-form-input>
                              <h6 v-else>{{ assessor_position || '-' }}</h6>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="12" class="mb-4">
                  <TabPaneRca
                    :activeTab="activeTab"
                    @clickTab="(val) => (activeTab = val)"
                  />
                </b-col>
                <b-col md="12" class="mt-2" v-if="activeTab == 1">
                  <b-row>
                    <b-col
                      cols="3"
                      class="d-flex flex-column align-items-center justify-content-center"
                    >
                      <b-avatar size="110px" variant="warning"></b-avatar>
                      <b-form-group label="Photo" v-if="isEditMode">
                        <b-form-file
                          accept="image"
                          v-model="personal_photo"
                        ></b-form-file>
                      </b-form-group>
                      <div v-else>
                        <h5 class="my-2 font-500">{{
                          personal_fullname ?? '-'
                        }}</h5>
                        <h6 class="text-center">{{
                          assessor_position || '-'
                        }}</h6>
                      </div>
                    </b-col>
                    <b-col cols="9">
                      <div class="pr-3">
                        <b-row style="background: #f2f1f1" class="pt-3">
                          <b-col cols="4">
                            <b-form-group
                              label-for="name"
                              label="Name"
                              label-class="mb-0 font-500"
                            >
                              <b-form-input
                                v-if="isEditMode"
                                class="w-100"
                                type="text"
                                v-model="personal_fullname"
                                placeholder="Name..."
                              ></b-form-input>
                              <h6 v-else>{{ personal_fullname || '-' }}</h6>
                            </b-form-group>
                          </b-col>
                          <b-col cols="4">
                            <b-form-group
                              label="Date of Birth"
                              label-for="date-of-birth"
                              label-class="mb-0 font-500"
                            >
                              <date-picker
                                v-if="isEditMode"
                                class="w-100"
                                type="date"
                                v-model="personal_birthdate"
                                format="YYYY-MM-DD"
                                value-type="format"
                                placeholder="Select date"
                              ></date-picker>
                              <h6 v-else>{{
                                moment(personal_birthdate).format('DD MMM YYYY')
                              }}</h6>
                            </b-form-group>
                          </b-col>
                          <b-col cols="4">
                            <b-form-group
                              label="Gender"
                              label-for="gender"
                              label-class="mb-0 font-500"
                            >
                              <template v-if="isEditMode">
                                <b-form-radio
                                  v-for="(item, index) in state"
                                  inline
                                  v-model="personal_gender"
                                  :name="item.name"
                                  :key="`A-${index}`"
                                  :value="item.value"
                                  >{{ item.label }}</b-form-radio
                                >
                              </template>
                              <h6 v-else>{{
                                state.find((el) => el.value == personal_gender)
                                  ?.label
                              }}</h6>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row class="pt-3">
                          <b-col cols="4">
                            <b-form-group
                              label="Marital Status"
                              label-for="marital-status"
                              label-class="mb-0 font-500"
                            >
                              <b-form-select
                                v-if="isEditMode"
                                plain
                                v-model="personal_marital_status"
                                :options="marital"
                                size="lg"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null"
                                    >Select Marital Status</b-form-select-option
                                  >
                                </template>
                              </b-form-select>
                              <h6 v-else>{{
                                personal_marital_status
                                  ? marital.find(
                                      (el) =>
                                        el.value == personal_marital_status
                                    )?.text
                                  : '-'
                              }}</h6>
                            </b-form-group>
                          </b-col>
                          <b-col cols="4">
                            <b-form-group
                              label="Company"
                              label-for="company"
                              label-class="mb-0 font-500"
                            >
                              <b-form-select
                                v-if="isEditMode"
                                plain
                                v-model="personal_company_id"
                                :options="companyList"
                                size="lg"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null"
                                    >Select Company</b-form-select-option
                                  >
                                </template>
                              </b-form-select>
                              <h6 v-else>{{
                                personal_company_id
                                  ? companyList.find(
                                      (el) => el.value == personal_company_id
                                    )?.text
                                  : '-'
                              }}</h6>
                            </b-form-group>
                          </b-col>
                          <b-col cols="4">
                            <b-form-group
                              label="Length of Work"
                              label-for="lenght-of-work"
                              label-class="mb-0 font-500"
                            >
                              <b-input-group
                                size="md"
                                append="Month"
                                v-if="isEditMode"
                              >
                                <b-form-input
                                  type="number"
                                  v-model="working_experience"
                                ></b-form-input>
                              </b-input-group>
                              <h6 v-else>{{ working_experience || '-' }}</h6>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row style="background: #f2f1f1" class="pt-3">
                          <b-col md="4">
                            <b-form-group
                              label="Position"
                              label-for="position"
                              label-class="mb-0 font-500"
                            >
                              <b-form-input
                                v-if="isEditMode"
                                class="w-100"
                                type="text"
                                v-model="personal_position"
                                placeholder="Position..."
                              ></b-form-input>
                              <h6 v-else>{{ personal_position || '-' }}</h6>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group
                              label="Type"
                              label-for="type"
                              label-class="mb-0 font-500"
                            >
                              <template v-if="isEditMode">
                                <b-form-radio
                                  v-for="(item, index) in types"
                                  inline
                                  v-model="type"
                                  :name="item.name"
                                  :key="`B-${index}`"
                                  :value="item.value"
                                  >{{ item.label }}</b-form-radio
                                >
                              </template>
                              <h6>{{
                                type
                                  ? types.find((el) => el.value == type)?.label
                                  : '-'
                              }}</h6>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group
                              label="Sign Picture"
                              label-for="Sign-Picture"
                              label-class="mb-0 font-500"
                            >
                              <b-form-file
                                v-if="isEditMode"
                                accept="image"
                                v-model="personal_sign"
                              ></b-form-file>
                              <h6 v-else>{{ personal_sign || '-' }}</h6>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="12" class="mt-2" v-if="activeTab == 2">
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <div class="px-3">
                        <b-row>
                          <b-col md="12" class="p-0 mb-0">
                            <b-alert show class="bg-primary mb-0"
                              ><strong class="text-center text-white"
                                >BACKGROUND INFORMATION ABOUT THE ACCIDENT
                                ORPROBLEM</strong
                              ></b-alert
                            >
                          </b-col>
                          <b-col md="12">
                            <b-row
                              class="mb-2 py-3"
                              style="background: #f9fafb"
                            >
                              <b-col md="5">
                                <h6
                                  ><strong
                                    >What part, Product, Process, or Services
                                    Involved</strong
                                  ></h6
                                >
                              </b-col>
                              <b-col md="7">
                                <b-form-textarea
                                v-if="isEditMode"
                                  rows="3"
                                  v-model="explanation_damage"
                                ></b-form-textarea>
                                <h6 v-else>{{  explanation_damage || '-' }}</h6>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col md="12">
                            <b-row class="mt-2 mb-2">
                              <b-col md="5">
                                <h6><strong>Explain the Problem(s)</strong></h6>
                              </b-col>
                              <b-col md="7">
                                <b-form-textarea
                                    v-if="isEditMode"
                                  rows="4"
                                  v-model="explanation_problem"
                                ></b-form-textarea>
                                <h6 v-else>{{  explanation_problem || '-' }}</h6>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col md="12">
                            <b-row
                              class="mt-2 mb-2 py-3"
                              style="background: #f9fafb"
                            >
                              <b-col md="5">
                                <h6 class="font-500">
                                  <strong>
                                    when in the life cycle of the unit is the
                                    problem detected ? (e.g at the source,
                                    intial inspection, final inspection, end
                                    user, etc)-Explain
                                  </strong>
                                </h6>
                              </b-col>
                              <b-col md="7">
                                <b-form-textarea
                                    v-if="isEditMode"
                                  rows="4"
                                  v-model="explanation_detected"
                                ></b-form-textarea>
                                <h6 v-else>{{  explanation_detected || '-' }}</h6>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <!-- Expectations -->
                        <b-row>
                          <b-col md="12" class="p-0 mt-4 mb-0">
                            <b-alert show class="bg-success mb-0"
                              ><strong class="text-center text-white"
                                >EXPECTATIONS !</strong
                              ></b-alert
                            >
                          </b-col>
                          <b-col md="12" class="p-0">
                            <div class="px-3">
                                <b-row class="mb-2 py-3" style="background: #F9FAFB;">
                                  <b-col md="5">
                                    <h6><strong class="text-center">RESULT OF DEFINING THE PROBLEM</strong></h6>
                                  </b-col>
                                  <b-col md="7">
                                    <b-form-textarea
                                    v-if="isEditMode"
                                      rows="4"
                                      v-model="explanation_result"
                                    ></b-form-textarea>
                                    <h6 v-else>{{ explanation_result || '-' }}</h6>
                                  </b-col>
                                </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <!--              collecting information-->
                <b-col md="12" class="mt-2" v-if="activeTab == 3">
                  <b-row>
                    <b-col md="12" class="mt-2">
                        <table class="table table-striped">
                            <thead>
                                <th width="5%" class="text-center">WHY</th>
                                <th width="45%">DEFECT, PROBLEM OR ERROR</th>
                                <th width="50%" >DESCRIPTION OF CAUSE</th>
                                <th v-if="isEditMode">Action</th>
                            </thead>
                            <tbody v-for="(why, index) in whys" :key="'C-' + index">
                                <tr>
                                    <td width="5%" class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td width="45%">
                                        <b-form-input
                                        v-if="isEditMode"
                                        class="w-100"
                                        type="text"
                                        v-model="why.defect"
                                        placeholder="DEFECT, PROBLEM OR ERROR ..."
                                        ></b-form-input>
                                        <h6 v-else class="font-500">{{ why.defect || '-' }}</h6>
                                    </td>
                                    <td width="50%">
                                        <b-form-textarea
                                        v-if="isEditMode"
                                        class="w-100"
                                        v-model="why.cause"
                                        placeholder="CAUSE"
                                        ></b-form-textarea>
                                        <h6 v-else>{{ why.cause || '-' }}</h6>
                                    </td>
                                    <td v-if="isEditMode">
                                        <b-button variant="danger" @click="whys.splice(index, 1)"><i class="fa fa-trash"></i></b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <b-row>
                            <b-col md="12" class="mb-4">
                            <b-button
                                v-if="isEditMode"
                                variant="warning"
                                block
                                class="mt-2 mb-3"
                                @click="addWhys"
                                ><i class="fa fa-plus"></i> Add More Data</b-button>
                            </b-col>
                        </b-row>
                        <table class="table table-striped">
                            <thead>
                                <th width="10%" >WHAT IS?
                                    <br>
                                    WHAT IS NOT?</th>
                                <th width="40%">WHAT IS ?</th>
                                <th width="50%" >WHAT IS NOT ?</th>
                                <th  v-if="isEditMode" >Action</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in whatisLength" :key="'D-' + index">
                                    <td class="pl-5">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <b-form-textarea
                                        v-if="isEditMode"
                                        v-model="whatis[index - 1]"
                                        class="w-100"
                                        placeholder="WHAT IS?"
                                        ></b-form-textarea>
                                        <h6 class="font-500" v-else>{{ whatis[index - 1] || '-' }}</h6>
                                    </td>
                                    <td>
                                        <b-form-textarea
                                        v-if="isEditMode"
                                        v-model="whatisnot[index - 1]"
                                        class="w-100"
                                        placeholder="WHAT IS NOT?"
                                        ></b-form-textarea>
                                        <h6 v-else>{{ whatisnot[index - 1] || '-' }}</h6>
                                    </td>
                                    <td v-if="isEditMode">
                                        <b-button variant="danger" @click="whatisLength -= 1"
                                            ><i class="fa fa-trash"></i
                                        ></b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <b-row v-if="isEditMode">
                            <b-col md="12">
                            <b-button
                                variant="warning"
                                block
                                class="mt-2 mb-3"
                                @click="addWhatis"
                                ><i class="fa fa-plus"></i> Add More Data</b-button
                            >
                            </b-col>
                        </b-row>
                    </b-col>
                  </b-row>
                </b-col>

                <!--              identify the root cause-->
                <b-col md="12" class="mt-2" v-if="activeTab == 4">
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <hr />
                      <b-row>
                        <b-col md="4">
                          <b-form-group label="Vessel">
                            <b-form-input
                              type="text"
                              :value="vessel.name"
                              placeholder="Select Vessel"
                              readonly
                            />
                          </b-form-group>
                          <b-form-group label="FMEA CASE :">
                            <b-form-input
                              class="w-100"
                              type="text"
                              v-model="fmea_case"
                              placeholder="FMEA CASE"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group label="FMEA Date (Orig.) :">
                            <date-picker
                              type="datetime"
                              v-model="fmea_date"
                              format="YYYY-MM-DD HH:mm:ss"
                              value-type="format"
                              placeholder="Select date and time"
                            ></date-picker>
                          </b-form-group>
                          <b-form-group label="Date of Loss">
                            <b-form-input
                              type="datetime"
                              :value="date_of_loss"
                              placeholder="Select date and time"
                              readonly
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row
                        v-for="(item, index) in identificationLength"
                        :key="'E-' + index"
                        class="mx-0"
                      >
                        <b-col md="12">
                          <b-row v-show="identifications">
                            <template v-for="(form, i) in identifications">
                              <b-col :key="i" :md="'6'" :style="{background:[0,1,4,5,8,9].includes(i) ? '#F2F1F1' :'#F9FAFB'}" class="py-3">
                                <div v-if="form.description">
                                  <div class="d-flex mb-2 align-items-center">
                                    <b-badge variant="success" class="mr-2 p-1 px-2" style="height: max-content;" >{{ form.title }}</b-badge>
                                    <h6 class="font-500">{{ form.description }}</h6>
                                  </div>
                                  <b-form-textarea v-model="form.answers[index -1]" style="min-height:auto ;" v-if="isEditMode"></b-form-textarea>
                                  <h6 v-else>{{ form.answers[index - 1] || '-' }}</h6>
                                </div>
                                <div v-else>
                                  <div class="d-flex justify-content-between mb-2">
                                    <h6 style="font-weight: 700;">{{ form.title }} {{ form.title !== 'RPN' ? '(1 to 10)' : '(Risk Priority Number)' }}</h6>
                                    <b-button v-if="form.title !== 'RPN'" size="sm" variant="primary" class="py-1 font-size-14" style="height: max-content" v-b-modal="`modal-detail-rating-${i}-${index}`" >Detail Rating</b-button>
                                  </div>
                                  <b-form-input
                                  v-if="isEditMode"
                                  @input="setRPN(index - 1)"
                                  v-model="form.answers[index - 1]"
                                  type="number"
                                  :readonly="form.title === 'RPN'"
                                  :min="form.title !== 'RPN' ? 1 : ''"
                                  :max="form.title !== 'RPN' ? 10 : ''"
                                  >
                                </b-form-input>
                                <div v-else>
                                  <div class="d-flex" v-if="form.title !== 'RPN'">
                                    <b-badge class="p-2"  :style="{'minWidth': '38px', height:'max-content' ,  background:getRatingValue(form.answers[index - 1],form.title).color}">
                                      <h5 class="text-white">
                                          <b>
                                            {{form.answers[index - 1]}}
                                          </b>
                                        </h5>
                                      </b-badge>
                                      <div class="ml-2">
                                        <h6><b>Rating Mean:</b></h6>
                                        <h6>{{getRatingValue(form.answers[index -1],form.title).label}}</h6>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <b-badge class="p-2"  variant="info"  :style="{'minWidth': '38px'}">
                                      <h5 class="text-white">
                                          <b>
                                            {{form.answers[index -1]}}
                                          </b>
                                        </h5>
                                      </b-badge>
                                    </div>
                                  </div>
                                  <b-modal :id="`modal-detail-rating-${i}-${index}`" :title="`Detail Rating ${form.title}`" ok-only ok-title="Close">
                                    <template v-if="form.title === 'Occurrence'">
                                      <DetailRatingTemplate :ratings="detailRating.occurrence" :headerTitle="detailRating.occurrenceHeader" />
                                    </template>
                                    <template v-if="form.title === 'Detection'">
                                      <DetailRatingTemplate :ratings="detailRating.detection" :headerTitle="detailRating.detectionHeader" />
                                    </template>
                                    <template v-if="form.title === 'Occurrence, Severity'">
                                      <DetailRatingTemplate :ratings="detailRating.saverity" :headerTitle="detailRating.saverityHeader" />
                                    </template>
                                  </b-modal>
                                </div>
                                <b-row >
                                  <b-col md="12">
                                  </b-col>
                                  <b-col md="12">
                                    <div class="p-4">
                                    </div>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                        <b-col md="12" class="text-right text-primary my-3">
                          <b-button
                            variant="danger"
                            class="px-5"
                            @click="identificationLength -= 1"
                            ><i class="fa fa-trash"></i>Delete</b-button>
                        </b-col>
                        <b-col cols="12">
                          <hr />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-button
                            variant="warning"
                            block
                            class="mt-2 mb-3"
                            @click="addIdentifications"
                            ><i class="fa fa-plus"></i> Add More Data</b-button
                          >
                        </b-col>
                      </b-row>
                      <hr />
                    </b-col>
                  </b-row>
                </b-col>

                <!--              implement corrective actions-->
                <b-col md="12" class="mt-2" v-if="activeTab == 5">
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <div v-for="(implementation, index) in implementations" :key="'G-' + index">
                        <div class="border-bottom pb-2 mb-3 d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <div class="rounded-sm bg-primary" style="width: max-content; padding: 10px 12px !important">
                              <h5 class="text-white">#{{ index + 1 }}</h5>
                            </div>
                            <h6 class="font-500 ml-2">Implement Corrective</h6>
                          </div>
                          <b-button :class="{active:implementation.visible}" @click="implementation.visible = !implementation.visible" variant="outline-secondary" class="rounded" style="width: max-content; height: max-content;">
                            <i class="fa fa-chevron-right m-0" :style="{rotate: implementation.visible ? '90deg':'0deg', transtiion:'all 300ms' }"></i>
                          </b-button>
                        </div>
                        <b-collapse :id="`implement-cerrective-${index}`" v-model="implementation.visible">
                          <b-row class="px-3">
                            <b-col md="6" style="background: #F9FAFB;" class="pt-2">
                              <b-form-group label="Tool" label-for="tool" label-class="font-500">
                                <template v-if="isEditMode">
                                  <b-form-select
                                    v-if="index <= 3"
                                    plain
                                    v-model="implementation.tool"
                                    @change="handleImplementToolChange(index)"
                                    size="sm"
                                    class="w-100"
                                  >
                                    <b-form-select-option value="Five Whys"
                                      >Five Whys</b-form-select-option
                                    >
                                    <b-form-select-option
                                      value="Comparative Analysis"
                                      >Comparative Analysis</b-form-select-option
                                    >
                                    <b-form-select-option
                                      value="Failure Mode and Effect Analysis (FMEA)"
                                      >Failure Mode and Effect Analysis
                                      (FMEA)</b-form-select-option
                                    >
                                    <b-form-select-option value="DOE"
                                      >DOE</b-form-select-option
                                    >
                                  </b-form-select>
                                  <b-form-input
                                    v-else
                                    class="w-100"
                                    type="text"
                                    v-model="implementation.tool"
                                    placeholder="Tool"
                                  ></b-form-input>
                                </template>
                                <template v-else>
                                  <h6>{{ implementation.tool ?? '-' }}</h6>
                                </template>
                              </b-form-group>
                            </b-col>
                            <b-col md="6" style="background: #F9FAFB;" class="pt-2">
                              <b-form-group label="Question" label-for="question" label-class="font-500">
                                <b-form-textarea
                                  v-if="isEditMode"
                                  class="w-100"
                                  row="2"
                                  v-model="implementation.question"
                                  placeholder="Question"
                                ></b-form-textarea>
                                <h6 v-else>{{ implementation.question || '-' }}</h6>
                              </b-form-group>
                            </b-col>
                            <b-col md="6" class="pt-2">
                              <b-form-group label="Findings" label-for="findings" label-class="font-500">
                                <b-form-textarea
                                  v-if="isEditMode"
                                  class="w-100"
                                  row="2"
                                  v-model="implementation.finding"
                                  placeholder="Findings"
                                ></b-form-textarea>
                                <h6 v-else>{{ implementation.finding || '-'}}</h6>
                              </b-form-group>
                            </b-col>
                            <b-col md="6" class="pt-2">
                              <b-form-group label="Corrective Actions" label-for="corrective-actions" label-class="font-500">
                                <b-form-textarea
                                  v-if="isEditMode"
                                  class="w-100"
                                  row="2"
                                  v-model="implementation.corrective_action"
                                  placeholder="Corrective Actions"
                                ></b-form-textarea>
                                <h6 v-else>{{ implementation.corrective_action || '-' }}</h6>
                              </b-form-group>
                            </b-col>
                            <b-col md="12" class="text-right text-primary mb-4">
                              <b-button
                                v-if="isEditMode"
                                variant="danger"
                                class="w-100"
                                @click="implementations.splice(index, 1)"
                                ><i class="fa fa-trash"></i> Delete Implement Corrective</b-button>
                            </b-col>
                          </b-row>
                        </b-collapse>
                      </div>
                      <b-row>
                        <b-col md="12">
                          <b-button
                            v-if="isEditMode"
                            variant="warning"
                            block
                            class="mt-2 mb-3"
                            @click="addImplementations"
                            ><i class="fa fa-plus"></i> Add More Data</b-button
                          >
                        </b-col>
                      </b-row>
                      <hr />
                    </b-col>
                  </b-row>
                </b-col>

                <!--              fish bone-->
                <b-col md="12" class="mt-2" v-if="activeTab == 6">
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <hr />
                      <b-row class="bg-light mx-0 pt-2">
                        <b-col md="4">
                          <b-form-group label="Diagram Name :" label-for="diagram-name" label-class="font-500">
                            <b-form-input
                              v-if="isEditMode"
                              class="w-100"
                              type="text"
                              v-model="diagram_title"
                              placeholder="Diagram Name"
                            ></b-form-input>
                            <h6 v-else>{{ diagram_title || '-' }}</h6>
                          </b-form-group>
                        </b-col>
                        <b-col md="4" v-if="isEditMode">
                          <b-form-group label="Photo" label-for="photo" label-class="font-500">
                            <b-form-file
                              accept="image"
                              v-model="diagram_photo"
                            ></b-form-file>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <hr />
                      <div v-for="(diagram, index) in diagrams" :key="'J' + index" >
                        <div class="border-bottom pb-2 mb-3 d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <div class="rounded-sm bg-primary" style="width: max-content; padding: 10px 12px !important">
                              <h5 class="text-white">#{{ index + 1 }}</h5>
                            </div>
                            <h6 class="font-500 ml-2">{{ diagram.header || '-' }}</h6>
                          </div>
                          <b-button :class="{active:diagram.visible}" @click="diagram.visible = !diagram.visible" variant="outline-secondary" class="rounded" style="width: max-content; height: max-content;">
                            <i class="fa fa-chevron-right m-0" :style="{rotate: diagram.visible ? '90deg':'0deg', transtiion:'all 300ms' }"></i>
                          </b-button>
                        </div>
                        <b-collapse :id="`fish-bone-${index}`" v-model="diagram.visible">
                          <b-row>
                            <b-col md="12">
                              <b-row class="mt-2 mb-2">
                                <b-col md="12" class="pl-5" v-if="isEditMode">
                                  <div>
                                    <b-form-group label="Header Name :" label-for="header">
                                      <b-form-input
                                        class="w-100"
                                        type="text"
                                        v-model="diagram.header"
                                        placeholder="Header Name"
                                      ></b-form-input>
                                    </b-form-group>
                                  </div>
                                </b-col>
                                <b-col >
                                  <div class="px-5">
                                    <b-row cols="12" v-for="(item, idx) in diagram.bodyLength" :key="'H' + idx" class="mb-3 border-bottom pb-2">
                                      <b-col cols="12" class="d-flex align-items-center">
                                        <b-alert show class="p-2 m-0 mr-2 bg-success" style="width: 43px;">
                                          <h5 class="text-white font-500">#{{ idx }}</h5>
                                        </b-alert>
                                        <div class="w-100">
                                          <b-form-textarea
                                            v-if="isEditMode"
                                            v-model="diagram.body[idx - 1]"
                                            rows="2"
                                          ></b-form-textarea>
                                          <h6 v-else>{{ diagram.body[idx-1] || '-' }}</h6>
                                        </div>
                                        <b-button v-if="isEditMode" class="ml-2" variant="danger" @click="diagram.bodyLength -= 1"><i class="fa fa-trash m-0"></i></b-button>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-col>
                                <b-col md="12" class="text-right" v-if="isEditMode">
                                  <b-button
                                  variant="danger"
                                  class="mr-2"
                                  @click="diagrams.splice(index, 1)"
                                  ><i class="fa fa-trash"></i> Delete </b-button>
                                  <b-button
                                    variant="warning"
                                    @click="addDiagramBody(index)"
                                    ><i class="fa fa-plus"></i> Add More
                                    Item</b-button
                                  >
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-collapse>
                      </div>
                      <b-row v-if="isEditMode">
                        <b-col md="12">
                          <b-button
                            variant="warning"
                            block
                            class="mt-2 mb-3"
                            @click="addDiagrams"
                            ><i class="fa fa-plus"></i> Add More Data</b-button
                          >
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="my-3">
                  <b-button
                    v-if="isEditMode"
                    variant="success"
                    block
                    class="mt-2 mb-3"
                    @click="submitForm"
                    ><i class="fa fa-check"></i> Submit Form</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '@/src/config/pluginInit'
import { miActions, companiesActions, vehiclesActions } from '@src/Utils/helper'
import TabPaneRca from '../component/TabPaneRca.vue'
import moment from '@/node_modules/moment/moment'
import DetailRatingTemplate from '../component/DetailRatingTemplate.vue';

export default {
  name: 'RootCauseAnalysisForm',
  components: { TabPaneRca, DetailRatingTemplate },
  async mounted() {
    xray.index()
    if (!this.$route.query.formId) {
      this.$router.push({
        name: 'mi.listInitial',
      })
    }
    if (this.$route.params.id) {
      this.isFetching = true
      if (this.$route.query.preview) {
        this.isEditMode = false
      }else{
        this.isEditMode = true
      }
    }else{
      this.isEditMode = true
    }
    if (this.$route.query.formId && this.$route.params.id) {
      if (await this.getDetail(this.$route.params.id)) {
        if (this.form_id.toString() !== this.$route.query.formId.toString()) {
          this.$router.push({
            name: 'mi.listInitial',
          })
        } else {
          this.isFetching = false
        }
      } else {
        this.$router.push({
          name: 'mi.listInitial',
        })
      }
    }
    await this.fetchIncident()

    if (!this.$route.params.id) {
      if (!this.identifications) {
        this.identifications = await this.getFormModel()
      }
      if (!this.companyList.length) {
        this.companyList = await this.getCompanyList()
      }
      if (!this.whys.length) {
        this.whys = await this.getQuestions()
      }
    } else {
      let formModel = await this.getFormModel()
      this.identifications = this.identifications.map(val => {
        let model = formModel.find(obj => obj.id === val.id)
        return {
          ...val,
          title: model.title,
          description: model.description
        }
      })
      console.log(this.identifications)
      // console.log('asdasd',this.identifications);
      
    }
  },
  data() {
    return {
      activeTab: 1,
      isEditMode: false,
      permission_user: JSON.parse(localStorage.getItem('profile')).role.roles,
      id: null,
      form_id: null,
      assessor_fullname: '',
      assessor_company_id: null,
      assessor_position: '',
      personal_fullname: '',
      personal_gender: '',
      personal_birthplace: '',
      personal_birthdate: null,
      personal_marital_status: null,
      personal_company_id: null,
      personal_position: '',
      personal_photo: null,
      personal_sign: null,
      working_experience: null,
      vessel: {
        id: '',
        name: '',
      },
      fmea_case: '',
      fmea_date: null,
      date_of_loss: null,
      explanation_damage: '',
      explanation_problem: '',
      explanation_detected: '',
      explanation_result: '',
      identificationLength: 1,
      identifications: null,
      type: '',
      whys: [],
      implementations: [
        {
          tool: '',
          question: '',
          finding: '',
          corrective_action: '',
          visible:false,
        },
        {
          tool: '',
          question: '',
          finding: '',
          corrective_action: '',
          visible:false,
        },
        {
          tool: '',
          question: '',
          finding: '',
          corrective_action: '',
          visible:false,
        },
        {
          tool: '',
          question: '',
          finding: '',
          corrective_action: '',
          visible:false,
        },
      ],
      whatisLength: 1,
      whatis: [],
      whatisnot: [],
      diagram_photo: null,
      diagram_title: '',
      diagrams: [
        {
          header: null,
          body: [],
          bodyLength: 2,
          visible:false
        },
        {
          header: null,
          body: [],
          bodyLength: 2,
          visible:false
        },
      ],
      form: {
        status: false,
      },
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger',
      },
      state: [
        {
          label: 'Male',
          value: 'M',
          disabled: false,
        },
        {
          label: 'Female',
          value: 'F',
          disabled: false,
        },
      ],
      types: [
        {
          label: 'Direct',
          value: 'DIRECT',
          disabled: false,
        },
        {
          label: 'Indirect',
          value: 'INDIRECT',
          disabled: false,
        },
      ],
      marital: [
        { value: 'SINGLE', text: 'Single' },
        { value: 'MARRIED', text: 'Married' },
        { value: 'DIVORCE', text: 'Divorce' },
      ],
      companyList: [],
      isFetching: false,
      detailRating:{
        occurrenceHeader:'DESCRIPTION OF OCCURRENCE OF CAUSAL FACTORS',
        detectionHeader:'DETECTION RATING DESCRIPTION',
        saverityHeader:'SEVERITY/INFLUENCE RATING DESCRIPTION',
        occurrence:[
          {
            label:'Failure is almost inevitable	',
            rating:[
              {
                value:10,
                color:'#b22222'
              }
            ]
          },
          {
            label:'This Process or similar processes have often',
            rating:[
              {
                value:9,
                color:'#b22222'
              },
              {
                value:8,
                color:'#dc143c'
              }
            ]
          },
          {
            label:'This Process has occasional failures but will impact damage to Vessel, in major or Minor',
            rating:[
              {
                value:7,
                color:'#dc143c'
              },
              {
                value:6,
                color:'#d2691e'
              }
            ]
          },
          {
            label:'slated failures associated with similar processes',
            rating:[
              {
                value:5,
                color:'#d2691e'
              },
              {
                value:4,
                color:'#d2691e'
              },
              {
                value:3,
                color:'#ffd700'
              }
            ]
          },
          {
            label:'Only Isolated Failures associated with this process or almost indentical processes',
            rating:[
              {
                value:2,
                color:'#ffd700'
              }
            ]
          },
          {
            label:'Failure unlike, No Failures ever associated with this process or almost identical processes',
            rating:[
              {
                value:1,
                color:'#7fff00'
              }
            ]
          },
        ],
        detection:[
         {
          label:'Controls will not or can not detect the existence of a failure, no known controls available to detect failure mode',
          rating:[
            {
              value:10,
              color:'#b22222'
            }
          ]
         }, 
         {
          label:'controls probably will not detect the exitence of failure',
          rating:[
            {
              value:9,
              color:'#b22222'
            }
          ]
         }, 
         {
          label:'Controls have a poor change of detecting the existence of a failure mode',
          rating:[
            {
              value:8,
              color:'#dc143c'
            },
            {
              value:7,
              color:'#dc143c'
            }
          ]
         }, 
         {
          label:'Controls may detect the existence of a failure mode',
          rating:[
            {
              value:6,
              color:'#d2691e'
            },
            {
              value:5,
              color:'#d2691e'
            },
          ]
         }, 
         {
          label:'Control have a good chance of detecting failure mode, process Minor - relatively few failures and minor impact to automatically detects failure mode',
          rating:[
            {
              value:4,
              color:'#d2691e'
            },
            {
              value:3,
              color:'ffd700'
            }
          ]
         }, 
         {
          label:'current control almost centrain to detect the failure mode, reliable detection control are known with similar processes. Process automatically prevents further processing',
          rating:[
            {
              value:2,
              color:'#7fff00'
            },
            {
              value:1,
              color:'#7fff00'
            }
          ]
         }, 
        ],
        saverity:[
         {
          label:'Very High-Hazardous, Vessel become inactive or cannot be operated and can cause injury or death for the crew, passengers, or other parties ( the effect directly to loss/damage of primary functions of the Vessel)',
          rating:[
            {
              value:10,
              color:'#b22222'
            },
            {
              value:9,
              color:'#b22222'
            },
          ]
         }, 
         {
          label:'High - Repeated failures made the vessel have the same problem -negligence, incompetence, misconduct, error in judgment by a person, and latent defect of the vessel',
          rating:[
            {
              value:8,
              color:'#dc143c'
            },
            {
              value:7,
              color:'#dc143c'
            },
          ]
         },
         {
          label:'Moderate -Some occasional Failures,',
          rating:[
            {
              value:6,
              color:'#d2691e'
            },
            {
              value:5,
              color:'#d2691e'
            },
            {
              value:4,
              color:'#d2691e'
            },
          ]
         }, 
         {
          label:'Minor - relatively few failures and minor impact to automatically detects failure mode Vessel or other parties',
          rating:[
            {
              value:3,
              color:'#ffd700'
            },
          ]
         }, 
         {
          label:'Very Minor',
          rating:[
            {
              value:2,
              color:'#ffd700'
            },
          ]
         }, 
         {
          label:'No Effect -No Danger',
          rating:[
            {
              value:1,
              color:'#7fff00'
            },
          ]
         }, 
        ]
      }
    }
  },
  computed: {
    userPermission() {
      if (
        [
          'SUPER ADMIN',
          'MARINE INSURANCE',
          'MARINE INSURANCE ADMIN',
          'MARINE INSURANCE MANAGER',
        ].includes(this.permission_user)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...miActions,
    ...companiesActions,
    ...vehiclesActions,
    setRPN(index) {
      const occurrence = this.identifications.find(
        (val) => val.title === 'Occurrence'
      ).answers
      const saverity = this.identifications.find(
        (val) => val.title === 'Occurrence, Severity'
      ).answers
      const detection = this.identifications.find(
        (val) => val.title === 'Detection'
      ).answers

      if (
        !this.identifications.find((val) => val.title === 'RPN').answers[index]
      ) {
        this.identifications.find((val) => val.title === 'RPN').answers[
          index
        ] = 1
      }

      if (!occurrence[index] && !saverity[index] && !detection[index]) {
        this.identifications.find((val) => val.title === 'RPN').answers[index] =
          undefined
      }

      if (occurrence[index] && saverity[index] && detection[index]) {
        this.identifications.find((val) => val.title === 'RPN').answers[index] =
          occurrence[index] * saverity[index] * detection[index]
      } else if (saverity[index] && detection[index]) {
        this.identifications.find((val) => val.title === 'RPN').answers[index] =
          saverity[index] * detection[index]
      } else if (detection[index] && occurrence[index]) {
        this.identifications.find((val) => val.title === 'RPN').answers[index] =
          detection[index] * occurrence[index]
      } else if (occurrence[index] && saverity[index]) {
        this.identifications.find((val) => val.title === 'RPN').answers[index] =
          occurrence[index] * saverity[index]
      } else if (occurrence[index]) {
        this.identifications.find((val) => val.title === 'RPN').answers[index] =
          occurrence[index]
      } else if (saverity[index]) {
        this.identifications.find((val) => val.title === 'RPN').answers[index] =
          saverity[index]
      } else if (detection[index]) {
        this.identifications.find((val) => val.title === 'RPN').answers[index] =
          detection[index]
      }
    },
    async fetchIncident() {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'type_loss,vehicle',
      })

      if (status === 'success') {
        this.date_of_loss = data.date_of_loss
        this.vessel = {
          id: data.vehicle.id,
          name: data.vehicle.name,
        }
      } else {
        this.$router.push({
          name: 'mi.listInitial',
        })
      }
    },
    async getDetail(id) {
      const { status, data } = await this.getRCA(id)
      if (status === 'success') {
        for (const key in data) {
          if (key === 'identifications') {
            data[key].forEach((d) => {
              if (this.identificationLength < d.answers.length) {
                this.identificationLength = d.answers.length
              }
            })
            this[key] = data[key].map((d) => ({
              ...d,
              answers: data[key].find((answer) => answer.id === d.id).answers,
            }))
          } else if (key === 'whys') {
            data[key].forEach((item) => {
              const dataIndex = this.whys.findIndex(
                (d) => d.defect === item.defect
              )
              if (dataIndex === -1) {
                this.whys.push({ defect: item.defect, cause: item.cause })
              }
            })
            this.whys = this.whys.map((why) => {
              const dataIndex = data[key].findIndex(
                (item) => item.defect === why.defect
              )
              if (dataIndex === -1) {
                return { ...why }
              }
              return { ...why, cause: data[key][dataIndex].cause }
            })
          } else if (key === 'whatis') {
            this.whatisLength = Math.max(
              data.whatis.length,
              data.whatisnot.length
            )
            this[key] = data[key]
          } else if (key === 'diagrams') {
            this[key] = data[key].map((d) => ({
              ...d,
              bodyLength: d.body.length,
            }))
          } else {
            this[key] = data[key]
          }
        }
        return true
      }
      return false
    },
    async getFormModel() {
      const { data } = await this.getRCAFormModel()
      return data.map((val) => ({
        ...val,
        title: ['saverity', 'severity'].includes(val.title.toLowerCase())
          ? 'Occurrence, Severity'
          : val.title,
        answers: [],
      }))
    },
    async getCompanyList() {
      const { data } = await this.getCompanies()
      return data.map((val) => ({ value: val.id, text: val.company }))
    },
    async getQuestions() {
      const { data } = await this.getMasterQuestions()
      return data.map((val) => ({
        defect: val.name,
        readonly: true,
        cause: null,
      }))
    },
    async submitForm() {
      const diagram_photo = this.createBase64Image(this.diagram_photo)
      const personal_photo = this.createBase64Image(this.personal_photo)
      const personal_sign = this.createBase64Image(this.personal_sign)
      const payload = {
        form_id: this.$route.query.formId,
        assessor_fullname: this.assessor_fullname,
        assessor_company_id: this.assessor_company_id,
        assessor_position: this.assessor_position,
        personal_fullname: this.personal_fullname,
        personal_gender: this.personal_gender,
        personal_birthplace: this.personal_birthplace,
        personal_birthdate: this.personal_birthdate,
        personal_marital_status: this.personal_marital_status,
        personal_company_id: this.personal_company_id,
        personal_position: this.personal_position,
        personal_photo,
        personal_sign,
        working_experience: this.working_experience,
        vessel_id: this.vessel.id,
        fmea_case: this.fmea_case,
        fmea_date: this.fmea_date,
        date_of_loss: this.date_of_loss,
        explanation_damage: this.explanation_damage,
        explanation_problem: this.explanation_problem,
        explanation_detected: this.explanation_detected,
        explanation_result: this.explanation_result,
        whys: this.whys
          .filter((why) => why.cause !== null)
          .map((why) => ({ defect: why.defect, cause: why.cause })),
        whatis: this.whatis.filter((val) => val !== null),
        whatisnot: this.whatisnot.filter((val) => val !== null),
        identifications: this.identifications.map((identification) => ({
          id: identification.id,
          answers: identification.answers.filter((answer) => answer !== null).map((answer) => answer?.toString()),
        })),
        type: this.type,
        implementations: this.implementations,
        diagram_title: this.diagram_title,
        diagram_photo,
        diagrams: this.diagrams
          .filter(
            (diagram) => diagram.header !== null && diagram.body.length !== 0
          )
          .map((diagram) => ({
            header: diagram.header,
            body: diagram.body.filter((val) => val !== null),
          })),
      }
      let status
      let data
      if (this.$route.params.id) {
        payload.id = this.id
        const res = await this.editRCA(payload)
        status = res.status
        data = res.data.data
      } else {
        const res = await this.createRCA(payload)
        status = res.status
        data = res.data.data
      }
      if (status === 'success') {
        this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')
        setTimeout(() => {
          this.$router.push({ name: 'mi.listInitial' })
        }, 1500)
      } else {
        let err = []
        for (const key in data) {
          err = [...err, ...data[key]]
        }
        this.$swal(`Oops!`, `${err.join(',')}`, 'error')
      }
    },
    addIdentifications() {
      this.identificationLength += 1
    },
    addWhatis() {
      this.whatisLength += 1
    },
    addWhys() {
      this.whys.push({
        defect: '',
        readonly: false,
        cause: null,
      })
    },
    addImplementations() {
      this.implementations.push({
        tool: '',
        question: '',
        finding: '',
        corrective_action: '',
        visible:false,
      })
    },
    addDiagrams() {
      this.diagrams.push({
        header: null,
        body: [],
        bodyLength: 2,
        visible:false
      })
    },
    addDiagramBody(index) {
      this.diagrams[index].bodyLength += 1
    },
    createBase64Image(file) {
      if (!file) return null
      const reader = new FileReader()

      reader.onload = (e) => e.target.result
      reader.readAsBinaryString(file)
    },
    handleImplementToolChange(index) {
      if (this.implementations[index]) {
        switch (this.implementations[index].tool) {
          case 'Five Whys':
            this.implementations[index].question =
              'Has repeatedly asking "why" drilled down into the process to uncover the root cause?'
            break
          case 'Comparative Analysis':
            this.implementations[index].question =
              'Has what is what not, questioning pointed to the root cause?'
            break
          case 'Failure Mode and Effect Analysis (FMEA)':
            this.implementations[index].question =
              'Were the causes of high risk potential failures modes worth exploring? '
            break
          case 'DOE':
            this.implementations[index].question =
              'Did gaining a deeper understanding of other Problems in the main problem and How to Prevent Problems?'
            break
          default:
            this.implementations[index].question =
              'Has repeatedly asking "why" drilled down into the process to uncover the root cause? '
            break
        }
      }
    },
    getRatingValue(rate=1, type){
      const newRating = []
      const rawData  = this.detailRating.saverity
      if (type == 'Occurrence') rawData == this.detailRating.occurrence
      if(type == 'Detection')rawData == this.detailRating.detection

      rawData.forEach((el)=>{
        el.rating.forEach((rate)=> {
          newRating.push({
            label:el.label,
            value:rate.value,
            color:rate.color
          })
        })
      })

      if (rate > 10) {
        return  newRating.find((el)=> el.value == 10)
      }else{
        return  newRating.find((el)=> el.value == rate)
      }

      
    }
  },
}
</script>

<style>
.v-select * {
  color: #a09e9e;
}

.font-500 {
  font-weight: 500;
}

/* .icon-rotate-animate{
  animation: all;
  animation-duration: 500ms;
} */

</style>

<template>
  <div class="w-100 mt-4">
    <!-- <b-row class="p-3 mb-3">
      <b-col sm="12" class="bg-white rounded p-0 shadow-sm">
        <div
          class="px-4 pt-4 pb-0 d-flex justify-content-between gap-2 border-bottom mb-3"
        >
          <p class="text-primary text-nowrap">Advance Search</p>
          <div
            aria-controls="collapse-1"
            class="p-0 cursor-pointer"
            @click="collapse = !collapse"
            ><span v-if="collapse" class="fa fa-solid fa-chevron-up"></span
            ><span v-else class="fa fa-solid fa-chevron-down"></span
          ></div>
        </div>
        <b-collapse id="collapse-1" v-model="collapse" class="mt-2">
          <b-row class="px-3 pt-0">
            <b-col md="1">
              <b-form-group label-align="left" label-for="year">
                <b-form-select
                  plain
                  :options="['2022', '2022']"
                  id="year"
                  size="lg"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-align="left" label-for="period">
                <b-form-select
                  plain
                  :options="['January-Juli', 'Juli-Juli']"
                  id="period"
                  size="lg"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-align="left" label-for="period">
                <v-multiselect
                  :options="[]"
                  :multiple="true"
                  group-values="sub"
                  group-label="project"
                  :group-select="true"
                  track-by="name"
                  label="name"
                >
                  <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                    query.</span
                  >
                </v-multiselect>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <div class="d-flex align-items-center h-100 pb-3">
                <button class="btn btn-primary px-3 py-1"
                  ><span class="fa fa-search"></span
                ></button>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row> -->
    <iq-card headerClass="bg-blue-navy">
      <template v-slot:headerTitle>
        <h4 class="card-title text-white"
          ><strong>FLEET AVAILABILITY & BREAKDOWN</strong></h4
        >
      </template>
      <template #body>
        <!-- <div>
          <b-form-input v-model="textEmoji"></b-form-input>
          <button @click="toogleDialogEmoji">😃</button>
          <VEmojiPicker @select="selectEmoji" v-show="showEmoji" style="position: absolute; z-index: 50;"/>
        </div> -->
        <div class="d-flex overflow-auto pb-3">
          <div
            class="card-container cursor-pointer shadow-sm mr-3"
            v-for="(item, index) in fleetsStatus"
            style="min-width: 300px"
            :key="index"
            :md="`${item.cols * 2}`"
          >
            <div
              class="p-2 py-3 card-title-wrap d-flex align-items-center px-3"
              :class="item.bg"
              style="border-radius: 10px"
            >
              <!-- <div class="mr-3">
                <OperationalIcon />
              </div> -->
              <div>
                <strong class="p-0 m-0 text-yellow">{{ item.title }}</strong>
                <h4 class="text-white">{{ item.total }}</h4>
              </div>
            </div>
            <img
              v-if="index == fleetsStatus.length - 1"
              :src="require(`../../../assets/images/icon/heavy-equipment.svg`)"
              width="90"
              alt=""
              class="card-image"
              style="top: 34px"
            />
            <img
              v-else
              :src="require(`../../../assets/images/icon/ship-3d.png`)"
              width="78"
              alt=""
              class="card-image"
            />
            <div class="mt-2">
              <div
                v-for="(tableData, i) in items"
                :key="i"
                class="py-1 px-3 d-flex justify-content-between"
                :class="{ 'bg-striped': i % 2 == 0 }"
              >
                <h6>{{ tableData.fleet }}</h6>
                <h6>{{ tableData.qty }}</h6>
              </div>
            </div>
          </div>
        </div>
      </template>
    </iq-card>
  </div>
</template>
<script>
import BreakdownIcon from '../IconsTabMenus/BreakdownIcon.vue'
import DockingIcon from '../IconsTabMenus/DockingIcon.vue'
import HeavyIcon from '../IconsTabMenus/HeavyIcon.vue'
import OperationalIcon from '../IconsTabMenus/OperationalIcon.vue'

export default {
  name: 'SummaryFleetStatus',
  components: {
    OperationalIcon,
    BreakdownIcon,
    DockingIcon,
    HeavyIcon,
  },
  data() {
    return {
      collapse: true,
      items: [
        { fleet: 'Tugboat', qty: 40, test: 'asd' },
        { fleet: 'Barge', qty: 21, test: 'asd' },
        { fleet: 'Pusher Tug', qty: 89, test: 'asd' },
        { fleet: 'Pusher Barge', qty: 38, test: 'asd' },
        { fleet: 'Floating Crane', qty: 38, test: 'asd' },
        { fleet: 'Oil Barge', qty: 38, test: 'asd' },
        { fleet: 'Crew Boat', qty: 38, test: 'asd' },
      ],
      fleetsStatus: [
        {
          title: 'Fleet Active',
          total: '124/200 Vessels',
          bg: 'active-bg',
          icon: OperationalIcon,
          cols: 3,
        },
        {
          title: 'Fleet Stand By',
          total: '124/200 Vessels',
          bg: 'standby-bg',
          icon: OperationalIcon,
          cols: 3,
        },
        {
          title: 'Fleet Breakdown',
          total: '14 Vessels',
          bg: 'breakdown-bg',
          icon: BreakdownIcon,
          cols: 3,
        },
        {
          title: 'Docking Progress',
          total: '2 Vessels',
          bg: 'progress-bg',
          icon: DockingIcon,
          cols: 3,
        },
        {
          title: 'Heavy Equipment',
          total: '3 Vessels',
          bg: 'heavy-equipment-bg',
          icon: HeavyIcon,
          cols: 3,
        },
      ],
      showEmoji:false,
      textEmoji:''
    }
  },
  methods: {
    selectEmoji(emoji) {
      this.textEmoji += emoji.data
    },
    toogleDialogEmoji() {
      this.showEmoji = !this.showEmoji;
    },
  },
}
</script>
<style>
.card-container {
  background: white;
  border: 0;
  border-radius: 10px;
  padding: 12px;
  position: relative;
  /* border: 1px solid; */
  transition: transform 0.25s ease;
}
.card-container .card-title-wrap {
  border: 0;
  border-radius: 8px;
  padding: 8px;
}
.card-container .card-image {
  position: absolute;
  right: 24px;
  top: 10px;
  transition: transform 0.3s ease;
}

.card-container:hover {
  transform: scale(1.03);
}
.card-container:hover .card-image {
  transform: scale(1.25);
}

.bg-blue-navy {
  background: #2553ad;
}

.active-bg {
  background: #017aff;
}
.standby-bg {
  background: #334697;
}
.breakdown-bg {
  background: #ff0000;
}
.progress-bg {
  background: #ffa500;
}
.icon {
  width: 58px;
  height: 58px;
}
.text-yellow {
  color: #ffff00;
}
.heavy-equipment-bg {
  background: #800080;
}
.bg-striped {
  background: #f9fafb;
}
</style>

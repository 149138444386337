<template>
  <div
    style="background: rgb(241 245 249); min-height: calc(100vh - 60px)"
    class="py-4"
  >
    <b-col md="12">
      <iq-card>
        <template #body>
          <b-row class="">
            <b-col md="3">
              <b-form-group label="Period" label-for="Period">
                <date-picker
                  type="date"
                  range
                  v-model="dateRange"
                  placeholder="Select date range"
                  :style="{ width: '100%' }"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Status" label-for="status">
                <b-form-select plain v-model="params.status">
                  <b-form-select-option value="all">All</b-form-select-option>
                  <b-form-select-option value="progress">Progress</b-form-select-option>
                  <b-form-select-option value="completed">Completed</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Filter Option" label-for="cargo">
                <b-form-select plain v-model="params.filterOption">
                  <b-form-select-option value="all">All</b-form-select-option>
                  <b-form-select-option value="by-project">By Project</b-form-select-option>
                  <b-form-select-option value="by-fleet">By Fleet</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2" v-if="params.filterOption == 'by-fleet'">
              <b-form-group label="Fleet Name" label-for="fleet-categtory">
                <v-multiselect
                  id="fleet-category"
                  :multiple="true"
                  placeholder="Select Categories"
                  label="name"
                  :options="fleetNameOpt"
                  track-by="code"
                  v-model="params.vehicleIds"
                >
                  <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                    query.</span
                  >
                </v-multiselect>
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="params.filterOption == 'by-project'">
              <b-form-group label="Project Name" label-for="fleet-categtory">
                <v-multiselect
                  id="fleet-category"
                  :multiple="true"
                  placeholder="Select Categories"
                  label="name"
                  :options="projectNameOpt"
                  track-by="code"
                  v-model="params.projectIds"
                >
                  <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                    query.</span
                  >
                </v-multiselect>
              </b-form-group>
            </b-col>
            <b-col md="2" class="d-flex align-items-end">
              <b-form-group class="w-100">
                <b-button variant="primary" class="w-100" type="button" @click="fetchVoyageByTrips">
                  <i class="fas fa-search"></i>
                  Show Data
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="mb-0"><strong>Operational Data Running Shipment</strong></h5>
            <!-- <b-button
              variant="outline-primary"
              size="md"
              class="d-flex align-items-center"
              @click="toggleModalFilter"
            >
              <i class="fas fa-sliders"></i>
              <p class="text-truncate m-0"> Filter Data</p>
            </b-button> -->
            <b-button variant="outline-primary" class="mx-2 d-flex" size="md">
              <i class="fa fa-download"></i>
              Export
            </b-button>
          </div>
          <div style="max-height: 700px" class="overflow-auto">
            <template v-if="list.length > 0">
              <div style="max-height:600px" v-for="(state, index) in list" :key="index">
                <div style="background-color: #EDEDED; cursor:pointer;" class="d-flex w-100 p-3" @click="toggleRow(`list-${index}`)" >
                  <h5 class="text-truncate">
                    <strong>{{ state?.projectSub?.project?.projectName }} - {{ state?.projectSub?.name }}</strong>
                  </h5>
                </div>
                <div class="overflow-auto" v-if="isRowVisible(`list-${index}`)" :class="{ 'collapsed-row': !isRowVisible(`list-${index}`), 'expanded-row': isRowVisible(`list-${index}`) }">
                  <table class="table table-borderless table-hover" v-for="(trip, tripIndex) in state?.projectSub?.trip" :key="tripIndex">
                    <thead>
                      <tr>
                        <th
                          v-for="(column, index) in columns"
                          :key="index"
                          class="text-truncate column-primary"
                        >{{ column }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-truncate">
                          <b-badge variant="primary" class="mr-2" v-for="(vehicle, vehicleIndex) in trip.vehicle">{{ vehicle?.name }}</b-badge>
                        </td>
                        <td class="text-truncate">{{ trip?.tripRecord?.portLoading || trip?.tripRecord?.portLoadingOther }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.portDischarge || trip?.tripRecord?.portDischargeOther }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.polGrabType || trip?.tripRecord?.podGrabType }}</td>
                        <td class="text-truncate text-right text-primary">{{ trip?.tripRecord?.cargoUnloading }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.status }}</td>
                        <td class="text-truncate">
                          <b-button
                            variant="primary"
                            @click="handleOpenFreight(trip, `${state?.projectSub?.project?.projectName} - ${state?.projectSub?.name}`)"
                          >
                            <i class="fa fa-plus"></i>
                            Input Freight
                          </b-button>
                        </td>
                        <td class="text-truncate">{{ trip?.tripRecord?.polArrivalTime }}</td>
                        <td class="text-truncate">{{ matrixData('Waiting for Berthing POL', index, tripIndex) }}</td>
                        <td class="text-truncate"></td>
                        <td class="text-truncate">{{ matrixData('Waiting for Loading', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.polCommenced }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.polCompleted }}</td>
                        <td class="text-truncate">{{ matrixData('Loading', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.polCastOut }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.polLhv }}</td>
                        <td class="text-truncate">{{ matrixData('Waiting LHV', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.polDocOnBoard }}</td>
                        <td class="text-truncate">{{ matrixData('Waiting Document POL', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.polDeparture }}</td>
                        <td class="text-truncate">{{ matrixData('Waiting Departure', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.podArrivalTime }}</td>
                        <td class="text-truncate">{{ matrixData('POL Steaming Time', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.podAnchorUp }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.podBerthing }}</td>
                        <td class="text-truncate"></td>
                        <td class="text-truncate">{{ matrixData('Waiting for Berthing', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ matrixData('Discharge', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.podCastOut }}</td>
                        <td class="text-truncate">{{ matrixData('Waiting Full Away', index, tripIndex) }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.podDeparture }}</td>
                        <td class="text-truncate">{{ trip?.tripRecord?.podArrivalTime }}</td>
                        <td class="text-truncate">{{ matrixData('POD Steaming Time', index, tripIndex)  }}</td>
                        <td class="text-truncate">{{ matrixData('Total Steaming', index, tripIndex)  }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
            <div class="d-flex align-items-center justify-content-center w-100 p-3" v-else>
              <h6 class="text-muted">No Data Found. try change filter period<strong></strong></h6>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-modal
      v-model="modal.freightRate"
      id="input-freight-rate"
      title="Input Freight Rate"
      centered
      size="xl"
      title-class="title-modal"
      hide-footer
    >
      <FormFreightRate :refreshData="refreshData" :fleets="fleetNameOpt" :tripData="selectedTrip" :projectName="selectedProject" />
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { fleetsActions, projectsActions, voyagesActions } from '@/src/Utils/helper'
import FormFreightRate from './FormFreightRate.vue'

export default {
  components: {
    FormFreightRate,
  },
  data() {
    return {
      fleetNameOpt: [],
      projectNameOpt: [],
      filterOptSelected: 'by-fleet',
      visibleRows: {},
      dateRange: [new Date(moment().startOf('month')), new Date(moment().endOf('month'))],
      params: {
        ownership:'all',
        filterOption:'all',
        status:'all',
        // projectIds: [],
        // vehicleIds: [],
        // dateRange: [],
      },
      modal: {
        freightRate: false,
      },
      vehicleSelected: [],
      inputFreightSelected: null,
      columns: [
        'Fleet Name',
        'POL',
        'POD',
        'Grab Type',
        'Actual COB',
        'Status',
        'Freight Rate',
        'Arrival Time POL',
        'Waiting for Berthing',
        'Alongside',
        'Waiting For Loading',
        'Commenced',
        'Completed',
        'Loading',
        'Cast Off',
        'LHV',
        'Waiting LHV',
        'Document on Board',
        'Waiting Document',
        'Time Departure',
        'Waiting Departure',
        'Arrival POD',
        'Steaming Time',
        'Anchor Up',
        'Berthing Process',
        'Alongside',
        'Waiting for Berthing',
        'Discharge',
        'Cast Off',
        'Waiting Full Away',
        'Departure To POD',
        'Arrival Time POD',
        'Steaming Time',
        'Total Steaming Time',
      ],
      rows: [
        {
          showModal: false,
          row: '',
        },
      ],

      selectedProject: '',
      selectedTrip: null,
      list: [],
    }
  },
  mounted() {
    this.fetchVoyageByTrips()
    this.fetchFleets()
    this.fetchProjects()
  },
  watch: {
    'modal.freightRate': {
      immediate: true,
      handler: function () {
        if (!this.modal.freightRate) {
          this.selectedTrip = null
          this.selectedProject = ''
        }
      },
    }
  },
  computed: {
    userVehicles() {
      return this.$store.state.auth.profile.vehicle || []
    },
    vehicleTypes() {
      let vehicleTypes = []

      this.userVehicles.forEach((vehicle) => {
        const vehicleTypeExist = vehicleTypes.find(
          (v) => v.id === vehicle.vehicleType.id
        )
        if (!vehicleTypeExist) {
          vehicle.vehicleType.checked = true
          vehicleTypes.push(vehicle.vehicleType)
        }
      })

      return vehicleTypes
    },
  },
  methods: {
    ...fleetsActions,
    ...projectsActions,
    ...voyagesActions,
    handleOpenFreight(data, projectName) {
      this.selectedTrip = data
      this.selectedProject = projectName

      this.modal.freightRate = true
    },
    toggleRow(id) {
      this.$set(this.visibleRows, id, !this.visibleRows[id]);
    },
    isRowVisible(id) {
      return !!this.visibleRows[id];
    },
    matrixData(keyMatrix, index, tripIndex){
     let matrix = this.list?.[index]?.projectSub?.trip?.[tripIndex]?.matrix?.find(obj => obj?.name === keyMatrix)?.durationFormat
     return matrix
    },

    refreshData(){
      this.fetchVoyageByTrips()
    },

    async fetchFleets() {
      let params = {
        page: 1,
        perPage: 1000,
      }
      try {
        this.fleetNameOpt = []
        const res = await this.getFleets(params)
        res.data.forEach((el) => {
          this.fleetNameOpt.push({
            name: el.name,
            code: el.id,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async fetchProjects() {
      let params = {
        page: 1,
        perPage: 1000,
      }
      try {
        this.projectNameOpt = []
        const res = await this.getProjects(params)
        res.data.data.forEach((el) => {
          this.projectNameOpt.push({
            name: el.projectName,
            code: el.id,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },

    async fetchVoyageByTrips() {
      let params = {
        // vehicleId: Number(this.$route.query.vehicleId),
        ...this.params,
        startDate: this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('DD-MM-YYYY') : null,
        endDate: this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('DD-MM-YYYY') : null, 
      }

      // Iterate over each property in params
      for (const prop in params) {
        if (params.hasOwnProperty(prop)) {
          // Check conditions for each property and delete if necessary
          if ((params[prop] === '') || (params[prop] === null)) {
            delete params[prop];
          }
        }
      }

      const res = await this.getVoyageTripsByPeriod(params)

      if (res.status == 'success') {
        this.list = res.data.data
      } else {
        this.list = []
      }
    },  
  },
}
</script>
<style>
.column-primary {
  background: #334697;
  color: white;
  /* padding: 4px 12px;
  vertical-align: middle !important; */
}
.title-modal {
  font-weight: 500 !important;
}

.collapsed-row .collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;
}

.expanded-row .collapsible-content {
  max-height: 100px; /* Set to a height that fits the content */
  transition: max-height 0.3s ease-in;
  opacity: 1;
}

.collapsible-content {
  transition: opacity 0.3s ease, max-height 0.3s ease;
}
</style>

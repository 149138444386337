var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.canPreview ? _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.isPreview,
      callback: function callback($$v) {
        _vm.isPreview = $$v;
      },
      expression: "isPreview"
    }
  }) : _vm._e(), _c('RcaFinalPreviewStep', {
    attrs: {
      "detailMi": _vm.detailMi
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
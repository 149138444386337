var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', _vm._l(_vm.tableData.head, function (state, index) {
    return _c('th', {
      key: index,
      staticClass: "text-truncate text-white",
      staticStyle: {
        "background": "#334697"
      }
    }, [_vm._v(_vm._s(state))]);
  }), 0)]), _c('tbody', _vm._l(_vm.tableData.body, function (data, index) {
    var _data$personal_data, _data$seaferer_code;
    return _c('tr', {
      key: "data-".concat(index)
    }, [_c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v(_vm._s(_vm.indexes + index))]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('router-link', {
      attrs: {
        "to": {
          path: "profile",
          query: {
            user: 'candidate',
            id: data.id
          }
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "size": "34",
        "variant": "warning",
        "src": (_data$personal_data = data.personal_data) === null || _data$personal_data === void 0 ? void 0 : _data$personal_data.photo_url
      }
    }), _c('h6', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(data.full_name))])], 1)])], 1), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s((_data$seaferer_code = data.seaferer_code) !== null && _data$seaferer_code !== void 0 ? _data$seaferer_code : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(data.name_position))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(data.date_interview ? _vm.moment(new Date(data.date_interview)).format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(data.expert || '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('b-badge', {
      attrs: {
        "variant": "".concat(data.status === 'candidate' ? 'info' : data.status === 'expired_interview' ? 'danger' : data.status === 'waiting_result' ? 'success' : 'primary', " d-block")
      }
    }, [_vm._v(" " + _vm._s(data.status.split('_').join(' ')) + " ")])], 1), _c('td', {}, [_c('b-dropdown', {
      attrs: {
        "size": "sm",
        "variant": "dark",
        "toggle-class": "text-decoration-none",
        "text": "Actions",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_vm._v(" Actions "), _c('i', {
            staticClass: "fa fa-chevron-down ml-1"
          })];
        },
        proxy: true
      }], null, true)
    }, [data.status !== 'candidate' && _vm.permission ? _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.sign-on",
        modifiers: {
          "sign-on": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": 'primary'
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-user mr-2"
    }), _vm._v(" Bypass Process to Crew ")]) : _vm._e(), data.status !== 'candidate' && _vm.permission === true ? _c('b-dropdown-divider') : _vm._e(), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": data.status === 'candidate' ? 'primary' : 'secondary',
        "disabled": data.status !== 'candidate'
      },
      on: {
        "click": function click($event) {
          return _vm.proceedToRecruitment(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-check mr-2"
    }), _vm._v("Proceed to Recruitment ")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.verification_document",
        modifiers: {
          "verification_document": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": data.status === 'checklist' || data.status === 'set_interview' ? 'primary' : 'secondary',
        "disabled": data.status === 'checklist' || data.status === 'set_interview' ? false : true
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-check mr-2"
    }), _vm._v("Verification ")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.setup-interview",
        modifiers: {
          "setup-interview": true
        }
      }],
      attrs: {
        "href": "#",
        "disabled": data.status === 'set_interview' || data.status === 'interview' ? false : true,
        "variant": data.status === 'set_interview' || data.status === 'interview' ? 'primary' : 'secondary'
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-calendar mr-2"
    }), _vm._v("Set Interview ")]), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "disabled": data.status !== 'interview',
        "variant": data.status === 'interview' ? 'primary' : 'secondary'
      },
      on: {
        "click": function click() {
          return _vm.emailPromptCandidate(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-calendar mr-2"
    }), _vm._v("Send Email to Candidate ")]), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "disabled": data.status !== 'interview',
        "variant": data.status === 'interview' ? 'primary' : 'secondary'
      },
      on: {
        "click": function click() {
          return _vm.emailPromptInterviewer(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-calendar mr-2"
    }), _vm._v("Send Email to Interviewer ")]), _c('b-dropdown-item', {
      attrs: {
        "disabled": data.status === 'interview' || data.status === 'mcu' ? false : true
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('router-link', {
      class: data.status === 'interview' || data.status === 'mcu' ? 'text-primary' : 'text-secondary',
      attrs: {
        "to": {
          path: "/crewing/interview-review/".concat(data.id),
          params: {
            id: data.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-address-card mr-2"
    }), _vm._v("Interview Review")])], 1), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.medical-checkup",
        modifiers: {
          "medical-checkup": true
        }
      }],
      attrs: {
        "href": "#",
        "disabled": data.status === 'mcu' || data.status === 'induction' ? false : true,
        "variant": data.status === 'mcu' || data.status === 'induction' ? 'primary' : 'secondary'
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-stethoscope mr-2"
    }), _vm._v("Medical Checkup")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.induction",
        modifiers: {
          "induction": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": data.status === 'induction' || data.status === 'contract' ? 'primary' : 'secondary',
        "disabled": data.status === 'induction' || data.status === 'contract' ? false : true
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-file mr-2"
    }), _vm._v("Induction")]), data.status === 'contract' ? _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.sign-on",
        modifiers: {
          "sign-on": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": 'primary'
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-handshake mr-2"
    }), _vm._v("Contract / Sign On")]) : _c('b-dropdown-item', {
      attrs: {
        "variant": 'secondary',
        "disabled": ""
      }
    }, [_c('i', {
      staticClass: "fas fa-handshake mr-2"
    }), _vm._v("Contract / Sign On")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.blacklist-crew",
        modifiers: {
          "blacklist-crew": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v("Black List")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.delete-candidate",
        modifiers: {
          "delete-candidate": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-user mr-2"
    }), _vm._v("Delete Candidate")])], 1)], 1)]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
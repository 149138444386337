<template>
  <div class="border p-1">
    <h6 style="font-weight: 500;" class="mb-1">Proximity Level Indicator</h6>
    <table class="table table-striped table-sm mb-0">
      <tbody>
        <tr v-for="(item, index) in indicators" :key="index" >
          <td class="text-center">
            <b-row>
                <b-col cols="6">{{ index + 1 }}</b-col>
                <b-col cols="6" class="px-3"><b-badge :variant="item.variant" class="w-100 py-1">{{ item.label }}</b-badge></b-col>
            </b-row>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      indicators: [
        {
          label: 'FATAL',
          variant: 'danger',
        },
        {
          label: 'DANGER',
          variant: 'warning',
        },
        {
          label: 'ALERT',
          variant: 'info',
        },
        {
          label: 'NORMAL',
          variant: 'success',
        },
        {
          label: 'NORMAL',
          variant: 'success',
        },
      ],
    }
  },
}
</script>

<template>
  <div v-if="activeRecommendation">
    <!-- Modal Filter !-->
    <b-modal
      v-model="modal.filter"
      size="lg"
      @ok="handleFetchRecommendation(1)"
      ok-title="Filter"
      title="Filter Data"
      centered
    >
      <b-row class="px-3">
        <b-col md="12">
          <b-form-group label-for="dateIssued" label="Date Range" label-cols-sm="4" label-cols-lg="3" class="mb-3" >
            <date-picker
              @change="handleDateRange"
              class="w-100"
              v-model="dateRange"
              type="date"
              value-type="YYYY-MM-DD"
              range
              placeholder="Select date range"
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label-for="dateIssued" label="Fleet/Vessel"  label-cols-sm="4" label-cols-lg="3" class="mb-3" >
            <p v-if="vehicleId" class="form-control-static">
              {{
                fleets.find((fleet) => fleet.code === vehicleId)
                  ? fleets.find((fleet) => fleet.code === vehicleId).name
                  : '-'
              }}
            </p>
            <v-select
              v-else
              v-model="recommendations.params.vehicle_id"
              label="name"
              :options="fleets"
              :reduce="(fleetList) => fleetList.code"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label-for="dateIssued" label="Type of Coverage"  label-cols-sm="4" label-cols-lg="3" class="mb-3" >
            <v-select
              v-model="recommendations.params.type_coverage_id"
              label="name"
              :options="typeCoverages"
              :reduce="(typeOfCoverage) => typeOfCoverage.code"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label-for="dateIssued" label="Type of Loss"  label-cols-sm="4" label-cols-lg="3" class="mb-3" >
            <v-select
              v-model="recommendations.params.type_loss_id"
              label="name"
              :options="typeLoss"
              :reduce="(typeOfLoss) => typeOfLoss.code"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label-for="dateIssued" label="Status"  label-cols-sm="4" label-cols-lg="3" class="mb-3">
            <v-select
              v-model="recommendations.params.status_id"
              label="text"
              :options="statusOptions"
              :reduce="(statusOptions) => statusOptions.value"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <!-- End Modal Filter !-->
    <!-- Content Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              ><strong>Recommendation Form List</strong></h4
            >
          </template>
          <template v-slot:body>
            <div class="d-flex justify-content-between mb-3">
              <div class="d-flex">
                <!-- <b-form-input size="md" placeholder="search..."></b-form-input>
                <b-button variant="primary" class="mx-2">
                  <i class="fa fa-search"></i>
                </b-button> -->
                <b-button
                  variant="outline-primary"
                  class="d-flex align-items-center"
                  @click="toggleModalFilter"
                >
                  <i class="fas fa-sliders"></i>
                  <p class="text-truncate m-0"> Filter Data</p>
                </b-button>
              </div>
              <div>
                <router-link to="#" v-if="canExport || canExportRecommendation">
                  <button class="btn btn-outline-primary mr-1"
                    ><i class="fa fa-file-excel"></i> Export</button
                  >
                </router-link>
                <router-link
                  to="/mi/add-form-recommendation"
                  v-if="canAdd && canAddRecommendation"
                >
                  <button class="btn btn-primary"
                    ><i class="fa fa-plus"></i> Add Form Recommendation</button
                  >
                </router-link>
              </div>
            </div>
            <div class="px-3 mb-3">
              <b-row class="py-3 border">
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Date Range">
                    <h6 style="font-weight: 500">
                      {{ moment(dateRange[0]).format('DD MMM YYYY') }} -
                      {{ moment(dateRange[1]).format('DD MMM YYYY') }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label-for="dateIssued"
                    label="Fleet/Vessel"
                    v-if="recommendations.params.vehicle_id"
                  >
                    <h6 style="font-weight: 500">
                      {{
                        fleets.find(
                          (el) => el.code == recommendations.params.vehicle_id
                        )?.name
                      }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label-for="dateIssued"
                    label="Type of Coverage"
                    v-if="recommendations.params.type_coverage_id"
                  >
                    <h6 style="font-weight: 500">
                      {{
                        typeCoverages.find(
                          (el) =>
                            el.code == recommendations.params.type_coverage_id
                        )?.name
                      }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label-for="dateIssued"
                    label="Type of Loss"
                    v-if="recommendations.params.type_loss_id"
                  >
                    <h6 style="font-weight: 500">
                      {{
                        typeLoss.find(
                          (el) => el.code == recommendations.params.type_loss_id
                        )?.name
                      }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label-for="dateIssued"
                    label="Status"
                    v-if="recommendations.params.status_id"
                  >
                    <h6 style="font-weight: 500">
                      {{
                        statusOptions.find(
                          (el) => el.value == recommendations.params.status_id
                        ).text
                      }}
                    </h6>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col md="12" class="text-right"> </b-col>
              <b-col cols="12">
                <table class="table table-borderless table-bordered">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Problem / Issue</th>
                      <!-- <th>Vessel Case</th> -->
                      <!-- <th>Root Cause</th> -->
                      <!-- <th>Rekomendasi</th> -->
                      <th>Cause of Loss</th>
                      <th>Status</th>
                      <th>Closed Date</th>
                      <th class="text-center">Detail</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      recommendations.data.length === 0 ||
                      recommendations.isLoading
                    "
                  >
                    <tr>
                      <td colspan="10" class="text-center">{{
                        recommendations.isLoading ? 'Loading...' : 'No Data'
                      }}</td>
                    </tr>
                  </tbody>
                  <tbody
                    v-for="(data, index) in recommendations.data"
                    :key="index"
                  >
                    <tr>
                      <td style="width: 4%">{{
                        recommendations.meta.pageNumber + index
                      }}</td>
                      <td style="width: 400px">{{ data.problem }}</td>
                      <td>{{
                        causesOfLoss.find(
                          (val) => data.cause_of_loss_id === val.code
                        )
                          ? causesOfLoss.find(
                              (val) => data.cause_of_loss_id === val.code
                            ).name
                          : '-'
                      }}</td>
                      <td>{{ data.status }}</td>
                      <td>{{
                        data.closed_at
                          ? moment(data.closed_at).format('DD MMM YYYY')
                          : '-'
                      }}</td>
                      <td class="text-center">
                        <b-button
                          class="text-truncate"
                          variant="primary"
                          size="sm"
                          style="font-size: small"
                          v-b-toggle="`rekomendation-${index}`"
                          >View Detail</b-button
                        >
                      </td>
                      <td class="d-flex">
                        <b-button
                          @click="
                            handleEditRecommendation(data.id, data.form_id)
                          "
                          variant="outline-primary"
                          size="sm"
                          v-if="canEditRecommendation"
                          class="mr-1"
                          ><i class="fa fa-edit"></i>
                        </b-button>
                        <b-button
                          @click="handleDeleteRecommendation(data)"
                          variant="outline-danger"
                          size="sm"
                          v-if="canDeleteRecommendation"
                          ><i class="fa fa-trash"></i>
                        </b-button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" style="padding: 0 !important">
                        <b-collapse :id="`rekomendation-${index}`">
                          <div class="pl-5">
                            <div style="background: #c7ebff" class="p-3">
                              <h6><Strong>Vessel Case</Strong></h6>
                              <h6>{{ data.vessel_case ?? '-' }}</h6>
                            </div>
                            <div style="background: #f1faff" class="p-3">
                              <h6><Strong>Route Cause</Strong></h6>
                              <h6>{{ data.root_cause ?? '-' }}</h6>
                            </div>
                            <div style="background: #c7ebff" class="p-3">
                              <h6><Strong>Rekomendasi</Strong></h6>
                              <h6>{{ data.recommendation ?? '-' }}</h6>
                            </div>
                          </div>
                        </b-collapse>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="mt-3 mb-3">
                  <b-pagination
                    v-model="recommendations.params.page"
                    :total-rows="recommendations.meta.total"
                    :per-page="recommendations.meta.perPage"
                    align="center"
                    @input="handleFetchRecommendation"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Content Section !-->
  </div>
  <div v-else>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <p>You don't have permission to access this menu</p>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'ListInitialRecommendation',
  props: {
    vehicleId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      dateRange: [
        moment().startOf('year').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
      ],
      statuses: [],
      fleets: [],
      typeCoverages: [],
      typeLoss: [],
      statusOptions: [],
      causesOfLoss: [],
      recommendations: {
        isLoading: false,
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
          pageNumber: 0,
        },
        params: {
          page: 1,
          from: null,
          to: null,
          vehicle_id: null,
          status_id: null,
          type_coverage_id: null,
          type_loss_id: null,
        },
      },
      modal: {
        filter: false,
      },
    }
  },
  mounted() {
    if (this.vehicleId) {
      this.recommendations.params.vehicle_id = this.vehicleId
    }

    this.handleDateRange()
    this.handleFetchCauseLoss()
    this.handleFetchFleets()
    this.handleFetchTypeCoverages()
    this.handleFetchTypeLoss()
    this.handleFetchStatusList()
    this.handleFetchRecommendation()
  },
  computed: {
    menuPermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Marine Insurance')
        ?.child.find(
          (child) => child.menu === 'List Initial Accident / Incident'
        )
    },
    canAdd() {
      return this.menuPermission ? this.menuPermission?.add : false
    },
    canEdit() {
      return this.menuPermission ? this.menuPermission?.update : false
    },
    canApprove() {
      return this.menuPermission ? this.menuPermission?.approval : false
    },
    canReview() {
      return this.menuPermission ? this.menuPermission?.review : false
    },
    canDelete() {
      return this.menuPermission ? this.menuPermission?.delete : false
    },
    canExport() {
      return this.menuPermission ? this.menuPermission?.export : false
    },
    recommendation() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Marine Insurance')
        ?.child.find((child) => child.menu === 'Recommendation')
    },
    activeRecommendation() {
      return this.recommendation ? this.recommendation?.active : false
    },
    canAddRecommendation() {
      return this.recommendation ? this.recommendation?.add : false
    },
    canEditRecommendation() {
      return this.recommendation ? this.recommendation?.update : false
    },
    canApproveRecommendation() {
      return this.recommendation ? this.recommendation?.approval : false
    },
    canReviewRecommendation() {
      return this.recommendation ? this.recommendation?.review : false
    },
    canDeleteRecommendation() {
      return this.recommendation ? this.recommendation?.delete : false
    },
    canExportRecommendation() {
      return this.recommendation ? this.recommendation?.export : false
    },
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,

    toggleModalFilter() {
      this.modal.filter = !this.modal.filter
    },

    handleDateRange() {
      this.recommendations.params.from = this.dateRange
        ? moment(this.dateRange[0]).format('YYYY-MM-DD')
        : null
      this.recommendations.params.to = this.dateRange
        ? moment(this.dateRange[1]).format('YYYY-MM-DD')
        : null
    },
    async handleFetchRecommendation(page = 1) {
      this.recommendations.data = []
      this.recommendations.isLoading = true
      this.recommendations.params.page = page
      const { data } = await this.getRecommendationList(
        this.recommendations.params
      )

      if (data.data) {
        this.recommendations.data = data.data

        this.recommendations.meta.perPage = data.per_page
        this.recommendations.meta.currentPage = data.current_page
        this.recommendations.meta.total = data.total
        this.recommendations.meta.pageNumber = data.from
      }

      this.recommendations.isLoading = false
    },
    async handleFetchFleets() {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: '',
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.fleets.push({
              name: el.name,
              code: el.id,
            })
          })
        }
      }
    },
    async handleFetchTypeCoverages() {
      this.typeCoverages = []
      let res = await this.getTypeCoverages({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.typeCoverages.push({
              name: el.name,
              code: el.id,
            })
          })
        }
      }
    },
    async handleFetchCauseLoss() {
      this.causesOfLoss = []
      let res = await this.getMasterCauseOfLoss({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.causesOfLoss.push({
              name: el.name,
              code: el.id,
            })
          })
        }
      }
    },
    async handleFetchTypeLoss() {
      this.typeLoss = []
      let res = await this.getTypeLoss({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.typeLoss.push({
              name: el.name,
              code: el.id,
            })
          })

          this.statusOptions = res.data.map((val) => ({
            value: val.id,
            text: val.name,
          }))
        }
      }
    },
    async handleFetchStatusList() {
      this.statuses = []
      let res = await this.getStatuses({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.statuses.push({
              name: el.name,
              code: el.id,
            })
          })
          this.statusOptions = res.data.map((val) => ({
            value: val.id,
            text: val.name,
          }))
        }
      }
    },
    onCreateRecommendation(formId) {
      this.$router.push({
        name: 'mi.add-form-recommendation',
        query: {
          formId,
        },
      })
    },
    handleEditRecommendation(id, formId) {
      this.$router.push({
        name: 'mi.add-form-recommendation',
        params: {
          id,
        },
        query: {
          formId,
        },
      })
    },
    handleDeleteRecommendation(recommendation) {
      this.$swal({
        title: 'Delete Recommendation?',
        text: `Recommendation for ${recommendation.problem} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteRecommendation(recommendation.id)
          if (res.status !== 'success') {
            if (res.data.message !== null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get Recommendation data.',
                'error'
              )
            }
          } else {
            this.handleFetchRecommendation()
            this.$swal(
              `Recommendation deleted!`,
              `Recommendation for ${recommendation.problem} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
}
table th {
  background: #334697;
  color: white;
  padding: 12px;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "headerClass": "align-items-center py-2"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('div', {}, [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER TRIP DISTANCE")])])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row w-100",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getMasterTripDistance();
            }
          }
        }, [_c('b-col', {
          staticClass: "p-0",
          attrs: {
            "cols": "12",
            "md": "10"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.mastertripDistance.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.mastertripDistance.params, "name", $$v);
            },
            expression: "mastertripDistance.params.name"
          }
        })], 1), _c('b-col', {
          staticClass: "p-0",
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.mastertripDistance._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.mastertripDistance.data && _vm.mastertripDistance.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Vehicle Type")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Port From")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Port To")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Distance")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Units")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.mastertripDistance.data, function (p, i) {
          var _p$port_from_value, _p$port_to_value, _p$distance, _p$units;
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_c('div', {
            staticClass: "d-flex flex-wrap"
          }, _vm._l(p === null || p === void 0 ? void 0 : p.vehicle_types, function (fleetType, fIndex) {
            return _c('b-badge', {
              key: fIndex,
              staticClass: "border border-primary text-primary text-uppercase mr-1 mb-1",
              attrs: {
                "variant": "none"
              }
            }, [_vm._v(_vm._s(fleetType === null || fleetType === void 0 ? void 0 : fleetType.name))]);
          }), 1)]), _c('td', [_vm._v(_vm._s((_p$port_from_value = p.port_from_value) !== null && _p$port_from_value !== void 0 ? _p$port_from_value : '-'))]), _c('td', [_vm._v(_vm._s((_p$port_to_value = p.port_to_value) !== null && _p$port_to_value !== void 0 ? _p$port_to_value : '-'))]), _c('td', [_vm._v(_vm._s((_p$distance = p.distance) !== null && _p$distance !== void 0 ? _p$distance : '-'))]), _c('td', [_vm._v(_vm._s((_p$units = p.units) !== null && _p$units !== void 0 ? _p$units : '-'))]), _c('td', [_c('div', {
            staticClass: "d-flex justify-content-center"
          }, [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.editFormTripDistance(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteTripDistance(p);
              }
            }
          }, [!_vm.delete_contract_type ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)])]);
        }), 0)])]) : _vm._e(), _vm.mastertripDistance.data && _vm.mastertripDistance.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.mastertripDistance.meta.totalItem,
            "per-page": _vm.mastertripDistance.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getMasterTripDistance
          },
          model: {
            value: _vm.mastertripDistance.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.mastertripDistance.meta, "page", $$v);
            },
            expression: "mastertripDistance.meta.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Trip Distance data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.master_trip_type_edit
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.master_trip_type_edit ? 'Edit' : 'Add') + " Trip Distance")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveMasterTripDistance();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Port From",
            "label-for": "port-from"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.portOptions,
            "reduce": function reduce(obj) {
              return obj.value;
            },
            "placeholder": "Select port"
          },
          model: {
            value: _vm.formTripDistance.port_from_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "port_from_id", $$v);
            },
            expression: "formTripDistance.port_from_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Port To",
            "label-for": "port-to"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.portOptions,
            "reduce": function reduce(obj) {
              return obj.value;
            },
            "placeholder": "Select Port"
          },
          model: {
            value: _vm.formTripDistance.port_to_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "port_to_id", $$v);
            },
            expression: "formTripDistance.port_to_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Vehicle Types",
            "label-for": "vehicle-types"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "id": "vehicle-types",
            "placeholder": "Search vehicle types",
            "label": "name",
            "track-by": "id",
            "options": _vm.vehicleTypeOptions,
            "multiple": true
          },
          model: {
            value: _vm.formTripDistance.vehicle_type_ids,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "vehicle_type_ids", $$v);
            },
            expression: "formTripDistance.vehicle_type_ids"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Category not found.")])])], 1), _c('b-form-group', {
          attrs: {
            "label": "Units",
            "label-for": "units"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "units",
            "type": "text",
            "placeholder": "Type text"
          },
          model: {
            value: _vm.formTripDistance.units,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "units", $$v);
            },
            expression: "formTripDistance.units"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Distance",
            "label-for": "distance"
          }
        }, [_c('b-form-input', {
          attrs: {
            "required": "",
            "id": "distance",
            "type": "number",
            "placeholder": "Type number"
          },
          model: {
            value: _vm.formTripDistance.distance,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "distance", $$v);
            },
            expression: "formTripDistance.distance"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Target Minimal Days",
            "label-for": "target_min_days"
          }
        }, [_c('b-input-group', {
          attrs: {
            "name": "target_min_days"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "target_min_days",
            "type": "number",
            "placeholder": "type number"
          },
          model: {
            value: _vm.formTripDistance.target_min_days,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "target_min_days", $$v);
            },
            expression: "formTripDistance.target_min_days"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "disabled": ""
          }
        }, [_c('p', {
          staticClass: "m-0 text-sm"
        }, [_vm._v("Day")])])], 1)], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Target Maximal Days",
            "label-for": "target_max_days"
          }
        }, [_c('b-input-group', {
          attrs: {
            "name": "target_max_days"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "target_max_days",
            "type": "number",
            "placeholder": "type number"
          },
          model: {
            value: _vm.formTripDistance.target_max_days,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "target_max_days", $$v);
            },
            expression: "formTripDistance.target_max_days"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "disabled": ""
          }
        }, [_c('p', {
          staticClass: "m-0 text-sm"
        }, [_vm._v("Day")])])], 1)], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Target Minimal Minutes",
            "label-for": "target_min_minutes"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "target_min_minutes",
            "type": "number",
            "placeholder": "type number"
          },
          model: {
            value: _vm.formTripDistance.target_min_minutes,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "target_min_minutes", $$v);
            },
            expression: "formTripDistance.target_min_minutes"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Target Maximal Minutes",
            "label-for": "target_max_minutes"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "target_max_minutes",
            "type": "number",
            "placeholder": "type number"
          },
          model: {
            value: _vm.formTripDistance.target_max_minutes,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "target_max_minutes", $$v);
            },
            expression: "formTripDistance.target_max_minutes"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Minimum Speed",
            "label-for": "speed_min"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "speed_min",
            "type": "number",
            "placeholder": "Type number"
          },
          model: {
            value: _vm.formTripDistance.speed_max,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "speed_max", $$v);
            },
            expression: "formTripDistance.speed_max"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Maximal Speed",
            "label-for": "speed_max"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "speed_max",
            "type": "number",
            "placeholder": "type number"
          },
          model: {
            value: _vm.formTripDistance.speed_min,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "speed_min", $$v);
            },
            expression: "formTripDistance.speed_min"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Kpi Fuel Comsumption",
            "label-for": "kpi_fuel_consumptions"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "kpi_fuel_consumptions",
            "type": "number",
            "placeholder": "type number"
          },
          model: {
            value: _vm.formTripDistance.kpi_fuel_consumptions,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "kpi_fuel_consumptions", $$v);
            },
            expression: "formTripDistance.kpi_fuel_consumptions"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Kpi Fresh Water Comsumption",
            "label-for": "kpi_fresh_water_consumptions"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "kpi_fresh_water_consumptions",
            "type": "number",
            "placeholder": "type number"
          },
          model: {
            value: _vm.formTripDistance.kpi_fresh_water_consumptions,
            callback: function callback($$v) {
              _vm.$set(_vm.formTripDistance, "kpi_fresh_water_consumptions", $$v);
            },
            expression: "formTripDistance.kpi_fresh_water_consumptions"
          }
        })], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.master_trip_type_edit ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetformMastertrip();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-container fluid>
    <!-- Modal Section !-->
     <b-modal v-model="modal.filter" centered size="md" title="Filter Data" ok-title="Filter" @ok="fetchUsers(1)">
       <b-row>
         <b-col cols="12">
          <b-form-group
            label="Company"
            label-for="company"
            label-cols-sm="4"
            label-cols-lg="3"
            class="mb-3"
          >
          <v-select
            v-model="usersData.params.companyId"
            label="company"
            :options="companies"
            :reduce="company => company.id"
            placeholder="Select Company"
          ></v-select>
          </b-form-group>
         </b-col>
         <b-col cols="12">
          <b-form-group
            label="Status"
            label-for="status"
            label-cols-sm="4"
            label-cols-lg="3"
            class="mb-3"
          >
            <v-select
              v-model="usersData.params.active"
              label="text"
              :options="statuses"
              :reduce="status => status.value"
              placeholder="Select Status"
            ></v-select>
          </b-form-group>
         </b-col>
       </b-row>
     </b-modal>
    <!-- End Modal Section !-->
    <!-- Content Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>Users</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
          </template>
          <template v-slot:body>
            <div class="d-flex justify-content-between mb-3">
              <div class="d-flex align-items-center">
                <b-form-input size="lg" placeholder="search..." v-model="usersData.params.search"></b-form-input>
                <b-button variant="primary" class="mx-2" @click="fetchUsers(1)">
                  <i class="fa fa-search"></i>
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="d-flex align-items-center"
                  @click="toggleModalFilter"
                >
                  <i class="fas fa-sliders"></i>
                  <p class="text-truncate m-0"> Filter Data</p>
                </b-button>
              </div>
              <div class="d-flex">
                <div class="d-flex  align-items-center">
                  <strong>Display : &nbsp;</strong>
                  <div class="btn-group">
                  <button
                    type="button"
                    @click="view_style = 'list'"
                    class="btn btn-outline-primary"
                    :class="{ active: view_style === 'list' }"
                  ><span class="fa fa-list"></span> List
                  </button>
                  <button
                    type="button"
                    @click="view_style = 'grid'"
                    class="btn btn-outline-primary"
                    :class="{ active: view_style === 'grid' }"
                  ><span class="fa fa-th-large"></span> Grid
                  </button>
                </div>
                </div>
                <div style="border-left: 1.5px solid" class="mx-3"></div>
                <div class="d-flex align-items-center">
                  <router-link v-if="findMenuPermission(1, 'add', $route.query.menuId)" :to="{ name: 'setting.addNewUser', query: { menuObject: $route.query.menuObject } }"
                    ><b-button variant="success"
                      ><i class="fa fa-plus"></i>Add User</b-button
                    ></router-link
                  >
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <b-row class="border py-3">
                <b-col cols="3">
                  <b-form-group class="m-0" label="Company">
                    <p class="m-0 text-black" style="font-weight: 500;">{{ usersData.params.companyId ? companies.find((el)=> el.id == usersData.params.companyId).company : 'All' }}</p>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="m-0" label="Status">
                    <p class="m-0 text-black" style="font-weight: 500;">{{ typeof usersData.params.active == 'boolean' ?   statuses.find((el)=> el.value == usersData.params.active).text:'All'}}</p>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div v-if="usersData._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <template v-if="view_style === 'list'">
                <div class='d-flex overflow-auto'>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center" style="max-width:100px">PHOTO</th>
                        <th class="text-center">NAME</th>
                        <th class="text-center">ROLES</th>
                        <th class="text-center">COMPANY</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(usr, i) in usersData.data" :key="i">
                        <td class="text-center">
                          <router-link :to="{ name: 'setting.viewUser', params: { id: usr.id }, query: { menuObject: $route.query.menuObject } }">
                            <b-img
                              :rounded="'circle'"
                              :src="
                                usr.userDetail.photo
                                  | checkPhoto(
                                    require('@src/assets/images/user/01.jpg')
                                  )
                              "
                              :alt="usr.userDetail.name"
                              style="width:100px; height:100px"
                            />
                          </router-link>
                        </td>
                        <td class="text-center">
                          <div class="d-flex flex-column">
                            <router-link :to="{ name: 'setting.viewUser', params: { id: usr.id }, query: { menuObject: $route.query.menuObject } }">
                              <h6> {{ usr.userDetail.name }} </h6>
                            </router-link>
                            <strong class="mb-0">{{ usr.username }}</strong>
                          </div>
                        </td>
                        <td class="text-center">
                          <p class="mb-0">{{ usr.role.roles }}</p>
                        </td>
                        <td class="text-center">
                          <p class="mb-2">{{ usr.company.company }}</p>
                          <router-link :to="{ name: 'setting.viewUser', params: { id: usr.id }, query: { menuObject: $route.query.menuObject } }">{{
                            usr.company.location
                          }}</router-link>
                        </td>
                        <td v-if="findMenuPermission(1, 'update', $route.query.menuId)" class="text-center">
                          <router-link :to="{ name: 'setting.EditUser', params: { id: usr.id }, query: { menuObject: $route.query.menuObject } }">
                            <b-button variant="success" size="sm" class="mt-2">
                              <i class="fa fa-edit"></i> Edit
                            </b-button>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template v-if="view_style === 'grid'">
                <b-row v-if="usersData.data && usersData.data.length > 0">
                  <b-col v-for="(usr, i) in usersData.data" :key="i" sm="12" md="3">
                    <b-card>
                      <b-card-text>
                        <div class="text-center">
                          <router-link :to="{ name: 'setting.viewUser', params: { id: usr.id }, query: { menuObject: $route.query.menuObject } }">
                            <b-img
                              :rounded="'circle'"
                              :src="
                                usr.userDetail.photo
                                  | checkPhoto(
                                    require('@src/assets/images/user/01.jpg')
                                  )
                              "
                              :alt="usr.userDetail.name"
                              style="width:100px; height:100px"
                            />
                          </router-link>
                        </div>
                        <div class="text-center mt-3">
                          <router-link :to="{ name: 'setting.viewUser', params: { id: usr.id }, query: { menuObject: $route.query.menuObject } }"
                            ><h4> {{ usr.userDetail.name }} </h4></router-link
                          >
                          <strong class="mb-0">{{ usr.username }}</strong>
                          <p class="mb-0">{{ usr.role.roles }}</p>
                          <router-link :to="{ name: 'setting.viewUser', params: { id: usr.id }, query: { menuObject: $route.query.menuObject } }">{{
                            usr.company.location
                          }}</router-link>
                        </div>
                        <div class="text-center mt-2">
                          <p class="mb-0">{{ usr.company.company }}</p>
                          <p class="mb-0">
                            <b-badge :variant="usr.active ? 'warning' : 'danger'" class="ml-2" >
                              {{ usr.active ? 'Active' : 'Not Active' }}
                            </b-badge
                            >
                          </p>
                        </div>
                      </b-card-text>
                      <b-card-footer class="text-center"
                        v-if="findMenuPermission(1, 'update', $route.query.menuId)">
                        <router-link :to="{ name: 'setting.EditUser', params: { id: usr.id }, query: { menuObject: $route.query.menuObject } }">
                          <b-button variant="success" size="sm" class="mt-2"
                            ><i class="fa fa-edit"></i
                          > Edit</b-button>
                        </router-link>
                      </b-card-footer>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
              <div class="mt-4" v-if="usersData.data && usersData.data.length > 0">
                <b-pagination
                  v-model="usersData.meta.currentPage"
                  :total-rows="usersData.meta.total"
                  @change="fetchUsers"
                  :per-page="usersData.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
              <p v-else class="mb-0 text-muted text-center">No users data found.</p>
            </template>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Content Section !-->
  </b-container>
</template>
<script>
import { usersActions, companiesActions } from '@src/Utils/helper'
import _ from 'lodash'

export default {
  name: 'Users',
  data () {
    return {
      // Breadcrumb
      view_style:'list',
      breadcrumIcon: [
        {
          html: '<i class="ri-home-4-line mr-1 float-left"></i>Dashboard',
          href: '/ops/home',
          active: false
        },
        {
          html: 'Users',
          href: '#',
          active: true
        },
      ],
      params: {
        page: 1,
        companyId: '',
        active: '',
        search: ''
      },
      companies: [],
      statuses: [
        { value: '', text: 'All' },
        { value: true, text: 'Active' },
        { value: false, text: 'Non Active' },
      ],
      users: [],
      usersData: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          page: 1,
          companyId: '',
          active: '',
          search: '',
          perPage: 24
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      loading: true,
      modal:{
        filter:false
      }
    }
  },
  created () {
    this.fetchUsers = _.debounce(this.fetchUsers, 500)
  },
  mounted() {
    this.fetchUsers()
    this.fetchCompanies()
  },
  methods: {
    ...usersActions,
    ...companiesActions,
    async fetchUsers(page) {
      this.usersData.params.page = page || 1
      this.usersData._loading = true
      this.usersData.data = []
      let params = this.$options.filters.cleanObject(this.usersData.params)
      let res = await this.getUsers(params)

      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.usersData.data = res.data.data
          this.usersData.meta.perPage = res.data.perPage
          this.usersData.meta.currentPage = res.data.currentPage
          this.usersData.meta.total = res.data.total
          this.usersData.meta.totalPage = res.data.totalPage
        }
        this.usersData._loading = false
      } else {
        this.usersData._loading = false
        this.$swal(
          `Oops!`,
          res.message,
          'error'
        )
      }
    },
    async fetchCompanies() {
      let res = await this.getCompanies()
      this.companies = res.data
      this.companies.unshift({
        company: 'All',
        id: '',
        location: ''
      });
    },  
    toggleModalFilter(){
      this.modal.filter = !this.modal.filter
    },
  },
}
</script>
<style scoped>
table th{
  background: #334697 !important;
  color: white;
}
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "maintenance-history-record"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Maintenance History Record")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [!_vm.preview ? _c('b-row', {
          staticClass: "px-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ship Ownership",
            "label-for": "ship_ownership"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "placeholder": "Select...",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          },
          model: {
            value: _vm.params.ship_ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "ship_ownership", $$v);
            },
            expression: "params.ship_ownership"
          }
        }, [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("All")]), _c('option', {
          attrs: {
            "value": "owned"
          }
        }, [_vm._v("Owned by Company")])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company",
            "label-for": "company"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": [],
            "placeholder": "Select..."
          },
          model: {
            value: _vm.params.company_id,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "company_id", $$v);
            },
            expression: "params.company_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet",
            "label-for": "fleet-equipment"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": [],
            "placeholder": "Select..."
          },
          model: {
            value: _vm.params.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "vehicle_id", $$v);
            },
            expression: "params.vehicle_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('button', {
          staticClass: "btn",
          style: {
            backgroundColor: '#2BDCAA',
            color: 'white',
            marginTop: '30px'
          },
          attrs: {
            "type": "button",
            "block": ""
          },
          on: {
            "click": _vm.onFilter
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])])], 1) : _vm._e(), !_vm.preview ? _c('div', {
          staticClass: "d-flex w-100 border my-2"
        }) : _vm._e(), !_vm.preview ? _c('b-row', [_c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "4"
          }
        }, [_c('b-card', {
          staticClass: "rounded-lg shadow"
        }, [_c('img', {
          staticClass: "w-100",
          attrs: {
            "src": require('../../assets/images/example-fleets.png'),
            "alt": "f",
            "height": "240"
          }
        }), _c('div', {
          staticClass: "d-flex w-100 border my-2"
        }), [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between mb-2"
        }, [_c('h5', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("TB. SML 301 🇮🇩")])]), _c('b-badge', {
          staticClass: "p-2",
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v(" SOLD ")])], 1), _c('div', {
          staticClass: "d-flex flex-wrap mb-2"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("Tug boat")])]), _c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("PT. Sentra Makmur Lines")])]), _c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("Owned")])])]), _c('div', {
          staticClass: "d-flex align-items-center justify-content-between p-2 rounded-lg mb-2",
          staticStyle: {
            "background-color": "#F9FAFB"
          }
        }, [_c('span', {
          staticClass: "text-black"
        }, [_vm._v("Maintenance Planned / Initiation")]), _c('strong', {
          staticClass: "text-black"
        }, [_vm._v("130 Plan")])])], _c('b-button', {
          staticClass: "w-100 align-items-center px-2",
          staticStyle: {
            "padding": "10px 0"
          },
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.onDetail(null, !_vm.preview);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-center"
        }, [_c('DetailHistoryIcon', {
          staticClass: "mr-2",
          staticStyle: {
            "height": "20px",
            "width": "20px"
          }
        }), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Detail Maintenance")])], 1)])], 2)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "4"
          }
        }, [_c('b-card', {
          staticClass: "rounded-lg shadow"
        }, [_c('img', {
          staticClass: "w-100",
          attrs: {
            "src": require('../../assets/images/example-fleets.png'),
            "alt": "f",
            "height": "240"
          }
        }), _c('div', {
          staticClass: "d-flex w-100 border my-2"
        }), [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between mb-2"
        }, [_c('h5', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("TB. SML 301 🇮🇩")])]), _c('b-badge', {
          staticClass: "p-2",
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" SINKED ")])], 1), _c('div', {
          staticClass: "d-flex flex-wrap mb-2"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("Tug boat")])]), _c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("PT. Sentra Makmur Lines")])]), _c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("Owned")])])]), _c('div', {
          staticClass: "d-flex align-items-center justify-content-between p-2 rounded-lg mb-2",
          staticStyle: {
            "background-color": "#F9FAFB"
          }
        }, [_c('span', {
          staticClass: "text-black"
        }, [_vm._v("Maintenance Planned / Initiation")]), _c('strong', {
          staticClass: "text-black"
        }, [_vm._v("130 Plan")])])], _c('b-button', {
          staticClass: "w-100 align-items-center px-2",
          staticStyle: {
            "padding": "10px 0"
          },
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.onDetail(null, !_vm.preview);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-center"
        }, [_c('DetailHistoryIcon', {
          staticClass: "mr-2",
          staticStyle: {
            "height": "20px",
            "width": "20px"
          }
        }), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Detail Maintenance")])], 1)])], 2)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "4"
          }
        }, [_c('b-card', {
          staticClass: "rounded-lg shadow"
        }, [_c('img', {
          staticClass: "w-100",
          attrs: {
            "src": require('../../assets/images/example-fleets.png'),
            "alt": "f",
            "height": "240"
          }
        }), _c('div', {
          staticClass: "d-flex w-100 border my-2"
        }), [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between mb-2"
        }, [_c('h5', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("TB. SML 301 🇮🇩")])])]), _c('div', {
          staticClass: "d-flex flex-wrap mb-2"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("Tug boat")])]), _c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("PT. Sentra Makmur Lines")])]), _c('div', {
          staticClass: "d-flex align-items-center mr-2"
        }, [_c('div', {
          staticClass: "mx-2",
          staticStyle: {
            "height": "6px",
            "width": "6px",
            "border-radius": "50%",
            "background-color": "black"
          }
        }), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("Owned")])])]), _c('div', {
          staticClass: "d-flex align-items-center justify-content-between p-2 rounded-lg mb-2",
          staticStyle: {
            "background-color": "#F9FAFB"
          }
        }, [_c('span', {
          staticClass: "text-black"
        }, [_vm._v("Maintenance Planned / Initiation")]), _c('strong', {
          staticClass: "text-black"
        }, [_vm._v("130 Plan")])])], _c('b-button', {
          staticClass: "w-100 align-items-center px-2",
          staticStyle: {
            "padding": "10px 0"
          },
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.onDetail(null);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-center"
        }, [_c('DetailHistoryIcon', {
          staticClass: "mr-2",
          staticStyle: {
            "height": "20px",
            "width": "20px"
          }
        }), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Detail Maintenance")])], 1)])], 2)], 1)], 1) : _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "text-primary d-flex align-items-center",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onDetail(null);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v(" Back ")]), _c('MaintenanceHistoryRecordDetail')], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
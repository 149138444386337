var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_vm.incident ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('StepWizard', {
    attrs: {
      "current-step": "RECOMMENDATION",
      "claim-type": _vm.incident.type_form === 'non_claim' ? 'NON_CLAIM' : 'CLAIM'
    }
  }), _c('div', {
    staticClass: "col-md-12 mb-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/mi/list-initial');
      }
    }
  }, [_vm._v("Back")])], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("Temporary Recommendation")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$forms;
        return [_c('b-row', [(_vm$forms = _vm.forms) !== null && _vm$forms !== void 0 && _vm$forms.length ? _vm._l(_vm.forms, function (item, i) {
          var _vm$linkToCaseOptions, _vm$linkToCaseOptions2;
          return _c('b-col', {
            key: i,
            attrs: {
              "md": "12"
            }
          }, [_c('div', {
            staticClass: "p-2 px-3 d-flex align-items-center justify-content-between item-temp cursor-pointer",
            style: {
              fontWeight: '600',
              background: _vm.tempIdx === i ? '#C7EBFF' : ''
            },
            on: {
              "click": function click($event) {
                return _vm.selectTemp(i);
              }
            }
          }, [_c('p', {
            staticClass: "m-0 text-black"
          }, [_vm._v(_vm._s((_vm$linkToCaseOptions = (_vm$linkToCaseOptions2 = _vm.linkToCaseOptions.find(function (el) {
            return el.value == item.form_id;
          })) === null || _vm$linkToCaseOptions2 === void 0 ? void 0 : _vm$linkToCaseOptions2.text) !== null && _vm$linkToCaseOptions !== void 0 ? _vm$linkToCaseOptions : '-'))]), _c('i', {
            staticClass: "fas fa-close text-danger cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteTemporary(i);
              }
            }
          })])]);
        }) : [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('h6', [_vm._v(" No Recomendation Found ")])])], _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "w-100",
          attrs: {
            "variant": "success",
            "disabled": !_vm.forms.length
          },
          on: {
            "click": function click($event) {
              return _vm.onSave();
            }
          }
        }, [_vm._v(" Submit ")])], 1)], 2)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("Form Recommendation")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveTemp.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Link to Case",
            "label-for": "link-case"
          }
        }, [_c('v-select', {
          attrs: {
            "id": "link-case",
            "placeholder": "Select Link To Case",
            "options": _vm.linkToCaseOptions,
            "label": "text",
            "reduce": function reduce(type) {
              return type.value;
            },
            "clearable": false
          },
          model: {
            value: _vm.formInitialInput.form_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "form_id", $$v);
            },
            expression: "formInitialInput.form_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Cause of Loss",
            "label-for": "couse-of-lose"
          }
        }, [_c('v-select', {
          attrs: {
            "id": "couse-of-lose",
            "placeholder": "Select Cause of Loss",
            "options": _vm.causeOfLossOptions,
            "label": "name",
            "clearable": false
          },
          model: {
            value: _vm.formInitialInput.cause_of_loss_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "cause_of_loss_id", $$v);
            },
            expression: "formInitialInput.cause_of_loss_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('v-select', {
          attrs: {
            "id": "status",
            "placeholder": "Select Status",
            "options": _vm.statusOptions,
            "label": "name",
            "clearable": false
          },
          model: {
            value: _vm.formInitialInput.status,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "status", $$v);
            },
            expression: "formInitialInput.status"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Closing Date",
            "label-for": "closing-date"
          }
        }, [_c('date-picker', {
          staticClass: "w-100",
          attrs: {
            "id": "closing-date",
            "name": "closedDate",
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "format": "DD MMM YYYY",
            "placeholder": "Select date"
          },
          model: {
            value: _vm.formInitialInput.closed_at,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "closed_at", $$v);
            },
            expression: "formInitialInput.closed_at"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Notif To Department",
            "label-for": "notif"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "id": "notif",
            "placeholder": "Notif",
            "label": "name",
            "track-by": "id",
            "options": _vm.notifOptions,
            "multiple": true
          },
          model: {
            value: _vm.formInitialInput.department_ids,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "department_ids", $$v);
            },
            expression: "formInitialInput.department_ids"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Data not found.")])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Problem / Issue",
            "label-for": "problem"
          }
        }, [_c('wysiwyg', {
          staticClass: "text-black",
          style: {
            minHeight: '250px'
          },
          attrs: {
            "id": "problem"
          },
          model: {
            value: _vm.formInitialInput.problem,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "problem", $$v);
            },
            expression: "formInitialInput.problem"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Vessel Case",
            "label-for": "vessel-case"
          }
        }, [_c('wysiwyg', {
          staticClass: "text-black",
          style: {
            minHeight: '250px'
          },
          attrs: {
            "id": "vessel-case"
          },
          model: {
            value: _vm.formInitialInput.vessel_case,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "vessel_case", $$v);
            },
            expression: "formInitialInput.vessel_case"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Root Cause",
            "label-for": "root-case"
          }
        }, [_c('wysiwyg', {
          staticClass: "text-black",
          style: {
            minHeight: '250px'
          },
          attrs: {
            "id": "root-case"
          },
          model: {
            value: _vm.formInitialInput.root_cause,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "root_cause", $$v);
            },
            expression: "formInitialInput.root_cause"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Recommendation",
            "label-for": "rekomendasi"
          }
        }, [_c('wysiwyg', {
          staticClass: "text-black",
          style: {
            minHeight: '250px'
          },
          attrs: {
            "id": "rekomendasi"
          },
          model: {
            value: _vm.formInitialInput.recommendation,
            callback: function callback($$v) {
              _vm.$set(_vm.formInitialInput, "recommendation", $$v);
            },
            expression: "formInitialInput.recommendation"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "d-flex",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "w-100",
          attrs: {
            "variant": "outline-primary mr-2",
            "type": "button"
          },
          on: {
            "click": _vm.onClearForm
          }
        }, [_vm._v("Clear Form")]), _c('b-button', {
          staticClass: "w-100",
          attrs: {
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm._v(_vm._s(_vm.isEditTemp ? 'Update' : 'Save to') + " Temporary Recomendation")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#position",
            "title": "MASTER DATA POSITION"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#department",
            "title": "MASTER DATA DEPARTMENT"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "position"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('update') && !_vm.menuPermission('add') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List Master Data Position")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "d-flex mb-3"
        }, [_c('b-form-input', {
          staticStyle: {
            "width": "240px"
          },
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.positions.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.positions.params, "search", $$v);
            },
            expression: "positions.params.search"
          }
        }), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchPositions(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _vm.menuPermission('export') ? _c('div', [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doExportPositions
          }
        }, [!_vm.loading_export_positions ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2)], 1) : _vm._e()]), _vm.positions._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.positions.data && _vm.positions.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Job Code")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Parent")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Department")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.positions.data, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.jobCode))]), _c('td', [_vm._v(_vm._s(p.parentId))]), _c('td', [_vm._v(_vm._s(p.departmentId))]), _c('td', {
            staticClass: "d-flex"
          }, [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditPositions(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeletePositions(p);
              }
            }
          }, [!_vm.loading_delete_positions ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v(" Deleting... ")]], 2) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), _vm.positions.data && _vm.positions.data.length > 0 ? _c('div') : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No positions data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_positions_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v(_vm._s(_vm.form_positions_edit_mode ? 'Edit' : 'Add') + " Positions")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$positions;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormPositions.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Position name",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text",
            "maxlength": "30"
          },
          model: {
            value: _vm.form_positions.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form_positions, "name", $$v);
            },
            expression: "form_positions.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Job Code",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text",
            "maxlength": "30"
          },
          model: {
            value: _vm.form_positions.jobCode,
            callback: function callback($$v) {
              _vm.$set(_vm.form_positions, "jobCode", $$v);
            },
            expression: "form_positions.jobCode"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Parent",
            "label-for": "name"
          }
        }, [_c('v-select', {
          attrs: {
            "options": (_vm$positions = _vm.positions) === null || _vm$positions === void 0 ? void 0 : _vm$positions.data,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "clearable": true,
            "placeholder": "Select..."
          },
          model: {
            value: _vm.form_positions.parentId,
            callback: function callback($$v) {
              _vm.$set(_vm.form_positions, "parentId", $$v);
            },
            expression: "form_positions.parentId"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Department",
            "label-for": "name"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.optionsDepartment,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "value": "id",
            "clearable": true,
            "placeholder": "Select..."
          },
          model: {
            value: _vm.form_positions.departmentId,
            callback: function callback($$v) {
              _vm.$set(_vm.form_positions, "departmentId", $$v);
            },
            expression: "form_positions.departmentId"
          }
        })], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_positions ? _c('span', [_vm._v(_vm._s(_vm.form_positions_edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormPositions.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3455373029)
  })], 1) : _vm._e()], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "department"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('update') && !_vm.menuPermission('add') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List Master Department")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex mb-3 justify-content-between"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          staticStyle: {
            "width": "240px"
          },
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.department.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.department.params, "search", $$v);
            },
            expression: "department.params.search"
          }
        }), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchDepartment(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('div', [_vm.menuPermission('export') ? _c('div', [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doExportDepartment
          }
        }, [!_vm.loading_export_department ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2)], 1) : _vm._e()])]), _vm.department._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.department.data && _vm.department.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Active")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Induction")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("MI")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.department.data, function (r, i) {
          return _c('tr', {
            key: "r-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(i + 1))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(r.name))]), _c('td', {
            staticClass: "text-capitalize text-truncate"
          }, [_vm._v(_vm._s(r.is_active ? 'Active' : 'Inactive'))]), _c('td', {
            staticClass: "text-capitalize text-truncate"
          }, [_vm._v(_vm._s(r.is_induction ? 'Yes' : 'No'))]), _c('td', {
            staticClass: "text-capitalize text-truncate"
          }, [_vm._v(_vm._s(r.is_mi ? 'Yes' : 'No'))]), _c('td', {
            staticClass: "d-flex"
          }, [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditDepartment(r);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteDepartment(r);
              }
            }
          }, [!_vm.loading_delete_department ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v(" Deleting... ")]], 2) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), _vm.department.data && _vm.department.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.department.meta.total,
            "per-page": _vm.department.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.fetchDepartment
          },
          model: {
            value: _vm.department.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.department.meta, "currentPage", $$v);
            },
            expression: "department.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No department data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_department_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_department_edit_mode ? 'Edit' : 'Add') + " Department (Idle)")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormDepartment.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Department Name",
            "label-for": "Department_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "port_name",
            "type": "text",
            "placeholder": "Department Name"
          },
          model: {
            value: _vm.form_department.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form_department, "name", $$v);
            },
            expression: "form_department.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "statusDept"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "switch": "",
            "value": 1,
            "unchecked-value": 0
          },
          model: {
            value: _vm.form_department.is_active,
            callback: function callback($$v) {
              _vm.$set(_vm.form_department, "is_active", $$v);
            },
            expression: "form_department.is_active"
          }
        }, [_vm._v(" Active / Inactive ")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Is Induction?",
            "label-for": "induction"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "switch": "",
            "value": 1,
            "unchecked-value": 0
          },
          model: {
            value: _vm.form_department.is_induction,
            callback: function callback($$v) {
              _vm.$set(_vm.form_department, "is_induction", $$v);
            },
            expression: "form_department.is_induction"
          }
        }, [_vm._v(" Yes / No ")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Is MI?",
            "label-for": "mi"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "switch": "",
            "value": 1,
            "unchecked-value": 0
          },
          model: {
            value: _vm.form_department.is_mi,
            callback: function callback($$v) {
              _vm.$set(_vm.form_department, "is_mi", $$v);
            },
            expression: "form_department.is_mi"
          }
        }, [_vm._v(" Yes / No ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_department ? _c('span', [_vm._v(_vm._s(_vm.form_department_edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormDepartment.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3031364672)
  })], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
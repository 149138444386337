<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#position"
                  title="MASTER DATA POSITION"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#department"
                  title="MASTER DATA DEPARTMENT"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="position">
        <b-row>
          <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"><strong>List Master Data Position</strong></h4>
              </template>
              <template v-slot:body>
                <div class="d-flex justify-content-between">                  
                  <div class="d-flex mb-3">
                    <b-form-input
                      id="fleet_name"
                      type="text"
                      placeholder="Search..."
                      v-model="positions.params.search"
                      style="width: 240px;"
                    ></b-form-input>
                    <b-button
                      variant="primary"
                      class="ml-2"
                      @click="fetchPositions(1)"
                      ><i class="fa fa-search"></i
                    ></b-button>
                  </div>
                  <div
                    v-if="menuPermission('export')">
                    <b-button variant="primary" @click="doExportPositions">
                      <template v-if="!loading_export_positions">
                        <i class="fa fa-file-excel"></i> &nbsp; Export
                      </template>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Exporting...
                      </template>
                    </b-button>
                  </div>
                </div>
                <div v-if="positions._loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="positions.data && positions.data.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">No</th>
                          <th width="20%">Name</th>
                          <th width="20%">Job Code</th>
                          <th width="15%">Parent</th>
                          <th width="15%">Department</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(p, i) in positions.data" :key="`p-${i}`">
                          <td class="text-center">{{ i + 1}}</td>
                          <td>{{ p.name }}</td>
                          <td>{{ p.jobCode }}</td>
                          <td>{{ p.parentId }}</td>
                          <td>{{ p.departmentId }}</td>
                          <td class="d-flex">
                            <b-button
                              v-if="menuPermission('update')"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditPositions(p)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="menuPermission('delete')"
                              variant="danger"
                              size="sm"
                              @click="onDeletePositions(p)"
                            >
                              <i
                                v-if="!loading_delete_positions"
                                class="fa fa-trash"
                              ></i>
                              <template v-else>
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Deleting...
                              </template>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="positions.data && positions.data.length > 0">
                    <!-- <b-pagination
                      v-model="positions.meta.currentPage"
                      :total-rows="positions.meta.total"
                      @change="fetchPositions"
                      :per-page="positions.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination> -->
                  </div>
                  <p v-else class="text-center my-4">No positions data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4"
            v-if="menuPermission('update') || menuPermission('add')">
            <iq-card
              :class="{
                'border border-danger shadow-lg': form_positions_edit_mode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                  ><strong>{{ form_positions_edit_mode ? 'Edit' : 'Add' }} Positions</strong></h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="submitFormPositions">
                  <b-form-group
                    label="Position name"
                    label-for="name"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Type Text"
                      maxlength="30"
                      v-model="form_positions.name"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Job Code"
                    label-for="name"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Type Text"
                      maxlength="30"
                      v-model="form_positions.jobCode"
                    ></b-form-input>
                  </b-form-group> 
                  
                  <b-form-group
                    label="Parent"
                    label-for="name"
                  >
                    <v-select
                      :options="positions?.data"
                      v-model="form_positions.parentId"
                      :reduce="option => option.id"
                      label="name"
                      :clearable="true"
                      placeholder="Select..."
                    ></v-select>
                  </b-form-group> 

                  <b-form-group
                    label="Department"
                    label-for="name"
                  >
                    <v-select
                      :options="optionsDepartment"
                      v-model="form_positions.departmentId"
                      :reduce="option => option.id"
                      label="name"
                      value="id"
                      :clearable="true"
                      placeholder="Select..."
                    ></v-select>
                  </b-form-group> 

                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_positions">{{
                        form_positions_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormPositions"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="department">
        <b-row>
          <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"><strong>List Master Department</strong></h4>
              </template>
              <template v-slot:body>
                <div class="d-flex mb-3 justify-content-between" >
                  <div class="d-flex">
                    <b-form-input
                      id="fleet_name"
                      style="width: 240px;"
                      type="text"
                      placeholder="Search..."
                      v-model="department.params.search"
                    ></b-form-input>
                    <b-button
                      variant="primary"
                      class="ml-2"
                      @click="fetchDepartment(1)"
                      ><i class="fa fa-search"></i
                    ></b-button>
                  </div>
                  <div>
                    <div
                      v-if="menuPermission('export')">
                      <b-button @click="doExportDepartment" variant="primary">
                        <template v-if="!loading_export_department">
                          <i class="fa fa-file-excel"></i> &nbsp; Export
                        </template>
                        <template v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Exporting...
                        </template>
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-if="department._loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="department.data && department.data.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">No</th>
                          <th width="20%">Name</th> 
                          <th width="10%">Active</th>
                          <th width="10%">Induction</th>
                          <th width="10%">MI</th>
                          <th width="10%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(r, i) in department.data" :key="`r-${i}`">
                          <td class="text-center">{{ i + 1 }}</td>
                          <td class="text-truncate">{{ r.name }}</td>
                          <td class="text-capitalize text-truncate">{{ r.is_active ? 'Active' : 'Inactive' }}</td>
                          <td class="text-capitalize text-truncate">{{ r.is_induction ? 'Yes' : 'No' }}</td>
                          <td class="text-capitalize text-truncate">{{ r.is_mi ? 'Yes' : 'No' }}</td>
                          <td class="d-flex">
                            <b-button
                              v-if="menuPermission('update')"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditDepartment(r)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="menuPermission('delete')"
                              variant="danger"
                              size="sm"
                              @click="onDeleteDepartment(r)"
                            >
                              <i
                                v-if="!loading_delete_department"
                                class="fa fa-trash"
                              ></i>
                              <template v-else>
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Deleting...
                              </template>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="department.data && department.data.length > 0">
                    <b-pagination
                      v-model="department.meta.currentPage"
                      :total-rows="department.meta.total"
                      @change="fetchDepartment"
                      :per-page="department.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No department data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4"
            v-if="menuPermission('update') || menuPermission('add')">
            <iq-card
              :class="{
                'border border-danger shadow-lg': form_department_edit_mode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title"
                  >{{ form_department_edit_mode ? 'Edit' : 'Add' }} Department
                  (Idle)</h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="submitFormDepartment">
                  <b-form-group label="Department Name" label-for="Department_name">
                    <b-form-input
                      id="port_name"
                      type="text"
                      placeholder="Department Name"
                      v-model="form_department.name"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Status"
                    label-for="statusDept"
                  >
                    <b-form-checkbox
                      v-model="form_department.is_active"
                      switch
                      :value="1"
                      :unchecked-value="0"
                    >
                      Active / Inactive
                    </b-form-checkbox>
                  </b-form-group> 
                  <b-form-group
                    label="Is Induction?"
                    label-for="induction"
                  >
                    <b-form-checkbox
                      v-model="form_department.is_induction"
                      switch
                      :value="1"
                      :unchecked-value="0"
                    >
                      Yes / No
                    </b-form-checkbox>
                  </b-form-group> 
                  <b-form-group
                    label="Is MI?"
                    label-for="mi"
                  >
                    <b-form-checkbox
                      v-model="form_department.is_mi"
                      switch
                      :value="1"
                      :unchecked-value="0"
                    >
                      Yes / No
                    </b-form-checkbox>
                  </b-form-group> 
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_department">{{
                        form_department_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="submit"
                      variant="none"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormDepartment"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </b-container>
</template>

<script>
import { saveAs } from 'file-saver'
import {
  PositionssActions,
  fleetsActions,
  busdevActions,
  crewingActions,
  opsV2Actions,
} from '@src/Utils/helper'
import _ from 'lodash'

export default {
  name: 'MasterPositionDepartment',
  components:{ },
  data () {
    return {
      optionsDepartment:[],

      positions: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          active: true,
          perPage: 50,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      department: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          active: true,
          perPage: 100,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      form_positions_edit_mode: false,
      loading_export_positions: false,
      loading_delete_positions: false,
      loading_form_positions: false,
      form_department_edit_mode: false,
      loading_export_department: false,
      loading_delete_department: false,
      loading_form_department: false,
      form_positions: {
        "name": "",
        "jobCode": "",
        "parentId": null,
        "departmentId": null
      },
      selected_positions: {},
      form_department: {
        name: '',
        is_active: 1,
        is_induction: 0,
        is_mi: 0,
      },
    
      selected_department: {}
    }
  },
  created () {
    this.fetchPositions = _.debounce(this.fetchPositions, 500)
    this.fetchDepartment = _.debounce(this.fetchDepartment, 500)
  },
  mounted () {
    this.fetchOptionsDepartment()
    this.fetchPositions()
    this.fetchDepartment()
  },
  computed:{ },
  methods: {
    ...fleetsActions,
    ...busdevActions,
    ...crewingActions,
    ...opsV2Actions,
    async fetchOptionsDepartment () {
      let params = {
        page:1,
        perPage:999999
      }

      let res = await this.fetchMasterDepartmentList(params)
      this.optionsDepartment = res?.data?.data || []
    }, 

    async fetchPositions (page) {
      this.positions.params.page = page || 1
      this.positions._loading = true
      this.positions.data = []
      let params = this.$options.filters.cleanObject(this.positions.params)

      let res = await this.getListPositions(params)
      console.log(res)
      if (res.status == 'success' || res.data.status == true) {
        this.positions.data = res?.data?.data || []
        console.log(this.positions.data, 'his.positions.data')
        this.positions._loading = false
      } else {
        this.positions._loading = false
        this.$swal(
          `Oops!`,
          res?.data?.message,
          'error'
        )
      }
    },
    async fetchDepartment (page) {
      this.department.params.page = page || 1
      this.department._loading = true
      this.department.data = []
      let params = this.$options.filters.cleanObject(this.department.params)

      let res = await this.fetchMasterDepartmentList(params)
      if (res.status == 'success' || res.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.department.data = res.data.data
          this.department.meta.perPage = res.data.per_page
          this.department.meta.currentPage = res.data.current_page
          this.department.meta.total = res.data.total
          this.department.meta.totalPage = res.data.total
        }
        this.department._loading = false
      } else {
        this.department._loading = false
        this.$swal(
          `Oops!`,
          res?.data?.message,
          'error'
        )
      }
    },
    async doExportPositions () {
      this.loading_export_positions = true
      // let res = await this.exportPositionss()
      // if (res.status == 'success') {
      //   this.loading_export_positions = false
      //   saveAs(res.data, 'Positions List.xlsx')
      // } else {
      //   this.loading_export_positions = false
      //   if (res?.data?.message != null) { this.$swal('Error', res?.data?.message, 'error') } else {
      //     this.$swal(
      //       'Error',
      //       'Something wrong with the server. Please try again later.',
      //       'error'
      //     )
      //   }
      // }
    },
    async doExportDepartment () {
      this.loading_export_department = true
      // let res = await this.exportDepartments()
      // if (res.status == 'success') {
      //   this.loading_export_department = false
      //   saveAs(res.data, 'Department List.xlsx')
      // } else {
      //   this.loading_export_department = false
      //   if (res?.data?.message != null) { this.$swal('Error', res?.data?.message, 'error') } else {
      //     this.$swal(
      //       'Error',
      //       'Something wrong with the server. Please try again later.',
      //       'error'
      //     )
      //   }
      // }
    },
    async submitFormPositions () {
      this.loading_form_positions = true

      let payload = {
        id: this.selected_positions.id,
        data: {
          ...this.form_positions,
        }
      }

      let form_has_empty_value = false
      for (var key in payload.data) {
        if (payload.data[key] === '') form_has_empty_value = true
        else if (Array.isArray(payload.data[key]) && !payload.data[key].length) { form_has_empty_value = true }
      }

      if (form_has_empty_value) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {

        if (!this.form_positions_edit_mode) {
          let res = await this.createPositions(payload)

          if (res.status == 'success') {
            this.resetFormPositions()
            this.fetchPositions()
            this.$swal(
              'Positions added!',
              'Positions added successfully.',
              'success'
            )
          } else {
            if (res?.data?.message != null) { this.$swal('Error', res?.data?.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        } else {
          let res = await this.updatePositions(payload)
          if (res.status == 'success') {
            this.resetFormPositions()
            this.fetchPositions()
            this.$swal(
              'Positions updated!',
              'Positions updated successfully.',
              'success'
            )
          } else {
            if (res?.data?.message != null) { this.$swal('Error', res?.data?.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        }
      }
      this.loading_form_positions = false
    },
    async submitFormDepartment () {
      this.loading_form_department = true

      let payload = {
        ...this.form_department,
        // status:'active'
      }

      let form_has_empty_value = false
      for (var key in payload) {
        if (payload[key] === '') form_has_empty_value = true
        else if (Array.isArray(payload[key]) && !payload[key].length) { form_has_empty_value = true }
      }

      if (form_has_empty_value) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {
        if (!this.form_department_edit_mode) {
          let res = await this.createDepartment({data: payload})
          if (res.status == 'success') {
            this.resetFormDepartment()
            this.fetchDepartment()
            this.$swal('Department added!', 'Department added successfully.', 'success')
          } else {
            if (res?.data?.message != null) { this.$swal('Error', res?.data?.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        } else {
          let res = await this.updateDepartment({
            id: this.selected_department.id,
            data: payload
          })
          if (res.status == 'success') {
            this.resetFormDepartment()
            this.fetchDepartment()
            this.$swal(
              'Department updated!',
              'Department updated successfully.',
              'success'
            )
          } else {
            if (res?.data?.message != null) { this.$swal('Error', res?.data?.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        }
      }
      this.loading_form_department = false
    },
    onEditPositions (state) {
      this.form_positions_edit_mode = true
      this.selected_positions = state
      this.form_positions = {
        "name": state?.name,
        "jobCode": state?.jobCode,
        "parentId": state?.parentId,
        "departmentId": state?.departmentId
      }
    },
    onEditDepartment (state) {
      this.form_department_edit_mode = true
      this.selected_department = state
      this.form_department = {
        name: state.name,
        is_active: state.is_active,
        is_induction: state.is_induction,
        is_mi: state.is_mi,
      }
    },
    onDeletePositions (state) {
      this.$swal({
        title: 'Delete Positions?',
        text: `Positions ${state.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_positions = true
          let res = await this.deletePositions(state.id)
          if (res.status != 'success') {
            this.loading_delete_positions = false
            if (res?.data?.message != null) { this.$swal('Error', res?.data?.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.loading_delete_positions = false
            this.fetchPositions()
            this.$swal(
              `Positions deleted!`,
              `Positions ${state.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    onDeleteDepartment (state) {
      this.$swal({
        title: 'Delete Department?',
        text: `Department ${state.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_department = true
          let res = await this.deleteDepartment(state.id)
          if (res.status != 'success') {
            this.loading_delete_department = false
            if (res?.data?.message != null) { this.$swal('Error', res?.data?.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.loading_delete_department = false
            this.fetchDepartment()
            this.$swal(
              `Department deleted!`,
              `Department ${Department.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    addSubPositions () {
      this.form_positions.positions.subPositions.push({ name: '' })
    },
    deleteSubPositions (index) {
      this.form_positions.positions.subPositions.splice(index, 1)
    },
    resetFormPositions () {
      this.form_positions_edit_mode = false
      this.form = {
        "name": "",
        "jobCode": "",
        "parentId": null,
        "departmentId": null
      }

      this.customerDetail = null
      this.editIdCustomer = null
    },
    resetFormDepartment () {
      this.form_department_edit_mode = false
      this.form_department = {
        name: '',
        is_active: 1,
        is_induction: 0,
        is_mi: 0,
      }
    },
    menuPermission (output) {
      const { menuObject } = this.$route.query

      if (!menuObject) {
        return true
      }

      return true

      // return JSON.parse(window.atob(menuObject))[output]
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <b-container fluid>
    <form @submit.prevent="onSaveTripDocument">
      <b-row>
        <b-col md="12">
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit" v-if="['EDIT', 'ADD'].includes(action)"><i class="fa fa-save"></i> Create Trip Document&nbsp; </b-button>
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormTripDocument"><i class="fa fa-ban"></i> Close&nbsp; </b-button>
        </b-col>
        <b-col md="12" class="p-4">
          <b-row>
            <b-col md="6">
              <b-form-group label="Document Name">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.name_document"  />
                <strong v-if="['PREVIEW'].includes(action)">{{form.name_document}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="projectData?.project_contract_plan?.length > 0">
              <b-form-group label="For Trip">
                <v-select
                  v-if="['EDIT', 'ADD'].includes(action)"
                  label="shipment_plan_detail_number"
                  track-by="id"
                  name="plan_id"
                  v-model="form.plan_id"
                  :options="projectData?.project_contract_plan"
                  :reduce="(plan) => plan.id"
                  placeholder="Select..."
                  @input="getProjectPlanById"
                  required
                ></v-select>
                <strong v-if="['PREVIEW'].includes(action)">{{projectData?.project_contract_plan?.find(obj => obj.id === form.plan_id)?.shipment_plan_detail_number}}</strong>
              </b-form-group>
            </b-col>
            
            <b-col md="12">
              <b-form-group label="Documents">
                <b-form-file v-if="['EDIT', 'ADD'].includes(action)" accept="application/*" multiple v-model="form.document" name='document' id="document" class="border p-2"></b-form-file>
                <div class="d-flex flex-column" v-if="tripData?.trip_attachment?.length > 0 && ['EDIT', 'PREVIEW'].includes(action)">
                  <div class="d-flex align-items-center mb-1" style="cursor:pointer" v-for="(file, fileIndex) in tripData?.trip_attachment" :key="fileIndex"  @click="handleOpenFilePreview(`${apiUrl}/${file?.file_location}`, file.file_name)">
                    <i class="fa fa-file text-primary mr-2" />
                    <span class="text-primary text-truncate">{{file.file_name}}</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- DETAIL PLAN -->
            <b-col lg="12" v-if="projectPlan && form.plan_id">
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary">Project Plan</legend>
                <div class="d-flex w-100 overflow-auto">
                  <table class="table table-bordered table-sm table-responsive">
                    <thead>
                      <tr>
                        <th style="width:5px" rowspan="2" class="text-center text-truncate align-middle">#</th>
                        <th class="text-center text-truncate align-middle" rowspan="2">*SHIPMENT PLAN DETAIL NUMBER</th>                       
                        <th class="text-center text-truncate align-middle" rowspan="2">*DESCRIPTION</th>                       
                        <th class="text-center text-truncate" :colspan="!projectData.third_party ? '3':'2'">FLEET</th>                       
                        <th class="text-center text-truncate" colspan="3">CARGO TYPE</th>                       
                        <th class="text-center text-truncate align-middle" rowspan="2">DRAUGHT TYPE</th>                       
                        <th class="text-center text-truncate" colspan="2">LOCATION</th>                       
                        <th class="text-center text-truncate align-middle" rowspan="2">*SHIPMENT MONTH</th>                       
                        <th class="text-center text-truncate" colspan="2">PLAN DATE</th>                                             
                        <th class="text-center text-truncate align-middle" rowspan="2">*PLAN QUANTITY</th>                       
                        <th class="text-center text-truncate align-middle" rowspan="2">*REMARKS</th>                                           
                      </tr>
                      <tr>
                        <th class="text-center text-truncate">*OWNERSHIP</th>
                        <th class="text-center text-truncate">*TYPE</th>
                        <th class="text-center text-truncate" v-if="!projectData.third_party">*FLEETS</th>
                        <th class="text-center text-truncate">*TYPE</th>
                        <th class="text-center text-truncate">*ACC. DATE</th>
                        <th class="text-center text-truncate">*TERMS</th>
                        <th class="text-center text-truncate">*POL</th>
                        <th class="text-center text-truncate">*POD</th>
                        <th class="text-center text-truncate">*ETA POL</th>
                        <th class="text-center text-truncate">*ETA POD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="text-center bg-white text-black">
                        </th>
                        <td class="text-truncate">{{ projectPlan?.shipment_plan_detail_number }}</td>
                        <td class="text-truncate">{{ projectPlan?.description }}</td>
                        <td class="text-truncate">
                          <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ projectPlan.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                        </td>
                        <td class="text-truncate">
                          <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ projectPlan.fleet_type?.name }}</b-badge>
                        </td>
                        <td class="text-truncate" v-if="!projectData.third_party">
                          <div class="d-flex">
                            <template v-for="(objDetail, detailIndex) in projectPlan.fleets">
                              <b-badge :key="detailIndex" v-if="objDetail.vehicle_id" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">{{ showVehicleName(objDetail?.vehicle_name) }}</b-badge>
                            </template>
                          </div>
                        </td>
                        <td class="text-truncate">{{ projectPlan?.cargo_type?.name }}</td>
                        <td class="text-truncate">{{ projectPlan?.acceptance_date ? moment(projectPlan?.acceptance_date).format('DD MMM YYYY HH:mm') : '-' }}</td>
                        <td class="text-truncate">
                          <div class="d-flex flex-column">
                            <span>{{ projectPlan?.terms?.name }}</span>
                            <span>Remarks: {{ projectPlan?.terms_remarks }}</span>
                          </div>
                        </td>
                        <td class="text-truncate">{{ capitalizeString(projectPlan?.draught_type) }}</td>
                        <td class="text-truncate">
                          <div class="d-flex w-100">
                            <b-badge 
                              v-for="(obj, polIndex) in projectPlan.pol" :key="polIndex" 
                              class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                              variant="none"
                            >
                              {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                            </b-badge>
                          </div>
                        </td>
                        <td class="text-truncate">
                          <div class="d-flex w-100">
                            <b-badge 
                              v-for="(obj, podIndex) in projectPlan.pod" :key="podIndex" 
                              class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                              variant="none"
                            >
                              {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                            </b-badge>
                          </div>
                        </td>
                        <td class="text-truncate">{{ projectPlan?.shipment }}</td>
                        <td class="text-truncate">{{ projectPlan.eta_pol ? moment(projectPlan.eta_pol).format('DD MMM YYYY') : '-'  }}</td>
                        <td class="text-truncate">{{ projectPlan.eta_pod ? moment(projectPlan.eta_pod).format('DD MMM YYYY') : '-'  }}</td>
                        <td class="text-truncate text-right">{{ projectPlan?.plan_quantity.toLocaleString() }}</td>
                        <td class="text-truncate">{{ projectPlan?.remarks }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </fieldset>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'
  import { saveAs } from 'file-saver'

  export default {
    name:'SlideTripDocument',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      }, 
      refreshData:{
        type:Function,
        default:true
      }, 
      closeSlideFormTripDocument:{
        type:Function,
        default:true
      },  
      projectData:{
        type:Object,
        default:null
      }, 
      showVehicleName:{
        type:Function,
        default: () => {}
      },
    },
    components:{
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,

        form:{
          name_document: '',
          plan_id: '',
          document: [],
        },

        tripData:null,
        projectPlan:null
      }
    },
    watch:{
      id: {
        deep: true,
        handler: function (value) {
          if (value) {
            this.getDetailTripDocument(value)
          }
        },
      },
      action: {
        deep: true,
        handler: function (value) {
          if (value === "ADD") {
            this.resetForm()
          }
        },
      }
    },
    mounted(){},
    methods:{
      ...busdevActions,
      capitalizeString(str) {
        return str
          .split('_') // Split the string into an array
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
          .join(' '); // Join the words back into a single string with spaces
      },
      async handleOpenFilePreview(url, filename = "File") {
        try {
          const response = await fetch(`${url}`);
          if (!response.ok) {
            throw new Error("Failed to fetch the PDF");
          }
          const blob = await response.blob(); 
          saveAs(blob, `${filename}.pdf`); 
        } catch (error) {
          console.error("Error downloading the PDF:", error);
        }
      },
      resetForm(){
        this.form = {
          name_document: '',
          plan_id: '',
          document: [],
        }
      },

      async getProjectPlanById(){
        if(this.form.plan_id){
          const res = await this.fetchProjectPlanById({id: this.form.plan_id})
          if(res.status === 'success') this.projectPlan = res.data.data
          console.log(this.projectPlan ,"this.projectPlan")
        }
      }, 

      async getDetailTripDocument(id){
        const res = await this.fetchTripDocumentById({id})

        if(res?.data?.status === true){
          let data = res.data.data

          this.tripData = data
          
          this.form = {
            name_document: data?.name_document,
            plan_id: data?.plan_id,
            document: [],
          }

          this.getProjectPlanById()
        }
      },

      async onSaveTripDocument(){
        let payload = {
          ...this.form,
          project_contract_id: this.projectData.id
        }

        let formData = new FormData()
        formData.append('name_document', payload.name_document)
        formData.append('plan_id', payload.plan_id)
        formData.append('project_contract_id', payload.project_contract_id)

        if(payload.document?.length > 0){
          // Append each file to FormData
          for (let i = 0; i < payload.document.length; i++) {
            formData.append('files', payload.document[i]);
          }
        }

        let res = null

        if(['EDIT'].includes(this.action) && this.id){
          res = await this.updateTripDocument({data:formData, id: Number(this.id)})
        } else {
          res = await this.createTripDocument({data:formData})
        }

        if(res.data.status === true){
          this.refreshData()
          this.resetForm()
          this.closeSlideFormTripDocument()
          this.$swal(`Success!`, `Create Trip Document Success.`, 'success')
        }
      }
    }
  }
</script>

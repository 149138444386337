<template>
  <div v-if="loading" class="alert alert-info m-4">
    Loading...
  </div>
  <b-row v-else class="m-4">
    <b-col lg="12" v-if="projectForms.length > 0">
      <iq-card body-class="p-0">
        <template v-slot:body>
          <div class="iq-edit-list">
            <tab-nav :pills="true" class="nav-fill mb-4 rounded shadow">
            <div class="d-flex w-100 overflow-auto align-item-center">
              <div v-for="(state, index) in projectForms" :key="index" :class="`col-md-2 p-2 rounded ${activeTab === index ? 'bg-primary': ''}`" style="cursor: pointer;"  @click="changeTab(index)">
                <div class="d-flex align-items-center justify-content-center w-100">
                  <span class="text-truncate ">{{ state.tabTitle }}</span>
                </div>
              </div>
            </div>
            </tab-nav>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-col lg="12">
      <div class="border my-2 w-100"></div>
    </b-col>
    <b-col lg="12"  v-if="projectForms.length > 0">
      <div class="tab-content">
        <div v-for="(form, formIndex) in projectForms" :key="formIndex" class="tab-pane" :class="{ active: activeTab === formIndex }">
          <div class="d-flex my-2 w-100 align-items-center justify-content-end">
            <b-button 
              variant="success" 
              @click="openInspectionChecklistSlide(false, true, form, projectForms[activeTab]?.form_id, detailProjectContract?.fleets, detailProjectContract?.fleet_type)"
            ><i class="fa fa-plus mr-2" /> Add Inspection</b-button>
          </div>
          <div class="overflow-auto d-flex">
            <table class="table table-striped table-bordered">
              <thead>
                <tr  v-if="form.inspection_checklist.length > 0">
                  <th class="text-center align-middle" style="width: 20px">√</th>
                  <th class="text-center align-middle text-truncate">Fleet</th>
                  <th class="text-center align-middle text-truncate">Field</th>
                  <th class="text-center align-middle text-truncate">Signature</th>
                  <th class="text-center align-middle text-truncate">Status</th>
                  <th class="text-center align-middle" style="width: 20px">#</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="form.inspection_checklist.length > 0">
                  <tr v-for="(state, index) in form.inspection_checklist" :key="index">
                    <td>
                      <!-- <b-form-checkbox
                        v-model="state.checked"
                      /> -->
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                        <strong class="text-truncate">{{ state?.vehicle?.name ?? '-' }}</strong>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                          <div class="d-flex mb-1" v-for="(field, fieldIndex) in state?.field" :key="fieldIndex">
                            <strong class="mr-2 text-truncate">{{ field?.label }}: </strong>
                            <span class="text-truncate">{{ field?.value ? handleTypeDate(field?.value) : '-' }}</span>
                          </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                          <div class="d-flex mb-1" v-for="(signature, signatureIndex) in state?.signature" :key="signatureIndex">
                            <strong class="mr-2 text-truncate">{{ signature?.label }}: </strong>
                            <span class="text-truncate">{{ signature?.value ? handleTypeDate(signature?.value) : '-' }}</span>
                          </div>
                      </div>
                    </td>
                    <td>
                      <strong class="text-primary text-capitalize">{{ state?.status }}</strong>
                    </td>
                    <td>
                      <b-dropdown v-b-tooltip.top="'Action'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                        <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                            <i class="fa fa-pen m-0"></i>
                            </span>
                        </template>
                        <b-dropdown-item href="#" variant="primary" @click="openInspectionChecklistSlide(true, false, state, state.collection_of_forms_id, state?.vehicle_id, state?.vehicle?.vehicle_type?.name)"><i class="fa fa-eye mr-2" ></i>Preview</b-dropdown-item>
                        <!-- <b-dropdown-item href="#" variant="success" v-else ><i class="fa fa-plus mr-2" ></i>Add Inspection Checklist</b-dropdown-item> -->
                      </b-dropdown>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colSpan="6" class="text-center">
                      There's no inspection yet.
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'

export default {
  name: 'SlideProjectForms',
  props:{
    formId:{
      type:Number,
      default: null
    },
    openInspectionChecklistSlide:{
      type:Function,
      default:() => {}
    },
  },
  data() {
    return {
      saving:false, 

      loading: false,
      activeTab:0,
      detailProjectContract:null,
      projectForms: [],

    }
  },
  async mounted () {},
  computed: {},
  watch: {
    formId: {
      deep: true,
      immediate:true,
      handler: function (value) {
        if (value) {
          console.log(value, "value")
          this.getDetailProjectContract(value)
        }
      },
    }
  },
  methods:{
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    ...crewingActions,
    ...busdevActions,
    onChangeChecked(value, formIndex, inspectIndex){
      this.projectForms[formIndex].inspection_checklist.map((val, valIndex) => {
        if(valIndex == inspectIndex){
          val.checked = value
        } else {
          val.checked = false
        }
        return val
      })

    },
    changeTab(index) {
      this.activeTab = index;
    },
    handleTypeDate(value){
      if(this.validDate(value)){
        return moment(value).format('DD MMMM YYYY')
      } else if (this.validDateTime(value)){
        return moment(value).format('HH:mm A')
      } else {
        return value
      }
    },
    validDate(val, format = 'YYYY-MM-DD'){
      return moment(val, format, true).isValid();
    },  
    validDateTime(val, format = 'YYYY-MM-DDTHH:mm'){
      return moment(val, format, true).isValid();
    },  
    async getDetailProjectContract(id){
      this.loading = true
      this.projectForms = []

      let res = await this.fetchDetailProjectContractWithoutPFA({id})

      if (res.data.status === true) {
        this.detailProjectContract = res.data.data

        let vehicleIds = this.detailProjectContract?.fleets?.map(val => val.vehicle_id)?.filter(val => val)
        let formIds = this.detailProjectContract?.project_forms?.map(val => val.collection_forms_id)

        let inspectionChecklist = []
        
        let forms = []

        await Promise.all(
          this.detailProjectContract?.project_forms?.map(async val => {
            let checklist = await this.getListInspectionChecklist([val.collection_forms_id], vehicleIds)

            forms.push({
              tabTitle: `${val.collection_forms.name}`,
              form_id: val.collection_forms_id,
              inspection_checklist: checklist
            })
          })
        )


        this.projectForms = forms

        // this.inspectionChecklistData.vehicle_type_name = detailProject?.fleet_type

        console.log(forms, "this.projectForms")

      } else {
        this.detailProjectContract = null
      }
      this.loading = false
    },

    async getListInspectionChecklist(form_id, vehicle_id) {
      let params = {
        page: 1,
        perPage: 100000000,
        form_id: form_id?.length > 0 ? JSON.stringify(form_id) : null,
        vehicle_id: vehicle_id?.length > 0 ? JSON.stringify(vehicle_id) : null
      }

      if(!params.form_id) delete params.form_id
      if(!params.vehicle_id) delete params.vehicle_id

      let res = await this.fetchInspectionChecklist(params)

      let inspectionChecklist = []
      if (res?.data?.status === true && res?.data?.data?.data?.length > 0) {
        inspectionChecklist = res.data.data?.data.map(val => {
          val.signature = JSON.parse(val.signature)
          val.field = JSON.parse(val.field)
          val.question_content = JSON.parse(val.question_content)

          return val
        })
      }

      return inspectionChecklist
    },
  }
}
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 mt-4"
  }, [_c('iq-card', {
    attrs: {
      "headerClass": "bg-blue-navy"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-white"
        }, [_c('strong', [_vm._v("FLEET AVAILABILITY & BREAKDOWN")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex overflow-auto pb-3"
        }, _vm._l(_vm.fleetsStatus, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "card-container cursor-pointer shadow-sm mr-3",
            staticStyle: {
              "min-width": "300px"
            },
            attrs: {
              "md": "".concat(item.cols * 2)
            }
          }, [_c('div', {
            staticClass: "p-2 py-3 card-title-wrap d-flex align-items-center px-3",
            class: item.bg,
            staticStyle: {
              "border-radius": "10px"
            }
          }, [_c('div', [_c('strong', {
            staticClass: "p-0 m-0 text-yellow"
          }, [_vm._v(_vm._s(item.title))]), _c('h4', {
            staticClass: "text-white"
          }, [_vm._v(_vm._s(item.total))])])]), index == _vm.fleetsStatus.length - 1 ? _c('img', {
            staticClass: "card-image",
            staticStyle: {
              "top": "34px"
            },
            attrs: {
              "src": require("../../../assets/images/icon/heavy-equipment.svg"),
              "width": "90",
              "alt": ""
            }
          }) : _c('img', {
            staticClass: "card-image",
            attrs: {
              "src": require("../../../assets/images/icon/ship-3d.png"),
              "width": "78",
              "alt": ""
            }
          }), _c('div', {
            staticClass: "mt-2"
          }, _vm._l(_vm.items, function (tableData, i) {
            return _c('div', {
              key: i,
              staticClass: "py-1 px-3 d-flex justify-content-between",
              class: {
                'bg-striped': i % 2 == 0
              }
            }, [_c('h6', [_vm._v(_vm._s(tableData.fleet))]), _c('h6', [_vm._v(_vm._s(tableData.qty))])]);
          }), 0)]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-container fluid>
    <form @submit.prevent="onSavePaymentList">
      <b-row>
        <b-col md="12">
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit" v-if="['EDIT', 'ADD'].includes(action)"><i class="fa fa-save"></i> {{ ['EDIT'].includes(action) ? 'Update' : 'Create' }} Payment List&nbsp; </b-button>
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormPayment"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
        </b-col>
        <b-col md="12" class="p-4">
          <b-row>
            <b-col md="6" v-if="projectData?.project_contract_plan?.length > 0">
              <b-form-group label="*For Trip">
                <v-select
                  v-if="['EDIT', 'ADD'].includes(action)"
                  label="shipment_plan_detail_number"
                  track-by="id"
                  name="plan_id"
                  v-model="form.plan_id"
                  :options="projectData?.project_contract_plan"
                  :reduce="(plan) => plan.id"
                  placeholder="Select..."
                  @input="getProjectPlanById"
                  required
                ></v-select>
                <strong v-if="['PREVIEW'].includes(action)">{{paymentData?.project_plan?.shipment_plan_detail_number}}</strong>
              </b-form-group>
            </b-col>
            <template v-if="form.plan_id">
              <b-col md="6" />
              <b-col lg="12" v-if="projectPlan">
                <fieldset class="form-group border p-3">
                  <legend class="w-auto px-2 text-primary">Project Plan</legend>
                  <div class="d-flex w-100 overflow-auto">
                    <table class="table table-bordered table-sm table-responsive">
                      <thead>
                        <tr>
                          <th style="width:5px" rowspan="2" class="text-center text-truncate align-middle">#</th>
                          <th class="text-center text-truncate align-middle" rowspan="2">*SHIPMENT PLAN DETAIL NUMBER</th>                       
                          <th class="text-center text-truncate align-middle" rowspan="2">*DESCRIPTION</th>                       
                          <th class="text-center text-truncate" :colspan="!projectData.third_party ? '3':'2'">FLEET</th>                       
                          <th class="text-center text-truncate" colspan="3">CARGO TYPE</th>                       
                          <th class="text-center text-truncate align-middle" rowspan="2">DRAUGHT TYPE</th>                       
                          <th class="text-center text-truncate" colspan="2">LOCATION</th>                       
                          <th class="text-center text-truncate align-middle" rowspan="2">*SHIPMENT MONTH</th>                       
                          <th class="text-center text-truncate" colspan="2">PLAN DATE</th>                                             
                          <th class="text-center text-truncate align-middle" rowspan="2">*PLAN QUANTITY</th>                       
                          <th class="text-center text-truncate align-middle" rowspan="2">*REMARKS</th>                                           
                        </tr>
                        <tr>
                          <th class="text-center text-truncate">*OWNERSHIP</th>
                          <th class="text-center text-truncate">*TYPE</th>
                          <th class="text-center text-truncate" v-if="!projectData.third_party">*FLEETS</th>
                          <th class="text-center text-truncate">*TYPE</th>
                          <th class="text-center text-truncate">*ACC. DATE</th>
                          <th class="text-center text-truncate">*TERMS</th>
                          <th class="text-center text-truncate">*POL</th>
                          <th class="text-center text-truncate">*POD</th>
                          <th class="text-center text-truncate">*ETA POL</th>
                          <th class="text-center text-truncate">*ETA POD</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th class="text-center bg-white text-black">
                          </th>
                          <td class="text-truncate">{{ projectPlan?.shipment_plan_detail_number }}</td>
                          <td class="text-truncate">{{ projectPlan?.description }}</td>
                          <td class="text-truncate">
                            <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ projectPlan.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                          </td>
                          <td class="text-truncate">
                            <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ projectPlan.fleet_type?.name }}</b-badge>
                          </td>
                          <td class="text-truncate" v-if="!projectData.third_party">
                            <div class="d-flex">
                              <template v-for="(objDetail, detailIndex) in projectPlan.fleets">
                                <b-badge :key="detailIndex" v-if="objDetail.vehicle_id" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">{{ showVehicleName(objDetail?.vehicle_name) }}</b-badge>
                              </template>
                            </div>
                          </td>
                          <td class="text-truncate">{{ projectPlan?.cargo_type?.name }}</td>
                          <td class="text-truncate">{{ projectPlan?.acceptance_date ? moment(projectPlan?.acceptance_date).format('DD MMM YYYY HH:mm') : '-' }}</td>
                          <td class="text-truncate">
                            <div class="d-flex flex-column">
                              <span>{{ projectPlan?.terms?.name }}</span>
                              <span>Remarks: {{ projectPlan?.terms_remarks }}</span>
                            </div>
                          </td>
                          <td class="text-truncate">{{ capitalizeString(projectPlan?.draught_type) }}</td>
                          <td class="text-truncate">
                            <div class="d-flex w-100">
                              <b-badge 
                                v-for="(obj, polIndex) in projectPlan.pol" :key="polIndex" 
                                class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                                variant="none"
                              >
                                {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                              </b-badge>
                            </div>
                          </td>
                          <td class="text-truncate">
                            <div class="d-flex w-100">
                              <b-badge 
                                v-for="(obj, podIndex) in projectPlan.pod" :key="podIndex" 
                                class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                                variant="none"
                              >
                                {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                              </b-badge>
                            </div>
                          </td>
                          <td class="text-truncate">{{ projectPlan?.shipment }}</td>
                          <td class="text-truncate">{{ projectPlan.eta_pol ? moment(projectPlan.eta_pol).format('DD MMM YYYY') : '-'  }}</td>
                          <td class="text-truncate">{{ projectPlan.eta_pod ? moment(projectPlan.eta_pod).format('DD MMM YYYY') : '-'  }}</td>
                          <td class="text-truncate text-right">{{ projectPlan?.plan_quantity.toLocaleString() }}</td>
                          <td class="text-truncate">{{ projectPlan?.remarks }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </fieldset>
              </b-col>
              <b-col md="6">
                <b-form-group label="*Request Name">
                  <b-form-input required v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.request_name"  />
                  <strong v-if="['PREVIEW'].includes(action)">{{paymentData.request_name}}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="*Request Date">
                  <b-form-input required v-if="['EDIT', 'ADD'].includes(action)" type="date" v-model="form.request_date" required />
                  <strong v-if="['PREVIEW'].includes(action)">{{moment(paymentData.request_date).format('DD MMMM YYYY')}}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Real Pricing">
                  <b-form-input required v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.real_pricing" />
                  <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.real_pricing) }}</b-form-text>
                  <strong v-if="['PREVIEW'].includes(action)">{{formatCurrency(paymentData.real_pricing)}}</strong>
                </b-form-group>
              </b-col> 
              <b-col md="6">
                <b-form-group label="Balance">
                  <b-form-input disabled v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.balance" />
                  <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.balance) }}</b-form-text>
                  <strong v-if="['PREVIEW'].includes(action)">{{formatCurrency(paymentData.balance)}}</strong>
                </b-form-group>
              </b-col>
              <!-- <b-col md="6">
                <b-form-group label="Last Invoice">
                  <strong>{{ formatCurrency(totalBilling || 0) }}</strong>
                </b-form-group>
              </b-col>   -->
              <b-col md="6">
                <b-form-group label="Invoice Price">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.invoice_price" @change="() => {onChangeInvoicePrice(); countTaxTotal()}" />
                  <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.invoice_price) }}</b-form-text>
                  <strong v-if="['PREVIEW'].includes(action)">{{ formatCurrency(paymentData.invoice_price) }}</strong>
                </b-form-group>
              </b-col>  
              <b-col md="6" />
              <b-col md="6">
                <b-form-group label="Use Tax">
                  <b-form-checkbox 
                    v-if="['EDIT', 'ADD'].includes(action)"
                    id="use_tax" 
                    v-model="form.use_tax" 
                    label="Yes / No" 
                    name="check-button"
                    switch
                    inline
                  />
                  <strong v-if="['PREVIEW'].includes(action)">{{ paymentData.use_tax ? 'Yes' : 'No' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Deductions?">
                  <b-form-checkbox 
                    v-if="['EDIT', 'ADD'].includes(action)"
                    id="is_deduction" 
                    v-model="form.is_deduction" 
                    label="Yes / No" 
                    name="check-button"
                    switch
                    inline
                  />
                  <strong v-if="['PREVIEW'].includes(action)">{{ paymentData.is_deduction ? 'Yes' : 'No' }}</strong>
                </b-form-group>
              </b-col>
  
              <template v-if="form.use_tax">
                <b-col md="6">
                  <b-form-group label="Tax Name">
                    <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.name_tax" />
                    <strong v-if="['PREVIEW'].includes(action)">{{ paymentData.name_tax }}</strong>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Tax Percentage (%)">
                    <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0.00" type="number" v-model="form.tax_percentage" step="0.001" @change="() => {countTaxTotal()}" />
                      <strong v-if="['PREVIEW'].includes(action)">{{ parseFloat(paymentData.tax_percentage).toLocaleString() }}%</strong>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Tax Total">
                    <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.tax_total" />
                    <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.tax_total) }}</b-form-text>
                    <strong v-if="['PREVIEW'].includes(action)">{{ formatCurrency(paymentData.tax_total) }}</strong>
                  </b-form-group>
                </b-col>
              </template>
              <b-col md="12" v-if="form.is_deduction">
                <div class="d-flex w-100 align-items-center justify-content-between mb-2">
                  <h6><strong>Deduction</strong></h6>
                  <b-button v-if="['ADD', 'EDIT'].includes(action)" variant="success" type="button" @click="addDeduction"><i class="fa fa-plus pr-0"></i> ADD MORE DEDUCTION</b-button>
                </div>
                <div class="overflow-auto" style="max-height:400px">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center" style="vertical-align:middle; width: 20px">#</th>
                        <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">Name</th>
                        <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">Amount</th>
                        <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">Remarks</th>
                        <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">File</th>
                      </tr>
                    </thead>
                    <tbody v-if="['ADD', 'EDIT'].includes(action)"> 
                      <tr v-for="(state, index) in form.deduction" :key="index">
                        <td class="text-center align-top">
                          <b-button size="sm" variant="danger" @click="() => {
                            form.deduction.splice(index, 1);
                            form.invoice_price += state.amount;
                          }" v-if="index !== 0"><i class="fa fa-trash pr-0"></i></b-button>
                        </td>
                        <td class="align-top">
                          <b-form-input placeholder="Type Text" v-model="state.name" />
                        </td>
                        <td class="align-top">
                          <b-form-input placeholder="Type Number" v-model="state.amount" />
                          <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(state.amount) }}</b-form-text>
                        </td>
                        <td class="align-top">
                          <b-form-input placeholder="Type Text" v-model="state.remarks" />
                        </td>
                        <td>
                          <b-form-file
                            :id="'fileSupport'+index"
                            placeholder="Choose a file"
                            accept="application/pdf"
                            v-model="state.file"
                          >
                          </b-form-file>
                          <span @click="handleOpenFilePreview(state?.file_url, state?.file_name)" class="text-primary" style="cursor: pointer">{{ state?.file_name }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr v-for="(state, index) in paymentData.deduction" :key="index">
                        <td class="text-center align-top">#</td>
                        <td class="text-center align-top">{{ state?.name }}</td>
                        <td class="text-center align-top">{{ formatCurrency(state.amount) }}</td>
                        <td class="text-center align-top">{{ state?.remarks }}</td>
                        <td class="text-center">
                          <span @click="handleOpenFilePreview(state?.file_url, state?.file_name)" class="text-primary" style="cursor: pointer">{{ state?.file_name }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2" class="text-right">Total Amount</td>
                        <th class="text-center">{{formatCurrency(totalAmount)}}</th>
                        <td colspan="2"></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </b-col>
  
              <b-col md="12" class="p-4" v-if="paymentData?.real_invoice_fulfilled && ['PREVIEW'].includes(action)">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Real Invoice Number">
                      <strong>{{paymentData?.real_invoice_number}}</strong>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Invoice Date">
                      <strong>{{paymentData?.invoice_date ? moment(paymentData?.invoice_date).format('DD MMM YYYY') : '-'}}</strong>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Due Date">
                      <strong>{{paymentData?.due_date ? moment(paymentData?.due_date).format('DD MMM YYYY') : '-'}}</strong>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Invoice File">
                      <div class="d-flex flex-column" v-if="paymentData.real_invoice_attachment.length > 0">
                        <div class="d-flex align-items-center mb-2" 
                          style="cursor:pointer" 
                          v-for="(file, fileIndex) in paymentData.real_invoice_attachment" 
                          :key="fileIndex"
                          @click="handleOpenFilePreview(file?.file_url, file?.file_name)"
                        >
                          <i class="fa fa-file text-primary mr-2" />
                          <span class="text-primary">{{file.file_name}}</span>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                  
                  <b-col md="12">
                    <b-form-group label="Status Invoice">
                      <strong class="text-capitalize">{{paymentData?.status}}</strong>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              
              <b-col md="12" class="mt-4">
                <h4><strong>Invoice Total</strong></h4>
                <div class="d-flex p-2 bg-success">
                  <h5 class="text-white"><strong>{{ formatCurrency(totalInvoice) }}</strong></h5>
                </div>
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'
  import { saveAs } from 'file-saver'

  export default {
    name:'TabPaymentList',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      },  
      refreshData:{
        type:Function,
        default:true
      }, 
      closeSlideFormPayment:{
        type:Function,
        default:true
      },  
      projectData:{
        type:Object,
        default:null
      }, 
      showVehicleName:{
        type:Function,
        default: () => {}
      },
    },
    components:{
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,

        last_invoice: 0,
        form:{
          request_name:'',
          plan_id:null,
          request_date:null,
          real_pricing: null,
          balance:null,
          invoice_price:null,
          use_tax:false,
          is_deduction:false,
          name_tax:'',
          tax_percentage:null,
          tax_total:null,

          deduction:[{
            name:'',
            amount:null,
            remarks:'',
            file:''
          }]
        },
        projectPlan: null,
        paymentData: null,
      }
    },
    computed:{
      totalAmount() {
        return this.form?.deduction?.reduce((total, item) => total + Number(item?.amount), 0);
      },
      totalInvoice() {
        let result = 0
        result = parseFloat(this.last_invoice || 0) + Number(this.form.invoice_price || 0) + parseFloat(this.form.tax_total || 0) - parseFloat(this.totalAmount || 0)
        return result
      }, 
    },
    watch:{
      id: {
        deep: true,
        handler: function (value) {
          if (value) {
            this.getDetailPayment(value)
          }
        },
      },
      action: {
        deep: true,
        handler: function (value) {
          if (value === "ADD") {
           this.resetForm()
          }
        },
      },
      totalInvoice: {
        immediate: true,
        deep:true,
        handler: function (value) {
          if(value) {
            this.form.balance = parseFloat(this.form.real_pricing || 0) - parseFloat(this.totalInvoice || 0)
          }
        }
      }
    },
    mounted(){

    },
    methods:{
      ...busdevActions,
      capitalizeString(str) {
        return str
          .split('_') // Split the string into an array
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
          .join(' '); // Join the words back into a single string with spaces
      },
      async handleOpenFilePreview(url, filename = "File") {
        try {
          const response = await fetch(`${this.apiUrl}/${url}`);
          if (!response.ok) {
            throw new Error("Failed to fetch the PDF");
          }
          const blob = await response.blob(); 
          saveAs(blob, `${filename}.pdf`); 
        } catch (error) {
          console.error("Error downloading the PDF:", error);
        }
      },
      countTaxTotal(){
        if(this.form.invoice_price && this.form.tax_percentage){
          this.form.tax_total = Number(this.form.invoice_price) * Number(this.form.tax_percentage) / 100
        }
      },
      onChangeInvoicePrice(){
        // if(this.form.invoice_price && this.form.real_pricing){
        //   this.form.balance = Number(this.form.real_pricing) - Number(this.form.invoice_price)
        // }
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
      addDeduction(){
        this.form.deduction.push({
          name:'',
          amount:null,
          remarks:'',
          file:''
        })
      },
      resetForm(){
        this.form = {
          request_name:'',
          plan_id:null,
          request_date:null,
          real_pricing: null,
          balance:null,
          invoice_price:null,
          use_tax:false,
          is_deduction:false,
          name_tax:'',
          tax_percentage:null,
          tax_total:null,

          deduction:[{
            name:'',
            amount:null,
            remarks:'',
            file:''
          }]
        }
      },

      async getProjectPlanById(id){
        if(this.form.plan_id){
          const res = await this.fetchProjectPlanById({id: this.form.plan_id})
          if(res.status === 'success') {
            this.projectPlan = res.data.data

            const respayment = await this.fetchPaymentListByPlan(this.form.plan_id)
            let payment = respayment.data.data

            let freightRate = parseFloat(this.projectData.freight_rate_est) * parseFloat(this.projectData.currency_rate || 1)
            this.form.real_pricing = freightRate * this.projectPlan.plan_quantity

            this.last_invoice = payment.invoice_total || 0
            console.log(this.last_invoice ,"this.last_invoice")
            // this.form.balance = this.form.real_pricing - this.last_invoice
          }
        }
      }, 

      async getDetailPayment(id){
        const res = await this.fetchPaymentListById({id})

        if(res?.data?.status === true){
          let data = res.data.data
          this.paymentData = data
          this.form = {
            request_name: data.request_name,
            plan_id: data.plan_id,
            request_date: data.request_date ? moment(data.request_date).format('YYYY-MM-DD') : null,
            real_pricing: data.real_pricing,
            balance: data.balance,
            invoice_price: data.invoice_price,
            use_tax: data.use_tax,
            is_deduction: data.is_deduction,
            name_tax: data.name_tax,
            tax_percentage: data.tax_percentage,
            tax_total: data.tax_total,
            invoice_total: data.invoice_total,

            deduction:data?.deduction?.length > 0 ? data?.deduction : []
          }

          const planRes = await this.fetchProjectPlanById({id: data.plan_id})
          this.projectPlan = planRes.data.data
        }
      },

      async onSavePaymentList(){
        let payload = {
          ...this.form,
          request_date: moment(this.form.request_date).format('YYYY-MM-DD'),
          project_contract_id: this.projectData.id,
          invoice_total: parseFloat(this.totalInvoice),
        }

        let res = null

        if(['EDIT'].includes(this.action) && this.id){
          res = await this.updatePaymentList({data:payload, id: Number(this.id)})
        } else {
          res = await this.createPaymentList({data:payload})
        }

        if(payload?.deduction?.length > 0){
            const formData = new FormData()
  
            if(this.form.deduction && this.form.deduction?.length > 0){
              this.form.deduction?.forEach((item, index) => {
                for (const property in item) {
                  formData.append(`deduction[${index}][${property}]`, item[property])
                }
              });
            }

            await this.createOrUpdatePaymentListDeduction({id: res.data.data.id, data: formData})
          }
          
        if(res.data.status === true){
          this.refreshData()
          this.resetForm()
          this.closeSlideFormPayment()
          this.$swal(`Success!`, `Create Payment List Success.`, 'success')
        }
      }
    }
  }
</script>

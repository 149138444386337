<template>
  <div class="d-flex justify-content-between">
    <div class="w-100" v-for="(tab, index) in tabs" :key="index">
      <div class="d-flex align-items-center w-100">
        <div
          class="tab-number border cursor-pointer"
          :class="{ 'bg-primary': activeTab == index + 1 }"
          @click="handleClick(tab.id)"
        >
          {{ tab.id }}
        </div>
        <div
          class="line-tab w-100"
          :class="{ 'border-active': activeTab == index + 1 }"
        ></div>
      </div>
      <h6 :class="{ 'text-primary': activeTab == index + 1, 'text-secondary': activeTab != index + 1 }" >{{ tab.label }}</h6>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeTab: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tabs: [
        {
          label: 'Person of Involved',
          id: 1,
        },
        {
          label: 'Define the Problem',
          id: 2,
        },
        {
          label: 'Collecting Information',
          id: 3,
        },
        {
          label: 'Root Cause Identify',
          id: 4,
        },
        {
          label: 'Implement Corrective ',
          id: 5,
        },
        {
          label: 'Fishbone Diagram',
          id: 6,
        },
      ],
    }
  },
  methods: {
    handleClick(id) {
      this.$emit('clickTab', id)
    },
  },
}
</script>
<style scoped>
.tab-number {
  border: 1px solid black;
  width: 60px !important;
  height: 48px !important;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}

.line-tab {
  border-top: 1.5px solid;
}
.border-active {
  border-color: #0a9cab;
}
</style>

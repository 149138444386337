<template>
  <div class="d-flex justify-content-between">
    <div class="w-100" v-for="(tab, index) in tabs" :key="index">
      <div class="d-flex align-items-center w-100">
        <div
          class="tab-number border cursor-pointer"
          :class="{
            'bg-primary': activeTab == index + 1,
            'text-secondary': activeTab != index + 1,
          }"
          @click="handleClick(tab.id)"
        >
          {{ tab.labelBtn }}
        </div>
        <div
          class="line-tab w-100"
          :class="{
            'border-active': activeTab == index + 1,
            'text-secondary': activeTab != index + 1,
          }"
        ></div>
      </div>
      <h6
        :class="{
          'text-primary font-semibold': activeTab == index + 1,
          'text-secondary': activeTab != index + 1,
        }"
        >{{ tab.label }}</h6
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeTab: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tabs: [
        {
          label: 'Investigation Details',
          labelBtn: 'A',
          id: 1,
        },
        {
          label: 'Supporting Document',
          labelBtn: 'B',
          id: 2,
        },
        {
          label: 'Details Of Investigation',
          labelBtn: 'C',
          id: 3,
        },
        {
          label: 'Summary Of Accident',
          labelBtn: 'D',
          id: 4,
        },
        {
          label: 'Claims Opportunity ',
          labelBtn: 'E',
          id: 5,
        },
        {
          label: 'Conclusion',
          labelBtn: 'F',
          id: 6,
        },
        {
          label: 'Recommendation',
          labelBtn: 'G',
          id: 7,
        },
      ],
    }
  },
  methods: {
    handleClick(id) {
      this.$emit('clickTab', id)
    },
  },
}
</script>
<style scoped>
.tab-number {
  border: 1px solid black;
  width: 60px !important;
  height: 48px !important;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}

.line-tab {
  border-top: 1.5px solid;
}
.border-active {
  border-color: #0a9cab;
}
.font-semibold {
  font-weight: 500;
}
</style>

import { render, staticRenderFns } from "./HistoryRecordDetail.vue?vue&type=template&id=12fd8f73&scoped=true&"
import script from "./HistoryRecordDetail.vue?vue&type=script&lang=js&"
export * from "./HistoryRecordDetail.vue?vue&type=script&lang=js&"
import style0 from "./HistoryRecordDetail.vue?vue&type=style&index=0&id=12fd8f73&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fd8f73",
  null
  
)

export default component.exports
<template>
  <b-row class="mt-2">
    <b-col md="12">
      <b-row>
        <b-col md="12">
          <h4 class="text-primary mb-2">Detail Information</h4>
          <table class="table mb-0 table-striped">
              <tbody>
              <tr>
                  <th width="20%">Lokasi Pekerjaan</th>
                  <td width="5%">:</td>
                  <td width="25%">
                  <b-form-input required type="text" name="location" placeholder="Type Text ..."
                  v-model="form.data.location"
                  ></b-form-input>
                  </td>
                  <th width="20%">Tanggal</th>
                  <td width="5%">:</td>
                  <td width="25%">
                  <b-form-input type="date" required name="accident_at"
                  v-model="form.data.accident_at"
                  ></b-form-input>
                  </td>
              </tr>
              <tr>
                  <th width="20%">Site & Group</th>
                  <td width="5%">:</td>
                  <td width="25%">
                  <b-form-input type="text" placeholder="Type Text ..." name="site"
                  v-model="form.data.site"
                  ></b-form-input>
                  </td>
                  <th width="20%">P.I.C</th>
                  <td width="5%">:</td>
                  <td width="25%">
                  <b-form-input type="text" placeholder="Type Text ..." name="pic"
                  v-model="form.data.pic"
                  ></b-form-input>
                  </td>
              </tr>
              </tbody>
          </table>
          <div class="overflow-auto mt-2" style="max-height:630px">
            <div class="mb-2" v-for="(state, index) in form.data.contents" :key="`add-` + index">
              <h4 class="text-primary mb-2">Repair #{{ index + 1 }}</h4>
              <b-form-row>
                  <b-col>
                  <b-form-group label="Equipment Name" label-for="equipmentName">
                      <b-form-input type="text" name="name" placeholder="Type Text ..." required
                      v-model="state.name"
                      ></b-form-input>
                  </b-form-group>
                  </b-col>
                  <b-col>
                  <b-form-group label="Work Item" label-for="repairTitle">
                      <b-form-input type="text" name="title" placeholder="Type Text ..." required
                      v-model="state.title"
                      ></b-form-input>
                  </b-form-group>
                  </b-col>
                  <b-col>
                  <b-form-group label="Type R & M *" label-for="type_repair">
                      <b-form-select plain v-model="state.task_type_id" name='task_type_id' :options="optionsTaskType" size="lg" required>
                      <template v-slot:first>
                          <b-form-select-option :value="null">Select Type R & M</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                  </b-col>
              </b-form-row>
              <b-form-row>
                  <b-col md="2">
                  <b-form-group label="Department *" label-for="department" >
                      <b-form-select plain v-model="state.department_id" name='department_id' :options="optionsDepartment" size="lg" required>
                      <template v-slot:first>
                          <b-form-select-option :value="null">Select Department</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                  </b-col>
                  <b-col md="2">
                      <b-form-group label="Section *" label-for="section" >
                          <b-form-select plain v-model="state.section" name='section' size="lg" required>
                          <template v-slot:first>
                              <b-form-select-option :value="''">Select Section</b-form-select-option>
                              <b-form-select-option :value="'DECK'">Deck</b-form-select-option>
                              <b-form-select-option :value="'ENGINE'">Engine</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col>
                  <b-form-group label="Category *" label-for="category">
                      <b-form-select plain v-model="state.category_id" name='category_id' :options="optionsCategory" size="lg" required>
                      <template v-slot:first>
                          <b-form-select-option :value="null">Select Category</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                  </b-col>
                  <b-col>
                  <b-form-group label="Classification *" label-for="classification">
                      <b-form-select plain v-model="state.classification_id" name='classification_id' :options="optionsClassification" @change="handleCountDaysBasedClassification(state)" size="lg" required>
                      <template v-slot:first>
                          <b-form-select-option :value="null">Select Classification</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                  </b-col>
              </b-form-row>
              <b-form-row>
                  <b-col>
                  <b-form-group label="Cause *" label-for="cause" >
                      <b-form-select plain v-model="state.case_id" name='case_id' :options="optionsCause" size="lg" required>
                      <template v-slot:first>
                          <b-form-select-option :value="null">Select Cause</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                  </b-col>
                  <b-col>
                  <b-form-group label="Kind of Task *" label-for="kind_of_task" >
                      <b-form-select plain v-model="state.kind_task_id" name='kind_task_id' :options="optionsKindTask" size="lg" required>
                      <template v-slot:first>
                          <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                  </b-col>
                  <b-col>
                  <b-form-group label="Berita Acara" label-for="attactment_file">
                      <b-form-file plain multiple v-model="state.file" name='file' id="attactment_file" accept="image/*,application/*"></b-form-file>
                  </b-form-group>
                  </b-col>
              </b-form-row>
              <b-form-row>
                  <b-col>
                  <b-form-group label="Rencana Mulai *" label-for="mulai">
                      <b-form-input type="date" name="start_at" id="mulai"  @change="() => {calculateDays(state); handleCountDaysBasedClassification(state)}" required 
                      v-model="state.start_at"
                      ></b-form-input>
                  </b-form-group>
                  </b-col>
                  <b-col>
                  <b-form-group label="Rencana Selesai *" label-for="selesai">
                      <b-form-input type="date" name="" id="selesai" @change="calculateDays(state)"  required
                      v-model="state.estimated_finished_at"
                      ></b-form-input>
                  </b-form-group>
                  </b-col>
                  <b-col>
                  <b-form-group label="Estimasi Hari" label-for="estimasi_hari">
                      <b-button variant="outline-primary" class="mb-1">
                      Perkiraan Pekerjaan <b-badge variant="primary" class="ml-2">{{ state?.estimate_total_days }} Hari</b-badge>
                      </b-button>
                  </b-form-group>
                  </b-col>
              </b-form-row>
              <b-form-row>
                  <b-col>
                  <b-form-group label="Description" label-for="description">
                      <b-form-textarea id="description" rows="3" name="" placeholder="Type Text ..."
                      v-model="state.description"
                      ></b-form-textarea>
                  </b-form-group>
                  </b-col>
              </b-form-row>
              <b-row class="mt-2">
                <b-col md="12">
                  <div class="d-flex w-100 align-items-center justify-content-between">
                    <b-card-text>
                      <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small>
                    </b-card-text>
                    <b-button v-if="index !== 0" variant="danger" class="pull-right" size="sm" @click="removeRepairJob(form.data.contents, index)">
                      <i class="fa fa-trash "></i> Delete Repair Job
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-button variant="success" class="mt-2" @click="addRepairJob(form.data.contents)">
            <i class="fa fa-plus"></i> ADD JOB REPAIR & MAINTENANCE
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  fleetsActions,
  crewingActions,
  srActions,
  maintenanceActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import moment from 'moment'
import optionsData from '@src/core/mock/data'

export default {
  name: 'FormServiceAndRepair',
  data() {
    return {
      ...optionsData,
      optionsDepartment: [],
      optionsActivity: [],
      optionsCategory:[],
      optionsCause: [],
      optionsClassification: [],
      optionsStatus: [],
      optionsTaskType: [],
      optionsKindTask:[],
      optionsReject:[],
      optionsVehicleType:[],

      form:{
        loading:false,
        data: {
          vehicle_id: null,
          location: '',
          site: '',
          pic: '',
          type_of_job:'custom',
          created_by: JSON.parse(localStorage.getItem('profile')).id,
          updated_by: null,
          accident_at: null,
          user_id: JSON.parse(localStorage.getItem('profile')).id,
          user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
          contents: [
              {
                  maintenance_id:null,
                  maintenance_component_setup_id:null,
                  maintenance_component_setup_name:'',
                  status_id:null,
                  name:'',
                  title:'',
                  classification_id:null,
                  category_id:null,
                  department_id:1,
                  section:'',
                  case_id:null,
                  task_type_id:null,
                  kind_task_id:null,
                  start_at:null,
                  actual_finished_at:null,
                  description:null,
                  estimate_total_days:null,
                  file:[],
              }
          ]
        }
      },
    }
  },
  async mounted() {
    await this.getListVehicleType()
    await this.fetchMasterRejectReason()
    await this.getMasterActivity()
    await this.getMasterCategory()
    await this.getMasterCause()
    await this.getMasterClassification()
    await this.getMasterKindTask()
    await this.getMasterStatus()
    await this.getMasterTaskType()
    await this.getMasterDepartment()
  },
  watch: {
    'form.data.type_of_job':{
      handler(value) {
        if (value) {
        }
      }
    },
  },
  methods: {
    ...fleetsActions,
    ...crewingActions,
    ...srActions,
    ...maintenanceActions,
    addRepairJob(form){
      form.push(
        {
          maintenance_id:null,
          maintenance_component_setup_id:null,
          maintenance_component_setup_name:'',
          status_id:null,
          name:'',
          title:'',
          classification_id:null,
          category_id:null,
          department_id:1,
          section:'',
          case_id:null,
          task_type_id:null,
          kind_task_id:null,
          start_at:null,
          actual_finished_at:null,
          description:null,
          estimate_total_days:null,
          file:[],
        }
      )
    },
    removeRepairJob(form, index){
      form.splice(index, 1)
    },
    resetFormAddServiceAndRepair(){
      this.form = {
          loading:false,
          data: {
              vehicle_id: null,
              location: '',
              site: '',
              pic: '',
              type_of_job:'custom',
              created_by: JSON.parse(localStorage.getItem('profile')).id,
              updated_by: null,
              accident_at: null,
              user_id: JSON.parse(localStorage.getItem('profile')).id,
              user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
              contents: [
                  {
                      maintenance_id:null,
                      maintenance_component_setup_id:null,
                      maintenance_component_setup_name:'',
                      status_id:null,
                      name:'',
                      title:'',
                      classification_id:null,
                      category_id:null,
                      department_id:1,
                      section:'',
                      case_id:null,
                      task_type_id:null,
                      kind_task_id:null,
                      start_at:null,
                      actual_finished_at:null,
                      description:null,
                      estimate_total_days:null,
                      file:[],
                  }
              ]
          }
      }
    },

    async getMasterActivity() {
      let res = await this.fetchMasterActivity()
      if (res.status === 'error') {
        this.optionsActivity = []
      } else {
        res.data.map(val => {
            this.optionsActivity.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getListVehicleType() {
      let res = await this.fetchListVehicleType()
      if (res.status === 'error') {
        this.optionsVehicleType = []
      } else {
        res.data.data.map(val => {
            this.optionsVehicleType.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterCategory() {
      let res = await this.fetchMasterCategory()
      if (res.status === 'error') {
        this.optionsCategory = []
      } else {
        res.data.map(val => {
            this.optionsCategory.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async fetchMasterRejectReason() {
      let res = await this.getMasterRejectReason()
      if (res.status === 'error') {
        this.optionsCategory = []
      } else {
        res.data.map(val => {
            this.optionsReject.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterCause() {
      let res = await this.fetchMasterCause()
      if (res.status === 'error') {
        this.optionsCause = []
      } else {
        res.data.map(val => {
            this.optionsCause.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterClassification() {
      let res = await this.fetchMasterClassification()
      if (res.status === 'error') {
        this.optionsClassification = []
      } else {
        res.data.map(val => {
            this.optionsClassification.push({
                text:`${val.name} (${val.interval} ${val.unit})`,
                value:val.id,
                interval:val.interval,
            })
        })
      }
    },
    async getMasterKindTask() {
      let res = await this.fetchMasterKindTask()
      if (res.status === 'error') {
        this.optionsKindTask = []
      } else {
        res.data.map(val => {
            this.optionsKindTask.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterStatus() {
      let res = await this.fetchMasterStatus()
      if (res.status === 'error') {
        this.optionsStatus = []
      } else {
        res.data.map(val => {
            this.optionsStatus.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterTaskType() {
      let res = await this.fetchMasterTaskType()
      if (res.status === 'error') {
        this.optionsTaskType = []
      } else {
        res.data.map(val => {
            this.optionsTaskType.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterDepartment() {
      this.optionsDepartment = []
      let res = await this.fetchListDepartment()
      if (!res.data.status) {
        this.optionsDepartment = []
      } else {
        res.data.data.map(val => {
            this.optionsDepartment.push({
                text:val.name,
                value:val.id,
                alias:val.alias
            })
        })
      }
    }, 

    async setServiceAndRepair(e){
      e.preventDefault()
      this.form.loading = true

      let formData = new FormData()
      formData.append('accident_at', this.form.data.accident_at)
      formData.append('created_by', this.form.data.created_by)
      formData.append('location', this.form.data.location)
      formData.append('pic', this.form.data.pic)
      formData.append('site', this.form.data.site)
      formData.append('type_of_job', this.form.data.type_of_job)
      formData.append('updated_by', this.form.data.updated_by)
      formData.append('user_id', this.form.data.user_id)
      formData.append('user_name', this.form.data.user_name)
      formData.append('vehicle_id', this.checkedFleets)

      this.form.data.contents.forEach((item, index) => {
          item.file.forEach((file, fileIndex) => {
              formData.append(`file[${index}]`, file);
          });
  
          for (const property in item) {
              formData.append(
                  `contents[${index}][${property}]`,
                  item[property]
              )
          }
      })

      const res = await this.createServiceAndRepair(formData)

      if(res.status === 'success'){
          this.showInitModalNext = false
          this.form.loading = false
          this.checkedFleets = null
          this.vehicleTypeId = null
          this.resetFormAddServiceAndRepair()
      
          this.$swal(`Berhasil!`, `Create Service & Repair Berhasil.`, 'success')
          
      } else {
          this.$swal(`Oops!`, `${res.data.message}`, 'error')
          this.form.loading = false
      }
    },
  },
}
</script>
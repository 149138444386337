<template>
  <b-row>
    <b-col lg="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>OPERATIONAL HISTORY - {{ fleet.name }}</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="4">
              <b-form-group label="Type" label-for="type">
                <b-form-select  
                  plain
                  size="sm"
                  placeholder="Select type..."
                  v-model="params.type"
                >
                  <option value="">All</option>
                  <option value="progress">Progress</option>
                  <option value="finish">finish</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Date" label-for="date">
                <date-picker v-model="dateRange" type="date" range placeholder="Select date range" format="DD-MM-YYYY"></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Filter" label-for="filter">
                <b-button variant="primary" type="button" @click="fetchVoyageByTrips"><i class="fa fa-filter"></i></b-button>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="12">
                  <div class="d-flex flex-column mb-2">
                      <div class="d-flex overflow-auto" v-if="list.length > 0">
                        <table class="table table-striped ">
                          <thead>
                            <tr>
                              <th rowspan="2" style="width:5px" class="text-center  text-truncate align-middle">NO</th>
                              <th rowspan="2" class="text-center  text-truncate align-middle">FLEET</th>                       
                              <th rowspan="2" class="text-center  text-truncate align-middle">CARGO TYPE</th>                       
                              <th rowspan="2" class="text-center  text-truncate align-middle">CARGO LOADING</th>                       
                              <th rowspan="2" class="text-center  text-truncate align-middle">CARGO UNLOADING</th>  
                              <th rowspan="2" class=" text-truncate align-middle">START DATE</th>
                              <th rowspan="2" class=" text-truncate align-middle">END DATE</th>
                              <th colspan="18" class=" text-center">Port of Loading</th>
                              <th colspan="19" class=" text-center">Port of Discharge</th>
                              <th rowspan="2" class=" text-center align-middle" style="width:5px; position: sticky; right: 0; z-index: 1">Action</th>
                            </tr>
                            
                            <tr>
                              <th class=" text-truncate">Agen POL</th>
                              <th class=" text-truncate">POL Grab Type</th>
                              <th class=" text-truncate">POL FC Name</th>
                              <th class=" text-truncate">POL MV Name</th>
                              <th class=" text-truncate">Arival Time POL</th>
                              <th class=" text-truncate">BL Date</th>
                              <th class=" text-truncate">NOR</th>
                              <th class=" text-truncate">NOA</th>
                              <th class=" text-truncate">Anchor Up</th>
                              <th class=" text-truncate">In Position / Berthing</th>
                              <th class=" text-truncate">Commenced</th>
                              <th class=" text-truncate">Completed</th>
                              <th class=" text-truncate">Draught Survey</th>
                              <th class=" text-truncate">Cast In</th>
                              <th class=" text-truncate">Cast Out</th>
                              <th class=" text-truncate">LHV</th>
                              <th class=" text-truncate">Document on Board</th>
                              <th class=" text-truncate">Departure to POD</th>
                              
                              <th class=" text-truncate">Agen POD</th>
                              <th class=" text-truncate">POD Grab Type</th>
                              <th class=" text-truncate">POD FC Name</th>
                              <th class=" text-truncate">POD MV Name</th>
                              <th class=" text-truncate">Arival Time POD</th>
                              <th class=" text-truncate">BL Date</th>
                              <th class=" text-truncate">NOR</th>
                              <th class=" text-truncate">NOA</th>
                              <th class=" text-truncate">Anchor Up</th>
                              <th class=" text-truncate">In Position / Berthing</th>
                              <th class=" text-truncate">Commenced</th>
                              <th class=" text-truncate">Completed</th>
                              <th class=" text-truncate">Draught Survey</th>
                              <th class=" text-truncate">Cast In</th>
                              <th class=" text-truncate">Cast Out</th>
                              <th class=" text-truncate">LHV</th>
                              <th class=" text-truncate">Document on Board</th>
                              <th class=" text-truncate">Departure to POL</th>
                              <th class=" text-truncate">Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(state, index) in list" :key="index">
                              <td>{{index + 1}}</td>
                              <td class="text-truncate">
                                <div class="d-flex flex-column align-items-center" @click="openSlideDetailRealisasi('PREVIEW', state)">
                                  <template v-if="state?.vehicle?.length > 0">
                                    <strong v-for="(val, index) in state?.vehicle" :key="index" class="cursor-pointer text-primary">{{ val?.name ?? '-' }}</strong>
                                  </template>
                                  <b-badge 
                                    :class="`border text-xl ${state?.tripRecord?.status === 'Progress' ? 'border-dark text-warning' : 
                                    state?.tripRecord?.status === 'Finish' ? 'border-dark text-success' : 
                                    'border-dark text-primary'} text-uppercase mr-2`" variant="none"
                                  >{{ state?.tripRecord?.status ?? '-' }}</b-badge>
                                </div>
                              </td>
                              <td>{{ state?.tripRecord?.cargoType ?? '-' }}</td>
                              <td>{{ state?.tripRecord?.cargoLoading ?? '0' }}</td>
                              <td>{{ state?.tripRecord?.cargoUnloading ?? '0' }}</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.startDate ? moment(state?.tripRecord?.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.endDate ? moment(state?.tripRecord?.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">{{ state?.tripRecord.agentPol || '-' }}</td>
                              <td class="text-truncate text-capitalize">{{ state?.tripRecord.polGrabType  || '-' }}</td>
                              <td class="text-truncate text-capitalize">{{ state?.tripRecord.polFcName || '-' }}</td>
                              <td class="text-truncate text-capitalize">{{ state?.tripRecord.polMvName || '-' }}</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polArrivalTime ? moment(state?.tripRecord?.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">{{ state?.tripRecord?.polBlDate }}</td>
                              <td class="text-truncate text-center">{{ state?.tripRecord?.polNor }}</td>
                              <td class="text-truncate text-center">{{ state?.tripRecord?.polNoa }}</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polAnchorUp ? moment(state?.tripRecord?.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polBerthing ? moment(state?.tripRecord?.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polCommenced ? moment(state?.tripRecord?.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polCompleted ? moment(state?.tripRecord?.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">{{state?.tripRecord?.polDraughtSurvey ?? 0}}</td>
                              <td class="text-truncate text-center">{{ state?.tripRecord?.polCastIn }}</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polCastOut ? moment(state?.tripRecord?.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td> 
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polLhv ? moment(state?.tripRecord?.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polDocOnBoard ? moment(state?.tripRecord?.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polDeparture ? moment(state?.tripRecord?.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">{{ state?.tripRecord.agentPod || '-' }}</td>
                              <td class="text-truncate text-capitalize">{{ state?.tripRecord.podGrabType  || '-' }}</td>
                              <td class="text-truncate text-capitalize">{{ state?.tripRecord.podFcName || '-' }}</td>
                              <td class="text-truncate text-capitalize">{{ state?.tripRecord.podMvName || '-' }}</td>
                              <!-- <td class="text-truncate text-center">-</td> -->
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podArrivalTime ? moment(state?.tripRecord?.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">{{ state?.tripRecord?.podBlDate }}</td>
                              <td class="text-truncate text-center">{{ state?.tripRecord?.podNor }}</td>
                              <td class="text-truncate text-center">{{ state?.tripRecord?.podNoa }}</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podAnchorUp ? moment(state?.tripRecord?.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podBerthing ? moment(state?.tripRecord?.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podCommenced ? moment(state?.tripRecord?.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podCompleted ? moment(state?.tripRecord?.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">{{state?.tripRecord?.podDraughtSurvey ?? 0}}</td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podCastOut ? moment(state?.tripRecord?.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td> 
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podLhv ? moment(state?.tripRecord?.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podDocOnBoard ? moment(state?.tripRecord?.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podDeparture ? moment(state?.tripRecord?.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-center align-middle " style="width:5px; position: sticky; right: 0; z-index: 1">
                                <b-dropdown
                                  size="sm"
                                  variant="dark"
                                  toggle-class="text-decoration-none"
                                  text="Actions"
                                  dropright
                                  no-caret
                                >
                                  <template v-slot:button-content>
                                    Actions
                                    <i class="fa fa-chevron-down ml-1"></i>
                                  </template>
                                  <b-dropdown-item
                                    variant="primary"
                                    @click="openSlideDetailRealisasi('PREVIEW', state)" 
                                  >
                                    <i class="fas fa-eye mr-2"></i> Detail
                                  </b-dropdown-item>
                                  <b-dropdown-item variant="primary" @click="openSlideEditOperational('EDIT', state)">
                                    <i class="fas fa-arrow-circle-down mr-2"></i> Edit
                                  </b-dropdown-item>
                                  <b-dropdown-divider v-if="!state?.activities" />
                                  <b-dropdown-item
                                    v-if="!state?.activities"
                                    href="#"
                                    variant="danger"
                                    @click="onDeleteTripRecord(state?.tripRecord.id)"
                                  ><i class="fa fa-trash mr-2"></i> Remove
                                  </b-dropdown-item>
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </b-col>
                <b-col md="12" class="text-center">
                  <div class="mt-3" v-if="list.length > 0">
                    <b-pagination v-model="page" :total-rows="1" align="center" />
                  </div>
                  <strong class="text-center text-muted" v-else>Data not found.</strong>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>

     <!-- SLIDE - DETAIL REALISASI -->
     <b-col md="12">
        <b-sidebar
          v-model="slideDetailRealisasi"
          id="slideDetailRealisasi"
          :title="`Detail Real Shipment`"
          width="100%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideDetailRealisasi 
              :id="id" 
              :closeSlideDetailRealisasi="closeSlideDetailRealisasi" 
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - DETAIL REALISASI END -->

      <!-- SLIDE - EDIT OPERATIONAL -->
      <b-col md="12">
        <b-sidebar
          v-model="slideEditOperational"
          id="slideEditOperational"
          :title="`Edit Operational`"
          width="80%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideEditOperational 
              :id="id" 
              :closeSlideEditOperational="closeSlideEditOperational" 
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - EDIT OPERATIONAL END -->
  </b-row>
</template>

<script>
import { fleetsActions, voyagesActions } from '@src/Utils/helper'
import SlideDetailRealisasi from '../Busdev/Dashboard/SlideDetailRealisasi.vue'
import SlideEditOperational from './SlideEditOperational.vue'
import moment from 'moment'
import CustomNav from '@/src/components/global/CustomNav.vue';

export default {
  name: 'TabVoyageByTrip',
  props:{
    fleet:{
      type:Object,
      default:() => {}
    }
  },
  data () {
    return {
      slideDetailRealisasi: false,
      slideEditOperational: false,

      id: null,

      dateRange: [new Date(moment().startOf('year')), new Date(moment().endOf('year'))],
      page:1,
      params:{
        type:'', // 'progress', 'finish'
        startDate:null,
        endDate:null,
      },

      list:[],
      meta:null,
    }
  },
  components:{
    SlideDetailRealisasi,
    SlideEditOperational,
    CustomNav
  },
  computed: {
  },
  mounted () {
    this.fetchVoyageByTrips()
  },
  methods:{
    ...voyagesActions,
    closeSlideDetailRealisasi(){
      this.slideDetailRealisasi = false
      this.matrixData = null
      this.id = null
    },  
    openSlideDetailRealisasi(value, state){
      this.id = state?.tripRecord?.id
      this.slideDetailRealisasi = !this.slideDetailRealisasi;

      if(state?.tripRecord?.id) this.id = state?.tripRecord?.id
    }, 
    closeSlideEditOperational(){
      this.slideEditOperational = false
      this.matrixData = null
      this.id = null
    },  
    openSlideEditOperational(value, state){
      this.id = state?.tripRecord?.id
      this.slideEditOperational = !this.slideEditOperational;

      if(state?.tripRecord?.id) this.id = state?.tripRecord?.id
    },
    async fetchVoyageByTrips(page) {
      let params = {
        page: 1,
        perPage: 25,
        vehicleId: Number(this.$route.query.vehicleId),
        ...this.params,
        startDate: this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('DD-MM-YYYY') : null,
        endDate: this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('DD-MM-YYYY') : null, 
      }

      // Iterate over each property in params
      for (const prop in params) {
        if (params.hasOwnProperty(prop)) {
          // Check conditions for each property and delete if necessary
          if ((params[prop] === '') || (params[prop] === null)) {
            delete params[prop];
          }
        }
      }

      const res = await this.getVoyageTrips(params)

      if (res.status == 'success') {
        this.list = res.data
      } else {
        this.list = []
      }
    },  

    async onDeleteTripRecord(id) {
      this.$swal({
        title: 'Delete Trip Record?',
        text: `Trip will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteTripRecordById({ id })
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.fetchVoyageByTrips()
            this.$swal(
              `Trip deleted!`,
              `Trip successfully deleted`,
              'success'
            )
          }
        }
      })
    },
  }
}
</script>
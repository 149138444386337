var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Root Cause Analysis Form")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$companyList$find, _vm$personal_fullname, _vm$state$find, _vm$marital$find, _vm$companyList$find2, _vm$types$find;
        return [_c('div', [_vm.$route.params.id && _vm.isFetching ? _c('div', [_c('p', [_vm._v("Fetching Data...")])]) : _c('b-row', [_c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mb-3 d-flex justify-content-between",
          attrs: {
            "md": "12"
          }
        }, [_c('h5', {
          staticClass: "font-500"
        }, [_vm._v("Assessor of the Accident")]), _vm.$route.params.id ? _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.isEditMode = !_vm.isEditMode;
            }
          }
        }, [_vm._v(_vm._s(_vm.isEditMode ? 'Preview' : 'Edit') + " RCA")]) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "px-3"
        }, [_c('b-row', {
          staticClass: "pt-3",
          staticStyle: {
            "background": "#f2f1f1"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name",
            "label-for": "Name",
            "label-class": "font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "type": "text",
            "placeholder": "Name..."
          },
          model: {
            value: _vm.assessor_fullname,
            callback: function callback($$v) {
              _vm.assessor_fullname = $$v;
            },
            expression: "assessor_fullname"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.assessor_fullname || '-'))])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company",
            "label-for": "company",
            "label-class": "font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.companyList,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Company")])];
            },
            proxy: true
          }], null, false, 3660131153),
          model: {
            value: _vm.assessor_company_id,
            callback: function callback($$v) {
              _vm.assessor_company_id = $$v;
            },
            expression: "assessor_company_id"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.assessor_company_id ? (_vm$companyList$find = _vm.companyList.find(function (el) {
          return el.value == _vm.assessor_company_id;
        })) === null || _vm$companyList$find === void 0 ? void 0 : _vm$companyList$find.text : '-'))])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Position",
            "label-for": "porisition",
            "label-class": "font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "type": "text",
            "placeholder": "Position..."
          },
          model: {
            value: _vm.assessor_position,
            callback: function callback($$v) {
              _vm.assessor_position = $$v;
            },
            expression: "assessor_position"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.assessor_position || '-'))])], 1)], 1)], 1)], 1)])], 1)], 1), _c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_c('TabPaneRca', {
          attrs: {
            "activeTab": _vm.activeTab
          },
          on: {
            "clickTab": function clickTab(val) {
              return _vm.activeTab = val;
            }
          }
        })], 1), _vm.activeTab == 1 ? _c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "d-flex flex-column align-items-center justify-content-center",
          attrs: {
            "cols": "3"
          }
        }, [_c('b-avatar', {
          attrs: {
            "size": "110px",
            "variant": "warning"
          }
        }), _vm.isEditMode ? _c('b-form-group', {
          attrs: {
            "label": "Photo"
          }
        }, [_c('b-form-file', {
          attrs: {
            "accept": "image"
          },
          model: {
            value: _vm.personal_photo,
            callback: function callback($$v) {
              _vm.personal_photo = $$v;
            },
            expression: "personal_photo"
          }
        })], 1) : _c('div', [_c('h5', {
          staticClass: "my-2 font-500"
        }, [_vm._v(_vm._s((_vm$personal_fullname = _vm.personal_fullname) !== null && _vm$personal_fullname !== void 0 ? _vm$personal_fullname : '-'))]), _c('h6', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s(_vm.assessor_position || '-'))])])], 1), _c('b-col', {
          attrs: {
            "cols": "9"
          }
        }, [_c('div', {
          staticClass: "pr-3"
        }, [_c('b-row', {
          staticClass: "pt-3",
          staticStyle: {
            "background": "#f2f1f1"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "name",
            "label": "Name",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "type": "text",
            "placeholder": "Name..."
          },
          model: {
            value: _vm.personal_fullname,
            callback: function callback($$v) {
              _vm.personal_fullname = $$v;
            },
            expression: "personal_fullname"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.personal_fullname || '-'))])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Date of Birth",
            "label-for": "date-of-birth",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _c('date-picker', {
          staticClass: "w-100",
          attrs: {
            "type": "date",
            "format": "YYYY-MM-DD",
            "value-type": "format",
            "placeholder": "Select date"
          },
          model: {
            value: _vm.personal_birthdate,
            callback: function callback($$v) {
              _vm.personal_birthdate = $$v;
            },
            expression: "personal_birthdate"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.moment(_vm.personal_birthdate).format('DD MMM YYYY')))])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Gender",
            "label-for": "gender",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _vm._l(_vm.state, function (item, index) {
          return _c('b-form-radio', {
            key: "A-".concat(index),
            attrs: {
              "inline": "",
              "name": item.name,
              "value": item.value
            },
            model: {
              value: _vm.personal_gender,
              callback: function callback($$v) {
                _vm.personal_gender = $$v;
              },
              expression: "personal_gender"
            }
          }, [_vm._v(_vm._s(item.label))]);
        }) : _c('h6', [_vm._v(_vm._s((_vm$state$find = _vm.state.find(function (el) {
          return el.value == _vm.personal_gender;
        })) === null || _vm$state$find === void 0 ? void 0 : _vm$state$find.label))])], 2)], 1)], 1), _c('b-row', {
          staticClass: "pt-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Marital Status",
            "label-for": "marital-status",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.marital,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Marital Status")])];
            },
            proxy: true
          }], null, false, 3006925900),
          model: {
            value: _vm.personal_marital_status,
            callback: function callback($$v) {
              _vm.personal_marital_status = $$v;
            },
            expression: "personal_marital_status"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.personal_marital_status ? (_vm$marital$find = _vm.marital.find(function (el) {
          return el.value == _vm.personal_marital_status;
        })) === null || _vm$marital$find === void 0 ? void 0 : _vm$marital$find.text : '-'))])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company",
            "label-for": "company",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.companyList,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Company")])];
            },
            proxy: true
          }], null, false, 3660131153),
          model: {
            value: _vm.personal_company_id,
            callback: function callback($$v) {
              _vm.personal_company_id = $$v;
            },
            expression: "personal_company_id"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.personal_company_id ? (_vm$companyList$find2 = _vm.companyList.find(function (el) {
          return el.value == _vm.personal_company_id;
        })) === null || _vm$companyList$find2 === void 0 ? void 0 : _vm$companyList$find2.text : '-'))])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Length of Work",
            "label-for": "lenght-of-work",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.working_experience,
            callback: function callback($$v) {
              _vm.working_experience = $$v;
            },
            expression: "working_experience"
          }
        })], 1) : _c('h6', [_vm._v(_vm._s(_vm.working_experience || '-'))])], 1)], 1)], 1), _c('b-row', {
          staticClass: "pt-3",
          staticStyle: {
            "background": "#f2f1f1"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Position",
            "label-for": "position",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "type": "text",
            "placeholder": "Position..."
          },
          model: {
            value: _vm.personal_position,
            callback: function callback($$v) {
              _vm.personal_position = $$v;
            },
            expression: "personal_position"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.personal_position || '-'))])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type",
            "label-for": "type",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _vm._l(_vm.types, function (item, index) {
          return _c('b-form-radio', {
            key: "B-".concat(index),
            attrs: {
              "inline": "",
              "name": item.name,
              "value": item.value
            },
            model: {
              value: _vm.type,
              callback: function callback($$v) {
                _vm.type = $$v;
              },
              expression: "type"
            }
          }, [_vm._v(_vm._s(item.label))]);
        }) : _vm._e(), _c('h6', [_vm._v(_vm._s(_vm.type ? (_vm$types$find = _vm.types.find(function (el) {
          return el.value == _vm.type;
        })) === null || _vm$types$find === void 0 ? void 0 : _vm$types$find.label : '-'))])], 2)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Sign Picture",
            "label-for": "Sign-Picture",
            "label-class": "mb-0 font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-file', {
          attrs: {
            "accept": "image"
          },
          model: {
            value: _vm.personal_sign,
            callback: function callback($$v) {
              _vm.personal_sign = $$v;
            },
            expression: "personal_sign"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.personal_sign || '-'))])], 1)], 1)], 1)], 1)])], 1)], 1) : _vm._e(), _vm.activeTab == 2 ? _c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "px-3"
        }, [_c('b-row', [_c('b-col', {
          staticClass: "p-0 mb-0",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          staticClass: "bg-primary mb-0",
          attrs: {
            "show": ""
          }
        }, [_c('strong', {
          staticClass: "text-center text-white"
        }, [_vm._v("BACKGROUND INFORMATION ABOUT THE ACCIDENT ORPROBLEM")])])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "mb-2 py-3",
          staticStyle: {
            "background": "#f9fafb"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('h6', [_c('strong', [_vm._v("What part, Product, Process, or Services Involved")])])]), _c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_vm.isEditMode ? _c('b-form-textarea', {
          attrs: {
            "rows": "3"
          },
          model: {
            value: _vm.explanation_damage,
            callback: function callback($$v) {
              _vm.explanation_damage = $$v;
            },
            expression: "explanation_damage"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.explanation_damage || '-'))])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "mt-2 mb-2"
        }, [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('h6', [_c('strong', [_vm._v("Explain the Problem(s)")])])]), _c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_vm.isEditMode ? _c('b-form-textarea', {
          attrs: {
            "rows": "4"
          },
          model: {
            value: _vm.explanation_problem,
            callback: function callback($$v) {
              _vm.explanation_problem = $$v;
            },
            expression: "explanation_problem"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.explanation_problem || '-'))])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "mt-2 mb-2 py-3",
          staticStyle: {
            "background": "#f9fafb"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('h6', {
          staticClass: "font-500"
        }, [_c('strong', [_vm._v(" when in the life cycle of the unit is the problem detected ? (e.g at the source, intial inspection, final inspection, end user, etc)-Explain ")])])]), _c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_vm.isEditMode ? _c('b-form-textarea', {
          attrs: {
            "rows": "4"
          },
          model: {
            value: _vm.explanation_detected,
            callback: function callback($$v) {
              _vm.explanation_detected = $$v;
            },
            expression: "explanation_detected"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.explanation_detected || '-'))])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          staticClass: "p-0 mt-4 mb-0",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          staticClass: "bg-success mb-0",
          attrs: {
            "show": ""
          }
        }, [_c('strong', {
          staticClass: "text-center text-white"
        }, [_vm._v("EXPECTATIONS !")])])], 1), _c('b-col', {
          staticClass: "p-0",
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "px-3"
        }, [_c('b-row', {
          staticClass: "mb-2 py-3",
          staticStyle: {
            "background": "#F9FAFB"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('h6', [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v("RESULT OF DEFINING THE PROBLEM")])])]), _c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_vm.isEditMode ? _c('b-form-textarea', {
          attrs: {
            "rows": "4"
          },
          model: {
            value: _vm.explanation_result,
            callback: function callback($$v) {
              _vm.explanation_result = $$v;
            },
            expression: "explanation_result"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.explanation_result || '-'))])], 1)], 1)], 1)])], 1)], 1)])], 1)], 1) : _vm._e(), _vm.activeTab == 3 ? _c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("WHY")]), _c('th', {
          attrs: {
            "width": "45%"
          }
        }, [_vm._v("DEFECT, PROBLEM OR ERROR")]), _c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("DESCRIPTION OF CAUSE")]), _vm.isEditMode ? _c('th', [_vm._v("Action")]) : _vm._e()]), _vm._l(_vm.whys, function (why, index) {
          return _c('tbody', {
            key: 'C-' + index
          }, [_c('tr', [_c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', {
            attrs: {
              "width": "45%"
            }
          }, [_vm.isEditMode ? _c('b-form-input', {
            staticClass: "w-100",
            attrs: {
              "type": "text",
              "placeholder": "DEFECT, PROBLEM OR ERROR ..."
            },
            model: {
              value: why.defect,
              callback: function callback($$v) {
                _vm.$set(why, "defect", $$v);
              },
              expression: "why.defect"
            }
          }) : _c('h6', {
            staticClass: "font-500"
          }, [_vm._v(_vm._s(why.defect || '-'))])], 1), _c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_vm.isEditMode ? _c('b-form-textarea', {
            staticClass: "w-100",
            attrs: {
              "placeholder": "CAUSE"
            },
            model: {
              value: why.cause,
              callback: function callback($$v) {
                _vm.$set(why, "cause", $$v);
              },
              expression: "why.cause"
            }
          }) : _c('h6', [_vm._v(_vm._s(why.cause || '-'))])], 1), _vm.isEditMode ? _c('td', [_c('b-button', {
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.whys.splice(index, 1);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])], 1) : _vm._e()])]);
        })], 2), _c('b-row', [_c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_vm.isEditMode ? _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": _vm.addWhys
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Data")]) : _vm._e()], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("WHAT IS? "), _c('br'), _vm._v(" WHAT IS NOT?")]), _c('th', {
          attrs: {
            "width": "40%"
          }
        }, [_vm._v("WHAT IS ?")]), _c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("WHAT IS NOT ?")]), _vm.isEditMode ? _c('th', [_vm._v("Action")]) : _vm._e()]), _c('tbody', _vm._l(_vm.whatisLength, function (item, index) {
          return _c('tr', {
            key: 'D-' + index
          }, [_c('td', {
            staticClass: "pl-5"
          }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
            staticClass: "w-100",
            attrs: {
              "placeholder": "WHAT IS?"
            },
            model: {
              value: _vm.whatis[index - 1],
              callback: function callback($$v) {
                _vm.$set(_vm.whatis, index - 1, $$v);
              },
              expression: "whatis[index - 1]"
            }
          }) : _c('h6', {
            staticClass: "font-500"
          }, [_vm._v(_vm._s(_vm.whatis[index - 1] || '-'))])], 1), _c('td', [_vm.isEditMode ? _c('b-form-textarea', {
            staticClass: "w-100",
            attrs: {
              "placeholder": "WHAT IS NOT?"
            },
            model: {
              value: _vm.whatisnot[index - 1],
              callback: function callback($$v) {
                _vm.$set(_vm.whatisnot, index - 1, $$v);
              },
              expression: "whatisnot[index - 1]"
            }
          }) : _c('h6', [_vm._v(_vm._s(_vm.whatisnot[index - 1] || '-'))])], 1), _vm.isEditMode ? _c('td', [_c('b-button', {
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                _vm.whatisLength -= 1;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])], 1) : _vm._e()]);
        }), 0)]), _vm.isEditMode ? _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": _vm.addWhatis
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Data")])], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.activeTab == 4 ? _c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Vessel"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.vessel.name,
            "placeholder": "Select Vessel",
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "FMEA CASE :"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "type": "text",
            "placeholder": "FMEA CASE"
          },
          model: {
            value: _vm.fmea_case,
            callback: function callback($$v) {
              _vm.fmea_case = $$v;
            },
            expression: "fmea_case"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "FMEA Date (Orig.) :"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "datetime",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-type": "format",
            "placeholder": "Select date and time"
          },
          model: {
            value: _vm.fmea_date,
            callback: function callback($$v) {
              _vm.fmea_date = $$v;
            },
            expression: "fmea_date"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Date of Loss"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime",
            "value": _vm.date_of_loss,
            "placeholder": "Select date and time",
            "readonly": ""
          }
        })], 1)], 1)], 1), _c('hr'), _vm._l(_vm.identificationLength, function (item, index) {
          return _c('b-row', {
            key: 'E-' + index,
            staticClass: "mx-0"
          }, [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-row', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: _vm.identifications,
              expression: "identifications"
            }]
          }, [_vm._l(_vm.identifications, function (form, i) {
            return [_c('b-col', {
              key: i,
              staticClass: "py-3",
              style: {
                background: [0, 1, 4, 5, 8, 9].includes(i) ? '#F2F1F1' : '#F9FAFB'
              },
              attrs: {
                "md": '6'
              }
            }, [form.description ? _c('div', [_c('div', {
              staticClass: "d-flex mb-2 align-items-center"
            }, [_c('b-badge', {
              staticClass: "mr-2 p-1 px-2",
              staticStyle: {
                "height": "max-content"
              },
              attrs: {
                "variant": "success"
              }
            }, [_vm._v(_vm._s(form.title))]), _c('h6', {
              staticClass: "font-500"
            }, [_vm._v(_vm._s(form.description))])], 1), _vm.isEditMode ? _c('b-form-textarea', {
              staticStyle: {
                "min-height": "auto"
              },
              model: {
                value: form.answers[index - 1],
                callback: function callback($$v) {
                  _vm.$set(form.answers, index - 1, $$v);
                },
                expression: "form.answers[index -1]"
              }
            }) : _c('h6', [_vm._v(_vm._s(form.answers[index - 1] || '-'))])], 1) : _c('div', [_c('div', {
              staticClass: "d-flex justify-content-between mb-2"
            }, [_c('h6', {
              staticStyle: {
                "font-weight": "700"
              }
            }, [_vm._v(_vm._s(form.title) + " " + _vm._s(form.title !== 'RPN' ? '(1 to 10)' : '(Risk Priority Number)'))]), form.title !== 'RPN' ? _c('b-button', {
              directives: [{
                name: "b-modal",
                rawName: "v-b-modal",
                value: "modal-detail-rating-".concat(i, "-").concat(index),
                expression: "`modal-detail-rating-${i}-${index}`"
              }],
              staticClass: "py-1 font-size-14",
              staticStyle: {
                "height": "max-content"
              },
              attrs: {
                "size": "sm",
                "variant": "primary"
              }
            }, [_vm._v("Detail Rating")]) : _vm._e()], 1), _vm.isEditMode ? _c('b-form-input', {
              attrs: {
                "type": "number",
                "readonly": form.title === 'RPN',
                "min": form.title !== 'RPN' ? 1 : '',
                "max": form.title !== 'RPN' ? 10 : ''
              },
              on: {
                "input": function input($event) {
                  return _vm.setRPN(index - 1);
                }
              },
              model: {
                value: form.answers[index - 1],
                callback: function callback($$v) {
                  _vm.$set(form.answers, index - 1, $$v);
                },
                expression: "form.answers[index - 1]"
              }
            }) : _c('div', [form.title !== 'RPN' ? _c('div', {
              staticClass: "d-flex"
            }, [_c('b-badge', {
              staticClass: "p-2",
              style: {
                'minWidth': '38px',
                height: 'max-content',
                background: _vm.getRatingValue(form.answers[index - 1], form.title).color
              }
            }, [_c('h5', {
              staticClass: "text-white"
            }, [_c('b', [_vm._v(" " + _vm._s(form.answers[index - 1]) + " ")])])]), _c('div', {
              staticClass: "ml-2"
            }, [_c('h6', [_c('b', [_vm._v("Rating Mean:")])]), _c('h6', [_vm._v(_vm._s(_vm.getRatingValue(form.answers[index - 1], form.title).label))])])], 1) : _c('div', [_c('b-badge', {
              staticClass: "p-2",
              style: {
                'minWidth': '38px'
              },
              attrs: {
                "variant": "info"
              }
            }, [_c('h5', {
              staticClass: "text-white"
            }, [_c('b', [_vm._v(" " + _vm._s(form.answers[index - 1]) + " ")])])])], 1)]), _c('b-modal', {
              attrs: {
                "id": "modal-detail-rating-".concat(i, "-").concat(index),
                "title": "Detail Rating ".concat(form.title),
                "ok-only": "",
                "ok-title": "Close"
              }
            }, [form.title === 'Occurrence' ? [_c('DetailRatingTemplate', {
              attrs: {
                "ratings": _vm.detailRating.occurrence,
                "headerTitle": _vm.detailRating.occurrenceHeader
              }
            })] : _vm._e(), form.title === 'Detection' ? [_c('DetailRatingTemplate', {
              attrs: {
                "ratings": _vm.detailRating.detection,
                "headerTitle": _vm.detailRating.detectionHeader
              }
            })] : _vm._e(), form.title === 'Occurrence, Severity' ? [_c('DetailRatingTemplate', {
              attrs: {
                "ratings": _vm.detailRating.saverity,
                "headerTitle": _vm.detailRating.saverityHeader
              }
            })] : _vm._e()], 2)], 1), _c('b-row', [_c('b-col', {
              attrs: {
                "md": "12"
              }
            }), _c('b-col', {
              attrs: {
                "md": "12"
              }
            }, [_c('div', {
              staticClass: "p-4"
            })])], 1)], 1)];
          })], 2)], 1), _c('b-col', {
            staticClass: "text-right text-primary my-3",
            attrs: {
              "md": "12"
            }
          }, [_c('b-button', {
            staticClass: "px-5",
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                _vm.identificationLength -= 1;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          }), _vm._v("Delete")])], 1), _c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('hr')])], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": _vm.addIdentifications
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Data")])], 1)], 1), _c('hr')], 2)], 1)], 1) : _vm._e(), _vm.activeTab == 5 ? _c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_vm._l(_vm.implementations, function (implementation, index) {
          var _implementation$tool;
          return _c('div', {
            key: 'G-' + index
          }, [_c('div', {
            staticClass: "border-bottom pb-2 mb-3 d-flex justify-content-between align-items-center"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "rounded-sm bg-primary",
            staticStyle: {
              "width": "max-content",
              "padding": "10px 12px !important"
            }
          }, [_c('h5', {
            staticClass: "text-white"
          }, [_vm._v("#" + _vm._s(index + 1))])]), _c('h6', {
            staticClass: "font-500 ml-2"
          }, [_vm._v("Implement Corrective")])]), _c('b-button', {
            staticClass: "rounded",
            class: {
              active: implementation.visible
            },
            staticStyle: {
              "width": "max-content",
              "height": "max-content"
            },
            attrs: {
              "variant": "outline-secondary"
            },
            on: {
              "click": function click($event) {
                implementation.visible = !implementation.visible;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-chevron-right m-0",
            style: {
              rotate: implementation.visible ? '90deg' : '0deg',
              transtiion: 'all 300ms'
            }
          })])], 1), _c('b-collapse', {
            attrs: {
              "id": "implement-cerrective-".concat(index)
            },
            model: {
              value: implementation.visible,
              callback: function callback($$v) {
                _vm.$set(implementation, "visible", $$v);
              },
              expression: "implementation.visible"
            }
          }, [_c('b-row', {
            staticClass: "px-3"
          }, [_c('b-col', {
            staticClass: "pt-2",
            staticStyle: {
              "background": "#F9FAFB"
            },
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Tool",
              "label-for": "tool",
              "label-class": "font-500"
            }
          }, [_vm.isEditMode ? [index <= 3 ? _c('b-form-select', {
            staticClass: "w-100",
            attrs: {
              "plain": "",
              "size": "sm"
            },
            on: {
              "change": function change($event) {
                return _vm.handleImplementToolChange(index);
              }
            },
            model: {
              value: implementation.tool,
              callback: function callback($$v) {
                _vm.$set(implementation, "tool", $$v);
              },
              expression: "implementation.tool"
            }
          }, [_c('b-form-select-option', {
            attrs: {
              "value": "Five Whys"
            }
          }, [_vm._v("Five Whys")]), _c('b-form-select-option', {
            attrs: {
              "value": "Comparative Analysis"
            }
          }, [_vm._v("Comparative Analysis")]), _c('b-form-select-option', {
            attrs: {
              "value": "Failure Mode and Effect Analysis (FMEA)"
            }
          }, [_vm._v("Failure Mode and Effect Analysis (FMEA)")]), _c('b-form-select-option', {
            attrs: {
              "value": "DOE"
            }
          }, [_vm._v("DOE")])], 1) : _c('b-form-input', {
            staticClass: "w-100",
            attrs: {
              "type": "text",
              "placeholder": "Tool"
            },
            model: {
              value: implementation.tool,
              callback: function callback($$v) {
                _vm.$set(implementation, "tool", $$v);
              },
              expression: "implementation.tool"
            }
          })] : [_c('h6', [_vm._v(_vm._s((_implementation$tool = implementation.tool) !== null && _implementation$tool !== void 0 ? _implementation$tool : '-'))])]], 2)], 1), _c('b-col', {
            staticClass: "pt-2",
            staticStyle: {
              "background": "#F9FAFB"
            },
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Question",
              "label-for": "question",
              "label-class": "font-500"
            }
          }, [_vm.isEditMode ? _c('b-form-textarea', {
            staticClass: "w-100",
            attrs: {
              "row": "2",
              "placeholder": "Question"
            },
            model: {
              value: implementation.question,
              callback: function callback($$v) {
                _vm.$set(implementation, "question", $$v);
              },
              expression: "implementation.question"
            }
          }) : _c('h6', [_vm._v(_vm._s(implementation.question || '-'))])], 1)], 1), _c('b-col', {
            staticClass: "pt-2",
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Findings",
              "label-for": "findings",
              "label-class": "font-500"
            }
          }, [_vm.isEditMode ? _c('b-form-textarea', {
            staticClass: "w-100",
            attrs: {
              "row": "2",
              "placeholder": "Findings"
            },
            model: {
              value: implementation.finding,
              callback: function callback($$v) {
                _vm.$set(implementation, "finding", $$v);
              },
              expression: "implementation.finding"
            }
          }) : _c('h6', [_vm._v(_vm._s(implementation.finding || '-'))])], 1)], 1), _c('b-col', {
            staticClass: "pt-2",
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Corrective Actions",
              "label-for": "corrective-actions",
              "label-class": "font-500"
            }
          }, [_vm.isEditMode ? _c('b-form-textarea', {
            staticClass: "w-100",
            attrs: {
              "row": "2",
              "placeholder": "Corrective Actions"
            },
            model: {
              value: implementation.corrective_action,
              callback: function callback($$v) {
                _vm.$set(implementation, "corrective_action", $$v);
              },
              expression: "implementation.corrective_action"
            }
          }) : _c('h6', [_vm._v(_vm._s(implementation.corrective_action || '-'))])], 1)], 1), _c('b-col', {
            staticClass: "text-right text-primary mb-4",
            attrs: {
              "md": "12"
            }
          }, [_vm.isEditMode ? _c('b-button', {
            staticClass: "w-100",
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.implementations.splice(index, 1);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          }), _vm._v(" Delete Implement Corrective")]) : _vm._e()], 1)], 1)], 1)], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.isEditMode ? _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": _vm.addImplementations
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Data")]) : _vm._e()], 1)], 1), _c('hr')], 2)], 1)], 1) : _vm._e(), _vm.activeTab == 6 ? _c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mt-2",
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _c('b-row', {
          staticClass: "bg-light mx-0 pt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Diagram Name :",
            "label-for": "diagram-name",
            "label-class": "font-500"
          }
        }, [_vm.isEditMode ? _c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "type": "text",
            "placeholder": "Diagram Name"
          },
          model: {
            value: _vm.diagram_title,
            callback: function callback($$v) {
              _vm.diagram_title = $$v;
            },
            expression: "diagram_title"
          }
        }) : _c('h6', [_vm._v(_vm._s(_vm.diagram_title || '-'))])], 1)], 1), _vm.isEditMode ? _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Photo",
            "label-for": "photo",
            "label-class": "font-500"
          }
        }, [_c('b-form-file', {
          attrs: {
            "accept": "image"
          },
          model: {
            value: _vm.diagram_photo,
            callback: function callback($$v) {
              _vm.diagram_photo = $$v;
            },
            expression: "diagram_photo"
          }
        })], 1)], 1) : _vm._e()], 1), _c('hr'), _vm._l(_vm.diagrams, function (diagram, index) {
          return _c('div', {
            key: 'J' + index
          }, [_c('div', {
            staticClass: "border-bottom pb-2 mb-3 d-flex justify-content-between align-items-center"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "rounded-sm bg-primary",
            staticStyle: {
              "width": "max-content",
              "padding": "10px 12px !important"
            }
          }, [_c('h5', {
            staticClass: "text-white"
          }, [_vm._v("#" + _vm._s(index + 1))])]), _c('h6', {
            staticClass: "font-500 ml-2"
          }, [_vm._v(_vm._s(diagram.header || '-'))])]), _c('b-button', {
            staticClass: "rounded",
            class: {
              active: diagram.visible
            },
            staticStyle: {
              "width": "max-content",
              "height": "max-content"
            },
            attrs: {
              "variant": "outline-secondary"
            },
            on: {
              "click": function click($event) {
                diagram.visible = !diagram.visible;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-chevron-right m-0",
            style: {
              rotate: diagram.visible ? '90deg' : '0deg',
              transtiion: 'all 300ms'
            }
          })])], 1), _c('b-collapse', {
            attrs: {
              "id": "fish-bone-".concat(index)
            },
            model: {
              value: diagram.visible,
              callback: function callback($$v) {
                _vm.$set(diagram, "visible", $$v);
              },
              expression: "diagram.visible"
            }
          }, [_c('b-row', [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-row', {
            staticClass: "mt-2 mb-2"
          }, [_vm.isEditMode ? _c('b-col', {
            staticClass: "pl-5",
            attrs: {
              "md": "12"
            }
          }, [_c('div', [_c('b-form-group', {
            attrs: {
              "label": "Header Name :",
              "label-for": "header"
            }
          }, [_c('b-form-input', {
            staticClass: "w-100",
            attrs: {
              "type": "text",
              "placeholder": "Header Name"
            },
            model: {
              value: diagram.header,
              callback: function callback($$v) {
                _vm.$set(diagram, "header", $$v);
              },
              expression: "diagram.header"
            }
          })], 1)], 1)]) : _vm._e(), _c('b-col', [_c('div', {
            staticClass: "px-5"
          }, _vm._l(diagram.bodyLength, function (item, idx) {
            return _c('b-row', {
              key: 'H' + idx,
              staticClass: "mb-3 border-bottom pb-2",
              attrs: {
                "cols": "12"
              }
            }, [_c('b-col', {
              staticClass: "d-flex align-items-center",
              attrs: {
                "cols": "12"
              }
            }, [_c('b-alert', {
              staticClass: "p-2 m-0 mr-2 bg-success",
              staticStyle: {
                "width": "43px"
              },
              attrs: {
                "show": ""
              }
            }, [_c('h5', {
              staticClass: "text-white font-500"
            }, [_vm._v("#" + _vm._s(idx))])]), _c('div', {
              staticClass: "w-100"
            }, [_vm.isEditMode ? _c('b-form-textarea', {
              attrs: {
                "rows": "2"
              },
              model: {
                value: diagram.body[idx - 1],
                callback: function callback($$v) {
                  _vm.$set(diagram.body, idx - 1, $$v);
                },
                expression: "diagram.body[idx - 1]"
              }
            }) : _c('h6', [_vm._v(_vm._s(diagram.body[idx - 1] || '-'))])], 1), _vm.isEditMode ? _c('b-button', {
              staticClass: "ml-2",
              attrs: {
                "variant": "danger"
              },
              on: {
                "click": function click($event) {
                  diagram.bodyLength -= 1;
                }
              }
            }, [_c('i', {
              staticClass: "fa fa-trash m-0"
            })]) : _vm._e()], 1)], 1);
          }), 1)]), _vm.isEditMode ? _c('b-col', {
            staticClass: "text-right",
            attrs: {
              "md": "12"
            }
          }, [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.diagrams.splice(index, 1);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          }), _vm._v(" Delete ")]), _c('b-button', {
            attrs: {
              "variant": "warning"
            },
            on: {
              "click": function click($event) {
                return _vm.addDiagramBody(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-plus"
          }), _vm._v(" Add More Item")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
        }), _vm.isEditMode ? _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": _vm.addDiagrams
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Data")])], 1)], 1) : _vm._e()], 2)], 1)], 1) : _vm._e(), _c('b-col', {
          staticClass: "my-3"
        }, [_vm.isEditMode ? _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "success",
            "block": ""
          },
          on: {
            "click": _vm.submitForm
          }
        }, [_c('i', {
          staticClass: "fa fa-check"
        }), _vm._v(" Submit Form")]) : _vm._e()], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
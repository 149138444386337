var render = function render(){
  var _vm$detailData, _vm$detailData$portLo, _vm$detailData2, _vm$detailData2$portL, _vm$detailData3, _vm$detailData4, _vm$detailData5, _vm$detailData6, _vm$detailData7, _vm$detailData8, _vm$detailData9, _vm$detailData10, _vm$detailData11, _vm$detailData12, _vm$detailData13, _vm$detailData14, _vm$detailData15, _vm$detailData16, _vm$detailData17, _vm$detailData18, _vm$detailData19, _vm$detailData20, _vm$detailData21, _vm$detailData21$port, _vm$detailData22, _vm$detailData23, _vm$detailData24, _vm$detailData25, _vm$detailData26, _vm$detailData27, _vm$detailData28, _vm$detailData29, _vm$detailData30, _vm$detailData31, _vm$detailData32, _vm$detailData33, _vm$detailData34, _vm$detailData35, _vm$detailData36, _vm$detailData37, _vm$detailData38, _vm$detailData39, _vm$detailData40;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-4"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("DETAIL TRIP")]), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.vehicle, function (state, index) {
    return _c('b-form-group', {
      key: index,
      staticClass: "mr-5",
      attrs: {
        "label": "Fleet - ".concat(index + 1)
      }
    }, [_c('strong', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))])]);
  }), 1)])], 1), _c('b-row', {
    staticClass: "m-2 py-2",
    staticStyle: {
      "background-color": "#E5F9FF"
    }
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("LOADING ACTIVITY RECORD")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Port of Loading",
      "label-for": "port_of_loading"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData = _vm.detailData) === null || _vm$detailData === void 0 ? void 0 : (_vm$detailData$portLo = _vm$detailData.portLoading) === null || _vm$detailData$portLo === void 0 ? void 0 : _vm$detailData$portLo.portName))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Agent POL",
      "label-for": "agent_pol"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData2 = _vm.detailData) === null || _vm$detailData2 === void 0 ? void 0 : (_vm$detailData2$portL = _vm$detailData2.portLoading) === null || _vm$detailData2$portL === void 0 ? void 0 : _vm$detailData2$portL.agentPol))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Is Pol Sts Mv:",
      "label-for": "kind-fleet"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData3 = _vm.detailData) !== null && _vm$detailData3 !== void 0 && _vm$detailData3.polStsMv ? 'Yes' : 'No'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Pol Mv Name",
      "label-for": "pol_mv_name"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData4 = _vm.detailData) === null || _vm$detailData4 === void 0 ? void 0 : _vm$detailData4.polMvName))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pol grab Type",
      "label-for": "pod_grab_type"
    }
  }, [_c('strong', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$detailData5 = _vm.detailData) === null || _vm$detailData5 === void 0 ? void 0 : _vm$detailData5.polGrabType))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Pol Fc Name",
      "label-for": "pol_fc_name"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData6 = _vm.detailData) === null || _vm$detailData6 === void 0 ? void 0 : _vm$detailData6.polFcName))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "NOR",
      "label-for": "nor"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData7 = _vm.detailData) === null || _vm$detailData7 === void 0 ? void 0 : _vm$detailData7.polNor))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "NOA",
      "label-for": "noa"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData8 = _vm.detailData) === null || _vm$detailData8 === void 0 ? void 0 : _vm$detailData8.polNoa))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Anchor Up",
      "label-for": "polAnchorUp"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData9 = _vm.detailData) === null || _vm$detailData9 === void 0 ? void 0 : _vm$detailData9.polAnchorUp))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "In Position / Berthing",
      "label-for": "polBerthing"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData10 = _vm.detailData) === null || _vm$detailData10 === void 0 ? void 0 : _vm$detailData10.polBerthing))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Commenced",
      "label-for": "polCommenced"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData11 = _vm.detailData) === null || _vm$detailData11 === void 0 ? void 0 : _vm$detailData11.polCommenced))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Completed",
      "label-for": "completed"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData12 = _vm.detailData) === null || _vm$detailData12 === void 0 ? void 0 : _vm$detailData12.polCompleted))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Draught Survey",
      "label-for": "polDraughtSurvey"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData13 = _vm.detailData) === null || _vm$detailData13 === void 0 ? void 0 : _vm$detailData13.polDraughtSurveyDate))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "BL Date",
      "label-for": "blDate"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData14 = _vm.detailData) === null || _vm$detailData14 === void 0 ? void 0 : _vm$detailData14.polBlDate))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Cast In",
      "label-for": "polCastIn"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData15 = _vm.detailData) === null || _vm$detailData15 === void 0 ? void 0 : _vm$detailData15.polCastIn))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Cast out",
      "label-for": "polCastOut"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData16 = _vm.detailData) === null || _vm$detailData16 === void 0 ? void 0 : _vm$detailData16.polCastOut))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "LHV",
      "label-for": "polLhv"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData17 = _vm.detailData) === null || _vm$detailData17 === void 0 ? void 0 : _vm$detailData17.polLhv))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Document On Board POL",
      "label-for": "document_on_board"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData18 = _vm.detailData) === null || _vm$detailData18 === void 0 ? void 0 : _vm$detailData18.polDocOnBoard))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Departure to POD",
      "label-for": "polDeparture"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData19 = _vm.detailData) === null || _vm$detailData19 === void 0 ? void 0 : _vm$detailData19.polDeparture))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Remarks",
      "label-for": "polCastIn"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData20 = _vm.detailData) === null || _vm$detailData20 === void 0 ? void 0 : _vm$detailData20.remark))])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticStyle: {
      "opacity": "0"
    },
    attrs: {
      "label": "PROJECT"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticStyle: {
      "opacity": "0"
    },
    attrs: {
      "label": "PROJECT"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1)], 1), _c('b-row', {
    staticClass: "m-2 py-2",
    staticStyle: {
      "background-color": "#EFFEE6"
    }
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("DISCHARGE ACTIVITY RECORD")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Port of Discharge",
      "label-for": "port_of_loading"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData21 = _vm.detailData) === null || _vm$detailData21 === void 0 ? void 0 : (_vm$detailData21$port = _vm$detailData21.portDischarge) === null || _vm$detailData21$port === void 0 ? void 0 : _vm$detailData21$port.portName))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Agent POD",
      "label-for": "agent_pol"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData22 = _vm.detailData) === null || _vm$detailData22 === void 0 ? void 0 : _vm$detailData22.agentPod))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Is Pod Sts Mv:",
      "label-for": "kind-fleet"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData23 = _vm.detailData) !== null && _vm$detailData23 !== void 0 && _vm$detailData23.podStsMv ? 'Yes' : 'No'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Pod Mv Name",
      "label-for": "pod_mv_name"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData24 = _vm.detailData) === null || _vm$detailData24 === void 0 ? void 0 : _vm$detailData24.podMvName))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pod grab Type",
      "label-for": "pod_grab_type"
    }
  }, [_c('strong', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$detailData25 = _vm.detailData) === null || _vm$detailData25 === void 0 ? void 0 : _vm$detailData25.podGrabType))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate",
    attrs: {
      "label": "Pod Fc Name",
      "label-for": "pol_fc_name"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData26 = _vm.detailData) === null || _vm$detailData26 === void 0 ? void 0 : _vm$detailData26.podFcName))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "NOR",
      "label-for": "nor"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData27 = _vm.detailData) === null || _vm$detailData27 === void 0 ? void 0 : _vm$detailData27.podNor))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "NOA",
      "label-for": "noa"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData28 = _vm.detailData) === null || _vm$detailData28 === void 0 ? void 0 : _vm$detailData28.podNoa))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Anchor Up",
      "label-for": "podAnchorUp"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData29 = _vm.detailData) === null || _vm$detailData29 === void 0 ? void 0 : _vm$detailData29.podAnchorUp))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "In Position / Berthing",
      "label-for": "podBerthing"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData30 = _vm.detailData) === null || _vm$detailData30 === void 0 ? void 0 : _vm$detailData30.podBerthing))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Commenced",
      "label-for": "podCommenced"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData31 = _vm.detailData) === null || _vm$detailData31 === void 0 ? void 0 : _vm$detailData31.podCommenced))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Completed",
      "label-for": "podCompleted"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData32 = _vm.detailData) === null || _vm$detailData32 === void 0 ? void 0 : _vm$detailData32.podCompleted))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Draught Survey",
      "label-for": "podDraughtSurvey"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData33 = _vm.detailData) === null || _vm$detailData33 === void 0 ? void 0 : _vm$detailData33.podDraughtSurveyDate))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "BL Date",
      "label-for": "blDate"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData34 = _vm.detailData) === null || _vm$detailData34 === void 0 ? void 0 : _vm$detailData34.podBlDate))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Cast In",
      "label-for": "podCastIn"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData35 = _vm.detailData) === null || _vm$detailData35 === void 0 ? void 0 : _vm$detailData35.podCastIn))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Cast out",
      "label-for": "podCastOut"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData36 = _vm.detailData) === null || _vm$detailData36 === void 0 ? void 0 : _vm$detailData36.podCastOut))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "LHV",
      "label-for": "podLhv"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData37 = _vm.detailData) === null || _vm$detailData37 === void 0 ? void 0 : _vm$detailData37.podLhv))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Document On Board POD",
      "label-for": "document_on_board"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData38 = _vm.detailData) === null || _vm$detailData38 === void 0 ? void 0 : _vm$detailData38.podDocOnBoard))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Departure to POD",
      "label-for": "podDeparture"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData39 = _vm.detailData) === null || _vm$detailData39 === void 0 ? void 0 : _vm$detailData39.podDeparture))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "text-truncate mb-0",
    attrs: {
      "label": "Remarks",
      "label-for": "polCastIn"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$detailData40 = _vm.detailData) === null || _vm$detailData40 === void 0 ? void 0 : _vm$detailData40.remark))])])], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "p-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("Activities")])]), _c('hr'), _c('div', {
    staticClass: "d-flex overflow-auto",
    staticStyle: {
      "max-heigth": "400px"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("START DATE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("END DATE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("REASON")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("HOURS")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("CARGO LOADING")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("CARGO UNLOADING")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("CURRENT POSITION")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("REMARKS")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("GPS")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("SPEED")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("REASON")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "width": "5px",
      "position": "sticky",
      "right": "0",
      "z-index": "1"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.activities, function (state, index) {
    var _state$reason$name, _state$reason, _state$cargoLoading, _state$cargoUnloading, _state$currentPositio, _state$remark, _state$reason2;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.startDate ? _vm.moment(state === null || state === void 0 ? void 0 : state.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.endDate ? _vm.moment(state === null || state === void 0 ? void 0 : state.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_state$reason$name = state === null || state === void 0 ? void 0 : (_state$reason = state.reason) === null || _state$reason === void 0 ? void 0 : _state$reason.name) !== null && _state$reason$name !== void 0 ? _state$reason$name : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.activityDurationFormat))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_state$cargoLoading = state === null || state === void 0 ? void 0 : state.cargoLoading) !== null && _state$cargoLoading !== void 0 ? _state$cargoLoading : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_state$cargoUnloading = state === null || state === void 0 ? void 0 : state.cargoUnloading) !== null && _state$cargoUnloading !== void 0 ? _state$cargoUnloading : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_state$currentPositio = state === null || state === void 0 ? void 0 : state.currentPosition) !== null && _state$currentPositio !== void 0 ? _state$currentPositio : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$remark = state === null || state === void 0 ? void 0 : state.remark) !== null && _state$remark !== void 0 ? _state$remark : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.speed))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$reason2 = state.reason) === null || _state$reason2 === void 0 ? void 0 : _state$reason2.name))]), _c('td', {
      staticClass: "text-center",
      staticStyle: {
        "width": "5px",
        "position": "sticky",
        "right": "0",
        "z-index": "1"
      }
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.toEditHistory(state.id, true);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Detail ")]), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.toEditHistory(state.id, false);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pen mr-2"
    }), _vm._v("Edit ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteActivity(state);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash mr-2"
    }), _vm._v("Delete ")])], 1)], 1)]);
  }), 0)])])])], 1), _c('b-row', {
    staticClass: "p-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("Voyage Matrix")])]), _c('hr'), _c('div', {
    staticClass: "d-flex overflow-auto",
    staticStyle: {
      "max-heigth": "400px"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', _vm._l(_vm.matrix, function (head, headIndex) {
    return _c('th', {
      key: headIndex,
      staticClass: "text-center text-truncate text-uppercase"
    }, [_vm._v(" " + _vm._s(head === null || head === void 0 ? void 0 : head.name) + " ")]);
  }), 0)]), _c('tbody', [_c('tr', _vm._l(_vm.matrix, function (body, bodyIndex) {
    var _body$durationFormat;
    return _c('td', {
      key: bodyIndex,
      staticClass: "text-center text-truncate"
    }, [_vm._v(" " + _vm._s((_body$durationFormat = body === null || body === void 0 ? void 0 : body.durationFormat) !== null && _body$durationFormat !== void 0 ? _body$durationFormat : '-') + " ")]);
  }), 0)])])])])], 1)], 1)]) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
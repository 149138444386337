var render = function render(){
  var _vm$detailMi, _vm$detailMi$rcas, _vm$detailMi2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-4"
  }, [_c('div', {
    staticClass: "mb-3 d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateRCA(_vm.detailMi.id);
      }
    }
  }, [_vm._v(" Create RCA ")])], 1), _c('table', {
    staticClass: "table table-striped border"
  }, [_vm._m(0), _c('tbody', [((_vm$detailMi = _vm.detailMi) === null || _vm$detailMi === void 0 ? void 0 : (_vm$detailMi$rcas = _vm$detailMi.rcas) === null || _vm$detailMi$rcas === void 0 ? void 0 : _vm$detailMi$rcas.length) > 0 ? _vm._l((_vm$detailMi2 = _vm.detailMi) === null || _vm$detailMi2 === void 0 ? void 0 : _vm$detailMi2.rcas, function (rca) {
    return _c('tr', {
      key: rca.id
    }, [_c('td', [_vm._v(_vm._s(rca.personal_fullname))]), _c('td', [_vm._v(_vm._s(_vm.findCompany(rca.personal_company_id)))]), _c('td', [_vm._v(_vm._s(rca.personal_gender === 'M' ? 'Laki - Laki' : 'Perempuan'))]), _c('td', [_vm._v(_vm._s(rca.personal_position))]), _c('td', {
      staticClass: "text-center"
    }, [_vm.canEditRCA ? _c('b-button', {
      staticClass: "mr-2",
      attrs: {
        "variant": "success",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onEditRCA(rca.id, _vm.detailMi.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-edit"
    })]) : _vm._e(), _vm.canDeleteRCA ? _c('b-button', {
      staticClass: "mr-2",
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteRCA(rca);
        }
      }
    }, [!_vm.loading_delete_rca ? _c('i', {
      staticClass: "fa fa-trash"
    }) : [_c('span', {
      staticClass: "spinner-border spinner-border-sm",
      attrs: {
        "role": "status",
        "aria-hidden": "true"
      }
    }), _vm._v(" Deleting... ")]], 2) : _vm._e(), _c('b-button', {
      attrs: {
        "variant": "warning",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onPreviewRCA(rca.id, _vm.detailMi.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye"
    })])], 1)]);
  }) : [_c('div', {
    staticClass: "p-2"
  }, [_vm._v("No Data RCA")])]], 2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('th', [_vm._v("Nama")]), _c('th', [_vm._v("Company")]), _c('th', [_vm._v("Gender")]), _c('th', [_vm._v("Posisi")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Action")])]);

}]

export { render, staticRenderFns }
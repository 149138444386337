var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [!_vm.isChildComponent ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#fleet_certificate",
            "title": "FLEET CERTIFICATE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#master_certificate",
            "title": "MASTER CERTIFICATE"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 1208118139)
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "fleet_certificate"
    }
  }, [_c('b-modal', {
    attrs: {
      "centered": "",
      "size": "lg",
      "title": "Filter Data",
      "ok-title": "Filter"
    },
    on: {
      "ok": _vm.fetchCertificates
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', [!_vm.fleetParamsAvailable ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Fleet/Equipment",
      "label-for": "kind-fleet",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.fleets,
      "reduce": function reduce(fleet) {
        return fleet.id;
      },
      "disabled": _vm.vehicleIdParam !== ''
    },
    model: {
      value: _vm.certificate_params.vehicle,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "vehicle", $$v);
      },
      expression: "certificate_params.vehicle"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Periode Certificate:",
      "label-for": "typeCertificate",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.remarks_filter,
      "value-field": "value",
      "text-field": "label"
    },
    model: {
      value: _vm.certificate_params.type,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "type", $$v);
      },
      expression: "certificate_params.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Category",
      "label-for": "kind-fleet",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.certificate_categories,
      "multiple": true,
      "group-values": "subCategory",
      "group-label": "name",
      "group-select": true,
      "placeholder": "",
      "track-by": "id",
      "label": "name"
    },
    model: {
      value: _vm.certificate_params.certificateCategoryIds,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "certificateCategoryIds", $$v);
      },
      expression: "certificate_params.certificateCategoryIds"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Status Certificate:",
      "label-for": "statusCertificate",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.certificate_statuses,
      "value-field": "value",
      "text-field": "text"
    },
    on: {
      "change": _vm.onStatusCertificateChange
    },
    model: {
      value: _vm.certificate_params.status,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "status", $$v);
      },
      expression: "certificate_params.status"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "text-primary"
        }, [_c('strong', [_vm._v(" Fleet Certificate ")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$form, _vm$form$certificate, _vm$form$certificate$, _vm$form2, _vm$form2$certificate, _vm$form2$certificate2;
        return [_vm.loading_certificates ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.toggleModalFilter
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data")])])], 1), _c('div', {
          staticClass: "d-flex"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('strong', [_vm._v("Display :  ")]), _c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-outline-primary",
          class: {
            active: _vm.view_style === 'list'
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.view_style = 'list';
            }
          }
        }, [_c('span', {
          staticClass: "fa fa-list"
        }), _vm._v(" List ")]), _c('button', {
          staticClass: "btn btn-outline-primary",
          class: {
            active: _vm.view_style === 'grid'
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.view_style = 'grid';
            }
          }
        }, [_c('span', {
          staticClass: "fa fa-th-large"
        }), _vm._v(" Grid ")]), _c('button', {
          staticClass: "btn btn-outline-primary",
          class: {
            active: _vm.view_style === 'calendar'
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.view_style = 'calendar';
            }
          }
        }, [_c('span', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" Calendar ")])])]), _c('div', {
          staticClass: "mx-3",
          staticStyle: {
            "border-left": "1.5px solid"
          }
        }), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_vm.menuPermission('export') || _vm.lsProfile.menu[1].child[0].child[0].export == true ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doExportCertificateVehicles
          }
        }, [!_vm.loading_export_certificates ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2) : _vm._e(), (!_vm.vehicleIdParam || _vm.fleetParamsAvailable) && (_vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].add == true) && !_vm.isChildComponent ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.onAddCertificateVehicle
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Certificate ")]) : _vm._e()], 1)])]), _c('div', {
          staticClass: "px-3 mt-3"
        }, [_c('b-row', {
          staticClass: "pt-3 border"
        }, [_vm.certificate_params.vehicle ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet/Equipment"
          }
        }, [_c('p', {
          staticClass: "m-0 text-black"
        }, [_vm._v(_vm._s(_vm.fleets.find(function (el) {
          return el.id == _vm.certificate_params.vehicle;
        }).name))])])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Periode Certificate:"
          }
        }, [_c('p', {
          staticClass: "m-0 text-black"
        }, [_vm._v(_vm._s(_vm.remarks_filter.find(function (el) {
          return el.value == _vm.certificate_params.type;
        }).label))])])], 1), _vm.certificate_params.certificateCategoryIds.length ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category"
          }
        }, _vm._l(_vm.certificate_params.certificateCategoryIds, function (item, index) {
          return _c('b-badge', {
            key: index,
            staticClass: "mr-1",
            attrs: {
              "variant": "success"
            }
          }, [_vm._v(_vm._s(item.name))]);
        }), 1)], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status Certificate:"
          }
        }, [_c('p', {
          staticClass: "m-0 text-black"
        }, [_vm._v(_vm._s(_vm.certificate_statuses.find(function (el) {
          return el.value == _vm.certificate_params.status;
        }).text))])])], 1)], 1)], 1), _vm.certificates.length > 0 ? [_vm.view_style == 'grid' ? _c('b-row', _vm._l(_vm.certificates, function (cert) {
          return _c('b-col', {
            key: cert.code + cert.id,
            attrs: {
              "sm": "12",
              "md": "3"
            }
          }, [_c('b-card', {
            staticClass: "iq-mb-3"
          }, [_c('b-card-body', [_c('div', {
            staticClass: "doc-profile text-center"
          }, [_c('img', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "img-fluid avatar-80 button-pointer",
            attrs: {
              "title": "View Details",
              "src": require('@assets/images/flaticon/certificate.png'),
              "alt": "profile"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleModalDetail(true, cert);
              }
            }
          })]), _c('div', {
            staticClass: "iq-doc-info mt-3 text-center"
          }, [_c('h4', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "text-default button-pointer",
            attrs: {
              "title": "View Details"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleModalDetail(true, cert);
              }
            }
          }, [_vm._v(" " + _vm._s(cert.certificate.name))]), cert.certificate.certificateType == 'FULL_TERM' && _vm.$options.filters.getDateDifferenceInMonthWithFormat(cert.expired, cert.dateIssued) <= 12 ? [_c('br'), _c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "pill": "",
              "variant": "secondary"
            }
          }, [_vm._v("INTERM ")])] : _vm._e(), _c('h6', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "text-primary button-pointer",
            attrs: {
              "title": "View Details"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleModalDetail(true, cert);
              }
            }
          }, [_vm._v(" " + _vm._s(cert.vehicle.name) + " ")]), _c('p', {
            staticClass: "mb-0"
          }, [_c('small', [_vm._v("Place Issued:")]), _vm._v(" " + _vm._s(cert.placeIssued) + " ")]), _c('p', {
            staticClass: "mb-0"
          }, [_c('small', [_vm._v("Date Issued:")]), _vm._v(" " + _vm._s(cert.dateIssued) + " ")]), cert.expired ? _c('p', {
            staticClass: "mb-0"
          }, [_c('small', [_vm._v("Expired: ")]), _vm._v(_vm._s(cert.expired))]) : _vm._e(), cert.endorsement.length > 0 ? _c('p', [_c('small', [_vm._v("Next Endorsement: ")]), _vm._v(_vm._s(_vm.findEndorsement(cert.endorsement, cert.lastEndorsementNumber)))]) : _vm._e()], 2), _c('div', {
            staticClass: "iq-doc-description mt-2 text-center"
          }, [_c('p', {
            staticClass: "mb-1"
          }, [cert.certificate.certificateType === 'PERMANENT' ? [_c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "variant": "dark"
            }
          }, [_vm._v("Permanent ")])] : cert.certificate.certificateType === 'SHORT_TERM' ? [_c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "variant": "secondary"
            }
          }, [_vm._v("Short Term ")])] : cert.certificate.certificateType === 'FULL_TERM' ? [_c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "variant": "light"
            }
          }, [_vm._v("Full Term ")])] : [_c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "variant": "primary"
            }
          }, [_vm._v("Provisional ")])]], 2), _c('div', {
            staticClass: "mb-1"
          }, [_c('span', {
            staticClass: "badge badge-pill text-capitalize",
            style: "background-color: ".concat(cert.status.color, "; color: white")
          }, [_vm._v(_vm._s(cert.status.text))])])]), _c('b-row', {
            staticClass: "mt-3 text-center"
          }, [_c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('b-button-group', {
            staticClass: "mr-2"
          }, [(_vm.menuPermission('update') || _vm.lsProfile.menu[1].child[0].child[0].update == true) && !_vm.isChildComponent ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "title": "Edit Certificate",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditCertificate(cert);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), cert.file && cert.file.url && (_vm.menuPermission('export') || _vm.lsProfile.menu[1].child[0].child[0].export == true) ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "variant": "dark",
              "title": "Download Certificate"
            },
            on: {
              "click": function click($event) {
                return _vm.downloadCertificate(cert.file.url);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-download"
          })]) : _vm._e(), (_vm.menuPermission('delete') || _vm.lsProfile.menu[1].child[0].child[0].delete == true) && !_vm.isChildComponent ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "title": "Delete Certificate",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onDeleteCertificate(cert);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), !['PROVISIONAL'].includes(cert.certificate.certificateType) ? [cert.certificate.certificateType === 'PERMANENT' && cert.status.showEndorsement && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].add == true && _vm.lsProfile.menu[1].child[0].child[0].update == true) ? _c('b-dropdown', {
            attrs: {
              "variant": "primary"
            }
          }, [_c('template', {
            slot: "button-content"
          }, [_vm._v(" Action "), _c('i', {
            staticClass: "uil uil-angle-down"
          })]), [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.toggleModalEndorsement(true, cert);
              }
            }
          }, [_vm._v("Proses Endorsement ")])]], 2) : _c('b-dropdown', {
            attrs: {
              "variant": "primary"
            }
          }, [_c('template', {
            slot: "button-content"
          }, [_vm._v(" Action "), _c('i', {
            staticClass: "uil uil-angle-down"
          })]), cert.status.showEndorsement && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].add == true && _vm.lsProfile.menu[1].child[0].child[0].update == true) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.toggleModalEndorsement(true, cert);
              }
            }
          }, [_vm._v("Proses Endorsement ")]) : _vm._e(), cert.status.showRenewal && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].add == true && _vm.lsProfile.menu[1].child[0].child[0].update == true) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleModalRenewal(true, cert);
              }
            }
          }, [_vm._v("Proses Perpanjang ")]) : _vm._e(), cert.status.showLog ? _c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.toggleModalLog(true, cert);
              }
            }
          }, [_vm._v("Log History ")]) : _vm._e()], 2)] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1);
        }), 1) : _vm.view_style == 'list' ? _c('table', {
          staticClass: "table table-striped mt-4"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticStyle: {
            "width": "30px"
          }
        }, [_vm._v("No")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Certificate")]), _vm.fleetParamsAvailable ? _c('th', {
          staticStyle: {
            "width": "120px"
          }
        }, [_vm._v("Issued")]) : _vm._e(), _c('th', {
          staticStyle: {
            "width": "120px"
          }
        }, [_vm._v("Expired")]), _c('th', [_vm._v("Next Endorse")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.certificates, function (cert, index) {
          return _c('tr', {
            key: cert.code + cert.id
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(" " + _vm._s(cert.vehicle.name) + " "), cert.vehicleOnDocking ? [_c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "variant": "warning"
            }
          }, [_vm._v("Docking ")])] : _vm._e()], 2), _c('td', [_c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "text-uppercase button-pointer text-primary",
            attrs: {
              "title": "View Details"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleModalDetail(true, cert);
              }
            }
          }, [_vm._v(_vm._s(cert.certificate.name))]), _c('br'), cert.certificate.certificateType === 'PROVISIONAL' ? _c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "variant": "secondary"
            }
          }, [_vm._v(" PERMANENT (NO ENDORS) ")]) : _c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "variant": "secondary"
            }
          }, [_vm._v(" " + _vm._s(cert.certificate.certificateType.replace('_', ' ')) + " ")]), cert.certificate.certificateType == 'FULL_TERM' && _vm.$options.filters.getDateDifferenceInMonthWithFormat(cert.expired, cert.dateIssued) <= 12 ? [_c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "pill": "",
              "variant": "secondary"
            }
          }, [_vm._v("INTERM ")])] : _vm._e()], 2), _vm.fleetParamsAvailable ? _c('td', [_vm._v(_vm._s(cert.dateIssued ? cert.dateIssued : '-'))]) : _vm._e(), _c('td', [_vm._v(_vm._s(cert.expired ? cert.expired : '-'))]), _c('td', [_vm._v(_vm._s(cert.endorsement.length > 0 ? _vm.findEndorsement(cert.endorsement, cert.lastEndorsementNumber) : '-') + " ")]), _c('td', [_c('span', {
            staticClass: "badge badge-pill text-capitalize",
            style: "background-color: ".concat(cert.status.color, "; color: white")
          }, [_vm._v(_vm._s(cert.status.text))])]), _c('td', [_c('b-button-group', {
            staticClass: "mr-2"
          }, [(_vm.menuPermission('update') || _vm.lsProfile.menu[1].child[0].child[0].update == true) && !_vm.isChildComponent ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "title": "Edit Certificate",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditCertificate(cert);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), cert.file && cert.file.url && (_vm.menuPermission('export') || _vm.lsProfile.menu[1].child[0].child[0].export == true) ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "variant": "dark",
              "title": "Download Certificate"
            },
            on: {
              "click": function click($event) {
                return _vm.downloadCertificate(cert.file.url);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-download"
          })]) : _vm._e(), (_vm.menuPermission('delete') || _vm.lsProfile.menu[1].child[0].child[0].delete == true) && !_vm.isChildComponent ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "title": "Delete Certificate",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onDeleteCertificate(cert);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), !['PROVISIONAL'].includes(cert.certificate.certificateType) && !_vm.isChildComponent ? [cert.certificate.certificateType === 'PERMANENT' && cert.status.showEndorsement && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].update == true && _vm.lsProfile.menu[1].child[0].child[0].add == true) ? _c('b-dropdown', {
            attrs: {
              "variant": "primary"
            }
          }, [_c('template', {
            slot: "button-content"
          }, [_vm._v(" Action "), _c('i', {
            staticClass: "uil uil-angle-down"
          })]), [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.toggleModalEndorsement(true, cert);
              }
            }
          }, [_vm._v("Proses Endorsement ")])]], 2) : _c('b-dropdown', {
            attrs: {
              "variant": "primary"
            }
          }, [_c('template', {
            slot: "button-content"
          }, [_vm._v(" Action "), _c('i', {
            staticClass: "uil uil-angle-down"
          })]), cert.status.showEndorsement && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].update == true && _vm.lsProfile.menu[1].child[0].child[0].add == true) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.toggleModalEndorsement(true, cert);
              }
            }
          }, [_vm._v("Proses Endorsement ")]) : _vm._e(), cert.status.showRenewal && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].update == true && _vm.lsProfile.menu[1].child[0].child[0].add == true) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleModalRenewal(true, cert);
              }
            }
          }, [_vm._v("Proses Perpanjang ")]) : _vm._e(), cert.status.showLog ? _c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.toggleModalLog(true, cert);
              }
            }
          }, [_vm._v("Log History ")]) : _vm._e()], 2)] : _vm._e()], 2)], 1)]);
        }), 0)]) : _c('v-calendar', {
          staticClass: "custom-calendar max-w-full mb-2 mt-4",
          attrs: {
            "masks": _vm.masks,
            "attributes": _vm.attributes,
            "disable-page-swipe": "",
            "is-expanded": ""
          },
          scopedSlots: _vm._u([{
            key: "day-content",
            fn: function fn(_ref) {
              var day = _ref.day,
                attributes = _ref.attributes;
              return [_c('div', {
                staticClass: "flex flex-col h-full z-10 overflow-hidden mb-4 mt-2 text-right"
              }, [_c('span', {
                staticClass: "day-label text-sm text-gray-900"
              }, [_vm._v(_vm._s(day.day))]), _c('div', {
                staticClass: "flex-grow overflow-y-auto overflow-x-auto"
              }, _vm._l(attributes, function (attr) {
                return _c('p', {
                  key: attr.key,
                  staticClass: "text-xs leading-tight rounded-sm p-1 mt-0 mb-1 text-justify",
                  class: attr.customData.class
                }, [_c('strong', [_vm._v(_vm._s(attr.customData.vehicleName))]), _c('br'), attr.customData.title.length > 25 ? _c('span', {
                  directives: [{
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: {
                      "hover": true
                    }
                  }],
                  attrs: {
                    "title": attr.customData.title
                  }
                }, [_vm._v(" " + _vm._s(attr.customData.title.substring(0, 25).concat('...')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(attr.customData.title) + " ")])]);
              }), 0)])];
            }
          }], null, false, 3207740262)
        }), [_c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "col-6": ""
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.pagination.total) + "   |   Per page: " + _vm._s(_vm.pagination.perPage) + " ")]), _c('b-col', {
          attrs: {
            "col-6": ""
          }
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.pagination.total,
            "per-page": _vm.pagination.perPage,
            "align": "right"
          },
          on: {
            "input": _vm.fetchCertificates
          },
          model: {
            value: _vm.certificate_params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.certificate_params, "page", $$v);
            },
            expression: "certificate_params.page"
          }
        })], 1)], 1)]] : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No certificates data found. "), (!_vm.vehicleIdParam || _vm.fleetParamsAvailable) && (_vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].add == true) ? _c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": _vm.onAddCertificateVehicle
          }
        }, [_vm._v("Add New")]) : _vm._e()])], _c('b-modal', {
          attrs: {
            "size": "xl",
            "title": "Add Certificate ".concat(_vm.fleetParamsAvailable && _vm.fleetFromParam ? "for ".concat(_vm.fleetFromParam.name) : ''),
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-footer": true
          },
          model: {
            value: _vm.show_add_modal,
            callback: function callback($$v) {
              _vm.show_add_modal = $$v;
            },
            expression: "show_add_modal"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Details:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name of Certificate*",
            "label-for": "category"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.masterAllCertificates,
            "clearable": false
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(option) {
              return [_c('strong', [_vm._v(_vm._s(option.certificateType === 'PROVISIONAL' ? 'PERMANENT (NO ENDORS)' : option.certificateType.replace('_', ' ')))]), _vm._v(" - " + _vm._s(option.name) + " ")];
            }
          }]),
          model: {
            value: _vm.form.certificate.certificate,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "certificate", $$v);
            },
            expression: "form.certificate.certificate"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": _vm.fleetParamsAvailable ? 3 : 6
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type",
            "label-for": "type"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "type",
            "type": "text",
            "disabled": "",
            "value": _vm.form.certificate.certificate.certificateType ? _vm.form.certificate.certificate.certificateType === 'PROVISIONAL' ? 'PERMANENT (NO ENDORS)' : _vm.form.certificate.certificate.certificateType.replace('_', ' ') : '-'
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": _vm.fleetParamsAvailable ? 3 : 6
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category",
            "label-for": "category"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "type",
            "type": "text",
            "disabled": "",
            "value": _vm.form.certificate.certificate.certificateCategory ? _vm.form.certificate.certificate.certificateCategory.name : '-'
          }
        })], 1)], 1), !_vm.fleetParamsAvailable ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet*",
            "label-for": "fleet"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.fleets,
            "reduce": function reduce(fleet) {
              return fleet.id;
            }
          },
          on: {
            "input": _vm.fetchFleetUsers
          },
          model: {
            value: _vm.form.certificate.vehicleId,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "vehicleId", $$v);
            },
            expression: "form.certificate.vehicleId"
          }
        })], 1)], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "certificateFile",
            "label": "File Certificate*"
          }
        }, [_c('b-form-file', {
          attrs: {
            "id": "customFile",
            "placeholder": "Choose a file",
            "accept": "application/pdf",
            "disabled": _vm.loading_upload_file
          },
          on: {
            "change": _vm.uploadFile
          }
        }), _vm.loading_upload_file ? _c('p', {
          staticClass: "mt-2 text-danger"
        }, [_vm._v("Please wait, uploading file is in process...")]) : _vm._e()], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "placeIssued",
            "label": "Place Issued*"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "place_issued",
            "type": "text",
            "placeholder": "Place Issued"
          },
          model: {
            value: _vm.form.certificate.placeIssued,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "placeIssued", $$v);
            },
            expression: "form.certificate.placeIssued"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "issued_by",
            "label": "Issued By*"
          }
        }, [_vm.form.certificate.certificate.certificateCategory ? _c('b-form-select', {
          attrs: {
            "disabled": !_vm.form.certificate.certificate.certificateCategory || !_vm.form.certificate.certificate.certificateCategory.subCategory.length > 0,
            "options": _vm.form.certificate.certificate.certificateCategory.subCategory,
            "value-field": "id",
            "text-field": "name"
          },
          model: {
            value: _vm.form.certificate.issuedBy,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "issuedBy", $$v);
            },
            expression: "form.certificate.issuedBy"
          }
        }) : _c('span', [_vm._v("-")])], 1)], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Validity:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Issued*"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          model: {
            value: _vm.form.certificate.dateIssued,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "dateIssued", $$v);
            },
            expression: "form.certificate.dateIssued"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _vm.form.certificate.certificate && _vm.form.certificate.certificate.certificateType !== 'PERMANENT' && _vm.form.certificate.certificate.certificateType !== 'PROVISIONAL' ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "expired",
            "label": "Valid Until*"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          on: {
            "change": _vm.checkFulltermInterm
          },
          model: {
            value: _vm.form.certificate.expiredDate,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "expiredDate", $$v);
            },
            expression: "form.certificate.expiredDate"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1) : _vm._e()], 1)], 1), ((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$certificate = _vm$form.certificate) === null || _vm$form$certificate === void 0 ? void 0 : (_vm$form$certificate$ = _vm$form$certificate.certificate) === null || _vm$form$certificate$ === void 0 ? void 0 : _vm$form$certificate$.certificateType) === 'PERMANENT' ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Endorsement:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "notification",
            "label": "Next Endorsement*"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "1"
          },
          model: {
            value: _vm.form.certificate.nextEndorsement,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "nextEndorsement", $$v);
            },
            expression: "form.certificate.nextEndorsement"
          }
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "notification",
            "label": "Notification Endorsement Before*"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "1"
          },
          model: {
            value: _vm.form.certificate.notificationEndorsement,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "notificationEndorsement", $$v);
            },
            expression: "form.certificate.notificationEndorsement"
          }
        })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.form.certificate.certificate && _vm.form.certificate.certificate.certificateType === 'FULL_TERM' && _vm.isFulltermInterm ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Endorsement:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "notification",
            "label": "Next Endorsement*"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "1"
          },
          model: {
            value: _vm.form.certificate.nextEndorsement,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "nextEndorsement", $$v);
            },
            expression: "form.certificate.nextEndorsement"
          }
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "notification",
            "label": "Notification Endorsement Before*"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "1"
          },
          model: {
            value: _vm.form.certificate.notificationEndorsement,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "notificationEndorsement", $$v);
            },
            expression: "form.certificate.notificationEndorsement"
          }
        })], 1)], 1)], 1)], 1), _c('b-row', [_vm.isFulltermInterm ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type of Endorsement*",
            "label-for": "Type of Endorsement"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.endorsement_types,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.form.certificate.endorsementType,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "endorsementType", $$v);
            },
            expression: "form.certificate.endorsementType"
          }
        })], 1)], 1) : _vm._e(), _vm.form.certificate.certificate && _vm.form.certificate.certificate.certificateType === 'FULL_TERM' && !_vm.isCertificateInterm() && _vm.form.certificate.endorsementType === 'VERIFIED' ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "DateVerified",
            "label": "Date Verified"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          model: {
            value: _vm.form.certificate.dateVerified,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "dateVerified", $$v);
            },
            expression: "form.certificate.dateVerified"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1) : _vm._e()], 1), _vm.form.certificate.endorsementType === 'MANUAL' ? _c('div', {
          staticClass: "mt-4"
        }, [_vm._l(_vm.form.certificate.endorsementDocuments, function (end, end_i) {
          return _c('b-row', {
            key: end_i,
            staticClass: "mt-2"
          }, [_c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_vm._v(" Endorsement #" + _vm._s(end.endorseNumber))]), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('date-picker', {
            attrs: {
              "lang": _vm.lang,
              "type": "date",
              "range": "",
              "placeholder": "Select range endorsement ".concat(end.endorseNumber)
            },
            model: {
              value: end.rangeEndorsement,
              callback: function callback($$v) {
                _vm.$set(end, "rangeEndorsement", $$v);
              },
              expression: "end.rangeEndorsement"
            }
          }), end_i !== 0 ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "ml-2",
            attrs: {
              "variant": "outline-danger",
              "title": "Delete endorsement ".concat(end.endorseNumber)
            },
            on: {
              "click": function click($event) {
                return _vm.deleteEndorsementDocument(end_i);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-times"
          })]) : _vm._e()], 1)], 1);
        }), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "block": "",
            "variant": "warning"
          },
          on: {
            "click": _vm.addEndorsementDocument
          }
        }, [_vm._v("Add Endorsement Based on Document ")])], 1)], 2) : _vm._e()], 1) : _vm._e(), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Remarks:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('label', [_vm._v("Office Checked")])]), _c('b-col', {
          attrs: {
            "md": "9"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.certificate.officeChecked,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "officeChecked", $$v);
            },
            expression: "form.certificate.officeChecked"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.certificate.officeChecked ? 'Yes' : 'No') + " ")]), _vm.form.certificate.officeChecked ? _c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Type Remark"
          },
          model: {
            value: _vm.form.certificate.officeRemark,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "officeRemark", $$v);
            },
            expression: "form.certificate.officeRemark"
          }
        }) : _vm._e()], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "3"
          }
        }, [_c('label', [_vm._v("Ship Checked")])]), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "9"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.certificate.shipChecked,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "shipChecked", $$v);
            },
            expression: "form.certificate.shipChecked"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.certificate.shipChecked ? 'Yes' : 'No') + " ")]), _vm.form.certificate.shipChecked ? _c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Type Remark"
          },
          model: {
            value: _vm.form.certificate.shipRemark,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "shipRemark", $$v);
            },
            expression: "form.certificate.shipRemark"
          }
        }) : _vm._e()], 1)], 1)], 1), _vm.form.certificate.certificate && !['PROVISIONAL'].includes(_vm.form.certificate.certificate.certificateType) ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Notification Receiver:")]), _vm.users && _vm.users.length > 0 ? _c('b-form-group', {
          attrs: {
            "label": "Receiver* ",
            "label-for": "category_for"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Select receivers",
            "label": "name",
            "track-by": "id",
            "options": _vm.users,
            "multiple": true
          },
          model: {
            value: _vm.form.certificate.user,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "user", $$v);
            },
            expression: "form.certificate.user"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Category not found.")])])], 1) : _c('p', {
          staticClass: "text-center my-3 text-muted"
        }, [_vm._v(_vm._s(_vm.form.certificate.vehicleId ? 'Users not found on selected fleet.' : 'Please select fleet first.'))])], 1) : _vm._e(), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              _vm.show_add_modal = !_vm.show_add_modal;
            }
          }
        }, [_vm._v("Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": _vm.loading_upload_file
          },
          on: {
            "click": _vm.doAddCertificate
          }
        }, [!_vm.loading_add_certificates ? _c('span', [_vm._v("Save")]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2)], 1)], 1), _c('b-modal', {
          attrs: {
            "size": "xl",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "title": "Edit Certificate ".concat(_vm.form.certificate.vehicleId && _vm.fleets.find(function (fleet) {
              return fleet.id === _vm.form.certificate.vehicleId;
            }) ? _vm.fleets.find(function (fleet) {
              return fleet.id === _vm.form.certificate.vehicleId;
            }).name : ''),
            "hide-footer": true
          },
          model: {
            value: _vm.show_edit_modal,
            callback: function callback($$v) {
              _vm.show_edit_modal = $$v;
            },
            expression: "show_edit_modal"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Details:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name of Certificate:",
            "label-for": "category"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.masterAllCertificates,
            "disabled": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(option) {
              return [_c('strong', [_vm._v(_vm._s(option.certificateType.replace('_', ' ')))]), _vm._v(" - " + _vm._s(option.name) + " ")];
            }
          }]),
          model: {
            value: _vm.form.certificate.certificate,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "certificate", $$v);
            },
            expression: "form.certificate.certificate"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type",
            "label-for": "type"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "type",
            "type": "text",
            "disabled": "",
            "value": _vm.form.certificate.certificate.certificateType ? _vm.form.certificate.certificate.certificateType === 'PROVISIONAL' ? 'PERMANENT (NO ENDORS)' : _vm.form.certificate.certificate.certificateType.replace('_', ' ') : '-'
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category",
            "label-for": "category"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "type",
            "type": "text",
            "disabled": "",
            "value": _vm.form.certificate.certificate.certificateCategory ? _vm.form.certificate.certificate.certificateCategory.name : '-'
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "certificateFile",
            "label": "File Certificate *"
          }
        }, [_c('b-form-file', {
          attrs: {
            "id": "customFile",
            "placeholder": "Choose a file",
            "accept": "application/pdf",
            "disabled": _vm.loading_upload_file
          },
          on: {
            "change": _vm.uploadFile
          }
        }), _vm.loading_upload_file ? _c('p', {
          staticClass: "mt-2 text-danger"
        }, [_vm._v("Please wait, uploading file is in process...")]) : _vm._e(), _vm.form.certificate.file ? _c('p', {
          staticClass: "mt-2"
        }, [_c('a', {
          attrs: {
            "href": _vm.form.certificate.file.url,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(_vm.form.certificate.file.fileName))])]) : _vm._e()], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "placeIssued",
            "label": "Place Issued"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "place_issued",
            "type": "text",
            "placeholder": "Place Issued"
          },
          model: {
            value: _vm.form.certificate.placeIssued,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "placeIssued", $$v);
            },
            expression: "form.certificate.placeIssued"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "issued_by",
            "label": "Issued By"
          }
        }, [_vm.form.certificate.certificate.certificateCategory ? _c('b-form-select', {
          attrs: {
            "options": _vm.form.certificate.certificate.certificateCategory.subCategory,
            "value-field": "id",
            "text-field": "name"
          },
          model: {
            value: _vm.form.certificate.issuedBy,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "issuedBy", $$v);
            },
            expression: "form.certificate.issuedBy"
          }
        }) : _c('span', [_vm._v("-")])], 1)], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Validity:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Issued"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          model: {
            value: _vm.form.certificate.dateIssued,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "dateIssued", $$v);
            },
            expression: "form.certificate.dateIssued"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _vm.form.certificate.certificate && _vm.form.certificate.certificate.certificateType !== 'PERMANENT' && _vm.form.certificate.certificate.certificateType !== 'PROVISIONAL' ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "expired",
            "label": "Valid Until"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          model: {
            value: _vm.form.certificate.expiredDate,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "expiredDate", $$v);
            },
            expression: "form.certificate.expiredDate"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1) : _vm._e()], 1)], 1), ((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$certificate = _vm$form2.certificate) === null || _vm$form2$certificate === void 0 ? void 0 : (_vm$form2$certificate2 = _vm$form2$certificate.certificate) === null || _vm$form2$certificate2 === void 0 ? void 0 : _vm$form2$certificate2.certificateType) === 'PERMANENT' ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Endorsement:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "notification",
            "label": "Next Endorsement*"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "1"
          },
          model: {
            value: _vm.form.certificate.nextEndorsement,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "nextEndorsement", $$v);
            },
            expression: "form.certificate.nextEndorsement"
          }
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "notification",
            "label": "Notification Endorsement Before*"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "1"
          },
          model: {
            value: _vm.form.certificate.notificationEndorsement,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "notificationEndorsement", $$v);
            },
            expression: "form.certificate.notificationEndorsement"
          }
        })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.form.certificate.certificate && _vm.form.certificate.certificate.certificateType === 'FULL_TERM' && _vm.isFulltermInterm ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Endorsement:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "notification",
            "label": "Next Endorsement*"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "1"
          },
          model: {
            value: _vm.form.certificate.nextEndorsement,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "nextEndorsement", $$v);
            },
            expression: "form.certificate.nextEndorsement"
          }
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "notification",
            "label": "Notification Endorsement Before*"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Month"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "min": "1"
          },
          model: {
            value: _vm.form.certificate.notificationEndorsement,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "notificationEndorsement", $$v);
            },
            expression: "form.certificate.notificationEndorsement"
          }
        })], 1)], 1)], 1)], 1), _c('b-row', [_vm.isFulltermInterm ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type of Endorsement*",
            "label-for": "Type of Endorsement"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.endorsement_types,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.form.certificate.endorsementType,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "endorsementType", $$v);
            },
            expression: "form.certificate.endorsementType"
          }
        })], 1)], 1) : _vm._e(), _vm.form.certificate.certificate && _vm.form.certificate.certificate.certificateType === 'FULL_TERM' && !_vm.isCertificateInterm() && _vm.form.certificate.endorsementType === 'VERIFIED' ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "DateVerified",
            "label": "Date Verified"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          model: {
            value: _vm.form.certificate.dateVerified,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "dateVerified", $$v);
            },
            expression: "form.certificate.dateVerified"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1) : _vm._e()], 1), _vm.form.certificate.endorsementType === 'MANUAL' ? _c('div', {
          staticClass: "mt-4"
        }, [_vm._l(_vm.form.certificate.endorsementDocuments, function (end, end_i) {
          return _c('b-row', {
            key: end_i,
            staticClass: "mt-2"
          }, [_c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_vm._v(" Endorsement #" + _vm._s(end.endorseNumber))]), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('date-picker', {
            attrs: {
              "lang": _vm.lang,
              "type": "date",
              "range": "",
              "placeholder": "Select range endorsement ".concat(end.endorseNumber)
            },
            model: {
              value: end.rangeEndorsement,
              callback: function callback($$v) {
                _vm.$set(end, "rangeEndorsement", $$v);
              },
              expression: "end.rangeEndorsement"
            }
          }), end_i !== 0 ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "ml-2",
            attrs: {
              "variant": "outline-danger",
              "title": "Delete endorsement ".concat(end.endorseNumber)
            },
            on: {
              "click": function click($event) {
                return _vm.deleteEndorsementDocument(end_i);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-times"
          })]) : _vm._e()], 1)], 1);
        }), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "block": "",
            "variant": "warning"
          },
          on: {
            "click": _vm.addEndorsementDocument
          }
        }, [_vm._v("Add Endorsement Based on Document ")])], 1)], 2) : _vm._e()], 1) : _vm._e(), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Remarks:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('label', [_vm._v("Office Checked")])]), _c('b-col', {
          attrs: {
            "md": "9"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.certificate.officeChecked,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "officeChecked", $$v);
            },
            expression: "form.certificate.officeChecked"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.certificate.officeChecked ? 'Yes' : 'No') + " ")]), _vm.form.certificate.officeChecked ? _c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Type Remark"
          },
          model: {
            value: _vm.form.certificate.officeRemark,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "officeRemark", $$v);
            },
            expression: "form.certificate.officeRemark"
          }
        }) : _vm._e()], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "3"
          }
        }, [_c('label', [_vm._v("Ship Checked")])]), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "9"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.certificate.shipChecked,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "shipChecked", $$v);
            },
            expression: "form.certificate.shipChecked"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.certificate.shipChecked ? 'Yes' : 'No') + " ")]), _vm.form.certificate.shipChecked ? _c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Type Remark"
          },
          model: {
            value: _vm.form.certificate.shipRemark,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "shipRemark", $$v);
            },
            expression: "form.certificate.shipRemark"
          }
        }) : _vm._e()], 1)], 1)], 1), _vm.form.certificate.certificate && !['PROVISIONAL'].includes(_vm.form.certificate.certificate.certificateType) ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Notification Receiver:")]), _vm.users && _vm.users.length > 0 ? _c('b-form-group', {
          attrs: {
            "label": "Receiver* ",
            "label-for": "category_for"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Select receivers",
            "label": "name",
            "track-by": "id",
            "options": _vm.users,
            "multiple": true
          },
          model: {
            value: _vm.form.certificate.user,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "user", $$v);
            },
            expression: "form.certificate.user"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Category not found.")])])], 1) : _c('p', {
          staticClass: "text-center my-3 text-muted"
        }, [_vm._v(_vm._s(_vm.form.certificate.vehicleId ? 'Users not found on selected fleet.' : 'Please select fleet first.'))])], 1) : _vm._e(), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              _vm.show_edit_modal = !_vm.show_edit_modal;
            }
          }
        }, [_vm._v("Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": _vm.loading_upload_file
          },
          on: {
            "click": _vm.doUpdateCertificate
          }
        }, [!_vm.loading_edit_certificates ? _c('span', [_vm._v("Save")]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2)], 1)], 1), _c('ModalDetailCertificate', {
          attrs: {
            "reminders": _vm.reminders
          },
          on: {
            "close": function close($event) {
              return _vm.toggleModalDetail(false);
            }
          }
        }), _c('ModalLogHistory', {
          on: {
            "close": function close($event) {
              return _vm.toggleModalLog(false);
            }
          }
        }), _c('ModalEndorsement', {
          on: {
            "refresh": _vm.fetchCertificates,
            "close": function close($event) {
              return _vm.toggleModalEndorsement(false);
            }
          }
        }), _c('ModalRenewal', {
          on: {
            "refresh": _vm.fetchCertificates,
            "close": function close($event) {
              return _vm.toggleModalRenewal(false);
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "master_certificate"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": _vm.isChildComponent ? !_vm.vehicleIdParam ? !_vm.menuPermission('add') && !_vm.menuPermission('update') ? '12' : '8' : '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "text-primary"
        }, [_c('strong', [_vm._v(" List Master Certificate ")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [[_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "8"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mr-0 pr-0"
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Find master certificates..."
          },
          model: {
            value: _vm.master_certificate_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.master_certificate_params, "search", $$v);
            },
            expression: "master_certificate_params.search"
          }
        })], 1), _c('b-col', {
          staticClass: "ml-0 pl-0",
          attrs: {
            "cols": "6"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.fetchMasterCertificates
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _vm.menuPermission('export') || _vm.lsProfile.menu[1].child[0].child[0].export ? _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12",
            "md": "4"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doExportMasterCertificates
          }
        }, [!_vm.loading_export_master_certificates ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2)], 1) : _vm._e()], 1)], _vm.loading_master_certificates ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.master_certificates.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Certificate Name")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Description")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Type/Remark")]), !_vm.isChildComponent ? _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.master_certificates, function (c, i) {
          return _c('tr', {
            key: "ms-c-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(" " + _vm._s(c.name) + " "), _c('br'), _c('strong', [_vm._v(_vm._s(c.certificateCategory.name))])]), _c('td', [_vm._v(_vm._s(c.description))]), _c('td', [_vm._v(_vm._s(c.certificateType ? c.certificateType === 'PROVISIONAL' ? 'PERMANENT (NO ENDORS)' : c.certificateType.replace("_", " ") : '-'))]), !_vm.isChildComponent ? _c('td', [_vm.menuPermission('update') || _vm.lsProfile.menu[1].child[0].child[0].update ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditMasterCertificate(c);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') || _vm.lsProfile.menu[1].child[0].child[0].delete ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteMasterCertificate(c);
              }
            }
          }, [!_vm.loading_delete_master_certificates ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v(" Deleting... ")]], 2) : _vm._e()], 1) : _vm._e()]);
        }), 0)]), _c('b-row', {
          staticClass: "mt-4"
        }, [_c('b-col', {
          attrs: {
            "col-6": ""
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.pagination_master_certificate.total) + "   |   Per page: " + _vm._s(_vm.pagination_master_certificate.perPage) + " ")]), _c('b-col', {
          attrs: {
            "col-6": ""
          }
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.pagination_master_certificate.total,
            "per-page": _vm.pagination_master_certificate.perPage,
            "align": "right"
          },
          on: {
            "input": _vm.fetchMasterCertificates
          },
          model: {
            value: _vm.master_certificate_params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.master_certificate_params, "page", $$v);
            },
            expression: "master_certificate_params.page"
          }
        })], 1)], 1)], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No master certificates found.")])]];
      },
      proxy: true
    }])
  })], 1), (_vm.menuPermission('add') || _vm.menuPermission('update') || _vm.lsProfile.menu[1].child[0].child[0].add || _vm.lsProfile.menu[1].child[0].child[0].update) && !_vm.isChildComponent ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": _vm.form_master_certificate_edit_mode ? "border border-danger shadow-lg" : ''
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v(_vm._s(_vm.form_master_certificate_edit_mode ? 'Edit' : 'Add') + " Master Certificate")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormMasterCertificate.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category",
            "label-for": "sub_project_name"
          }
        }, [_c('b-row', [_c('b-col', [_c('v-multiselect', {
          attrs: {
            "placeholder": "Select category",
            "label": "name",
            "track-by": "id",
            "options": _vm.certificate_categories
          },
          model: {
            value: _vm.form.master_certificate.certificateCategory,
            callback: function callback($$v) {
              _vm.$set(_vm.form.master_certificate, "certificateCategory", $$v);
            },
            expression: "form.master_certificate.certificateCategory"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Category not found.")])])], 1), _vm.form.master_certificate.certificateCategory ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.edit-certificate-subcategory",
            modifiers: {
              "edit-certificate-subcategory": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-2",
          attrs: {
            "variant": "outline-primary",
            "title": "Edit Sub Category"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onEditSubCategory.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })]) : _vm._e(), _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.add-certificate-category",
            modifiers: {
              "add-certificate-category": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-2",
          attrs: {
            "variant": "outline-primary",
            "title": "Add New Category"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })]), _c('b-modal', {
          attrs: {
            "id": "edit-certificate-subcategory",
            "size": "md",
            "title": "Edit Category ".concat(_vm.form.edit_certificate_category.name),
            "ok-title": "Save",
            "cancel-title": "Cancel",
            "centered": ""
          },
          on: {
            "ok": _vm.doEditCertificateCategory
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category name",
            "label-for": "category_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "category_name",
            "type": "text",
            "placeholder": "Category Name"
          },
          model: {
            value: _vm.form.edit_certificate_category.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit_certificate_category, "name", $$v);
            },
            expression: "form.edit_certificate_category.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Description",
            "label-for": "description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "5",
            "placeholder": "Category Description"
          },
          model: {
            value: _vm.form.edit_certificate_category.description,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit_certificate_category, "description", $$v);
            },
            expression: "form.edit_certificate_category.description"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Sub Category"
          }
        }, [_vm._l(_vm.form.edit_certificate_category.subCategory, function (sc, i) {
          return [_c('b-input-group', {
            key: "scf-".concat(i),
            staticClass: "mb-3",
            attrs: {
              "prepend": (i + 1).toString()
            }
          }, [_c('b-form-input', {
            attrs: {
              "placeholder": "Sub Category ".concat(i + 1, " Name"),
              "type": "text",
              "maxlength": "30"
            },
            model: {
              value: sc.name,
              callback: function callback($$v) {
                _vm.$set(sc, "name", $$v);
              },
              expression: "sc.name"
            }
          }), _c('b-input-group-append', [_c('b-button', {
            attrs: {
              "variant": "outline-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteSubCategory(i, true);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-times"
          })])], 1)], 1)];
        }), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addSubCategory(true);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("Add New Sub Category")])], 1)], 2)], 1), _c('b-modal', {
          attrs: {
            "id": "add-certificate-category",
            "size": "md",
            "title": "Add New Category",
            "ok-title": "Add",
            "cancel-title": "Cancel",
            "centered": ""
          },
          on: {
            "ok": _vm.doAddCertificateCategory
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category name",
            "label-for": "category_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "category_name",
            "type": "text",
            "placeholder": "Category Name"
          },
          model: {
            value: _vm.form.certificate_category.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate_category, "name", $$v);
            },
            expression: "form.certificate_category.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Description",
            "label-for": "description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Category Description"
          },
          model: {
            value: _vm.form.certificate_category.description,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate_category, "description", $$v);
            },
            expression: "form.certificate_category.description"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Sub Category"
          }
        }, [_vm._l(_vm.form.certificate_category.subCategory, function (sc, i) {
          return [_c('b-input-group', {
            key: "scf-".concat(i),
            staticClass: "mb-3",
            attrs: {
              "prepend": (i + 1).toString()
            }
          }, [_c('b-form-input', {
            attrs: {
              "placeholder": "Sub Category ".concat(i + 1),
              "type": "text",
              "maxlength": "30"
            },
            model: {
              value: sc.name,
              callback: function callback($$v) {
                _vm.$set(sc, "name", $$v);
              },
              expression: "sc.name"
            }
          }), _c('b-input-group-append', [_c('b-button', {
            attrs: {
              "variant": "outline-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteSubCategory(i, false);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-times"
          })])], 1)], 1)];
        }), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1 btn-sm",
          attrs: {
            "pill": "",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addSubCategory(false);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("Add New Sub Category")])], 1)], 2)], 1)], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Certificate Name",
            "label-for": "name_certificate"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name_certificate",
            "type": "text",
            "placeholder": "Name of Certificate"
          },
          model: {
            value: _vm.form.master_certificate.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.master_certificate, "name", $$v);
            },
            expression: "form.master_certificate.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Type",
            "for": "remarks"
          }
        }, [_c('b-form-radio-group', {
          attrs: {
            "id": "remarks"
          },
          model: {
            value: _vm.form.master_certificate.certificateType,
            callback: function callback($$v) {
              _vm.$set(_vm.form.master_certificate, "certificateType", $$v);
            },
            expression: "form.master_certificate.certificateType"
          }
        }, [_vm._l(_vm.remarks, function (item, index) {
          return [_c('b-form-radio', {
            key: "rm-".concat(index),
            attrs: {
              "value": item.value
            }
          }, [_vm._v(_vm._s(item.label) + " ")])];
        })], 2)], 1), _c('b-form-group', {
          attrs: {
            "label": "Description",
            "label-for": "description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Description"
          },
          model: {
            value: _vm.form.master_certificate.description,
            callback: function callback($$v) {
              _vm.$set(_vm.form.master_certificate, "description", $$v);
            },
            expression: "form.master_certificate.description"
          }
        })], 1), _vm.form.master_certificate.certificateType !== 'PROVISIONAL' && _vm.form.master_certificate.certificateType !== 'PERMANENT' ? _c('b-form-group', {
          attrs: {
            "label": "Setting Reminder",
            "label-for": "reminder"
          }
        }, [_c('b-form-checkbox-group', {
          attrs: {
            "options": _vm.reminders,
            "switches": "",
            "stacked": ""
          },
          model: {
            value: _vm.form.master_certificate.reminder,
            callback: function callback($$v) {
              _vm.$set(_vm.form.master_certificate, "reminder", $$v);
            },
            expression: "form.master_certificate.reminder"
          }
        })], 1) : _vm._e(), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_master_certificates ? _c('span', [_vm._v(_vm._s(_vm.form_master_certificate_edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "Filter": "",
            "Log": "",
            "History": "",
            "Users": "",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormMasterCertificate.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel ")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 656629171)
  })], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SHIPMENT REAL")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$optionsCargoType$, _vm$optionsCargoType$2, _vm$optionsContractTy, _vm$optionsContractTy2, _vm$optionsStage$find, _vm$optionsStage$find2, _vm$params$ownership;
        return [_c('div', {
          staticClass: "d-flex justify-content-between mb-3"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form', {
          staticClass: "d-flex",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onFilter.apply(null, arguments);
            }
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "width": "220px"
          },
          attrs: {
            "placeholder": "search project..."
          },
          model: {
            value: _vm.params.keyword,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "keyword", $$v);
            },
            expression: "params.keyword"
          }
        }), _c('b-button', {
          staticClass: "mx-2",
          attrs: {
            "variant": "primary",
            "type": "submit"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.toggleModalFilter
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data")])])], 1), _c('div', {
          staticClass: "d-flex"
        }, [_c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-download mr-1"
              }), _vm._v(" Export ")])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1), _c('b-button', {
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.refreshDataTable
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        }), _vm._v("   Refresh Data")]), _c('b-button', {
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.slideRunningShipment = true;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        }), _vm._v("Operational Data Running Shipment")])], 1)]), _c('b-modal', {
          attrs: {
            "centered": "",
            "title": "Filter Data",
            "ok-title": "Filter"
          },
          on: {
            "ok": _vm.onFilter
          },
          model: {
            value: _vm.modal.filter,
            callback: function callback($$v) {
              _vm.$set(_vm.modal, "filter", $$v);
            },
            expression: "modal.filter"
          }
        }, [_c('b-row', {}, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-3",
          attrs: {
            "label": "Period",
            "label-for": "Period",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('date-picker', {
          style: {
            width: '100%'
          },
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-3",
          attrs: {
            "label": "Cargo Type",
            "label-for": "cargo",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsCargoType
          },
          model: {
            value: _vm.params.cargo_type_id,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "cargo_type_id", $$v);
            },
            expression: "params.cargo_type_id"
          }
        }, [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("All")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-3",
          attrs: {
            "label": "Stage Status",
            "label-for": "stage_id",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-select', {
          staticClass: "mb-2",
          attrs: {
            "plain": "",
            "options": _vm.optionsStage
          },
          model: {
            value: _vm.params.stage_id,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "stage_id", $$v);
            },
            expression: "params.stage_id"
          }
        }, [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("All")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-3",
          attrs: {
            "label": "Ownership",
            "label-for": "fleet_ownership",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-select', {
          staticClass: "mb-2",
          attrs: {
            "plain": ""
          },
          model: {
            value: _vm.params.fleet_ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "fleet_ownership", $$v);
            },
            expression: "params.fleet_ownership"
          }
        }, [_c('b-form-select-option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("All")]), _c('b-form-select-option', {
          attrs: {
            "value": "own-ship"
          }
        }, [_vm._v("Own Ship")]), _c('b-form-select-option', {
          attrs: {
            "value": "chartered"
          }
        }, [_vm._v("Chartered")])], 1)], 1)], 1), _vm.params.fleet_ownership ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Vehicle",
            "label-for": "vehicle_id",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "name": "vehicle_id",
            "options": _vm.optionsFleets,
            "reduce": function reduce(optionsFleets) {
              return optionsFleets.value;
            },
            "placeholder": "Select Fleet"
          },
          model: {
            value: _vm.params.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "vehicle_id", $$v);
            },
            expression: "params.vehicle_id"
          }
        })], 1)], 1) : _vm._e()], 1)], 1), _c('div', {
          staticClass: "px-3 pb-3"
        }, [_c('b-row', {
          staticClass: "border rounded-md pt-2"
        }, [_vm.params.cargo_type_id ? _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Cargo Type :"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s((_vm$optionsCargoType$ = (_vm$optionsCargoType$2 = _vm.optionsCargoType.find(function (el, i) {
          return el.id == _vm.params.cargo_type_id;
        })) === null || _vm$optionsCargoType$2 === void 0 ? void 0 : _vm$optionsCargoType$2.name) !== null && _vm$optionsCargoType$ !== void 0 ? _vm$optionsCargoType$ : '-'))])])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Contract Type :"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s((_vm$optionsContractTy = (_vm$optionsContractTy2 = _vm.optionsContractType.find(function (el, i) {
          return el.id == _vm.params.contract_type_id;
        })) === null || _vm$optionsContractTy2 === void 0 ? void 0 : _vm$optionsContractTy2.name) !== null && _vm$optionsContractTy !== void 0 ? _vm$optionsContractTy : 'All'))])])], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Stage Status :"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s((_vm$optionsStage$find = (_vm$optionsStage$find2 = _vm.optionsStage.find(function (el, i) {
          return el.value == _vm.params.stage_id;
        })) === null || _vm$optionsStage$find2 === void 0 ? void 0 : _vm$optionsStage$find2.text) !== null && _vm$optionsStage$find !== void 0 ? _vm$optionsStage$find : 'All'))])])], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ownership"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s((_vm$params$ownership = _vm.params.ownership) !== null && _vm$params$ownership !== void 0 ? _vm$params$ownership : "All"))])])], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_vm.dateRange.length ? _c('b-form-group', {
          attrs: {
            "label": "Period :"
          }
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(_vm.dateRange[0] ? _vm.moment(_vm.dateRange[0]).format("DD MMMM YYYY") : '') + " - "), _c('span', [_vm._v(_vm._s(_vm.dateRange[1] ? _vm.moment(_vm.dateRange[1]).format("DD MMMM YYYY") : ''))])])])]) : _vm._e()], 1)], 1)], 1), _c('b-row', [!_vm.loading ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex overflow-auto",
          staticStyle: {
            "height": "400px"
          }
        }, [_c('table', {
          staticClass: "table table-responsive"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center text-truncate",
          staticStyle: {
            "width": "5px"
          }
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center text-truncate w-100"
        }, [_vm._v("SHIPPER")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("TYPE")]), _c('th', {
          staticClass: "text-right text-truncate"
        }, [_vm._v("SHIPMENT PLAN")]), _c('th', {
          staticClass: "text-right text-truncate"
        }, [_vm._v("VOLUME PLAN")]), _c('th', {
          staticClass: "text-right text-truncate"
        }, [_vm._v("SHIPMENT REAL")]), _c('th', {
          staticClass: "text-right text-truncate"
        }, [_vm._v("VOLUME REAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STAGE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _vm.list.data.length > 0 ? _c('tbody', _vm._l(_vm.list.data, function (state, index) {
          var _state$customer$accou, _state$customer2, _state$cargo_type, _state$volume, _state$volume_real;
          return _c('tr', {
            key: index
          }, [_c('th', {
            staticClass: "text-center bg-white text-black"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_c('div', {
            staticClass: "d-flex align-items-center",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                var _state$customer;
                return _vm.openNewWindow(state === null || state === void 0 ? void 0 : (_state$customer = state.customer) === null || _state$customer === void 0 ? void 0 : _state$customer.id);
              }
            }
          }, [_c('b-avatar', {
            attrs: {
              "size": "34",
              "variant": "warning",
              "src": require('../../../assets/images/user/11.png')
            }
          }), _c('h6', {
            staticClass: "ml-2 text-primary"
          }, [_c('strong', [_vm._v(" " + _vm._s((_state$customer$accou = state === null || state === void 0 ? void 0 : (_state$customer2 = state.customer) === null || _state$customer2 === void 0 ? void 0 : _state$customer2.account_name) !== null && _state$customer$accou !== void 0 ? _state$customer$accou : 'N/A') + " ")])])], 1)]), _c('td', {
            staticClass: "text-center text-truncate text-primary",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideFormAddPlan('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
              }
            }
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$cargo_type = state.cargo_type) === null || _state$cargo_type === void 0 ? void 0 : _state$cargo_type.name))]), _c('td', {
            staticClass: "text-right text-truncate text-primary",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideFormAddPlan('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
              }
            }
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.shipment) + " SHIPMENT")]), _c('td', {
            staticClass: "text-right text-truncate text-primary",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideFormAddPlan('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
              }
            }
          }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.volume ? state === null || state === void 0 ? void 0 : (_state$volume = state.volume) === null || _state$volume === void 0 ? void 0 : _state$volume.toLocaleString() : '0') + " " + _vm._s(state === null || state === void 0 ? void 0 : state.unit))]), _c('td', {
            staticClass: "text-right text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.shipment_real) + " SHIPMENT")]), _c('td', {
            staticClass: "text-right text-truncate"
          }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.volume_real ? state === null || state === void 0 ? void 0 : (_state$volume_real = state.volume_real) === null || _state$volume_real === void 0 ? void 0 : _state$volume_real.toLocaleString() : '0') + " " + _vm._s(state === null || state === void 0 ? void 0 : state.unit))]), _c('td', {
            staticClass: "text-center bg-white text-black"
          }, [_c('b-dropdown', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.top",
              value: 'Action',
              expression: "'Action'",
              modifiers: {
                "top": true
              }
            }],
            staticClass: "float-right mr-1",
            attrs: {
              "variant": "link iq-bg-primary",
              "toggle-class": "text-decoration-none",
              "no-caret": "",
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                var _state$project_stage;
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : (_state$project_stage = state.project_stage) === null || _state$project_stage === void 0 ? void 0 : _state$project_stage.title) + " ")])];
              },
              proxy: true
            }], null, true)
          }, _vm._l(_vm.listProjectStage, function (obj, objIndex) {
            return _c('b-dropdown-item', {
              key: objIndex,
              attrs: {
                "href": "#",
                "variant": (state === null || state === void 0 ? void 0 : state.detail_project_stage_id) === obj.id ? 'primary' : 'secondary'
              }
            }, [_c('strong', {
              on: {
                "click": function click($event) {
                  return _vm.onChangeStatusStage(index, state === null || state === void 0 ? void 0 : state.id, obj.id);
                }
              }
            }, [_vm._v(_vm._s(obj === null || obj === void 0 ? void 0 : obj.title))])]);
          }), 1)], 1), _c('td', {
            staticClass: "text-center bg-white text-black"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideFormAddPlan('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-eye mr-2"
          }), _vm._v("Detail ")]), _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideFormRealShipment('ADD', state === null || state === void 0 ? void 0 : state.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-plus mr-2"
          }), _vm._v("Add Real Shipment ")])], 1)], 1)]);
        }), 0) : _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "9"
          }
        }, [_c('h6', {
          staticClass: "text-muted"
        }, [_c('strong', [_vm._v("No Data Found.")])])])])])])]), _vm.list.data.length > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.list.meta.totalItem,
            "per-page": _vm.list.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListRunningShipmentTable
          },
          model: {
            value: _vm.page,
            callback: function callback($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1) : _vm._e()]) : _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "alert alert-info"
        }, [_vm._v(" Loading... ")])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "add_rakor",
      "title": ['PREVIEW', 'EDIT'].includes(_vm.action) ? 'DETAIL PLAN' : "ADD PLAN",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('FormAddPlan', {
          attrs: {
            "open-customer-slide": function openCustomerSlide() {},
            "refresh-data-table": _vm.refreshDataTable,
            "refreshDataTableWithoutCloseModal": function refreshDataTableWithoutCloseModal() {},
            "action": _vm.action,
            "edit-id": _vm.editId,
            "openInspectionChecklistSlide": _vm.openInspectionChecklistSlide,
            "optionsCompanies": _vm.optionsCompanies
          },
          on: {
            "updateAction": _vm.updateAction
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideAddRakor,
      callback: function callback($$v) {
        _vm.slideAddRakor = $$v;
      },
      expression: "slideAddRakor"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideFormRealShipment",
      "title": "Create Real Shipment",
      "width": "90%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('SlideFormRealShipment', {
          attrs: {
            "closeSlideFormRealShipment": _vm.closeSlideFormRealShipment,
            "refreshData": function refreshData() {},
            "optionsFleets": _vm.optionsFleets,
            "projectId": _vm.idRealShipment,
            "action": _vm.actionRealShipment
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideFormRealShipment,
      callback: function callback($$v) {
        _vm.slideFormRealShipment = $$v;
      },
      expression: "slideFormRealShipment"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideRunningShipment",
      "title": "Operational Data Running Shipment",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    model: {
      value: _vm.slideRunningShipment,
      callback: function callback($$v) {
        _vm.slideRunningShipment = $$v;
      },
      expression: "slideRunningShipment"
    }
  }, [_c('SlideRunningShipment')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.detailMi ? _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('h5', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('b-button', {
    ref: "button",
    attrs: {
      "id": "popover-type",
      "variant": "info"
    }
  }, [_vm._v("Claim")])], 1), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Status")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('b-button', {
    ref: "button",
    attrs: {
      "id": "popover-view-status",
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.status.name))])], 1)]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.company.company))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Name of Vessel")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.vehicle.name))])], 1)]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type of Coverage")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.type_coverage.name))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Place of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.place_of_loss))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Cause of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.type_loss.name))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.type_loss.name))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Date of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.date_of_loss))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Created Form")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_vm._v(_vm._s(_vm.detailMi.created_at))])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.remarks))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Cost")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v("Rp." + _vm._s(_vm.detailMi.cost))])])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('h5', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("CLAIMANT / NOTIFICATION DETAILS")])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.company.company))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Department/Vessel")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.department.name))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Status")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailMi.status.name))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', [_c('strong')])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('h5', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("MONITORING LIST")]), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.add-monitoring",
      modifiers: {
        "add-monitoring": true
      }
    }],
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "variant": "primary"
    },
    on: {
      "click": _vm.onCreate
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v("Add Monitoring")])], 1)]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Actual Claim Progress")]), _c('th', [_vm._v("Person in Charge")]), _c('th', [_vm._v("Start Date")]), _c('th', [_vm._v("End Date")]), _c('th', [_vm._v("Total Days")]), _c('th', [_vm._v("Remarks")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("#")]), _c('th')])]), _c('tbody', _vm._l(_vm.detailMi.monitorings, function (monitoring, index) {
    return _c('tr', {
      key: monitoring.id
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(monitoring.actual_claim_progress))]), _c('td', [_vm._v(_vm._s(monitoring.person_in_charge))]), _c('td', [_vm._v(_vm._s(monitoring.start_date))]), _c('td', [_vm._v(_vm._s(monitoring.end_date))]), _c('td', [_vm._v(_vm._s(monitoring.total_days))]), _c('td', [_vm._v(_vm._s(monitoring.remarks))]), _c('td', [_vm._v(_vm._s(monitoring.status.split('_').join(' ')))]), _c('td', [_c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.add-monitoring",
        modifiers: {
          "add-monitoring": true
        }
      }],
      attrs: {
        "variant": "warning",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onEdit(monitoring);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-edit"
    })])], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onDelete(monitoring);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash text-white"
    })])], 1)]);
  }), 0)])])], 1), _c('b-sidebar', {
    attrs: {
      "id": "add-monitoring",
      "title": "ADD MONITORING DATA",
      "width": "800px",
      "backdrop-variant": "dark",
      "header-class": "bg-white",
      "body-class": "p-3",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave();
      }
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2 mx-0 bg-white",
    staticStyle: {
      "border-radius": "8px"
    }
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Input Your Monitoring Data")])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm._v("Actual Claim Progress")]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_vm._v(":")]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "exampleFormControlTextarea1",
      "rows": "3"
    },
    model: {
      value: _vm.form.actual_claim_progress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "actual_claim_progress", $$v);
      },
      expression: "form.actual_claim_progress"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm._v("Person in Charge")]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_vm._v(":")]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "serial",
      "type": "text"
    },
    model: {
      value: _vm.form.person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "person_in_charge", $$v);
      },
      expression: "form.person_in_charge"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm._v("Start Date")]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_vm._v(":")]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "datetime",
      "format": "YYYY-MM-DD HH:mm:ss",
      "value-type": "format",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.form.start_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "start_date", $$v);
      },
      expression: "form.start_date"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm._v("End Date")]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_vm._v(":")]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "datetime",
      "format": "YYYY-MM-DD HH:mm:ss",
      "value-type": "format",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.form.end_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "end_date", $$v);
      },
      expression: "form.end_date"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm._v("Status")]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_vm._v(":")]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Status",
      "label": "name",
      "track-by": "value",
      "options": _vm.statusOptions
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Data not found.")])])], 1)], 1), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm._v("Remarks")]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_vm._v(":")]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "2"
    },
    model: {
      value: _vm.form.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    staticClass: "text-right mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "disabled": _vm.form._saving,
      "variant": "primary"
    }
  }, [_vm._v(" Submit")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.add-monitoring",
      modifiers: {
        "add-monitoring": true
      }
    }],
    staticClass: "iq-bg-danger ml-3",
    attrs: {
      "disabled": _vm.form._saving,
      "variant": "none"
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1)], 1)])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
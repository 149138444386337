<template>
  <div class="p-2 px-3">
    <template v-for="(item, index) in itemActions">
      <b-button
        v-if="item.permission"
        :key="index"
        :variant="'outline-' + item.variant"
        class="text-truncate w-100 mb-1 d-flex justify-content-start align-items-center"
        @click="item.action"
      >
        <i :class="item.icon"></i>
        {{ item.label }}{{ fleet?.unscheduledDocking.includes('SPECIAL') }}
      </b-button>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    permissionEditFleet: Boolean,
    permissionDeleteFleet: Boolean,
    permissionMaintenanceSetup: Boolean,
    permissionMaintenanceInitiation: Boolean,
    permissionSpecialSurvey: Boolean,
    fleet: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      itemActions: [
        {
          label: 'Crew List',
          icon: 'fa fa-user',
          variant: 'warning',
          action: () => this.$emit('showCrewList'),
          permission: true,
        },
        {
          label: 'Update Fleets',
          icon: 'fa fa-edit',
          action: () => this.$emit('onUpdate'),
          variant: 'primary',
          permission: this.permissionEditFleet,
        },
        {
          label: 'Delete (Inactive)',
          icon: 'fa fa-trash',
          action: () => this.$emit('onDelete'),
          variant: 'danger',
          permission: this.permissionDeleteFleet,
        },
        {
          label: 'Setup Component',
          icon: 'fa fa-file',
          action: () => this.$emit('onSetup'),
          variant: 'success',
          permission: this.permissionMaintenanceSetup,
        },
        {
          label: 'Maintenance Initiation',
          icon: 'fa fa-file',
          action: () => this.$emit('onInit'),
          variant: 'success',
          permission: this.permissionMaintenanceInitiation,
        },
        {
          label: 'Set Intermediate Survey',
          icon: 'fa fa-file',
          action: () => this.$emit('setItermediate'),
          variant: 'danger',
          permission:
            this.permissionSpecialSurvey &&
            this.fleet?.unscheduledDocking.includes('INTERMEDIATE'),
        },
        {
          label: 'Set Special Survey',
          icon: 'fa fa-file',
          action: () => this.$emit('setSpecial'),
          variant: 'info',
          permission:
            this.permissionSpecialSurvey &&
            this.fleet?.unscheduledDocking.includes('SPECIAL'),
        },
        {
          label: 'Data Claim',
          icon: 'fa fa-file',
          action: () => null,
          variant: 'success',
          permission: false,
        },
        {
          label: 'Data Non Claim',
          icon: 'fa fa-file',
          action: () => null,
          variant: 'success',
          permission: false,
        },
        {
          label: 'Schedule Docking IS',
          icon: 'fa fa-calendar',
          action: () => null,
          variant: 'info',
          permission: false,
        },
        {
          label: 'Schedule Docking',
          icon: 'fa fa-calendar',
          action: () => null,
          variant: 'info',
          permission: false,
        },
      ],
    }
  },
  watch: {
    permissionEditFleet(newVal) {
      this.itemActions = this.itemActions
    },
    permissionDeleteFleet(newVal) {
      this.itemActions = this.itemActions
    },
    permissionMaintenanceSetup(newVal) {
      this.itemActions = this.itemActions
    },
    permissionMaintenanceInitiation(newVal) {
      this.itemActions = this.itemActions
    },
    permissionSpecialSurvey(newVal) {
      this.itemActions = this.itemActions
    },
    fleet: {
      handler(newVal) {
        this.itemActions = this.itemActions; 
      },
      deep: true 
    }
  },
}
</script>

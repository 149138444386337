<template>
  <div class="mt-4">
    <form v-if="detailMi && detailSurvey && !loading.survey" action="" @submit.prevent="onSave">
      <b-row>
        <b-col md="12" class="d-flex justify-content-end mb-3">
          <b-button variant="outline-primary" class="mr-2">Export</b-button>
          <b-button variant="primary" @click="isEditMode = !isEditMode"
            >{{ isEditMode ? 'Preview' : 'Update' }} Cost Breakdown</b-button
          >
        </b-col>
        <b-col md="12" class="">
          <table class="table table-striped table-bordered">
            <thead>
              <tr width="100%">
                <th class="text-center" width="5%">NO</th>
                <th width="45%">Description/Item</th>
                <th width="20%">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cb, index) in listBreakdowns" :key="index">
                <td class="text-center">{{ index + 1 }}</td>
                <td>{{ cb.description }}</td>
                <td v-if="isEditMode">
                  <div
                    style="width: 250px"
                    class="d-flex justify-content-end w-100"
                  >
                    <InputCurrency
                      v-model="cb.value"
                      placeholder="Text Number..."
                    />
                  </div>
                </td>
                <td v-else class="text-right pr-4">
                  <p class="mb-0">{{ formatCurrency(cb.value) }}</p>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-right"
                  ><h6><b>Total</b></h6></td
                >
                <td colspan="1" class="text-right"
                  ><h6
                    ><b>IDR. {{ numberFormat(subTotal) }}</b></h6
                  ></td
                >
              </tr>
              <tr>
                <td colspan="2" class="text-right"
                  ><h6><b>Deductible</b></h6></td
                >
                <td colspan="1" class="d-flex justify-content-end">
                  <div style="max-width: 250px" v-if="isEditMode">
                    <InputCurrency v-model="form.deductible" />
                  </div>
                  <h6 v-else
                    ><b>IDR. {{ numberFormat(form.deductible) }}</b></h6
                  >
                  <template
                    v-if="
                      detailMi.type_coverage &&
                      detailMi.type_coverage.name === 'Hull & Machinery'
                    "
                  >
                    <br /><small>Price List Kapal HM x Deductible %</small>
                  </template>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-right"
                  ><h6><b>Grand Total</b></h6></td
                >
                <td colspan="1" class="text-right"
                  ><h6
                    ><b>IDR. {{ numberFormat(grandTotal) }}</b></h6
                  ></td
                >
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col offset="6" cols="6" class="text-right mb-5">
          <!-- <b-button variant="link mr-3">
            <i class="ri-printer-line"></i>
            Download Print
          </b-button> -->
          <b-button v-if="isEditMode" type="submit" variant="primary">
            Submit Cost Breakdown</b-button
          >
        </b-col>
      </b-row>
    </form>
    <div v-else class="py-5 my-5 d-flex justify-content-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>
<script>
import InputCurrency from '@/src/components/global/InputCurrency.vue'
import { xray } from '@/src/config/pluginInit'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  components: { StepNavigation, StepWizard, InputCurrency },
  props: {
    detailMi: null,
  },
  data() {
    return {
      permission_user: JSON.parse(localStorage.getItem('profile')).role.roles,
      // detailMi: null,
      detailSurvey: null,
      listBreakdowns: [],
      isEditMode: false,
      form: {
        _saving: false,
        sub_total: 0,
        deductible: 0,
        grand_total: 0,
      },
      loading: {
        survey: false,
      },
    }
  },
  computed: {
    userPermission() {
      if (
        [
          'SUPER ADMIN',
          'MARINE INSURANCE',
          'MARINE INSURANCE ADMIN',
          'MARINE INSURANCE MANAGER',
        ].includes(this.permission_user)
      ) {
        return true
      } else {
        return false
      }
    },
    subTotal() {
      let ret = 0

      this.listBreakdowns.forEach((list) => {
        if (list.value > -0) {
          ret += parseInt(list.value)
        }
      })

      return ret
    },

    grandTotal() {
      return parseInt(this.subTotal) - parseInt(this.form.deductible)
    },
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    numberFormat(number) {
      return numberFormat(number)
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    // async fetchMi() {
    //   const { data, status } = await this.fetchDetailMi({
    //     id: this.$route.query.formId,
    //     with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories',
    //   })

    //   if ([500, 404, 403].includes(status)) {
    //     this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
    //     return
    //   }

    //   this.detailMi = data
    // },
    async fetchSurvey() {
      try {
        const { data, status } = await this.getCostBreakdownByFormId({
          id: this.$route.query.formId,
        })

        if ([500, 404, 403].includes(status)) {
          this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
          return
        }

        this.listBreakdowns = data.items
        this.detailSurvey = data

        if (data.breakdown && data.breakdown.deductible > 0) {
          this.form.deductible = data.breakdown.deductible || 0
        } else {
          this.fetchDeductible()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchDeductible() {
      const { data, status } = await this.getFormDeductible({
        id: this.$route.query.formId,
      })

      if ([500, 404, 403].includes(status)) {
        return
      }

      this.form.deductible = data || 0
    },
    async onSave(e) {
      e.preventDefault()
      this.form._saving = true
      const contents = []
      this.listBreakdowns.forEach((content) => {
        contents.push({
          id: content.id,
          value: parseInt(content.value),
        })
      })

      const { data, status } = await this.saveCostBreakdown({
        form_id: this.$route.query.formId,
        deductible: parseInt(this.form.deductible),
        grand_total: parseInt(this.grandTotal),
        contents,
      })

      if (status === 422) {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.form._saving = false

        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
        this.form._saving = false

        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form._saving = false
    },
  },
  async mounted() {
    this.loading.survey = true
    xray.index()
    // await this.fetchMi()
    await this.fetchSurvey()
    this.loading.survey = false
  },
}
</script>

<template>
  <div class="maintenance-history-record">
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h5 class="card-title text-primary"><strong>Maintenance History Record</strong></h5>
          </template>
          <template v-slot:body>
            <b-row class="px-3" v-if="!preview">
              <b-col md="4">
                <b-form-group label="Ship Ownership" label-for="ship_ownership">
                  <b-form-select  
                    plain
                    size="sm"
                    placeholder="Select..."
                    v-model="params.ship_ownership"
                    label-cols-sm="4"
                    label-cols-lg="3"
                  >
                    <option value="">All</option>
                    <option value="owned">Owned by Company</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Company"
                  label-for="company"
                >
                  <v-select
                    v-model="params.company_id"
                    label="text"
                    :options="[]"
                    placeholder="Select..."
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Fleet"
                  label-for="fleet-equipment"
                >
                  <v-select
                    v-model="params.vehicle_id"
                    label="text"
                    :options="[]"
                    placeholder="Select..."
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <button type="button" class="btn" :style="{ backgroundColor: '#2BDCAA', color: 'white', marginTop:'30px' }" block @click="onFilter"><i class="fa fa-search" /></button>
              </b-col>
            </b-row>
            <div class="d-flex w-100 border my-2" v-if="!preview" />
            <b-row v-if="!preview">
              <b-col md="4" class="mb-2">
                <b-card class="rounded-lg shadow">
                  <img :src="require('../../assets/images/example-fleets.png')" alt="f" height="240" class="w-100"/>
                  <div class="d-flex w-100 border my-2" />
                  <template>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <h5><strong class="text-primary">TB. SML 301 🇮🇩</strong></h5>
                      <b-badge variant="warning" class="p-2">
                        SOLD
                      </b-badge>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">Tug boat</span>
                      </div>
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">PT. Sentra Makmur Lines</span>
                      </div>
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">Owned</span>
                      </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between p-2 rounded-lg mb-2" style="background-color: #F9FAFB" >
                      <span class="text-black">Maintenance Planned / Initiation</span>
                      <strong class="text-black">130 Plan</strong>
                    </div>
                  </template>
                  <b-button variant="warning" class="w-100 align-items-center px-2" style="padding: 10px 0;"  @click="onDetail(null, !preview)">
                    <div class="d-flex align-items-center justify-content-center">
                      <DetailHistoryIcon style="height: 20px; width: 20px;" class="mr-2" />
                      <p class="mb-0">Detail Maintenance</p>
                    </div>
                  </b-button>
                </b-card>
              </b-col>
              <b-col md="4" class="mb-2">
                <b-card class="rounded-lg shadow">
                  <img :src="require('../../assets/images/example-fleets.png')" alt="f" height="240" class="w-100"/>
                  <div class="d-flex w-100 border my-2" />
                  <template>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <h5><strong class="text-primary">TB. SML 301 🇮🇩</strong></h5>
                      <b-badge variant="danger" class="p-2">
                        SINKED
                      </b-badge>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">Tug boat</span>
                      </div>
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">PT. Sentra Makmur Lines</span>
                      </div>
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">Owned</span>
                      </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between p-2 rounded-lg mb-2" style="background-color: #F9FAFB" >
                      <span class="text-black">Maintenance Planned / Initiation</span>
                      <strong class="text-black">130 Plan</strong>
                    </div>
                  </template>
                  <b-button variant="warning" class="w-100 align-items-center px-2" style="padding: 10px 0;"  @click="onDetail(null, !preview)">
                      <div class="d-flex align-items-center justify-content-center">
                        <DetailHistoryIcon style="height: 20px; width: 20px;" class="mr-2" />
                        <p class="mb-0">Detail Maintenance</p>
                      </div>
                    </b-button>
                </b-card>
              </b-col>
              <b-col md="4" class="mb-2">
                <b-card class="rounded-lg shadow">
                  <img :src="require('../../assets/images/example-fleets.png')" alt="f" height="240" class="w-100"/>
                  <div class="d-flex w-100 border my-2" />
                  <template>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <h5><strong class="text-primary">TB. SML 301 🇮🇩</strong></h5>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">Tug boat</span>
                      </div>
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">PT. Sentra Makmur Lines</span>
                      </div>
                      <div class="d-flex align-items-center mr-2">
                        <div style="height: 6px; width: 6px; border-radius: 50%; background-color: black;" class="mx-2"></div>
                        <span class="text-black">Owned</span>
                      </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between p-2 rounded-lg mb-2" style="background-color: #F9FAFB" >
                      <span class="text-black">Maintenance Planned / Initiation</span>
                      <strong class="text-black">130 Plan</strong>
                    </div>
                  </template>
                
                  <b-button variant="warning" class="w-100 align-items-center px-2" style="padding: 10px 0;" @click="onDetail(null)">
                    <div class="d-flex align-items-center justify-content-center">
                      <DetailHistoryIcon style="height: 20px; width: 20px;" class="mr-2" />
                      <p class="mb-0">Detail Maintenance</p>
                    </div>
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col md="12">
                <div class="text-primary d-flex align-items-center" style="cursor:pointer" @click="onDetail(null)">
                  <i class="fa fa-arrow-left mr-2" />
                  Back
                </div>
                <MaintenanceHistoryRecordDetail />
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <!-- Sidebar View -->
        <!-- <b-sidebar
          v-model="viewDetailMaintenanceHistory"
          id="view-detail-history"
          title="View Job Maintenance"
          width="1300px"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow
        >
          <template>
            <MaintenanceHistoryRecordDetail />
          </template>
        </b-sidebar> -->
        <!-- End Sidebar View -->
      </b-col>
    </b-row>
    <!-- End Content Section !-->
  </div>
</template>

<script>
import {
  fleetsActions,
  maintenanceActions,
  filesActions,
} from '@src/Utils/helper'
import _ from 'lodash'
import MaintenanceHistoryRecordDetail from './Components/MaintenanceHistoryRecordDetail'
import AdvancedFilter from './Components/AdvancedFilter'
import DetailHistoryIcon from './Components/Icons/DetailHistoryIcon'

export default {
  name: 'MaintenanceHistoryRecord',
  components: { 
    MaintenanceHistoryRecordDetail, 
    AdvancedFilter,
    DetailHistoryIcon
  },
  data() {
    return {
      viewDetailMaintenanceHistory:false,

      params: {
        ship_ownership: 'owned',
        company_id: null,
        vehicle_id: null
      },

      preview:false,
      detailData: null,
    }
  },
  computed: {

  },
  watch: {
   
  },
  created() {
  },
  async mounted() {},
  methods: {
    onDetail(id){
      this.preview = !this.preview
    },
    getListHistoryRecord(){},
    onFilter(){},
  },
}
</script>

<style>
.editr--content ul {
  list-style: circle;
}
</style>

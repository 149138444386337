<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>BUNKER ROUTES</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center gap-4 float-right">
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="onExportBunkerRoute">
                <i class="fa fa-file-excel mr-2" />
                <span>Export Data</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="onAdd" v-if="addPermission">
                <i class="fa fa-plus mr-2" />
                <span>Create Data</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <!-- FILTERING BunkerRouteS -->
            <b-row class="mb-4">
              <b-col md="3">
                <b-form-group label="Search" label-for="search" v-model="params.search">
                  <b-form-input
                    type="text"
                    placeholder="Search ID/Name..."
                    v-model="params.search"
                    name="search"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button
                    @click="getListBunkerRoute"
                    type="button"
                    variant="primary"
                    ><i class="fa fa-filter"></i
                  ></b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- TABLE CONTENT -->
            <b-row>
              <b-col md="12">
                <div v-if="loading" class="alert alert-info">Loading...</div>
                <iq-card> 
                  <div class="overflow-auto d-flex" v-if="list?.data?.length > 0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                            <th class="text-center align-middle" style="width: 20px">No</th>
                            <th class="text-left align-middle text-truncate">Route ID</th>
                            <th class="text-left align-middle text-truncate">POL</th>
                            <th class="text-left align-middle text-truncate">POD</th>
                            <th class="text-left align-middle text-truncate">Type</th>
                            <th class="text-left align-middle text-truncate">Route Name</th>
                            <th class="text-right align-middle text-truncate">Steaming Time</th>
                            <th class="text-right align-middle text-truncate">Distance</th>
                            <th class="text-right align-middle text-truncate">Speed</th>
                            <th class="text-right align-middle text-truncate">Ltrs</th>
                            <th class="text-right align-middle text-truncate">Amount</th>
                            <th class="text-center align-middle" style="width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(val, index) in list.data" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td class="text-truncate">{{ val?.route_code }}</td>
                          <td class="text-truncate">{{ val?.pol?.port_name }}</td>
                          <td class="text-truncate">{{ val?.pod?.port_name }}</td>
                          <td class="text-truncate">{{ val?.type }}</td>
                          <td class="text-truncate">{{ val?.route_name }}</td>
                          <td class="text-truncate">{{ val?.streaming_time?.toLocaleString() }}</td>
                          <td class="text-truncate">{{ val?.distance?.toLocaleString() }}</td>
                          <td class="text-truncate">{{ val?.speed?.toLocaleString() }}</td>
                          <td class="text-truncate">{{ val?.ltrs?.toLocaleString() }}</td>
                          <td class="text-truncate">{{ val?.amount?.toLocaleString() }}</td>
                          <td v-if="updatePermission || deletePermission">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-pen text-primary mr-3" style="cursor: pointer;" @click="onEdit(val?.id)" />
                              <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="onDelete(val)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-3" v-if="list?.data?.length > 0">
                    <b-pagination
                      v-model="params.page"
                      :total-rows="list?.meta?.totalItem"
                      @change="getListBunkerRoute"
                      :per-page="list?.meta?.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No BunkerRoute data found.</p>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="showAddBunkerRoute" id="add-bunker" :title="`${isEdit ? 'EDIT' : 'ADD'} Bunker Route`" width="50%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Create Bunker Route</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <FormBunkerRoute 
                          :show-add-bunker="showAddBunkerRoute" 
                          :is-edit="isEdit" 
                          :edit-id="editId" 
                          :refresh-data="refreshData" 
                          :listPorts="listPorts" 
                        />
                      </b-col>
                    </b-row>
                </fieldset>
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import {
    fleetsActions,
  } from '@src/Utils/helper'
  import { createPopper } from '@popperjs/core'
  import moment from 'moment'
  import FormBunkerRoute from './components/FormBunkerRoute.vue'
  import { busdevActions, portsActions } from '@src/Utils/helper'

  export default {
    name: 'BunkerRoute',
    data() {
      return {
        exporting:false,
        loading:false,
        showBunkerRouteMaster:false,
        showAddBunkerRoute:false,
        listPorts:[],

        isEdit:false,
        editId:null,

        params:{
          page: 1,
          perPage:25,
          BunkerRoute_class_id:null,
          BunkerRoute_status:'',
          search:'',
        },

        list: {
          data:[],
          meta:null
        }
      }
    },
    components:{
      FormBunkerRoute,
    },
    watch: {

    },
    computed: {
      addPermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Customer').add

        return true
      },
      updatePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Customer').update

        return true
      },
      deletePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Customer').delete

        return true
      },
    },
    async mounted() {
      this.getPortList()
      this.getListBunkerRoute()
    },
    created () {
      // this.getListBunkerRoute = _.debounce(this.getListBunkerRoute, 500)
    },
    methods: {
      ...busdevActions,
      ...portsActions,
      refreshData(){
        this.isEdit = false
        this.editId = null

        this.getListBunkerRoute()
      },
      openBunkerRouteMaster(){
        this.showBunkerRouteMaster = true
        this.$root.$emit('bv::toggle::collapse', `BunkerRoute-master`)
      }, 
      onAdd(){
        this.isEdit = false
        this.editId = null

        this.showAddBunkerRoute = true
        this.$root.$emit('bv::toggle::collapse', `add-bunker`)
      }, 
      onEdit(id){
        this.isEdit = true
        this.editId = id

        this.showAddBunkerRoute = true
        this.$root.$emit('bv::toggle::collapse', `add-bunker`)
      },
      refreshData(){
        this.showAddBunkerRoute = false
        this.$root.$emit('bv::toggle::collapse', `add-bunker`)

        this.getListBunkerRoute()
      },
      async getPortList () {
        let params = {
          active: true,
          search: '',
          page: 1,
          perPage: 250
        }
        const res = await this.getPorts(params)
        if (res.status == 'success') {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.forEach(val => {
              this.listPorts.push({ value: val.id, text: val.portName })
            })
          }
        } else {
          this.$swal(
            `Oops!`,
            res.data.data,
            'error'
          )
        }
      },
      async getListBunkerRoute(page) {
        let params = {
          page: page || 1,
          ...this.params,
        }

        if(!params.search) delete params.search

        let res = await this.fetchBunkerRoute(params)

        if (res.data.status === true && res.data.data?.data.length > 0) {
          this.list.data = res.data.data?.data
          this.list.meta = res.data.data?.meta
        } else {
          this.list.data = []
          this.list.meta = null
        }
      },
      async onExportBunkerRoute() {
        this.exporting = true
        try {
          let params = {
            ...this.params,
          }

          if(!params.BunkerRoute_class_id) delete params.BunkerRoute_class_id
          if(!params.BunkerRoute_status) delete params.BunkerRoute_status
          if(!params.search) delete params.search

          let res = await this.exportBunkerRoute(params)

          if(res.status === 'success'){
            saveAs(res.data, 'List BunkerRoutes.xlsx')
          }
        } catch (error) {
          this.$swal(
            'Failed Export!',
            'Something went wrong.',
            'error'
          ) 
        }

        this.exporting = false
      },

      async onDelete(data){
        this.$swal({
            title: `Are you sure want to delete BunkerRoute Code ${data?.route_code} ?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await this.deleteBunkerRoute({id:data.id})
            if(res.status === false || res.status === 400 || res.status === 500){
                this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                )
                return 
            } 
                await this.getListBunkerRoute()
                this.$swal(
                    'Success!',
                    'Success delete BunkerRoute.',
                    'success'
                )
            }
        })
      },
    }
  }
</script>
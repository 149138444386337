<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="mb-4">
        <div class="d-flex flex-column">
          <h5><strong class="text-primary">General Settings</strong></h5>
          <span>Dashboard > <span class="text-primary">General Settings</span></span>
        </div>
      </b-col>
      <b-col md="3">
        <iq-card class="rounded-0 position-static">
          <template v-slot:headerTitle>
            <h5 class="card-title text-primary">
              <strong>Setting List Settings</strong>
            </h5>
          </template>
          <template v-slot:body>
            <div>
              <div
                v-for="(section, index) in sections"
                :key="index"
                class="d-flex p-2"
                style="cursor:pointer"
                :class="[activeSection === index ? 'bg-primary' : '']"
                @click="scrollToSection(index)"
              >
                <h6 :class="[activeSection === index ? 'text-white' : '']">{{ section }}</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="9">
        <div 
          class="d-flex flex-column overflow-auto" 
          style="height:78vh"
          @scroll="handleScroll"
          ref="scrollContainer"
        >
          <iq-card class="rounded-0" :ref="`section0`">
            <template v-slot:headerTitle>
              <div class="position-absolute left-0" :style="{ borderLeft: `5px solid #334697`, left:0 }" />
                
              <div :style="{ borderLeft: `3px solid #334697`, marginLeft: `-20px` }" class="px-4">
                <h5 class="card-title text-primary">
                  <strong>Brand Settings</strong>
                </h5>
                <span>Edit your brand details</span>
              </div>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="4">
                  <iq-card class="rounded-0 shadow">
                    <template v-slot:headerTitle>
                      <div :style="{ borderLeft: `3px solid #334697`, marginLeft: `-20px` }" class="px-4">
                        <strong class="card-title text-primary">Logo Dark</strong>
                      </div>
                    </template>
                    <template v-slot:body>
                      <b-row>
                        <b-col md="12">
                          <div class="d-flex w-100 border mb-3" :style="{ marginTop: `-20px` }"  />
                          <div class="d-flex flex-column align-items-center justify-content-between" style="height: 170px">
                            <img :src="darkLogo ? darkLogo : form.darkLogo || 'https://iconape.com/wp-content/files/sh/336155/png/logo.png'" :style="{ width:'120px', height:'120px' }" alt="logo-light" />
                            <b-button
                              variant="dark"
                              type="button"
                              @click="triggerFileUpload('darkLogo')"
                            >
                              <i class="fa fa-upload"></i> Choose file here
                            </b-button>
                            <input type="file" ref="darkLogo" @change="uploadFile($event, 'darkLogo')" accept="image/png" style="display: none;" />
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="4">
                  <iq-card class="rounded-0 shadow">
                    <template v-slot:headerTitle>
                      <div :style="{ borderLeft: `3px solid #334697`, marginLeft: `-20px` }" class="px-4">
                        <strong class="card-title text-primary">Logo Light</strong>
                      </div>
                    </template>
                    <template v-slot:body>
                      <b-row>
                        <b-col md="12">
                          <div class="d-flex w-100 border mb-3" :style="{ marginTop: `-20px` }"  />
                          <div class="d-flex flex-column align-items-center justify-content-between" style="height: 170px">
                            <img :src="lightLogo ? lightLogo : form.lightLogo || 'https://iconape.com/wp-content/files/sh/336155/png/logo.png'" :style="{ width:'120px', height:'120px' }" alt="logo-light" />
                            <b-button
                              variant="dark"
                              type="button"
                              @click="triggerFileUpload('lightLogo')"
                            >
                              <i class="fa fa-upload"></i> Choose file here
                            </b-button>
                            <input type="file" ref="lightLogo" @change="uploadFile($event, 'lightLogo')" accept="image/png" style="display: none;" />
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="4">
                  <iq-card class="rounded-0 shadow">
                    <template v-slot:headerTitle>
                      <div :style="{ borderLeft: `3px solid #334697`, marginLeft: `-20px` }" class="px-4">
                        <strong class="card-title text-primary">Favicon</strong>
                      </div>
                    </template>
                    <template v-slot:body>
                      <b-row>
                        <b-col md="12">
                          <div class="d-flex w-100 border mb-3" :style="{ marginTop: `-20px` }"  />
                          <div class="d-flex flex-column align-items-center justify-content-between" style="height: 170px">
                            <img :src="favicon ? favicon : form.favicon || 'https://iconape.com/wp-content/files/sh/336155/png/logo.png'" :style="{ width:'120px', height:'120px' }" alt="logo-light" />
                            <b-button
                              variant="dark"
                              type="button"
                              @click="triggerFileUpload('favicon')"
                            >
                              <i class="fa fa-upload"></i> Choose file here
                            </b-button>
                            <input type="file" ref="favicon" @change="uploadFile($event, 'favicon')" accept=".ico" style="display: none;" />
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Title Text" label-for="title">
                    <b-form-input type="text" placeholder="Type Text" v-model="form.titleText"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Footer Text" label-for="title">
                    <b-form-input type="text" placeholder="Type Text" v-model="form.footerText"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <div class="d-flex flex-column text-black">
                    <strong>Theme Customizer</strong>
                    <div class="d-flex align-items-center mb-2">
                      <i class="fa fa-brush mr-2" />
                      <strong>Primary color settings</strong>
                    </div>

                    <color-picker :width=200 :height=200 v-model="form.primaryColor"></color-picker>

                    <div class="d-flex align-items-center">
                      <span class="mt-2 mr-2">Selected Color: <strong class="text-uppercase" :style="{color: form.primaryColor }">{{ form.primaryColor }}</strong></span>
                      <svg height="24" width="24">
                        <circle cx="16" cy="16" r="15" :fill="form.primaryColor" />
                      </svg>
                    </div>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="d-flex flex-column text-black">
                    <div class="d-flex align-items-center mb-2">
                      <i class="fa fa-square mr-2" />
                      <strong>Sidebar settings</strong>
                    </div>
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.useTransparentLayout"
                    >
                      Transparent Layout
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="d-flex flex-column text-black">
                    <div class="d-flex align-items-center mb-2">
                      <i class="fa fa-sun mr-2" />
                      <strong>Layout settings</strong>
                    </div>
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.useDarkLayout"
                    >
                      Dark Layout
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="d-flex w-100 my-2" />
                  <b-row>
                    <b-col md="6">
                      <b-button variant="outline-primary" block type="button" @click.prevent="onResetSettings('brand')">
                        Reset
                      </b-button>
                    </b-col>
                    <b-col md="6">
                      <b-button variant="primary" block type="button" @click.prevent="onSaveSettings('brand')">
                        Save Changes
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
          </iq-card>
  
          <iq-card class="rounded-0" :ref="`section1`">
            <template v-slot:headerTitle>
              <div class="position-absolute left-0" :style="{ borderLeft: `5px solid #334697`, left:0 }" />
                
              <div :style="{ borderLeft: `3px solid #334697`, marginLeft: `-20px` }" class="px-4">
                <h5 class="card-title text-primary">
                  <strong>System Settings</strong>
                </h5>
                <span>Edit your system details</span>
              </div>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Currency" label-for="currency_id">
                    <v-select 
                      label="text" 
                      :options="optionsCurrency" 
                      :reduce="optionsCurrency => optionsCurrency.value" 
                      placeholder="Select..." 
                      style="background-color:#ffffff;"
                      v-model="form.currencyId"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Currency Symbol *" label-for="currency_id">
                    <b-form-input placeholder="Rp" v-model="form.currencySymbol" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Status"
                    label-for="status"
                  >
                    <template v-for="(item, index) in statusOptions">
                      <b-form-radio
                        class="custom-radio-color-checked"
                        inline
                        :color="item.color"
                        name="color"
                        :key="index"
                        :disabled="item.disabled"
                        v-model="form.status"
                      >
                        <span>{{ item.label }}</span>
                      </b-form-radio>
                    </template>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Decimal Number Format" label-for="num_format">
                    <b-form-input placeholder="0" type="number"  v-model="form.decimalNumberFormat" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Date Format" label-for="date_format">
                    <b-form-input placeholder="0" type="date"  v-model="form.dateFormat"  />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Time Format" label-for="time_format">
                    <b-form-select plain 
                      class="form-control" 
                      name="time_format"
                      v-model="form.timeFormat" 
                    >
                      <option value="">Select format...</option>
                      <option value="HH:mm">24 Hour Format</option>
                      <option value="">12 Hour Format</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <div class="d-flex w-100 my-2" />
                  <b-row>
                    <b-col md="6">
                      <b-button variant="outline-primary" block type="button" @click.prevent="onResetSettings('system')">
                        Reset
                      </b-button>
                    </b-col>
                    <b-col md="6">
                      <b-button variant="primary" block type="button" @click.prevent="onSaveSettings('system')">
                        Save Changes
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
          </iq-card>
  
          <iq-card class="rounded-0" :ref="`section2`">
            <template v-slot:headerTitle>
              <div class="position-absolute left-0" :style="{ borderLeft: `5px solid #334697`, left:0 }" />
                
              <div :style="{ borderLeft: `3px solid #334697`, marginLeft: `-20px` }" class="px-4">
                <h5 class="card-title text-primary">
                  <strong>Company Settings</strong>
                </h5>
                <span>Edit your company details</span>
              </div>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Company Name *" label-for="company_name">
                    <b-form-input placeholder="Type text..." type="text"  v-model="form.companyName"  />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Address" label-for="address">
                    <b-form-input placeholder="Type text..." type="text"  v-model="form.companyAddress"   />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Zip/Post Code" label-for="post_code">
                    <b-form-input placeholder="Type text..." type="text" v-model="form.postalCode"  />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Country" label-for="country">
                    <v-select 
                      label="text" 
                      :options="optionsCountry" 
                      :reduce="optionsCountry => optionsCountry.value" 
                      placeholder="Select..." 
                      style="background-color:#ffffff;"
                      v-model="form.country"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Telephone" label-for="telephone">
                    <b-form-input placeholder="62+" type="number" v-model="form.telephone" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="System Email *" label-for="email">
                    <b-form-input placeholder="example@example.com" v-model="form.systemEmail" type="email" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Email (from name) *" label-for="email">
                    <b-form-input placeholder="example@example.com" type="text" v-model="form.email" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Company Registration Number *" label-for="email" >
                    <b-form-input placeholder="Type Number" type="text" v-model="form.companyRegistrationNumber" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Company Start Time *" label-for="email">
                    <b-form-timepicker locale="id" v-model="form.companyStartTime" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Company End Time *" label-for="email">
                    <b-form-timepicker locale="id" v-model="form.companyEndTime"  />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="IP Restrict" label-for="email">
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.ipRestrict" 
                    >
                      Off / On
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Timezone" label-for="country">
                    <v-select
                      label="text" 
                      :options="optionsTimezone" 
                      :reduce="optionsTimezone => optionsTimezone.value" 
                      placeholder="Select..." 
                      style="background-color:#ffffff;"
                      v-model="form.timezone"
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-checkbox
                    name="check-button"
                    switch
                    inline
                    v-model="form.useTaxNumber"
                  >
                    Tax Number
                  </b-form-checkbox>
                </b-col>
                <b-col md="6">
                  <div class="d-flex flex-column">
                    <div class="d-flex">
                      <template v-for="(item, index) in optionsTax">
                        <b-form-radio
                          class="custom-radio-color-checked"
                          inline
                          :color="item.color"
                          name="color"
                          :key="index"
                          :disabled="item.disabled"
                          v-model="form.taxType"
                        >
                          <span>{{ item.label }}</span>
                        </b-form-radio>
                      </template>
                    </div>
                    <b-form-input class="mt-2" placeholder="Enter VAT / GST Number..." v-model="form.taxNumber" type="number" />
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="d-flex w-100 my-2" />
                  <b-row>
                    <b-col md="6">
                      <b-button variant="outline-primary" block type="button" @click.prevent="onResetSettings('company')">
                        Reset
                      </b-button>
                    </b-col>
                    <b-col md="6">
                      <b-button variant="primary" block type="button" @click.prevent="onSaveSettings('company')">
                        Save Changes
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
          </iq-card>
  
          <iq-card class="rounded-0" :ref="`section3`">
            <template v-slot:headerTitle>
              <div class="position-absolute left-0" :style="{ borderLeft: `5px solid #334697`, left:0 }" />
                
              <div :style="{ borderLeft: `3px solid #334697`, marginLeft: `-20px` }" class="px-4">
                <h5 class="card-title text-primary">
                  <strong>Email Notification Settings</strong>
                </h5>
                <span>Edit email notification settings</span>
              </div>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12" v-for="(state, index) in emailSettings" :key="index">
                  <h5 class="mb-2"><strong>{{ state?.name }}</strong></h5>
                  <b-row>
                    <b-col md="6" class="mb-3" v-for="(obj, objIndex) in state?.emailNotificationSettings" :key="objIndex">
                      <div class="d-flex w-100 align-items-center justify-content-between p-2 border border-dark rounded text-black">
                        <div class="d-flex align-items-center">
                          <span class="mr-2">{{ obj.name }}</span>
                          <b-badge variant="primary" v-b-toggle.email-setting @click="formEmailSetting = obj" style="cursor: pointer;">
                            <i class="fa fa-pen" />
                          </b-badge>
                        </div>
                        <b-form-checkbox
                          name="check-button"
                          switch
                          inline
                          style="margin-right: -8px"
                          v-model="obj.useActive"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </div>
      </b-col>

      <b-col md="12">
        <!-- FORM SIGN ON -->
        <b-sidebar
          id="email-setting"
          title="Email Notification Setting"
          width="80%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow
        >
          <template #default="{ hide }">
            <form action="#" method="post" @submit.prevent="onSaveEmailSetting">
              <b-row class="m-3">
                <!-- FORM -->
                <b-col md="12" class="mb-4">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Title Text" label-for="title">
                        <b-form-input type="text" placeholder="Type Text" v-model="formEmailSetting.title"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Sub title" label-for="title">
                        <b-form-input type="text" placeholder="Type Text" v-model="formEmailSetting.subtitle"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Model" label-for="currency_id">
                        <v-select 
                          label="model" 
                          :options="optionsModelColumns" 
                          :reduce="optionsModelColumns => optionsModelColumns.model" 
                          placeholder="Select..." 
                          style="background-color:#ffffff; z-index: 99999"
                          v-model="formEmailSetting.model"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Show Shortcode" label-for="currency_id" >
                        <div class="form-control text-center bg-primary" @click="fetchColumnBySelectedModel" style="cursor:pointer">
                          Shortcode
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" v-if="listColumns.length > 0" class="my-4">
                      <div class="w-100 d-flex flex-wrap">
                        <div :class="`px-3 p-2 table-primary mr-2 mb-2 rounded-lg`" style="cursor:pointer" v-for="(state, index) in listColumns" :key="index" @click="onSelectedColumns(index)">
                          <span>{{ state }}</span>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Content" label-for="content">
                        <editor api-key="a1koqlofb8tq75y26avurdkgz0u4mx1mdih2u3u8ey2krd5c" :init="editorConfig" @onEditorChange="handleEditorChange" v-model="formEmailSetting.content" />
                        <!-- <wysiwyg :style="{ height: '500px' }" v-model="formEmailSetting.content" /> -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- PREVIEW HEADER -->
                <b-col md="12" class="mb-4">
                  <strong>Preview</strong>
                  <div class="notification-box">
                    <img 
                      :src="require('@src/assets/images/logo/logo-telis.png')"
                      alt="logo-telis"
                      style="width:300px"
                      class="img-fluid"
                    />
                    <h4 class="text-white">{{formEmailSetting.title}}</h4>
                    <p class="text-white">{{formEmailSetting.subtitle}}</p>
                  </div>
                </b-col>

                <!-- PREVIEW CONTENT -->
                <b-col md="12">
                  <div v-html="formEmailSetting.content" class="content-box"></div>

                  <!-- <div class="content-box">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <b-avatar variant="info" src="https://placekitten.com/300/300" class="mr-2"></b-avatar>
                        <div class="d-flex flex-column">
                          <strong>Adyanto Pramono</strong>
                          <span>ETI451023124911</span>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-end align-items-end">
                        <strong class="text-primary">Request Item</strong>
                        <span>{{ moment().format('DD MMMM YYYY') }} at {{ moment().format('LT a') }}</span>
                      </div>
                    </div>
                    <div class="align-items-start w-100 d-flex">
                      <span class="text-left"><strong>Adyanto Pramono</strong>  send a request for purchase / material request with the following details: </span>
                    </div>
                    
                    <div class="d-flex w-100 border my-2" />

                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th>Item</th>
                          <td>Anchor Rope</td>
                        </tr>
                        <tr>
                          <th>Qty Request</th>
                          <td>1</td>
                        </tr>
                        <tr>
                          <th>Request Current Month</th>
                          <td>(October 2024) - 4</td>
                        </tr>
                        <tr>
                          <th>Max Request</th>
                          <td>4</td>
                        </tr>
                        <tr>
                          <th>Reason</th>
                          <td>Terjadi Putus Tali Pada saat badai</td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="d-flex align-items-center justify-content-center">
                      <b-button variant='warning' type="button">
                        Approval Process
                      </b-button>
                    </div>
                  </div> -->
                </b-col>

                <!-- PREVIEW FOOTER -->
                <b-col md="12">
                  <div class="footer-box">
                    <img 
                      :src="require('@src/assets/images/logo/tcp-logo.png')"
                      alt="logo-telis"
                      style="width:300px"
                      class="img-fluid"
                    />
                    <div class="mb-2">
                      <span>Bakrie Tower lantai 9, Komplek Rasuna Epicentrum Jl. HR Rasuna Said, Karet Kuningan, Setiabudi, Jakarta, 12940.</span>
                    </div>
                    <div class="d-flex w-100 flex-column">
                      <span>Telp: +62-21 2994 1389</span>
                      <span>Fax : +62-21 2994 2886</span>
                      <span>Website: www.transcoalpacific.com</span>
                      <span>Email : info@transcoalpacific.com</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2 p-4">
                <b-col md="6">
                  <b-button variant="outline-primary" block type="button">
                    Cancel
                  </b-button>
                </b-col>
                <b-col md="6">
                  <b-button variant="primary" block type="button" @click.prevent="onSaveEmailSetting">
                    Save Changes
                  </b-button>
                </b-col>
              </b-row>
            </form>
          </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { busdevActions, filesActions, opsV2Actions } from '@src/Utils/helper'
import ColorPicker from 'vue-color-picker-wheel';
import moment from 'moment';
import timezones from '@src/core/json/timezone.json';
import Editor from '@tinymce/tinymce-vue';

export default {
  name:'GeneralSettings',
  components:{ ColorPicker, 'editor': Editor },
  data() {
    return {
      editorConfig: {
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help',
          readonly: false // Ensure this is set to false for editing
      },
      optionsTax: [{
          label: 'VAT Number',
          color: 'primary',
          value: 'VAT',
        },
        {
          label: 'GST Number',
          color: 'success',
          value: 'GST',
        }
      ],
      statusOptions: [{
          label: 'Pre',
          color: 'primary',
          value: 'PRE',
        },
        {
          label: 'Post',
          color: 'success',
          value: 'POST',
        }
      ],
      sections: ['General Settings', 'System Settings', 'Company Settings', 'Email Notification Settings'],
      activeSection: 0,

      loading_upload_file:false,
      optionsCurrency:[],
      optionsCountry:[],
      optionsTimezone:[],
      optionsModelColumns:[],

      darkLogo:"",
      lightLogo:"",
      favicon:"",

      form:{
        id:null,
        "darkLogo": "",
        "lightLogo": "",
        "favicon": "",
        "titleText": "",
        "footerText": "",
        "primaryColor": "#000",
        "useTransparentLayout": false,
        "useDarkLayout": false,

        "currencyId": null,
        "status": "PRE", // PRE POST
        "dateFormat": "",
        "currencySymbol": "",
        "decimalNumberFormat": "",
        "timeFormat": "", // HH:mm:ss

        "companyName": "",
        "companyAddress": "",
        "city": "",
        "state": "",
        "postalCode": "",
        "country": "",
        "telephone": "",
        "systemEmail": "",
        "email": "",
        "companyRegistrationNumber": "",
        "companyStartTime": "", // HH:mm
        "companyEndTime": "",  // HH:mm
        "ipRestrict": "ON", // ON OFF
        "timezone": "",
        "useTaxNumber": false,
        "taxType": "VAT", // VAT GST
        "taxNumber": "",
      },
      emailSettings:[],
      listColumns:[],

      selectedColumns:[],

      formEmailSetting:{
        "id": null,
        "name": "",
        "useActive": false,
        "title": "",
        "subtitle": null,
        "content": null,
        "model": null
      }
    }
  },
  mounted() {
    this.fetchEmailNotificationSettings()
    this.fecthGeneralSettings()
    this.getCurrency()
    this.getCountry()
    this.getTimezone()
    this.fetchModelColumns()
  },
  methods: {
    ...filesActions,
    ...busdevActions,
    ...opsV2Actions,
    handleEditorChange(content) {
      this.formEmailSetting.content = content;
    },
    onSelectedColumns(index){
      this.formEmailSetting.content += `{{${this.listColumns[index]}}}`

      console.log(this.formEmailSetting.content, "formEmailSetting.content")
    },
    triggerFileUpload(key) {
      this.$refs[key].click();
    },
    async uploadFile (event, key) {
      this.loading_upload_file = true

      const files = [...event.target.files]
      let file = files[0]
      this.createImagePreview(file, key)

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        this.loading_upload_file = false
        this.form[key] = res.data.fileName
      } else {
        this.loading_upload_file = false
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    createImagePreview(file, key) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this[key] = e.target.result
      };
      reader.readAsDataURL(file);
    },
    closeSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', `${id}`)
    },
    handleScroll() {
      this.updateActiveSection()
    },
    updateActiveSection() {
      const sections = this.$refs;
      let foundSection = null;
      const viewportHeight = window.innerHeight;

      for (let i = 0; i < Object.keys(sections).length; i++) {
        const section = sections[`section${i}`];
        if (section) {
          const sectionEl = section.$el; // Access the underlying DOM element
          const rect = sectionEl.getBoundingClientRect();
          
          // Adjust threshold based on your content height
          const thresholdTop = viewportHeight * 0.28; // 20% from the top of the viewport
          const thresholdBottom = viewportHeight * 0.80; // 80% from the top of the viewport

          // Check if the section is within the viewport thresholds
          if (rect.top < thresholdBottom && rect.bottom > thresholdTop) {
            foundSection = i; // Update foundSection if it meets the criteria
            break; // Exit the loop as we found the active section
          }
        }
      }

      if (foundSection) {
        this.activeSection = foundSection;
        console.log(`Active Section: ${this.activeSection}`); // You can use this value for further processing
      }
    },
    scrollToSection(index) {
      const sectionRef = this.$refs[`section${index}`]; // Get the array of refs
      if (sectionRef) {
        const sectionEl = sectionRef.$el; // Access the underlying DOM element
        sectionEl.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to section
      }
      this.activeSection = index
    },
    async fecthGeneralSettings() {
      let res = await this.getGeneralSettings()
      if(res.status === 'success') {
        this.form = res.data.data[0]
      }
      console.log(res, "res")
    },   
    async fetchEmailNotificationSettings() {
      let res = await this.getEmailNotificationSettings()
      if(res.status === 'success') {
        this.emailSettings = res.data.data
      }
    }, 

    async getCountry() {
      let res = await this.fetchCountry()
      if (res.data.status === true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsCountry.push({
              value: state.name,
              text: `${state.name}`,
            })
          })
        }
      }
    }, 
    async getTimezone() {
      timezones.map((state) => {
        this.optionsTimezone.push({
          value: state.text,
          text: `${state.text}`,
        })
      })
    },
    async getCurrency() {
      let res = await this.fetchCurrency()
      if (res.data.status === true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsCurrency.push({
              value: state.id,
              text: state.alias,
            })
          })
        }
      }
    },     
    async fetchModelColumns() {
      let res = await this.getDynamicColumns()
      this.optionsModelColumns = res?.data?.data || []
    },  

    async onResetSettings(key){
      let res 

      if(key === 'brand'){
        let payload = {
          id: this.form.id ? this.form.id : null,
          "darkLogo": '',
          "lightLogo": "",
          "favicon": '',
          "titleText": '',
          "footerText": '',
          "primaryColor": '',
          "useTransparentLayout": false,
          "useDarkLayout": false,
        }
        console.log(payload, "payload")
        res = await this.upsertGeneralSettings({data: payload})
      } else if(key === 'system'){
        let payload = {
          id: this.form.id ? this.form.id : null,
            "currencyId": null,
          "status": "PRE", // PRE POST
          "dateFormat": "",
          "currencySymbol": "",
          "decimalNumberFormat": "",
          "timeFormat": "", // HH:mm:ss
        }
        console.log(payload, "payload")
        res = await this.upsertGeneralSettings({data: payload})
      } else if(key === 'company'){
        let payload = {
          id: this.form.id ? this.form.id : null,
          "companyName": "",
          "companyAddress": "",
          "city": "",
          "state": "",
          "postalCode": "",
          "country": "",
          "telephone": "",
          "systemEmail": "",
          "email": "",
          "companyRegistrationNumber": "",
          "companyStartTime": "", // HH:mm
          "companyEndTime": "",  // HH:mm
          "ipRestrict": "ON", // ON OFF
          "timezone": "",
          "useTaxNumber": false,
          "taxType": "VAT", // VAT GST
          "taxNumber": "",
        }
        console.log(payload, "payload")
        res = await this.upsertGeneralSettings({data: payload})
      }

      if(res.status === 'success'){
        this.fecthGeneralSettings()
        this.$swal('Saved!', 'General Settings saved.', 'success')
      } else {
        this.$swal('Oops!', 'Something went wrong.', 'error')
      }
    },
    
    async onSaveSettings(key){
      let res 

      if(key === 'brand'){
        let payload = {
          id: this.form.id ? this.form.id : null,
          "darkLogo": this.form.darkLogo,
          "lightLogo": this.form.lightLogo,
          "favicon": this.form.favicon,
          "titleText": this.form.titleText,
          "footerText": this.form.footerText,
          "primaryColor": this.form.primaryColor,
          "useTransparentLayout": this.form.useTransparentLayout,
          "useDarkLayout": this.form.useDarkLayout,
        }

        if(!payload.darkLogo) delete payload.darkLogo
        if(!payload.lightLogo) delete payload.lightLogo
        if(!payload.favicon) delete payload.favicon
        
        res = await this.upsertGeneralSettings({data: payload})
      } else if(key === 'system'){
        let payload = {
          id: this.form.id ? this.form.id : null,
          "currencyId": this.form.currencyId,
          "status": this.form.status, // PRE, POST
          "dateFormat": this.form.dateFormat,
          "currencySymbol": this.form.currencySymbol,
          "decimalNumberFormat": this.form.decimalNumberFormat,
          "timeFormat": this.form.timeFormat,
        }
        res = await this.upsertGeneralSettings({data: payload})
      } else if(key === 'company'){
        let payload = {
          id: this.form.id ? this.form.id : null,
          "companyName": this.form.companyName,
          "companyAddress": this.form.companyAddress,
          "city": this.form.city,
          "state": this.form.state,
          "postalCode": this.form.postalCode,
          "country": this.form.country,
          "telephone": this.form.telephone,
          "systemEmail": this.form.systemEmail,
          "email": this.form.email,
          "companyRegistrationNumber": this.form.companyRegistrationNumber,
          "companyStartTime": this.form.companyStartTime ? moment(this.form.companyStartTime).format('HH:mm') : '',
          "companyEndTime": this.form.companyEndTime ? moment(this.form.companyEndTime).format('HH:mm') : '',
          "ipRestrict": this.form.ipRestrict ? 'ON' : 'OFF', // ON, OFF
          "timezone": this.form.timezone,
          "useTaxNumber": this.form.useTaxNumber,
          "taxType":this.form.taxType, // VAT, GST
          "taxNumber": this.form.taxNumber
        }
        res = await this.upsertGeneralSettings({data: payload})
      }

      if(res.status === 'success'){
        this.fecthGeneralSettings()
        this.$swal('Saved!', 'General Settings saved.', 'success')
      } else {
        this.$swal('Oops!', 'Something went wrong.', 'error')
      }
    },

    async onSaveEmailSetting(){
      let payload = {...this.formEmailSetting}
      delete payload.id
      let res = await this.setEmailSetting({data: payload, id:this.formEmailSetting.id })

      if(res.status === 'success'){
        this.fecthGeneralSettings()
        this.fetchEmailNotificationSettings()
        this.$swal('Saved!', 'Email Setting saved.', 'success')
      } else {
        this.$swal('Oops!', 'Something went wrong.', 'error')
      }
    },
    async fetchColumnBySelectedModel(){
      let params = {
        model: this.formEmailSetting.model
      }
      if(!this.formEmailSetting.model) return   this.$swal('Required!', 'Select model first.', 'warning')
      let res = await this.getColumnBySelectedModel(params)
      this.listColumns = res?.data || []
    }
  },
}
</script>

<style scoped>
.notification-box {
  position: relative;
  background-color: #3a4ba0; /* Adjust color to match */
  color: #ffffff;
  width: 100%; /* Adjust width as needed */
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  font-family: Arial, sans-serif;
}

.notification-box .logo {
  max-width: 50px; /* Adjust size as needed */
  margin-bottom: 10px;
}

.notification-box::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 60px 60px 0 60px;
  border-style: solid;
  border-color: #3a4ba0 transparent transparent transparent;
}

.content-box {
  background-color: transparent; /* Adjust color to match */
  width: 100%; /* Adjust width as needed */
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.footer-box {
  position: relative;
  background-color: #F0F0F0; /* Adjust color to match */
  color: #95969B;
  width: 100%; /* Adjust width as needed */
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  font-family: Arial, sans-serif;
}

</style>


<template>
  <div>
    <!-- Top Section !-->
    <div v-if="showFilter">
      <b-modal v-model="modal.filter" centered size="lg" ok-title="Filter" @ok="handleFetchIncidents(1,true)" title="Filter Data">
          <b-row>
            <b-col md="12">
              <b-form-group label-for="dateIssued" label="Date Range"  label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <date-picker @change="handleChangeDate" v-model="dateRange" style="width: 100%" type="date" value-type="YYYY-MM-DD"
                              range placeholder="Select date range"></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="dateIssued" label="Company" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <v-select v-model="incidents.params.company_id" label="text" :options="companies"
                          :reduce="companyOptions => companyOptions.value"></v-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="dateIssued" label="Fleet/Vessel" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <p v-if="vehicleId" class="form-control-static">
                  {{fleets.find(fleet => fleet.code === vehicleId) ? fleets.find(fleet => fleet.code === vehicleId).name : '-'}}
                </p>
                <v-select v-else v-model="incidents.params.vehicle_id" label="name" :options="fleets"
                          :reduce="fleetList => fleetList.code"></v-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="dateIssued" label="Type" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <v-select v-model="incidents.params.type_form" label="text" :options="typeOfForm"
                          :reduce="typeOfForm => typeOfForm.value"></v-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="dateIssued" label="Type of Coverage" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <v-select v-model="incidents.params.id_type_coverage" label="name" :options="typeCoverages"
                          :reduce="typeOfCoverage => typeOfCoverage.code"></v-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="dateIssued" label="Type of Loss" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <v-select v-model="incidents.params.id_type_loss" label="name" :options="typeLoss"
                          :reduce="typeOfLoss => typeOfLoss.code"></v-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="dateIssued" label="Status" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                <v-select v-model="incidents.params.status_id" label="text" :options="statusOptions"
                          :reduce="statusOptions => statusOptions.value"></v-select>
              </b-form-group>
            </b-col>
          </b-row>
      </b-modal>
    </div>
    <!-- End Top Section !-->
    <!-- Content Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template #headerTitle>
            <h4 class="card-title text-primary"><strong>List of Accident AND Incident</strong></h4>
          </template>
          <template v-slot:body>
            <div class="d-flex justify-content-between mb-3">
              <div class="d-flex">
                <!-- <b-form-input size="md" placeholder="search..."></b-form-input>
                <b-button variant="primary" class="mx-2">
                  <i class="fa fa-search"></i>
                </b-button> -->
                <b-button
                  variant="outline-primary"
                  class="d-flex align-items-center"
                  @click="toggleModalFilter"
                >
                  <i class="fas fa-sliders"></i>
                  <p class="text-truncate m-0"> Filter Data</p>
                </b-button>
              </div>
              <div>
                <b-button variant="outline-primary" class="mr-2" @click="exportIncidentsFile" v-if="canExport">
                  <template v-if="!loading_export">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
                <router-link to="/mi/portal" v-if="canAdd">
                  <button class="btn btn-primary"><i class="fa fa-plus"></i> Add Form Submission</button>
                </router-link>
              </div>
            </div>
            <div class="px-3 mb-3">
              <b-row class="border py-2">
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Date Range">
                    <h6 style="font-weight: 500;">
                      {{moment(dateRange[0]).format('DD MMM YYYY')}} - {{ moment(dateRange[1]).format('DD MMM YYYY') }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="2" v-if="incidents.params.company_id">
                  <b-form-group label-for="dateIssued" label="Company">
                    <h6 style="font-weight: 500;">
                      {{ companies.find((el)=> el.value == incidents.params.company_id).text }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="1" v-if="incidents.params.vehicle_id">
                  <b-form-group label-for="dateIssued" label="Fleet/Vessel">
                    <h6 style="font-weight: 500;">
                      {{ fleets.find((el)=> el.code == incidents.params.vehicle_id).name }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="1" v-if="incidents.params.type_form">
                  <b-form-group label-for="dateIssued" label="Type">
                    <h6 style="font-weight: 500;">
                      {{ typeOfForm.find((el)=> el.value == incidents.params.type_form).text }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="2" v-if="incidents.params.id_type_coverage">
                  <b-form-group label-for="dateIssued" label="Type of Coverage" >
                    <h6 style="font-weight: 500;">
                      {{ typeCoverages.find((el)=> el.code == incidents.params.id_type_coverage).name }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="2" v-if="incidents.params.id_type_loss">
                  <b-form-group label-for="dateIssued" label="Type of Loss" >
                    <h6 style="font-weight: 500;">
                      {{ typeLoss.find((el)=> el.code == incidents.params.id_type_loss).name }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Status" v-if="incidents.params.status_id">
                    <h6 style="font-weight: 500;">
                      {{ statusOptions.find((el)=> el.value == incidents.params.status_id).text }}
                    </h6>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col cols="12">
                <div class="d-flex w-100 overflow-auto ">
                  <table class="table mb-0 table-borderless table-box-shadow">
                    <thead>
                    <tr>
                      <th  class="column-1">No.</th>
                      <th class="text-truncate column-2" >Name of Vessel</th>
                      <th class="text-truncate column-3">Type</th>
                      <th class="text-truncate">Type of Coverage</th>
                      <th class="text-truncate">Type of Loss</th>
                      <th class="text-truncate">Cost</th>
                      <th class="text-truncate">Remarks</th>
                      <th class="text-truncate">Status</th>
                      <th class="text-truncate">Date of Loss / Created</th>
                      <th >Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="incidents?.data?.length === 0 || incidents.isLoading">
                      <td colspan="10" class="text-center">{{ incidents.isLoading ? 'Loading...' : "No Data" }}</td>
                    </tr>
                    <tr v-for="(data, index) in incidents?.data" :key="index">
                      <td class="text-truncate column-1 bg-white">{{ incidents.meta.from + index }}.</td>
                      <td class="text-truncate column-2 bg-white"><a href="#" @click.prevent="handleOpenDetailIncident(index)">{{ data.vehicle.name }}</a></td>
                      <td class="text-truncate column-3 bg-white">
                        <b-button v-if="data.type_form === 'claim'" variant="info" ref="button">Claim</b-button>
                        <b-button v-if="data.type_form === 'non_claim'" variant="warning" ref="button">Non Claim
                        </b-button>
                      </td>
                      <td class="text-truncate">{{ data.type_coverage.name }}</td>
                      <td class="text-truncate">{{ data.type_loss.name }}</td>
                      <td class="text-truncate">Rp. {{ numberFormat(data.cost) }},-</td>
                      <td style="min-width:350px; max-width: 400px;">{{ data.remarks || '-' }}</td>
                      <td class="text-truncate">
                        <b-button :id="canApprove ? `popover-status-${index}` : ''" variant="primary" ref="button">{{ data.status ?
                          data.status.name : '-'
                          }}</b-button>
                      </td>
                      <td class="text-truncate">
                        {{ data.date_of_loss }}
                        <br>
                        <strong>PIC: {{ data.creator ? data.creator.username : '-' }}</strong>
                      </td>
                      <td>
                        <b-dropdown variant="primary">
                          <template slot="button-content">
                            Action
                            <i class="uil uil-angle-down"></i>
                          </template>
                          <template>
                            <b-dropdown-item @click="handleNextProcess(index, data)"><i class="fa fa-arrow-right"></i> Next Step
                              Process</b-dropdown-item>
                            <!-- <b-dropdown-item v-if="data.status.id === 3"
                                            @click="$router.push(`/mi/claim-analysis?formId=${data.id}`)"><i
                              class="fa fa-adjust"></i> Claim Analysis</b-dropdown-item> -->
                          </template>
                          <b-dropdown-item @click="handleOpenDetailIncident(index)"><i class="fa fa-list"></i> Detail</b-dropdown-item>
                          <b-dropdown-item @click="handleOpenModalEmail('Accident', data.id)" variant="primary"><i class="fa fa-send"></i> Send Email Accident</b-dropdown-item>
                          <!-- <b-dropdown-item @click="handleOpenModalEmail()" variant="primary"><i class="fa fa-send"></i> Send Email Recommendation</b-dropdown-item> -->
                          <!-- <b-dropdown-item @click="handleOpenModalEmail('Evaluation')" variant="primary"><i class="fa fa-send"></i> Send Email Evaluation</b-dropdown-item> -->
                          <template v-if="role === 'MANAGEMENT' || canApprove">
                            <b-dropdown-item
                              @click="$router.push(`/mi/management-approval?formId=${data.id}`)"><i
                              class="fa fa-check"></i> Management Approval</b-dropdown-item>
                            <b-dropdown-item
                              @click="$router.push(`/mi/management-summary?formId=${data.id}`)"><i
                              class="fa fa-check"></i> Management Summary</b-dropdown-item>
                          </template>
                          <b-dropdown-item @click="handleDeleteMi(data.id)" v-if="canDelete"><i class="fa fa-trash"></i> Delete</b-dropdown-item>
                          <!-- <b-dropdown-item v-b-toggle.sidebar-followup><i class="fa fa-check"></i> Marine Insurance Approval</b-dropdown-item>-->
                        </b-dropdown>
                      </td>

                      <b-popover v-if="showStatusAction" :target="`popover-status-${index}`" placement="bottom" triggers="hover focus">
                        <template #title>Status Initial Claim!</template>
                        <template v-for="(status, idx) in statuses">
                          <div
                            v-if="data.status.id !== status.code && handleShowListStatuses(data.status.id).includes(status.code)"
                            @click="handleChangeStatus(data.id, status, index)" :key="idx">
                            <b-button v-if="status.code === 11" class="mb-2" block variant="warning" ref="button">
                              Completed</b-button>
                            <b-button v-else-if="status.code === 10" class="mb-2" block variant="danger"
                                      ref="button">Waiting R&D</b-button>
                            <b-button v-else-if="status.code === 9" class="mb-2" block variant="success"
                                      ref="button">Waiting Final Adjustment</b-button>
                            <b-button v-else class="mb-2" block variant="info" ref="button">{{ status.name }}
                            </b-button>
                          </div>
                        </template>
                      </b-popover>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-3 mb-3">
                  <b-pagination
                    v-model="incidents.params.page"
                    :total-rows="incidents.meta.total"
                    :per-page="incidents.meta.perPage"
                    align="center"
                    @input="handleFetchIncidents"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>
          </template>
          <b-sidebar id="sidebar-view" title="DETAIL INFORMATION OF ACCIDENT/INCIDENT" width="1200px" backdrop-variant="dark" right backdrop shadow>
            <b-row v-if="detailIncident" class="pt-2 pl-3 pr-3 pb-2">
              <b-col md="12" class="mb-3">
                <hr>
                <h5 class="text-center">INFORMATION OF ACCIDENT/INCIDENT</h5>
              </b-col>
              <b-col md="11">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <td width="25%">Type</td>
                    <td width="5%">:</td>
                    <td>
                      <b-button id="popover-type" v-if="detailIncident.type_form === 'claim'" variant="info"
                                ref="button">Claim</b-button>
                      <b-button id="popover-type" v-if="detailIncident.type_form === 'non_claim'"
                                variant="warning" ref="button">Non Claim</b-button>
                    </td>
                    <td width="25%" class="pl-5">Status</td>
                    <td width="5%">:</td>
                    <td>
                      <b-button id="popover-view-status" variant="primary" ref="button">{{ detailIncident.status ?
                        detailIncident.status.name : ''
                        }}</b-button>
                      <b-popover target="popover-view-status" placement="bottom" triggers="hover focus">
                        <template #title>Status Initial Claim!</template>
                        <template v-for="(status, idx) in statuses">
                          <div
                            v-if="detailIncident.status.id !== status.code && handleShowListStatuses(detailIncident.status.id).includes(status.code)"
                            @click="handleChangeStatus(detailIncident.id, status)" :key="idx">
                            <b-button v-if="status.code === 11" class="mb-2" block variant="warning" ref="button">
                              Completed</b-button>
                            <b-button v-else-if="status.code === 10" class="mb-2" block variant="danger"
                                      ref="button">Waiting R&D</b-button>
                            <b-button v-else-if="status.code === 9" class="mb-2" block variant="success"
                                      ref="button">Waiting Final Adjustment</b-button>
                            <b-button v-else class="mb-2" block variant="info" ref="button">{{ status.name }}
                            </b-button>
                          </div>
                        </template>
                      </b-popover>
                    </td>
                  </tr>
                  <tr>
                    <td width="25%">Company</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.company.company }}</strong></td>
                    <td width="25%" class="pl-5">Name of Vessel</td>
                    <td width="5%">:</td>
                    <td>
                      <b-badge variant="warning">{{ detailIncident.vehicle.name }}</b-badge>
                    </td>
                  </tr>
                  <tr>
                    <td width="25%">Type of Coverage</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.type_coverage.name || '-' }}</strong></td>
                    <td width="25%" class="pl-5">Place of Loss</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.place_of_loss || '-' }}</strong></td>
                  </tr>
                  <tr>
                    <td width="25%">Cause of Loss</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.cause_of_loss ? detailIncident.cause_of_loss.name :
                      '-'
                      }}</strong></td>
                    <td width="25%" class="pl-5">Type of Loss</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.type_loss.name || '-' }}</strong></td>
                  </tr>
                  <tr>
                    <td width="25%">Date of Loss</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.date_of_loss }}</strong></td>
                    <td width="25%" class="pl-5">Created Form</td>
                    <td width="5%">:</td>
                    <td>{{ detailIncident.created_at }}</td>
                  </tr>
                  <tr>
                    <td width="25%">Remarks</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.remarks || '-' }}</strong></td>
                    <td width="25%" class="pl-5">Cost</td>
                    <td width="5%">:</td>
                    <td><strong>Rp. {{ detailIncident.cost }},-</strong></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="1" class="text-right pt-2">
                <b-dropdown size="sm" text="Small" right>
                  <template slot="button-content">
                    <i class="fa fa-ellipsis-h"></i>
                  </template>
                  <b-dropdown-item>
                    <router-link :to="`/mi/portal?formId=${detailIncident.id}`"><i class="fa fa-edit"></i> Edit</router-link>
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleDeleteMi(detailIncident.id)"><i class="fa fa-trash"></i> Delete
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-popover target="popover-view-type" placement="bottom" triggers="hover focus">
                <template #title>Type Form!</template>
                <b-button class="mb-2" block variant="info" ref="button">Non Claim</b-button>
              </b-popover>
              <b-col md="12" class="mb-3">
                <hr>
                <h5 class="text-center">CLAIMANT / NOTIFICATION DETAILS</h5>
              </b-col>
              <b-col md="12">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <td width="25%">Company</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.company_claimant ? detailIncident.company_claimant.company :
                      '-'
                      }}</strong></td>
                    <td width="25%" class="pl-5">Department/Vessel</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.department.name }}</strong></td>
                  </tr>
                  <tr>
                    <td width="25%">Status</td>
                    <td width="5%">:</td>
                    <td><strong>{{ detailIncident.status ? detailIncident.status.name : '-' }}</strong></td>
                    <td width="25%" class="pl-5"></td>
                    <td width="5%"></td>
                    <td><strong></strong></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12" class="mb-3">
                <hr>
                <h5 class="text-center">Details of Chronology</h5>
              </b-col>
              <b-col md="12">
                <table class="table table-striped table-warning">
                  <tbody>
                  <tr>
                    <td v-html="detailIncident.details_of_chronology || '-'"></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12" class="mb-3">
                <hr>
                <h5 class="text-center">Estimate Damage or Loss</h5>
              </b-col>
              <b-col md="12">
                <table class="table table-striped table-primary">
                  <tbody>
                  <tr>
                    <td><span v-html="detailIncident.estimate_damage || '-'"></span></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12" class="mb-3">
                <hr>
                <h5 class="text-center">Conclusion</h5>
              </b-col>
              <b-col md="12" class="mb-3">
                <table class="table table-striped table-info">
                  <tbody>
                  <tr>
                    <td><span v-html="detailIncident.conclusion || '-'"></span></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12" class="mb-3">
                <hr>
                <h5 class="text-center">Support Document</h5>
              </b-col>
              <b-col md="12" class="mb-4">
                <table class="table mb-0 table-borderless table-box-shadow">
                  <thead>
                  <tr>
                    <th width="5%">No.</th>
                    <th width="50%">Name of File / Document</th>
                    <th width="35%">File / Document</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="detailIncident?.documents?.length === 0">
                    <td colspan="3" class="text-center">No Document</td>
                  </tr>
                  <tr v-for="(file, index) in detailIncident?.documents" :key="index">
                    <td>{{ index + 1 }}.</td>
                    <td>{{ file.name_of_file }}</td>
                    <td>
                      <b-button-group>
                        <b-button variant="info" :href="file.document_url" download v-b-tooltip.hover
                                  title="Preview File"><i class="fa fa-eye"></i></b-button>
                        <b-button variant="dark" :href="file.document_url" download v-b-tooltip.hover
                                  title="Download File"><i class="fa fa-download"></i></b-button>
                      </b-button-group>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12" class="mb-3">
                <hr>
                <b-btn block href="#" v-b-toggle.accordion1 variant="secondary">
                  History Form
                  <span class="when-opened"><i class="fa fa-arrow-down" /></span>
                  <span class="when-closed"><i class="fa fa-arrow-up" /></span>
                </b-btn>

                <b-collapse id="accordion1" role="tabpanel">
                  <!-- some content -->
                  <ul class="iq-timeline">
                    <li v-if="detailIncident?.histories?.length == 0">
                      No Data
                    </li>
                    <li v-for="(history, index) in detailIncident?.histories" :key="index">
                      <div class="timeline-dots border-secondary"></div>
                      <h6 class="">{{ history.history_text }}</h6>
                      <small class="mt-1">{{ history.date_history }}</small>
                    </li>
                  </ul>
                </b-collapse>
              </b-col>
              <!-- Approval Form -->
              <b-col md="12" class="mb-4" v-if="showApprovalForm || detailIncident.status_id === 3">
                <h4 class="mb-3 mt-2 text-center">
                  <b-badge variant="success">{{ detailIncident.status_id !== 3 ? 'Approval' : 'Get' }} Marine Insurance Form</b-badge>
                </h4>
                <b-form-group class="row" label-cols-sm="3" label="Actual Close Date *" label-for="acd">
                  <date-picker type="date" v-model="approvalForm.actual_close_date" value-type="YYYY-MM-DD"
                               format="DD MMM YYYY" placeholder="Select date"></date-picker>
                </b-form-group>
                <table class="table mb-3 table-borderless table-box-shadow table-success">
                  <thead>
                  <tr>
                    <th width="35%">Type</th>
                    <th width="30%">Quantification</th>
                    <th width="35%"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <v-select v-model="approvalForm.type_form" label="text" :options="typeOfForm"
                                :reduce="typeOfForm => typeOfForm.value"></v-select>
                    </td>
                    <td>
                      <v-select v-model="approvalForm.quantification" label="text"
                                :options="quantificationOptions"
                                :reduce="quantificationOptions => quantificationOptions.value"></v-select>
                    </td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-form-group label="Coverage Period" label-for="fleet-equipment">
                        <date-picker style="width: 100%" @change="handleCoverageDateRange" v-model="coverageDateRange" type="year" value-type="YYYY"
                                     range placeholder="Select year"></date-picker>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="Status Coverage" label-for="fleet-equipment">
                        <v-select v-model="approvalForm.status_coverage" label="text"
                                  :options="statusCoverageOptions"
                                  :reduce="statusCoverageOptions => statusCoverageOptions.value"></v-select>
                      </b-form-group>
                    </td>
                    <td>

                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-form-group label="Proximity Level" label-for="fleet-equipment">
                        <b-input-group size="md" append="PL">
                          <b-form-spinbutton class="bg-secondary text-white mr-2"
                                             v-model="approvalForm.proximity_level" min="1" max="5" inline></b-form-spinbutton>
                        </b-input-group>
                      </b-form-group>
                    </td>
                    <td colspan="2">
                      <b-form-group label="Remarks">
                              <textarea class="form-control border-dark" v-model="approvalForm.remarks"
                                        rows="1"></textarea>
                      </b-form-group>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-form-group label="Follow Up Response" label-for="fleet-equipment">
                        <v-select v-model="approvalForm.response_fu_user" label="text"
                                  :options="responseFuOptions" :reduce="responseFuOptions => responseFuOptions.value">
                        </v-select>
                      </b-form-group>
                    </td>
                    <td colspan="2">
                      <b-form-group label="Reporting Status">
                        <!-- <textarea class="form-control border-dark" v-model="approvalForm.reporting_status"
                        rows="1"></textarea>-->
                        <v-select v-model="approvalForm.reporting_status" label="text"
                                  :options="statusOptions" :reduce="statusOptions => statusOptions.text">
                        </v-select>
                      </b-form-group>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <table class="table w-100">
                        <thead>
                        <tr>
                          <th width="15%">Insurer</th>
                          <th width="12%">Share</th>
                          <th width="12%">Currency</th>
                          <th width="15%">Amount</th>
                          <th width="13%">Paid at</th>
                          <th width="1%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(insurer, index) in approvalForm.insurers" :key="index">
                          <td>
                            <b-form-input type="text" class="border-dark" required v-model="insurer.insurer_id" />
                          </td>
                          <td>
                            <b-input-group size="sm" append="%">
                              <b-form-input type="number" class="border-dark" required
                                            v-model="insurer.share" />
                            </b-input-group>
                          </td>
                          <td>
                            <!--                                    <b-form-select size="sm" class="border-dark" required type="text"
                                                                  v-model="insurer.currency">
                                                                  <b-select-option value="IDR">IDR</b-select-option>
                                                                  <b-select-option value="USD">USD</b-select-option>
                                                                  <b-select-option value="SGD">SGD</b-select-option>
                                                                </b-form-select>-->
                            <v-select label="alias" :options="currencies" :reduce="(type) => type.id" v-model="insurer.currency_id"></v-select>
                          </td>
                          <td>
                            <b-input-group size="sm" prepend="Rp">
                              <b-form-input class="border-dark" required type="number"
                                            v-model="insurer.amount" />
                            </b-input-group>
                          </td>
                          <td>
                            <date-picker style="width: 100%" v-model="insurer.paid_at" required type="date"
                                         placeholder="Select Date" value-type="YYYY-MM-DD" format="DD MMM YYYY">
                            </date-picker>
                          </td>
                          <td>
                            <b-button variant="danger" @click="removeApprovalInsurer(index)" size="sm">
                              <i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <b-button @click="addApprovalInsurer()">Add Insurer</b-button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-form-group label="Cause of Loss">
                        <v-select label="name" v-model="approvalForm.cause_of_loss_id" :options="causesOfLoss"
                                  :reduce="causesOfLoss => causesOfLoss.code"></v-select>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="Classification of Loss">
                        <v-select label="name" v-model="approvalForm.classification_of_loss_id"
                                  :options="classificationsOfLoss"
                                  :reduce="classificationsOfLoss => classificationsOfLoss.code"></v-select>
                      </b-form-group>
                    </td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
                <b-button variant="primary" class="mt-5 m-auto" @click="onApprove()">{{ detailIncident.status_id !== 3 ? 'Setujui' : 'Submit' }}</b-button>
              </b-col>
              <!-- End Approval Form -->
              <!-- Reject Form -->
              <b-col md="12" class="mb-4" v-else-if="showRejectForm">
                <h4 class="mb-3 mt-2 text-center text-white">
                  <b-badge variant="warning">Reject / Pending Form</b-badge>
                </h4>
                <table class="table mb-3 table-borderless table-box-shadow table-warning">
                  <thead>
                  <tr>
                    <th>Remarks</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><textarea v-model="rejectForm.remarks" class="form-control border-dark"
                                  rows="3"></textarea></td>
                  </tr>
                  </tbody>
                </table>
                <b-button variant="danger" class="mt-5 m-auto" @click="onReject()">Tolak</b-button>
              </b-col>
              <!-- End Reject Form -->
              <b-col md="12" class="mb-3 text-center">
                <hr>
                <b-button-group class="mr-2">
                  <b-button v-if="![11, 12].includes(detailIncident.status.id)" title="Approval Marine Insurance"
                            variant="success" @click="handleChangeStatus(detailIncident.id, { code: 3 })"><i class="fa fa-check">
                    Approval</i></b-button>
                  <b-button v-if="![11, 12].includes(detailIncident.status.id)"
                            title="Reject/Pending this Submit Form" variant="warning"
                            @click="handleChangeStatus(detailIncident.id, { code: 12 })"><i class="fa fa-times"> Reject / Pending</i>
                  </b-button>
                  <router-link :to="`/mi/portal?formId=${detailIncident.id}`">
                    <b-button title="Edit Data" variant="primary"><i class="fa fa-edit"> Edit</i></b-button>
                  </router-link>
                  <b-button @click="handleDeleteMi(detailIncident.id)" title="Delete Data" variant="danger"><i
                    class="fa fa-trash"> Delete</i></b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-sidebar>
          <b-sidebar v-model="modal.nextStep" id="sidebar-view-next" title="NEXT PROCESS" width="100vw" header-class="bg-white mb-3" right backdrop body-class="px-3 overflow-x-hidden">
            <NextStepProcess v-if="modal.nextStep" :companies="companies"/>
            <!-- <b-row v-if="detailIncident" class="pt-2 pl-3 pr-3 pb-2">
              <b-col v-if="detailIncident.type_form === 'claim' && activeInitial" md="12" class="mb-3 bg-white">
                <hr>
                <h5 class="text-center p-3 bg-info">Initial Claim Analysis</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="detailIncident.initialClaimAnalysis" variant="success">Already Input</b-badge>
                    <b-badge v-else variant="warning">Not Input Yet</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <router-link :to="`/mi/claim-analysis?formId=${detailIncident.id}`" v-if="canAddInitial || canEditInitial">
                      <b-button v-if="!userPermission" variant="dark" size="sm">View InitialClaim Analysis</b-button>
                      <b-button v-else-if="detailIncident.initialClaimAnalysis" variant="dark" size="sm">Edit InitialClaim Analysis</b-button>
                      <b-button v-else variant="dark" size="sm">Input Initial Claim Analysis</b-button>
                    </router-link>
                  </b-col>
                  <b-col md="12" class="mt-4">
                    <b-table-simple>
                      <b-thead>
                        <b-th>Kesimpulan</b-th>
                        <b-th>Nilai Seluruhnya</b-th>
                        <b-th>Komentar / Saran</b-th>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-if="detailIncident.initialClaimAnalysis">
                          <b-td>{{ detailIncident.initialClaimAnalysis.conclusion }}</b-td>
                          <b-td>{{ detailIncident.initialClaimAnalysis.value_total }}</b-td>
                          <b-td>{{ detailIncident.initialClaimAnalysis.comments }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="detailIncident.type_form === 'claim' && activeCostBreakdown" md="12" class="mb-3 bg-white">
                <hr>
                <h5 class="text-center p-3 bg-info">Cost Breakdown</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="detailIncident.costBreakdown" variant="success">Already Input</b-badge>
                    <b-badge v-else variant="warning">Not Input Yet</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <router-link :to="`/mi/cost-breakdown?formId=${detailIncident.id}`" v-if="canAddCostBreakdown || canEditCostBreakdown">
                      <b-button v-if="!userPermission" variant="dark" size="sm">View Cost Breakdown</b-button>
                      <b-button v-else-if="detailIncident.costBreakdown" variant="dark" size="sm">Edit Cost Breakdown</b-button>
                      <b-button v-else variant="dark" size="sm">Input Cost Breakdown</b-button>
                    </router-link>
                  </b-col>
                  <b-col md="12" class="mt-4">
                    <b-table-simple v-if="detailIncident.costBreakdown">
                      <b-thead>
                        <b-th>No</b-th>
                        <b-th>Description/Item</b-th>
                        <b-th>Value</b-th>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(br, index) in detailIncident.costBreakdown.data" :key="index">
                          <b-td>{{ parseInt(index) + 1 }}</b-td>
                          <b-td>{{ br.description }}</b-td>
                          <b-td>Rp{{ numberFormat(br.value) }}</b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-th colspan="2" class="text-right">
                            Total
                          </b-th>
                          <b-td colspan="2">
                            <b>IDR. {{numberFormat(subTotalCostBreakdown)}}</b>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th colspan="2" class="text-right">
                            Deductible
                          </b-th>
                          <b-td colspan="2">
                            IDR. {{ numberFormat(detailIncident.costBreakdown.deductible) }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th colspan="2" class="text-right">
                            <strong>Grand Total</strong>
                          </b-th>
                          <b-td colspan="2">
                            IDR. {{ numberFormat(detailIncident.costBreakdown.grand_total) }}
                          </b-td>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="detailIncident.type_form === 'claim' && activeLossRatio" md="12" class="mb-3 bg-white">
                <hr>
                <h5 class="text-center p-3 bg-info">Loss Ratio</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="detailIncident?.lostRatio?.length > 0" variant="success">Already Input</b-badge>
                    <b-badge v-else variant="warning">Not Input Yet</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <router-link :to="`/mi/loss-ratio-input?formId=${detailIncident.id}`" v-if="canAddLossRatio || canEditLossRatio">
                      <b-button v-if="!userPermission"  variant="dark" size="sm">View Loss Ratio</b-button>
                      <b-button v-else-if="detailIncident?.lostRatio?.length > 0" variant="dark" size="sm">Edit Loss Ratio</b-button>
                      <b-button v-else variant="dark" size="sm">Input Loss Ratio</b-button>
                    </router-link>
                  </b-col>
                  <b-col md="12" class="mt-4">
                    <b-table-simple v-if="detailIncident?.lostRatio?.length > 0">
                      <b-thead>
                        <b-th>Type</b-th>
                        <b-th>Premium</b-th>
                        <b-th>Loss</b-th>
                        <b-th>Ratio</b-th>
                      </b-thead>
                      <b-tbody v-for="lostRatio in detailIncident.lostRatio" :key="lostRatio.id">
                        <b-tr>
                          <b-td>{{ lostRatio.type }}</b-td>
                          <b-td>{{ numberFormat(lostRatio.premium) }}</b-td>
                          <b-td>{{ numberFormat(lostRatio.loss) }}</b-td>
                          <b-td>{{ lostRatio.ratio }} %</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="detailIncident.type_form === 'claim' && activeManagementApproval" md="12" class="mb-3 bg-white">
                <hr>
                <h5 class="text-center p-3 bg-info">Management Approval</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="
                          !detailIncident.creator_fullname &&
                          !detailIncident.creator_date &&
                          !detailIncident.approver_fullname &&
                          !detailIncident.approver_date
                        " variant="warning">
                      Not Input Yet
                    </b-badge>
                    <b-badge v-else variant="success">Already Input</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                     <b-button v-if="canAddManagementApproval || canEditManagementApproval" @click="onCreateApproval(detailIncident.id)" variant="dark" size="sm">
                      {{
                        !detailIncident.creator_fullname &&
                        !detailIncident.creator_date &&
                        !detailIncident.approver_fullname &&
                        !detailIncident.approver_date ? 'Add' : 'Edit'
                      }} Management Approval
                    </b-button>
                  </b-col>
                  <b-col md="12" class="mt-4">
                    <b-table-simple v-if="
                          detailIncident.creator_fullname &&
                          detailIncident.creator_date &&
                          detailIncident.approver_fullname &&
                          detailIncident.approver_date
                        ">
                      <b-thead>
                        <b-th>Creator Fullname</b-th>
                        <b-th>Creation Date</b-th>
                        <b-th>Aprrover Fullname</b-th>
                        <b-th>Approval Date</b-th>
                        <b-th>Approval Description</b-th>
                        <b-th>Action</b-th>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td>{{ detailIncident.creator_fullname }}</b-td>
                          <b-td>{{ detailIncident.creator_date }}</b-td>
                          <b-td>{{ detailIncident.approver_fullname }}</b-td>
                          <b-td>{{ detailIncident.approver_date }}</b-td>
                          <b-td>
                            {{
                              detailIncident.approver_description && detailIncident?.approver_description?.length > 20 ?
                                detailIncident.approver_description.substring(0, length - 3) + '...' :
                                detailIncident.approver_description
                            }}
                          </b-td>
                          <b-td>
                            <b-button variant="warning" size="sm" @click="onPreviewApproval(detailIncident.id)"><i
                              class="fa fa-eye"></i></b-button>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="detailIncident.type_form === 'claim' && activeRCA" md="12" class="mb-3 bg-white">
                <hr>
                <h5 class="text-center p-3 bg-info">List RCA</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="!detailIncident?.rcas?.length" variant="warning">Not Input Yet</b-badge>
                    <b-badge v-else variant="success">Already Input</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <b-button v-if="!userPermission"  variant="dark" size="sm" @click="onCreateRCA(detailIncident.id)">View RCA</b-button>
                    <b-button v-else-if="canAddRCA" variant="dark" size="sm" @click="onCreateRCA(detailIncident.id)">Input RCA</b-button>
                  </b-col>
                  <b-col v-if="detailIncident?.rcas?.length > 0" md="12" class="mt-4">
                    <b-table-simple>
                      <b-thead>
                        <b-th>Nama</b-th>
                        <b-th>Company</b-th>
                        <b-th>Gender</b-th>
                        <b-th>Posisi</b-th>
                        <b-th>Action</b-th>
                      </b-thead>
                      <b-tbody v-for="rca in detailIncident?.rcas" :key="rca.id">
                        <b-tr>
                          <b-td>{{ rca.personal_fullname }}</b-td>
                          <b-td>{{ findCompany(rca.personal_company_id) }}</b-td>
                          <b-td>{{ rca.personal_gender === 'M' ? 'Laki - Laki' : 'Perempuan' }}</b-td>
                          <b-td>{{ rca.personal_position }}</b-td>
                          <b-td>
                            <b-button variant="success" size="sm" class="mr-2" v-if="canEditRCA"
                                      @click="onEditRCA(rca.id, detailIncident.id)"><i class="fa fa-edit"></i></b-button>
                            <b-button variant="danger" size="sm" class="mr-2" @click="onDeleteRCA(rca)" v-if="canDeleteRCA">
                              <i v-if="!loading_delete_rca" class="fa fa-trash"></i>
                              <template v-else>
                                    <span class="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                Deleting...
                              </template>
                            </b-button>
                            <b-button variant="warning" size="sm" @click="onPreviewRCA(rca.id, detailIncident.id)">
                              <i class="fa fa-eye"></i></b-button>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="detailIncident.type_form === 'claim' && activeFinalRCA" md="12" class="mb-3 bg-white">
                <hr>
                <h5 class="text-center p-3 bg-info">RCA Final</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="detailIncident.listRcaFinal" variant="success">Already Input</b-badge>
                    <b-badge v-else variant="warning">Not Input Yet</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <template v-if="canAddFinalRCA || canEditFinalRCA">
                      <router-link v-if="!userPermission" :to="`/mi/final-report-root-cause-analysis?formId=${detailIncident.id}`">
                      <b-button variant="dark" size="sm">View Final RCA</b-button>
                    </router-link>
                    <router-link v-if="detailIncident.listRcaFinal" :to="`/mi/final-report-root-cause-analysis?id=${detailIncident.listRcaFinal.id}&formId=${detailIncident.id}`">
                      <b-button variant="dark" size="sm">Edit Final RCA</b-button>
                    </router-link>
                     <router-link :to="`/mi/final-report-root-cause-analysis?formId=${detailIncident.id}`">
                      <b-button variant="dark" size="sm">Input Final RCA</b-button>
                    </router-link>
                    </template>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="detailIncident.type_form === 'claim' && activeMonitoring" md="12" class="mb-3 bg-white">
                <hr>
                <h5 class="text-center p-3 bg-info">Monitoring</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="detailIncident?.monitorings && detailIncident?.monitorings?.length > 0"
                             variant="success">Already Input</b-badge>
                    <b-badge v-else variant="warning">Not Input Yet</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <b-button @click="onCreateMonitoring(detailIncident.id)" variant="dark" size="sm" v-if="!userPermission">
                      View Monitoring
                    </b-button> 
                    <b-button @click="onCreateMonitoring(detailIncident.id)" variant="dark" size="sm" v-else-if="canAddMonitoring || canEditMonitoring">
                      {{ !detailIncident?.monitorings?.length ? 'Input' : 'Edit' }} Monitoring
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="detailIncident?.monitorings?.length" md="12" class="mt-4">
                  <b-table-simple>
                    <b-thead>
                      <b-th>Actual Claim Progress</b-th>
                      <b-th>Person in Charge</b-th>
                      <b-th>Total Days</b-th>
                      <b-th>Remarks</b-th>
                      <b-th>Status</b-th>
                    </b-thead>
                    <b-tbody v-for="monitoring in detailIncident.monitorings" :key="monitoring.id">
                      <b-tr>
                        <b-td>{{ monitoring.actual_claim_progress }}</b-td>
                        <b-td>{{ monitoring.person_in_charge }}</b-td>
                        <b-td>{{ monitoring.total_days }}</b-td>
                        <b-td>{{ monitoring.remarks }}</b-td>
                        <b-td>{{ monitoring.status.split('_').join(' ') }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-row>
              </b-col>
              <b-col md="12" class="mb-3 bg-white" v-if="activeRecommendation">
                <hr>
                <h5 class="text-center p-3 bg-info">Recommendation</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="detailIncident?.recommendations && detailIncident?.recommendations?.length > 0"
                             variant="success">Already Input</b-badge>
                    <b-badge v-else variant="warning">Not Input Yet</b-badge>
                    <b-badge variant="primary" class="ml-2" style="cursor:pointer" @click="handleOpenModalEmail('Recommendation', detailIncident.id)">Send Email Notification</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <b-button @click="onCreateRecommendation(detailIncident.id)" variant="dark" size="sm" v-if="!userPermission">
                      View Recommendation
                    </b-button> 
                    <b-button @click="onCreateRecommendation(detailIncident.id)" variant="dark" size="sm" v-else-if="canAddRecommendation || canEditRecommendation">
                      {{ !detailIncident?.recommendations?.length ? 'Input' : 'Edit' }} Recommendation
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="detailIncident?.recommendations?.length" md="12" class="mt-4">
                  <b-table-simple>
                    <b-thead>
                      <b-th>Problem</b-th>
                      <b-th>Vessel Case</b-th>
                      <b-th>Root Cause</b-th>
                      <b-th>Recommendation</b-th>
                      <b-th>Status</b-th>
                    </b-thead>
                    <b-tbody v-for="recommendation in detailIncident?.recommendations" :key="recommendation.id">
                      <b-tr>
                        <b-td>{{ recommendation.problem }}</b-td>
                        <b-td>{{ recommendation.vessel_case }}</b-td>
                        <b-td>{{ recommendation.root_cause }}</b-td>
                        <b-td>{{ recommendation.recommendation }}</b-td>
                        <b-td>{{ recommendation.status }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-row>
              </b-col>
              <b-col md="12" class="mb-3 bg-white" v-if="activeEvaluation">
                <hr>
                <h5 class="text-center p-3 bg-info">Evaluation</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="detailIncident?.evaluations && detailIncident?.evaluations?.length > 0"
                             variant="success">Already Input</b-badge>
                    <b-badge v-else variant="warning">Not Input Yet</b-badge>
                    <b-badge variant="primary" class="ml-2" style="cursor:pointer" @click="handleOpenModalEmail('Evaluation', detailIncident.id)">Send Email Notification</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <b-button @click="onCreateEvaluation(detailIncident.id)" variant="dark" size="sm" v-if="!userPermission">
                      View Evaluation
                    </b-button> 
                    <b-button @click="onCreateEvaluation(detailIncident.id)" variant="dark" size="sm" v-else-if="canAddEvaluation || canEditEvaluation">
                      {{ !detailIncident?.evaluations?.length ? 'Input' : 'Edit' }} Evaluation
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="detailIncident?.evaluations?.length > 0" md="12" class="mt-4">
                  <b-table-simple>
                    <b-thead>
                      <b-th>Activity Stage</b-th>
                      <b-th>Milestone</b-th>
                      <b-th>Executor</b-th>
                      <b-th>Follow Up Needs</b-th>
                    </b-thead>
                    <b-tbody v-for="evaluation in detailIncident?.evaluations" :key="evaluation.id">
                      <b-tr>
                        <b-td>{{ evaluation.activity_stage }}</b-td>
                        <b-td>{{ evaluation.milestone }}</b-td>
                        <b-td>{{ evaluation.executor }}</b-td>
                        <b-td>{{ evaluation.follow_up_needs }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-row>
              </b-col>
              <b-col md="12" class="mb-3 bg-white" v-if="activeERM">
                <hr>
                <h5 class="text-center p-3 bg-info">Enterprise Risk Management</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="!detailIncident.erm" variant="warning">Not Input Yet</b-badge>
                    <b-badge v-else variant="success">Already Input</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <b-button @click="onCreateErm(detailIncident.id)" variant="dark" size="sm" v-if="!userPermission">
                      View Enterprise Risk Management
                    </b-button> 
                    <b-button @click="onCreateErm(detailIncident.id)" variant="dark" size="sm" v-else-if="canAddERM || canEditERM">
                      {{ !detailIncident.erm ? 'Input' : 'Edit' }} Enterprise Risk Management
                    </b-button>
                  </b-col>
                  <b-col v-if="detailIncident.erm" md="12" class="mt-4">
                    <b-table-simple>
                      <b-thead>
                        <b-th>Nama</b-th>
                        <b-th>Company</b-th>
                        <b-th>Action</b-th>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td>{{ detailIncident.erm.assessor_fullname }}</b-td>
                          <b-td>{{ findCompany(detailIncident.erm.assessor_company_id) }}</b-td>
                          <b-td>
                            <b-button variant="success" size="sm" class="mr-2"
                                      @click="onEditErm(detailIncident.id)"><i class="fa fa-edit"></i></b-button>
                            <b-button variant="warning" size="sm" @click="onPreviewErm(detailIncident.id)">
                              <i class="fa fa-eye"></i></b-button>
                          </b-td>
                        </b-tr>
                      </b-tbody>p
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="mb-3 bg-white" v-if="detailIncident.type_form === 'claim' && activeManagementSummary">
                <hr>
                <h5 class="text-center p-3 bg-info">Management Summary</h5>
                <b-row class="mt-4 mb-3">
                  <b-col md="6">
                    <b-badge v-if="!detailIncident.managementSummary" variant="warning">Not Input Yet</b-badge>
                    <b-badge v-else variant="success">Already Input</b-badge>
                  </b-col>
                  <b-col md="6" class="text-right" v-if="canAddManagementSummary || canEditManagementSummary">
                    <b-button v-if="detailIncident.managementSummary" variant="dark" size="sm" @click="onCreateManagementSummary(detailIncident.id, detailIncident.managementSummary.id)">Edit Management Summary</b-button>
                    <b-button v-else variant="dark" size="sm" @click="onCreateManagementSummary(detailIncident.id)">Input Management Summary</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row> -->
          </b-sidebar>

           <!-- SEND EMAIL -->
           <b-modal
              id="send-email"
              size="lg"
              :title="`Send Email ${emailTitle}`"
              cancel-title="Close"
              hide-footer
            >
              <b-row>
                <b-col lg='12'>
                  <b-row>
                    <b-col lg="12">
                      <!-- <b-form-input placeholder="Search user..." v-model="searchUser" @input="filterUserByName" /> -->
                    </b-col>
                    <b-col lg="12">
                      <div class="d-flex w-100 border my-2" />
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label="Notif mail to user" class="mt-2" label-for="notifMailUser">
                        <v-multiselect   v-model="notifMailUser" placeholder="Notif user" label="name"
                          track-by="id" :options="filteredExpertUsers" :multiple="true">
                          <span slot="noResult">Data not found.</span>
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" v-if="emailTitle === 'Accident'">
                      <b-form-group label="Approval mail to user" class="mt-2" label-for="approvalMailUser">
                        <v-multiselect   v-model="approvalMailUser" placeholder="Notif user" label="name"
                          track-by="id" :options="filteredExpertUsers" :multiple="true">
                          <span slot="noResult">Data not found.</span>
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="3" v-for="(state, index) in filteredExpertUsers" class="mb-2">
                      <div class="d-flex flex-column">
                        <b-form-checkbox type="checkbox" v-model="state.selected" />
                        <div class="d-flex flex-column  align-items-center justify-content-center w-100" @click="state.selected = !state.selected">
                          <img :src="require('../../assets/images/user/11.png')" alt="user-images" style="width:80px; height:80px" />
                          <span>{{ state?.name }}</span>
                        </div>
                      </div>
                    </b-col> -->
                  </b-row>
                </b-col>
                <b-col lg="12">
                  <div class="d-flex w-100 border my-2" />
                </b-col>
                <b-col lg="12" class="text-right">
                  <b-button variant="primary" type="button" @click.prevent="sendEmailToUser"><i class="fa fa-send mr-2" /> Send Mail</b-button>
                </b-col>
              </b-row>
            </b-modal>
            <!-- SEND EMAIL END -->
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Content Section !-->
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import moment from 'moment'
import { companiesActions, fleetsActions, miActions, crewingActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'
import NextStepProcess from './next-step-process/index.vue'

export default {
  name: 'ListInitialTable',
  props: {
    vehicleId: {
      type: Number,
      default: null
    },
    nonSettled: {
      type: Boolean,
      default: false
    },
    typeForm: {
      type: String,
      default: null
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    showStatusAction: {
      type: Boolean,
      default: true
    }
  },
  components:{
    NextStepProcess
  },
  mounted () {
    xray.index()

    if (this.vehicleId) {
      this.incidents.params.vehicle_id = this.vehicleId
    }

    this.handleChangeDate()
    this.handleFetchTypeCoverages()
    this.handleFetchTypeLoss()
    this.handleFetchStatusList()
    this.handleFetchInsurers()
    this.handleFetchCauseOfLossList()
    this.handleFetchCompanies()
    this.handleFetchFleets()
    this.handleFetchIncidents()
    this.handleFetchCurrencies()
    this.fetchDataUserExpert()
  },
  data () {
    return {
      permission_user:JSON.parse(localStorage.getItem('profile')).role.roles,
      dateRange: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      currencies: [],
      optionsExpertUsers: [],
      filteredExpertUsers: [],
      loading_export: false,
      statusOptions: [],
      typeOfForm: [
        { value: 'claim', text: 'Claim' },
        { value: 'non_claim', text: 'Non Claim' }
      ],
      quantificationOptions: [
        { value: 'major', text: 'Major' },
        { value: 'minor', text: 'Minor' }
      ],
      statusCoverageOptions: [
        { value: 'valid', text: 'Valid' },
        { value: 'expire', text: 'Expired' }
      ],
      followUpOptions: [
        { value: 'withdrawal', text: 'Withdrawal' },
        { value: 'subject_to', text: 'Subject To' },
        { value: 'on_progress', text: 'On Progress' }
      ],
      responseFuOptions: [
        { value: 'responsive', text: 'Responsive' },
        { value: 'unresponsive', text: 'Unresponsive' }
      ],
      statuses: [],
      companies: [],
      fleetsLoading: true,
      fleets: [],
      typeCoverages: [],
      typeLoss: [],
      insurers: [],
      causesOfLoss: [],
      classificationsOfLoss: [],
      detailIncident: null,
      changeStatusLoading: false,
      incidents: {
        isLoading: false,
        data: [],
        meta: {
          from: 0,
          to: 0,
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        },
        params: {
          page: 1,
          from: null,
          to: null,
          company_id: null,
          vehicle_id: null,
          type_form: null,
          status_id: null,
          id_type_coverage: null,
          id_type_loss: null,
          sort: 'date_of_loss:asc',
          with: 'vehicle,type_coverage,type_loss,cause_of_loss,company,documents,company_claimant,status,creator,histories,rcas,claim_analysis,cost_breakdown,loss_ratio,rca_final,department'
        },
        detailParams: {
          withDetail:
            'vehicle,type_coverage,type_loss,cause_of_loss,company,documents,company_claimant,status,creator,histories,rcas,claim_analysis,cost_breakdown,loss_ratio,rca_final,insurers,classification_of_loss,monitorings,recommendations,evaluations,erm,summary,department'
        }
      },
      showApprovalForm: false,
      coverageDateRange: [moment().year().toString(), moment().add(1, 'year').year().toString()],
      approvalForm: {
        status_id: 3,
        actual_close_date: '',
        type_form: '',
        quantification: 'minor',
        insurers: [],
        coverage_period: moment().year().toString(),
        coverage_period_to: moment().add(1, 'year').year().toString(),
        status_coverage: 'valid',
        classification_of_loss_id: '',
        proximity_level: 1,
        remarks: '',
        follow_up_response: '',
        response_fu_user: '',
        reporting_status: '',
        cause_of_loss_id: '',
        approver_fullname: '',
        approver_date: ''
      },
      showRejectForm: false,
      rejectForm: {
        status_id: 12,
        remarks: ''
      },
      loading_delete_rca: false,
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      notifMailUser: [],
      approvalMailUser: [],
      searchUser: '',
      emailTitle: '',
      formId: null,
      // lsProfile: null
      modal:{
        filter:false,
        nextStep:false
      }
    }
  },
  computed: {
    userPermission(){
      if(['SUPER ADMIN', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'MARINE INSURANCE MANAGER'].includes(this.permission_user)){
        return true
      } else{
        return false
      } 
    },
    role () {
      return this.lsProfile ? this.lsProfile.role.roles : ''
    },
    subTotalCostBreakdown () {
      let ret = 0

      this.detailIncident.costBreakdown.data.forEach(list => {
        if (list.value > -0) {
          ret += parseInt(list.value)
        }
      })

      return ret
    },
    menuPermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'List Initial Accident / Incident')
    },
    canAdd () {
      return this.menuPermission ? this.menuPermission?.add : false
    },
    canEdit () {
      return this.menuPermission ? this.menuPermission?.update : false
    },
    canApprove () {
      return this.menuPermission ? this.menuPermission?.approval : false
    },
    canReview () {
      return this.menuPermission ? this.menuPermission?.review : false
    },
    canDelete () {
      return this.menuPermission ? this.menuPermission?.delete : false
    },
    canExport () {
      return this.menuPermission ? this.menuPermission?.export : false
    },
    initialPermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Initial Claim Analysis')
    },
    activeInitial () {
      return this.initialPermission ? this.initialPermission?.active : false
    },
    canAddInitial () {
      return this.initialPermission ? this.initialPermission?.add : false
    },
    canEditInitial () {
      return this.initialPermission ? this.initialPermission?.update : false
    },
    canApproveInitial () {
      return this.initialPermission ? this.initialPermission?.approval : false
    },
    canReviewInitial () {
      return this.initialPermission ? this.initialPermission?.review : false
    },
    canDeleteInitial () {
      return this.initialPermission ? this.initialPermission?.delete : false
    },
    canExportInitial () {
      return this.initialPermission ? this.initialPermission?.export : false
    },
    costBreakdown () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Cost Breakdown')
    },
    activeCostBreakdown () {
      return this.costBreakdown ? this.costBreakdown?.active : false
    },
    canAddCostBreakdown () {
      return this.costBreakdown ? this.costBreakdown?.add : false
    },
    canEditCostBreakdown () {
      return this.costBreakdown ? this.costBreakdown?.update : false
    },
    canApproveCostBreakdown () {
      return this.costBreakdown ? this.costBreakdown?.approval : false
    },
    canReviewCostBreakdown () {
      return this.costBreakdown ? this.costBreakdown?.review : false
    },
    canDeleteCostBreakdown () {
      return this.costBreakdown ? this.costBreakdown?.delete : false
    },
    canExportCostBreakdown () {
      return this.costBreakdown ? this.costBreakdown?.export : false
    },
    lossRatio () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Loss Ratio')
    },
    activeLossRatio () {
      return this.lossRatio ? this.lossRatio?.active : false
    },
    canAddLossRatio () {
      return this.lossRatio ? this.lossRatio?.add : false
    },
    canEditLossRatio () {
      return this.lossRatio ? this.lossRatio?.update : false
    },
    canApproveLossRatio () {
      return this.lossRatio ? this.lossRatio?.approval : false
    },
    canReviewLossRatio () {
      return this.lossRatio ? this.lossRatio?.review : false
    },
    canDeleteLossRatio () {
      return this.lossRatio ? this.lossRatio?.delete : false
    },
    canExportLossRatio () {
      return this.lossRatio ? this.lossRatio?.export : false
    },
    managementApproval () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Management Approval')
    },
    activeManagementApproval () {
      return this.managementApproval ? this.managementApproval?.active : false
    },
    canAddManagementApproval () {
      return this.managementApproval ? this.managementApproval?.add : false
    },
    canEditManagementApproval () {
      return this.managementApproval ? this.managementApproval?.update : false
    },
    canApproveManagementApproval () {
      return this.managementApproval ? this.managementApproval?.approval : false
    },
    canReviewManagementApproval () {
      return this.managementApproval ? this.managementApproval?.review : false
    },
    canDeleteManagementApproval () {
      return this.managementApproval ? this.managementApproval?.delete : false
    },
    canExportManagementApproval () {
      return this.managementApproval ? this.managementApproval?.export : false
    },
    RCA () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'RCA')
    },
    activeRCA () {
      return this.RCA ? this.RCA?.active : false
    },
    canAddRCA () {
      return this.RCA ? this.RCA?.add : false
    },
    canEditRCA () {
      return this.RCA ? this.RCA?.update : false
    },
    canApproveRCA () {
      return this.RCA ? this.RCA?.approval : false
    },
    canReviewRCA () {
      return this.RCA ? this.RCA?.review : false
    },
    canDeleteRCA () {
      return this.RCA ? this.RCA?.delete : false
    },
    canExportRCA () {
      return this.RCA ? this.RCA?.export : false
    },
    finalRCA () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Final RCA')
    },
    activeFinalRCA () {
      return this.finalRCA ? this.finalRCA?.active : false
    },
    canAddFinalRCA () {
      return this.finalRCA ? this.finalRCA?.add : false
    },
    canEditFinalRCA () {
      return this.finalRCA ? this.finalRCA?.update : false
    },
    canApproveFinalRCA () {
      return this.finalRCA ? this.finalRCA?.approval : false
    },
    canReviewFinalRCA () {
      return this.finalRCA ? this.finalRCA?.review : false
    },
    canDeleteFinalRCA () {
      return this.finalRCA ? this.finalRCA?.delete : false
    },
    canExportFinalRCA () {
      return this.finalRCA ? this.finalRCA?.export : false
    },
    monitoring () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Monitoring')
    },
    activeMonitoring () {
      return this.monitoring ? this.monitoring?.active : false
    },
    canAddMonitoring () {
      return this.monitoring ? this.monitoring?.add : false
    },
    canEditMonitoring () {
      return this.monitoring ? this.monitoring?.update : false
    },
    canApproveMonitoring () {
      return this.monitoring ? this.monitoring?.approval : false
    },
    canReviewMonitoring () {
      return this.monitoring ? this.monitoring?.review : false
    },
    canDeleteMonitoring () {
      return this.monitoring ? this.monitoring?.delete : false
    },
    canExportMonitoring () {
      return this.monitoring ? this.monitoring?.export : false
    },
    recommendation () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Recommendation')
    },
    activeRecommendation () {
      return this.recommendation ? this.recommendation?.active : false
    },
    canAddRecommendation () {
      return this.recommendation ? this.recommendation?.add : false
    },
    canEditRecommendation () {
      return this.recommendation ? this.recommendation?.update : false
    },
    canApproveRecommendation () {
      return this.recommendation ? this.recommendation?.approval : false
    },
    canReviewRecommendation () {
      return this.recommendation ? this.recommendation?.review : false
    },
    canDeleteRecommendation () {
      return this.recommendation ? this.recommendation?.delete : false
    },
    canExportRecommendation () {
      return this.recommendation ? this.recommendation?.export : false
    },
    evaluation () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Evaluation')
    },
    activeEvaluation () {
      return this.evaluation ? this.evaluation?.active : false
    },
    canAddEvaluation () {
      return this.evaluation ? this.evaluation?.add : false
    },
    canEditEvaluation () {
      return this.evaluation ? this.evaluation?.update : false
    },
    canApproveEvaluation () {
      return this.evaluation ? this.evaluation?.approval : false
    },
    canReviewEvaluation () {
      return this.evaluation ? this.evaluation?.review : false
    },
    canDeleteEvaluation () {
      return this.evaluation ? this.evaluation?.delete : false
    },
    canExportEvaluation () {
      return this.evaluation ? this.evaluation?.export : false
    },
    ERM () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Enterprise Risk Management')
    },
    activeERM () {
      return this.ERM ? this.ERM?.active : false
    },
    canAddERM () {
      return this.ERM ? this.ERM?.add : false
    },
    canEditERM () {
      return this.ERM ? this.ERM?.update : false
    },
    canApproveERM () {
      return this.ERM ? this.ERM?.approval : false
    },
    canReviewERM () {
      return this.ERM ? this.ERM?.review : false
    },
    canDeleteERM () {
      return this.ERM ? this.ERM?.delete : false
    },
    canExportERM () {
      return this.ERM ? this.ERM?.export : false
    },
    managementSummary () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Management Summary')
    },
    activeManagementSummary () {
      return this.managementSummary ? this.managementSummary?.active : false
    },
    canAddManagementSummary () {
      return this.managementSummary ? this.managementSummary?.add : false
    },
    canEditManagementSummary () {
      return this.managementSummary ? this.managementSummary?.update : false
    },
    canApproveManagementSummary () {
      return this.managementSummary ? this.managementSummary?.approval : false
    },
    canReviewManagementSummary () {
      return this.managementSummary ? this.managementSummary?.review : false
    },
    canDeleteManagementSummary () {
      return this.managementSummary ? this.managementSummary?.delete : false
    },
    canExportManagementSummary () {
      return this.managementSummary ? this.managementSummary?.export : false
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    ...crewingActions,
    toggleModalFilter(){
      this.modal.filter = !this.modal.filter
    },
    numberFormat (number) {
      return numberFormat(number)
    },
    async handleFetchCurrencies () {
      const { data } = await this.getCurrencies()
      this.currencies = data
    },
    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 10000,
      }

      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          data.map((state) => {
            this.optionsExpertUsers.push({
              id: state.id,
              name: state.userDetail.name,
              selected:false
            })
            this.filteredExpertUsers = this.optionsExpertUsers
          })
        }
      }
    },
    async handleFetchCompanies () {
      const res = await this.getCompanies()

      if (res.status === 'success' && res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          this.companies.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id
          })
        })
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async handleFetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: ''
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.fleets.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async handleFetchTypeCoverages () {
      this.typeCoverages = []
      let res = await this.getTypeCoverages({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.typeCoverages.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async handleFetchTypeLoss () {
      this.typeLoss = []
      let res = await this.getTypeLoss({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.typeLoss.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async handleFetchStatusList () {
      this.statuses = []
      let res = await this.getStatuses({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.statuses.push({
              name: el.name,
              code: el.id
            })
          })
          this.statusOptions = res.data.map((val) => ({
            value: val.id,
            text: val.name
          }))
        }
      }
    },
    async handleFetchInsurers () {
      this.insurers = []
      let res = await this.getInsurers({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.insurers.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async handleFetchCauseOfLossList () {
      this.causesOfLoss = []
      let res = await this.getMasterCauseOfLoss({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.causesOfLoss.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async handleFetchClassificationOfLoss () {
      this.classificationsOfLoss = []
      let res = await this.getMasterClassificationOfLoss({
        type_coverage_id: this.detailIncident.type_coverage.id,
        pagination: 0
      })

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.classificationsOfLoss.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    handleShowListStatuses (currentStatusId) {
      if (currentStatusId === 1) {
        return [2, 3, 12]
      } else if (currentStatusId === 2) {
        return [3, 12]
      } else if (currentStatusId === 3) {
        return [3, 4, 5, 6, 7, 8, 9, 10, 11]
      }

      return [4, 5, 6, 7, 8, 9, 10, 11]
    },
    async handleFetchIncidents (page = 1, isSavingToStorage) {
      this.incidents.data = []
      this.incidents.isLoading = true
      this.incidents.params.page = page

      if(isSavingToStorage){
        localStorage.setItem('mi/list-initial-filter', JSON.stringify(this.incidents.params))
        localStorage.setItem('mi/saved', true)
      }

      const local_params = JSON.parse(localStorage.getItem('mi/list-initial-filter'))
      const isSaved = localStorage.getItem('mi/saved')

      if(isSaved){
        this.incidents.params = {
          ...this.incidents.params,
          company_id: local_params.company_id,
          vehicle_id: local_params.vehicle_id,
          type_form: local_params.type_form,
          status_id: local_params.status_id,
          id_type_coverage: local_params.id_type_coverage,
          id_type_loss: local_params.id_type_loss,
        }
      }

      const params = { ...this.incidents.params }

      if (this.nonSettled) {
        params.nonsettled = true
      }

      if (this.typeForm) {
        params.type_form = this.typeForm
      }

      const { data } = await this.getMi(params)

      if (data.data) {
        this.incidents.data = data.data
        this.incidents.meta.perPage = data.per_page
        this.incidents.meta.currentPage = data.current_page
        this.incidents.meta.total = data.total
        this.incidents.meta.totalPage = data.totalPage
        this.incidents.meta.from = data.from
        this.incidents.meta.to = data.to
      }

      this.incidents.isLoading = false
    },
    async exportIncidentsFile () {
      this.loading_export = true

      const params = {
        ...this.incidents.params,
        excel: true
      }

      const res = await this.exportInitial(params)
      if (res.data) {
        this.loading_export = false
        // window.open(res.data)
        // saveAs(res.data, 'TSI List.xlsx')
        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    },
    async handleChangeStatus (formId, status, index) {
      // if change status to approve, show detail
      if (status.code === 3) {
        if (typeof index !== 'undefined') {
          this.handleOpenDetailIncident(index)
        }
        this.handleClearApprovalForm()
        this.showRejectForm = true
        this.showApprovalForm = true
        return
      }

      // if change status to reject, show detail
      if (status.code === 12) {
        if (typeof index !== 'undefined') {
          this.handleOpenDetailIncident(index)
        }
        this.handlecCearRejectForm()
        this.showApprovalForm = false
        this.showRejectForm = true
        return
      }

      let payload = {
        id: formId,
        status_id: status.code
      }

      this.$swal({
        title: `Ingin Melakukan ${status.name} ?`,
        text: `Periksa Kembali Seluruh Data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Lakukan ${status.name}`
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.changeStatusLoading = true

          const { statusCode = status } = await this.updateMi(payload)

          if ([500, 404, 403].includes(statusCode)) {
            this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
            this.changeStatusLoading = false
            return
          }

          this.$swal(`Berhasil Melakukan ${status.name}`, '', 'success')

          this.handleFetchIncidents()
          this.changeStatusLoading = false
        }
      })
    },
    async handleDeleteMi (formId) {
      this.$swal({
        title: `Ingin Menghapus Data ?`,
        text: `Tidak dapat diurungkan`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Hapus`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { statusCode = status } = await this.deleteMi({ id: formId })

          if ([500, 404, 403].includes(statusCode)) {
            this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
            this.changeStatusLoading = false
            return
          }

          this.$swal(`Berhasil Menghapus Data`, '', 'success')

          this.handleFetchIncidents()
        }
      })
    },
    handleChangeDate () {
      this.incidents.params.from = this.dateRange ? moment(this.dateRange[0]).format('YYYY-MM-DD') : null
      this.incidents.params.to = this.dateRange ? moment(this.dateRange[1]).format('YYYY-MM-DD') : null
    },
    handleCoverageDateRange () {
      this.approvalForm.coverage_period = this.coverageDateRange ? moment(this.coverageDateRange[0]).format('YYYY') : null
      this.approvalForm.coverage_period_to = this.coverageDateRange ? moment(this.coverageDateRange[1]).format('YYYY') : null
    },
    async handleOpenDetailIncident (index) {
      this.detailIncident = null
      this.showApprovalForm = false
      this.showRejectForm = false

      if (this.incidents.data[index]) {
        const { data } = await this.fetchDetailMi({
          id: this.incidents.data[index].id,
          with: this.incidents.detailParams.withDetail
        })

        this.detailIncident = data

        if (this.detailIncident.status_id === 3) {
          this.approvalForm.insurers = data.insurers
          this.approvalForm.cause_of_loss_id = data.cause_of_loss_id
          this.approvalForm.quantification = data.quantification
          // this.approvalForm.follow_up_response = data.follow_up_response
          this.approvalForm.response_fu_user = data.response_fu_user
          this.approvalForm.type_form = data.type_form
          this.approvalForm.remarks = data.remarks
          this.approvalForm.proximity_level = data.proximity_level
          this.approvalForm.status_coverage = data.status_coverage
          this.approvalForm.coverage_period = data.coverage_period ? data.coverage_period.toString() : ''
          this.approvalForm.actual_close_date = data.actual_close_date
          this.approvalForm.reporting_status = data.reporting_status
          this.approvalForm.classification_of_loss_id = data.classification_of_loss.id
        }

        this.handleFetchClassificationOfLoss()
        this.$root.$emit('bv::toggle::collapse', 'sidebar-view')
      }
    },
    async handleNextProcess (index, data) {
      this.$router.replace({ 
        query: {
          ...this.$route.query,
          formId:data.id,
        }
      });
      this.detailIncident = null
      this.showApprovalForm = false
      this.showRejectForm = false

      if (this.incidents.data[index]) {
        const { data } = await this.fetchDetailMi({
          id: this.incidents.data[index].id,
          with: this.incidents.detailParams.withDetail
        })

        this.detailIncident = {
          ...data,
          initialClaimAnalysis: data.claim_analysis,
          costBreakdown: data.cost_breakdown,
          lostRatio: data.loss_ratio,
          managementApproval: null,
          listRca: data.rcas,
          listRcaFinal: data.rca_final,
          managementSummary: data.summary,
          erm: data.erm
        }
        // this.$root.$emit('bv::toggle::collapse', 'sidebar-view-next')
        this.modal.nextStep = true
      }
    },
    onApprove () {
      let payload = {
        id: this.detailIncident.id,
        status_id: this.approvalForm.status_id,
        actual_close_date: this.approvalForm.actual_close_date,
        type_form: this.approvalForm.type_form,
        quantification: this.approvalForm.quantification,
        status_coverage: this.approvalForm.status_coverage,
        coverage_period: this.approvalForm.coverage_period,
        classification_of_loss_id: this.approvalForm.classification_of_loss_id,
        proximity_level: this.approvalForm.proximity_level,
        remarks: this.approvalForm.remarks,
        // follow_up_response: this.approvalForm.follow_up_response,
        response_fu_user: this.approvalForm.response_fu_user,
        reporting_status: this.approvalForm.reporting_status,
        insurers: this.approvalForm.insurers
      }

      this.$swal({
        title: `Ingin Menyetujui ?`,
        text: `Periksa Kembali Seluruh Data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Setuju`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await this.updateMi(payload)
          if ([500, 404, 403].includes(status)) {
            this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
            this.changeStatusLoading = false
            return
          }

          if (status === 422) {
            this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
            this.changeStatusLoading = false
            return
          }

          this.$swal(`Berhasil Menyetujui`, '', 'success')
          this.handleClearApprovalForm()
          this.handleFetchIncidents()
          this.$root.$emit('bv::toggle::collapse', 'sidebar-view')
        }
      })
    },
    onReject () {
      let payload = {
        id: this.detailIncident.id,
        status_id: this.rejectForm.status_id,
        remarks: this.rejectForm.remarks
      }

      this.$swal({
        title: `Ingin Menolak ?`,
        text: `Periksa Kembali Seluruh Data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Tolak`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { statusCode = status } = await this.updateMi(payload)
          if ([500, 404, 403].includes(statusCode)) {
            this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
            this.changeStatusLoading = false
            return
          }

          if (statusCode === 422) {
            this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
            this.changeStatusLoading = false
            return
          }

          this.$swal(`Berhasil Menolak`, '', 'success')
          this.handlecCearRejectForm()
          this.handleFetchIncidents()
          this.$root.$emit('bv::toggle::collapse', 'sidebar-view')
        }
      })
    },
    addApprovalInsurer () {
      this.approvalForm.insurers.push({
        insurer_id: '',
        share: '',
        currency: '',
        amount: 0,
        paid_at: null
      })
    },
    removeApprovalInsurer (index) {
      this.approvalForm.insurers.splice(index, 1)
    },
    handleClearApprovalForm () {
      this.approvalForm = {
        status_id: 3,
        actual_close_date: '',
        type_form: this.detailIncident.type_form,
        quantification: 'minor',
        insurers: [],
        coverage_period: moment().year().toString(),
        coverage_period_to: moment().add(1, 'year').year().toString(),
        status_coverage: 'valid',
        classification_of_loss_id: '',
        proximity_level: 1,
        remarks: '',
        follow_up_response: '',
        response_fu_user: '',
        reporting_status: '',
        cause_of_loss_id: '',
        approver_fullname: '',
        approver_date: ''
      }
    },
    handlecCearRejectForm () {
      this.rejectForm = {
        status_id: 12,
        remarks: ''
      }
    },
    onCreateErm (formId) {
      this.$router.push({
        name: 'mi.enterprise-risk-management',
        query: {
          formId
        }
      })
    },
    onEditErm (formId) {
      this.$router.push({
        name: 'mi.enterprise-risk-management',
        query: {
          formId
        }
      })
    },
    onPreviewErm (formId) {
      this.$router.push({
        name: 'mi.enterprise-risk-management',
        query: {
          formId,
          preview: true
        }
      })
    },
    onCreateManagementSummary (formId, id) {
      this.$router.push({
        name: 'mi.management-summary',
        query: {
          formId,
          id
        }
      })
    },
    onCreateRCA (formId) {
      this.$router.push({
        name: 'mi.root-cause-analysis',
        query: {
          formId
        }
      })
    },
    onEditRCA (id, formId) {
      this.$router.push({
        name: 'mi.root-cause-analysis',
        params: {
          id
        },
        query: {
          formId
        }
      })
    },
    onDeleteRCA (rca) {
      this.$swal({
        title: 'Delete RCA?',
        text: `RCA for ${rca.personal_fullname} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_rca = true
          let res = await this.deleteRCA(rca.id)
          if (res.status !== 'success') {
            this.loading_delete_rca = false
            if (res.data.message !== null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get RCA data.',
                'error'
              )
            }
          } else {
            this.loading_delete_rca = false
            this.handleFetchIncidents()
            this.$swal(
              `RCA deleted!`,
              `RCA for ${rca.personal_fullname} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    onPreviewRCA (id, formId) {
      this.$router.push({
        name: 'mi.root-cause-analysis',
        params: {
          id
        },
        query: {
          formId,
          preview: true
        }
      })
    },
    onCreateRecommendation (formId) {
      this.$router.push({
        name: 'mi.add-form-recommendation',
        query: {
          formId
        }
      })
    },
    onCreateMonitoring (id) {
      this.$router.push({
        name: 'mi.add-form-monitoring',
        query: {
          formId: id
        }
      })
    },
    onCreateEvaluation (id) {
      this.$router.push({
        name: 'mi.add-form-evaluation',
        query: {
          formId: id
        }
      })
    },
    onCreateApproval (id) {
      this.$router.push({
        name: 'mi.management-approval',
        query: {
          formId: id
        }
      })
    },
    onPreviewApproval (id) {
      this.$router.push({
        name: 'mi.management-approval',
        query: {
          formId: id,
          state: 'view'
        }
      })
    },
    findCompany (id) {
      return this.companies.find((company) => company.value === id).text
    },

    filterUserByName() {
      this.filteredExpertUsers = this.optionsExpertUsers?.filter(item => {
        // Use item.name.toLowerCase() for case-insensitive search
        return item.name.toLowerCase().includes(this.searchUser.toLowerCase());
      });
    },
    handleOpenModalEmail (title, formId) {
      this.emailTitle = title
      this.formId = formId
      this.$nextTick(() => {
        this.$bvModal.show('send-email')
      })

      if(title === 'Accident'){
        let findDicky = this.filteredExpertUsers?.find(obj => obj.name.toLowerCase().includes('dicky'))
        this.approvalMailUser.push(findDicky)
      }
    },
    async sendEmailToUser(){
      let userId = []
      let approvalId = []
      this.notifMailUser.map(obj => {
        userId.push(obj.id)
      }) 
      this.approvalMailUser.map(obj => {
        approvalId.push(obj.id)
      })

      if(userId.length <= 0) return this.$swal(`Oops!`, 'Select user first.', 'warning')

      let payload = {
        user_id: userId,
        approval_id: this.emailTitle == 'Accident' ? approvalId : []
      }

      this.$swal({
        title: `Apakah anda yakin ingin mengirimkan email ${this.emailTitle} ?`,
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = null
          if(this.emailTitle == 'Evaluation'){
            res = await this.sendEmailEvaluation({data:payload, id: Number(this.formId)})
          } else if(this.emailTitle == 'Accident'){
            res = await this.sendEmailAccident({data:payload, id: Number(this.formId)})
          } else if(this.emailTitle == 'Recommendation'){
            res = await this.sendEmailRecommendation({data:payload, id: Number(this.formId)})
          }

          if(res.status === 'success'){
            this.$nextTick(() => {
              this.$bvModal.hide('send-email')
            })
            this.notifMailUser = []
            this.approvalMailUser = []
            this.$swal(`Oops!`, `Email ${this.emailTitle} sended to user.`, 'success')
          } else {
            this.$swal(`Oops!`, res?.data, 'error')
          }
        } 
        // else {
        //   this.filteredExpertUsers.map(obj => {
        //     obj.selected = false
            
        //     return obj
        //   })
        // }
      })
    }
  }
}
</script>
<style>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
#sidebar-view-next *,
#sidebar-view *,
#sidebar-view >>> * {
  font-size: 18px;
}
table th {
  background: #334697;
  color: white;
}

.column-1{
  position: sticky;
  left: 0;
  min-width: 50px;
}
.column-2{
  position: sticky;
  left: 50px;
  min-width: 250px;
}
.column-3{
  position: sticky;
  left: 300px;
  min-width: 150px;
}
.overflow-x-hidden{
  overflow-x: hidden;
}
</style>

<template>
  <div v-if="detailMi">
    <b-row>
      <b-col md="12" class="mb-3">
        <h5 style="font-weight: 500;">INFORMATION OF ACCIDENT/INCIDENT</h5>
      </b-col>
      <b-col md="12">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td width="25%">Type</td>
              <td width="5%">:</td>
              <td>
                <b-button id="popover-type" variant="info" ref="button"
                  >Claim</b-button
                >
              </td>
              <td width="25%" class="pl-5">Status</td>
              <td width="5%">:</td>
              <td>
                <b-button
                  id="popover-view-status"
                  variant="primary"
                  ref="button"
                  >{{ detailMi.status.name }}</b-button
                >
              </td>
            </tr>
            <tr>
              <td width="25%">Company</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.company.company }}</strong></td
              >
              <td width="25%" class="pl-5">Name of Vessel</td>
              <td width="5%">:</td>
              <td
                ><b-badge variant="warning">{{
                  detailMi.vehicle.name
                }}</b-badge></td
              >
            </tr>
            <tr>
              <td width="25%">Type of Coverage</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.type_coverage.name }}</strong></td
              >
              <td width="25%" class="pl-5">Place of Loss</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.place_of_loss }}</strong></td
              >
            </tr>
            <tr>
              <td width="25%">Cause of Loss</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.type_loss.name }}</strong></td
              >
              <td width="25%" class="pl-5">Type of Loss</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.type_loss.name }}</strong></td
              >
            </tr>
            <tr>
              <td width="25%">Date of Loss</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.date_of_loss }}</strong></td
              >
              <td width="25%" class="pl-5">Created Form</td>
              <td width="5%">:</td>
              <td>{{ detailMi.created_at }}</td>
            </tr>
            <tr>
              <td width="25%">Remarks</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.remarks }}</strong></td
              >
              <td width="25%" class="pl-5">Cost</td>
              <td width="5%">:</td>
              <td
                ><strong>Rp.{{ detailMi.cost }}</strong></td
              >
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col md="12" class="mb-3">
        <h5 style="font-weight: 500;">CLAIMANT / NOTIFICATION DETAILS</h5>
      </b-col>
      <b-col md="12" class="mb-3">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td width="25%">Company</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.company.company }}</strong></td
              >
              <td width="25%" class="pl-5">Department/Vessel</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.department.name }}</strong></td
              >
            </tr>
            <tr>
              <td width="25%">Status</td>
              <td width="5%">:</td>
              <td
                ><strong>{{ detailMi.status.name }}</strong></td
              >
              <td width="25%" class="pl-5"></td>
              <td width="5%"></td>
              <td><strong></strong></td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col md="12" class="mb-3">
        <h5 style="font-weight: 500;">MONITORING LIST</h5>
        <div class="text-right"
          ><b-button
            type="button"
            @click="onCreate"
            class="btn btn-primary"
            variant="primary"
            v-b-toggle.add-monitoring
            ><i class="fa fa-plus"></i>Add Monitoring</b-button
          ></div
        >
      </b-col>
      <b-col md="12" class="mb-4">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>No.</th>
              <th>Actual Claim Progress</th>
              <th>Person in Charge</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Total Days</th>
              <th>Remarks</th>
              <th>Status</th>
              <th>#</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(monitoring, index) in detailMi.monitorings"
              :key="monitoring.id"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ monitoring.actual_claim_progress }}</td>
              <td>{{ monitoring.person_in_charge }}</td>
              <td>{{ monitoring.start_date }}</td>
              <td>{{ monitoring.end_date }}</td>
              <td>{{ monitoring.total_days }}</td>
              <td>{{ monitoring.remarks }}</td>
              <td>{{ monitoring.status.split('_').join(' ') }}</td>
              <td>
                <b-button
                  @click="onEdit(monitoring)"
                  variant="warning"
                  size="sm"
                  v-b-toggle.add-monitoring
                  ><i class="fa fa-edit"></i>
                </b-button>
              </td>
              <td>
                <b-button
                  @click="onDelete(monitoring)"
                  variant="danger"
                  size="sm"
                  ><i class="fa fa-trash text-white"></i>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <button @click="$router.go(-1)" variant="none" class="iq-bg-danger ml-3"
          >Back</button
        > -->
      </b-col>
    </b-row>
    <b-sidebar
      id="add-monitoring"
      title="ADD MONITORING DATA"
      width="800px"
      backdrop-variant="dark"
      header-class="bg-white"
      body-class="p-3"
      right
      backdrop
      shadow
    >
      <form @submit.prevent="onSave()">
        <b-row class="pt-2 pl-3 pr-3 pb-2 mx-0 bg-white" style="border-radius: 8px;">
          <b-col md="12" class="mb-3">
            <hr />
            <h5 class="text-center">Input Your Monitoring Data</h5>
          </b-col>
          <b-col md="12" class="mb-3">
            <b-row class="mb-4">
              <b-col md="4">Actual Claim Progress</b-col>
              <b-col md="1">:</b-col>
              <b-col md="7">
                <b-form-textarea
                  v-model="form.actual_claim_progress"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col md="4">Person in Charge</b-col>
              <b-col md="1">:</b-col>
              <b-col md="7">
                <b-form-input
                  v-model="form.person_in_charge"
                  id="serial"
                  type="text"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col md="4">Start Date</b-col>
              <b-col md="1">:</b-col>
              <b-col md="7">
                <date-picker
                  v-model="form.start_date"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                  placeholder="Select date"
                ></date-picker>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col md="4">End Date</b-col>
              <b-col md="1">:</b-col>
              <b-col md="7">
                <date-picker
                  v-model="form.end_date"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                  placeholder="Select date"
                ></date-picker>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col md="4">Status</b-col>
              <b-col md="1">:</b-col>
              <b-col md="7">
                <v-multiselect
                  v-model="form.status"
                  placeholder="Status"
                  label="name"
                  track-by="value"
                  :options="statusOptions"
                >
                  <span slot="noResult">Data not found.</span>
                </v-multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col md="4">Remarks</b-col>
              <b-col md="1">:</b-col>
              <b-col md="7">
                <b-form-textarea
                  v-model="form.remarks"
                  rows="2"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col md="12" class="text-right mt-4">
                <b-button
                  type="submit"
                  :disabled="form._saving"
                  variant="primary"
                >
                  Submit</b-button
                >
                <b-button
                  v-b-toggle.add-monitoring
                  :disabled="form._saving"
                  variant="none"
                  class="iq-bg-danger ml-3"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-sidebar>
  </div>
</template>
<script>
import { xray } from '@/src/config/pluginInit'
import { miActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  components: { StepNavigation, StepWizard },
  async mounted() {
    xray.index()
    if (!this.$route.query.formId) {
      this.$router.push({
        name: 'mi.listInitial',
      })
    }
    // await this.getIncidents()
  },
  props: {
    detailMi: null,
  },
  data() {
    return {
      permission_user: JSON.parse(localStorage.getItem('profile')).role.roles,
      form: {
        _saving: false,
        actual_claim_progress: '',
        person_in_charge: '',
        start_date: '',
        end_date: '',
        status: '',
        remarks: '',
      },
      statusOptions: [
        {
          value: 'done',
          name: 'Done',
        },
        {
          value: 'working_on_it',
          name: 'Working on it',
        },
        {
          value: 'waiting_time',
          name: 'waiting time (barriers/stuck)',
        },
      ],
    }
  },
  computed: {
    userPermission() {
      if (
        [
          'SUPER ADMIN',
          'MARINE INSURANCE',
          'MARINE INSURANCE ADMIN',
          'MARINE INSURANCE MANAGER',
        ].includes(this.permission_user)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...miActions,
    // async getIncidents() {
    //   const { data, status } = await this.fetchDetailMi({
    //     id: this.$route.query.formId,
    //     with: 'type_loss,vehicle,type_coverage,company,department,status,monitorings',
    //   })
    //   if (status === 'success') {
    //     this.detailMi = data
    //     this.monitoringList = data.monitorings
    //   } else {
    //     this.$router.push({
    //       name: 'mi.listInitial',
    //     })
    //   }
    // },
    onCreate() {
      this.form = {
        _saving: false,
        actual_claim_progress: '',
        person_in_charge: '',
        start_date: '',
        end_date: '',
        status: null,
        remarks: '',
      }
    },
    async onSave() {
      let payload = { ...this.form }
      payload.status = payload.status.value
      payload.form_id = this.$route.query.formId

      this.form._saving = true

      let status
      if (!payload.id) {
        status = (await this.saveMonitoring(payload)).status
      } else {
        status = (await this.editMonitoring(payload)).status
      }

      if (status !== 'success') {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.form._saving = false
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      this.getIncidents()
      this.onCreate()

      this.form._saving = false
    },
    onEdit(monitoring) {
      this.form = {
        id: monitoring.id,
        _saving: false,
        actual_claim_progress: monitoring.actual_claim_progress,
        person_in_charge: monitoring.person_in_charge,
        start_date: monitoring.start_date,
        end_date: monitoring.end_date,
        status: this.statusOptions.find(
          (stat) => stat.value === monitoring.status
        ),
        remarks: monitoring.remarks,
      }
    },
    async onDelete(monitoring) {
      this.$swal({
        title: 'Delete Monitoring?',
        text: `Monitoring for ${monitoring.actual_claim_progress} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.form._saving = true
          let res = await this.deleteMonitoring({
            form_id: this.$route.query.formId,
            id: monitoring.id,
          })
          if (res.status !== 'success') {
            this.form._saving = false
            if (res.data.message !== null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get Monitoring data.',
                'error'
              )
            }
          } else {
            this.form._saving = false
            this.getIncidents()
            this.$swal(
              `Monitoring deleted!`,
              `Monitoring for ${monitoring.actual_claim_progress} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
  },
}
</script>

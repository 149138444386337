var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('CertificateDashboard'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Daily Report Fleet Position & Progress")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$filterDailyOps$pi;
        return [_c('b-modal', {
          attrs: {
            "id": "daily-report",
            "centered": "",
            "size": "lg",
            "title": "Filter Data",
            "ok-title": "Filter"
          },
          on: {
            "ok": function ok($event) {
              return _vm.fetchDailyOps();
            }
          },
          model: {
            value: _vm.modal.filter,
            callback: function callback($$v) {
              _vm.$set(_vm.modal, "filter", $$v);
            },
            expression: "modal.filter"
          }
        }, [_c('b-row', {
          staticClass: "px-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          model: {
            value: _vm.filterDailyOps.date,
            callback: function callback($$v) {
              _vm.$set(_vm.filterDailyOps, "date", $$v);
            },
            expression: "filterDailyOps.date"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show ")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Project Name",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.projectOptions,
            "multiple": true,
            "group-values": "sub",
            "group-label": "project",
            "group-select": true,
            "placeholder": "Select one",
            "track-by": "name",
            "label": "name"
          },
          model: {
            value: _vm.filterDailyOps.subProject,
            callback: function callback($$v) {
              _vm.$set(_vm.filterDailyOps, "subProject", $$v);
            },
            expression: "filterDailyOps.subProject"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Ownership",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.categoryOwnership,
            "id": "gender"
          },
          model: {
            value: _vm.filterDailyOps.ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.filterDailyOps, "ownership", $$v);
            },
            expression: "filterDailyOps.ownership"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "filter-options",
            "label": "Filter Options",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.filterOptions,
            "id": "filtet-options"
          },
          model: {
            value: _vm.filterOpionSelected,
            callback: function callback($$v) {
              _vm.filterOpionSelected = $$v;
            },
            expression: "filterOpionSelected"
          }
        })], 1)], 1), _vm.filterOpionSelected == 'category' ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Fleet Category",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.vehicle,
            "multiple": true,
            "track-by": "value",
            "placeholder": "Select one",
            "label": "text",
            "value": "value"
          },
          model: {
            value: _vm.filterDailyOps.vehicleTypeIds,
            callback: function callback($$v) {
              _vm.$set(_vm.filterDailyOps, "vehicleTypeIds", $$v);
            },
            expression: "filterDailyOps.vehicleTypeIds"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1) : _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "pic",
            "label": "PIC",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.optionsUsers,
            "placeholder": "Select one",
            "track-by": "value",
            "label": "text"
          },
          model: {
            value: _vm.filterDailyOps.pic,
            callback: function callback($$v) {
              _vm.$set(_vm.filterDailyOps, "pic", $$v);
            },
            expression: "filterDailyOps.pic"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1)], 1)], 1), _c('div', {}, [_c('b-row', [_vm.findMenuPermission() ? _c('b-col', {
          staticClass: "d-flex justify-content-between",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.daily-report",
            modifiers: {
              "daily-report": true
            }
          }],
          staticClass: "mb-3 mr-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.modal.filter = true;
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _vm._v(" Filter Data ")])], 1), _c('div', [_c('b-button', {
          staticClass: "mb-3 mr-2",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_vm._v(" Import Data "), _c('i', {
          staticClass: "fas fa-download"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.exportDailyOps
          }
        }, [_vm._v(" Export Data "), _c('i', {
          staticClass: "fas fa-upload"
        })])], 1)]) : _vm._e()], 1), _c('div', {
          staticClass: "mb-3",
          staticStyle: {
            "padding": "0 14px"
          }
        }, [_c('b-row', {
          staticClass: "border rounded-md pt-2"
        }, [_vm.filterDailyOps.date ? _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Date :"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(_vm.filterDailyOps.date ? _vm.moment(_vm.filterDailyOps.date).format("DD MMMM YYYY") : '-'))])])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ownership :"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(_vm.filterDailyOps.ownership || "All"))])])], 1), _vm.filterDailyOps.subProject.length ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Project Name :"
          }
        }, [[_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, _vm._l(_vm.filterDailyOps.subProject, function (project, index) {
          return _c('span', {
            key: index
          }, [_vm._v(_vm._s(project.name) + _vm._s(index + 1 !== _vm.filterDailyOps.subProject.length ? ', ' : ''))]);
        }), 0)]], 2)], 1) : _vm._e(), _vm.filterOpionSelected == 'category' && _vm.filterDailyOps.vehicleTypeIds.length ? _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category :"
          }
        }, [_vm.filterDailyOps.vehicleTypeIds.length ? _vm._l(_vm.filterDailyOps.vehicleTypeIds, function (vehicle, index) {
          return _c('b-badge', {
            key: index,
            staticClass: "mr-1",
            attrs: {
              "variant": "success"
            }
          }, [_vm._v(_vm._s(vehicle.text))]);
        }) : _c('div', [_vm._v("-")])], 2)], 1) : [_vm.filterDailyOps.pic ? _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "PIC"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s((_vm$filterDailyOps$pi = _vm.filterDailyOps.pic.text) !== null && _vm$filterDailyOps$pi !== void 0 ? _vm$filterDailyOps$pi : '-'))])])], 1) : _vm._e()]], 2)], 1), _c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "position"
          }
        }, [_c('div', {
          staticClass: "overflow-auto d-flex flex-column",
          staticStyle: {
            "max-height": "600px"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center sticky-top",
          staticStyle: {
            "z-index": "1"
          }
        }, [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Start Date")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("End Date")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Current Position")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Port Loading")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Port Discharge")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Agent POL")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Agent POD")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("PIC / PE")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Departure")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("ETA")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Update Today")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Reason")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Idle (Hours)")]), _c('th', {
          staticClass: "text-truncate",
          staticStyle: {
            "min-width": "300px"
          },
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Remarks")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Document on Board")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Cargo Loading")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("GPS")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Speed")])])]), _c('tbody', [_vm._l(_vm.dailyOpsData, function (position, index) {
          return [_vm.dailyOpsData.length > 0 ? [_c('tr', {
            key: "pos-".concat(index)
          }, [_c('td', {
            attrs: {
              "colspan": "22"
            }
          }, [_c('h5', [_c('strong', [_vm._v(_vm._s(position.projectSub.project.projectName) + " - " + _vm._s(position.projectSub.name))])])])]), _vm._l(position.voyage, function (project, idx) {
            var _vm$filterDailyOps;
            return [position.voyage ? _c('tr', {
              key: "proj-".concat(index).concat(idx)
            }, [_c('td', [_vm._v(_vm._s(idx + 1))]), _c('td', {
              staticClass: "text-truncate"
            }, _vm._l(project.vehicle, function (each, i) {
              return _c('span', {
                key: i
              }, [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v(_vm._s(each.name))]), _vm._v("  ")], 1);
            }), 0), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.moment(project.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm')))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.moment(project.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm')))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.currentPosition))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.portLoading ? project.portLoading.portName : '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.portDischarge ? project.portDischarge.portName : '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.agentPol || '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.agentPod || '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, _vm._l(project.pic, function (each, i) {
              return _c('span', {
                key: i
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(_vm._s(each.name))]), _vm._v("  ")], 1);
            }), 0), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.moment(project.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm')))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.arrival ? _vm.moment(project.arrival, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.formatDate((_vm$filterDailyOps = _vm.filterDailyOps) === null || _vm$filterDailyOps === void 0 ? void 0 : _vm$filterDailyOps.date) || '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.reason ? project.reason.name : '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.activityDurationFormat || '-'))]), _c('td', [_vm._v(_vm._s(project.remark || '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.docOnBoard ? _vm.moment(project.docOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(_vm.numberFormat(project.cargoLoading) || '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.dmsLatitude || '-') + ", " + _vm._s(project.dmsLongitude || '-'))]), _c('td', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(project.speed || '-'))])]) : _vm._e()];
          })] : _vm._e()];
        })], 2)])]), _vm.dailyOpsData.length <= 0 ? _c('div', {
          staticClass: "d-flex align-items-center justify-content-center w-100 mt-2"
        }, [_c('strong', {
          staticClass: "text-muted"
        }, [_vm._v("No Data.")])]) : _vm._e()]), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "repair",
            "aria-labelled-by": "profile-tab"
          }
        }, [_c('div', {
          staticClass: "overflow-auto d-flex flex-column tableFixHead"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Current Position")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Remarks")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Arrival")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Update Today / On Going Repair")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Stoppage Time (Hours)")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Est Complete Repair")])])]), _c('tbody', _vm._l(_vm.idleRepair, function (repair, index) {
          return _c('tr', {
            key: "rep-".concat(index)
          }, [_c('td', [_vm._v(_vm._s(repair.no))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(repair.fleet))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(repair.currentPosition))]), _c('td', [_vm._v(_vm._s(repair.remarks))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(repair.arrival))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(repair.onGoingRepair))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(repair.StoppageTime))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(repair.completeRepair))])]);
        }), 0)])])]), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "docking",
            "aria-labelled-by": "contact-tab"
          }
        }, [_c('div', {
          staticClass: "table-responsive tableFixHead"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover",
          staticStyle: {
            "width": "1800px"
          }
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "10%"
          }
        }, [_vm._v("Fleet")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "15%"
          }
        }, [_vm._v("Position")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Preedocking")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Up Slip")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Waiting Up Slip (H)")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Date")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Docking Progress (Hours)")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Down Slip")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Waiting Down Slip (H)")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Completed Docking")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Progress %")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "25%"
          }
        }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.shipyard, function (shipyard, index) {
          return _c('tr', {
            key: "ship-".concat(index)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.no))]), _c('td', [_vm._v(_vm._s(shipyard.fleet))]), _c('td', [_vm._v(_vm._s(shipyard.position))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.predocking))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.upSlip))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(shipyard.waitingUpSlip) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.updateToday))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(shipyard.dockingProgress) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.downSlip))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(shipyard.waitingDownSlip) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.completed))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.progress))]), _c('td', [_vm._v(_vm._s(shipyard.remarks))])]);
        }), 0)])])])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Operational Fleet Matrix")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$filterMonthlyOps$;
        return [_c('div', [_c('b-modal', {
          attrs: {
            "centered": "",
            "size": "lg",
            "title": "Filter Data",
            "ok-title": "Filter"
          },
          on: {
            "ok": function ok($event) {
              return _vm.fetchMonthlyOps();
            }
          },
          model: {
            value: _vm.modal.filterMonthly,
            callback: function callback($$v) {
              _vm.$set(_vm.modal, "filterMonthly", $$v);
            },
            expression: "modal.filterMonthly"
          }
        }, [_c('b-row', {
          staticClass: "px-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Range Filter",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('date-picker', {
          staticClass: "w-100",
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "format": "YYYY-MM-DD",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.filterMonthlyOps.dateRange,
            callback: function callback($$v) {
              _vm.$set(_vm.filterMonthlyOps, "dateRange", $$v);
            },
            expression: "filterMonthlyOps.dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Project Name",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.projectOptions,
            "multiple": true,
            "group-values": "sub",
            "group-label": "project",
            "group-select": true,
            "placeholder": "Type to search",
            "track-by": "name",
            "label": "name"
          },
          model: {
            value: _vm.filterMonthlyOps.subProject,
            callback: function callback($$v) {
              _vm.$set(_vm.filterMonthlyOps, "subProject", $$v);
            },
            expression: "filterMonthlyOps.subProject"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Ownership",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.categoryOwnership,
            "id": "gender"
          },
          model: {
            value: _vm.filterMonthlyOps.ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.filterMonthlyOps, "ownership", $$v);
            },
            expression: "filterMonthlyOps.ownership"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "filter-options",
            "label": "Filter Options",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.filterOptions,
            "id": "filtet-options"
          },
          model: {
            value: _vm.filterOpionSelectedMonthly,
            callback: function callback($$v) {
              _vm.filterOpionSelectedMonthly = $$v;
            },
            expression: "filterOpionSelectedMonthly"
          }
        })], 1)], 1), _vm.filterOpionSelectedMonthly == 'category' ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "kind-fleet",
            "label": "Fleet Category",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.vehicle,
            "multiple": true,
            "track-by": "value",
            "placeholder": "Select one",
            "label": "text",
            "value": "value",
            "id": "kind-fleet"
          },
          model: {
            value: _vm.filterMonthlyOps.vehicleTypeIds,
            callback: function callback($$v) {
              _vm.$set(_vm.filterMonthlyOps, "vehicleTypeIds", $$v);
            },
            expression: "filterMonthlyOps.vehicleTypeIds"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1) : _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "pic",
            "label": "PIC",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.optionsUsers,
            "placeholder": "Select one",
            "track-by": "value",
            "label": "text"
          },
          model: {
            value: _vm.filterMonthlyOps.pic,
            callback: function callback($$v) {
              _vm.$set(_vm.filterMonthlyOps, "pic", $$v);
            },
            expression: "filterMonthlyOps.pic"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex justify-content-between align-items-center mb-3"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.modal.filterMonthly = true;
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _vm._v(" Filter Data ")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.exportMonthlyOps
          }
        }, [_vm._v(" Export Data "), _c('i', {
          staticClass: "fas fa-upload"
        })])], 1), _c('div', {
          staticClass: "mb-3",
          staticStyle: {
            "padding": "0 14px"
          }
        }, [_c('b-row', {
          staticClass: "border rounded-md pt-2"
        }, [_vm.filterMonthlyOps.dateRange.length ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period :"
          }
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(_vm.filterMonthlyOps.dateRange[0] ? _vm.moment(_vm.filterMonthlyOps.dateRange[0]).format("DD MMMM YYYY") : '') + " - "), _c('span', [_vm._v(_vm._s(_vm.filterMonthlyOps.dateRange[1] ? _vm.moment(_vm.filterMonthlyOps.dateRange[1]).format("DD MMMM YYYY") : ''))])])])])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ownership :"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(_vm.filterMonthlyOps.ownership || "All"))])])], 1), _vm.filterMonthlyOps.subProject.length ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Project Name :"
          }
        }, [_vm.filterMonthlyOps.subProject.length ? [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, _vm._l(_vm.filterMonthlyOps.subProject, function (project, index) {
          return _c('span', {
            key: index
          }, [_vm._v(_vm._s(project.name) + _vm._s(index + 1 !== _vm.filterMonthlyOps.subProject.length ? ', ' : ''))]);
        }), 0)] : _c('div', [_vm._v("-")])], 2)], 1) : _vm._e(), _vm.filterOpionSelectedMonthly == 'category' && _vm.filterMonthlyOps.vehicleTypeIds.length ? _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category :"
          }
        }, [_vm.filterMonthlyOps.vehicleTypeIds.length ? _vm._l(_vm.filterMonthlyOps.vehicleTypeIds, function (vehicle, index) {
          return _c('b-badge', {
            key: index,
            staticClass: "mr-1",
            attrs: {
              "variant": "success"
            }
          }, [_vm._v(_vm._s(vehicle.text))]);
        }) : _vm._e()], 2)], 1) : [_vm.filterMonthlyOps.pic ? _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "PIC"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-black",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s((_vm$filterMonthlyOps$ = _vm.filterMonthlyOps.pic.text) !== null && _vm$filterMonthlyOps$ !== void 0 ? _vm$filterMonthlyOps$ : '-'))])])], 1) : _vm._e()]], 2)], 1), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_vm.loadingOpsMatrix ? _c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm._l(_vm.opsMatrix.matrix, function (matrix, index) {
          return [_c('b-card', {
            key: "mat-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'matrix' + matrix.projectSub.id,
              expression: "'matrix' + matrix.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer",
              "text-transform": "uppercase",
              "font-weight": "bold"
            }
          }, [_vm._v(" " + _vm._s(matrix.projectSub.project.projectName) + " - " + _vm._s(matrix.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'matrix' + matrix.projectSub.id,
              "visible": matrix.visibility,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_vm._l(matrix.vehicleTypes, function (category, index2) {
            return [_c('b-card', {
              key: "mt2-".concat(index2),
              staticClass: "mb-1",
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: 'category' + index2,
                expression: "'category' + index2"
              }],
              staticClass: "p-1",
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('h4', {
              staticClass: "p-3",
              staticStyle: {
                "cursor": "pointer"
              }
            }, [_vm._v(" " + _vm._s(category.vehicleType.name) + " ")])]), _c('b-collapse', {
              attrs: {
                "id": 'category' + index2,
                "visible": "",
                "role": "tabpanel"
              }
            }, [_c('b-card-body', [_c('b-card-text', [category.vehicles.vehicle !== 'he' ? _c('div', {
              staticClass: "table-responsive"
            }, [_c('table', {
              staticClass: "table mb-0 table-striped table-hover",
              staticStyle: {
                "width": "3000px"
              }
            }, [_c('thead', {
              staticClass: "text-center"
            }, [_c('tr', {
              staticClass: "bg-primary",
              staticStyle: {
                "text-transform": "uppercase"
              }
            }, [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Date")]), category.vehicles[0] ? _vm._l(category.vehicles[0].activities, function (activity, i) {
              return _c('th', {
                key: i,
                staticClass: "text-truncate"
              }, [_vm._v(_vm._s(activity.name))]);
            }) : _vm._e()], 2)]), _c('tbody', _vm._l(category.vehicles, function (ops, idx) {
              var _vm$filterMonthlyOps, _vm$filterMonthlyOps2;
              return _c('tr', {
                key: "cat-".concat(idx)
              }, [_c('td', {
                staticClass: "text-center"
              }, [_vm._v(" " + _vm._s(idx + 1) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(_vm._s(ops.vehicle.name))]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(_vm._s(_vm.formatDate((_vm$filterMonthlyOps = _vm.filterMonthlyOps) === null || _vm$filterMonthlyOps === void 0 ? void 0 : _vm$filterMonthlyOps.dateRange[0])) + " - " + _vm._s(_vm.formatDate((_vm$filterMonthlyOps2 = _vm.filterMonthlyOps) === null || _vm$filterMonthlyOps2 === void 0 ? void 0 : _vm$filterMonthlyOps2.dateRange[1])))]), _vm._l(ops.activities, function (activity, i) {
                return _c('td', {
                  key: i,
                  staticClass: "text-center text-truncate"
                }, [_vm._v(" " + _vm._s(activity.durationFormat) + " ")]);
              })], 2);
            }), 0)])]) : _vm._e()])], 1)], 1)], 1)];
          })], 2)], 1)], 1)];
        }), !_vm.opsMatrix.matrix.length ? _c('p', {
          staticClass: "m-0"
        }, [_vm._v("No data available")]) : _vm._e()]], 2)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Operational Statistic")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loadingOpsMatrix ? _c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_c('tab-nav', {
          attrs: {
            "tabs": true
          }
        }, [_c('tab-nav-items', {
          attrs: {
            "active": true,
            "id": "percent-tab",
            "ariaControls": "percent",
            "role": "tab",
            "ariaSelected": true,
            "title": "Based on Percentage"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "id": "hours-tab",
            "ariaControls": "hours",
            "role": "tab",
            "ariaSelected": false,
            "title": "Based on Hours"
          }
        })], 1), _c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "percent"
          }
        }, [_vm._l(_vm.opsMatrix.matrix, function (percentage, index) {
          return [_c('b-card', {
            key: "per-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'percentage' + percentage.projectSub.id,
              expression: "'percentage' + percentage.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_vm._v(" " + _vm._s(percentage.projectSub.project.projectName) + " - " + _vm._s(percentage.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'percentage' + percentage.projectSub.id,
              "visible": percentage.visibility,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_vm._l(percentage.vehicleTypes, function (category, index2) {
            return [_c('b-card', {
              key: "per2-".concat(index2),
              staticClass: "mb-1",
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: 'category' + index2,
                expression: "'category' + index2"
              }],
              staticClass: "p-1",
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('h4', {
              staticClass: "p-3",
              staticStyle: {
                "cursor": "pointer"
              }
            }, [_vm._v(" " + _vm._s(category.vehicleType.name) + " ")])]), _c('b-collapse', {
              attrs: {
                "id": 'category' + index2,
                "visible": "",
                "role": "tabpanel"
              }
            }, [_c('b-card-body', [percentage.TypeVehicleOpt ? _c('b-form-group', {
              attrs: {
                "label": 'Filter Chart ' + category.vehicleType.name
              }
            }, [_c('v-multiselect', {
              attrs: {
                "tag-placeholder": "Category",
                "placeholder": "Search or statistic",
                "label": "name",
                "track-by": "name",
                "options": percentage.TypeVehicleOpt,
                "multiple": true,
                "taggable": true
              },
              on: {
                "input": function input($event) {
                  return _vm.onChange($event, index, index2);
                }
              },
              model: {
                value: category.bodyData.data,
                callback: function callback($$v) {
                  _vm.$set(category.bodyData, "data", $$v);
                },
                expression: "category.bodyData.data"
              }
            })], 1) : _vm._e(), _c('b-card-text', [_c('AmChart', {
              attrs: {
                "element": "chart-".concat(percentage.projectSub.id, "-").concat(index, "-").concat(index2, "-").concat(category.bodyData.type),
                "type": category.bodyData.type,
                "option": category.bodyData
              }
            })], 1)], 1)], 1)], 1)];
          })], 2)], 1)], 1)];
        })], 2), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "hours",
            "aria-labelled-by": "hours-tab"
          }
        }, [_vm._l(_vm.opsMatrix.matrix, function (hours, index) {
          return [_c('b-card', {
            key: "stat-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'hours' + hours.projectSub.id,
              expression: "'hours' + hours.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_vm._v(" " + _vm._s(hours.projectSub.project.projectName) + " - " + _vm._s(hours.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'hours' + hours.projectSub.id,
              "visible": hours.visibility,
              "accordion": "my-accordion",
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_vm._l(hours.vehicleTypes, function (category, index2) {
            return [_c('b-card', {
              key: "stat-h-".concat(index2),
              staticClass: "mb-1",
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: "category-".concat(hours.projectSub.id, "=").concat(index2),
                expression: "\n                                    `category-${hours.projectSub.id}=${index2}`\n                                  "
              }],
              staticClass: "p-1",
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('h4', {
              staticClass: "p-3",
              staticStyle: {
                "cursor": "pointer"
              }
            }, [_vm._v(" " + _vm._s(category.vehicleType.name) + " ")])]), _c('b-collapse', {
              attrs: {
                "id": "category-".concat(hours.projectSub.id, "=").concat(index2),
                "visible": "",
                "role": "tabpanel"
              }
            }, [_c('b-card-body', [_c('b-form-group', {
              attrs: {
                "label": 'Filter Chart ' + category.vehicleType.name
              }
            }, [_c('v-multiselect', {
              attrs: {
                "tag-placeholder": "Category",
                "placeholder": "Search or statistic",
                "options": category.bodyData.data.map(function (f) {
                  return f.name;
                }),
                "multiple": true,
                "taggable": true
              },
              on: {
                "input": function input($event) {
                  return _vm.onChangeBarChart($event, index, index2);
                }
              },
              model: {
                value: category.opsChart.xaxis.categories,
                callback: function callback($$v) {
                  _vm.$set(category.opsChart.xaxis, "categories", $$v);
                },
                expression: "\n                                          category.opsChart.xaxis.categories\n                                        "
              }
            })], 1), _c('b-card-text', [!category.loadingChart ? _c('ApexChart', {
              attrs: {
                "element": "chart-hours-".concat(hours.projectSub.id, "-").concat(index, "-").concat(index2, "-").concat(category.bodyData.type),
                "chartOption": category.opsChart
              }
            }) : _vm._e()], 1)], 1)], 1)], 1)];
          })], 2)], 1)], 1)];
        })], 2)], 1)]];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Trip & Cargo")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_vm._l(_vm.opsMatrix.tripCargo, function (cargo, index) {
          return [_c('b-card', {
            key: "trip-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'cargo' + cargo.projectSub.id,
              expression: "'cargo' + cargo.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_vm._v(" " + _vm._s(cargo.projectSub.project.projectName) + " - " + _vm._s(cargo.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'cargo' + cargo.projectSub.id,
              "visible": cargo.visibility,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
            staticClass: "table-responsive"
          }, [_c('table', {
            staticClass: "table mb-0 table-striped table-hover"
          }, [_c('thead', {
            staticClass: "thead-dark text-center"
          }, [_c('tr', [_c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("No")]), _c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("Fleet")]), _c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("Date")]), _c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("Trip Status")]), _c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("Cargo Status")])])]), _c('tbody', [_vm._l(cargo.trip, function (trip, index2) {
            var _vm$filterMonthlyOps3, _vm$filterMonthlyOps4;
            return _c('tr', {
              key: "trip-d-".concat(index2)
            }, [_c('td', [_vm._v(_vm._s(index2 + 1))]), _c('td', _vm._l(trip.vehicle, function (each, index3) {
              return _c('span', {
                key: "fleet-".concat(index3)
              }, [_vm._v(" " + _vm._s(index3 > 0 ? ' / ' + each.name : each.name) + " ")]);
            }), 0), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(_vm.formatDate((_vm$filterMonthlyOps3 = _vm.filterMonthlyOps) === null || _vm$filterMonthlyOps3 === void 0 ? void 0 : _vm$filterMonthlyOps3.dateRange[0])) + " - " + _vm._s(_vm.formatDate((_vm$filterMonthlyOps4 = _vm.filterMonthlyOps) === null || _vm$filterMonthlyOps4 === void 0 ? void 0 : _vm$filterMonthlyOps4.dateRange[1])))]), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(trip.tripStatus))]), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(trip.cargoStatus))])]);
          }), _c('tr', [_c('td', {
            staticClass: "text-right font-weight-bold",
            attrs: {
              "colspan": "3"
            }
          }, [_vm._v("Total")]), _c('td', {
            staticClass: "text-center font-weight-bold"
          }, [_vm._v(_vm._s(_vm.numberFormat(cargo.totalTripStatus)))]), _c('td', {
            staticClass: "text-center font-weight-bold"
          }, [_vm._v(_vm._s(_vm.numberFormat(cargo.totalCargoStatus)))])])], 2)])])])], 1)], 1)], 1)];
        })], 2)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$projectData, _vm$projectData$proje, _vm$projectData2, _vm$paymentData, _vm$paymentData$proje, _vm$projectPlan, _vm$projectPlan2, _vm$projectPlan$fleet, _vm$projectPlan3, _vm$projectPlan3$carg, _vm$projectPlan4, _vm$projectPlan5, _vm$projectPlan6, _vm$projectPlan6$term, _vm$projectPlan7, _vm$projectPlan8, _vm$projectPlan9, _vm$projectPlan10, _vm$projectPlan11, _attrs, _vm$paymentData2, _vm$paymentData3, _vm$paymentData4, _vm$paymentData5, _vm$paymentData6, _vm$paymentData7, _vm$paymentData8;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSavePaymentList.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" " + _vm._s(['EDIT'].includes(_vm.action) ? 'Update' : 'Create') + " Payment List  ")]) : _vm._e(), _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideFormPayment
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Cancel  ")])], 1), _c('b-col', {
    staticClass: "p-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [((_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : (_vm$projectData$proje = _vm$projectData.project_contract_plan) === null || _vm$projectData$proje === void 0 ? void 0 : _vm$projectData$proje.length) > 0 ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*For Trip"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('v-select', {
    attrs: {
      "label": "shipment_plan_detail_number",
      "track-by": "id",
      "name": "plan_id",
      "options": (_vm$projectData2 = _vm.projectData) === null || _vm$projectData2 === void 0 ? void 0 : _vm$projectData2.project_contract_plan,
      "reduce": function reduce(plan) {
        return plan.id;
      },
      "placeholder": "Select...",
      "required": ""
    },
    on: {
      "input": _vm.getProjectPlanById
    },
    model: {
      value: _vm.form.plan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "plan_id", $$v);
      },
      expression: "form.plan_id"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s((_vm$paymentData = _vm.paymentData) === null || _vm$paymentData === void 0 ? void 0 : (_vm$paymentData$proje = _vm$paymentData.project_plan) === null || _vm$paymentData$proje === void 0 ? void 0 : _vm$paymentData$proje.shipment_plan_detail_number))]) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.plan_id ? [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }), _vm.projectPlan ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project Plan")]), _c('div', {
    staticClass: "d-flex w-100 overflow-auto"
  }, [_c('table', {
    staticClass: "table table-bordered table-sm table-responsive"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center text-truncate align-middle",
    staticStyle: {
      "width": "5px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*SHIPMENT PLAN DETAIL NUMBER")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*DESCRIPTION")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "colspan": !_vm.projectData.third_party ? '3' : '2'
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("DRAUGHT TYPE")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("LOCATION")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*SHIPMENT MONTH")]), _c('th', {
    staticClass: "text-center text-truncate",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PLAN DATE")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*PLAN QUANTITY")]), _c('th', {
    staticClass: "text-center text-truncate align-middle",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("*REMARKS")])]), _c('tr', [_c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*OWNERSHIP")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*TYPE")]), !_vm.projectData.third_party ? _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*FLEETS")]) : _vm._e(), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*TYPE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*ACC. DATE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*TERMS")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*POL")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*POD")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*ETA POL")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("*ETA POD")])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center bg-white text-black"
  }), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan = _vm.projectPlan) === null || _vm$projectPlan === void 0 ? void 0 : _vm$projectPlan.shipment_plan_detail_number))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan2 = _vm.projectPlan) === null || _vm$projectPlan2 === void 0 ? void 0 : _vm$projectPlan2.description))]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s(_vm.projectPlan.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED'))])], 1), _c('td', {
    staticClass: "text-truncate"
  }, [_c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s((_vm$projectPlan$fleet = _vm.projectPlan.fleet_type) === null || _vm$projectPlan$fleet === void 0 ? void 0 : _vm$projectPlan$fleet.name))])], 1), !_vm.projectData.third_party ? _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._l(_vm.projectPlan.fleets, function (objDetail, detailIndex) {
    return [objDetail.vehicle_id ? _c('b-badge', {
      key: detailIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(_vm.showVehicleName(objDetail === null || objDetail === void 0 ? void 0 : objDetail.vehicle_name)))]) : _vm._e()];
  })], 2)]) : _vm._e(), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan3 = _vm.projectPlan) === null || _vm$projectPlan3 === void 0 ? void 0 : (_vm$projectPlan3$carg = _vm$projectPlan3.cargo_type) === null || _vm$projectPlan3$carg === void 0 ? void 0 : _vm$projectPlan3$carg.name))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan4 = _vm.projectPlan) !== null && _vm$projectPlan4 !== void 0 && _vm$projectPlan4.acceptance_date ? _vm.moment((_vm$projectPlan5 = _vm.projectPlan) === null || _vm$projectPlan5 === void 0 ? void 0 : _vm$projectPlan5.acceptance_date).format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v(_vm._s((_vm$projectPlan6 = _vm.projectPlan) === null || _vm$projectPlan6 === void 0 ? void 0 : (_vm$projectPlan6$term = _vm$projectPlan6.terms) === null || _vm$projectPlan6$term === void 0 ? void 0 : _vm$projectPlan6$term.name))]), _c('span', [_vm._v("Remarks: " + _vm._s((_vm$projectPlan7 = _vm.projectPlan) === null || _vm$projectPlan7 === void 0 ? void 0 : _vm$projectPlan7.terms_remarks))])])]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.capitalizeString((_vm$projectPlan8 = _vm.projectPlan) === null || _vm$projectPlan8 === void 0 ? void 0 : _vm$projectPlan8.draught_type)))]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex w-100"
  }, _vm._l(_vm.projectPlan.pol, function (obj, polIndex) {
    var _obj$value;
    return _c('b-badge', {
      key: polIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(" " + _vm._s((obj === null || obj === void 0 ? void 0 : obj.value) === "Others" ? obj.valueOther : (_obj$value = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value !== void 0 ? _obj$value : '-') + " ")]);
  }), 1)]), _c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex w-100"
  }, _vm._l(_vm.projectPlan.pod, function (obj, podIndex) {
    var _obj$value2;
    return _c('b-badge', {
      key: podIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(" " + _vm._s((obj === null || obj === void 0 ? void 0 : obj.value) === "Others" ? obj.valueOther : (_obj$value2 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value2 !== void 0 ? _obj$value2 : '-') + " ")]);
  }), 1)]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan9 = _vm.projectPlan) === null || _vm$projectPlan9 === void 0 ? void 0 : _vm$projectPlan9.shipment))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.projectPlan.eta_pol ? _vm.moment(_vm.projectPlan.eta_pol).format('DD MMM YYYY') : '-'))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.projectPlan.eta_pod ? _vm.moment(_vm.projectPlan.eta_pod).format('DD MMM YYYY') : '-'))]), _c('td', {
    staticClass: "text-truncate text-right"
  }, [_vm._v(_vm._s((_vm$projectPlan10 = _vm.projectPlan) === null || _vm$projectPlan10 === void 0 ? void 0 : _vm$projectPlan10.plan_quantity.toLocaleString()))]), _c('td', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$projectPlan11 = _vm.projectPlan) === null || _vm$projectPlan11 === void 0 ? void 0 : _vm$projectPlan11.remarks))])])])])])])]) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Request Name"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "required": "",
      "placeholder": "Type Text",
      "type": "text"
    },
    model: {
      value: _vm.form.request_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "request_name", $$v);
      },
      expression: "form.request_name"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.paymentData.request_name))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Request Date"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: (_attrs = {
      "required": "",
      "type": "date"
    }, _attrs["required"] = "", _attrs),
    model: {
      value: _vm.form.request_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "request_date", $$v);
      },
      expression: "form.request_date"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.moment(_vm.paymentData.request_date).format('DD MMMM YYYY')))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Real Pricing"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "required": "",
      "placeholder": "0",
      "type": "number"
    },
    model: {
      value: _vm.form.real_pricing,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "real_pricing", $$v);
      },
      expression: "form.real_pricing"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.real_pricing)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.real_pricing)))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Balance"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "0",
      "type": "number"
    },
    model: {
      value: _vm.form.balance,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "balance", $$v);
      },
      expression: "form.balance"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.balance)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.balance)))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice Price"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    on: {
      "change": function change() {
        _vm.onChangeInvoicePrice();
        _vm.countTaxTotal();
      }
    },
    model: {
      value: _vm.form.invoice_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "invoice_price", $$v);
      },
      expression: "form.invoice_price"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.invoice_price)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.invoice_price)))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Use Tax"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-checkbox', {
    attrs: {
      "id": "use_tax",
      "label": "Yes / No",
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.use_tax,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "use_tax", $$v);
      },
      expression: "form.use_tax"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.paymentData.use_tax ? 'Yes' : 'No'))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Deductions?"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-checkbox', {
    attrs: {
      "id": "is_deduction",
      "label": "Yes / No",
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.is_deduction,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_deduction", $$v);
      },
      expression: "form.is_deduction"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.paymentData.is_deduction ? 'Yes' : 'No'))]) : _vm._e()], 1)], 1), _vm.form.use_tax ? [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax Name"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "type": "text"
    },
    model: {
      value: _vm.form.name_tax,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name_tax", $$v);
      },
      expression: "form.name_tax"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.paymentData.name_tax))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax Percentage (%)"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0.00",
      "type": "number",
      "step": "0.001"
    },
    on: {
      "change": function change() {
        _vm.countTaxTotal();
      }
    },
    model: {
      value: _vm.form.tax_percentage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tax_percentage", $$v);
      },
      expression: "form.tax_percentage"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(parseFloat(_vm.paymentData.tax_percentage).toLocaleString()) + "%")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax Total"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    model: {
      value: _vm.form.tax_total,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tax_total", $$v);
      },
      expression: "form.tax_total"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.tax_total)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.tax_total)))]) : _vm._e()], 1)], 1)] : _vm._e(), _vm.form.is_deduction ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 align-items-center justify-content-between mb-2"
  }, [_c('h6', [_c('strong', [_vm._v("Deduction")])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-button', {
    attrs: {
      "variant": "success",
      "type": "button"
    },
    on: {
      "click": _vm.addDeduction
    }
  }, [_c('i', {
    staticClass: "fa fa-plus pr-0"
  }), _vm._v(" ADD MORE DEDUCTION")]) : _vm._e()], 1), _c('div', {
    staticClass: "overflow-auto",
    staticStyle: {
      "max-height": "400px"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "width": "20px"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "vertical-align": "middle",
      "width": "250px"
    }
  }, [_vm._v("Name")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "vertical-align": "middle",
      "width": "250px"
    }
  }, [_vm._v("Amount")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "vertical-align": "middle",
      "width": "250px"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "vertical-align": "middle",
      "width": "250px"
    }
  }, [_vm._v("File")])])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('tbody', _vm._l(_vm.form.deduction, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center align-top"
    }, [index !== 0 ? _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click() {
          _vm.form.deduction.splice(index, 1);
          _vm.form.invoice_price += state.amount;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash pr-0"
    })]) : _vm._e()], 1), _c('td', {
      staticClass: "align-top"
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Type Text"
      },
      model: {
        value: state.name,
        callback: function callback($$v) {
          _vm.$set(state, "name", $$v);
        },
        expression: "state.name"
      }
    })], 1), _c('td', {
      staticClass: "align-top"
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Type Number"
      },
      model: {
        value: state.amount,
        callback: function callback($$v) {
          _vm.$set(state, "amount", $$v);
        },
        expression: "state.amount"
      }
    }), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatCurrency(state.amount)))]) : _vm._e()], 1), _c('td', {
      staticClass: "align-top"
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Type Text"
      },
      model: {
        value: state.remarks,
        callback: function callback($$v) {
          _vm.$set(state, "remarks", $$v);
        },
        expression: "state.remarks"
      }
    })], 1), _c('td', [_c('b-form-file', {
      attrs: {
        "id": 'fileSupport' + index,
        "placeholder": "Choose a file",
        "accept": "application/pdf"
      },
      model: {
        value: state.file,
        callback: function callback($$v) {
          _vm.$set(state, "file", $$v);
        },
        expression: "state.file"
      }
    }), _c('span', {
      staticClass: "text-primary",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenFilePreview(state === null || state === void 0 ? void 0 : state.file_url, state === null || state === void 0 ? void 0 : state.file_name);
        }
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.file_name))])], 1)]);
  }), 0) : _c('tbody', _vm._l(_vm.paymentData.deduction, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v("#")]), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v(_vm._s(_vm.formatCurrency(state.amount)))]), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.remarks))]), _c('td', {
      staticClass: "text-center"
    }, [_c('span', {
      staticClass: "text-primary",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenFilePreview(state === null || state === void 0 ? void 0 : state.file_url, state === null || state === void 0 ? void 0 : state.file_name);
        }
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.file_name))])])]);
  }), 0), _c('tfoot', [_c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Total Amount")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalAmount)))]), _c('td', {
    attrs: {
      "colspan": "2"
    }
  })])])])])]) : _vm._e(), (_vm$paymentData2 = _vm.paymentData) !== null && _vm$paymentData2 !== void 0 && _vm$paymentData2.real_invoice_fulfilled && ['PREVIEW'].includes(_vm.action) ? _c('b-col', {
    staticClass: "p-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Real Invoice Number"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$paymentData3 = _vm.paymentData) === null || _vm$paymentData3 === void 0 ? void 0 : _vm$paymentData3.real_invoice_number))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice Date"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$paymentData4 = _vm.paymentData) !== null && _vm$paymentData4 !== void 0 && _vm$paymentData4.invoice_date ? _vm.moment((_vm$paymentData5 = _vm.paymentData) === null || _vm$paymentData5 === void 0 ? void 0 : _vm$paymentData5.invoice_date).format('DD MMM YYYY') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Due Date"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$paymentData6 = _vm.paymentData) !== null && _vm$paymentData6 !== void 0 && _vm$paymentData6.due_date ? _vm.moment((_vm$paymentData7 = _vm.paymentData) === null || _vm$paymentData7 === void 0 ? void 0 : _vm$paymentData7.due_date).format('DD MMM YYYY') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice File"
    }
  }, [_vm.paymentData.real_invoice_attachment.length > 0 ? _c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l(_vm.paymentData.real_invoice_attachment, function (file, fileIndex) {
    return _c('div', {
      key: fileIndex,
      staticClass: "d-flex align-items-center mb-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenFilePreview(file === null || file === void 0 ? void 0 : file.file_url, file === null || file === void 0 ? void 0 : file.file_name);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-file text-primary mr-2"
    }), _c('span', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s(file.file_name))])]);
  }), 0) : _vm._e()])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Invoice"
    }
  }, [_c('strong', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$paymentData8 = _vm.paymentData) === null || _vm$paymentData8 === void 0 ? void 0 : _vm$paymentData8.status))])])], 1)], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_c('strong', [_vm._v("Invoice Total")])]), _c('div', {
    staticClass: "d-flex p-2 bg-success"
  }, [_c('h5', {
    staticClass: "text-white"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalInvoice)))])])])])] : _vm._e()], 2)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }